import DndScrollable from "@share/components/dnd-scrollable";
import { TAB_WIDTH_DISPLAY } from "@share/configs/const";
import { Image, Selector } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  tabWidthDisplayValue: string;
  onUpdateTabWidthDisplayValue: any;
};

const TabWidthDisplay = (props: Props) => {
  const { tabWidthDisplayValue, onUpdateTabWidthDisplayValue } = props;
  const { t } = useTranslation();
  const tabWidthDisplayOptions = useMemo(
    () => [
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/display-styles/width-tab-auto.png"
            height={77}
            width={77}
            className="m-auto"
          />
        ),
        description: (
          <div className="mt-1">{t("ss_builder_width_tab_type_1")}</div>
        ),
        value: TAB_WIDTH_DISPLAY.AUTO,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/display-styles/width-tab-equal.png"
            height={77}
            width={77}
            className="m-auto"
          />
        ),
        description: (
          <div className="mt-1">{t("ss_builder_width_tab_type_2")}</div>
        ),
        value: TAB_WIDTH_DISPLAY.EQUAL,
      },
    ],
    []
  );

  const handleSelectTabWidthDisplay = (arr) => {
    if (arr && arr.length) {
      const valueRatio = arr[0];
      onUpdateTabWidthDisplayValue(valueRatio);
    }
  };

  return (
    <div>
      <div className="text-[15px] text-[#666666] mb-1">
        {t("ss_builder_width_tab_setting_label")}
      </div>
      {/* <DndScrollable className="selector-scroll"> */}
      <Selector
        options={tabWidthDisplayOptions}
        value={[tabWidthDisplayValue]}
        onChange={handleSelectTabWidthDisplay}
        columns={3}
        style={{
          "--border": "solid transparent 1px",
          "--checked-border": "solid var(--adm-color-primary) 1px",
          "--checked-color": "#fff",
        }}
      />
      {/* </DndScrollable> */}
    </div>
  );
};

export default TabWidthDisplay;
