import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { fetchStoreToken } from "@features/mini-store/share/api";
import {
  getMaxPrice,
  getMinPrice,
} from "@features/mini-store/share/helper/price";
import { useAppTranslation } from "@features/mini-store/share/hook";
import { selectProfile } from "@features/user/userSlice";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

export default function Donation(props) {
  const { onUpdateData, block, setButtonStatus, setEditStatus } = props;
  const profile = useSelector(selectProfile);
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const positionSlug = edittingBlock && edittingBlock.position_uid;
  const tabUid = edittingBlock && edittingBlock.tab_uid;

  const defaultValueEditBio =
    edittingBlockData && edittingBlockData.uid ? edittingBlockData : null;
  const titleBlockDefault = "Donation";

  const KEY = edittingBlock && edittingBlock.KEY;
  useEffect(() => {
    const getParams = async () => {
      const store = await fetchStoreToken();
      const dataParams = {
        name: t("invitation_default_donation", { name: profile.username }),
        acceptance_criteria: t("message_default_donation", {
          name: profile.username,
        }),
        price: getMinPrice(profile.country),
        max_price: getMaxPrice(profile.country),
        seller_id: store.seller_id,
        btn_donation_title: t("button_title_default_donation", {
          name: profile.username,
        }),
        country: profile.country,
      };
      if (!block.uid) {
        const UID = `donation-${uuidv4()}`;
        const defaultDonateData = {
          uid: UID,
          sort_order: 1,
          enable: 1,
          block_type: BLOCK_TYPE.DONATION,
          title: "Donation",
          content_attributes: {
            dataParams,
          },
          style_attributes: {},
        };
        onUpdateData(defaultDonateData);
        setButtonStatus(false);
      }
    };
    getParams();
  }, []);

  return (
    <>
      <div className={"bg-white mt-4 px-4 py-3 text-center"}>
        <img
          className={""}
          src={"https://i.ecomobi.com/ssp/mini-store/donation/image-frame.png"}
          alt=""
        />
        <div className={"text-lg"}>{t("ss_donation_setting_default_title_setup_donation")}</div>
        <div className={"text-xs text-[#8C8C8C]"}>
          {t("ss_donation_setting_default_content_setup_donation")}
        </div>
      </div>
    </>
  );
}
