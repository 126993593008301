import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum FirstGuideStateEnum {
  ADD_BLOCK = "add_block",
  PREVIEW = "preview",
  PUBLISH = "publish",
  DONE = "done",
}

export enum IntroduceDoubleTapEnum {
  TRUE = 1,
  FALSE = 2,
}

export enum FloatingIntroStatus {
  ENABLE = 1,
  DISABLE = 2,
}

export interface FloatingIntroduction {
  [key: string]: FloatingIntroStatus;
}
export interface DataFont {
  title: string;
  paragraph: string;
}

export enum FLOATING {
  BRAND_GIFT_FOOTER = "brand_gift",
  BRAND_RATING_BLOCK = "brand_rating",
}

interface ExternalStateStored {
  guide_state: FirstGuideStateEnum;
  introduce_double_tap: IntroduceDoubleTapEnum;
  floating_icon: FloatingIntroduction;
  floating_block: FloatingIntroduction;
  data_font: DataFont;
  is_valid_font: boolean;
  font_weight_available: Array<number>;
  is_font_loading: boolean;
}

const initialExternalState: ExternalStateStored = {
  guide_state: FirstGuideStateEnum.DONE,
  introduce_double_tap: IntroduceDoubleTapEnum.TRUE,
  floating_icon: {
    [FLOATING.BRAND_GIFT_FOOTER]: FloatingIntroStatus.DISABLE,
  },
  floating_block: {
    [FLOATING.BRAND_RATING_BLOCK]: FloatingIntroStatus.DISABLE,
  },
  data_font: {
    title: "",
    paragraph: "",
  },
  is_valid_font: true,
  font_weight_available: [],
  is_font_loading: false
};

const externalStateSlice = createSlice({
  name: "externalState",
  initialState: initialExternalState,
  reducers: {
    getExternalState: () => {},
    getExternalStateSuccess: (
      state,
      action: PayloadAction<ExternalStateStored>
    ) => {
      return {
        ...action.payload,
      };
    },
    setFirstGuideState: (state, action: PayloadAction<FirstGuideStateEnum>) => {
      state.guide_state = action.payload;
    },
    setIntroduceDoubleTapState: (
      state,
      action: PayloadAction<IntroduceDoubleTapEnum>
    ) => {
      state.introduce_double_tap = action.payload;
    },
    setFloatingIntroductionState: (
      state,
      action: PayloadAction<FloatingIntroduction>
    ) => {
      state.floating_icon = action.payload;
    },
    setFloatingBlockIntroductionState: (
      state,
      action: PayloadAction<FloatingIntroduction>
    ) => {
      state.floating_block = action.payload;
    },
    setDataFont: (state, action) => {
      state.data_font = action.payload;
    },
    setValidFont: (state, action) => {
      state.is_valid_font = action.payload;
    },
    setValidFontWeight: (state, action) => {
      state.font_weight_available = action.payload;
    },
    setFontLoading: (state, action) => {
      state.is_font_loading = action.payload;
    },
  },
});

export const {
  getExternalState,
  getExternalStateSuccess,
  setFirstGuideState,
  setIntroduceDoubleTapState,
  setFloatingIntroductionState,
  setFloatingBlockIntroductionState,
  setDataFont,
  setValidFont,
  setValidFontWeight,
  setFontLoading,
} = externalStateSlice.actions;
export default externalStateSlice.reducer;
