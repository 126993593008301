import { postSaveBrandGift } from "@features/mini-store/share/api";
import { dataService } from "@share/configs/rxjs";
import { NAME_SERVICE } from "@features/mini-store/setting/constants";
import brandGiftApi from "@features/mini-store/block/BrandGift/brandGiftApi";

export const BrandGiftMiddleWare = async (data, extraData) => {
  if (data) {
    const content = data.content_attributes && data.content_attributes;
    if (content.cate_items && content.cate_items.length) {
       await brandGiftApi.setting({setting: content.cate_items})
    }
    try {
      await postSaveBrandGift({
        block_title: content.title,
        button_text: content.button,
        template_key: content.key,
        service: NAME_SERVICE.BRAND_GIFT,
        cate_items: content.cate_items,
      });
      dataService.publish({ loading: true });
      const newData = {
        ...data,
        content_attributes: {
          block_title: content.title,
          button_text: content.button,
          template_key: content.key,
          service: NAME_SERVICE.BRAND_GIFT,
          cate_items: content.cate_items,
        },
      };
      return newData;
    } catch (e) {
      return data;
    }
  }
  return data;
};
