import {
  AffiliateProductInterface,
  ProductInterface,
} from "@features/main/store/interface";
import { genLinkV2 } from "@features/user/userApi";
import { ENABLE_BLOCK } from "@share/configs/const";
import { RightArrowIcon } from "@share/icons";
import { Button, Input } from "antd-mobile";
import cloneDeep from "lodash/cloneDeep";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AffiliateProductGroupPickerView from "../AffiliateProductGroupPickerView";
import ProductItem from "../ProductItem";
import { checkOpenNewTab } from "@share/helper/validator";
import HidddenWarningUI from "@features/block/components/HiddenWarningUI";
import MoreActions from "./MoreActions";

type Props = {
  productData: ProductInterface;
  callBackCancelAffiliateProductEdit: any;
  callBackSaveAffiliateProductEdit: any;
  affiliateProductGroupData: AffiliateProductInterface[];
  callbackAddNewAffiliateProductGroup: any;
  activeProductGroup: string;
  productGroupMaximum: number;
};

const AffiliateProductEdit = (props: Props) => {
  const {
    productData,
    callBackCancelAffiliateProductEdit,
    callBackSaveAffiliateProductEdit,
    affiliateProductGroupData,
    callbackAddNewAffiliateProductGroup,
    activeProductGroup,
    productGroupMaximum,
  } = props;

  const { t } = useTranslation();
  const popoverRef = useRef(null);

  const [productDataState, setProductDataState] = useState(productData);

  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [productGroupValue, setProductGroupValue] =
    useState(activeProductGroup);
  const [isOpenProductGroupSelection, setIsOpenProductGroupSelection] =
    useState(false);

  useEffect(() => {
    if (!productData?.shortern_link) {
      genLinkV2(productData.origin_link, {
        sub1: "ig",
        sub2: "true",
        traffic_source: "ss-landing-copy",
      })
        .then((res) => {
          setProductDataState((state) => ({
            ...state,
            shortern_link: res?.link_shorten,
            gen_link_err_code: !res?.success ? res?.code : null,
          }));
        })
        .catch(console.log);
    }
  }, []);

  useEffect(() => {
    if (productData) {
      setProductDataState(productData);
    }
  }, [productData]);

  const handleCheckOpenNewTab = (openNewTab) => {
    const isEcommerce = checkOpenNewTab(new URL(productDataState?.origin_link));
    const productDataClone = cloneDeep(productDataState);
    productDataClone.open_new_tab = isEcommerce ? true : openNewTab;
    setProductDataState(productDataClone);
  };

  const handleChangeNameProduct = (value) => {
    const productDataClone = cloneDeep(productDataState);
    productDataClone.title = value;
    setProductDataState(productDataClone);
  };

  const handleDeleteImageProduct = () => {
    const productDataClone = cloneDeep(productDataState);
    productDataClone.image = productData?.image;
    setProductDataState(productDataClone);
  };

  const handleImageUrl = (data) => {
    const imgFileItems = data && data[data.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(data);
      const productDataClone = cloneDeep(productDataState);
      productDataClone.image = imgFileItems.dataBase64;
      productDataClone.image_name = imgFileItems.fileName;
      productDataClone.image_base64 = imgFileItems.dataBase64;
      setProductDataState(productDataClone);
    }
  };

  const handleCheckAffiliateUrl = () => {
    const productDataClone = cloneDeep(productDataState);
    productDataClone.use_aff_url = productDataClone.use_aff_url === 1 ? 2 : 1;
    setProductDataState(productDataClone);
  };
  const handleSelectLabelProduct = (data) => {
    const productDataClone = cloneDeep(productDataState);
    productDataClone.label_product = data && Array.isArray(data) ? data : [];
    setProductDataState(productDataClone);
  };

  const handleCancelAffiliateProductEdit = () => {
    callBackCancelAffiliateProductEdit();
  };

  const handleSaveAffiliateProductEdit = useCallback(() => {
    const currentTime = new Date();
    const updatedAtTime = currentTime.toISOString();
    const affiliateProductGroupDataClone = cloneDeep(affiliateProductGroupData);
    const activedProductGroupData = affiliateProductGroupData?.find(
      (x) => x.uid === productGroupValue
    );
    if (productGroupValue === activeProductGroup) {
      const newActivedProductGroup = {
        ...activedProductGroupData,
        content_attributes: {
          ...activedProductGroupData.content_attributes,
          items: activedProductGroupData?.content_attributes?.items.map(
            (ele) => {
              if (ele.uid === productDataState.uid) {
                if (productDataState?.post_link) {
                  return { ...productDataState, updatedAt: updatedAtTime };
                }
                return productDataState;
              }
              return ele;
            }
          ),
        },
      };

      const newAffiliateProductGroupData = affiliateProductGroupDataClone?.map(
        (ele) => {
          return ele.uid === productGroupValue ? newActivedProductGroup : ele;
        }
      );
      callBackSaveAffiliateProductEdit(newAffiliateProductGroupData);
    } else {
      const productGroupDataAfterAddingProductId =
        affiliateProductGroupDataClone.map((element) => {
          if (element.uid === productGroupValue) {
            const productList = element?.content_attributes?.items;
            if (productDataState) {
              if (productDataState?.post_link) {
                productList.unshift({
                  ...productDataState,
                  updatedAt: updatedAtTime,
                });
              } else productList.unshift(productDataState);
            }

            const newProductListSortOrder = productList.map((ele, index) => ({
              ...ele,
              sort_order: index + 1,
            }));
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: newProductListSortOrder,
              },
            };
          } else return element;
        });

      //Remove product from the old product group
      const productGroupDataAfterRemovingProductId =
        productGroupDataAfterAddingProductId.map((element) => {
          if (element.uid === activeProductGroup) {
            const productList = element?.content_attributes?.items;
            const productListRemovedProductId = productList.filter(
              (x) => x.uid !== productDataState?.uid
            );
            const productListRemovedProductIdSortOrder =
              productListRemovedProductId.map((ele, index) => ({
                ...ele,
                sort_order: index + 1,
              }));
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: productListRemovedProductIdSortOrder,
              },
            };
          } else return element;
        });
      callBackSaveAffiliateProductEdit(productGroupDataAfterRemovingProductId);
    }
    callBackCancelAffiliateProductEdit();
  }, [affiliateProductGroupData, productGroupValue, productDataState]);

  const isSaveButtonEnable = useMemo(() => {
    if (
      (JSON.stringify(productDataState) !== JSON.stringify(productData) ||
        productGroupValue !== activeProductGroup) &&
      // productDataState.image &&
      productDataState.title &&
      productDataState.title.trim() &&
      productDataState.origin_link &&
      productGroupValue
    ) {
      return true;
    }
    return false;
  }, [productDataState, productData, productGroupValue, activeProductGroup]);

  const productGroupPickerValue = useMemo(
    () =>
      productGroupValue
        ? affiliateProductGroupData?.find((x) => x?.uid === productGroupValue)
            ?.content_attributes?.title_product_group
        : "",
    [productGroupValue, affiliateProductGroupData]
  );

  const onDeleteProduct = () => {
    const newAffiliateProductGroupData = affiliateProductGroupData.map(
      (element) => {
        if (element.uid === productGroupValue) {
          const productList = element?.content_attributes?.items;
          const newProductList = productList.filter(
            (x) => x.uid !== productDataState.uid
          );
          const newProductListSortOrder = newProductList.map((ele, index) => ({
            ...ele,
            sort_order: index + 1,
          }));
          return {
            ...element,
            content_attributes: {
              ...element.content_attributes,
              items: newProductListSortOrder,
            },
          };
        } else return element;
      }
    );
    callBackSaveAffiliateProductEdit(newAffiliateProductGroupData);
    callBackCancelAffiliateProductEdit();
  };

  return (
    <>
      <div
        className="flex flex-row text-center items-center justify-center py-3 bg-white relative"
        ref={popoverRef}
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <div className="text-lg leading-6">
          {t("ss_builder_edit_affiliate_product_title")}
        </div>
        <div className="absolute right-4">
          <MoreActions
            productData={productDataState}
            onUpdateProductData={(data) => setProductDataState(data)}
            onDeleteProduct={onDeleteProduct}
          />
        </div>
      </div>
      {productDataState?.enable != ENABLE_BLOCK && (
        <HidddenWarningUI label={t("ss_builder_product_is_hiding_label")} />
      )}
      <div
        className="p-[12px_16px] bg-[#FFFFFF] flex flex-row items-center justify-between cursor-pointer"
        onClick={() => setIsOpenProductGroupSelection(true)}
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <div className="grow">
          <div className="text-[15px] leading-[23px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_product_group_selection_title")}
          </div>
          <Input
            placeholder={
              !productGroupValue &&
              t("ss_builder_product_group_selection_placeholder")
            }
            value={productGroupPickerValue}
            readOnly
            className="w-full text-ellipsis overflow-hidden whitespace-nowrap"
          />
        </div>
        <RightArrowIcon
          className="w-4 h-4"
          fillColor="#333333"
        />
      </div>
      <ProductItem
        item={productDataState as any}
        onUpdateName={function (name: string): void {
          handleChangeNameProduct(name);
        }}
        onCheckOpenNewTab={function (openNewTab: boolean): void {
          handleCheckOpenNewTab(openNewTab);
        }}
        onUpdateImage={function (image: any): void {
          if (image == null) {
            handleDeleteImageProduct();
          } else {
            handleImageUrl(image);
          }
        }}
        onCheckUseAffiliateUrl={function (checked: boolean): void {
          handleCheckAffiliateUrl();
        }}
        onSelectLabelProduct={function (labelProduct: any): void {
          handleSelectLabelProduct(labelProduct);
        }}
        isValidFileFormat={isValidFileFormat}
        isValidFileSize={isValidFileSize}
        isEdit={true}
      />
      <div>
        <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              onClick={handleCancelAffiliateProductEdit}
            >
              {t("ss_builder_editor_btn-cancel")}
            </Button>
          </div>
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              disabled={!isSaveButtonEnable}
              color={"primary"}
              onClick={handleSaveAffiliateProductEdit}
            >
              {t("ss_builder_editor_btn-save")}
            </Button>
          </div>
        </div>
      </div>
      {isOpenProductGroupSelection && (
        <AffiliateProductGroupPickerView
          affiliateProductGroupData={affiliateProductGroupData}
          visible={isOpenProductGroupSelection}
          onCancelAffiliateProductGroupSelection={() =>
            setIsOpenProductGroupSelection(false)
          }
          defaultValue={productGroupValue}
          onSelectAffiliateProductGroup={(val) => setProductGroupValue(val)}
          onAddNewAffiliateProductGroup={callbackAddNewAffiliateProductGroup}
          productGroupMaximum={productGroupMaximum}
        />
      )}
    </>
  );
};

export default AffiliateProductEdit;
