import React from "react";
import { getOpacityFromHex, getGlobalColor } from "@share/lib";

export default function DisplayColor({
  backgroundColor,
  onClickOpenColorPicker,
}) {
  return (
    <>
      <div
        className="w-12 h-12 rounded-[50%] cursor-pointer  relative overflow-hidden"
        style={{
          backgroundColor: getGlobalColor(backgroundColor),
        }}
        onClick={() => onClickOpenColorPicker(true)}
      >
        {getOpacityFromHex(backgroundColor) !== 1 && (
          <div
            className="w-[50%] h-[100%] absolute right-0 top-0"
            style={{
              background:
                "repeat #fff url(https://image.passio.eco/page-builder/page-builder/background-default/checkerboard-v2.png) ",
            }}
          ></div>
        )}

        <div
          className="w-[50%] h-[100%] absolute right-0 top-0"
          style={{
            backgroundColor: backgroundColor,
          }}
        ></div>
        <div
          className="w-[100%] h-[100%] absolute left-0 top-0 rounded-[50%]"
          style={{
            outline: "rgba(0, 0, 0, 0.2) solid 1px",
            outlineOffset: "-1px",
          }}
        ></div>
      </div>
    </>
  );
}
