import { InfomationCircleIcon } from "@share/icons";
import { Popover, Switch } from "antd-mobile";
import React, { ReactElement } from "react";

interface Props {
  label?: string | ReactElement;
  onChange: (val) => void;
  defaultChecked?: boolean;
  checked?: boolean;
  helperContent?: string | ReactElement;
}

const SwitchFieldCustom = ({
  label,
  onChange,
  checked = false,
  defaultChecked = false,
  helperContent = "",
}: Props) => {
  return (
    <>
      <div className="flex items-center gap-x-1">
        {label && (
          <div className="text-[15px] text-[#666666] font-normal">{label}</div>
        )}
        {helperContent && (
          <>
            <Popover
              content={helperContent}
              placement="top-start"
              mode="dark"
              trigger="click"
            >
              <div className="w-[21px] h-[21px] flex items-center justify-center">
                <InfomationCircleIcon
                  fillColor="#1890FF"
                  className="w-[15px] h-[15px] cursor-pointer"
                />
              </div>
            </Popover>
          </>
        )}
      </div>
      <Switch
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
      />
    </>
  );
};

export default SwitchFieldCustom;
