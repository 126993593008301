import AutoScrollSelector from "@share/components/AutoScrollSelector";
import { CARD_LAYOUTS } from "@share/configs/const";
import { Image } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  cardLayoutValue: string;
  onUpdateCardLayoutValue: any;
  isCardStyleSlide: boolean;
};

const CardLayouts = (props: Props) => {
  const { cardLayoutValue, onUpdateCardLayoutValue, isCardStyleSlide } = props;

  const { t } = useTranslation();
  const elementId = useMemo(() => {
    return "e" + Math.random().toString(36).slice(2, 7);
  }, []);
  const cardLayoutOptions = useMemo(
    () => [
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text under image - Left align.png"
            height={96}
            width={96}
            className="m-auto"
            data-value={CARD_LAYOUTS.LAYOUT_1}
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_1}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_1")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_1,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text under image - Center align.png"
            height={96}
            width={96}
            className="m-auto"
            data-value={CARD_LAYOUTS.LAYOUT_2}
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_2}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_2")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_2,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text over image - Center align.png"
            height={96}
            width={96}
            className="m-auto"
            data-value={CARD_LAYOUTS.LAYOUT_3}
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_3}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_3")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_3,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text over image - Left align.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_4}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_4")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_4,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text on the right.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_5}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_5")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_5,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text on the left.png"
            width={96}
            height={96}
            className="m-auto"
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_6}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_6")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_6,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text over image - Left align 2.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_7}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_7")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_7,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/Text over image - Center align 2.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: (
          <div
            id={elementId + "-" + CARD_LAYOUTS.LAYOUT_8}
            className="mt-1"
          >
            {t("ss_builder_card_layout_label_8")}
          </div>
        ),
        value: CARD_LAYOUTS.LAYOUT_8,
      },
    ],
    []
  );

  const handleSelectCardLayout = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      onUpdateCardLayoutValue(value);
    }
  };

  const cardLayoutOptionsMemo = useMemo(() => {
    if (isCardStyleSlide) {
      return cardLayoutOptions?.filter(
        (x) =>
          x.value !== CARD_LAYOUTS.LAYOUT_5 && x.value !== CARD_LAYOUTS.LAYOUT_6
      );
    } else {
      return cardLayoutOptions;
    }
  }, [isCardStyleSlide]);

  // useEffect(() => {
  //   const element = document.querySelector(`#${elementId} .adm-selector-item-active`);
  //   element.scrollIntoView({block: 'nearest', inline: 'center'});
  //   // element.querySelector('');
  // }, []);

  // useEffect(() => {
  //   const childElementId = elementId + "-" + cardLayoutValue;
  //   const element = document.getElementById(childElementId);
  //   if(element){
  //     element.scrollIntoView({block: 'nearest', inline: 'center', behavior: "smooth"});
  //   }

  // }, [cardLayoutValue])

  return (
    <div id={elementId}>
      <span className="text-[15px] leading-[13px] text-[#666666]">
        {t("ss_builder_card_layout_label")}
      </span>
      {/* <DndScrollable className="selector-scroll">
        <Selector
          options={cardLayoutOptionsMemo}
          value={[cardLayoutValue]}
          onChange={handleSelectCardLayout}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      </DndScrollable> */}
      <AutoScrollSelector
        elementId={elementId}
        options={cardLayoutOptionsMemo}
        value={cardLayoutValue}
        onChange={handleSelectCardLayout}
      />
    </div>
  );
};

export default CardLayouts;
