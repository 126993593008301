import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";

enum ACTIVE_STATUS {
  ENABLE = 1,
  DISABLE = 2,
}

interface Item {
  id: string;
  sort_order?: number;
  enable?: ACTIVE_STATUS;
  [key: string]: any;
}

const useActionItem = (defaultList: Array<Item>, propertiesIndex: string) => {
  const [items, setItems] = useState<Array<Item>>(cloneDeep(defaultList));

  const updateSortOrder = (list: Array<Item>) => {
    return list.map((e, id) => ({
      ...e,
      sort_order: id,
    }));
  };

  const onReOrder = (startIndex: number, endIndex: number) => {
    const newItems = updateSortOrder(cloneDeep(items));
    const [removed] = newItems.splice(startIndex, 1);
    newItems.splice(endIndex, 0, removed);
    setItems(updateSortOrder(newItems));
    return newItems;
  };

  const onAdd = (item: Item, position: "TOP" | "BOT") => {
    const newItems = cloneDeep(items);
    position === "TOP" ? newItems.unshift(item) : newItems.push(item);
    setItems(updateSortOrder(newItems));
    return newItems;
  };

  const onRemove = (ids: Array<string>) => {
    let newItems = items.filter((e) => !ids.includes(e[propertiesIndex]));
    newItems = updateSortOrder(newItems);
    setItems(newItems);
    return newItems;
  };

  const onEnable = (ids: Array<string>) => {
    const newItems = cloneDeep(items).map((e) => ({
      ...e,
      enable: ids.includes(e[propertiesIndex])
        ? ACTIVE_STATUS.ENABLE
        : e.enable,
    }));
    setItems(updateSortOrder(newItems));
    return newItems;
  };

  const onDisable = (ids: Array<string>) => {
    const newItems = cloneDeep(items).map((e) => ({
      ...e,
      enable: ids.includes(e[propertiesIndex])
        ? ACTIVE_STATUS.DISABLE
        : e.enable,
    }));
    setItems(updateSortOrder(newItems));
    return newItems;
  };

  const onReset = (items: Array<Item>) => {
    setItems(items);
    return items;
  };

  return {
    items,
    onAdd,
    onRemove,
    onReOrder,
    onEnable,
    onDisable,
    onReset,
  };
};

export default useActionItem;
