export const LIST_FRAME = [
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/91d62e8c-eda1-4e26-ba3b-1d083761052f1-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/1-ST.svg",
    id: 1,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/2-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/2-ST.svg",
    id: 2,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/58d6d2ca-8c69-4a2d-bab5-196e009c07e13-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/3-ST.svg",
    id: 3,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/24b8cc44-bdb8-41b6-a247-5b004b1f0f2b4-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/4-ST.svg",
    id: 4,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/5-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/5-ST.svg",
    id: 5,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/6-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/6-ST.svg",
    id: 6,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/7-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/7-ST.svg",
    id: 7,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/a52e8319-a963-44c3-9bd8-fd71d6e090348-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/8-ST.svg",
    id: 8,
  },
  {
    urlEU:
      "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/d3180365-5d5f-4e57-98f3-a4926be11e559-EU.png",
    url: "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/632d34c653c0c84ca461b2dc/9-ST.svg",
    id: 9,
  },
];

export const TYPE_FILE_IMAGE_FRAME = [
  "image/gif",
  "image/png",
  "image/svg+xml",
];

export const TRANSPARENT_IMAGE =
  "https://passio-prod.s3-ap-southeast-1.amazonaws.com/passio-prod/builder/62971a2c53c0c838726388eb/92d9f969-560d-47bb-8367-cb3b344d37a2transparent_bg.svg";
