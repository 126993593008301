import { createSlice } from "@reduxjs/toolkit";
import {CREATOR_ROLE_ENUM, CREATOR_TYPE_ENUM, PassioPageProfileInterface} from "./interface";

const initialState: PassioPageProfileInterface = {
  id: "",
  name: "",
  avatar: "",
  email: "",
  country: "",
  cps_user_id: "",
  eop_user_id: "",
  username: "",
  creator_role: CREATOR_ROLE_ENUM.SELLER,
  passio_page_onboard_state: null,
  finish_product_tour: null,
  site_id: null,
  domain: "",
  creator_type: CREATOR_TYPE_ENUM.NORMAL
};

export const passioPageProfileSlice = createSlice({
  name: "passioPageProfile",
  initialState,
  reducers: {
    setPassioPageProfile: (state, { payload: { data } }) => {
      return { ...state, ...data };
    },
  },
});

export const { setPassioPageProfile } = passioPageProfileSlice.actions;
export default passioPageProfileSlice.reducer;
