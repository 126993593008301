import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { useAppTranslation } from "@features/mini-store/share/hook";
import { selectProfile } from "@features/user/userSlice";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {BACK_GROUND_IMAGE} from "@features/mini-store/setting/constants";

export default function BrandRating(props) {
  const { onUpdateData, block, setButtonStatus, setEditStatus } = props;
  const profile = useSelector(selectProfile);
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();

  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const positionSlug = edittingBlock && edittingBlock.position_uid;
  const tabUid = edittingBlock && edittingBlock.tab_uid;

  const titleBlockDefault = "Brand Rating";

  const KEY = edittingBlock && edittingBlock.KEY;
  useEffect(() => {
    const getParams = async () => {
      if (!block.uid) {
        const UID = `brand-rating-${uuidv4()}`;
        const defaultDonateData = {
          uid: UID,
          sort_order: 1,
          enable: 1,
          block_type: BLOCK_TYPE.BRAND_RATING,
          title: titleBlockDefault,
          content_attributes: {},
          style_attributes: {},
        };
        onUpdateData(defaultDonateData);
        setButtonStatus(false);
      }
    };
    getParams();
  }, []);

  return (
    <>
      <div className={"bg-white mt-4 px-4 py-3 text-center"}>
        <img
          className={"rounded-xl"}
          src={BACK_GROUND_IMAGE.BRAND_RATING}
          alt=""
        />
        <div className={"text-xs text-[#8C8C8C] mt-2"}>
          {t("ss_brand_rating_setting_default_content_setup_donation")}
        </div>
      </div>
    </>
  );
}
