import {
  actionChannel,
  take,
  select,
  call,
  takeEvery,
  put,
} from "redux-saga/effects";
import {
  callBackSavingItem,
  executeSavingItem,
  pushSavingItem,
  releaseSavingItem,
  SavingItem,
} from "../savingItemSlice";
import { ActionInterface } from "@app/store";
import { setDynamicParams, setError } from "../dynamicSlice";
import { v4 as generateRandomId } from "uuid";
import { setHasChange, setVersion } from "../siteSettingSlice";

export function generateSavingProcessId(prefix) {
  return `${prefix}-` + generateRandomId();
}
function* savingItemSaga() {
  yield takeEvery(pushSavingItem.type, function* (action: ActionInterface) {
    const { payload } = action;
    const savingProcess = yield select((state) => state.savingItem);
    const isSaving = savingProcess && savingProcess.length > 0;
    yield put(setDynamicParams({ data: { isSaving: isSaving } }));
    yield put(executeSavingItem(payload));
  });

  yield takeEvery(releaseSavingItem.type, function* (action: ActionInterface) {
    const savingProcess = yield select((state) => state.savingItem);
    const isSaving = savingProcess && savingProcess.length > 0;
    yield put(setDynamicParams({ data: { isSaving: isSaving } }));
  });

  const saveChannel = yield actionChannel(executeSavingItem.type);
  while (true) {
    const { payload }: { payload: { item: SavingItem } } = yield take(
      saveChannel
    );
    const isReponseValidDefault = async (res) => {
      return true;
    };

    const getVersionFromResponseDefault = async (res) => {
      return res.data.version;
    };
    const { item } = payload;
    const {
      isResponseValid = isReponseValidDefault,
      getVersionFromResponse = getVersionFromResponseDefault,
    } = item;
    const baseData = yield select((state) => {
      const siteSetting = state.siteSettings;
      return {
        site_setting_id: siteSetting.id,
        user_id: siteSetting.user_id,
        version: siteSetting.version,
      };
    });
    const excuteArguments = item.getArguments(baseData);
    try {
      const res = yield call(item.executer, ...excuteArguments);
      const version = yield call(getVersionFromResponse, res);
      yield put(setVersion({ version: version }));
      yield put(setHasChange({ has_change: true }));
      yield put(callBackSavingItem({ id: item.id, data: res }));
    } catch (e) {
      yield put(setError({ is_error: true }));
    }
    yield put(releaseSavingItem({ id: item.id }));
  }
}

export default savingItemSaga;
