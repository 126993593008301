import { useEffect, useState } from "react";
import { requestGetHTMLDataFromUrl } from "../api";

const PRODUCT_URL_ELEMENT_CLASS = "pkAuV";

export const useGetProductLinksFromLinkTree = (
  url: string,
  isInvalidURL: boolean
) => {
  const [links, setLinks] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchData = async (url: string) => {
      let response = null;
      try {
        response = await requestGetHTMLDataFromUrl(url);
      } catch (error) {
        console.error("Error fetching HTML:", error);
        setIsLoading(false);
        setIsError(true);
      }
      if (response?.data) {
        const htmlValue = response.data;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlValue, "text/html");
        const items = doc.querySelectorAll(`.${PRODUCT_URL_ELEMENT_CLASS}`);
        
        const links = [];
        for (let i = 0; i < items.length; i++) {
          const href = items[i]
            ?.getElementsByTagName("a")[0]
            ?.getAttribute("href");
          if (href) {
            links.push(href);
          }
        }
        setLinks(links);
        setIsLoading(false);
        setIsError(false);
      }
    };
    if (url && !isInvalidURL) {
      setIsLoading(true);
      fetchData(url);
    }
  }, [url, isInvalidURL]);

  return { isLoading, links, isError };
};
