import {
  CardIcon,
  InformationIcon,
  InformationTriangleIcon,
} from "@share/icons";
import { mockUpload } from "@share/lib";
import {
  Button,
  Divider,
  ImageUploader,
  Input,
  Modal,
  Popover,
  TextArea,
  Checkbox,
} from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import UploadImage from "@share/components/UploadImage";
import FormInput from "@features/block/components/FormInput";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import { checkOpenNewTab } from "@share/helper/validator";

const CARD_TITLE_MAXIMUM = 50;
const CARD_SUBTITLE_MAXIMUM = 50;
const CARD_LABEL_MAXIMUM = 25;
const CARD_DESCRIPTION_MAXIMUM = 250;

const CardItemAddNew = (props) => {
  const {
    imageRatio,
    callBackCancelCardAddNew,
    handleSaveCardAddNew,
    cardEditData,
    blockType,
  } = props;

  const { t } = useTranslation();

  const UID = `${blockType}-${uuidv4()}`;
  const isUseAffUrl = cardEditData ? cardEditData?.use_aff_url === 1 : true;

  const cardUrlDefault = cardEditData?.card_url || "";
  const valueUrlImageDefault = cardEditData?.image || "";
  const cardTitleDefault = cardEditData?.card_title || "";
  const cardSubtitleDefault = cardEditData?.card_subtitle || "";
  const cardLabelDefault = cardEditData?.card_label || "";
  const cardDescriptionDefault = cardEditData?.card_description || "";
  const defaultLinkData = cardEditData?.link_data || {
    type: LinkTypeEnum.WEB,
    value: cardUrlDefault,
  };
  const openInNewTabDefault =
    cardEditData?.content_attributes?.open_new_tab || false;

  const [valueUrlImage, setValueUrlImage] = useState(valueUrlImageDefault);
  const [valueNameImage, setValueNameImage] = useState("");
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [cardUrl, setCardUrl] = useState(cardUrlDefault);
  const [cardLabel, setCardLabel] = useState(cardLabelDefault);
  const [cardTitle, setCardTitle] = useState(cardTitleDefault);
  const [cardSubtitle, setCardSubtitle] = useState(cardSubtitleDefault);
  const [cardDescription, setCardDescription] = useState(
    cardDescriptionDefault
  );
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isDisableBtnSave, setIsDisableBtnSave] = useState(false);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [hasCardUrl, setHasCardUrl] = useState(isUseAffUrl);
  const [linkData, setLinkData] = useState<LinkDataInterface>(defaultLinkData);

  const [isEcommerce, setIsEcommerce] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);
  const checkEcommerce = (data) => {
    if (data) {
      const ecommerceOrSocial = new URL(data);
      setIsEcommerce(checkOpenNewTab(ecommerceOrSocial));
    }
  };

  useEffect(() => {
    if (linkData && linkData.type == LinkTypeEnum.WEB) {
      checkEcommerce(linkData.target_url);
    }
  }, [linkData]);

  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
    }
  };

  const handleSaveCard = () => {
    const defaultData = {
      uid: UID,
      sort_order: 1,
      enable: 1,
      image: valueImageBase64 || valueUrlImage,
      image_name: valueNameImage,
      image_base64: valueImageBase64,
      card_title: cardTitle,
      card_subtitle: cardSubtitle,
      card_label: cardLabel,
      card_url: cardUrl,
      card_description: cardDescription,
      use_aff_url: hasCardUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
      link_data: linkData,
      open_new_tab: isEcommerce ? true : openNewTab,
    };

    if (cardEditData) {
      handleSaveCardAddNew({
        ...cardEditData,
        image: valueImageBase64 || valueUrlImage,
        image_base64: valueImageBase64,
        card_title: cardTitle,
        card_subtitle: cardSubtitle,
        card_label: cardLabel,
        card_url: cardUrl,
        card_description: cardDescription,
        image_name: valueNameImage,
        use_aff_url: hasCardUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
        link_data: linkData,
        open_new_tab: isEcommerce ? true : openNewTab,
      });
    } else {
      handleSaveCardAddNew(defaultData);
    }
    callBackCancelCardAddNew();
    setValueUrlImage(null);
    setUploadedImageArr([]);
    setValueImageBase64(null);
  };

  const handleCancelCardAddNew = () => {
    if (
      linkData.value !== defaultLinkData.value ||
      (valueUrlImage && valueUrlImage !== valueUrlImageDefault) ||
      cardUrl !== cardUrlDefault ||
      cardTitle !== cardTitleDefault ||
      cardSubtitle !== cardSubtitleDefault ||
      cardDescription !== cardDescriptionDefault ||
      cardLabel !== cardLabelDefault ||
      openNewTab !== openInNewTabDefault
    ) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="var(--adm-color-primary)"
            onClick={() => {}}
          />
        ),
        title: (
          <div>{t("ss_builder_confirm-cancel_image_slider_add_new_title")}</div>
        ),
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_confirm-cancel_image_slider_add_new_content")}
          </div>
        ),
        confirmText: t(
          "ss_builder_confirm_cancel_image_slider_add_new_btn_label"
        ),
        cancelText: t("ss_builder_cancel_image_slider_add_new_btn_label"),
        onConfirm: () => {
          callBackCancelCardAddNew();
          setValueUrlImage(null);
          setUploadedImageArr([]);
          setValueImageBase64(null);
        },
        onCancel: () => {
          return;
        },
      });
    } else {
      callBackCancelCardAddNew();
      setValueUrlImage(null);
      setUploadedImageArr([]);
      setValueImageBase64(null);
    }
  };

  const handleChangeCardDescription = (val) => {
    if (val?.length <= CARD_DESCRIPTION_MAXIMUM) {
      setCardDescription(val);
    }
  };

  const handleChangeCardTitle = (val) => {
    if (val?.length <= CARD_TITLE_MAXIMUM) {
      setCardTitle(val);
    }
  };

  const handleChangeCardSubtitle = (val) => {
    if (val?.length <= CARD_SUBTITLE_MAXIMUM) {
      setCardSubtitle(val);
    }
  };

  const handleChangeCardLabel = (val) => {
    if (val?.length <= CARD_LABEL_MAXIMUM) {
      setCardLabel(val);
    }
  };

  const handleCheckCardUrl = (data) => {
    setHasCardUrl(data);
  };

  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    if (linkData.type === LinkTypeEnum.WEB) {
      setCardUrl(linkData.value);
    } else {
      setCardUrl(null);
    }
    setLinkData(linkData);
  };
  useEffect(() => {
    setIsDisableBtnSave(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setIsLoadingBtn(isLoadingBtn);
  }, [isLoadingBtn]);

  const onAffLoading = useCallback(
    (isLoading) => {
      setIsDisableBtnSave(isLoading);
      setIsLoadingBtn(isLoading);
    },
    [setIsDisableBtnSave, setIsLoadingBtn]
  );

  const isSaveButtonEnable = useMemo(() => {
    if (
      valueUrlImage &&
      (valueUrlImage !== valueUrlImageDefault ||
        cardDescription !== cardDescriptionDefault ||
        cardTitle !== cardTitleDefault ||
        cardSubtitle !== cardSubtitleDefault ||
        cardLabel !== cardLabelDefault ||
        cardUrl !== cardUrlDefault ||
        JSON.stringify(linkData) !== JSON.stringify(defaultLinkData) ||
        isUseAffUrl !== hasCardUrl ||
        openNewTab !== openInNewTabDefault) &&
      !isValidUrl &&
      !isDisableBtnSave
    ) {
      return true;
    } else return false;
  }, [
    valueUrlImage,
    valueUrlImageDefault,
    isValidUrl,
    isDisableBtnSave,
    cardDescription,
    cardDescriptionDefault,
    cardLabel,
    cardLabelDefault,
    cardSubtitle,
    cardSubtitleDefault,
    cardTitle,
    cardTitleDefault,
    cardUrl,
    cardUrlDefault,
    hasCardUrl,
    linkData,
    defaultLinkData,
    openNewTab,
    openInNewTabDefault,
  ]);

  return (
    <div className="z-[2]">
      <div
        className="flex flex-row text-center items-center justify-center py-3 gap-2 relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <CardIcon />
        <div>{t("ss_builder_title_block_card")}</div>
      </div>
      {/* <Divider /> */}
      <div className="pb-[70px]">
        <div className="bg-white px-4 py-[13px]">
          <div className="text-[15px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_add_new_image_of_image_slider_title")}
          </div>
          <UploadImage
            valueUrlImage={valueUrlImage}
            imageRatio={imageRatio}
          />
          {isValidFileFormat && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_format_invalid_content")}
              </div>
            </div>
          )}
          {isValidFileSize && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_size_invalid_content")}
              </div>
            </div>
          )}
          <div className="mt-4">
            <ImageUploader
              onChange={handleImageUrl}
              upload={(file) => mockUpload(file, 514, 980)}
              className={`image-slider-upload ${
                uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
              }`}
              value={uploadedImageArr}
              // disableUpload={isDisableUploadBtn}
              children={
                <Button
                  block
                  color="primary"
                  fill="solid"
                  size="middle"
                  className="w-full grow"
                  //   onClick={handleSaveBlockText}
                  // disabled={isDisableUploadBtn}
                >
                  {t("ss_builder_upload_button_label")}
                </Button>
              }
            />
          </div>
        </div>
        <div className=" bg-white mt-3 px-4 py-[13px]">
          <div>
            <span className="text-[15px] leading-[13px] text-[#666666]">
              {t("ss_builder_card_add_new_title_label")}
            </span>
            <Input
              placeholder={t("ss_builder_block_heading_placeholder")}
              onChange={handleChangeCardTitle}
              value={cardTitle}
              maxLength={CARD_TITLE_MAXIMUM}
            />
            <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
              cardTitle ? cardTitle.length : 0
            }/${CARD_TITLE_MAXIMUM}`}</div>
          </div>
          <Divider />
          <div>
            <span className="text-[15px] leading-[13px] text-[#666666]">
              {t("ss_builder_card_add_new_subtitle_label")}
            </span>
            <Input
              placeholder={t("ss_builder_block_heading_placeholder")}
              onChange={handleChangeCardSubtitle}
              value={cardSubtitle}
              maxLength={CARD_SUBTITLE_MAXIMUM}
            />
            <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
              cardSubtitle ? cardSubtitle.length : 0
            }/${CARD_SUBTITLE_MAXIMUM}`}</div>
          </div>
          <Divider />
          <div>
            <span className="text-[15px] leading-[13px] text-[#666666]">
              {t("ss_builder_card_add_new_label")}
            </span>
            <Input
              placeholder={t("ss_builder_block_heading_placeholder")}
              onChange={handleChangeCardLabel}
              value={cardLabel}
              maxLength={CARD_LABEL_MAXIMUM}
            />
            <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
              cardLabel ? cardLabel.length : 0
            }/${CARD_LABEL_MAXIMUM}`}</div>
          </div>
          <Divider />
          <div>
            <span className="text-[15px] leading-[13px] text-[#666666]">
              {t("ss_builder_card_add_new_description_label")}
            </span>
            <TextArea
              placeholder={t("ss_builder_block_description_placeholder")}
              onChange={handleChangeCardDescription}
              value={cardDescription}
              maxLength={CARD_DESCRIPTION_MAXIMUM}
            />
            <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
              cardDescription ? cardDescription.length : 0
            }/${CARD_DESCRIPTION_MAXIMUM}`}</div>
          </div>
          <Divider />
          <FormInput label={t("ss_builder_button_link_icon_origin_url_label")}>
            <LinkInput
              item={linkData}
              onChange={(item) => {
                onUpdateLinkData(item);
              }}
              onLoading={onAffLoading}
              onError={(errorMessage) => {
                setIsValidUrl(!!errorMessage);
              }}
            />
          </FormInput>
          {linkData.type === LinkTypeEnum.WEB && (
            <>
              <Divider />
              <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                <Checkbox
                  checked={typeof hasCardUrl !== "boolean" ? true : hasCardUrl}
                  onChange={handleCheckCardUrl}
                >
                  <div className="text-left">
                    {t("ss_builder_use_affiliate_link_label")}
                  </div>
                </Checkbox>
                <Popover
                  content={t("ss_builder_use_affiliate_link_description")}
                  placement="top-start"
                  mode="dark"
                  trigger="click"
                  stopPropagation={["click"]}
                >
                  <InformationIcon
                    className="ml-1 w-[14px] h-[14px]"
                    onClick={() => {}}
                    fillColor="#1890FF"
                  />
                </Popover>
              </div>
              <Divider />
              {!isEcommerce && (
                <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                  <Checkbox
                    checked={!!openNewTab}
                    onChange={handleChangeOpenNewTab}
                  >
                    <div className="text-left">
                      {t("ss_builder_open_new_tab_label")}
                    </div>
                  </Checkbox>
                  <Popover
                    content={t("ss_builder_open_new_tab_description")}
                    placement="top-start"
                    mode="dark"
                    trigger="click"
                  >
                    <InformationIcon
                      className="ml-1 w-[14px] h-[14px]"
                      onClick={() => {}}
                      fillColor="#1890FF"
                    />
                  </Popover>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={handleCancelCardAddNew}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={!isSaveButtonEnable}
            color={"primary"}
            onClick={handleSaveCard}
            loading={isLoadingBtn}
          >
            {t("ss_builder_editor_btn-save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardItemAddNew;
