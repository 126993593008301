import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { t } from "i18next";

export default function TruthOrDareFooter(props) {
  const { onUpdateData, block, setButtonStatus, setEditStatus } = props;
  const UID = `brand-gift-footer-${uuidv4()}`;
  useEffect(() => {
    if (!block.uid) {
      const defaultBrandGiftFooterData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.BRAND_GIFT_FOOTER,
        title: "Brand Gift Footer",
        content_attributes: {},
        style_attributes: {},
      };
      onUpdateData(defaultBrandGiftFooterData);
      setButtonStatus(false);
      setEditStatus(false);
    }
  }, []);
  return (
    <div className="bg-[#FFF] border solid border-[#EEEEEE] flex flex-col justify-center items-center py-3 mt-2">
      <img
        width={512}
        src={
          "https://i.ecomobi.com/ssp/mini-store/brand-gift/background-brand-gift.png"
        }
        alt=""
      />
      <div className="text-[#8C8C8C] text-xs">
        {t("ss_brand_gift_setting_float_icon_description")}
      </div>
    </div>
  );
}
