import { requestUploadImage } from "@share/api";

export const ImageGalleryMiddleware = async (data, extraData) => {
  if (data) {
    const imageArrItems =
      data.content_attributes && data.content_attributes.items;
    let response = null;
    try {
      const arrRequest =
        imageArrItems &&
        Array.isArray(imageArrItems) &&
        imageArrItems.map(async (ele) => {
          if (ele.image_base64 && ele.name) {
            const dataRequest = await requestUploadImage({
              ...extraData,
              content: ele.image_base64,
              name: ele.name,
            });
            return dataRequest;
          } else {
            delete ele.image_base64;
            delete ele.image_name;
            return ele;
          }
        });
      response = await Promise.all(arrRequest);
    } catch (error) {
      console.log(error);
    }
    if (response && response.every((x) => x !== undefined)) {
      const arrUrl = response.map((ele) => {
        return ele?.url ?? ele.image;
      });

      const newImageArrItems = imageArrItems.map((element, index) => {
        delete element.image_base64;
        delete element.image_name;
        return {
          ...element,
          image: arrUrl[index],
        };
      });
      const newData = {
        ...data,
        content_attributes: {
          ...data.content_attributes,
          items: newImageArrItems,
        },
      };
      return newData;
    }
  }
  return data;
};
