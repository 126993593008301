import { ActionInterface } from "@app/store";
import { select, takeEvery, put } from "redux-saga/effects";
import { setDynamicParams } from "../dynamicSlice";
import { addSavingElement, releaseSavingElement } from "../savingProcessSlice";

function* savingProcessSaga() {
  yield takeEvery(addSavingElement.type, function* (action: ActionInterface) {
    const savingProcess = yield select((state) => state.savingProcess);
    const isSaving = savingProcess && savingProcess.length > 0;
    yield put(setDynamicParams({ data: { isSaving: isSaving } }));
  });

  yield takeEvery(
    releaseSavingElement.type,
    function* (action: ActionInterface) {
      const savingProcess = yield select((state) => state.savingProcess);
      const isSaving = savingProcess && savingProcess.length > 0;
      yield put(setDynamicParams({ data: { isSaving: isSaving } }));
    }
  );
}

export default savingProcessSaga;
