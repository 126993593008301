// parse an input string, looking for any number of hexadecimal color
// values, possibly with whitespace or garbage in between.  Return an array of
// color values. Supports hex shorthand.
export function parseColorValues(colorValues) {
  var colorValuesArray = colorValues.match(
    /[0-9A-Fa-f]{6}/g
    // /\b[0-9A-Fa-f]{3}\b|[0-9A-Fa-f]{6}\b/g
  );
  if (colorValuesArray) {
    colorValuesArray = colorValuesArray.map(function (item) {
      if (item.length === 3) {
        var newItem = item.toString().split("");
        newItem = newItem.reduce(function (acc, it) {
          return acc + it + it;
        }, "");
        return newItem;
      }

      return item;
    });
  }

  return colorValuesArray; // this could be null if there are no matches
}

// pad a hexadecimal string with zeros if it needs it
export function pad(number, length) {
  var str = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
}

// convert a hex string into an object with red, green, blue numeric properties
// '501214' => { red: 80, green: 18, blue: 20 }
export function hexToRGB(colorValue) {
  return {
    red: parseInt(colorValue.substr(0, 2), 16),
    green: parseInt(colorValue.substr(2, 2), 16),
    blue: parseInt(colorValue.substr(4, 2), 16),
  };
}

// convert an integer to a 2-char hex string
// for sanity, round it and ensure it is between 0 and 255
// 43 => '2b'
export function intToHex(rgbint) {
  return pad(Math.min(Math.max(Math.round(rgbint), 0), 255).toString(16), 2);
}

// convert one of our rgb color objects to a full hex color string
// { red: 80, green: 18, blue: 20 } => '501214'
export function rgbToHex(rgb) {
  return intToHex(rgb.red) + intToHex(rgb.green) + intToHex(rgb.blue);
}

// shade one of our rgb color objects to a distance of i*10%
// ({ red: 80, green: 18, blue: 20 }, 1) => { red: 72, green: 16, blue: 18 }
export function rgbShade(rgb, i) {
  return {
    red: rgb.red * (1 - 0.1 * i),
    green: rgb.green * (1 - 0.1 * i),
    blue: rgb.blue * (1 - 0.1 * i),
  };
}

// tint one of our rgb color objects to a distance of i*10%
// ({ red: 80, green: 18, blue: 20 }, 1) => { red: 98, green: 42, blue: 44 }
export function rgbTint(rgb, i) {
  return {
    red: rgb.red + (255 - rgb.red) * i * 0.1,
    green: rgb.green + (255 - rgb.green) * i * 0.1,
    blue: rgb.blue + (255 - rgb.blue) * i * 0.1,
  };
}

// take a hex color string and produce a list of 10 tints or shades of that color
// shadeOrTint should be either `rgbShade` or `rgbTint`, as defined above
// this allows us to use `calculate` for both shade and tint
export function calculateShade(colorValue, shade) {
  var color = hexToRGB(colorValue);
  var shadeValues = [];

  for (var i = 0; i <= 8; i++) {
    if(i % 2 == 0){
        shadeValues.push("#"+rgbToHex(shade(color, i)));
    }
    
  }
  return shadeValues;
}
export function calculateTint(colorValue, tint) {
    var color = hexToRGB(colorValue);
    var shadeValues = [];
  
    for (var i = 1; i <= 9; i++) {
        if(i % 2 == 0 || i == 9){
            shadeValues.push("#"+rgbToHex(tint(color, i)));
        }
     
    }
    return shadeValues;
  }

// given a color value, return an array of ten shades in 10% increments
export function calculateShades(colorValue) {
  return calculateShade(colorValue, rgbShade);
}

// given a color value, return an array of ten tints in 10% increments
export function calculateTints(colorValue) {
  return calculateTint(colorValue, rgbTint);
}
