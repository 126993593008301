import { useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { MOVE } from "../../constants";
import { Image, Toast } from "antd-mobile";
import { CheckCircleIcon } from "@share/icons";
import Item from "../Item";
import { BrandGiftSelectDetailModel } from "@features/mini-store/setting/interface";

const ListBrand = (props) => {
  const {arrBrand, setArrBrand, onUpdateData, listBrandTitle} = props;
  const { t } = useTranslation();

  const [popoverActive, setPopoverActive] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const newList = reorder(arrBrand, result.source.index, result.destination.index)
    setArrBrand([...newList])
  }

  const handleMove = (id, move) => {
    const result = Array.from(arrBrand.filter(Boolean))
    const findIndex = result.findIndex((item: any) => item.id === id)
    const [removed] = result.splice(findIndex, 1)
    if (move === MOVE.UP_FIRST) result.splice(0, 0, removed)
    else result.splice(result.length, 0, removed)
    setPopoverActive(null)
    setArrBrand([...result])
  }

  const handleHide = (id) => {
    const result: Array<BrandGiftSelectDetailModel> = Array.from(arrBrand.filter(Boolean))
    const findIndex = result.find((item) => item.id === id)
    findIndex.brand_enable = findIndex.brand_enable === 1 ? 0 : 1
    setArrBrand([...result])
    setPopoverActive(null)
    Toast.show({
      icon:  <CheckCircleIcon className="text-center inline-block w-[30px] h-[30px]" fillColor="#00B578"/>,
      content: (
        <div className="text-center">
          {findIndex.brand_enable !== 1
            ? t("ss_brand_gift_disable_deal_successfully")
            : t("ss_brand_gift_enable_deal_successfully")}
        </div>
      ),
    });
  }

  const emtpy = () => {
    return (
      <div className="flex flex-col text-center items-center justify-center mt-4">
        <Image src="https://i.ecomobi.com/ssp/passio-page/empty_digital_product.png"/>
        <div className="text-[#333333] text-lg leading-6 pb-[2px]">{t("ss_brand_gift_empty_title")}</div>
        <div className="text-[#8C8C8C] text-xs leading-4"> {t("ss_brand_gift_list_empty_content")}</div>
      </div>
    )
  }

  return (
    <>
      {(arrBrand && arrBrand?.length > 0) ? (
      <div className="flex flex-col gap-2 px-3 py-3">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='droppable'>
            {droppableProvided => (
              <div ref={droppableProvided.innerRef} className={`flex flex-col gap-2`}>
                {arrBrand.filter(Boolean).map((item, index: number) => (
                  <Draggable key={item.id} draggableId={item?.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{ ...provided.draggableProps.style, opacity: snapshot.isDragging ? 0.8 : 1, }}
                      >
                        <Item
                          item={item}
                          handleMove={handleMove}
                          popoverActive={popoverActive}
                          setPopoverActive={setPopoverActive}
                          number={index}
                          lastList={arrBrand.filter(Boolean).length -1}
                          handleHide={handleHide}
                          arr={arrBrand}
                          setArr={setArrBrand}
                          onUpdateData={onUpdateData}
                          category={false}
                        />
                      </div>
                    )}
                    </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      ) : emtpy()}
    </>
  );
};

export default ListBrand;