import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseUpdateDataInterface } from "@share/api";
import { select, SelectEffect } from "redux-saga/effects";

const initialState: SavingItem[] = [];

export interface SavingItem {
  id: string;
  executer: (...args: any[]) => Promise<any>;
  getArguments: (baseData: BaseUpdateDataInterface) => any[];
  getVersionFromResponse?: (response: any) => Promise<number>;
  isResponseValid?: (response: any) => Promise<boolean>;
}

export const savingItemSlice = createSlice({
  name: "savingItem",
  initialState,
  reducers: {
    pushSavingItem: (state, action: PayloadAction<{ item: SavingItem }>) => {
      const { payload } = action;
      return [...state, payload.item];
    },
    releaseSavingItem: (state, action: PayloadAction<{ id: string }>) => {
      return state.filter((item) => item.id !== action.payload.id);
    },
    executeSavingItem: (
      state,
      action: PayloadAction<{ item: SavingItem }>
    ) => {},
    callBackSavingItem: (
      state,
      action: PayloadAction<{ id: string; data: any }>
    ) => {},
  },
});

export const {
  pushSavingItem,
  releaseSavingItem,
  executeSavingItem,
  callBackSavingItem,
} = savingItemSlice.actions;
export default savingItemSlice.reducer;
