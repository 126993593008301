import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { requestGetListVirtualGift } from "@share/api";
import { InboxIcon } from "@share/icons";
import { DotLoading, Image, InfiniteScroll, List } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

type Props = {};

const GiftComponent = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    handleCancelBlock,
  } = props;
  const { t } = useTranslation();
  const UID = `virtual-gift-${uuidv4()}`;

  const count = useRef(1);

  const dispatch = useAppDispatch();

  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const user = useAppSelector((state) => state.user);

  const userId = user?.kol?._id;
  const countryCode = user?.kol?.country;
  const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const KEY = edittingBlock && edittingBlock.KEY;
  const tabUid = edittingBlock && edittingBlock.tab_uid;
  const positionSlug = edittingBlock && edittingBlock.position_uid;

  const defaultValueEditGift =
    edittingBlockData && edittingBlockData.uid ? edittingBlockData : null;

  const titleBlockDefault = "Virtual Gift";

  const [virtualGiftArr, setVirtualGiftArr] = useState([]);
  const [hasMoreIcon, setHasMoreIcon] = useState(true);
  useEffect(() => {
    loadMoreIcon();
  }, []);

  useEffect(() => {
    if (!block.uid) {
      const defaultGiftData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.GIFT,
        title: titleBlockDefault,
        content_attributes: {
          //   items: virtualGiftArr,
        },
        style_attributes: {},
      };
      onUpdateData(defaultGiftData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          //   items: virtualGiftArr,
        },
        style_attributes: {},
      });
    }
  }, []);

  const isDisableBtnSave = useMemo(() => {
    if (KEY === "add-new-block" && virtualGiftArr.length > 0) {
      return false;
    } else return true;
  }, [virtualGiftArr, KEY]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("ss_builder_loading_label")}</span>
            <DotLoading />
          </>
        ) : null}
      </>
    );
  };

  async function loadMoreIcon() {
    if (count.current !== 1) {
      await sleep(1000);
    }
    let response = null;
    // const page = count.current;
    const data = {
      page: count.current,
      per_page: 10,
      country_code: countryCode,
      user_id: userId,
    };
    try {
      response = await requestGetListVirtualGift(data);
    } catch (error) {
      console.log(error);
    }
    if (response && /^20[0-9]$/.test(response.status.toString())) {
      const virtualGiftData = response?.data?.data?.items.map((item) => ({
        id: item.id,
        name: item.name,
        description: item.description,
        image_url: item?.thumbnails[0] ? item?.thumbnails[0].url : "",
      }));
      setVirtualGiftArr((value) => [...value, ...virtualGiftData]);
      if (Array.isArray(virtualGiftData) && virtualGiftData.length === 0) {
        setHasMoreIcon(false);
      }
    }
    count.current = count.current + 1;
  }

  return (
    <div>
      {/* <Divider /> */}
      <div className=" px-4 py-[13px]">
        <div className="text-[15px] text-[#666666]">
          {t("ss_builder_virtual_gift_list_title")}
        </div>
        {virtualGiftArr && virtualGiftArr.length > 0 ? (
          <div className="flex flex-col gap-3">
            {virtualGiftArr.map((ele, index) => (
              <List
                className=""
                key={ele.id}
              >
                <List.Item
                // prefix={<DragIcon className="" />}
                //   extra={<MoreElement />}
                // onClick={() => {}}
                >
                  <div className="flex flex-row items-center gap-3">
                    <Image
                      src={ele.image_url}
                      width={40}
                      height={40}
                      fit="cover"
                    />
                    <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
                      <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                        {ele.name}
                      </div>
                      <div className="text-xs leading-4 font-normal text-[#8C8C8C] text-ellipsis whitespace-nowrap overflow-hidden">
                        {ele.description}
                      </div>
                    </div>
                  </div>
                </List.Item>
              </List>
            ))}
            <InfiniteScroll
              loadMore={loadMoreIcon}
              hasMore={hasMoreIcon}
            >
              <InfiniteScrollContent hasMore={hasMoreIcon} />
            </InfiniteScroll>
          </div>
        ) : (
          <div className="flex flex-col text-center items-center justify-center">
            <span className="inline-block">
              <InboxIcon className="align-middle" />
            </span>
            <div className="text-[#333333] text-lg leading-6">
              {t("ss_builder_affiliate_product_list_empty_title")}
            </div>
            <div className="text-[#8C8C8C] text-xs leading-4">
              {t("ss_builder_affiliate_product_list_empty_content")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GiftComponent;
