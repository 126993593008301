import { Divider, Image, Selector, Slider } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DisplayColor from "@share/components/DisplayColor";
import PopupComponent from "@share/components/PopupComponent";
import { ColorPickerV2 } from "@features/setting/components/ThemeSetting/ColorPicker";

export enum DIVIDER_LAYOUT_TYPE_ENUM {
  SOLID = "solid",
  DASHED = "dashed",
  DOT_STROKES = "dot_strokes",
}

const DefaultsLayoutSetting = (props) => {
  const {
    divider_type,
    divider_thickness,
    divider_color,
    onUpdateSettingData,
  } = props;
  const { t } = useTranslation();

  const [isOpenColorPicker, setIsOpenColorPicker] = useState(false);

  const layoutTypeOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/divider-solid-type.svg"
        />
      ),
      value: DIVIDER_LAYOUT_TYPE_ENUM.SOLID,
      description: t("ss_builder_solid_type_label"),
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/divider-dashed-type.svg"
        />
      ),
      value: DIVIDER_LAYOUT_TYPE_ENUM.DASHED,
      description: t("ss_builder_dashed_type_label"),
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/divider-dot-strokes-type.svg"
        />
      ),
      value: DIVIDER_LAYOUT_TYPE_ENUM.DOT_STROKES,
      description: t("ss_builder_dot_strokes_type_label"),
    },
  ];

  return (
    <>
      <div>
        <div className="text-base mb-4">{t("ss_builder_type_label")}</div>
        <Selector
          columns={3}
          options={layoutTypeOptions}
          onChange={(val) => {
            if (val.length > 0) onUpdateSettingData("divider_type", val[0]);
          }}
          value={[divider_type]}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      </div>
      <Divider
        style={{
          margin: "12px 0px",
        }}
      />
      <div>
        <div className="text-base mb-4">
          {t("ss_builder_divider_thickness_label")}
        </div>
        <Slider
          onChange={(val) =>
            onUpdateSettingData("divider_thickness", parseInt(val.toString()))
          }
          ticks={true}
          marks={{
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10,
          }}
          min={1}
          step={1}
          max={10}
          value={divider_thickness}
        />
      </div>
      <Divider
        style={{
          margin: "12px 0px",
        }}
      />
      <div>
        <div className="text-base mb-4">
          {t("ss_builder_divider_color_label")}
        </div>
        <DisplayColor
          backgroundColor={divider_color}
          onClickOpenColorPicker={setIsOpenColorPicker}
        />
      </div>
      <PopupComponent
        visible={isOpenColorPicker}
        onMaskClick={() => setIsOpenColorPicker(false)}
        title={t("ss_builder_color_picker_panel_title")}
        onClose={() => setIsOpenColorPicker(false)}
      >
        <ColorPickerV2
          defaultColor={divider_color}
          onCancel={() => setIsOpenColorPicker(false)}
          onColorSelected={(data) => onUpdateSettingData("divider_color", data)}
        />
      </PopupComponent>
    </>
  );
};

export default DefaultsLayoutSetting;
