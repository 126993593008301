import React from "react";
import { Button, Popup, SafeArea } from "antd-mobile";
import cls from "classnames";
import { ClosePopupIcon } from "@share/icons";
import { MAX_WIDTH_CONTENT_LAYOUT } from "@share/configs/const";
import ButtonConfirmPopup from "../ButtonConfirmPopup";
import gaEvents, {CATEGORY_CONFIG} from "@share/lib/ga-events";
import {useAppSelector} from "@app/hooks";

export default function PopupComponent({
  visible,
  onMaskClick,
  children,
  position = "bottom",
  classNameBody = "",
  style = {},
  title = "",
  showConfirmButton = false,
  onConfirmButton,
  confirmButtonLabel = "",
  disabledConfirmButton,
  onClose,
}: {
  visible: boolean;
  onMaskClick?: () => void;
  onClose?: () => void;
  [x: string]: any;
}) {

  return (
    <Popup
      visible={visible}
      onMaskClick={onMaskClick}
      position={position}
      bodyClassName="adm-popup-body-position-bottom-panel"
      bodyStyle={{
        backgroundColor: "none",
      }}
    >
      {visible && (
        <div
          className={cls(
            "bg-[#fff] max-width-content-layout m-auto relative h-full max-h-screen",
            `${classNameBody}`
          )}
          style={{
            borderRadius: "8px 8px 0px 0px",
            ...style,
          }}
        >
          <div className="popup-header">
            {title && <div className="title">{title}</div>}
            <span
              className="popup-close"
              onClick={() => {
                onClose();
              } }
            >
              <ClosePopupIcon />
            </span>
          </div>
          <div className="popup-body overflow-y-auto">{children}</div>
          {showConfirmButton && (
            <ButtonConfirmPopup
              onConfirm={onConfirmButton}
              title={confirmButtonLabel}
              isDisable={disabledConfirmButton}
            />
          )}
        </div>
      )}
    </Popup>
  );
}
