import React from "react";

interface FormInputPropsInterface {
  label: string;
  isRequire?: boolean;
  children: React.ReactNode;
}

export default function FormInput(props: FormInputPropsInterface) {
  const { label, isRequire = false } = props;
  return (
    <div>
      <div className="text-[15px] text-[#666666]">
        {isRequire ? <span className="text-red-600">*</span> : <></>}
        {label}
      </div>
      {props.children}
    </div>
  );
}
