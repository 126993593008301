import { useEffect, useMemo, useState } from "react";
import { requestGetDataFromUrl } from "../api";
import { AT_CATEGORY_REGEX, DOMAIN_REGEX, FILE_NAME_REGEX } from "../constants";

export const useGetJsonFileURL = (url: string, isInvalidURL: boolean) => {
  const [urlValue, setUrlValue] = useState(null);
  const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchData = async (url: string) => {
      let response = null;
      try {
        response = await requestGetDataFromUrl(url);
      } catch (error) {
        console.error("Error fetching HTML:", error);
        setIsLoading(false);
        setIsError(true);
      }
      if (response?.data) {
        const htmlValue = response.data;
        const validString = htmlValue.match(FILE_NAME_REGEX);
        console.log("validString", validString);
        
        const categoryMatch = htmlValue.match(AT_CATEGORY_REGEX);
        const categoryObj = JSON.parse(categoryMatch[0]?.split("=")[1]);
        const domainMatch = url.match(DOMAIN_REGEX);
        const subdomain = domainMatch[1]?.split(".")[0];
        const matches = validString[0]?.match(/([^\\\/]*?\.json)/);
        const fileName = matches && matches[1] ? matches[1] : null;
        if (fileName) {
          const jsonUrl = `${url.replace(
            /\/$/,
            ""
          )}/json/profile/${subdomain}/${fileName}`;
          setUrlValue(jsonUrl);
          setIsLoading(false);
        }
        if (categoryObj) {
          setCategories(categoryObj);
        }
        setIsError(false);
      }
    };
    if (url && !isInvalidURL) {
      setIsLoading(true);
      const newUrl = url?.replace("https", "http");
      fetchData(newUrl);
    }
  }, [url, isInvalidURL]);

  return { urlValue, isLoading, categories, isError };
};
