import { BLOCK_TYPE } from "@features/block/block.conf";
import { Block, Position } from "@features/main/store/interface";

interface BlockQuery {
  id?: string;
  uid?: string;
  positionSlug: string;
  tabUid?: string;
}

export function findBlock(positions: Position[], query: BlockQuery): Block {
  const { id, uid, positionSlug, tabUid } = query;
  if (!id && !uid) {
    throw "Uid or id is required";
  }
  if (positionSlug == "body" && !tabUid) {
    throw "Tab uid is required";
  }
  let blockList = [];
  if (positionSlug === "body") {
    blockList = getBlockListInTab(tabUid, positions);
  } else {
    blockList = positions.find(
      (position) => position.slug == positionSlug
    ).block_list;
  }
  if (id) {
    return blockList.find((block) => block.id === id);
  } else {
    return blockList.find((block) => block.uid === uid);
  }
}

function getBlockListInTab(tabUid, positions: Position[]) {
  const tabs = positions
    .find((position) => position.slug == "body")
    .block_list.find((item) => item.block_type == BLOCK_TYPE.TAB);
  if (!tabs) {
    throw "Block tab not found";
  }
  const tabList = tabs?.content_attributes?.tabs as any[];
  if (!tabList) {
    throw "Tab list not found";
  }
  const tab = tabList.find((tab) => tab.uid == tabUid);
  if (!tab) {
    throw "Tab not found";
  }
  const blockList = tab?.block_list as Block[];
  if (!blockList) {
    throw "Block list not found";
  }
  return blockList;
}

export function getParentId(positions: Position[], query: BlockQuery) {
  const { positionSlug, tabUid } = query;
  const tabs = positions
    .find((position) => position.slug == "body")
    .block_list.find((item) => item.block_type == BLOCK_TYPE.TAB);
  if (!tabs) {
    throw "Block tab not found";
  }
  if (!positionSlug) {
    throw "Position Slug not found";
  }
  if (positionSlug === "header" || positionSlug === "footer") {
    return "0";
  } else {
    return tabs?.id;
  }
}
