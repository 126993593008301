import {
  BroomIcon,
  BroomLockIcon,
  CheckCircleIcon,
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  FavouriteIcon,
  FolderArrowRightIcon,
  HotIcon,
  MoreIcon,
  MoveToBottomIcon,
  MoveToTopIcon,
  SaleIcon,
} from "@share/icons";
import { List, Popover } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { ProductInterface } from "@features/main/store/interface";
import { AFFILIATE_SETTING_TOUR_CLASS_NAME } from "../AffiliateProductTourOpening/ProductSettingTour/setting-tour-config";
import { useAppSelector } from "@app/hooks";
import { DOMAIN_RESIZE_IMG, LABEL_PRODUCT } from "@share/configs/const";
import AntImage from "@share/components/AntImage";
import { useToast } from "@share/hooks/toast";
import { ModalIconConfig, useModal } from "@share/hooks/modal";
import { useAffiliateContext } from "@features/block/block-editor/AffiliateProductGroup/context";
import { getHorizontalResizeUrl } from "@share/helper/image";

interface ProductListItemsProps {
  productData: ProductInterface;
  index: number;
  isFinalItem?: boolean;
  isOnlyItem?: boolean;
  isOnlyProductGroup?: boolean;
  onRemoveProductItem: any;
  onDisableEnableProduct: any;
  callbackOpenEditProduct: any;
  onMoveProductItem: any;
  onMoveToTopProductItem: any;
  onMoveToBottomProductItem: any;
  onCleanupLockProduct: any;
}

export enum ActionEnum {
  VISIBLE_HIDE_PRODUCT = "visible-hide-product",
  DELETE_PRODUCT = "delete-product",
  MOVE_PRODUCT = "move-product",
  MOVE_TO_TOP = "move-to-top",
  MOVE_TO_BOTTOM = "move-to-bottom",
  CLEANUP_LOCK_PRODUCT = "cleanup-lock-product",
  EDIT_PRODUCT = "edit-product",
}

const ProductListItems = (props: ProductListItemsProps) => {
  const { t } = useTranslation();
  const { frameData, usingFrame } = useAffiliateContext();
  const {
    index,
    productData,
    isFinalItem,
    isOnlyItem,
    isOnlyProductGroup,
    onRemoveProductItem,
    onDisableEnableProduct,
    callbackOpenEditProduct,
    onMoveProductItem,
    onMoveToTopProductItem,
    onMoveToBottomProductItem,
    onCleanupLockProduct,
  } = props;

  const { SuccessToast } = useToast();
  const { ConfirmModal } = useModal();

  const isEnable = !!(productData?.enable === 1);
  const isCleanupLock = !!(productData?.is_cleanup_lock === 1);
  const isOpenAffiliateSettingTour = useAppSelector(
    (state) => state?.dynamicConfig?.isOpenAffiliateSettingTour
  );
  const popoverContainerRef = useRef(null);
  const popoverOptionRef = useRef(null);
  const [forceVisiblePopoverProduct, setForceVisiblePopoverProduct] =
    useState(false);

  useEffect(() => {
    const castWindow = window as any;
    if (index === 0) {
      castWindow.triggerVisibleAffiliatePopoverProductDetail =
        setForceVisiblePopoverProduct;
      () => {
        if (castWindow.triggerVisibleAffiliatePopoverProductDetail) {
          castWindow.triggerVisibleAffiliatePopoverProductDetail = undefined;
          setForceVisiblePopoverProduct(false);
        }
      };
    }
  }, []);

  const labelProduct = productData?.label_product ?? [];
  const actions: Action[] = [
    {
      key: ActionEnum.VISIBLE_HIDE_PRODUCT,
      icon: !isEnable ? (
        <EyeHiddenIcon className="" />
      ) : (
        <EyeVisibleIcon className="" />
      ),
      text: !isEnable
        ? t("ss_builder_show_product_label")
        : t("ss_builder_hide_product_label"),
    },
    {
      key: ActionEnum.DELETE_PRODUCT,
      icon: <DeleteIcon className="" />,
      text: t("ss_builder_delete_product_label"),
    },
    {
      key: ActionEnum.MOVE_PRODUCT,
      icon: (
        <FolderArrowRightIcon
          className=""
          fillColor="white"
        />
      ),
      text: t("ss_builder_move_product_label"),
    },
    {
      key: ActionEnum.MOVE_TO_TOP,
      icon: (
        <MoveToTopIcon
          className=""
          fillColor="white"
        />
      ),
      text: t("ss_builder_move_to_top_label"),
    },
    {
      key: ActionEnum.MOVE_TO_BOTTOM,
      icon: (
        <MoveToBottomIcon
          className=""
          fillColor="white"
        />
      ),
      text: t("ss_builder_move_to_bottom_label"),
    },
    {
      key: ActionEnum.CLEANUP_LOCK_PRODUCT,
      icon: !isCleanupLock ? (
        <BroomLockIcon
          className=""
          fillColor="white"
        />
      ) : (
        <BroomIcon
          className=""
          fillColor="white"
        />
      ),
      text: !isCleanupLock
        ? t("ss_builder_affiliate_product_cleanup_lock_label")
        : t("ss_builder_affiliate_product_cleanup_unlock_label"),
    },
  ];

  const actionsFilter = useMemo(() => {
    let actionsDefault = actions;
    if (index === 0 || isOnlyItem) {
      actionsDefault = actionsDefault.filter(
        (x) => x.key !== ActionEnum.MOVE_TO_TOP
      );
    }
    if (isFinalItem || isOnlyItem) {
      actionsDefault = actionsDefault.filter(
        (x) => x.key !== ActionEnum.MOVE_TO_BOTTOM
      );
    }
    if (isOnlyProductGroup) {
      actionsDefault = actionsDefault.filter(
        (x) => x.key !== ActionEnum.MOVE_PRODUCT
      );
    }
    return actionsDefault;
  }, [index, isFinalItem, isOnlyItem, isOnlyProductGroup, actions]);

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case ActionEnum.VISIBLE_HIDE_PRODUCT:
        handleEnableDisableProduct();
        break;
      case ActionEnum.DELETE_PRODUCT:
        confirmRemoveProduct();
        break;
      case ActionEnum.MOVE_PRODUCT:
        handleMoveProduct();
        break;
      case ActionEnum.MOVE_TO_TOP:
        handleMoveToTop();
        break;
      case ActionEnum.MOVE_TO_BOTTOM:
        handleMoveToBottom();
        break;
      case ActionEnum.CLEANUP_LOCK_PRODUCT:
        handleCleanupLockProduct();
        break;
      default:
      // to do
    }
  };

  const handleCleanupLockProduct = () => {
    const cleanupLockStatus = !isCleanupLock ? 1 : 2;
    onCleanupLockProduct(index, cleanupLockStatus);
    SuccessToast(
      isCleanupLock
        ? t("ss_builder_cleanup_unlock_product_successfully_label")
        : t("ss_builder_cleanup_lock_product_successfully_label")
    );
  };

  const handleMoveToTop = () => {
    onMoveToTopProductItem(productData.uid);
  };

  const handleMoveToBottom = () => {
    onMoveToBottomProductItem(productData.uid);
  };

  const handleMoveProduct = () => {
    onMoveProductItem(productData.uid);
  };

  const confirmRemoveProduct = () => {
    const onConfirm = () => {
      onRemoveProductItem(index);
      SuccessToast(t("ss_builder_delete_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_confirm_delete_product_modal_title"),
      icon: ModalIconConfig.INFO_PRIMARY_CIRCLE,
      description: t("ss_builder_confirm_delete_product_modal_content"),
      confirmText: t("ss_builder_confirm_delete_product_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const handleEditProduct = (event) => {
    if (event?.defaultPrevented) {
      return;
    }
    callbackOpenEditProduct(productData);
  };

  const handleEnableDisableProduct = () => {
    const productStatus = !isEnable ? 1 : 2;
    onDisableEnableProduct(index, productStatus);
    SuccessToast(
      isEnable
        ? t("ss_builder_disable_product_successfully_label")
        : t("ss_builder_enable_product_successfully_label")
    );
  };

  const handleClickMoreIcon = (event) => {
    event.preventDefault();
    // event.stopPropagation();
  };
  const labelProductIcon = () => {
    return (
      labelProduct && (
        <div className="flex items-center gap-1 mt-1">
          {labelProduct.includes(LABEL_PRODUCT.HOT) && (
            <span className="label-product-icon-list">
              <HotIcon
                width={12}
                height={12}
              />
            </span>
          )}
          {labelProduct.includes(LABEL_PRODUCT.SALE) && (
            <span className="label-product-icon-list">
              <SaleIcon
                width={12}
                height={12}
              />
            </span>
          )}
          {labelProduct.includes(LABEL_PRODUCT.FAVOURITE) && (
            <span className="label-product-icon-list">
              <FavouriteIcon
                width={12}
                height={12}
              />
            </span>
          )}
        </div>
      )
    );
  };

  const visibleStep7Checking = useMemo(() => {
    let value = {};
    if (isOpenAffiliateSettingTour) {
      value =
        forceVisiblePopoverProduct && index === 0
          ? { visible: true }
          : { visible: false };
    }
    return value;
  }, [isOpenAffiliateSettingTour, forceVisiblePopoverProduct, index]);

  const imageResizing = useMemo(() => {
    if (
      !productData.image_base64 &&
      productData.image?.startsWith("https://passio-prod")
    ) {
      return `${DOMAIN_RESIZE_IMG}/80x80/${
        productData.image?.split("/passio-prod/")[1]
      }`;
    } else return productData.image;
  }, [productData.image, productData.image_base64]);

  return (
    <div
      ref={popoverContainerRef}
      id={index === 0 && AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_7}
    >
      <List className="">
        <List.Item
          prefix={<DragIcon className="" />}
          extra={
            <div className="flex flex-row items-center gap-[15px]">
              <div
                className=""
                onClick={handleClickMoreIcon}
              >
                <Popover.Menu
                  className={
                    index === 0 && AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_7
                  }
                  mode="dark"
                  actions={actionsFilter}
                  placement="left"
                  trigger="click"
                  stopPropagation={["click"]}
                  getContainer={() => popoverContainerRef.current}
                  onAction={(item: Action) => handleClickPopoverItem(item)}
                  ref={popoverOptionRef}
                  {...visibleStep7Checking}
                >
                  <MoreIcon className="mr-3" />
                </Popover.Menu>
              </div>
            </div>
          }
          onClick={handleEditProduct}
        >
          <div className="flex items-center gap-[6px] flex-row">
            <div className="relative">
              <AntImage
                src={imageResizing}
                width={40}
                height={40}
                fit="cover"
                style={{
                  outline: "rgba(0, 0, 0, 0.16) solid 1px",
                  outlineOffset: "-1px",
                  borderRadius: "4px",
                  opacity: !isEnable ? "40%" : "",
                }}
              />
              {usingFrame && frameData && (
                <div
                  className="absolute w-10 h-10 top-0 left-0"
                  style={{
                    background: `url("${getHorizontalResizeUrl(
                      frameData.urlEU,
                      160
                    )}") no-repeat left bottom/ contain`,
                    aspectRatio: "1/1",
                    borderRadius: "4px",
                  }}
                ></div>
              )}
              {isCleanupLock && (
                <Popover
                  content={t(
                    "ss_builder_affiliate_product_clean_up_popover_content"
                  )}
                  trigger="click"
                  placement="bottom"
                  mode="dark"
                  stopPropagation={["click"]}
                  getContainer={() => popoverContainerRef.current}
                >
                  <div
                    className="absolute top-[-10px] left-[-10px] cursor-pointer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="relative rounded-ful">
                      <div className="p-3 rounded-full bg-black opacity-80"></div>
                      <span className="absolute z-[2] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <BroomLockIcon
                          className="w-[14px] h-[14px]"
                          fillColor="#FFFFFF"
                        />
                      </span>
                    </div>
                  </div>
                </Popover>
              )}
            </div>
            <div
              className={cls(
                "flex flex-col w-[30vw] md:w-[10vw] grow",
                !isEnable && "opacity-40"
              )}
            >
              {productData?.title ? (
                <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                  {productData.title}
                </div>
              ) : (
                <div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm text-[#FF3141]">
                  {t("ss_builder_product_title_error")}
                </div>
              )}
              {productData && productData.use_aff_url === 1 ? (
                <div className="flex flex-row gap-1">
                  <div>
                    <CheckCircleIcon
                      className="w-4 h-4"
                      fillColor="#00B578"
                    />
                  </div>
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4 text-[#8C8C8C]">
                    {t("ss_builder_product_link_wrapped_label")}
                  </div>
                </div>
              ) : (
                <span className="text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4 text-[#8C8C8C]">
                  {productData.origin_link}
                </span>
              )}
              {labelProductIcon()}
            </div>
          </div>
        </List.Item>
      </List>
    </div>
  );
};

export default ProductListItems;
