import { ImageCover } from "@features/main/store/interface";
import { requestValidateUrl } from "@share/api";
import {
  CheckCircleIcon,
  IconIsChecked,
  IconIsNotChecked,
  ImageIcon,
  InformationIcon,
  InformationTriangleIcon,
  TrashIcon,
} from "@share/icons";
import { mockUpload, validURL } from "@share/lib";
import {
  Button,
  Checkbox,
  Divider,
  ImageUploader,
  Modal,
  Popover,
  Toast,
} from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import UploadImage from "@share/components/UploadImage";
import FormInput from "@features/block/components/FormInput";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import { checkOpenNewTab } from "@share/helper/validator";

type Props = {
  imageRatio: string;
  callBackCancelImageCoverAddNew: any;
  handleSaveImageCover: any;
  imageCoverEditData: ImageCover;
  indexImageCoverEdit: number;
};

const CoverImageAddNew = (props: Props) => {
  const {
    imageRatio,
    callBackCancelImageCoverAddNew,
    handleSaveImageCover,
    imageCoverEditData,
    indexImageCoverEdit,
  } = props;
  const { t } = useTranslation();

  const UID = `cover-image-${uuidv4()}`;

  const originUrlDefault = imageCoverEditData?.origin_url;
  const affiliateUrlDefault = imageCoverEditData?.target_url;
  const valueUrlImageDefault = imageCoverEditData?.image;
  const hasAffiliateUrlDefault = imageCoverEditData?.use_aff_url
    ? !!(imageCoverEditData?.use_aff_url === 1)
    : true;
  const defaultLinkData = imageCoverEditData?.link_data || {
    type: LinkTypeEnum.WEB,
    value: originUrlDefault,
  };
  const openInNewTabDefault = imageCoverEditData?.open_new_tab || false;

  const [valueUrlImage, setValueUrlImage] = useState(valueUrlImageDefault);
  const [valueNameImage, setValueNameImage] = useState("");
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [originUrl, setOriginUrl] = useState(originUrlDefault || "");
  const [affiliateUrl, setAffiliateUrl] = useState(affiliateUrlDefault || "");
  const [hasAffiliateUrl, setHasAffiliateUrl] = useState(
    hasAffiliateUrlDefault
  );
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [warningCode, setWarningCode] = useState(null);
  const [isDisableBtnSave, setIsDisableBtnSave] = useState(false);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [linkData, setLinkData] = useState<LinkDataInterface>(defaultLinkData);

  const [isEcommerce, setIsEcommerce] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);

  const checkEcomerce = (data) => {
    if (data) {
      try {
        const ecommerceOrSocial = new URL(data);
        setIsEcommerce(checkOpenNewTab(ecommerceOrSocial));
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    if (linkData && linkData.type == LinkTypeEnum.WEB) {
      checkEcomerce(linkData.target_url);
    } else if (originUrl) checkEcomerce(originUrl);
  }, [originUrl, linkData]);

  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
  };
  useEffect(() => {
    if (valueUrlImageDefault) {
      setValueUrlImage(valueUrlImageDefault);
    }
    if (originUrlDefault) {
      setOriginUrl(originUrlDefault);
    }
    if (affiliateUrlDefault) {
      setAffiliateUrl(affiliateUrlDefault);
    }
    if (hasAffiliateUrlDefault) {
      setHasAffiliateUrl(hasAffiliateUrlDefault);
    }
  }, [
    valueUrlImageDefault,
    originUrlDefault,
    affiliateUrlDefault,
    hasAffiliateUrlDefault,
  ]);

  const validateOriginUrl = (data) => {
    if (data) {
      const validateUrl = async () => {
        let response = null;
        try {
          response = await requestValidateUrl(data);
        } catch (error) {
          console.log(error);
        }
        if (response) {
          setIsLoadingBtn(false);
          setIsDisableBtnSave(false);
          const isSuccess = response?.data?.success;
          if (isSuccess) {
            const isAccepted = response?.data?.data?.accepted;
            const targetUrl = response?.data?.data?.target_url;
            setAffiliateUrl(targetUrl);
            if (!isAccepted) {
              const warningCode = response?.data?.data?.warning_code;
              setWarningCode(warningCode);
              setErrorCode(null);
            } else {
              setWarningCode(null);
            }
          } else {
            const errorCode = response?.data?.error_code;
            setErrorCode(errorCode);
            setWarningCode(null);
          }
        }
      };
      const isCheckUrl = validURL(data);
      if (isCheckUrl) {
        setIsLoadingBtn(true);
        validateUrl();
        setIsValidUrl(false);
      } else {
        setIsValidUrl(true);
      }
    } else {
      setErrorCode(null);
      setIsValidUrl(false);
      setIsDisableBtnSave(false);
    }
    setWarningCode(null);
  };

  const handleDeleteImage = () => {
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_image_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm-delete_image_label")}
        </div>
      ),
      confirmText: t("ss_builder_delete_image_label"),
      cancelText: t("ss_builder_cancel-delete_image_label"),
      onConfirm: () => {
        setValueUrlImage(null);
        setUploadedImageArr([]);
        Toast.show({
          icon: (
            <CheckCircleIcon
              className="text-center inline-block w-[30px] h-[30px]"
              fillColor="#00B578"
            />
          ),
          content: (
            <div className="text-center">
              {t("ss_builder_delete_image_successfully_label")}
            </div>
          ),
        });
      },
      onCancel: () => {
        return;
      },
    });
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
    }
  };

  const handleSaveImage = () => {
    const defaultImageData = {
      uid: UID,
      sort_order: 1,
      enable: 1,
      use_aff_url:
        hasAffiliateUrl === true && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
      image: valueImageBase64 || valueUrlImage,
      image_base64: valueImageBase64,
      origin_url: originUrl,
      target_url: affiliateUrl,
      image_name: valueNameImage,
      link_data: linkData,
      open_new_tab: isEcommerce ? true : openNewTab,
    };
    if (imageCoverEditData) {
      handleSaveImageCover({
        ...imageCoverEditData,
        use_aff_url:
          hasAffiliateUrl === true && linkData.type === LinkTypeEnum.WEB
            ? 1
            : 2,
        image: valueImageBase64 || valueUrlImage,
        image_base64: valueImageBase64,
        origin_url: originUrl,
        target_url: affiliateUrl,
        image_name: valueNameImage,
        link_data: linkData,
        open_new_tab: isEcommerce ? true : openNewTab,
      });
    } else {
      handleSaveImageCover(defaultImageData);
    }
    callBackCancelImageCoverAddNew();
    setValueUrlImage(null);
    setOriginUrl("");
    setUploadedImageArr([]);
    setValueImageBase64(null);
  };

  const handleCancelImageSliderAddNew = () => {
    if (
      (valueUrlImage && valueUrlImage !== valueUrlImageDefault) ||
      (originUrl && originUrl !== originUrlDefault) ||
      (typeof hasAffiliateUrl === "boolean" &&
        hasAffiliateUrl !== hasAffiliateUrlDefault) ||
      openNewTab !== openInNewTabDefault
    ) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="var(--adm-color-primary)"
            onClick={() => {}}
          />
        ),
        title: (
          <div>{t("ss_builder_confirm-cancel_image_slider_add_new_title")}</div>
        ),
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_confirm-cancel_image_slider_add_new_content")}
          </div>
        ),
        confirmText: t(
          "ss_builder_confirm_cancel_image_slider_add_new_btn_label"
        ),
        cancelText: t("ss_builder_cancel_image_slider_add_new_btn_label"),
        onConfirm: () => {
          callBackCancelImageCoverAddNew();
          setValueUrlImage(null);
          setOriginUrl("");
          setUploadedImageArr([]);
          setValueImageBase64(null);
        },
        onCancel: () => {
          return;
        },
      });
    } else {
      callBackCancelImageCoverAddNew();
      setValueUrlImage(null);
      setOriginUrl("");
      setUploadedImageArr([]);
      setValueImageBase64(null);
    }
  };

  const handleCheckAffiliateUrl = (data) => {
    setHasAffiliateUrl(data);
  };

  const isSaveButtonEnable = useMemo(() => {
    if (
      typeof hasAffiliateUrl === "boolean" &&
      valueUrlImage &&
      // originUrl &&
      (valueUrlImage !== valueUrlImageDefault ||
        originUrl !== originUrlDefault ||
        JSON.stringify(linkData) !== JSON.stringify(defaultLinkData) ||
        openNewTab !== openInNewTabDefault ||
        (typeof hasAffiliateUrl === "boolean" &&
          hasAffiliateUrl !== hasAffiliateUrlDefault)) &&
      !isValidUrl &&
      !isDisableBtnSave
    ) {
      return true;
    } else return false;
  }, [
    hasAffiliateUrl,
    valueUrlImage,
    valueUrlImageDefault,
    originUrl,
    originUrlDefault,
    hasAffiliateUrlDefault,
    isValidUrl,
    isDisableBtnSave,
    linkData,
    defaultLinkData,
    openNewTab,
    openInNewTabDefault,
  ]);

  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    if (linkData.type == LinkTypeEnum.WEB) {
      setOriginUrl(linkData.value);
    } else {
      setOriginUrl(null);
    }
    setLinkData(linkData);
  };

  const onAffLoading = useCallback(
    (isLoading) => {
      setIsDisableBtnSave(isLoading);
      setIsLoadingBtn(isLoading);
    },
    [setIsLoadingBtn]
  );

  return (
    <div className="z-[2]">
      <div
        className="flex flex-row text-center items-center justify-center py-3 gap-[10px] relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <ImageIcon className="" />
        <div>
          {typeof indexImageCoverEdit === "number"
            ? `Image ${indexImageCoverEdit + 1}`
            : "Image"}
        </div>
      </div>
      {/* <Divider /> */}
      <div className="p-4 bg-white mb-[70px]">
        <div className="text-[15px] text-[#666666]">
          <span className="text-red-600">*</span>
          {t("ss_builder_add_new_image_of_image_slider_title")}
        </div>
        <UploadImage
          valueUrlImage={valueUrlImage}
          imageRatio={imageRatio}
        />
        {isValidFileFormat && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_format_invalid_content")}
            </div>
          </div>
        )}
        {isValidFileSize && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_size_invalid_content")}
            </div>
          </div>
        )}
        <div className="flex gap-2 mt-4">
          <Button
            size="middle"
            color={valueUrlImage ? "primary" : "default"}
            fill="solid"
            className=""
            onClick={valueUrlImage ? handleDeleteImage : () => {}}
          >
            <TrashIcon
              className="w-[17px] h-[17px]"
              fillColor={valueUrlImage ? "#FFFFFF" : "#CCCCCC"}
            />
          </Button>
          <ImageUploader
            onChange={handleImageUrl}
            upload={(file) => mockUpload(file, 514, 980)}
            className={`image-slider-upload ${
              uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
            }`}
            value={uploadedImageArr}
            // disableUpload={isDisableUploadBtn}
            children={
              <Button
                block
                color="primary"
                fill="solid"
                size="middle"
                className="w-full grow"
                //   onClick={handleSaveBlockText}
                // disabled={isDisableUploadBtn}
              >
                {t("ss_builder_upload_button_label")}
              </Button>
            }
          />
        </div>
        <Divider />
        <div>
          <FormInput
            label={t("ss_builder_button_link_icon_origin_url_label")}
            isRequire={false}
          >
            <LinkInput
              item={linkData}
              onChange={(item) => {
                onUpdateLinkData(item);
              }}
              onLoading={onAffLoading}
              onError={(errorMessage) => {
                setIsValidUrl(!!errorMessage);
              }}
            />
          </FormInput>
        </div>

        {linkData.type === LinkTypeEnum.WEB && (
          <>
            <Divider />
            <div className="flex flex-row text-center items-center leading-[22px] gap-1">
              <Checkbox
                checked={
                  typeof hasAffiliateUrl !== "boolean" ? true : hasAffiliateUrl
                }
                onChange={handleCheckAffiliateUrl}
                icon={(checked) =>
                  checked ? <IconIsChecked /> : <IconIsNotChecked />
                }
              >
                <div className="text-left">
                  {t("ss_builder_use_affiliate_link_label")}
                </div>
              </Checkbox>
              <Popover
                content={t("ss_builder_use_affiliate_link_description")}
                placement="top-start"
                mode="dark"
                trigger="click"
              >
                <InformationIcon
                  className="ml-1 w-[14px] h-[14px]"
                  onClick={() => {}}
                  fillColor="#1890FF"
                />
              </Popover>
            </div>
            {!isEcommerce && (
              <>
                <Divider />
                <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                  <Checkbox
                    checked={!!openNewTab}
                    onChange={handleChangeOpenNewTab}
                    icon={(checked) =>
                      checked ? <IconIsChecked /> : <IconIsNotChecked />
                    }
                  >
                    <div className="text-left">
                      {t("ss_builder_open_new_tab_label")}
                    </div>
                  </Checkbox>
                  <Popover
                    content={t("ss_builder_open_new_tab_description")}
                    placement="top-start"
                    mode="dark"
                    trigger="click"
                  >
                    <InformationIcon
                      className="ml-1 w-[14px] h-[14px]"
                      onClick={() => {}}
                      fillColor="#1890FF"
                    />
                  </Popover>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={handleCancelImageSliderAddNew}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={!isSaveButtonEnable}
            color={"primary"}
            onClick={handleSaveImage}
            loading={isLoadingBtn}
          >
            {t("ss_builder_editor_btn-save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CoverImageAddNew;
