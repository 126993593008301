import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Ellipsis, Image, List, Modal, Popover, Toast } from "antd-mobile";
import {
  CheckCircleIcon,
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  InformationIcon,
  MoreIcon,
  TickIcon,
} from "@share/icons";
import cls from "classnames";
import { LinkTypeEnum } from "@features/block/components/LinkInput";
import { DONATION, GIFT, TRUTH_OR_DATE } from "@share/configs/const";
import { Action } from "antd-mobile/es/components/popover";

const CoverImageList = (props) => {
  const { t } = useTranslation();
  const AFF_CODE = 1;
  const {
    index,
    imageData,
    onRemoveItem,
    onDisableEnableImage,
    callbackOpenEditImage,
  } = props;
  const isEnable = !!(imageData && imageData.enable === 1);

  const popoverRef = useRef(null);

  const actions: Action[] = [
    {
      key: "eye-visible-image",
      icon: !isEnable ? (
        <EyeHiddenIcon className="" />
      ) : (
        <EyeVisibleIcon className="" />
      ),
      text: !isEnable
        ? t("ss_builder_show_image_label")
        : t("ss_builder_hide_image_label"),
    },
    {
      key: "delete-image",
      icon: <DeleteIcon className="" />,
      text: t("ss_builder_delete_image_label"),
    },
  ];

  const MoreElement = () => {
    return (
      <div className="flex flex-row items-center gap-[15px]">
        {/* {!isEnable && (
          <div className="text-[13px]">
            {t("ss_builder_hiding_block_label")}
          </div>
        )} */}
        <div
          className=""
          onClick={handleClickMoreIcon}
        >
          <Popover.Menu
            mode="dark"
            actions={actions}
            placement="left"
            trigger="click"
            stopPropagation={["click"]}
            getContainer={() => popoverRef.current}
            onAction={(item: Action) => handleClickPopoverItem(item)}
          >
            <MoreIcon className="mr-3" />
          </Popover.Menu>
        </div>
      </div>
    );
  };

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case "eye-visible-image":
        handleEnableDisableImage();
        break;
      case "delete-image":
        confirmRemoveImage();
        break;
      default:
      // to do
    }
  };

  const confirmRemoveImage = () =>
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_image_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm-delete_image_label")}
        </div>
      ),
      confirmText: t("ss_builder_delete_image_label"),
      cancelText: t("ss_builder_cancel-delete_image_label"),
      onConfirm: () => {
        onRemoveItem(index);
        Toast.show({
          icon: (
            <CheckCircleIcon
              className="text-center inline-block w-[30px] h-[30px]"
              fillColor="#00B578"
            />
          ),
          content: (
            <div className="text-center">
              {t("ss_builder_delete_image_successfully_label")}
            </div>
          ),
        });
      },
      onCancel: () => {
        return;
      },
    });

  const handleEditImage = (event) => {
    if (event?.defaultPrevented) {
      return;
    }
    callbackOpenEditImage(imageData, index);
  };

  const handleEnableDisableImage = () => {
    const imageStatus = !isEnable ? 1 : 2;
    onDisableEnableImage(index, imageStatus);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {isEnable
            ? t("ss_builder_disable_image_successfully_label")
            : t("ss_builder_enable_image_successfully_label")}
        </div>
      ),
    });
  };

  const handleClickMoreIcon = (event) => {
    event.preventDefault();
    // event.stopPropagation();
  };
  const getContent = () => {
    let content = "";
    if (imageData.link_data.type !== LinkTypeEnum.SERVICE) {
      content = imageData.link_data.value;
    } else {
      if (imageData.link_data.value === TRUTH_OR_DATE)
        content = t("ss_builder_title_block_truth_or_dare");
      if (imageData.link_data.value === GIFT)
        content = t("ss_builder_title_block_brand_gift");
      if (imageData.link_data.value === DONATION)
        content = t("ss_builder_title_block_donation");
    }
    return content;
  };
  return (
    <div ref={popoverRef}>
      <List className="">
        <List.Item
          prefix={<DragIcon className="" />}
          extra={<MoreElement />}
          onClick={handleEditImage}
        >
          <div
            className={cls(
              "flex items-center gap-[6px] flex-row",
              !isEnable && "opacity-40"
            )}
          >
            <Image
              src={imageData?.image}
              width={40}
              height={40}
              fit="cover"
            />
            <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
              <div className="text-xs leading-4 font-normal text-[#8C8C8C] text-ellipsis whitespace-nowrap overflow-hidden">
                {imageData?.use_aff_url === AFF_CODE ? (
                  <div
                    className="flex"
                    style={{ marginTop: "2px" }}
                  >
                    <TickIcon />
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#8C8C8C",
                        fontWeight: 400,
                      }}
                    >
                      {t("ss_builder_product_link_wrapped_label")}
                    </span>
                  </div>
                ) : (
                  <Ellipsis
                    className="text-[#999999] text-[15px] leading-5 text-ellipsis"
                    content={getContent()}
                  />
                )}
              </div>
            </div>
          </div>
        </List.Item>
      </List>
    </div>
  );
};
export default CoverImageList;
