import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/user/userSlice";
import positionsReducer from "@features/main/store/positionsSlice";
import siteSettingSlice from "@features/main/store/siteSettingSlice";
import dynamicSlice from "@features/main/store/dynamicSlice";
import edittingBlockSlice from "@features/block/store/edittingBlockSlice";
import passioPageProfileSlice from "@features/main/store/passioPageProfileSlice";
import videoShoutoutSlice from "@features/video-shoutout/store/videoShoutoutSlice";
import externalStateSlice from "@features/design/store/externalStateSlice";
import blockSaga from "@features/block/store/saga";
import { all } from "redux-saga/effects";
import savingProcessSlice from "@features/main/store/savingProcessSlice";
import savingProcessSaga from "@features/main/store/saga/saving-process";
import savingItemSlice from "@features/main/store/savingItemSlice";
import savingItemSaga from "@features/main/store/saga/saving-item";
import positionSaga from "@features/main/store/saga/position";
import siteSettingSaga from "@features/main/store/saga/site-setting";
import digitalProductSlice from "@features/block/block-editor/DigitalProduct/store/digitalProductSlice";
import externalStateSaga from "@features/design/store/saga";
import creatorProfileSlice from "@features/main/store/creatorProfileSlice";
import bookingProfile from "@features/main/store/bookingProfileSlice";
import websiteConfigSlice from "@features/main/store/websiteConfigSlice";

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({ thunk: true, serializableCheck: false }),
  sagaMiddleware,
];
export interface ActionInterface {
  type: string;
  payload: any;
}

const rootSaga = function* rootSaga() {
  yield all([
    blockSaga(),
    savingProcessSaga(),
    savingItemSaga(),
    positionSaga(),
    siteSettingSaga(),
    externalStateSaga(),
  ]);
};

export function makeStore() {
  return configureStore({
    reducer: {
      positions: positionsReducer,
      counter: counterReducer,
      user: userReducer,
      siteSettings: siteSettingSlice,
      dynamicConfig: dynamicSlice,
      edittingBlock: edittingBlockSlice,
      passioPageProfile: passioPageProfileSlice,
      videoShoutout: videoShoutoutSlice,
      savingProcess: savingProcessSlice,
      savingItem: savingItemSlice,
      digitalProduct: digitalProductSlice,
      externalState: externalStateSlice,
      creatorProfile: creatorProfileSlice,
      bookingProfile: bookingProfile,
      websiteConfig: websiteConfigSlice,
    },
    middleware,
  });
}

const store = makeStore();
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch | any;

export type AppState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<any>
  // Action<string>
>;

export default store;
