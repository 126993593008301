import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import { Checkbox, List } from "antd-mobile";
import { AffiliateProductInterface } from "@features/main/store/interface";
import { PRODUCT_MAX } from "@share/configs/const";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";

type Props = {
  callbackCancelAffiliateProductMovingPanel: any;
  callbackConfirmAffiliateProductMovingPanel: any;
  allowedMovingAffiliateProductGroup: AffiliateProductInterface[];
  productMovingQuantity: number;
};

const AffiliateProductMovingPanel = (props: Props) => {
  const {
    callbackCancelAffiliateProductMovingPanel,
    callbackConfirmAffiliateProductMovingPanel,
    allowedMovingAffiliateProductGroup = [],
    productMovingQuantity,
  } = props;
  const { t } = useTranslation();

  const [valueCheckedArr, setValueCheckedArr] = useState([]);

  const handleConfirmAffiliateProductMovingPanel = () => {
    callbackConfirmAffiliateProductMovingPanel(valueCheckedArr[0]);
    callbackCancelAffiliateProductMovingPanel();
  };

  const handleGetCheckedProductGroup = (val) => {
    const newValueCheckedArr = [];
    const value = val[val.length - 1];
    if (value) newValueCheckedArr.push(value);
    setValueCheckedArr(newValueCheckedArr);
  };

  const isEnableConfirmProductSelectedList = useMemo(
    () => valueCheckedArr?.length > 0,
    [valueCheckedArr]
  );

  return (
    <div className="affiliate-product-moving-panel">
      <div
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
        className="pb-3"
      >
        <span className="pl-3">
          {t("ss_builder_move_product_quantity_to_group_alert_label", {
            product_quantity: productMovingQuantity,
          })}
          :
        </span>
      </div>

      <div>
        <Checkbox.Group
          onChange={handleGetCheckedProductGroup}
          value={valueCheckedArr}
          // disabled={isDisableSelectProduct}
        >
          <List
            className="affiliate-product-moving-panel-content"
            style={{
              "--border-bottom": "unset",
              "--border-top": "unset",
            }}
          >
            {allowedMovingAffiliateProductGroup?.map((ele, index) => (
              <List.Item
                arrow={false}
                key={index}
              >
                <Checkbox
                  value={ele.uid}
                  disabled={
                    ele?.content_attributes?.items?.length >= PRODUCT_MAX ||
                    productMovingQuantity >
                      PRODUCT_MAX - ele?.content_attributes?.items?.length
                  }
                  className="w-full"
                >
                  <div className={cls("flex items-center gap-[6px] flex-row")}>
                    <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
                      <div className="text-ellipsis whitespace-nowrap overflow-hidden text-[17px] leading-[22px] font-normal">
                        {ele.title}
                      </div>
                      <span
                        className={cls(
                          `text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4`,
                          ele?.content_attributes?.items?.length >=
                            PRODUCT_MAX ||
                            productMovingQuantity >
                              PRODUCT_MAX -
                                ele?.content_attributes?.items?.length
                            ? "text-[#FF3141]"
                            : "text-[#8C8C8C]"
                        )}
                      >
                        {t(
                          "ss_builder_affiliate_product_group_quantity_label",
                          {
                            product_quantity: `${ele?.content_attributes?.items?.length}/${PRODUCT_MAX}`,
                          }
                        )}
                        .&nbsp;
                        {ele?.content_attributes?.items?.length >=
                          PRODUCT_MAX &&
                          t(
                            "ss_builder_affiliate_product_group_quantity_warning_1"
                          )}
                        {ele?.content_attributes?.items?.length !==
                          PRODUCT_MAX &&
                          productMovingQuantity >
                            PRODUCT_MAX -
                              ele?.content_attributes?.items?.length &&
                          t(
                            "ss_builder_affiliate_product_group_quantity_warning_2"
                          )}
                      </span>
                    </div>
                  </div>
                </Checkbox>
              </List.Item>
            ))}
          </List>
        </Checkbox.Group>
      </div>
      <ButtonConfirmPopup
        onConfirm={
          isEnableConfirmProductSelectedList
            ? handleConfirmAffiliateProductMovingPanel
            : () => {}
        }
        isDisable={!isEnableConfirmProductSelectedList}
      />
    </div>
  );
};

export default AffiliateProductMovingPanel;
