import { CAT_ID } from "./type"

export const categoryBackgroundMap = {
  [CAT_ID.RANDOM_PRODUCT]: '#D1FAE5',
  [CAT_ID.ONLINE_CONSULTION]: '#CFFAFE',
  [CAT_ID.COURSE]: '#FFE4E6',
  [CAT_ID.VIDEO]: '#FEF3C7',
  [CAT_ID.EXCLUSIVE_EBOOK]: '#F3E8FF',
  [CAT_ID.TICKET_ACCOUNT]: '#F0FDF4',
  [CAT_ID.PHOTO]: '#EEF2FF',
}

export const categoryTextColorMap = {
  [CAT_ID.RANDOM_PRODUCT]: '#059669',
  [CAT_ID.ONLINE_CONSULTION]: '#06B6D4',
  [CAT_ID.COURSE]: '#DB2777',
  [CAT_ID.VIDEO]: '#D97706',
  [CAT_ID.EXCLUSIVE_EBOOK]: '#9333EA',
  [CAT_ID.TICKET_ACCOUNT]: '#16A34A',
  [CAT_ID.PHOTO]: '#4F46E5',
}

export const categoryNameMap = {
  [CAT_ID.RANDOM_PRODUCT]: 'ss_dp_cat_random_product',
  [CAT_ID.ONLINE_CONSULTION]: 'ss_dp_cat_online_consultion',
  [CAT_ID.COURSE]: 'ss_dp_cat_course',
  [CAT_ID.VIDEO]: 'ss_dp_cat_video',
  [CAT_ID.EXCLUSIVE_EBOOK]: 'ss_dp_cat_ebook_document',
  [CAT_ID.TICKET_ACCOUNT]: 'ss_dp_cat_ticket_account',
  [CAT_ID.PHOTO]: 'ss_dp_sell_photo',
}
