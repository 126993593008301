import { useAppDispatch } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import FormInput from "@features/block/components/FormInput";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import { requestValidateUrl } from "@share/api";
import UploadImage from "@share/components/UploadImage";
import { ENABLE_BLOCK, IMAGE_ORIENTATION_TYPE } from "@share/configs/const";
import { checkOpenNewTab } from "@share/helper/validator";
import {
  CheckCircleIcon,
  InformationIcon,
  InformationTriangleIcon,
  TrashIcon,
} from "@share/icons";
import { mockUpload, validURL } from "@share/lib";
import {
  Button,
  Checkbox,
  Divider,
  Image,
  ImageUploader,
  Input,
  Modal,
  Popover,
  Selector,
  TextArea,
  Toast,
} from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import isEqual from "lodash/isEqual";

const BLOCK_HEADING_MAXIMUM = 50;
const BLOCK_DESCRIPTION_MAXIMUM = 250;

const ImageComponent = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    setButtonLoading,
    handleCancelBlock,
    defaultBlockData,
  } = props;
  const { t } = useTranslation();
  const UID = `image-gallery-${uuidv4()}`;

  const dispatch = useAppDispatch();

  // const edittingBlock = useAppSelector((state) => state.edittingBlock);
  // const KEY = edittingBlock && edittingBlock.KEY;
  // const edittingBlockData =
  //   block ?? (edittingBlock && edittingBlock.block_data);
  // const tabUid = edittingBlock && edittingBlock.tab_uid;
  // const positionSlug = edittingBlock && edittingBlock.position_uid;
  const defaultValueEditImage =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const titleBlockDefault = "Image";
  const imageUrlDefault =
    defaultValueEditImage?.content_attributes?.image || "";
  const valueHeadingBlockDefault =
    defaultValueEditImage?.content_attributes?.block_heading || "";
  const valueDescriptionBlockDefault =
    defaultValueEditImage?.content_attributes?.block_description || "";

  const originUrlDefault =
    defaultValueEditImage?.content_attributes?.origin_url || "";

  const affiliateUrlDefault =
    defaultValueEditImage?.content_attributes?.target_url || "";
  const imageOrientationDefault =
    defaultValueEditImage?.style_attributes?.image_orientation ||
    IMAGE_ORIENTATION_TYPE.LANDSCAPE;

  const ratioDefault = useMemo(() => {
    let valueRatio = defaultValueEditImage?.style_attributes?.ratio || "16/9";
    if (imageOrientationDefault === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [defaultValueEditImage, imageOrientationDefault]);

  const hasAffiliateUrlDefault = defaultValueEditImage?.content_attributes
    ?.use_aff_url
    ? !!(defaultValueEditImage?.content_attributes?.use_aff_url === 1)
    : true;

  const defaultLinkData = defaultValueEditImage?.content_attributes
    ?.link_data || {
    type: LinkTypeEnum.WEB,
    value: originUrlDefault,
  };
  const openInNewTabDefault =
    defaultValueEditImage?.content_attributes?.open_new_tab || false;
  const [linkData, setLinkData] = useState<LinkDataInterface>(defaultLinkData);
  const [imageRatio, setImageRatio] = useState(ratioDefault);
  const [valueUrlImage, setValueUrlImage] = useState(imageUrlDefault);
  const [valueNameImage, setValueNameImage] = useState("");
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [originUrl, setOriginUrl] = useState(originUrlDefault);
  const [affiliateUrl, setAffiliateUrl] = useState(affiliateUrlDefault);
  const [hasAffiliateUrl, setHasAffiliateUrl] = useState(
    hasAffiliateUrlDefault
  );
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [warningCode, setWarningCode] = useState(null);
  const [isCheckDisableBtnSave, setIsCheckDisableBtnSave] = useState(false);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [valueHeadingBlock, setValueHeadingBlock] = useState(
    valueHeadingBlockDefault
  );
  const [imageOrientation, setImageOrientation] = useState(
    imageOrientationDefault
  );
  const [valueDescriptionBlock, setValueDescriptionBlock] = useState(
    valueDescriptionBlockDefault
  );
  const [isEnable, setIsEnable] = useState(block?.enable ?? ENABLE_BLOCK);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);

  const imageRatioDetection = useMemo(() => {
    let valueRatio = imageRatio;
    if (imageOrientation === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [imageOrientation, imageRatio]);

  const checkEcommerce = (data) => {
    if (data) {
      try {
        const ecommerceOrSocial = new URL(data);
        setIsEcommerce(checkOpenNewTab(ecommerceOrSocial));
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    if (linkData && linkData.type == LinkTypeEnum.WEB) {
      checkEcommerce(linkData.target_url);
    } else if (originUrl) checkEcommerce(originUrl);
  }, [originUrl, linkData]);

  useEffect(() => {
    if (!block.uid) {
      const defaultImageData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.IMAGE,
        title: titleBlockDefault,
        content_attributes: {
          image: valueImageBase64 || valueUrlImage,
          image_base64: valueImageBase64,
          image_name: valueNameImage,
          use_aff_url:
            hasAffiliateUrl === true && linkData.type === LinkTypeEnum.WEB
              ? 1
              : 2,
          target_url: affiliateUrl,
          origin_url: originUrl,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          open_new_tab:
            isEcommerce || linkData.type !== LinkTypeEnum.WEB
              ? true
              : openNewTab,
          is_ecommerce: isEcommerce,
          link_data: linkData,
        },
        style_attributes: {
          ratio: imageRatioDetection,
          image_orientation: imageOrientation,
        },
      };
      onUpdateData(defaultImageData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          image: valueImageBase64 || valueUrlImage,
          image_base64: valueImageBase64,
          image_name: valueNameImage,
          use_aff_url:
            hasAffiliateUrl === true && linkData.type === LinkTypeEnum.WEB
              ? 1
              : 2,
          target_url: affiliateUrl,
          origin_url: originUrl,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          link_data: linkData,
          open_new_tab:
            isEcommerce || linkData.type !== LinkTypeEnum.WEB
              ? true
              : openNewTab,
          is_ecommerce: isEcommerce,
        },
        style_attributes: {
          ratio: imageRatioDetection,
          image_orientation: imageOrientation,
        },
      });
    }
  }, [
    valueUrlImage,
    imageRatioDetection,
    valueImageBase64,
    valueNameImage,
    hasAffiliateUrl,
    affiliateUrl,
    originUrl,
    imageOrientation,
    valueHeadingBlock,
    valueDescriptionBlock,
    openNewTab,
    linkData,
    isEcommerce,
  ]);

  const isDisableBtnSave = useMemo(() => {
    if (
      valueUrlImage &&
      (valueUrlImage !== imageUrlDefault ||
        imageRatio !== ratioDefault ||
        originUrl !== originUrlDefault ||
        valueHeadingBlock !== valueHeadingBlockDefault ||
        valueDescriptionBlock !== valueDescriptionBlockDefault ||
        imageOrientation !== imageOrientationDefault ||
        openNewTab !== openInNewTabDefault ||
        (typeof hasAffiliateUrl === "boolean" &&
          hasAffiliateUrl !== hasAffiliateUrlDefault)) &&
      !isValidUrl &&
      !isCheckDisableBtnSave
    ) {
      return false;
    } else return true;
  }, [
    imageRatio,
    ratioDefault,
    originUrl,
    originUrlDefault,
    valueUrlImage,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    imageUrlDefault,
    isValidUrl,
    isCheckDisableBtnSave,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    imageOrientation,
    imageOrientationDefault,
    openNewTab,
    openInNewTabDefault,
    linkData.value,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      imageRatio !== ratioDefault ||
      originUrl !== originUrlDefault ||
      valueUrlImage !== imageUrlDefault ||
      valueHeadingBlock !== valueHeadingBlockDefault ||
      valueDescriptionBlock !== valueDescriptionBlockDefault ||
      imageOrientation !== imageOrientationDefault ||
      (typeof hasAffiliateUrl === "boolean" &&
        hasAffiliateUrl !== hasAffiliateUrlDefault) ||
      !isEqual(linkData, defaultLinkData) ||
      openNewTab !== openInNewTabDefault
    ) {
      return true;
    } else return false;
  }, [
    imageRatio,
    ratioDefault,
    originUrl,
    originUrlDefault,
    valueUrlImage,
    imageUrlDefault,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    imageOrientation,
    imageOrientationDefault,
    openNewTab,
    openInNewTabDefault,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  useEffect(() => {
    setButtonLoading(isLoadingBtn);
  }, [isLoadingBtn]);

  const imageOptions = [
    {
      label: "1:1",
      value: "1/1",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "4:3" : "3:4",
      value: "4/3",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:2" : "2:3",
      value: "3/2",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "16:9" : "9:16",
      value: "16/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "21:9" : "9:21",
      value: "21/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:1" : "1:3",
      value: "3/1",
    },
  ];

  const imageOrientationOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://i.ecomobi.com/ssp/passio-page/config/landscape.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_landscape_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.LANDSCAPE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://i.ecomobi.com/ssp/passio-page/config/portrait.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_portrait_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.PORTRAIT,
    },
  ];

  const handleSelectImageRatio = (arr) => {
    if (arr && arr.length) {
      const valueRatio = arr[0];
      setImageRatio(valueRatio);
    }
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
    }
  };

  const handleDeleteImage = () => {
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_image_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm-delete_image_label")}
        </div>
      ),
      confirmText: t("ss_builder_delete_image_label"),
      cancelText: t("ss_builder_cancel-delete_image_label"),
      onConfirm: () => {
        setValueUrlImage(null);
        setUploadedImageArr([]);
        Toast.show({
          icon: (
            <CheckCircleIcon
              className="text-center inline-block w-[30px] h-[30px]"
              fillColor="#00B578"
            />
          ),
          content: (
            <div className="text-center">
              {t("ss_builder_delete_image_successfully_label")}
            </div>
          ),
        });
      },
      onCancel: () => {
        return;
      },
    });
  };

  const validateOriginUrl = (data) => {
    if (data) {
      const validateUrl = async () => {
        let response = null;
        try {
          response = await requestValidateUrl(data);
        } catch (error) {
          console.log(error);
        }
        if (response) {
          setIsLoadingBtn(false);
          setIsCheckDisableBtnSave(false);
          const isSuccess = response?.data?.success;
          if (isSuccess) {
            const isAccepted = response?.data?.data?.accepted;
            const targetUrl = response?.data?.data?.target_url;
            setAffiliateUrl(targetUrl);
            if (!isAccepted) {
              const warningCode = response?.data?.data?.warning_code;
              setWarningCode(warningCode);
              setErrorCode(null);
            } else {
              setWarningCode(null);
            }
          } else {
            const errorCode = response?.data?.error_code;
            setErrorCode(errorCode);
            setWarningCode(null);
          }
        }
      };
      const isCheckUrl = validURL(data);
      if (isCheckUrl) {
        setIsLoadingBtn(true);
        validateUrl();
        setIsValidUrl(false);
      } else {
        setIsValidUrl(true);
      }
    } else {
      setErrorCode(null);
      setIsValidUrl(false);
      setIsCheckDisableBtnSave(false);
    }
    setWarningCode(null);
  };

  const handleCheckAffiliateUrl = (data) => {
    setHasAffiliateUrl(data);
  };

  const handleChangeBlockHeading = (val) => {
    if (val?.length <= BLOCK_HEADING_MAXIMUM) {
      setValueHeadingBlock(val);
    }
  };

  const handleChangeBlockDescription = (val) => {
    if (val?.length <= BLOCK_DESCRIPTION_MAXIMUM) {
      setValueDescriptionBlock(val);
    }
  };

  const handleSelectImageOrientation = (arr) => {
    if (arr && arr.length) {
      const imageOrientation = arr[0];
      setImageOrientation(imageOrientation);
    }
  };

  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    if (linkData.type == LinkTypeEnum.WEB) {
      setOriginUrl(linkData.value);
    } else {
      setOriginUrl(null);
    }
    setLinkData(linkData);
  };
  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
  };

  const onAffLoading = useCallback(
    (isLoading) => {
      setIsCheckDisableBtnSave(isLoading);
      setIsLoadingBtn(isLoading);
    },
    [setIsCheckDisableBtnSave, setIsLoadingBtn]
  );

  return (
    <>
      {/* <div className="text-[15px] text-[#666666]">
          {t("ss_builder_preview_image_label")}
        </div>
        <div>
          <Image
            src={
              // "https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=60"
              "https://sf.ezoiccdn.com/ezoimgfmt/animetroop.com/wp-content/uploads/2022/05/Kengan-Omega-Chapter-160-Raw-Scans-Spoilers-Release-Date.jpg?ezimgfmt=rs%3Adevice%2Frscb7-1"
            }
          />
        </div>
        <div>
          <Button
            block
            color="default"
            size="middle"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
              gap: "5px",
              borderColor: "var(--adm-color-primary)",
              color: "var(--adm-color-primary)",
            }}
          >
            <SampleSelectionIcon
              className="w-[17px] h-[17px]"
              fillColor="var(--adm-color-primary)"
            />
            <div>{t("ss_builder_sample_selection_label")}</div>
          </Button>
        </div> */}
      <div className=" bg-white px-4 py-[13px]">
        <div className="text-[15px] text-[#666666]">
          <span className="text-red-600">*</span>
          {t("ss_builder_add_new_image_of_image_slider_title")}
        </div>

        <UploadImage
          valueUrlImage={valueUrlImage}
          imageRatio={imageRatio}
        />
        {isValidFileFormat && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_format_invalid_content")}
            </div>
          </div>
        )}
        {isValidFileSize && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_size_invalid_content")}
            </div>
          </div>
        )}
        <div className="flex gap-2 mt-4">
          <Button
            size="middle"
            color={valueUrlImage ? "primary" : "default"}
            fill="solid"
            className=""
            onClick={valueUrlImage ? handleDeleteImage : () => {}}
          >
            <TrashIcon
              className="w-[17px] h-[17px]"
              fillColor={valueUrlImage ? "#FFFFFF" : "#CCCCCC"}
            />
          </Button>
          <ImageUploader
            onChange={handleImageUrl}
            upload={(file) => mockUpload(file, 514, 980)}
            className={`image-slider-upload ${
              uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
            }`}
            value={uploadedImageArr}
            // disableUpload={isDisableUploadBtn}
            children={
              <Button
                block
                color="primary"
                fill="solid"
                size="middle"
                className="w-full grow"
                //   onClick={handleSaveBlockText}
                // disabled={isDisableUploadBtn}
              >
                {t("ss_builder_upload_button_label")}
              </Button>
            }
          />
        </div>
      </div>

      <div className=" bg-white mt-3 px-4 py-[13px]">
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_heading_label")}
          </span>
          <Input
            placeholder={t("ss_builder_block_heading_placeholder")}
            onChange={handleChangeBlockHeading}
            value={valueHeadingBlock}
            maxLength={BLOCK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueHeadingBlock ? valueHeadingBlock.length : 0
          }/${BLOCK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </span>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            onChange={handleChangeBlockDescription}
            value={valueDescriptionBlock}
            maxLength={BLOCK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueDescriptionBlock ? valueDescriptionBlock.length : 0
          }/${BLOCK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
      </div>
      <div className="bg-white my-3 px-4 py-[13px]">
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_image_orientation_label")}
          </span>
          <Selector
            options={imageOrientationOptions}
            value={[imageOrientation]}
            onChange={handleSelectImageOrientation}
            columns={4}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
            }}
          />
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_image_slider_ratio_selected_title")}
          </div>
          <Selector
            options={imageOptions}
            value={[imageRatio]}
            onChange={handleSelectImageRatio}
            columns={4}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
            }}
          />
        </div>
        <Divider />
        <div>
          <FormInput
            label={t("ss_builder_button_link_icon_origin_url_label")}
            isRequire={false}
          >
            <LinkInput
              item={linkData}
              onChange={(item) => {
                onUpdateLinkData(item);
              }}
              onLoading={onAffLoading}
              onError={(errorMessage) => {
                setIsValidUrl(!!errorMessage);
              }}
            />
          </FormInput>
        </div>
        <Divider />
        {linkData.type === LinkTypeEnum.WEB && (
          <>
            <div className="flex flex-row text-center items-center leading-[22px] gap-1">
              <Checkbox
                checked={
                  typeof hasAffiliateUrl !== "boolean" ? true : hasAffiliateUrl
                }
                onChange={handleCheckAffiliateUrl}
              >
                <div className="text-left">
                  {t("ss_builder_use_affiliate_link_label")}
                </div>
              </Checkbox>
              <Popover
                content={t("ss_builder_use_affiliate_link_description")}
                placement="top-start"
                mode="dark"
                trigger="click"
              >
                <InformationIcon
                  className="ml-1 w-[14px] h-[14px]"
                  onClick={() => {}}
                  fillColor="#1890FF"
                />
              </Popover>
            </div>
            <Divider />
            {!isEcommerce && (
              <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                <Checkbox
                  checked={!!openNewTab}
                  onChange={handleChangeOpenNewTab}
                >
                  <div className="text-left">
                    {t("ss_builder_open_new_tab_label")}
                  </div>
                </Checkbox>
                <Popover
                  content={t("ss_builder_open_new_tab_description")}
                  placement="top-start"
                  mode="dark"
                  trigger="click"
                >
                  <InformationIcon
                    className="ml-1 w-[14px] h-[14px]"
                    onClick={() => {}}
                    fillColor="#1890FF"
                  />
                </Popover>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ImageComponent;
