import { createSlice } from "@reduxjs/toolkit";
import { WebsiteConfigInterface } from "./interface";

const initialState: WebsiteConfigInterface = {
  favicon: "",
  id: "",
  meta_description: "",
  meta_title: "",
  site_title: "",
};

export const websiteConfigSlice = createSlice({
  name: "websiteConfig",
  initialState,
  reducers: {
    setWebsiteConfig: (state, { payload: { data } }) => {
      return { ...state, ...data };
    },
  },
});

export const { setWebsiteConfig } = websiteConfigSlice.actions;
export default websiteConfigSlice.reducer;
