import { useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { scrollToNewItem } from "@features/block/libs";
import { Block } from "@features/main/store/interface";
import FullScreenPopup from "@share/components/full-screen-popup";
import StickyChecking from "@share/components/StickyChecking";
import { ENABLE_BLOCK } from "@share/configs/const";
import { usePopup } from "@share/hooks";
import { AddIcon, ImageIconEmpty } from "@share/icons";
import { getPlaceholderProps } from "@share/lib";
import { Button } from "antd-mobile";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import {
  IMAGE_POPUP_ACTION,
  listImgPopupReducer as imgPopupListReducer,
} from "./helpers";
import ImagePopupForm, { ImagePopup } from "./ImagePopupForm";
import ImagePopupList from "./ImagePoupList";

export const IMAGE_MAX = 3;

interface ImagePopupProps {
  onUpdateData: (block: Block) => void;
  block: Block;
  setButtonStatus: (status: boolean) => void;
  setEditStatus: (status: boolean) => void;
  handleCancelBlock: () => void;
  defaultBlockData?: Block;
}

const ImagePopup = (props: ImagePopupProps) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    handleCancelBlock,
    defaultBlockData,
  } = props;
  const { t } = useTranslation();

  const {
    visible,
    closePopup: closeImgPopupForm,
    openPopup: openImgPopupForm,
  } = usePopup();

  const [placeholderProps, setPlaceholderProps] = useState({});

  // const currentBlock = useAppSelector((state) => state.edittingBlock);
  const defaultImgPopupList = defaultBlockData.uid
    ? defaultBlockData?.content_attributes?.items
    : [];

  const [imgPopup, setImgPopup] = useState<ImagePopup>();
  const [imgPopupList, dispatchImgPopupList] = useReducer(
    imgPopupListReducer,
    defaultImgPopupList
  );

  const reoderImgPopupList = (startIndex: number, endIndex: number) => {
    const result = [...imgPopupList];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedList = result.map((ele, id) => ({
      ...(ele as Object),
      sort_order: id + 1,
    }));
    return orderedList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const listOrdered = reoderImgPopupList(startIndex, endIndex);
    dispatchImgPopupList({
      type: IMAGE_POPUP_ACTION.SORT_LIST,
      payload: listOrdered,
    });
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  const handleChangeImgPopup = (
    action: IMAGE_POPUP_ACTION,
    item?: ImagePopup
  ) => {
    dispatchImgPopupList({
      type: action,
      payload: item,
    });
    scrollToNewItem(`blocks-${item?.uid}`);
    if (Object.is(action, IMAGE_POPUP_ACTION.SAVE_ITEM)) resetImgPopupForm();
  };

  const openEditImgPopup = (item: ImagePopup) => {
    setImgPopup(item);
    openImgPopupForm();
  };

  const resetImgPopupForm = () => {
    setImgPopup(undefined);
    closeImgPopupForm();
  };

  useEffect(() => {
    const isImgPopupListChanged =
      JSON.stringify(imgPopupList) !== JSON.stringify(defaultImgPopupList);
    const isOldBlock = !!block.uid;
    const isImgPopupListInValid =
      imgPopupList.findIndex((e) => Object.is(e.enable, ENABLE_BLOCK)) === -1;

    let blockEditting = {
      uid: `image-popup-${uuidv4()}`,
      sort_order: 1,
      enable: ENABLE_BLOCK,
      block_type: BLOCK_TYPE.IMAGE_POPUP,
      title: t("ss_builder_title_block_image_popup"),
      content_attributes: {
        items: imgPopupList,
      },
    };

    if (isOldBlock) {
      blockEditting = {
        ...block,
        content_attributes: {
          items: imgPopupList,
        },
      };
    }

    setEditStatus(isImgPopupListChanged);
    setButtonStatus(isImgPopupListInValid || !isImgPopupListChanged);
    onUpdateData(blockEditting);
  }, [imgPopupList]);

  const EmptyData = () => (
    <div className="flex flex-col text-center items-center justify-center my-6">
      <span className="inline-block mb-2">
        <ImageIconEmpty className="align-middle w-8 h-8" />
      </span>
      <div className="text-[#333333] text-lg leading-6">
        {t("ss_builder_image_list_empty_title")}
      </div>
      <div className="text-[#8C8C8C] text-xs leading-4">
        {t("ss_builder_image_list_empty_content")}
      </div>
    </div>
  );

  const imageEnable = useMemo(() => {
    return imgPopupList?.filter((item) => item.enable === 1);
  }, [imgPopupList]);

  return (
    <>
      <div className="relative">
        <StickyChecking previousStickyElementId="block-image-popup-editor-header">
          <div className="flex flex-row justify-between px-3 pt-4 pb-3">
            <div className="grow">
              <div className="text-[15px] text-[#666666]">
                <span className="text-red-600">*</span>
                {t("ss_builder_list_label")}
              </div>
            </div>
            <div className="flex flex-grow justify-end gap-2">
              <Button
                size="small"
                fill="solid"
                color="primary"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  padding: "3px 12px 3px 12px",
                }}
                onClick={openImgPopupForm}
                disabled={imgPopupList.length === IMAGE_MAX}
              >
                <AddIcon className="w-[15px] h-[15px]" />
                <div>{t("ss_builder_add_new_label")}</div>
                <span> {`${imgPopupList.length}/${IMAGE_MAX}`} </span>
              </Button>
            </div>
          </div>
          {imageEnable.length === 0 && imgPopupList.length > 0 && (
            <div
              className="px-3 pb-4 text-[13px] font-normal leading-[17px]"
              style={{ color: "var(--adm-color-danger)" }}
            >
              {t("ss_builder_need_to_display_at_least_one_image")}
            </div>
          )}
        </StickyChecking>
        {!!imgPopupList.length ? (
          <ImagePopupList
            onDragEnd={onDragEnd}
            listImgPopup={imgPopupList}
            onChangeItem={handleChangeImgPopup}
            onOpenEditImgPopup={openEditImgPopup}
            onDragUpdate={onDragUpdate}
            placeholderProps={placeholderProps}
          />
        ) : (
          <EmptyData />
        )}
      </div>

      <FullScreenPopup
        visible={visible}
        onMaskClick={closeImgPopupForm}
      >
        <ImagePopupForm
          value={imgPopup}
          onCancel={resetImgPopupForm}
          onSubmit={(value) => {
            handleChangeImgPopup(IMAGE_POPUP_ACTION.SAVE_ITEM, value);
          }}
        />
      </FullScreenPopup>
    </>
  );
};

export default ImagePopup;
