declare global {
  interface Window {
    seller_id: any;
  }
}

export const BLOCK_TYPE_EVENT_CONFIG = {
  TEXT: "Text",
  TAB_BAR: "TabMenu",
  IMAGE_SLIDER: "ImageSlider",
  AFFILIATE_PRODUCT: "Affiliate",
  STORY: "Spotlight",
  BIO: "Bio",
  IMAGE: "Image",
  BUTTON_LINK: "Link",
  GIFT: "VirtualGift",
  DIGITAL_PRODUCT: "DigitalProduct",
  AFFILIATE_PRODUCT_GROUP: "Affiliate",
  IMAGE_GALLERY: "ImageGallery",
  EMBED: "Embed",
  CARD: "Cards",
  BUTTON_MULTI_LINK: "MultiLinks",
  DONATION: "Donation",
  TOD: "TruthOrDare",
  TOD_FOOTER: "TruthOrDareFloating",
  GIFT_FOOTER: "VirtualGiftFloating",
  THEME_TEMPLATE_SEARCH_CLEAR: "ThemeTemplate_Search_Clear",
  BLOCK_TYPE_SEARCH_CLEAR: "BlockType_Search_Clear",
  COMBINED: "Combined",
};

export const LABEL_CONFIG = {
  CLICK_ON: "ClickOn",
  EDIT: "Edit",
  CANCEL: "Cancel",
  CLEAR_SEARCH: "Clear Search",
};

export const CATEGORY_CONFIG = {
  BLOCK: "Block",
  BLOCK_TYPE: "Block Type",
  ADD_BLOCK_BUTTON: "Add Block Button",
  POSITION: "Position",
  TAB: "Tab",
  THEME_TEMPLATE: "Theme Template",
  THEME_SETTINGS: "Theme Settings",
  SITE_SETTINGS: "Site Settings",
  SETTINGS: "Settings",
  PREVIEW_SITE: "Preview Site",
  RESTORE_EDITS: "Restore Edits",
  APPLY_SAMPLE_CONTENT: "Apply Sample Content",
  PRODUCT_TOUR_AUTO: "Product Tour Auto",
  PRODUCT_TOUR: "Product Tour",
  PUBLISH_SITE: "Publish Site",
  PUBLISH_RESULT: "Publish Result",
  POWERED_BY_LOGO: "Powered by Logo",
  POWERED_BY_TEXT: "Powered by Text",
  ERROR_PAGE:"Error Page",
  ONBOARDING: "Onboarding",
  ASK_TO_REVIEW: "Ask To Review",
  BUILDER_ACCESS: "Builder Access",
  CREATOR_SEND_REVIEW: "Creator Send Review"
};

export const ACTION_CONFIG = {
  EDIT: "Edit",
  CANCEL: "Cancel",
  START_EDITING_POPOVER: "Start editing - Popover",
  START_EDITING_QUICK: "Start editing - Quick",
  HIDE: "Hide",
  SHOW: "Show",
  DELETE: "Delete",
  MOVE_UP: "Move up",
  MOVE_DOWN: "Move down",
  DRAG_DROP_UP: "Drag Drop - Up",
  DRAG_DROP_DOWN: "Drag Drop - Down",
  SEARCH: "Search",
  GROUP_FILTER: "Group Filter",
  SELECT: "Select",
  ADD: "Add",
  ADD_BLOCK: "Add Block",
  VIEW: "View",
  APPLY: "Apply",
  GENERAL: "General",
  BLOCK: "Block",
  HEADING: "Heading",
  PARAGRAPH: "Paragraph",
  BUTTON: "Button",
  PERSONAL_BRAND: "Personal brand",
  DOMAIN: "Domain",
  FAVICON_ICON: "Click Favicon Icon",
  EDIT_DOMAIN: "Click Edit Domain Icon",
  COPY_DOMAIN: "Copy Domain",
  VIEW_SITE_IN_BROWSER: "View Site in Browser",
  SHARE_SITE: "Share Site",
  TURN_ON: "Turn on",
  TURN_OFF: " Turn off",
  SET_DEFAULT: "Set default",
  RENAME: "Rename",
  VIEW_SITE: "View Site in Browser",
  SHARE: "Share Site",
  THEME_NOT_EXITS: "Theme Template Does Not Exist",
  CONFIRM: "Confirm",
  PREVIEW_SITE_IN_BROWSER: "Preview Site in Browser",
  PREVIEW_SITE: "Preview Site",
  CLICK: "Click",
  SHOW_ERROR_PAGE:"Show Error Page",
  SEND_ERROR_REPORT:"Send Error Report",
  DRAG_AND_DROP: "Drag n Drop",
  SELECT_CATEGORY: "Select Category",
  COMPLETE: "Complete",
  SUBMIT_REVIEW_FORM: "Submit Review Form",
  CLOSE_REVIEW_FORM: "Close Review Form",
  SESSION_START: "Session Start",
  MOVE_TO: "Move to",
  CLICK_REVIEW_BUTTON: "Click Review Button",
  UPGRADE_PLAN: "Upgrade Plan",
  SETTING_UPGRADE_PLAN: "Click Upgrade Plan Button",
};

export const EVENT_CONFIG = {
  MOVE_TO: "Block_MoveTo_",
  CLICK_REVIEW_BUTTON: "Settings_ClickReviewButton",
  SUBMIT_REVIEW_FORM: "CreatorSendReview_SubmitReview",
  CLOSE_REVIEW_FORM: "CreatorSendReview_CloseReviewForm",
  ASK_TO_REVIEW_SUBMIT_REVIEW: "AskToReview_SubmitReview",
  ASK_TO_REVIEW_CLOSE_REVIEW_FORM: "AskToReview_CloseReviewForm",
  CREATOR_SEND_REVIEW_SUBMIT_REVIEW: "CreatorSendReview_SubmitReview",
  CREATOR_SEND_REVIEW_CLOSE_REVIEW_FORM: "CreatorSendReview_CloseReviewForm",
  THEME_TEMPLATE: "ThemeTemplate",
  SETTINGS: "Settings",
}

export const submitEvent = (
  category,
  event,
  label = null,
  action = null,
  parameterOpts = {}
) => {
  if (
    (typeof window !== "undefined" && !window.gtag) ||
    typeof window.gtag !== "function"
  )
    return;
  try {
    window.gtag("event", event, {
      event_category: category,
      event_label: label,
      event_action: action,
      ...parameterOpts,
    });
  } catch (e) {}
};

export const submitEventV2 = (
  profile,
  category,
  eventName,
  label = null,
  action = null,
  parameterOpts = {}
) => {
  if (
    (typeof window !== "undefined" && !window.gtag) ||
    typeof window.gtag !== "function"
  )
    return;
  try {
    window.gtag("event", eventName, {
      event_category: category,
      event_action: action,
      event_label: label,
      // domain: profile?.domain,
      seller_id: profile?.id,
      site_id: profile?.site_id,
      // country: profile?.country,
      ...parameterOpts,
    });
  } catch (e) {}
};

export const submitEventSearch = (location, page, searchTerm) => {
  if (
    (typeof window !== "undefined" && !window.gtag) ||
    typeof window.gtag !== "function"
  )
    return;
  try {
    // window.gtag("event", "page_view", {
    //   search_term: searchTerm,
    //   page_location: `${location}/${page}?keyword=${searchTerm}`,
    //   page_path: page,
    // });
    window.gtag("event", "view_search_results", {
      search_term: searchTerm,
      page_location: `${location}/${page}?keyword=${searchTerm}`,
      page_path: page,
    });
  } catch (e) {}
};
export default { submitEvent, submitEventV2, submitEventSearch };
