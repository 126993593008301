import {
  InformationIcon,
  InformationTriangleIcon,
  SampleSelectionIcon,
  ButtonLinkIcon,
  AddIcon,
  IconDefault,
} from "@share/icons";
import FullScreenPopup from "@share/components/full-screen-popup";
import IconRender from "@share/IconRender";

import { mockUpload } from "@share/lib";
import {
  Button,
  Divider,
  Image,
  ImageUploader,
  Input,
  Modal,
  TextArea,
  Checkbox,
  Popover,
} from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import {
  LINK_STYLE_DISPLAY,
  LINK_TEMPLATE,
  LINK_TEMPLATE_ARR,
} from "@share/configs/const";
import { BLOCK_TYPE } from "@features/block/block.conf";
import LinkTemplateSelection from "./LinkTemplateSelection";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import FormInput from "@features/block/components/FormInput";
import PopupComponent from "@share/components/PopupComponent";
import { checkOpenNewTab } from "@share/helper/validator";
import ListIconBuilder from "@share/components/ListIconBuilder";

const LINK_TITLE_MAXIMUM = 100;
const LINK_DESCRIPTION_MAXIMUM = 250;

const ButtonMultiLinksItemAddNew = (props) => {
  const {
    callBackCancelLinkAddNew,
    handleSaveLinkAddNew,
    linkEditData,
    blockType,
    styleOfParent,
  } = props;
  const { t } = useTranslation();

  const UID = `${blockType}-${uuidv4()}`;
  const style = styleOfParent?.style_attributes?.button_link_style;
  const linkUrlDefault = linkEditData?.content_attributes?.link_url || "";
  const valueUrlImageDefault = linkEditData?.content_attributes?.image || "";
  const linkTitleDefault = linkEditData?.content_attributes?.link_title || "";
  const linkDescriptionDefault =
    linkEditData?.content_attributes?.link_description || "";
  const buttonIconDefault = linkEditData?.content_attributes?.button_icon || "";
  const defaultLinkData = linkEditData?.content_attributes?.link_data || {
    type: LinkTypeEnum.WEB,
    value: linkUrlDefault,
  };
  const openInNewTabDefault =
    linkEditData?.content_attributes?.open_new_tab || false;

  const linkTemplateDefault =
    linkEditData?.style_attributes?.button_link_template ||
    LINK_TEMPLATE.TEMPLATE1;
  const buttonIconUrlDefault =
    linkEditData?.content_attributes?.button_icon_url || null;
  const hasAffiliateUrlDefault = linkEditData
    ? linkEditData?.content_attributes?.use_aff_url === 1
    : true;

  const [valueUrlImage, setValueUrlImage] = useState(valueUrlImageDefault);
  const [valueNameImage, setValueNameImage] = useState("");
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [linkUrl, setLinkUrl] = useState(linkUrlDefault);
  const [linkTitle, setLinkTitle] = useState(linkTitleDefault);
  const [linkDescription, setLinkDescription] = useState(
    linkDescriptionDefault
  );
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isDisableBtnSave, setIsDisableBtnSave] = useState(false);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [linkTemplate, setLinkTemplate] = useState(linkTemplateDefault);

  const [isOpenTemplateSelection, setIsOpenTemplateSelection] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(buttonIconDefault);
  const [buttonIconUrl, setButtonIconUrl] = useState(buttonIconUrlDefault);

  const [isOpenSelectedIcon, setIsOpenSelectedIcon] = useState(false);
  const [hasAffiliateUrl, setHasAffiliateUrl] = useState(
    hasAffiliateUrlDefault
  );
  const [linkData, setLinkData] = useState<LinkDataInterface>(defaultLinkData);

  const [isEcommerce, setIsEcommerce] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);
  const checkEcommerce = (data) => {
    if (data) {
      try {
        const ecommerceOrSocial = new URL(data);
        setIsEcommerce(checkOpenNewTab(ecommerceOrSocial));
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (linkData && linkData.type == LinkTypeEnum.WEB) {
      checkEcommerce(linkData.target_url);
    }
  }, [linkData]);

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
    }
  };

  const handleSaveButtonMultiLinks = () => {
    const defaultData = {
      uid: UID,
      sort_order: 1,
      enable: 1,
      block_type: BLOCK_TYPE.BUTTON_LINK,
      title: "Link",
      content_attributes: {
        button_icon: buttonIcon,
        button_icon_url: buttonIconUrl,
        use_aff_url:
          hasAffiliateUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
        image: valueImageBase64 || valueUrlImage,
        image_name: valueNameImage,
        image_base64: valueImageBase64,
        link_title: linkTitle,
        link_url: linkUrl,
        link_description: linkDescription,
        link_data: linkData,
        open_new_tab: isEcommerce ? true : openNewTab,
      },
      style_attributes: {
        ...styleOfParent.style_attributes,
        button_link_template: linkTemplate,
      },
    };
    if (linkEditData) {
      handleSaveLinkAddNew({
        uid: linkEditData.uid,
        sort_order: linkEditData.sort_order,
        enable: linkEditData.enable,
        block_type: BLOCK_TYPE.BUTTON_LINK,
        title: "Link",
        content_attributes: {
          image: valueImageBase64 || valueUrlImage,
          image_name: valueNameImage,
          image_base64: valueImageBase64,
          link_title: linkTitle,
          link_url: linkUrl,
          link_description: linkDescription,
          button_icon: buttonIcon,
          button_icon_url: buttonIconUrl,
          use_aff_url:
            hasAffiliateUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
          link_data: linkData,
          open_new_tab: isEcommerce ? true : openNewTab,
        },
        style_attributes: {
          ...styleOfParent.style_attributes,
          button_link_template: linkTemplate,
        },
      });
    } else {
      handleSaveLinkAddNew(defaultData);
    }
    callBackCancelLinkAddNew();
    setValueUrlImage(null);
    setUploadedImageArr([]);
    setValueImageBase64(null);
  };

  const handleCancelLinkAddNew = () => {
    if (
      (valueUrlImage && valueUrlImage !== valueUrlImageDefault) ||
      linkUrl !== linkUrlDefault ||
      linkTitle !== linkTitleDefault ||
      linkDescription !== linkDescriptionDefault ||
      openNewTab !== openInNewTabDefault
    ) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="var(--adm-color-primary)"
            onClick={() => {}}
          />
        ),
        title: (
          <div>{t("ss_builder_confirm-cancel_image_slider_add_new_title")}</div>
        ),
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_confirm-cancel_image_slider_add_new_content")}
          </div>
        ),
        confirmText: t(
          "ss_builder_confirm_cancel_image_slider_add_new_btn_label"
        ),
        cancelText: t("ss_builder_cancel_image_slider_add_new_btn_label"),
        onConfirm: () => {
          callBackCancelLinkAddNew();
          setValueUrlImage(null);
          setUploadedImageArr([]);
          setValueImageBase64(null);
        },
        onCancel: () => {
          return;
        },
      });
    } else {
      callBackCancelLinkAddNew();
      setValueUrlImage(null);
      setUploadedImageArr([]);
      setValueImageBase64(null);
    }
  };

  const handleChangeLinkDescription = (val) => {
    if (val?.length <= LINK_DESCRIPTION_MAXIMUM) {
      setLinkDescription(val);
    }
  };

  const handleChangeLinkTitle = (val) => {
    if (val?.length <= LINK_TITLE_MAXIMUM) {
      setLinkTitle(val);
    }
  };
  const handleCheckAffiliateUrl = (data) => {
    setHasAffiliateUrl(data);
  };

  const handleOpenSelectedIcon = () => {
    setIsOpenSelectedIcon(true);
  };
  const handleCancelListIcon = () => {
    setIsOpenSelectedIcon(false);
  };

  const handleConfirmListIcon = (data) => {
    const { valueIcon, valueUrlIcon } = data;
    setButtonIcon(valueIcon);
    setButtonIconUrl(valueUrlIcon);
  };

  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
  };

  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    if (linkData.type === LinkTypeEnum.WEB) {
      setLinkUrl(linkData.value);
    } else {
      setLinkUrl(null);
    }
    setLinkData(linkData);
  };

  const isSaveButtonEnable = useMemo(() => {
    const hasImg =
      (styleOfParent.style_attributes.button_link_style ===
        LINK_STYLE_DISPLAY.TEXT_AND_IMAGE ||
        styleOfParent.style_attributes.button_link_style ===
          LINK_STYLE_DISPLAY.ONLY_IMAGE) &&
      !linkEditData?.content_attributes?.image
        ? uploadedImageArr?.length
        : true;
    if (
      (linkUrl || linkData.value) &&
      (valueUrlImage !== valueUrlImageDefault ||
        linkDescription !== linkDescriptionDefault ||
        linkTitle !== linkTitleDefault ||
        linkUrl !== linkUrlDefault ||
        JSON.stringify(linkData) !== JSON.stringify(defaultLinkData) ||
        buttonIcon !== buttonIconDefault ||
        linkTemplate !== linkTemplateDefault ||
        hasAffiliateUrl !== hasAffiliateUrlDefault ||
        openNewTab !== openInNewTabDefault) &&
      !isValidUrl &&
      !isDisableBtnSave &&
      hasImg
    ) {
      return true;
    } else return false;
  }, [
    valueUrlImage,
    valueUrlImageDefault,
    isValidUrl,
    isDisableBtnSave,
    linkDescription,
    linkDescriptionDefault,
    linkTitle,
    linkTitleDefault,
    linkUrl,
    linkUrlDefault,
    buttonIcon,
    buttonIconDefault,
    linkTemplate,
    linkTemplateDefault,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    linkData,
    defaultLinkData,
    openNewTab,
    openInNewTabDefault,
  ]);

  useEffect(() => {
    setIsDisableBtnSave(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setIsLoadingBtn(isLoadingBtn);
  }, [isLoadingBtn]);

  const onAffLoading = useCallback(
    (isLoading) => {
      setIsDisableBtnSave(isLoading);
      setIsLoadingBtn(isLoading);
    },
    [setIsDisableBtnSave, setIsLoadingBtn]
  );

  return (
    <div className="z-[2]">
      <div
        className="flex flex-row text-center items-center justify-center py-3 gap-[10px] relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <ButtonLinkIcon className="" />
        <div
          className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[70vw] md:max-w-[20vw]"
          style={{ fontSize: "18px" }}
        >
          Link
        </div>
      </div>
      <div className="pb-[70px]">
        <div className="p-4 bg-white my-3">
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_sample_selection_label")}
          </div>
          <div>
            <Image
              src={
                LINK_TEMPLATE_ARR?.find((x) => x.value === linkTemplate)?.image
              }
            />
          </div>
          <div>
            <Button
              block
              color="default"
              size="middle"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
                gap: "5px",
                borderColor: "var(--adm-color-primary)",
                color: "var(--adm-color-primary)",
              }}
              onClick={() => setIsOpenTemplateSelection(true)}
            >
              <SampleSelectionIcon
                className="w-[17px] h-[17px]"
                fillColor="var(--adm-color-primary)"
              />
              <div>{t("ss_builder_sample_change_label")}</div>
            </Button>
          </div>
        </div>
        <div className=" bg-white mt-3 px-4 py-[13px]">
          <>
            {(style === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE ||
              style === LINK_STYLE_DISPLAY.ONLY_IMAGE) && (
              <>
                <Divider />
                <div className="">
                  <div className="text-[15px] leading-[150%] text-[#666666]">
                    <span className="text-red-600">*</span>
                    {t("ss_builder_image_selection_label")}
                  </div>
                  <div className="grid grid-cols-[120px_auto] gap-4">
                    <Image
                      src={
                        valueUrlImage ??
                        "https://i.ecomobi.com/ssp/passio-page/image-default.png"
                      }
                      fit="cover"
                      style={{
                        borderRadius: "8px",
                        maxWidth: "120px",
                        maxHeight: "120px",
                        backgroundColor: "#EEEEEE",
                        border: "1px solid #EEEEEE",
                      }}
                    />
                    <div>
                      <div className="mb-8 leading-4 text-[#8C8C8C] text-xs">
                        {t("ss_builder_theme_setting_background_content")}
                      </div>
                      <div className="flex gap-2">
                        <ImageUploader
                          onChange={handleImageUrl}
                          upload={(file) => mockUpload(file, 514, 980)}
                          className="flex-grow"
                          value={uploadedImageArr}
                          children={
                            <Button
                              color="primary"
                              fill="solid"
                              size="middle"
                              className="w-full"
                            >
                              {t("ss_builder_upload_button_label")}
                            </Button>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {isValidFileFormat && (
                    <div className="flex flex-row gap-1 items-center mt-1">
                      <InformationTriangleIcon
                        fillColor="#FF3141"
                        className="w-4 h-4"
                      />
                      <div className="text-[#FF3141]">
                        {t("ss_builder_upload_file_format_invalid_content")}
                      </div>
                    </div>
                  )}
                  {isValidFileSize && (
                    <div className="flex flex-row gap-1 items-center mt-1">
                      <InformationTriangleIcon
                        fillColor="#FF3141"
                        className="w-4 h-4"
                      />
                      <div className="text-[#FF3141]">
                        {t("ss_builder_upload_file_size_invalid_content")}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
          {(style === LINK_STYLE_DISPLAY.ONLY_ICON ||
            style === LINK_STYLE_DISPLAY.TEXT_AND_ICON) && (
            <>
              <Divider />

              <div>
                <div className="text-[15px] text-[#666666]">
                  <span className="text-red-600">*</span>
                  {t("ss_builder_button_link_icon_title")}
                </div>
                <div className="flex flex-row items-center gap-4">
                  <Button
                    style={{
                      backgroundColor: "#F0F0F0",
                      border: "none",
                    }}
                  >
                    {buttonIcon ? (
                      <IconRender iconName={buttonIcon} />
                    ) : (
                      <IconDefault
                        className=""
                        width={24}
                        height={24}
                      />
                    )}
                  </Button>
                  <div className="flex flex-row gap-2 justify-between grow">
                    <Button
                      block
                      color="primary"
                      size="middle"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      onClick={handleOpenSelectedIcon}
                    >
                      <AddIcon className="w-[18px] h-[18px]" />
                      <div className="text-[17px] leading-6">
                        {t("ss_builder_button_link_icon_btn_selection_label")}
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
          {style !== LINK_STYLE_DISPLAY.ONLY_ICON &&
            style !== LINK_STYLE_DISPLAY.ONLY_IMAGE && (
              <>
                <Divider />
                <div>
                  <span className="text-[15px] leading-[13px] text-[#666666]">
                    {style === LINK_STYLE_DISPLAY.ONLY_TEXT && (
                      <span className="text-red-600">*</span>
                    )}
                    {t("ss_builder_card_add_new_title_label")}
                  </span>
                  <Input
                    placeholder={t("ss_builder_block_heading_placeholder")}
                    onChange={handleChangeLinkTitle}
                    value={linkTitle}
                    maxLength={LINK_TITLE_MAXIMUM}
                  />
                  <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
                    linkTitle ? linkTitle.length : 0
                  }/${LINK_TITLE_MAXIMUM}`}</div>
                </div>
              </>
            )}
          {style !== LINK_STYLE_DISPLAY.ONLY_ICON &&
            style !== LINK_STYLE_DISPLAY.ONLY_IMAGE && (
              <>
                <Divider />
                <div>
                  <span className="text-[15px] leading-[13px] text-[#666666]">
                    {t("ss_builder_card_add_new_description_label")}
                  </span>
                  <TextArea
                    placeholder={t("ss_builder_block_description_placeholder")}
                    onChange={handleChangeLinkDescription}
                    value={linkDescription}
                    maxLength={LINK_DESCRIPTION_MAXIMUM}
                  />
                  <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
                    linkDescription ? linkDescription.length : 0
                  }/${LINK_DESCRIPTION_MAXIMUM}`}</div>
                </div>
              </>
            )}
          <Divider />
          <FormInput
            label={t("ss_builder_button_link_icon_origin_url_label")}
            isRequire={true}
          >
            <LinkInput
              item={linkData}
              onChange={(item) => {
                onUpdateLinkData(item);
              }}
              onLoading={onAffLoading}
              onError={(errorMessage) => {
                setIsValidUrl(!!errorMessage);
              }}
            />
          </FormInput>
          {linkData.type === LinkTypeEnum.WEB && (
            <>
              <Divider />
              <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                <Checkbox
                  checked={
                    typeof hasAffiliateUrl !== "boolean"
                      ? true
                      : hasAffiliateUrl
                  }
                  onChange={handleCheckAffiliateUrl}
                >
                  <div className="text-left">
                    {t("ss_builder_use_affiliate_link_label")}
                  </div>
                </Checkbox>
                <Popover
                  content={t("ss_builder_use_affiliate_link_description")}
                  placement="top-start"
                  mode="dark"
                  trigger="click"
                >
                  <InformationIcon
                    className="ml-1 w-[14px] h-[14px]"
                    onClick={() => {}}
                    fillColor="#1890FF"
                  />
                </Popover>
              </div>
              <Divider />
              {!isEcommerce && (
                <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                  <Checkbox
                    checked={!!openNewTab}
                    onChange={handleChangeOpenNewTab}
                  >
                    <div className="text-left">
                      {t("ss_builder_open_new_tab_label")}
                    </div>
                  </Checkbox>
                  <Popover
                    content={t("ss_builder_open_new_tab_description")}
                    placement="top-start"
                    mode="dark"
                    trigger="click"
                  >
                    <InformationIcon
                      className="ml-1 w-[14px] h-[14px]"
                      onClick={() => {}}
                      fillColor="#1890FF"
                    />
                  </Popover>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={handleCancelLinkAddNew}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={!isSaveButtonEnable}
            color={"primary"}
            onClick={handleSaveButtonMultiLinks}
            loading={isLoadingBtn}
          >
            {t("ss_builder_editor_btn-save")}
          </Button>
        </div>
      </div>
      <PopupComponent
        visible={isOpenSelectedIcon}
        onMaskClick={() => handleCancelListIcon()}
        title={t("ss_builder_panel_add_icon_tab_title")}
        onClose={() => handleCancelListIcon()}
        classNameBody="h-[80vh] popup-hard"
      >
        <ListIconBuilder
          callbackCancelListIcon={handleCancelListIcon}
          callbackConfirmListIcon={handleConfirmListIcon}
          defaultNameIcon={buttonIcon}
        />
      </PopupComponent>

      <FullScreenPopup visible={isOpenTemplateSelection}>
        <LinkTemplateSelection
          linkTemplateDefault={linkTemplate}
          onUpdateLinkTemplate={(val) => setLinkTemplate(val)}
          onCancelLinkTemplate={() => setIsOpenTemplateSelection(false)}
        />
      </FullScreenPopup>
    </div>
  );
};

export default ButtonMultiLinksItemAddNew;
