export const PASSIO_DEFAULT_ICON = process.env.NEXT_PUBLIC_PASSIO_DEFAULT_ICON;
export const OLD_PASSIO_DEFAULT_ICON =
  process.env.NEXT_PUBLIC_OLD_PASSIO_DEFAULT_ICON;
export const CREATOR_FOURTEEN_DEFAULT_ICON =
  process.env.NEXT_PUBLIC_CREATOR_FOURTEEN_DEFAULT_ICON;

export const PRODUCT_MAX_DEFAULT = 12;
export const PRODUCT_MAX = 400;
export const MAX_PRODUCTS_ALL_GROUPS = 5000;
export const PRODUCT_GROUP_MAXIMUM = 30;
export const social_link_MAX = 6;
export const EDITOR_MAX_LENGTH = 500;
export const EDITOR_BLOCK_TEXT_MAX_LENGTH = 5000;

export const BLOCK_NOT_DISPLAY = ["gift_footer, tod_footer, brand_gift_footer"];

export const TAB_ICON_DEFAULT = "star.svg";

export const BLOCK_TYPE_CONFIG = {
  TEXT: "text",
  TAB_BAR: "tab_bar",
  TAB: "tabs",
};

export const THEME_TEMPLATE_KEY = {
  SAVE_NEW: "save-new",
  OVERWRITE: "overwrite",
  EDIT: "edit",
};

export const THEME_RENDER_LIST_KEY = {
  THEME_STORE: "theme-store",
  THEME_OVERWRITE_SELECTION: "theme-overwrite-selection",
};

export const THEME_PREVIEW_BY_IMAGE_KEY = {
  PREVIEW: "preview",
  PREVIEW_AND_APPLY: "preview-and-apply",
  PREVIEW_AND_REPLACE: "preview-and-replace",
};

export const THEME_PREVIEW_KEY = {
  PREVIEW_AND_APPLY: "preview-and-apply",
  PREVIEW_AND_APPLY_CONTENT: "preview-and-apply-content",
};

export const KEY_BLOCK = { EDIT: "edit-block", ADD_NEW: "add-new-block" };
export const KEY_TAB = { EDIT: "edit-tab", ADD_NEW: "add-new-tab" };

export const ICON_RENDER_URL =
  "https://image.passio.eco/page-builder/icon-builder";

export const THEME_SETTING_BACKGROUND_TYPE = {
  COLOR: "color",
  GRADIENT: "gradient",
  IMAGE: "image",
};

export const TAB_BAR_INDEX = {
  DESIGN: 0,
  BACKGROUND: 1,
  TEMPLATE: 2,
  SETTING: 3,
  MANAGEMENT: 4,
};

export const TAB_BAR_KEY = {
  DESIGN: "design",
  BACKGROUND: "background",
  TEMPLATE: "theme-store",
  SETTING: "setting",
  MANAGEMENT: "management",
};

export const FONT_OPTIONS = [
  {
    label: "Roboto",
    value: "Roboto",
  },
  {
    label: "Be Vietnam Pro",
    value: "Be Vietnam Pro",
  },
  {
    label: "Nunito",
    value: "Nunito",
  },
  {
    label: "Dosis",
    value: "Dosis",
  },
  {
    label: "Barlow Condensed",
    value: "Barlow Condensed",
  },
  {
    label: "Play",
    value: "Play",
  },
  {
    label: "Roboto Slab",
    value: "Roboto Slab",
  },
  {
    label: "Lora",
    value: "Lora",
  },
  {
    label: "Crimson Text",
    value: "Crimson Text",
  },
  {
    label: "Alegreya",
    value: "Alegreya",
  },
];

export const IMAGE_ORIENTATION_TYPE = {
  PORTRAIT: "portrait",
  LANDSCAPE: "landscape",
};

export const STYLE_SLIDE_IMAGE = {
  GRID: "grid",
  SLIDE: "slide",
  MASONRY: "masonry",
};

export const CARD_STYLE = {
  GRID: "grid",
  SLIDE: "slide",
};

export const CARD_ITEM_ON_ROW = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
};

export const TAB_DISPLAY = {
  TEXT_AND_ICON: "text-and-icon",
  TEXT: "text",
  ICON: "icon",
};

export const TAB_LAYOUT = {
  TEXT_RIGHT: "text-right",
  TEXT_UNDER: "text-under",
};

export const TAB_GHIM_STYLE = {
  NOT_GHIM: "not-ghim",
  ALWAYS_GHIM: "always-ghim",
  AUTO_GHIM: "auto-ghim",
};

export const TAB_POSITION = {
  TOP: "top",
  BOTTOM: "bottom",
};

export const TAB_WIDTH_DISPLAY = {
  AUTO: "auto",
  EQUAL: "equal",
};

export const SLIDE_STYLE = {
  PARTIAL_VISIBLE_RIGHT: "partial-visible-right",
  PARTIAL_VISIBLE_BOTH_SIDES: "partial-visible-both-sides",
  NOT_PARTIAL_VISIBLE: "not-partial-visible",
  POSTERS: "posters",
  PANORAMA: "panorama",
};

export const CARD_LAYOUTS = {
  LAYOUT_1: "text-below-image-left",
  LAYOUT_2: "text-below-image-center",
  LAYOUT_3: "text-above-image-center",
  LAYOUT_4: "text-above-image-left",
  LAYOUT_5: "text-right",
  LAYOUT_6: "text-left",
  LAYOUT_7: "text-above-image-left-2",
  LAYOUT_8: "text-above-image-center-2",
};

export const PRODUCT_DISPLAY_STYLES = {
  GRID: "grid",
  SLIDE: "slide",
  TEXT_OVER_IMAGE: "text-over-image",
  ZIGZAG: "zigzag",
};

export const PRODUCT_ON_ROW = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
};

export const LINK_STYLE_DISPLAY = {
  TEXT_AND_ICON: "text-and-icon",
  TEXT_AND_IMAGE: "text-and-image",
  ONLY_TEXT: "only-text",
  ONLY_ICON: "only-icon",
  ONLY_IMAGE: "only-image",
};

export const LINK_LAYOUTS = {
  TEXT_RIGHT: "text-right",
  TEXT_LEFT: "text-left",
  TEXT_UNDER: "text-under",
  TEXT_ZIG_ZAG: "text-zig-zag",
};

export const LINK_TEMPLATE = {
  TEMPLATE1: "template1",
  TEMPLATE2: "template2",
  TEMPLATE3: "template3",
};

export const LINK_TEMPLATE_ARR = [
  {
    value: LINK_TEMPLATE.TEMPLATE1,
    image:
      "https://image.passio.eco/page-builder/page-builder/link-template/link-template-1.png",
  },
  {
    value: LINK_TEMPLATE.TEMPLATE2,
    image:
      "https://image.passio.eco/page-builder/page-builder/link-template/link-template-2.png",
  },
  {
    value: LINK_TEMPLATE.TEMPLATE3,
    image:
      "https://image.passio.eco/page-builder/page-builder/link-template/link-template-3.png",
  },
];

export const COUNTRY_VN = "VN";
export const COUNTRY_EN = "OTHER";
export const COUNTRY_ID = "ID";
export const COUNTRY_SG = "SG";
export const COUNTRY_PH = "PH";
export const COUNTRY_MY = "MY";
export const COUNTRY_TH = "TH";

export const GENERAL_SETTING_DEFAULT_VALUE = {
  PRIMARY_COLOR: "#ee3244ff",
  SECONDARY_COLOR: "#0A2463ff",
};

export const BLOCK_SETTING_DEFAULT_VALUE = {
  BLOCK_STYLE: "rounded",
  BORDER_RADIUS: 8,
  BORDER_WIDTH: 1,
  INNER_SPACE: true,
  INNER_SPACE_NUMBER: 12,
  OUTSIDE_SPACE: true,
  OUTSIDE_SPACE_NUMBER: 12,
  BACKGROUND_COLOR: "#FFFFFFBF",
  TAB_BACKGROUND_COLOR: "#FFFFFFFF",
  BACKGROUND_BLUR: 0,
  BORDER_ENABLE: false,
  BORDER_COLOR: "#FFFFFFBF",
  BORDER_STYLE: "solid",
  SHADOW_ENABLE: false,
  SHADOW_COLOR: "#00000040",
  BLOCK_SPACE: 12,
};

export const BACKGROUND_SETTING_DEFAULT_VALUE = {
  BACKGROUND_TYPE: "solid",
  IMAGE: null,
  BLUR: 0,
  OVERLAY_ENABLE: false,
  OVERLAY_COLOR: "#00000066",
  COLOR_SOLID: "#f5f5f5ff",
  COLOR_GRADIENT_GENERATOR_FIRST_COLOR: "#f9a8d4ff",
  COLOR_GRADIENT_GENERATOR_SECOND_COLOR: "#a5b4fcff",
  COLOR_GRADIENT_INTENSITY: 300,
  COLOR_GRADIENT_DIRECTION: "to bottom",
};

export const FONT_GOOGLE = 2;
export const FONT_AVAILABLE = 1;

export const TITLE_SETTING_DEFAULT_VALUE = {
  FONT_FAMILY: "roboto",
  FONT_COLOR: "#333333ff",
  FONT_SIZE: 13,
  FONT_SCALE: "1.200",
  FONT_WEIGHT: 500,
  LINE_HEIGHT: 1.3,
  USING_FONT_CUSTOM: FONT_AVAILABLE,
  FONT_FAMILY_CUSTOM: "Roboto",
};

export const PARAGRAPH_SETTING_DEFAULT_VALUE = {
  FONT_FAMILY: "Roboto",
  FONT_COLOR: "#333333ff",
  FONT_SIZE: 13,
  FONT_SCALE: "1.200",
  FONT_WEIGHT: 400,
  LINE_HEIGHT: 1.5,
  USING_FONT_CUSTOM: FONT_AVAILABLE,
  FONT_FAMILY_CUSTOM: "Roboto",
};

export const BUTTON_SETTING_SHADOW_STYLE = {
  NO_SHADOW: "no_shadow",
  SOFT_SHADOW: "soft_shadow",
  HARD_SHADOW: "hard_shadow",
};

export const BUTTON_SETTING_DEFAULT_VALUE = {
  ENABLE_BORDER: true,
  BORDER_COLOR: "#EE3244ff",
  BACKGROUND_COLOR: "#EE3244ff",
  TEXT_COLOR: "#FFFFFFff",
  BORDER_WIDTH: 1,
  RADIUS_TYPE: "rounded",
  RADIUS_VALUE: 8,
  SHADOW_TYPE: BUTTON_SETTING_SHADOW_STYLE.NO_SHADOW,
  SOFT_SHADOW_COLOR: "#00000026",
  HARD_SHADOW_COLOR: "#000000",
};

export const BIG_BUTTON_SETTING_DEFAULT_VALUE = {
  FONT_SIZE: 18,
  LINE_HEIGHT: 1.4,
  FONT_WEIGHT: 500,
};

export const MEDIUM_BUTTON_SETTING_DEFAULT_VALUE = {
  FONT_SIZE: 17,
  LINE_HEIGHT: 1.4,
  FONT_WEIGHT: 400,
};

export const SMALL_BUTTON_SETTING_DEFAULT_VALUE = {
  FONT_SIZE: 15,
  LINE_HEIGHT: 1.4,
  FONT_WEIGHT: 400,
};

export const BUTTON_SETTING_RADIUS_STYLE = {
  SQUARE: "square",
  ROUNDED: "rounded",
  CUSTOM: "custom",
};

export const IS_ONBOARDING = 2;

export const FINISH_ONBOARDING = 1;

export const INITIAL_ONBOARDING = 0;

export const BIO_DISPLAY_STYLES = {
  STYLE1: "style-1",
  STYLE2: "style-2",
  STYLE3: "style-3",
  STYLE4: "style-4",
  STYLE5: "style-5",
  STYLE6: "style-6",
  STYLE7: "style-7",
};

export const ACTION_BLOCK = {
  VISIBLE_BLOCK: "eye-visible-block",
  MOVE_UP_BLOCK: "move-up-block",
  MOVE_DOWN_BLOCK: "move-down-block",
  EDIT_BLOCK: "edit-block",
  MOVE_BLOCK: "move-block",
  DELETE_BLOCK: "delete-block",
  // CREATE_NEW_TEMPLATE:"create-new-template",
  HANDLE_TEMPLATE: "handle-template",
  UPLOAD_AFFILIATE_PRODUCT: "upload-affiliate-product",
  IMPORT_SITE_URL: "import-site-url",
};

export const ENABLE_BLOCK = 1;
export const DISABLE_BLOCK = 2;

export enum STATUS {
  ENABLE = 1,
  DISABLE = 2,
}

export const POSITION_MAIN_LAYOUT = {
  HEADER: "header",
  BODY: "body",
  FOOTER: "footer",
  EXPAND: "expand",
};

export const MAX_NUMBER_BLOCK = {
  HEADER: 4,
  BODY: 10,
  FOOTER: 4,
  EXPAND: 5,
};

export const FILLCOLOR_DEFAULT_TAB = "#ee3244";
export const ENDABLE = 1;
export const DISABLE = 2;

export const BUILDER_IMAGE_HOST =
  process.env.BUILDER_IMAGE_HOST ||
  "https://passio-prod.s3-ap-southeast-1.amazonaws.com";
export const PASSIO_IMAGE_HOST =
  process.env.PASSIO_IMAGE_HOST || "https://image.passio.eco";

export const MULTI_BUTTON_LINK_ITEM_ON_ROW = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
};

export const MULTI_LINK_MAX = 30;

export const BLOCK_TAB_MENU_DESIGN_CLASS = "block-tab-menu-design";
export const MAX_WIDTH_OUTSIDE_ON = 476;

export const MAX_WIDTH_BODY = "500px";

export const TAB_TEMPLATE = {
  TEMPLATE_1: "template_1",
  TEMPLATE_2: "template_2",
};

export const TAB_TEMPLATE_LIST = [
  {
    value: TAB_TEMPLATE.TEMPLATE_1,
    image:
      "https://image.passio.eco/page-builder/page-builder/tab-template/tab_template_1.svg",
  },
  {
    value: TAB_TEMPLATE.TEMPLATE_2,
    image:
      "https://image.passio.eco/page-builder/page-builder/tab-template/tab_template_2.svg",
  },
];

export const TAB_SETTING = {
  SHADOW_ENABLE: false,
  SHADOW_COLOR: "#00000026",
};

export const IS_PRODUCT_TOUR = 2;

export const FINISH_PRODUCT_TOUR = 1;

export const ENABLE_POSITION = 1;

export const DISABLE_POSITION = 0;

export const BLOCK_FILTER_GROUP_TYPE = {
  ALL: "all",
  BASIC: "basic",
  ADVANCED: "advanced",
  MONETIZATION: "monetization",
  HIGHLIGHT: "highlight",
};

export const MAX_WIDTH_CONTENT_LAYOUT = 500;

export const LINK_TYPE_LIST = {
  WEB: "web",
  PHONE: "phone",
  EMAIL: "email",
  SERVICE: "service",
};

export const SOCIAL_LINK = {
  FACEBOOK: "facebook",
  MESSENGER: "messenger",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  PINTEREST: "pinterest",
  SNAPCHAT: "snapchat",
  LINECHAT: "linechat",
  TIKTOK: "tiktok",
  TWITCH: "twitch",
  TWITTER: "twitter",
  WHATSAPP: "whatsapp",
  YOUTUBE: "youtube",
  DISCORD: "discord",
  BEHANCE: "behance",
  DRIBBLE: "dribble",
  EMAIL: "email",
  TEL: "tel",
  LEMON8: "lemon8",
  OTHER: "other",
};
export const LIST_ECOMMERCE = ["shopee", "lazada", "tiki", "youtube", "tiktok"];

export const PRODUCT_URL_CHECKING = {
  INVALID: "invalid",
  DUPLICATE: "duplicate",
  NOT_ADDED_YET: "not_added_yet",
  SUCCESS: "success",
  MISSING_REQUIRED: "missing_required",
};

export const STOP_DETECTING = "stop-detecting";
export enum DETECT_URL_PROCESS_STATUS {
  STOP_DETECTING = "stop-detecting",
  CONTINUE = "continue",
}
export enum PRODUCT_URL_CHECKING_STATUS {
  INVALID = "invalid",
  DUPLICATE = "duplicate",
  DUPLICATE_PRODUCT = "duplicate_product",
  SINGLE_URL_DUPLICATE_PRODUCT = "single_dup",
  NOT_ADDED_YET = "not_added_yet",
  SUCCESS = "success",
  MAXIMUM_OR_NOT_EXIST_GROUP = "maximum_or_not_exist_group",
}

export const countryCurrencyMap = {
  [COUNTRY_VN]: "VND",
  [COUNTRY_ID]: "IDR",
  [COUNTRY_PH]: "PHP",
  [COUNTRY_TH]: "THB",
  [COUNTRY_SG]: "SGD",
  [COUNTRY_MY]: "MYR",
  [COUNTRY_EN]: "OTH",
};

export const DONATION = "donation";
export const GIFT = "gift";
export const TRUTH_OR_DATE = "tod";

export const LABEL_PRODUCT = {
  HOT: "hot",
  SALE: "sale",
  FAVOURITE: "favourite",
};

export const AFFILIATE_LAYOUT_MAX_HEIGHT = 1260;
export const HIDE_THEME_TEMPLATE = "hide";
export const SHOW_THEME_TEMPLATE = "show";
export const POSITION_STICKY = "sticky";
export const TAB_STICKY_CLASS = "tab-sticky";

export const POSITION_DROPPABLE_ID = {
  HEADER: "designs-header",
  BODY: "designs-body",
  FOOTER: "designs-footer",
  EXPAND_POPUP: "expand-popup",
  EXPAND_FLOATING_ICONS: "expand-floating-icons",
  ADD_NEW_BLOCK_BUTTON_DRAGGING: "add-new-block-button-dragging",
};

export const DOMAIN_RESIZE_IMG = `https://image.passio.eco/passio-prod`;
export const ROUND_PHOTO = 1;
export const SQUARE_PHOTO = 2;
export const SMAll = "small";
export const MIDDLE = "middle";
export const BIG = "big";

export const TEMPLATE_KEY = {
  SAVE_NEW: "save-new",
  OVERWRITE: "overwrite",
  EDIT: "edit",
};

export const TEMPLATE_PREVIEW_BY_IMAGE_KEY = {
  PREVIEW: "preview",
};

export const TEMPLATE_RENDER_LIST_KEY = {
  TEMPLATE_LIST: "template-list",
  TEMPLATE_OVERWRITE_SELECTION: "template-overwrite-selection",
  TEMPLATE_APPLY: "template-apply",
};

export const STYLE_ICON = {
  LINE: "line",
  SOLID: "solid",
};

export const STYLE_ICON_TITLE = {
  LINE: "Line",
  SOLID: "Solid",
};

export const VISIBLE_ICON = {
  SHOW: "show",
  HIDE: "hide",
};

export const PACKAGE_TYPE = {
  PREMIUM: 1,
  FREE: 2,
};

export const KEY_IS_LOGIN = "is_login";
export const USER_DEVICE = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile",
};

export const MAX_WIDTH_SCREEN_SIZE = {
  MOBILE: 480,
  DESKTOP: 768,
};

export const SLIDE = "slide";

export const IS_DISPLAY_IMPORT_PRODUCT_FILE = 1;

export const GG_FONT_URI = "https://fonts.googleapis.com/css";
