import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { placeHolderImageIcon } from "@share/icons/product-tour-icon";
import {
  AFFILIATE_GROUP_MANAGEMENT_TOUR_CLASS_NAME,
  steps,
  STEP_INDEX,
} from "./group-tour-config";
import ProductTourComponent from "@share/components/ProductTourComponent";

const AffiliateProductGroupTour = (props) => {
  const { handleHideProductGroupTour } = props;
  const { t } = useTranslation();
  const productGroupTourRef = useRef(null);

  const setRef = (ref) => {
    productGroupTourRef.current = ref;
    const fullscreenContentElement = document.getElementsByClassName(
      "full-screen-popup-content"
    );
    if (productGroupTourRef.current) {
      productGroupTourRef.current.introJs._targetElement =
        fullscreenContentElement[fullscreenContentElement?.length - 1];
    }
  };

  const stepsDefault = steps.map((ele) => ({
    title:
      `<div class="wrapper-image">` +
      placeHolderImageIcon +
      `
        <img class="img-product-tour" src=${ele.image_header} /> </div>
        <div class="title">${t(ele.title_key)}<div/>`,
    element: ele.using_selector
      ? document.querySelector(`.${ele.element_class}`)
      : `.${ele.element_class}`,
    intro: `<div> ${t(ele.intro_key)}<div/>`,
    tooltipClass: `tooltip-product-tour ${ele.tooltip_class}`,
    position: ele.position,
  }));

  const onExit = () => {
    handleHideProductGroupTour();
  };
  const onBeforeExit = (stepIndex) => {
    const castWindow = window as any;
    if (stepIndex === STEP_INDEX.STEP_1) {
      castWindow.triggerVisibleAffiliatePopoverProductGroup(false);
    }
  };

  const onChangeProductTour = (nextStepIndex, nextElement) => {
    const fullScreenPopupContent = document.querySelectorAll(
      ".full-screen-popup-content"
    ) as any;

    const popupConentScrollHeight =
      fullScreenPopupContent[fullScreenPopupContent?.length - 1].scrollTop;

    const tooltipElement = document.querySelectorAll(
      ".introjs-fixedTooltip"
    ) as any;

    if (tooltipElement) {
      if (nextStepIndex !== STEP_INDEX.STEP_1) {
        for (let i = 0; i < tooltipElement?.length; i++) {
          tooltipElement[i].style.cssText = `top:${
            nextElement.getBoundingClientRect().top +
            popupConentScrollHeight -
            5
          }px !important`;
          tooltipElement[i].style.position = "absolute";
          tooltipElement[i].style.background = "#FFFFFF";
        }
      } else {
        for (let i = 0; i < tooltipElement?.length; i++) {
          tooltipElement[i].style.cssText = "";
          tooltipElement[i].style.position = "";
          tooltipElement[i].style.background = "";
        }
      }
    }
    const castWindow = window as any;
    if (nextStepIndex === STEP_INDEX.STEP_1) {
      castWindow.triggerVisibleAffiliatePopoverProductGroup(false);
    }
  };

  const onCompleteProductTour = () => {
    const castWindow = window as any;
    castWindow.triggerVisibleAffiliatePopoverProductGroup(false);
    handleHideProductGroupTour();
  };

  const onBeforeChange = async (nextStepIndex, nextElement) => {
    const castWindow = window as any;
    if (nextStepIndex === STEP_INDEX.STEP_2) {
      castWindow.triggerVisibleAffiliatePopoverProductGroup(true);
      const scrollElement = document.getElementById(
        "product-group-scroll-element"
      );
      scrollElement.scrollIntoView(true);
      await new Promise((resolve, reject) => {
        productGroupTourRef.current?.updateStepElement(1);
        setTimeout(() => resolve({}), 10);
      });
    }
    if (nextStepIndex === STEP_INDEX.STEP_1) {
      castWindow.triggerVisibleAffiliatePopoverProductGroup(false);
    }
  };

  return (
    <ProductTourComponent
      steps={stepsDefault}
      onExit={onExit}
      onBeforeExit={onBeforeExit}
      onChange={onChangeProductTour}
      onComplete={onCompleteProductTour}
      onBeforeChange={onBeforeChange}
      setRef={setRef}
    />
  );
};

export default AffiliateProductGroupTour;
