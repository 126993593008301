import {
  CheckCircleIcon,
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  GlobalIcon,
  InformationIcon,
  InformationTriangleIcon,
  MoreIcon,
  TickIcon,
} from "@share/icons";
import { List, Modal, Popover, Toast } from "antd-mobile";
import React, { useCallback, useMemo, useRef } from "react";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { socialLinkInterface } from "@features/main/store/interface";
import {
  BehanceIcon,
  DiscordIcon,
  DribbleIcon,
  EmailIcon,
  FacebookIcon,
  GeneralLinkIcon,
  InstagramIcon,
  Lemon8Icon,
  LineChatIcon,
  LinkedInIcon,
  MessengerIcon,
  PinterestIcon,
  SnapchatIcon,
  TelIcon,
  TiktokIcon,
  TwitchIcon,
  TwitterIcon,
  WhatsappIcon,
  YoutubeIcon,
} from "@share/icons/social-network-icon-svg";
import { LinkTypeEnum } from "@features/block/components/LinkInput";
import { getServiceLabelKey } from "@features/block/components/LinkInput/components/ServiceSelectorPopup";
import { SOCIAL_LINK } from "@share/configs/const";

interface socialLinkItemsProps {
  socialLinkData: socialLinkInterface;
  index: number;
  onRemoveItem: any;
  onDisableEnableSocialLink: any;
  callbackOpenEditSocialLink: any;
}

const socialLinkItems = (props: socialLinkItemsProps) => {
  const { t } = useTranslation();

  const {
    index,
    socialLinkData,
    onRemoveItem,
    onDisableEnableSocialLink,
    callbackOpenEditSocialLink,
  } = props;

  const isEnable = !!(socialLinkData && socialLinkData.enable === 1);

  const popoverRef = useRef(null);

  const actions: Action[] = [
    {
      key: "eye-visible-social-network",
      icon: !isEnable ? (
        <EyeHiddenIcon className="" />
      ) : (
        <EyeVisibleIcon className="" />
      ),
      text: !isEnable
        ? t("ss_builder_show_social_link_label")
        : t("ss_builder_hide_social_link_label"),
    },
    {
      key: "delete-social-network",
      icon: <DeleteIcon className="" />,
      text: t("ss_builder_delete_social_link_label"),
    },
  ];

  const MoreElement = () => {
    return (
      <div className="flex flex-row items-center gap-[15px]">
        <div
          className=""
          onClick={handleClickMoreIcon}
        >
          <Popover.Menu
            mode="dark"
            actions={actions}
            placement="left"
            trigger="click"
            stopPropagation={["click"]}
            getContainer={() => popoverRef.current}
            onAction={(item: Action) => handleClickPopoverItem(item)}
          >
            <MoreIcon className="mr-3" />
          </Popover.Menu>
        </div>
      </div>
    );
  };

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case "eye-visible-social-network":
        handleEnableDisableSocialLink();
        break;
      case "delete-social-network":
        confirmRemoveSocialLink();
        break;
      default:
      // to do
    }
  };

  const confirmRemoveSocialLink = () =>
    Modal.confirm({
      header: (
        <InformationTriangleIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => { }}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_social_link_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm-delete_social_link_content")}
        </div>
      ),
      confirmText: t("ss_builder_delete_social_link_btn_label"),
      cancelText: t("ss_builder_cancel-delete_social_link_btn_label"),
      onConfirm: () => {
        onRemoveItem(index);
        Toast.show({
          icon: (
            <CheckCircleIcon
              className="text-center inline-block w-[30px] h-[30px]"
              fillColor="#00B578"
            />
          ),
          content: (
            <div className="text-center">
              {t("ss_builder_delete_social_link_successfully_label")}
            </div>
          ),
        });
      },
      onCancel: () => {
        return;
      },
    });

  const handleEditImage = (event) => {
    if (event?.defaultPrevented) {
      return;
    }
    callbackOpenEditSocialLink(socialLinkData);
  };

  const handleEnableDisableSocialLink = () => {
    const socialLinkStatus = !isEnable ? 1 : 2;
    onDisableEnableSocialLink(index, socialLinkStatus);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {isEnable
            ? t("ss_builder_disable_social_link_successfully_label")
            : t("ss_builder_enable_social_link_successfully_label")}
        </div>
      ),
    });
  };

  const handleClickMoreIcon = (event) => {
    event.preventDefault();
    // event.stopPropagation();
  };

  const SocialLinkIconRender = useCallback((data) => {
    const type = data.valueIcon;
    let element = null;
    switch (type) {
      case SOCIAL_LINK.FACEBOOK:
        element = <FacebookIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.MESSENGER:
        element = <MessengerIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.INSTAGRAM:
        element = <InstagramIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.LINKEDIN:
        element = <LinkedInIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.SNAPCHAT:
        element = <SnapchatIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.LINECHAT:
        element = <LineChatIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.YOUTUBE:
        element = <YoutubeIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.TIKTOK:
        element = <TiktokIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.TWITCH:
        element = <TwitchIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.TWITTER:
        element = <TwitterIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.WHATSAPP:
        element = <WhatsappIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.DISCORD:
        element = <DiscordIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.BEHANCE:
        element = <BehanceIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.DRIBBLE:
        element = <DribbleIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.PINTEREST:
        element = <PinterestIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.EMAIL:
        element = <EmailIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.TEL:
        element = <TelIcon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.LEMON8:
        element = <Lemon8Icon className="w-8 h-8 rounded-lg" />;
        break;
      case SOCIAL_LINK.OTHER:
        element = <GeneralLinkIcon className="w-8 h-8 rounded-lg" />;
        break;
      default:
        element = <GeneralLinkIcon className="w-8 h-8 rounded-lg" />;
    }
    return element;
  }, []);
  const linkDatavalue = socialLinkData.url || socialLinkData?.link_data?.value;

  return (
    <div ref={popoverRef}>
      <List className="">
        <List.Item
          prefix={<DragIcon className="" />}
          extra={<MoreElement />}
          onClick={handleEditImage}
        >
          <div
            className={cls(
              "flex items-center gap-[6px] flex-row",
              !isEnable && "opacity-40"
            )}
          >
            <SocialLinkIconRender valueIcon={socialLinkData.icon} />
            <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
              <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                {socialLinkData.icon &&
                  typeof socialLinkData.icon === "string" &&
                  socialLinkData.icon.charAt(0).toUpperCase() +
                  socialLinkData.icon.slice(1)}
              </div>
              <div
                className="flex"
                style={{ marginTop: "2px" }}
              >
                {socialLinkData?.use_aff_url === 1 && linkDatavalue ? (
                  <>
                    <TickIcon />
                    <span className="ml-1 text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                      {t("ss_builder_product_link_wrapped_label")}
                    </span>
                  </>
                ) : (
                  <div className="text-xs leading-4 font-normal text-[#8C8C8C] text-ellipsis whitespace-nowrap overflow-hidden">
                    {linkDatavalue}
                  </div>
                )}
              </div>
            </div>
          </div>
        </List.Item>
      </List>
    </div>
  );
};

export default socialLinkItems;
