import { LINK_LAYOUTS } from "@share/configs/const";
import { Image, Selector } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AutoScrollSelector from "@share/components/AutoScrollSelector";

const LinkLayouts = (props) => {
  const { linkLayout, onUpdateLinkLayout, isMultiLink } = props;
  const { t } = useTranslation();
  const elementId = useMemo(() => {
    return "e" + Math.random().toString(36).slice(2, 7);
  }, []);
  const linkLayoutsOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/text-right-layout.png"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_link_text_right_layout")}</div>
      ),
      value: LINK_LAYOUTS.TEXT_RIGHT,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/text-left-layout.png"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_link_text_left_layout")}</div>
      ),
      value: LINK_LAYOUTS.TEXT_LEFT,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/text-under-layout.png"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_link_text_under_layout")}</div>
      ),
      value: LINK_LAYOUTS.TEXT_UNDER,
    },
  ];

  const linkLayoutsOptionsForMulti = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/TextRightMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_LAYOUTS.TEXT_RIGHT}
          className="mt-1"
        >
          {t("ss_builder_link_text_right_layout")}
        </div>
      ),
      value: LINK_LAYOUTS.TEXT_RIGHT,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/TextLeftMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_LAYOUTS.TEXT_LEFT}
          className="mt-1"
        >
          {t("ss_builder_link_text_left_layout")}
        </div>
      ),
      value: LINK_LAYOUTS.TEXT_LEFT,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/TextUnderMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_LAYOUTS.TEXT_UNDER}
          className="mt-1"
        >
          {t("ss_builder_link_text_under_layout")}
        </div>
      ),
      value: LINK_LAYOUTS.TEXT_UNDER,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/TextZigZagMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_LAYOUTS.TEXT_ZIG_ZAG}
          className="mt-1"
        >
          {t("ss_builder_link_text_zig_zag_layout")}
        </div>
      ),
      value: LINK_LAYOUTS.TEXT_ZIG_ZAG,
    },
  ];

  const handleSelectLinkLayout = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      onUpdateLinkLayout(value);
    }
  };
  return (
    <div>
      <div className="text-[15px] text-[#666666]">
        {/*{isMultiLink ? <span className="text-red-600">*</span> : null}*/}
        {t("ss_builder_bio_layout_title")}
      </div>
      {isMultiLink ? (
        <AutoScrollSelector
          elementId={elementId}
          options={linkLayoutsOptionsForMulti}
          value={linkLayout}
          onChange={handleSelectLinkLayout}
          isMulti
        />
      ) : (
        <Selector
          options={linkLayoutsOptions}
          value={[linkLayout]}
          onChange={handleSelectLinkLayout}
          columns={3}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      )}
    </div>
  );
};

export default LinkLayouts;
