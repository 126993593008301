import React, { useEffect, useMemo, useState } from "react";
import { TAB_TEMPLATE, TAB_TEMPLATE_LIST } from "@share/configs/const";
import { Button, Image, Selector } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { SampleSelectionIcon } from "@share/icons";

const TabTemplateSelection = (props) => {
  const { tabTemplateDefault, onUpdateTabTemplate, onCancelTabTemplate } =
    props;
  const { t } = useTranslation();

  const [tabTemplate, setTabTemplate] = useState(tabTemplateDefault);

  useEffect(() => {
    if (tabTemplateDefault) setTabTemplate(tabTemplateDefault);
  }, [tabTemplateDefault]);

  const tabLayoutsOptions = TAB_TEMPLATE_LIST.map((ele) => ({
    label: (
      <Image
        fit="cover"
        src={ele.image}
      />
    ),
    value: ele.value,
  }));

  const handleSelectTabTemplate = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      setTabTemplate(value);
    }
  };

  const handleSaveTabTemplate = () => {
    onUpdateTabTemplate(tabTemplate);
    onCancelTabTemplate();
  };

  const isDisableBtnSave = useMemo(() => {
    if (!tabTemplate || tabTemplate === tabTemplateDefault) {
      return true;
    } else return false;
  }, [tabTemplate, tabTemplateDefault]);

  return (
    <div>
      <div
        className="flex flex-row text-center items-center justify-center py-3 gap-[10px] relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <SampleSelectionIcon className="" />
        <div className="text-lg leading-6">
          {t("ss_builder_sample_selection_label")}
        </div>
      </div>
      <Selector
        options={tabLayoutsOptions}
        value={[tabTemplate]}
        onChange={handleSelectTabTemplate}
        columns={1}
        style={{
          "--border": "solid transparent 1px",
          "--checked-border": "solid var(--adm-color-primary) 1px",
          "--checked-color": "#fff",
          "--color": "#fff",
          margin: "16px 12px",
        }}
      />
      <div className="fixed bottom-0 p-2 flex max-width-content-class w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={onCancelTabTemplate}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={isDisableBtnSave}
            color="primary"
            onClick={handleSaveTabTemplate}
          >
            {t("ss_builder_done_panel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TabTemplateSelection;
