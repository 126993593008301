import React from "react";
import { DIVIDER_LAYOUT_TYPE_ENUM } from "../../../DividerSetting/DefaultsLayoutSetting";

const DividerLine = (props) => {
  const { divider_thickness, divider_color, divider_type } = props;

  const styleRender = () => {
    let style = {};
    switch (divider_type) {
      case DIVIDER_LAYOUT_TYPE_ENUM.SOLID:
        style = {
          borderStyle: "solid",
        };
        break;
      case DIVIDER_LAYOUT_TYPE_ENUM.DASHED:
        style = {
          borderStyle: "dashed",
        };
        break;
      case DIVIDER_LAYOUT_TYPE_ENUM.DOT_STROKES:
        style = {
          borderStyle: "dotted",
        };
        break;
    }
    return style;
  };

  return (
    <hr
      style={{
        borderColor: divider_color,
        height: divider_thickness,
        borderWidth: `0 0 ${divider_thickness}px`,
        ...styleRender(),
      }}
    />
  );
};

export default DividerLine;
