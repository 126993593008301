import React from "react";
import { Button, NavBar, Popup, SafeArea } from "antd-mobile";
import cls from "classnames";
import { ClosePopupIcon } from "@share/icons";
import { MAX_WIDTH_CONTENT_LAYOUT } from "@share/configs/const";
import ButtonConfirmPopup from "../ButtonConfirmPopup";

export default function PopupHorizontalComponent({
  visible,
  onMaskClick,
  children,
  position = "bottom",
  classNameBody = "",
  className = "adm-popup-right",
  style = {},
  title = "",
  subTitle = "",
  showConfirmButton = false,
  mask = true,
  onConfirmButton,
  confirmButtonLabel = "",
  disabledConfirmButton,
  onClose,
}: {
  visible: boolean;
  onMaskClick?: () => void;
  onClose?: () => void;
  [x: string]: any;
}) {
  return (
    <Popup
      visible={visible}
      onMaskClick={onMaskClick}
      position={position}
      bodyClassName="adm-popup-body-horizontal"
      bodyStyle={{
        backgroundColor: "none",
      }}
      className={className}
      mask={mask}
    >
      {visible && (
        <div
          className={cls(
            "bg-[#fff] max-width-content-layout popup-body-horizontal m-auto relative h-full",
            `${classNameBody}`
          )}
          style={{
            ...style,
          }}
        >
          <div className="popup-header">
            {title && (
              <NavBar
                onBack={onClose}
                style={{ "--height": "44px" }}
              >
                <div className="main-title">{title}</div>
                {subTitle && <div className="sub-title">{subTitle}</div>}
              </NavBar>
            )}
          </div>
          <div className="popup-body overflow-y-auto">{children}</div>
          {showConfirmButton && (
            <ButtonConfirmPopup
              onConfirm={onConfirmButton}
              title={confirmButtonLabel}
              isDisable={disabledConfirmButton}
            />
          )}
        </div>
      )}
    </Popup>
  );
}
