import { CreateGatewayAxios } from "@share/api/createAxios";
import {
  FirstGuideStateEnum,
  IntroduceDoubleTapEnum,
  FloatingIntroduction,
  FloatingIntroStatus,
} from "@features/design/store/externalStateSlice";
import { GG_FONT_URI } from "@share/configs/const";
import axios from "axios";

const API_PATH = {
  EXTERNAL_STATE: `/api/v1/builder/website-config/external-state`,
  SAVE_GUIDE_STATUS: `/api/v1/builder/website-config/update-first-guide-state`,
  SAVE_INTRODUCE_DOUBLE_TAP_STATUS: `/api/v1/builder/website-config/update-status-introduce-double-tap`,
};

const gatewayInstance = CreateGatewayAxios();

export interface ExternalState {
  user_id: string;
  first_guide_state: FirstGuideStateEnum;
  introduce_double_tap: IntroduceDoubleTapEnum;
  floating_icon: {
    [key: string]: FloatingIntroStatus;
  };
  createdAt: string;
  id: string;
}

export const getGuideState = (
  user_id: string
): Promise<{
  data: ExternalState;
  message: string;
}> => {
  return gatewayInstance.get(API_PATH.EXTERNAL_STATE, {
    params: {
      user_id,
    },
  });
};

export const setGuideState = (
  user_id: string,
  state: FirstGuideStateEnum
): Promise<{
  data: {
    success: boolean;
    message?: string;
  };
}> => {
  return gatewayInstance.post(API_PATH.SAVE_GUIDE_STATUS, { user_id, state });
};

export const setIntroduceDoubleTapState = (
  user_id: string,
  status: IntroduceDoubleTapEnum
): Promise<{
  data: {
    success: boolean;
    message?: string;
  };
}> => {
  return gatewayInstance.post(API_PATH.SAVE_INTRODUCE_DOUBLE_TAP_STATUS, {
    user_id,
    status,
  });
};

export const setFloatingIntroduceState = (
  user_id: string,
  floating_icon: FloatingIntroduction
) => {
  return gatewayInstance.post(API_PATH.EXTERNAL_STATE, {
    user_id,
    floating_icon_brand_gift: floating_icon.brand_gift,
  });
};
export const setFloatingBlockIntroduceState = (
  user_id: string,
  floating_block: FloatingIntroduction
) => {
  return gatewayInstance.post(API_PATH.EXTERNAL_STATE, {
    user_id,
    floating_block_brand_rating: floating_block.brand_rating,
  });
};

export const checkFontGG = async (
  fontName: string,
  fontWeight?: string | number
) => {
  const uri = `${GG_FONT_URI}?family=${fontName.replaceAll(
    " ",
    "+"
  )}`;
  const API_FONT_GG = fontWeight ? `${uri}:${fontWeight}` : uri;
  return axios.get(API_FONT_GG);
};