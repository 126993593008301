import { AddIcon, BanIcon, InfomationCircleIcon } from "@share/icons";
import { Button, Popover, SpinLoading } from "antd-mobile";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import { AFFILIATE_ADD_NEW_TOUR_CLASS_NAME } from "../ProductAddNewTourOpening/affiliate-add-new-tour-config";

const ProductAddNewInput = (props) => {
  const {
    detectProductUrl,
    onChangeProductLink,
    onPasteProductLink,
    disabledInput,
    disabledAddMoreBtn,
    onClickAddMoreBtn,
    onClickStopBtn,
    isValidUrl,
    isLoadingBtn,
  } = props;
  const { t } = useTranslation();
  const popoverRef = useRef(null);

  useEffect(() => {
    if (!isLoadingBtn) {
      const textarea = document.querySelector(
        ".detect-product-link-textarea"
      ) as HTMLElement;
      textarea.style.cssText = "height:auto; padding:0";
    }
  }, [isLoadingBtn]);

  useEffect(() => {
    const textarea = document.querySelector(".detect-product-link-textarea");
    function autosize() {
      const el = this;
      setTimeout(function () {
        el.style.cssText = "height:" + el.scrollHeight + "px";
      }, 0);
    }
    textarea.addEventListener("keydown", autosize);
    return () => removeEventListener("keydown", autosize);
  }, [detectProductUrl]);

  return (
    <div
      className={cls(
        AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_2,
        "p-[12px_16px] bg-[#FFFFFF] m-3 rounded-lg"
      )}
    >
      <div className="flex flex-row justify-between items-center">
        <div
          className="text-[15px] leading-[23px] text-[#666666] flex flex-row items-center gap-1"
          ref={popoverRef}
        >
          <span>
            <span className="text-red-600">*</span>
            {t("ss_builder_product_detect_link_title")}
          </span>
          <span>
            <Popover
              content={t("ss_builder_import_multi_product_url_popover_content")}
              trigger="click"
              placement="top"
              mode="dark"
              stopPropagation={["click"]}
              getContainer={() => popoverRef.current}
            >
              <InfomationCircleIcon
                className="w-[17px] h-[17px]"
                fillColor="#1890FF"
              />
            </Popover>
          </span>
        </div>
        {isLoadingBtn ? (
          <Button
            size="mini"
            fill="outline"
            color="primary"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              padding: "3px 12px 3px 12px",
            }}
            onClick={onClickStopBtn}
          >
            <BanIcon
              className="w-[13px] h-[13px]"
              fillColor="#ee3244ff"
            />
            <div>{t("ss_builder_stop_label")}</div>
          </Button>
        ) : (
          <Button
            size="mini"
            fill="solid"
            color="primary"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              padding: "3px 12px 3px 12px",
            }}
            onClick={onClickAddMoreBtn}
            disabled={disabledAddMoreBtn}
          >
            <AddIcon className="w-[13px] h-[13px]" />
            <div>{t("ss_builder_add_new_label")}</div>
          </Button>
        )}
      </div>
      <div className="relative">
        <textarea
          className="detect-product-link-textarea"
          placeholder={t("ss_builder_product_detect_link_placeholder")}
          onChange={(e) => onChangeProductLink(e)}
          onPaste={(e) => onPasteProductLink(e)}
          value={detectProductUrl}
          disabled={disabledInput}
        />
        {isLoadingBtn && (
          <div
            style={{
              backgroundColor: "#FFFFFF",
              opacity: "0.6",
            }}
            className="absolute top-0 left-0 w-full h-full justify-center rounded-lg flex"
          >
            <SpinLoading
              color="primary"
              style={{ "--size": "32px", margin: "auto" }}
            />
          </div>
        )}
      </div>
      {isValidUrl && (
        <div className="text-[13px] leading-[17px] text-[#FF3141]">
          {t("ss_builder_product_detect_link_malformed_error")}
        </div>
      )}
    </div>
  );
};

export default ProductAddNewInput;
