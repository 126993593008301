import { TickIcon } from "@share/icons";
import { Image } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import { LinkTypeEnum } from "@features/block/components/LinkInput";
import { getServiceLabelKey } from "@features/block/components/LinkInput/components/ServiceSelectorPopup";

interface CardModeSelectProps {
  enable: any;
  image: any;
  card_title: any;
  use_aff_url: any;
  link_url: any;
  link_data: any;
}
const CardModeSelect: React.FunctionComponent<CardModeSelectProps> = (
  props
) => {
  const { enable, image, card_title, use_aff_url, link_url, link_data } = props;
  const { t } = useTranslation();

  const linkDatavalue = link_url || link_data?.value;

  return (
    <>
      <div
        className={cls(
          "flex items-center gap-[6px] flex-row",
          enable !== 1 && "opacity-40"
        )}
      >
        <Image
          src={image}
          width={40}
          height={40}
          fit="cover"
          style={{
            borderRadius: "4px",
          }}
        />
        <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
          <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
            {card_title}
          </div>
          <div
            className="flex"
            style={{ marginTop: "2px" }}
          >
            {use_aff_url === 1 && linkDatavalue ? (
              <>
                <TickIcon />
                <span className="ml-1 text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                  {t("ss_builder_product_link_wrapped_label")}
                </span>
              </>
            ) : (
              <span className="text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                {link_data?.type !== LinkTypeEnum.SERVICE
                  ? linkDatavalue
                  : t(getServiceLabelKey(linkDatavalue))}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardModeSelect;
