import { BLOCK_TYPE } from "@features/block/block.conf";
import { ENABLE_BLOCK } from "@share/configs/const";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { BOOKING_PROFILE_FIELD, BOOKING_PROFILE_STYLE } from "./constant";
import { Popover, Toast } from "antd-mobile";
import {
  CheckCircleIcon,
  CoppyAltIcon,
  InfomationCircleIcon,
} from "@share/icons";
import { copyToClipboard } from "@share/helper/browser";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import StyleSelector from "./components/StyleSelector";
import CardContentStyle from "./components/CardContentStyle";
import EmbedCodeStyle from "./components/EmbedCodeStyle";

const BookingProfile = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
  } = props;
  const { t } = useTranslation();
  const UID = `booking-profile-${uuidv4()}`;
  const dataBookingProfileDefault = defaultBlockData?.uid
    ? defaultBlockData
    : null;
  const dataInitial = {
    title: dataBookingProfileDefault?.title || BLOCK_TYPE.BOOKING_PROFILE,
    image: dataBookingProfileDefault?.content_attributes?.image || "",
    image_name: dataBookingProfileDefault?.content_attributes?.image_name,
    image_base64: dataBookingProfileDefault?.content_attributes?.image_base64,
    booking_profile_title:
      dataBookingProfileDefault?.content_attributes?.booking_profile_title ||
      "",
    booking_profile_description:
      dataBookingProfileDefault?.content_attributes
        ?.booking_profile_description || "",
    booking_profile_button_content:
      dataBookingProfileDefault?.content_attributes
        ?.booking_profile_button_content ||
      t("ss_builder_block_booking_file_button_label_default"),
    booking_profile_style:
      dataBookingProfileDefault?.style_attributes?.booking_profile_style ||
      BOOKING_PROFILE_STYLE.CARD_CONTENT,
    open_new_tab:
      dataBookingProfileDefault?.content_attributes?.open_new_tab || false,
    apply_block_settings:
      dataBookingProfileDefault?.content_attributes?.apply_block_settings ||
      false,
  };

  const [dataBookingProfile, setDataBookingProfile] = useState(dataInitial);
  const [errorImage, setErrorImage] = useState(false);
  const domain = useSelector<RootState, any>(
    (state) => state?.user?.kol?.domain
  );
  const bookingProfileLink = useMemo(() => {
    let link = "";
    if (domain) {
      link = `https://${domain}/booking-profile`;
    }
    return link;
  }, [domain]);

  const isDisableBtnSave = useMemo(() => {
    const dataRequired = {
      bookingProfileButtonContent:
        dataBookingProfile.booking_profile_button_content,
      image: dataBookingProfile.image,
    };
    const isValidRequired = Object.values(dataRequired).every((x) => x);
    const isError = !!(
      dataBookingProfile.booking_profile_style ===
        BOOKING_PROFILE_STYLE.CARD_CONTENT &&
      (!isValidRequired || errorImage)
    );
    if (isEqual(dataInitial, dataBookingProfile) || isError) {
      return true;
    }
    return false;
  }, [dataBookingProfile, dataInitial]);

  const isEditDataStatus = useMemo(() => {
    if (!isEqual(dataInitial, dataBookingProfile)) {
      return true;
    }
    return false;
  }, [dataBookingProfile]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
    return () => {
      setButtonStatus(true);
    };
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
    return () => {
      setEditStatus(false);
    };
  }, [isEditDataStatus]);

  useEffect(() => {
    const contentAttributes = {
      image: dataBookingProfile.image,
      image_name: dataBookingProfile?.image_name,
      image_base64: dataBookingProfile?.image_base64,
      booking_profile_title: dataBookingProfile.booking_profile_title.trim(),
      booking_profile_description:
        dataBookingProfile.booking_profile_description.trim(),
      booking_profile_button_content:
        dataBookingProfile.booking_profile_button_content.trim(),
      open_new_tab: dataBookingProfile?.open_new_tab || false,
      apply_block_settings: dataBookingProfile?.apply_block_settings || false,
    };

    const styleAttributes = {
      booking_profile_style:
        dataBookingProfile?.booking_profile_style ||
        BOOKING_PROFILE_STYLE.CARD_CONTENT,
    };

    if (!block.uid) {
      const defaultBookingProfile = {
        uid: UID,
        sort_order: 1,
        enable: ENABLE_BLOCK,
        block_type: BLOCK_TYPE.BOOKING_PROFILE,
        title: dataBookingProfile.title,
        content_attributes: contentAttributes,
        style_attributes: styleAttributes,
      };
      onUpdateData(defaultBookingProfile);
    } else {
      onUpdateData({
        ...block,
        title: dataBookingProfile.title,
        content_attributes: contentAttributes,
        style_attributes: styleAttributes,
      });
    }
  }, [dataBookingProfile]);

  const handleChange = (nameField, value) => {
    const dataBookingProfileCurrent = cloneDeep(dataBookingProfile);
    if (nameField === BOOKING_PROFILE_FIELD.IMAGE) {
      setDataBookingProfile({
        ...dataBookingProfileCurrent,
        image_name: value?.file_name,
        image_base64: value?.base64,
        image: value?.url,
      });
      return;
    }
    setDataBookingProfile({ ...dataBookingProfileCurrent, [nameField]: value });
  };

  const handleClickCoppyLink = () => {
    copyToClipboard(bookingProfileLink);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_block_link_copy_successfully_label")}
        </div>
      ),
    });
  };

  const handleErrorImage = (error) => {
    setErrorImage(error);
  };

  const cardContentMode = !!(
    dataBookingProfile?.booking_profile_style ===
    BOOKING_PROFILE_STYLE.CARD_CONTENT
  );
  const embedCodeMode = !!(
    dataBookingProfile?.booking_profile_style ===
    BOOKING_PROFILE_STYLE.EMBED_CODE
  );

  return (
    <>
      <StyleSelector
        data={dataBookingProfile?.booking_profile_style}
        onUpdateData={(val) => handleChange(BOOKING_PROFILE_FIELD.STYLE, val)}
      />
      {cardContentMode && (
        <CardContentStyle
          handleChange={handleChange}
          handleErrorImage={handleErrorImage}
          image={dataBookingProfile.image}
          booking_profile_title={dataBookingProfile.booking_profile_title}
          booking_profile_description={
            dataBookingProfile.booking_profile_description
          }
          booking_profile_button_content={
            dataBookingProfile.booking_profile_button_content
          }
        />
      )}
      {embedCodeMode && (
        <EmbedCodeStyle
          handleChange={handleChange}
          openNewTab={dataBookingProfile.open_new_tab}
          applyBlockSettings={dataBookingProfile.apply_block_settings}
        />
      )}
      <div className="px-4 py-3 bg-white overflow-hidden">
        <div className="flex items-center gap-x-1 mb-1">
          <div className="text-[#666666] text-[15px] leading-[150%]">
            {t("ss_builder_block_booking_file_label")}
          </div>
          <Popover
            content={t("ss_builder_block_booking_file_link_tooltip")}
            placement="top-start"
            mode="dark"
            trigger="click"
          >
            <div className="w-[21px] h-[21px] flex items-center justify-center">
              <InfomationCircleIcon
                fillColor="#1890FF"
                className="w-[15px] h-[15px] cursor-pointer"
              />
            </div>
          </Popover>
        </div>
        <div className="flex items-center w-full justify-between">
          <div
            className="text-[17px] leading-[150%] truncate"
            style={{
              color: "rgba(51, 51, 51, 0.4)",
            }}
          >
            {bookingProfileLink}
          </div>
          <div
            className="w-[24px] h-[24px] cursor-pointer flex items-center justify-center"
            onClick={handleClickCoppyLink}
          >
            <CoppyAltIcon
              width={16}
              height={16}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingProfile;
