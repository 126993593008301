import { useAppDispatch, useAppSelector } from "@app/hooks";
import { setParamsThemeSetting } from "@features/main/store/dynamicSlice";
import { dataService } from "@share/configs/rxjs";
import { Divider } from "antd-mobile";
import cloneDeep from "lodash/cloneDeep";
import React, { useState } from "react";
import { ChromePicker, CirclePicker } from "react-color";
import { useTranslation } from "react-i18next";
import { rgbaToHex, hexToRgba } from "@share/lib";
import { useDispatch } from "react-redux";
import style from "./style.module.css";
import { getOpacityFromHex, getGlobalColor } from "@share/lib";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";

const RECENTLY_COLOR_MAXIMUM = 10;

type Props = {
  actionKey: string;
  indexColor?: number;
  callbackCancelColorPicker: any;
  defaultColor?: string;
};

const ColorPicker = (props: Props) => {
  const {
    indexColor,
    actionKey,
    callbackCancelColorPicker,
    defaultColor = "#000",
  } = props;

  const dynamicConfig = useAppSelector<any>((state) => state.dynamicConfig);
  const themeSettings = dynamicConfig && dynamicConfig.themeSettings;
  const { recentlyColor } = themeSettings;

  const [colorState, setColorState] = useState(defaultColor);
  const [colorStatePicker, setColorStatePicker] = useState(
    hexToRgba(defaultColor)
  );
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleChangeColor = (color) => {
    const hexColor = rgbaToHex(color.rgb);
    setColorState(hexColor);
    setColorStatePicker(color.rgb);
  };

  const handleChangeRecentColor = (color) => {
    setColorState(color);
    setColorStatePicker(hexToRgba(color));
  };

  const handleCancelColorPicker = () => {
    callbackCancelColorPicker();
  };

  const handleConfirmColorPicker = () => {
    handleCancelColorPicker();
    const newRecentlyColor = cloneDeep(recentlyColor);
    if (colorState) newRecentlyColor.unshift(colorState);
    const newRecentlyColorFilter = Array.from(new Set(newRecentlyColor));
    if (newRecentlyColorFilter.length > RECENTLY_COLOR_MAXIMUM)
      newRecentlyColorFilter.pop();
    dispatch(
      setParamsThemeSetting({
        data: {
          recentlyColor: newRecentlyColorFilter,
        },
      })
    );
    switch (actionKey) {
      case "SELECT_BACKGROUND_GRADIENT_COLOR":
        if (indexColor !== undefined) {
          dataService.publish({
            selectedBackgroundGradientColor: colorState,
            indexColor,
          });
        }
        break;
      case "SELECT_BACKGROUND_SOLID_COLOR":
        dataService.publish({
          selectedBackgroundSolidColor: colorState,
        });
        break;
      case "SELECT_PRIMARY_COLOR":
        dataService.publish({
          selectedMainColor: colorState,
        });
        break;
    }
  };

  return (
    <div className="pb-3">
      {recentlyColor[0] && (
        <>
          <div className="px-4">
            <div className="text-[13px] leading-[17px] mb-3">
              {t("ss_builder_recently_color_panel_label")}
            </div>
            <div className="flex flex-wrap">
              {recentlyColor.map((ele, index) => (
                <div
                  className="w-[28px] h-[28px] rounded-[50%] cursor-pointer  relative overflow-hidden mr-[10px] mb-[10px]"
                  style={{
                    backgroundColor: getGlobalColor(ele),
                  }}
                  onClick={() => {
                    handleChangeRecentColor(ele);
                  }}
                >
                  {getOpacityFromHex(ele) !== 1 && (
                    <div
                      className="w-[50%] h-[100%] absolute right-0 top-0"
                      style={{
                        background:
                          "repeat #fff url(https://image.passio.eco/page-builder/page-builder/background-default/checkerboard-v2.png) ",
                      }}
                    ></div>
                  )}

                  <div
                    className="w-[50%] h-[100%] absolute right-0 top-0"
                    style={{
                      backgroundColor: ele,
                    }}
                  ></div>
                  <div
                    className="w-[100%] h-[100%] absolute left-0 top-0 rounded-[50%]"
                    style={{
                      outline: "rgba(0, 0, 0, 0.2) solid 1px",
                      outlineOffset: "-1px",
                    }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
          <Divider />
        </>
      )}

      <div className="px-4">
        <div className="text-[13px] leading-[17px] mb-3">
          {t("ss_builder_suggested_color_panel_label")}
        </div>
        <CirclePicker
          // onSwatchHover={handleChangeColor}
          onChange={handleChangeColor}
          color={colorState}
          width="100%"
          className={style["circle-picker-custom"]}
          circleSpacing={10}
        />
      </div>
      <Divider />
      <div className="px-4">
        <div className="text-[11px] text-[#191919] leading-[16px] mb-2 font-medium">
          {t("ss_builder_optional_color_panel_label")}
        </div>
        <ChromePicker
          color={colorStatePicker}
          onChange={handleChangeColor}
          width="100%"
          className={style["circle-picker-custom"]}
        />
      </div>
      <ButtonConfirmPopup
        onConfirm={colorState ? handleConfirmColorPicker : () => {}}
      />
    </div>
  );
};

interface ColorPickerV2Props {
  defaultColor: string;
  onColorSelected: (color: any) => void;
  onCancel: () => void;
}

export const ColorPickerV2 = (props: ColorPickerV2Props) => {
  const { defaultColor, onColorSelected, onCancel } = props;

  const dynamicConfig = useAppSelector<any>((state) => state.dynamicConfig);
  const themeSettings = dynamicConfig && dynamicConfig.themeSettings;
  const { recentlyColor } = themeSettings;

  const [colorState, setColorState] = useState(defaultColor);
  const [colorStatePicker, setColorStatePicker] = useState(
    hexToRgba(defaultColor)
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleChangeColor = (color) => {
    const hexColor = rgbaToHex(color.rgb);

    setColorState(hexColor);
    setColorStatePicker(color.rgb);
  };

  const handleChangeRecentColor = (color) => {
    setColorState(color);
    setColorStatePicker(hexToRgba(color));
  };

  const handleCancelColorPicker = () => {
    onCancel();
  };

  const handleConfirmColorPicker = () => {
    const newRecentlyColor = cloneDeep(recentlyColor);
    if (colorState) newRecentlyColor.unshift(colorState);
    const newRecentlyColorFilter = Array.from(new Set(newRecentlyColor));
    if (newRecentlyColorFilter.length > RECENTLY_COLOR_MAXIMUM)
      newRecentlyColorFilter.pop();
    dispatch(
      setParamsThemeSetting({
        data: {
          recentlyColor: newRecentlyColorFilter,
        },
      })
    );
    onColorSelected(colorState);
    handleCancelColorPicker();
  };

  return (
    <div>
      {recentlyColor[0] && (
        <>
          <div className="px-4 recent-color">
            <div className="text-[13px] leading-[17px] mb-3">
              {t("ss_builder_recently_color_panel_label")}
            </div>
            <div className="flex flex-wrap">
              {recentlyColor.map((ele, index) => (
                <div
                  className="w-[28px] h-[28px] rounded-[50%] cursor-pointer  relative overflow-hidden mr-[10px] mb-[10px]"
                  style={{
                    backgroundColor: getGlobalColor(ele),
                  }}
                  onClick={() => {
                    handleChangeRecentColor(ele);
                  }}
                >
                  {getOpacityFromHex(ele) !== 1 && (
                    <div
                      className="w-[50%] h-[100%] absolute right-0 top-0"
                      style={{
                        background:
                          "repeat #fff url(https://image.passio.eco/page-builder/page-builder/background-default/checkerboard-v2.png) ",
                      }}
                    ></div>
                  )}
                  <div
                    className="w-[50%] h-[100%] absolute right-0 top-0"
                    style={{
                      backgroundColor: ele,
                    }}
                  ></div>
                  <div
                    className="w-[100%] h-[100%] absolute left-0 top-0 rounded-[50%]"
                    style={{
                      outline: "rgba(0, 0, 0, 0.2) solid 1px",
                      outlineOffset: "-1px",
                    }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
          <Divider />
        </>
      )}

      <div className="px-4">
        <div className="text-[13px] leading-[17px] mb-3">
          {t("ss_builder_suggested_color_panel_label")}
        </div>
        <CirclePicker
          // onSwatchHover={handleChangeColor}
          onChange={handleChangeColor}
          color={colorState}
          width="100%"
          className={style["circle-picker-custom"]}
          circleSpacing={10}
        />
      </div>
      <Divider />
      <div className="px-4">
        <div className="text-[11px] text-[#191919] leading-[16px] mb-2 font-medium">
          {t("ss_builder_optional_color_panel_label")}
        </div>
        <ChromePicker
          color={colorStatePicker}
          onChange={handleChangeColor}
          width="100%"
          className={style["circle-picker-custom"]}
          circleSpacing={10}
        />
      </div>
      <ButtonConfirmPopup
        onConfirm={colorState ? handleConfirmColorPicker : () => {}}
      />
    </div>
  );
};

export default ColorPicker;
