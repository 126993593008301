import { useAppDispatch } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { BIO_DISPLAY_STYLES, EDITOR_MAX_LENGTH } from "@share/configs/const";
import {
  AddIcon,
  ImageIconEmpty,
  InformationTriangleIcon,
  TrashIcon,
} from "@share/icons";
import { getPlaceholderProps, mockUpload } from "@share/lib";
import {
  Button,
  Divider,
  Image,
  ImageUploader,
  Input,
  Switch,
  Selector,
} from "antd-mobile";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import SocialLinkList from "./SocialLinkList";
import cls from "classnames";
import AutoScrollSelector from "@share/components/AutoScrollSelector";
import { ENABLE_BLOCK } from "@share/configs/const";
import EditorComp from "../TextV2/EditorComp";
import draftToHtml from "draftjs-to-html";
import { imageOptions } from "@features/block/block-editor/Bio/const";
import FullScreenPopup from "@share/components/full-screen-popup";
import CoverImageAddNew from "@features/block/block-editor/Bio/CoverImageAddNew";
import CoverImageList from "@features/block/block-editor/Bio/CoverImageList";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import StickyChecking from "@share/components/StickyChecking";
import { scrollToNewItem } from "@features/block/libs";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import BookingProfileLink from "./BookingProfileLink";

const BioComponent = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
  } = props;
  const IMAGE_COVER_MAX = 5;
  const defaultAvatar =
    "https://image.passio.eco/page-builder/page-builder/images-default/bio-default.svg";
  const DROPPABLE_ID = "cover-image-droppable-id";
  const { t } = useTranslation();
  const UID = `bio-${uuidv4()}`;

  const dispatch = useAppDispatch();
  // const edittingBlock = useAppSelector((state) => state.edittingBlock);
  // const KEY = edittingBlock && edittingBlock.KEY;

  // const edittingBlockData = edittingBlock && edittingBlock.block_data;
  // const tabUid = edittingBlock && edittingBlock.tab_uid;
  // const positionSlug = edittingBlock && edittingBlock.position_uid;

  const defaultValueEditBio =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;

  const titleBlockDefault =
    (defaultValueEditBio && defaultValueEditBio.title) || "Bio";

  const styleBioValueDefault =
    (defaultValueEditBio &&
      defaultValueEditBio.style_attributes &&
      defaultValueEditBio.style_attributes.style_display) ||
    BIO_DISPLAY_STYLES.STYLE1;

  const valueUrlImageDefault =
    (defaultValueEditBio &&
      defaultValueEditBio.content_attributes &&
      defaultValueEditBio.content_attributes.bio_image) ||
    defaultAvatar;

  const valueNameImageDefault =
    (defaultValueEditBio &&
      defaultValueEditBio.content_attributes &&
      defaultValueEditBio.content_attributes.bio_image_name) ||
    null;

  const valueBioNameDefault =
    (defaultValueEditBio &&
      defaultValueEditBio.content_attributes &&
      defaultValueEditBio.content_attributes.bio_name) ||
    "";

  const valueBioSubtitleDefault =
    (defaultValueEditBio &&
      defaultValueEditBio.content_attributes &&
      defaultValueEditBio.content_attributes.bio_subtitle) ||
    "";

  const contentBioStateDefault = useMemo(() => {
    const bioContent = defaultValueEditBio?.content_attributes?.bio_content;
    if (bioContent) {
      if (typeof bioContent == "string") {
        return bioContent;
      } else {
        return draftToHtml(bioContent);
      }
    } else {
      return null;
    }
  }, [defaultValueEditBio?.content_attributes?.bio_content]);

  const elementId = useMemo(() => {
    return "e" + Math.random().toString(36).slice(2, 7);
  }, []);

  const socialLinkArrDefault =
    (defaultValueEditBio &&
      defaultValueEditBio.content_attributes &&
      defaultValueEditBio.content_attributes.social_link_items) ||
    [];

  const defaultIsShowCoverImage =
    defaultBlockData?.content_attributes?.bio_show_cover_image || false;
  const defaultImageRatio =
    defaultBlockData?.content_attributes?.bio_image_ratio || "16/9";
  const defaultIsAutoSwitch =
    defaultBlockData?.content_attributes?.bio_auto_switch || false;
  const defaultListCoverImage =
    defaultBlockData?.content_attributes?.bio_cover_image_list || [];
  const defaultBookingProfile = {
    bio_booking_profile_display:
      defaultBlockData?.content_attributes?.bio_booking_profile_display ||
      false,
    bio_booking_profile_title:
      defaultBlockData?.content_attributes?.bio_booking_profile_title || "",
    bio_booking_profile_description:
      defaultBlockData?.content_attributes?.bio_booking_profile_description ||
      "",
    bio_booking_profile_button_label:
      defaultBlockData?.content_attributes?.bio_booking_profile_button_label ||
      t("ss_builder_block_booking_file_button_label_default"),
  };

  const [styleBioValue, setStyleBioValue] = useState(styleBioValueDefault);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [valueUrlImage, setValueUrlImage] = useState(valueUrlImageDefault);
  const [valueNameImage, setValueNameImage] = useState(valueNameImageDefault);
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [valueBioName, setValueBioName] = useState(valueBioNameDefault);
  const [valueBioSubTitle, setValueBioSubTitle] = useState(
    valueBioSubtitleDefault
  );
  const [contentBioState, setContentBioState] = useState(
    contentBioStateDefault
  );

  const [socialLinkArr, setSocialLinkArr] = useState(socialLinkArrDefault);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [count, setCount] = useState(0);

  const [isShowCoverImage, setIsShowCoverImage] = useState(
    defaultIsShowCoverImage
  );
  const [isAutoSwitch, setIsAutoSwitch] = useState(defaultIsAutoSwitch);
  const [imageRatio, setImageRatio] = useState(defaultImageRatio);
  const [imageCoverList, setImageCoverList] = useState(defaultListCoverImage);
  const [isOpenAddNewPopup, setIsOpenAddNewPopup] = useState(false);
  const [imageCoverEditData, setImageCoverEditData] = useState(null);
  const [indexImageCoverEdit, setIndexImageCoverEdit] = useState(null);
  const [bioBookingProfile, setBioBookingFile] = useState(
    defaultBookingProfile
  );

  const [placeholderProps, setPlaceholderProps] = useState({});

  const charCount = (editor) => editor.getContent({ format: "text" }).length;

  const socialLinkListSortOrder = useMemo(
    () => orderBy(socialLinkArr, ["sort_order"], ["asc"]),
    [socialLinkArr]
  );

  const isValidMaxLength = useMemo(() => {
    return count > EDITOR_MAX_LENGTH;
  }, [count]);

  const checkFieldRequired = useMemo(() => {
    const dataRequired = {
      valueBioName: valueBioName,
      bioBookingProfileButtonLabel:
        bioBookingProfile.bio_booking_profile_button_label,
    };
    const isValid = Object.values(dataRequired).every((x) => x);
    return isValid;
  }, [bioBookingProfile, valueBioName]);

  const isDisableBtnSave = useMemo(() => {
    if (
      (!isValidMaxLength &&
        checkFieldRequired &&
        valueBioName &&
        valueBioName.trim().length > 0 &&
        (JSON.stringify(socialLinkArr) !==
          JSON.stringify(socialLinkArrDefault) ||
          valueBioName !== valueBioNameDefault ||
          valueBioSubTitle !== valueBioSubtitleDefault ||
          valueUrlImage !== valueUrlImageDefault ||
          styleBioValue !== styleBioValueDefault ||
          contentBioState !== contentBioStateDefault ||
          isShowCoverImage !== defaultIsShowCoverImage ||
          isAutoSwitch !== defaultIsAutoSwitch ||
          imageRatio !== defaultImageRatio ||
          imageCoverList !== defaultListCoverImage)) ||
      (checkFieldRequired && !isEqual(bioBookingProfile, defaultBookingProfile))
    ) {
      return false;
    } else return true;
  }, [
    isValidMaxLength,
    valueBioName,
    socialLinkArr,
    socialLinkArrDefault,
    valueBioNameDefault,
    valueBioSubTitle,
    valueBioSubtitleDefault,
    valueUrlImage,
    valueUrlImageDefault,
    styleBioValue,
    styleBioValueDefault,
    contentBioState,
    contentBioStateDefault,
    isShowCoverImage,
    defaultIsShowCoverImage,
    isAutoSwitch,
    defaultIsAutoSwitch,
    imageRatio,
    defaultImageRatio,
    imageCoverList,
    defaultListCoverImage,
    bioBookingProfile,
    defaultBookingProfile,
    checkFieldRequired,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      JSON.stringify(socialLinkArr) !== JSON.stringify(socialLinkArrDefault) ||
      valueBioName !== valueBioNameDefault ||
      valueBioSubTitle !== valueBioSubtitleDefault ||
      valueUrlImage !== valueUrlImageDefault ||
      styleBioValue !== styleBioValueDefault ||
      contentBioState !== contentBioStateDefault ||
      isShowCoverImage !== defaultIsShowCoverImage ||
      isAutoSwitch !== defaultIsAutoSwitch ||
      imageRatio !== defaultImageRatio ||
      imageCoverList !== defaultListCoverImage ||
      !isEqual(bioBookingProfile, defaultBookingProfile)
    ) {
      return true;
    } else return false;
  }, [
    valueBioName,
    socialLinkArr,
    socialLinkArrDefault,
    valueBioNameDefault,
    valueBioSubTitle,
    valueBioSubtitleDefault,
    valueUrlImage,
    valueUrlImageDefault,
    styleBioValue,
    styleBioValueDefault,
    contentBioState,
    contentBioStateDefault,
    isShowCoverImage,
    defaultIsShowCoverImage,
    isAutoSwitch,
    defaultIsAutoSwitch,
    imageRatio,
    defaultImageRatio,
    imageCoverList,
    defaultListCoverImage,
    bioBookingProfile,
    defaultBookingProfile,
    checkFieldRequired,
  ]);

  const imageCoverListSortOrder = useMemo(
    () => orderBy(imageCoverList, ["sort_order"], ["asc"]),
    [imageCoverList]
  );

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  useEffect(() => {
    if (!block.uid) {
      const defaultBioData = {
        uid: UID,
        sort_order: 1,
        enable: ENABLE_BLOCK,
        block_type: BLOCK_TYPE.BIO,
        title: titleBlockDefault,
        content_attributes: {
          social_link_items: socialLinkListSortOrder,
          bio_image: valueImageBase64 || valueUrlImage || defaultAvatar,
          bio_image_base64: valueImageBase64,
          bio_name: valueBioName,
          bio_subtitle: valueBioSubTitle,
          bio_content: contentBioState,
          bio_image_name: valueNameImage,
          bio_show_cover_image: isShowCoverImage,
          bio_image_ratio: imageRatio,
          bio_auto_switch: isAutoSwitch,
          bio_cover_image_list: imageCoverList,
          bio_booking_profile_display:
            bioBookingProfile.bio_booking_profile_display,
          bio_booking_profile_title:
            bioBookingProfile.bio_booking_profile_title.trim(),
          bio_booking_profile_description:
            bioBookingProfile.bio_booking_profile_description.trim(),
          bio_booking_profile_button_label:
            bioBookingProfile.bio_booking_profile_button_label.trim(),
        },
        style_attributes: {
          style_display: styleBioValue,
        },
      };
      onUpdateData(defaultBioData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          social_link_items: socialLinkListSortOrder,
          bio_image: valueImageBase64 || valueUrlImage || defaultAvatar,
          bio_image_base64: valueImageBase64,
          bio_name: valueBioName,
          bio_subtitle: valueBioSubTitle,
          bio_content: contentBioState,
          bio_image_name: valueNameImage,
          bio_show_cover_image: isShowCoverImage,
          bio_image_ratio: imageRatio,
          bio_auto_switch: isAutoSwitch,
          bio_cover_image_list: imageCoverList,
          bio_booking_profile_display:
            bioBookingProfile.bio_booking_profile_display,
          bio_booking_profile_title:
            bioBookingProfile.bio_booking_profile_title.trim(),
          bio_booking_profile_description:
            bioBookingProfile.bio_booking_profile_description.trim(),
          bio_booking_profile_button_label:
            bioBookingProfile.bio_booking_profile_button_label.trim(),
        },
        style_attributes: {
          style_display: styleBioValue,
        },
      });
    }
  }, [
    styleBioValue,
    valueImageBase64,
    valueBioName,
    valueBioSubTitle,
    contentBioState,
    socialLinkListSortOrder,
    valueNameImage,
    valueUrlImage,
    isShowCoverImage,
    imageRatio,
    isAutoSwitch,
    imageCoverList,
    bioBookingProfile,
  ]);

  const styleBioOptions = [
    {
      label: (
        <div id={elementId + "-" + BIO_DISPLAY_STYLES.STYLE1}>
          <Image
            // fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/bio-layout/bio-style-layout-1.svg"
            width={96}
            height={96}
          />
        </div>
      ),
      description: <div>{`${t("ss_builder_bio_layout_title")} 1`}</div>,
      value: BIO_DISPLAY_STYLES.STYLE1,
    },
    {
      label: (
        <div id={elementId + "-" + BIO_DISPLAY_STYLES.STYLE2}>
          <Image
            // fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/bio-layout/bio-style-layout-2.svg"
            width={96}
            height={96}
          />
        </div>
      ),
      description: <div>{`${t("ss_builder_bio_layout_title")} 2`}</div>,
      value: BIO_DISPLAY_STYLES.STYLE2,
    },
    {
      label: (
        <div id={elementId + "-" + BIO_DISPLAY_STYLES.STYLE3}>
          <Image
            // fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/bio-layout/bio-style-layout-3.svg"
            width={96}
            height={96}
          />
        </div>
      ),
      description: <div>{`${t("ss_builder_bio_layout_title")} 3`}</div>,
      value: BIO_DISPLAY_STYLES.STYLE3,
    },
    {
      label: (
        <div id={elementId + "-" + BIO_DISPLAY_STYLES.STYLE4}>
          <Image
            // fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/bio-layout/bio-style-layout-4.svg"
            width={96}
            height={96}
          />
        </div>
      ),
      description: <div>{`${t("ss_builder_bio_layout_title")} 4`}</div>,
      value: BIO_DISPLAY_STYLES.STYLE4,
    },
    {
      label: (
        <div id={elementId + "-" + BIO_DISPLAY_STYLES.STYLE5}>
          <Image
            // fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/bio-layout/bio-style-layout-5.svg"
            width={96}
            height={96}
          />
        </div>
      ),
      description: <div>{`${t("ss_builder_bio_layout_title")} 5`}</div>,
      value: BIO_DISPLAY_STYLES.STYLE5,
    },
    {
      label: (
        <div id={elementId + "-" + BIO_DISPLAY_STYLES.STYLE6}>
          <Image
            // fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/bio-layout/bio-style-layout-6.svg"
            width={96}
            height={96}
          />
        </div>
      ),
      description: <div>{`${t("ss_builder_bio_layout_title")} 6`}</div>,
      value: BIO_DISPLAY_STYLES.STYLE6,
    },
    {
      label: (
        <div id={elementId + "-" + BIO_DISPLAY_STYLES.STYLE7}>
          <Image
            // fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/bio-layout/bio-style-layout-7.svg"
            width={96}
            height={96}
          />
        </div>
      ),
      description: <div>{`${t("ss_builder_bio_layout_title")} 7`}</div>,
      value: BIO_DISPLAY_STYLES.STYLE7,
    },
  ];

  const handleSelectStyleBio = (arr) => {
    if (arr && arr.length) {
      const valueStyle = arr[0];
      setStyleBioValue(valueStyle);
    }
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
    }
  };

  const handleChangeBioName = (value) => {
    setValueBioName(value);
  };

  const handleChangeBioSubTitle = (value) => {
    setValueBioSubTitle(value);
  };

  const handleDeleteImage = () => {
    setValueUrlImage(null);
    setValueImageBase64(null);
    setValueNameImage(null);
    setUploadedImageArr([]);
  };

  const onContentStateChange = (value, editor) => {
    const cCount = charCount(editor);
    setContentBioState(value);
    setCount(cCount);
  };
  const handleInitEditor = (value, editor) => {
    setCount(charCount(editor));
  };

  const handleSelectImageRatio = (arr) => {
    if (arr && arr.length) {
      const valueRatio = arr[0];
      setImageRatio(valueRatio);
    }
  };

  const imageRate = useMemo(
    () =>
      imageCoverListSortOrder &&
      `${imageCoverListSortOrder.length}/${IMAGE_COVER_MAX}`,
    [imageCoverListSortOrder]
  );

  const handleOpenAddImageCover = () => {
    setIsOpenAddNewPopup(true);
    setIndexImageCoverEdit(null);
    setImageCoverEditData(null);
  };

  const handleSaveImageCover = (data) => {
    const imageSliderClone = cloneDeep(imageCoverListSortOrder);
    if (Array.isArray(imageSliderClone)) {
      if (imageCoverEditData) {
        const indexEditItems = imageSliderClone.findIndex(
          (x) => x.uid === imageCoverEditData.uid
        );
        imageSliderClone[indexEditItems] = data;
      } else {
        if (imageSliderClone.length === 0) {
          imageSliderClone.push(data);
        } else {
          const sortIdArr = imageSliderClone.map((x) => x.sort_order);
          imageSliderClone.push({
            ...data,
            sort_order: Math.max(...sortIdArr) + 1,
          });
        }
      }
      setImageCoverList(imageSliderClone);
      !imageCoverEditData && scrollToNewItem(data?.uid);
    }
  };

  const reorder = (start: number, end: number) => {
    const result = Array.from(imageCoverListSortOrder);
    const [itemRemove] = result.splice(start, 1);
    result.splice(end, 0, itemRemove);
    const orderedImageCoverList = result.map((ele, index) => ({
      ...(ele as Object),
      sort_order: index + 1,
    }));
    return orderedImageCoverList;
  };

  const onDragEnd = (result) => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    setImageCoverList(itemsOrder);
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  const onRemoveItem = (index: number) => {
    const imageSliderClone = cloneDeep(imageCoverListSortOrder);
    const newImageItems =
      imageSliderClone &&
      Array.isArray(imageSliderClone) &&
      imageSliderClone.filter((ele: any, idx: number) => idx !== index);
    setImageCoverList(newImageItems);
  };

  const onDisableEnableImage = (index: number, imageStatus: boolean) => {
    const imageSliderClone = cloneDeep(imageCoverListSortOrder);
    const newImageItems =
      imageSliderClone &&
      Array.isArray(imageSliderClone) &&
      imageSliderClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              enable: imageStatus,
            }
          : ele;
      });
    setImageCoverList(newImageItems);
  };

  const handleOpenEditImageCover = (imageData, index) => {
    setIsOpenAddNewPopup(true);
    setIndexImageCoverEdit(index);
    setImageCoverEditData(imageData);
  };

  const handleChangeProfile = (data) => {
    const dataCurrent = cloneDeep(bioBookingProfile);
    setBioBookingFile({ ...dataCurrent, ...data });
  };

  return (
    <>
      <div className="p-4 bg-white">
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_bio_layout_title")}
          </div>
          <AutoScrollSelector
            elementId={elementId}
            options={styleBioOptions}
            value={styleBioValue}
            onChange={handleSelectStyleBio}
          />
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_bio_avatar_title")}
          </div>
          <div className="grid grid-cols-[120px_auto] gap-4">
            <Image
              src={
                valueUrlImage ||
                "https://i.ecomobi.com/ssp/passio-page/avatar-default.png"
              }
              fit="cover"
              style={{
                borderRadius: "8px",
                maxWidth: "120px",
                maxHeight: "120px",
                backgroundColor: "#EEEEEE",
              }}
            />
            <div>
              <div className="mb-8 leading-4 text-[#8C8C8C]">
                {t("ss_builder_theme_setting_background_content")}
              </div>
              <div className="flex gap-2">
                <Button
                  size="middle"
                  color={valueUrlImage ? "primary" : "primary"}
                  disabled={valueUrlImage ? false : true}
                  fill="outline"
                  className=""
                  onClick={handleDeleteImage}
                >
                  <TrashIcon
                    className="w-[17px] h-[17px]"
                    fillColor={
                      valueUrlImage
                        ? "var(--adm-color-primary)"
                        : "var(--adm-color-primary)"
                    }
                  />
                </Button>
                <ImageUploader
                  onChange={handleImageUrl}
                  upload={(file) => mockUpload(file, 514, 980)}
                  className="flex-grow"
                  value={uploadedImageArr}
                  children={
                    <Button
                      color="primary"
                      fill="solid"
                      size="middle"
                      className="w-full"
                    >
                      {t("ss_builder_upload_button_label")}
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
          {isValidFileFormat && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_format_invalid_content")}
              </div>
            </div>
          )}
          {isValidFileSize && (
            <div className="flex flex-row gap-1 items-center mt-1">
              <InformationTriangleIcon
                fillColor="#FF3141"
                className="w-4 h-4"
              />
              <div className="text-[#FF3141]">
                {t("ss_builder_upload_file_size_invalid_content")}
              </div>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_bio_name_title")}
          </div>
          <Input
            value={valueBioName}
            onChange={handleChangeBioName}
            maxLength={50}
            placeholder={t("ss_builder_bio_name_title_placeholder")}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${valueBioName.length}/50`}</div>
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_bio_sub_title")}
          </div>
          <Input
            value={valueBioSubTitle}
            onChange={handleChangeBioSubTitle}
            maxLength={80}
            placeholder={t("ss_builder_bio_name_sub_placeholder")}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${valueBioSubTitle.length}/80`}</div>
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_bio_text_title")}
          </div>
          <EditorComp
            value={contentBioState}
            onUpdateValue={onContentStateChange}
            handleInit={handleInitEditor}
            placeholder={t("ss_builder_block_description_placeholder")}
          />
          {count <= EDITOR_MAX_LENGTH ? (
            <div
              className={cls(
                "text-[13px] leading-[17px] w-full text-right mt-2 text-[#999999]"
              )}
            >
              <span>{`${count}/${EDITOR_MAX_LENGTH}`}</span>
            </div>
          ) : (
            <div
              className={cls(
                "text-[13px] leading-[17px] w-full d-flex justify-between mt-2 gap-4 text-[red]"
              )}
            >
              <span>
                {t("ss_builder_text_editor_max_length", {
                  editor_max_length: EDITOR_MAX_LENGTH,
                })}
              </span>
              <span>{`${count}/${EDITOR_MAX_LENGTH}`}</span>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 bg-white py-3 pl-3 text-[17px] leading-[22px] font-normal">
        {t("ss_builder_social_network_label")}
      </div>
      <SocialLinkList
        socialLinkListSortOrder={socialLinkListSortOrder}
        callBackUpdateSocialLinkItems={(data) => setSocialLinkArr(data)}
      />
      <div className="bg-white mt-3">
        <div className="text-[17px] leading-[22px] font-normal py-[12px] pl-[12px]">
          {t("ss_builder_cover_image")}
        </div>
        <Divider style={{ margin: "0px" }} />
        <div className="px-4 py-3">
          <div className="text-[15px] text-[#666666] font-normal">
            {t("ss_builder_enable_shadow")}
          </div>
          <Switch
            defaultChecked={false}
            checked={isShowCoverImage}
            onChange={(val) => setIsShowCoverImage(val)}
          />
        </div>
        {isShowCoverImage && (
          <>
            <Divider style={{ margin: "0px" }} />
            <div className="px-4 pt-[13px] pb-6">
              <div className="text-[15px] text-[#666666] leading-[22px] font-normal mb-2">
                {t("ss_builder_image_slider_ratio_selected_title")}
              </div>
              <Selector
                options={imageOptions()}
                value={[imageRatio]}
                onChange={handleSelectImageRatio}
                columns={4}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </div>
            <Divider style={{ margin: "0px" }} />
            <div className="px-4 py-3">
              <div className="text-[15px] text-[#666666] font-normal">
                {t("ss_builder_image_slider_auto_play_switch_title")}
              </div>
              <Switch
                defaultChecked={false}
                checked={isAutoSwitch}
                onChange={(val) => setIsAutoSwitch(val)}
              />
            </div>
          </>
        )}
      </div>
      {isShowCoverImage && (
        <div className="relative">
          <StickyChecking previousStickyElementId="block-bio-editor-header">
            <div className="flex flex-row justify-between px-3 py-4">
              <div className="grow">
                <div className="text-[15px] text-[#666666]">
                  {t("ss_builder_social_links_list_title")}
                </div>
              </div>
              <div className="flex flex-grow justify-end gap-2">
                <Button
                  size="small"
                  fill="solid"
                  color="primary"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    padding: "3px 12px 3px 12px",
                    // opacity:
                    //   isFulledProductsAllGroups || !isAllowedToAddProducts
                    //     ? "40%"
                    //     : "",
                  }}
                  onClick={handleOpenAddImageCover}
                  disabled={imageCoverListSortOrder.length >= IMAGE_COVER_MAX}
                >
                  <AddIcon className="w-[15px] h-[15px]" />
                  <div>{t("ss_builder_add_new_label")}</div>
                  <span>{imageRate}</span>
                </Button>
              </div>
            </div>
          </StickyChecking>
          {imageCoverList?.length > 0 ? (
            <div className="px-3">
              <DragDropContext
                onDragEnd={onDragEnd}
                onDragUpdate={onDragUpdate}
              >
                <Droppable droppableId={DROPPABLE_ID}>
                  {(provided, snapshot): JSX.Element => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="relative"
                    >
                      {imageCoverListSortOrder?.length > 0 &&
                        imageCoverListSortOrder.map((ele, index) => (
                          <Draggable
                            draggableId={ele.uid}
                            index={index}
                            key={ele.uid}
                          >
                            {(provided, snapshot): JSX.Element => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={cls(
                                  "mt-2",
                                  snapshot.isDragging && "adm-item-custom"
                                )}
                              >
                                <CoverImageList
                                  index={index}
                                  imageData={ele}
                                  onRemoveItem={onRemoveItem}
                                  onDisableEnableImage={onDisableEnableImage}
                                  callbackOpenEditImage={
                                    handleOpenEditImageCover
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      {!isEmpty(placeholderProps) &&
                        snapshot.isDraggingOver && (
                          <PlaceholderCustomDragging
                            placeholderProps={placeholderProps}
                            content={t(
                              "ss_builder_placeholder_dragging_content"
                            )}
                            style={{
                              backgroundColor: "rgba(230, 247, 255, 0.75)",
                              border: "1px dashed #1890FF",
                              borderRadius: "4px",
                              color: "#1890FF",
                            }}
                          />
                        )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <div className="flex flex-col text-center items-center justify-center">
              <span className="inline-block">
                <ImageIconEmpty className="align-middle w-8 h-8" />
              </span>
              <div className="text-[#333333] text-lg leading-6">
                {t("ss_builder_image_list_empty_title")}
              </div>
              <div className="text-[#8C8C8C] text-xs leading-4">
                {t("ss_builder_image_list_empty_content")}
              </div>
            </div>
          )}
        </div>
      )}
      {/*<CoverImage />*/}
      {/* Start Booking Profile div*/}
      <BookingProfileLink
        data={bioBookingProfile}
        onChange={handleChangeProfile}
      />
      {/* End Booking Profile div*/}

      <FullScreenPopup
        visible={isOpenAddNewPopup}
        onMaskClick={() => setIsOpenAddNewPopup(false)}
      >
        <CoverImageAddNew
          imageRatio={imageRatio}
          callBackCancelImageCoverAddNew={() => setIsOpenAddNewPopup(false)}
          handleSaveImageCover={handleSaveImageCover}
          imageCoverEditData={imageCoverEditData}
          indexImageCoverEdit={indexImageCoverEdit}
        />
      </FullScreenPopup>
    </>
  );
};

export default BioComponent;
