import { useState, useRef } from "react";
import style from "./style.module.scss";

export default function DndScrollable(props) {
  const [allowToScroll, setAllowToScroll] = useState<boolean>(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const { children, className, elementRef, ...rest } = props;
  const hookRef = useRef(elementRef);
  const ref = elementRef ? elementRef : hookRef;

  const onMouseDown = (e) => {
    setAllowToScroll(true);
    const slider = ref.current;
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const onMouseUp = (e) => {
    setAllowToScroll(false);
  };

  const onMouseLeave = (e) => {
    setAllowToScroll(false);
  };

  const onMouseMove = (e) => {
    if (!allowToScroll) return;
    const slider = e.currentTarget;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  };
  return (
    <div
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      ref={ref}
      className={`${className}
            ${style.container}`}
      {...rest}
    >
      {props.children}
    </div>
  );
}
