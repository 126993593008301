import React, { useState } from "react";
import { DEFAULT_IMG_URL, DEFAULT_UPLOAD_IMG_URL, RESIZE } from "@share/components/ImageUploadCommon/constants";
import UploadImage from "./UploadImage";
import { InformationTriangleIcon, TrashIcon } from "@share/icons";
import { mockUpload } from "@share/lib";
import { Button, Image, ImageUploader } from "antd-mobile";
import { useTranslation } from "react-i18next";

const Layout2 = (props) => {
  const {
    listImg,
    handleChangeImgs,
    imgStatus,
    errorMsg,
    value,
    maxWidth = RESIZE.MAX_WIDTH,
    maxHeight = RESIZE.MAX_HEIGHT,
    resizeType = 1,
    isRequired = false,
    hasFit,
  } = props;
  const { t } = useTranslation();

  const handleDeleteImage = () => {
    handleChangeImgs([{
      base64: "",
      url: "",
      file_name: "",
    }]);
  };

  return (
    <>
      <div className="">
        <div>
          <div className="">
            <div className="mb-4">
              <UploadImage
                valueUrlImage={value}
                imageRatio={"16/9"}
                hasFit={hasFit}
              />
            </div>
            <div className="flex gap-2">
              {!isRequired && <Button
                size="middle"
                color="default"
                fill="solid"
                style={{ borderColor: "#EE3244" }}
                disabled={value ? false : true}
                onClick={handleDeleteImage}
              >
                <TrashIcon
                  className="w-[17px] h-[17px]"
                  fillColor="#EE3244"
                />
              </Button>}
              <ImageUploader
                onChange={handleChangeImgs}
                upload={(file) => mockUpload(file, maxWidth, maxHeight, resizeType)}
                className="flex-grow"
                value={listImg}
                children={
                  <Button
                    color="primary"
                    fill="solid"
                    size="middle"
                    className="w-full"
                  >
                    {t("ss_builder_upload_button_label")}
                  </Button>
                }
              />
            </div>
          </div>
        </div>
      </div>
      {errorMsg[imgStatus] && (
        <div className="flex flex-row gap-1 items-center mt-1">
          <span className="w-4 h-4">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
          </span>
          <div className="text-[#FF3141]">{errorMsg[imgStatus]}</div>
        </div>
      )}
    </>
  );
}
export default Layout2;