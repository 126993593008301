import React, { useMemo, useState } from "react";
import { Button, Divider, ImageUploader, NavBar, Switch } from "antd-mobile";
import { useTranslation } from "react-i18next";
import PopupComponent from "@share/components/PopupComponent";
import ListFrame from "@features/block/block-editor/AffiliateProductGroup/Frame/ListFrame";
import { InformationTriangleIcon } from "@share/icons";
import { requestUploadImage } from "@share/api";
import { useAppSelector } from "@app/hooks";
import { mockUpload } from "@share/lib";
import {
  LIST_FRAME,
  TRANSPARENT_IMAGE,
  TYPE_FILE_IMAGE_FRAME,
} from "@features/block/block-editor/AffiliateProductGroup/Frame/const";
import { useModal } from "@share/hooks/modal";
import { getHorizontalResizeUrl } from "@share/helper/image";

const FrameAff = (props) => {
  const { triggerOpenClose, handleClickSave, frameDefault, usingFrameDefault } =
    props;
  const { t } = useTranslation();
  const { ConfirmModal } = useModal();

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const [useFrameAff, setUseFrameAff] = useState<boolean>(usingFrameDefault);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [valueUrlImage, setValueUrlImage] = useState(
    frameDefault.urlEU || LIST_FRAME[0].urlEU
  );
  const [valueNameImage, setValueNameImage] = useState(null);
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [frameData, setFrameData] = useState(frameDefault);
  const [usingFromList, setUsingFromList] = useState<boolean>();

  const disableButtonSave = useMemo(() => {
    if (valueImageBase64 && !usingFromList) return false;
    return !(
      useFrameAff !== usingFrameDefault ||
      JSON.stringify(frameData) !== JSON.stringify(frameDefault)
    );
  }, [
    useFrameAff,
    usingFrameDefault,
    frameData,
    frameDefault,
    valueImageBase64,
    usingFromList,
  ]);

  const uploadImageFrame = async (data) => {
    const { image, image_name } = data;
    let responseImage = null;
    if (!image?.startsWith("http")) {
      const dataRequestUploadImage = {
        id: siteSettings.id,
        user_id: siteSettings.user_id,
        name: image_name,
        content: image,
      };
      try {
        responseImage = await requestUploadImage(dataRequestUploadImage);
      } catch (error) {
        console.log(error);
      }
    }
    return responseImage;
  };
  const handleClick = async () => {
    if (valueImageBase64) {
      uploadImageFrame({
        image: valueImageBase64,
        image_name: valueNameImage,
      })
        .then((res) => {
          handleClickSave(useFrameAff, { url: res.url, urlEU: res.url });
          triggerOpenClose(false);
        })
        .catch((e) => console.log(e));
    } else {
      handleClickSave(useFrameAff, frameData);
      triggerOpenClose(false);
    }
  };
  const handleBeforeUpload = (file) => {
    const fileType = file?.type;
    if (TYPE_FILE_IMAGE_FRAME.includes(fileType)) return file;
    setIsValidFileFormat(true);
    return null;
  };

  const handleUploadImageUrl = (value) => {
    setUsingFromList(false);
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
    }
  };
  const handleClose = () => {
    if (disableButtonSave) {
      triggerOpenClose(false);
    } else {
      ConfirmModal({
        title: t("ss_builder_cancel_edit_block_title"),
        description: t("ss_builder_cancel_edit_block_content"),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          triggerOpenClose(false);
        },
      });
    }
  };

  return (
    <div>
      <NavBar
        className="bg-white sticky top-0 z-[9999]"
        onBack={() => triggerOpenClose(false)}
        backArrow={false}
      >
        <div className="flex justify-center text-[18px] font-normal text-[#333]">
          {t("ss_builder_frame_aff")}
          <div
            className="absolute right-[18px] cursor-pointer w-8 h-8 flex justify-center items-center"
            onClick={() => triggerOpenClose(true)}
          ></div>
        </div>
      </NavBar>

      <div
        className="py-3 px-4 bg-white  "
        style={{ borderTop: "1px solid #EEE" }}
      >
        <div className="text-[15px] leading-[22px] font-normal text-[#666666] font-normal">
          {t("ss_builder_frame_aff")}
        </div>
        <Switch
          checked={useFrameAff}
          className="mt-2"
          onChange={(val) => setUseFrameAff(val)}
          uncheckedText={t("ss_builder_off")}
          checkedText={t("ss_builder_on")}
        />
        {!!useFrameAff && (
          <>
            <Divider className="!my-3" />

            <div className="grid grid-cols-[120px_auto] gap-4 mt-3">
              <div
                style={{
                  backgroundImage: `url(${getHorizontalResizeUrl(
                    usingFromList ? frameData.url : valueUrlImage
                  )}) , url(${TRANSPARENT_IMAGE})`,
                  backgroundPosition: "left bottom, top left",
                  backgroundRepeat: "no-repeat, repeat",
                  backgroundSize: "contain, auto",
                  aspectRatio: "1/1",
                  borderRadius: "4px",
                }}
              ></div>

              <div>
                <div className="mb-[22px] leading-4 text-[#8C8C8C] text-xs">
                  <div>{t("ss_builder_aff_frame_max_size_content")}</div>
                  <div>
                    {t("ss_builder_aff_frame_support_type_file_content")}
                  </div>
                </div>
                <ImageUploader
                  beforeUpload={handleBeforeUpload}
                  onChange={handleUploadImageUrl}
                  upload={(file) => mockUpload(file, 514, 980)}
                  className="flex-grow"
                  value={uploadedImageArr}
                  // accept="image/png, image/gif, image/svg"
                  children={
                    <Button
                      // color="primary"
                      fill="solid"
                      size="small"
                      className="w-full grow"
                      style={{
                        color: "#EE3244",
                        border: "1px solid #EE3244",
                      }}
                    >
                      {t("ss_builder_upload_button_label")}
                    </Button>
                  }
                />

                <Button
                  color="primary"
                  fill="solid"
                  size="small"
                  className="w-full !mt-2 grow"
                  onClick={() => setIsOpenPopup(true)}
                >
                  {t("ss_builder_aff_frame_select_from_library")}
                </Button>
              </div>
            </div>
            {isValidFileFormat && (
              <div className="flex flex-row gap-1 items-center mt-1">
                <InformationTriangleIcon
                  fillColor="#FF3141"
                  className="w-4 h-4"
                />
                <div className="text-[#FF3141]">
                  {t(
                    "ss_builder_upload_file_format_invalid_content_for_gif_png_svg"
                  )}
                </div>
              </div>
            )}
            {isValidFileSize && (
              <div className="flex flex-row gap-1 items-center mt-1">
                <InformationTriangleIcon
                  fillColor="#FF3141"
                  className="w-4 h-4"
                />
                <div className="text-[#FF3141]">
                  {t("ss_builder_upload_file_size_invalid_content")}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]"
        style={{
          borderTop: `1px solid #EEEEEE`,
        }}
      >
        <div className="w-1/2 text-center">
          <Button
            // onClick={() => triggerOpenClose(false)}
            onClick={() => handleClose()}
            className="adm-button adm-button-default adm-button-shape-default w-full"
          >
            {t("ss_builder_close_panel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            color="primary"
            className="adm-button adm-button-primary adm-button-shape-default w-full"
            onClick={handleClick}
            disabled={disableButtonSave}
          >
            {t("ss_builder_save")}
          </Button>
        </div>
      </div>
      <PopupComponent
        visible={isOpenPopup}
        onMaskClick={() => setIsOpenPopup(false)}
        title={t("ss_builder_affiliate_product_frame_panel_title")}
        onClose={() => setIsOpenPopup(false)}
      >
        <ListFrame
          frame={frameData}
          setFrame={setFrameData}
          setUsingFromList={setUsingFromList}
          usingFromList={usingFromList}
          onClose={() => setIsOpenPopup(false)}
        />
      </PopupComponent>
    </div>
  );
};

export default FrameAff;
