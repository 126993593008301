import { ImageGalleryInterface } from "@features/main/store/interface";
import { InformationIcon, InformationTriangleIcon } from "@share/icons";
import { mockUpload } from "@share/lib";
import {
  Button,
  Divider,
  ImageUploader,
  Input,
  Modal,
  TextArea,
} from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import FieldTitle from "@share/components/FieldTitle";
import UploadImage from "@share/components/UploadImage";

type Props = {
  imageRatio: string;
  callBackCancelImageSliderAddNew: any;
  handleSaveImageSlider: any;
  imageEditData: ImageGalleryInterface;
  indexImageEdit: number;
};

const ImageGalleryNewImage = (props: Props) => {
  const DESCRIPTION_MAXIMUM = 250;
  const TITLE_MAXIMUM = 50;
  const {
    imageRatio,
    callBackCancelImageSliderAddNew,
    handleSaveImageSlider,
    imageEditData,
  } = props;
  const { t } = useTranslation();

  const UID = `image-${uuidv4()}`;

  const valueUrlImageDefault = imageEditData && imageEditData.image;
  const defaultTitle = imageEditData?.title;
  const defaultDescription = imageEditData?.description;

  const [valueUrlImage, setValueUrlImage] = useState(valueUrlImageDefault);
  const [valueNameImage, setValueNameImage] = useState(null);
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);

  useEffect(() => {
    if (valueUrlImageDefault) {
      setValueUrlImage(valueUrlImageDefault);
    }
    if (message) {
      setMessage(message);
    }
  }, [valueUrlImageDefault]);

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];

    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
    }
  };

  const handleSaveImage = () => {
    const defaultImageData = {
      uid: UID,
      sort_order: 1,
      enable: 1,
      image: valueImageBase64 || valueUrlImage,
      image_base64: valueImageBase64,
      title: title,
      description: description,
      name: valueNameImage,
    };
    if (imageEditData) {
      handleSaveImageSlider({
        ...imageEditData,
        image: valueImageBase64 || valueUrlImage,
        image_base64: valueImageBase64,
        title: title,
        description: description,
      });
    } else {
      handleSaveImageSlider(defaultImageData);
    }
    callBackCancelImageSliderAddNew();
    setValueUrlImage(null);
    setUploadedImageArr([]);
    setValueImageBase64(null);
  };

  const handleCancelImageSliderAddNew = () => {
    if (valueUrlImage && valueUrlImage !== valueUrlImageDefault) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          callBackCancelImageSliderAddNew();
          setValueUrlImage(null);
          setUploadedImageArr([]);
          setValueImageBase64(null);
        },
      });
    } else {
      callBackCancelImageSliderAddNew();
      setValueUrlImage(null);
      setUploadedImageArr([]);
      setValueImageBase64(null);
    }
  };
  const handleSetDescription = (val) => {
    if (val?.length <= DESCRIPTION_MAXIMUM) {
      setDescription(val);
    }
  };

  const isSaveButtonEnable =
    valueUrlImage &&
    (valueUrlImage !== valueUrlImageDefault ||
      title !== defaultTitle ||
      description !== defaultDescription);

  return (
    <div className="z-[2]">
      <div
        className="flex flex-row text-center items-center justify-center py-3 relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <div>
          {title && title != "" ? title : t("ss_builder_image_gallery")}
        </div>
      </div>
      {/* <Divider /> */}
      <div className="p-4 bg-white">
        <FieldTitle
          title={t("ss_builder_image")}
          isRequire={true}
        />
        <UploadImage
          valueUrlImage={valueUrlImage}
          imageRatio={imageRatio}
        />
        {isValidFileFormat && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_format_invalid_content")}
            </div>
          </div>
        )}
        {isValidFileSize && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_size_invalid_content")}
            </div>
          </div>
        )}
        <div className="flex gap-2 mt-4">
          <ImageUploader
            onChange={handleImageUrl}
            upload={(file) => mockUpload(file, 2000, 2000)}
            className={`image-slider-upload ${
              uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
            }`}
            value={uploadedImageArr}
            children={
              <Button
                block
                color="primary"
                fill="solid"
                size="middle"
                className="w-full grow"
              >
                {t("ss_builder_upload_button_label")}
              </Button>
            }
          />
        </div>
        <Divider />
        <div>
          <FieldTitle title={t("ss_builder_title")} />
          <Input
            type="text"
            value={title}
            placeholder={t("ss_builder_image_title_place_holder")}
            onChange={(value) => {
              setTitle(value);
            }}
            maxLength={TITLE_MAXIMUM}
          />
          <div className="py-1 text-gray-500 text-sm text-right">
            {title ? title.length : 0}/{TITLE_MAXIMUM}
          </div>
        </div>
        <Divider />
        <div>
          <FieldTitle title={t("ss_builder_description")} />
          <TextArea
            onChange={(value) => {
              handleSetDescription(value);
            }}
            value={description}
            placeholder={t("ss_builder_image_description_place_holder")}
            maxLength={DESCRIPTION_MAXIMUM}
          />
          <div className="py-1 text-gray-500 text-sm text-right">
            {description ? description.length : 0}/{DESCRIPTION_MAXIMUM}
          </div>
        </div>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={handleCancelImageSliderAddNew}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={!isSaveButtonEnable}
            color={"primary"}
            onClick={handleSaveImage}
          >
            {t("ss_builder_done_panel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryNewImage;
