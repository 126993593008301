import { ActionInterface } from "@app/store";
import * as api from "@features/design/api";
import {
  getExternalState,
  getExternalStateSuccess,
  setDataFont,
  setFirstGuideState,
  setFloatingBlockIntroductionState,
  setFloatingIntroductionState,
  setIntroduceDoubleTapState,
  setValidFont,
  setValidFontWeight,
} from "@features/design/store/externalStateSlice";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

function* getExternalStateData() {
  try {
    const userId = yield select((state) => state.siteSettings.user_id);
    const { data } = yield call(api.getGuideState, userId);
    const display_setting = yield select(
      (state) => state.siteSettings.display_setting
    );

    yield put(
      getExternalStateSuccess({
        guide_state: data.first_guide_state,
        introduce_double_tap: data.introduce_double_tap,
        floating_icon: {
          brand_gift: data?.floating_icon_brand_gift,
        },
        floating_block: {
          brand_rating: data?.floating_block_brand_rating,
        },
        data_font: {
          title: "",
          paragraph: "",
        },
        is_valid_font: true,
        font_weight_available: [],
        is_font_loading: false,
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* setFirstUserGuide(action: ActionInterface) {
  try {
    const userId = yield select((state) => state.siteSettings.user_id);
    const newGuideState = action.payload;
    yield call(api.setGuideState, userId, newGuideState);
  } catch (error) {
    console.error(error);
  }
}

function* setIntroduceDoubleTap(action: ActionInterface) {
  try {
    const userId = yield select((state) => state.siteSettings.user_id);
    const payload = action.payload;
    yield call(api.setIntroduceDoubleTapState, userId, payload);
  } catch (error) {
    console.error(error);
  }
}

function* setFloatingIntroduction(action: ActionInterface) {
  try {
    const userId = yield select((state) => state.siteSettings.user_id);
    const payload = action.payload;
    yield call(api.setFloatingIntroduceState, userId, payload);
  } catch (e) {
    console.error(e);
  }
}
function* setFloatingBlockIntroduction(action: ActionInterface) {
  try {
    const userId = yield select((state) => state.siteSettings.user_id);
    const payload = action.payload;
    yield call(api.setFloatingBlockIntroduceState, userId, payload);
  } catch (e) {
    console.error(e);
  }
}

function* setFontWeightAvailable(action: ActionInterface) {
  if (!action.payload) return;
  const payload = action.payload;
  const fontName = payload.title || payload.paragraph;
  if (!fontName) return;
  let validResponseFont = null;
  let responseFontWeight = null;
  let fontWeightAvailable = [];
  //Kiểm tra font có hợp lệ
  try {
    validResponseFont = yield call(api.checkFontGG, fontName);
  } catch (e) {
    console.error("error", e);
  }

  if (!validResponseFont) {
    yield put(setValidFont(false));
    return;
  }
  yield put(setValidFont(true));
  //Tiến hành lấy dữ liệu của font weight
  for (const item of [400, 500, 600, 700, 800, 900]) {
    try {
      responseFontWeight = yield call(api.checkFontGG, fontName, item);
      if (
        responseFontWeight?.status === 200 ||
        responseFontWeight?.status === "200"
      ) {
        fontWeightAvailable.push(item);
      }
    } catch {}
  }
  yield put(setValidFontWeight(fontWeightAvailable));
}

function* externalStateSaga() {
  yield takeEvery(getExternalState, getExternalStateData);
  yield takeEvery(setFirstGuideState, setFirstUserGuide);
  yield takeEvery(setIntroduceDoubleTapState, setIntroduceDoubleTap);
  yield takeEvery(setFloatingIntroductionState, setFloatingIntroduction);
  yield takeEvery(
    setFloatingBlockIntroductionState,
    setFloatingBlockIntroduction
  );
  yield takeLatest(setDataFont, setFontWeightAvailable);
}

export default externalStateSaga;
