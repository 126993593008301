import { IMAGE_ORIENTATION_TYPE } from "@share/configs/const";

export const imageOptions = (
  imageOrientation = IMAGE_ORIENTATION_TYPE.LANDSCAPE
) => [
  {
    label: "1:1",
    value: "1/1",
  },
  {
    label:
      imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "4:3" : "3:4",
    value: "4/3",
  },
  {
    label:
      imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:2" : "2:3",
    value: "3/2",
  },
  {
    label:
      imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "16:9" : "9:16",
    value: "16/9",
  },
  {
    label:
      imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "21:9" : "9:21",
    value: "21/9",
  },
  {
    label:
      imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:1" : "1:3",
    value: "3/1",
  },
];

export const DATA_BIO_BOOKING_PROFILE_DEFAULT = {
  bio_booking_profile_display: false,
  bio_booking_profile_title: "",
  bio_booking_profile_description: "",
  bio_booking_profile_button_label: "Hồ sơ công việc",
};

export const BIO_BOOKING_PROFILE_FIELD = {
  BIO_BOOKING_PROFILE_FIELD_DISPLAY: "bio_booking_profile_display",
  BIO_BOOKING_PROFILE_FIELD_TITLE: "bio_booking_profile_title",
  BIO_BOOKING_PROFILE_FIELD_DESCRIPTION: "bio_booking_profile_description",
  BIO_BOOKING_PROFILE_FIELD_LABEL: "bio_booking_profile_button_label",
}

export const TIME_REFRESH = 60 * 1000; // 1 minutes
export const FINISH_ONBOARDING = 1;
