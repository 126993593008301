import { useAppTranslation } from "@features/mini-store/share/hook";
import BlockWrapper from "@features/design/components/BlockWrapper";
import { Block } from "@features/main/store/interface";
import React from "react";
import { useAppSelector } from "@app/hooks";
type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};
interface data {
  loading: boolean;
}
export default function BlockBrandRating(props: Props) {
  const { t } = useAppTranslation();
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const allowStore = window["AllowStore"];
  const { inner_space_number, outside_space } =
  siteSettings?.display_setting?.block_setting?.block_style || {};
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const enable = blockData && blockData.enable;

  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <div className={`block-heading-class ${!allowStore?.allow_view_brand_rating && 'opacity-60'}`}>
        <h3 className={"design-class h3"}>{t('ss_builder_title_block_brand_rating')}</h3>
        <img
          width={48}
          height={48}
          src={"https://i.ecomobi.com/ssp/mini-store/brand-deal/brandRatingDetails.png"}
          className={"mx-auto mt-2"}
          alt=""
        />
      </div>
    </BlockWrapper>
  );
}
