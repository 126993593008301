import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

type VideoShoutoutState = {
  isUploading: boolean;
};

const initialState: VideoShoutoutState = {
  isUploading: false,
};

const videoShoutoutSlice = createSlice({
  name: "videoShoutout",
  initialState,
  reducers: {
    setUploading: (
      state: Draft<VideoShoutoutState>,
      action: PayloadAction<boolean>
    ) => {
      state.isUploading = action.payload;
    },
  },
});

export const videoShoutoutAction = videoShoutoutSlice.actions;
export default videoShoutoutSlice.reducer;
