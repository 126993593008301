import { MoreIcon } from "@share/icons";
import { Popover } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import React, { useMemo, useRef } from "react";
import cls from "classnames";

interface optionsInterface {
  key: string;
  icon?: any;
  text?: string;
  action?: () => void;
}

type Props = {
  className?: string;
  visible?: boolean;
  options: Array<optionsInterface>;
  fillColor?: string;
  popoverContainerId?: string;
};

const MoreActionsCommon = ({
  className,
  visible,
  options,
  fillColor,
  popoverContainerId,
}: Props) => {
  const popoverContainerRef = useRef(null);
  const actions: Action[] = useMemo(() => {
    return options.map(({ key, icon, text, action }) => ({
      key,
      icon,
      text,
      onClick: action,
    }));
  }, [options]);

  const getContainer = () => {
    if (popoverContainerId) {
      return document.getElementById(popoverContainerId);
    }
    return popoverContainerRef.current;
  };

  return (
    <div
      ref={popoverContainerRef}
      className="cursor-pointer w-full h-full flex items-center justify-center"
    >
      <Popover.Menu
        mode="dark"
        actions={actions}
        placement="bottom-end"
        trigger="click"
        stopPropagation={["click"]}
        getContainer={getContainer}
        // onAction={(item: Action) => handleClickPopoverItem(item)}
        {...(visible ? { visible: true } : {})}
      >
        <MoreIcon
          className={className}
          fillColor={fillColor}
        />
      </Popover.Menu>
    </div>
  );
};

export default MoreActionsCommon;
