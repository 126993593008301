export const ACTION_KEY = {
  EDIT: "edit",
  DELETE_DISPLAY: "delete-display",
  UP_FIRST: "up-first",
  DOWN_LAST: "down-last",
  DELETE: "delete",
};

export const IFRAME_ACTION = {
  CLOSE_IFRAME: "close-iframe",
  LIST_DATA: "list-data",
  GET_DATA: "get-data",
  RECIVED_DATA: "received-data",
  SAVE_INTRO: "save-intro",
  FETCH_DATA: "fetch-data",
};

export const MOVE = {
  UP_FIRST: "up-first",
  DOWN_LAST: "down-last",
};

export const URL_IFRAME = {
  SELECT_CATEGORY: "select-category",
  IFRAME: "iframe",
  EDIT: "edit",
  COURSE: "course",
  PHOTO: "photo",
  TICKET_ACCOUNT: "ticket-account"
};
