import {
  COUNTRY_ID,
  COUNTRY_MY,
  COUNTRY_PH,
  COUNTRY_SG,
  COUNTRY_TH,
  COUNTRY_VN,
} from "@share/configs/const";

export const getMaxPrice = (country) => {
  switch (country) {
    case COUNTRY_VN:
      return 9999999999;
    case COUNTRY_ID:
      return 200000000;
    case COUNTRY_MY:
      return 90000000;
    case COUNTRY_SG:
      return 50000;
    case COUNTRY_PH:
      return 100000000;
    case COUNTRY_TH:
      return 50000000;
    default:
      return 10000;
  }
};
export const getMinPrice = (country) => {
  switch (country) {
    case COUNTRY_VN:
      return 1000;
    case COUNTRY_ID:
      return 1000;
    case COUNTRY_MY:
      return 1;
    case COUNTRY_SG:
      return 1;
    case COUNTRY_PH:
      return 1000;
    case COUNTRY_TH:
      return 20;
    default:
      return 1;
  }
};
