import ImageUploadCommon from "@share/components/ImageUploadCommon";
import {
  IMAGE_UPLOAD_LAYOUTS,
  RESIZE,
  TYPE_RESIZE,
} from "@share/components/ImageUploadCommon/constants";
import InputCustom from "@share/components/InputCustom";
import React from "react";
import { useTranslation } from "react-i18next";
import { BOOKING_PROFILE_FIELD } from "../../constant";

type Props = {
  handleChange: (field: string, value: any) => void;
  handleErrorImage: (val) => void;
  image: any;
  booking_profile_title: string;
  booking_profile_description: string;
  booking_profile_button_content: string;
};

const CardContentStyle = ({
  handleChange,
  handleErrorImage,
  image,
  booking_profile_title,
  booking_profile_description,
  booking_profile_button_content,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="px-4 py-3 bg-white mb-3">
        <ImageUploadCommon
          value={image}
          onChange={(img) => handleChange(BOOKING_PROFILE_FIELD.IMAGE, img)}
          layout_type={IMAGE_UPLOAD_LAYOUTS.LAYOUT_2}
          label={t("ss_builder_image")}
          isRequired={true}
          maxWidth={RESIZE.MAX_WIDTH}
          resizeType={TYPE_RESIZE.WIDTH}
          className="p-0"
          onError={handleErrorImage}
        />
      </div>
      <div className="px-4 py-3 bg-white mb-3">
        <InputCustom
          label={t("ss_builder_block_booking_profile_setting_title")}
          value={booking_profile_title}
          maxLength={50}
          placeholder={t("ss_builder_block_description_placeholder")}
          onChange={(value) => handleChange(BOOKING_PROFILE_FIELD.TITLE, value)}
          hasNoBorder={true}
          showCountCharacter={true}
          hasBottomDivider={true}
        />
        <InputCustom
          label={t("ss_builder_block_booking_profile_setting_description")}
          value={booking_profile_description}
          maxLength={250}
          placeholder={t("ss_builder_block_description_placeholder")}
          onChange={(value) =>
            handleChange(BOOKING_PROFILE_FIELD.DESCRIPTION, value)
          }
          hasNoBorder={true}
          isMultiline={true}
          showCountCharacter={true}
          hasBottomDivider={true}
        />
        <InputCustom
          label={t("ss_builder_block_booking_profile_button_label")}
          value={booking_profile_button_content}
          maxLength={25}
          placeholder={t("ss_builder_block_description_placeholder")}
          onChange={(value) =>
            handleChange(BOOKING_PROFILE_FIELD.BUTTON_CONTENT, value)
          }
          hasNoBorder={true}
          required={true}
          showCountCharacter={true}
          hasBottomDivider={false}
        />
      </div>
    </>
  );
};

export default CardContentStyle;
