import { CreateGatewayAxios } from "@share/api/createAxios";

const baseApiUrlGW = process.env.NEXT_PUBLIC_GATEWAY_URL;
const API_URL = {
  list_category: `${baseApiUrlGW}/api/mini-store/v1/catalog/brand-gift/categories`,
  setting: `${baseApiUrlGW}/api/mini-store/v1/catalog/brand-gift/categories/setting`,
};
const CaxiosGW = CreateGatewayAxios(); 
const brandGiftApi = {
  async listCategory(params) {
    const res = await CaxiosGW.get(API_URL.list_category, {params});
    return res.data;
  },
  async setting(params) {
    const res = await CaxiosGW.post(API_URL.setting, params);
    return res.data;
  },
};

export default brandGiftApi;
