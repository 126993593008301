import { Button, Checkbox, Image, List, Popover } from "antd-mobile";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import {
  BroomIcon,
  BroomLockIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  FolderArrowRightIcon,
  MoreIcon,
  TrashIcon,
} from "@share/icons";
import cloneDeep from "lodash/cloneDeep";
import { Action } from "antd-mobile/es/components/popover";
import AffiliateProductMovingPanel from "../AffiliateProductMovingPanel";
import PopupComponent from "@share/components/PopupComponent";
import { CheckCircleIconV2 } from "@share/icons/line-icon";
import { useToast } from "@share/hooks/toast";
import { useModal } from "@share/hooks/modal";

enum ActionEnum {
  VISIBLE_PRODUCT = "visible-product",
  HIDE_PRODUCT = "hide-product",
  MOVE_PRODUCT = "move-product",
  CLEANUP_LOCK_PRODUCT = "cleanup-lock-product",
  CLEANUP_UNLOCK_PRODUCT = "cleanup-unlock-product",
  ENABLE_TRACKING_LINK = "enable-tracking-link",
}

const AffiliateProductSelection = (props) => {
  const {
    affiliateProductLists,
    isSticky,
    isOnlyProductGroup,
    callBackCancelAffiliateProductSelection,
    onDeleteProducts,
    onEnableProducts,
    onEnableTrackingLinkProducts,
    onHideProducts,
    onMoveProducts,
    onCleanupLockProducts,
    onCleanupUnlockProducts,
    allowedMovingAffiliateProductGroup,
    stickyTop,
  } = props;
  const { t } = useTranslation();
  const popoverRef = useRef(null);
  const { SuccessToast } = useToast();
  const { ConfirmModal } = useModal();

  const [valueCheckedArr, setValueCheckedArr] = useState([]);
  const [
    isOpenMoveAffiliateProductsPanel,
    setIsOpenMoveAffiliateProductsPanel,
  ] = useState(false);

  const actions: Action[] = [
    {
      key: ActionEnum.VISIBLE_PRODUCT,
      icon: <EyeHiddenIcon className="" />,
      text: t("ss_builder_show_product_label"),
    },
    {
      key: ActionEnum.HIDE_PRODUCT,
      icon: <EyeVisibleIcon className="" />,
      text: t("ss_builder_hide_product_label"),
    },
    {
      key: ActionEnum.MOVE_PRODUCT,
      icon: (
        <FolderArrowRightIcon
          className=""
          fillColor="white"
        />
      ),
      text: t("ss_builder_move_product_label"),
    },
    {
      key: ActionEnum.CLEANUP_LOCK_PRODUCT,
      icon: (
        <BroomLockIcon
          className=""
          fillColor="white"
        />
      ),
      text: t("ss_builder_affiliate_product_cleanup_lock_label"),
    },
    {
      key: ActionEnum.CLEANUP_UNLOCK_PRODUCT,
      icon: (
        <BroomIcon
          className=""
          fillColor="white"
        />
      ),
      text: t("ss_builder_affiliate_product_cleanup_unlock_label"),
    },
    {
      key: ActionEnum.ENABLE_TRACKING_LINK,
      icon: (
        <CheckCircleIconV2
          className=""
          fillColor="white"
        />
      ),
      text: t("ss_builder_enable_tracking_link_product_label"),
    },
  ];

  const handleGetCheckedProductGroup = (val) => {
    setValueCheckedArr(val);
  };

  const handleSelectProductOption = useCallback(
    (optionValue) => {
      const affiliateProductListsClone = cloneDeep(affiliateProductLists);
      if (affiliateProductLists?.length >= optionValue) {
        const newValueCheckedArr = affiliateProductListsClone
          .slice(-optionValue)
          ?.map((x) => x.uid);
        setValueCheckedArr(newValueCheckedArr);
      } else {
        setValueCheckedArr(affiliateProductLists?.map((x) => x.uid));
      }
    },
    [affiliateProductLists]
  );

  const handleGetValueCheckedCustom = (checked) => {
    if (checked) {
      setValueCheckedArr(affiliateProductLists?.map((x) => x.uid));
    } else {
      setValueCheckedArr([]);
    }
  };

  const handleDeleteProducts = () => {
    const onConfirm = () => {
      onDeleteProducts(valueCheckedArr);
      SuccessToast(t("ss_builder_delete_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_confirm_delete_product_modal_title"),
      description: t(
        "ss_builder_confirm_delete_product_selection_modal_content",
        {
          product_quantity: valueCheckedArr.length,
        }
      ),
      confirmText: t("ss_builder_confirm_delete_product_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const handleEnableProducts = () => {
    const onConfirm = () => {
      onEnableProducts(valueCheckedArr);
      SuccessToast(t("ss_builder_enable_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_show_product_label"),
      description: t(
        "ss_builder_confirm_enable_product_selection_modal_content",
        {
          product_quantity: valueCheckedArr.length,
        }
      ),
      confirmText: t("ss_builder_confirm_show_product_btn_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const handleHideProducts = () => {
    const onConfirm = () => {
      onHideProducts(valueCheckedArr);
      SuccessToast(t("ss_builder_disable_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_hide_product_label"),
      description: t(
        "ss_builder_confirm_hide_product_selection_modal_content",
        {
          product_quantity: valueCheckedArr.length,
        }
      ),
      confirmText: t("ss_builder_confirm_hide_product_btn_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const handleCleanupLockProducts = () => {
    const onConfirm = () => {
      onCleanupLockProducts(valueCheckedArr);
      SuccessToast(t("ss_builder_cleanup_lock_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_affiliate_product_cleanup_lock_label"),
      description: t(
        "ss_builder_confirm_cleanup_lock_product_selection_modal_content",
        {
          product_quantity: valueCheckedArr.length,
        }
      ),
      confirmText: t("ss_builder_confirm_cleanup_lock_product_btn_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const handleCleanupUnlockProducts = () => {
    const onConfirm = () => {
      onCleanupUnlockProducts(valueCheckedArr);
      SuccessToast(t("ss_builder_cleanup_unlock_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_affiliate_product_cleanup_unlock_label"),
      description: t(
        "ss_builder_confirm_cleanup_unlock_product_selection_modal_content",
        {
          product_quantity: valueCheckedArr.length,
        }
      ),
      confirmText: t("ss_builder_confirm_cleanup_unlock_product_btn_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case ActionEnum.VISIBLE_PRODUCT:
        handleEnableProducts();
        break;
      case ActionEnum.HIDE_PRODUCT:
        handleHideProducts();
        break;
      case ActionEnum.MOVE_PRODUCT:
        setIsOpenMoveAffiliateProductsPanel(true);
        break;
      case ActionEnum.CLEANUP_LOCK_PRODUCT:
        handleCleanupLockProducts();
        break;
      case ActionEnum.CLEANUP_UNLOCK_PRODUCT:
        handleCleanupUnlockProducts();
        break;
      case ActionEnum.ENABLE_TRACKING_LINK:
        onEnableTrackingLinkProducts(valueCheckedArr);
        SuccessToast(
          t("ss_builder_enable_tracking_link_product_successfully_label")
        );
        break;
      default:
      // to do
    }
  };

  const handleConfirmAffiliateProductMovingPanel = (productGroupId) => {
    onMoveProducts(valueCheckedArr, productGroupId);
  };

  const actionsFilter = useMemo(() => {
    let actionsDefault = actions;
    if (isOnlyProductGroup) {
      actionsDefault = actionsDefault.filter(
        (x) => x.key !== ActionEnum.MOVE_PRODUCT
      );
    }
    return actionsDefault;
  }, [isOnlyProductGroup]);

  return (
    <div className="affiliate-product-list-selection">
      <div
        className="affiliate-product-list-header py-4 px-3 sticky z-10"
        style={{
          backgroundColor: isSticky ? "white" : "",
          boxShadow: isSticky ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "",
          top: `${stickyTop}px`,
        }}
      >
        <div className="flex flex-row justify-between items-center">
          <div className="grow">
            <div className="text-[15px] text-[#666666]">
              <span className="text-red-600">*</span>
              {t("ss_builder_affiliate_product_list_title")}
            </div>
          </div>
          <div>
            <Button
              size="small"
              fill="solid"
              color="primary"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
              onClick={callBackCancelAffiliateProductSelection}
            >
              <CloseCircleIcon
                className="w-[15px] h-[15px]"
                fillColor="white"
              />
              <div>{t("ss_builder_close_panel")}</div>
            </Button>
          </div>
        </div>

        <div className="flex flex-row gap-4 items-center ml-[10px] mt-4">
          <Checkbox
            onChange={handleGetValueCheckedCustom}
            indeterminate={
              valueCheckedArr.length > 0 &&
              valueCheckedArr.length < affiliateProductLists.length
            }
            checked={valueCheckedArr.length === affiliateProductLists.length}
          />
          <div className="flex flex-row gap-2 items-center">
            <span>{t("ss_builder_select_label")}:</span>
            <div className="flex flex-row gap-2 items-center">
              {[10, 20, 50].map((item) => (
                <Button
                  shape="rounded"
                  fill="solid"
                  size="small"
                  onClick={() => handleSelectProductOption(item)}
                >
                  {t("ss_builder_filter_select_multi_items", {
                    num_items: item,
                  })}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        <Checkbox.Group
          onChange={handleGetCheckedProductGroup}
          value={valueCheckedArr}
          // disabled={isDisableSelectProduct}
        >
          {affiliateProductLists?.map((ele, index) => (
            <List
              style={{
                "--border-bottom": "unset",
                "--border-top": "unset",
                marginBottom: "10px",
              }}
              className={cls(!ele?.title && "adm-item-error")}
            >
              <List.Item
                arrow={false}
                key={index}
              >
                <div className="relative">
                  <Checkbox
                    value={ele.uid}
                    className="w-full"
                  >
                    <div
                      className={cls("flex items-center gap-[6px] flex-row")}
                      style={{
                        opacity: ele?.enable !== 1 ? "40%" : "",
                      }}
                    >
                      <Image
                        src={ele.image}
                        width={40}
                        height={40}
                        fit="cover"
                        style={{
                          outline: "rgba(0, 0, 0, 0.16) solid 1px",
                          outlineOffset: "-1px",
                          borderRadius: "4px",
                        }}
                      />
                      <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
                        {ele.title ? (
                          <div className="text-ellipsis whitespace-nowrap overflow-hidden text-[17px] leading-[22px] font-normal">
                            {ele.title}
                          </div>
                        ) : (
                          <div className="text-ellipsis whitespace-nowrap overflow-hidden text-sm text-[#FF3141]">
                            {t("ss_builder_product_title_error")}
                          </div>
                        )}

                        {ele?.use_aff_url === 1 ? (
                          <div className="flex flex-row gap-1">
                            <div>
                              <CheckCircleIcon
                                className="w-4 h-4"
                                fillColor="#00B578"
                              />
                            </div>
                            <div className="text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4 text-[#8C8C8C]">
                              {t("ss_builder_product_link_wrapped_label")}
                            </div>
                          </div>
                        ) : (
                          <span className="text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4 text-[#8C8C8C]">
                            {ele?.origin_link}
                          </span>
                        )}
                      </div>
                    </div>
                  </Checkbox>
                  {ele?.is_cleanup_lock === 1 && (
                    <Popover
                      content={t(
                        "ss_builder_affiliate_product_clean_up_popover_content"
                      )}
                      trigger="click"
                      placement="bottom"
                      mode="dark"
                      stopPropagation={["click"]}
                      getContainer={() => popoverRef.current}
                    >
                      <div
                        className="absolute top-[-5px] left-[25px] cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="relative rounded-ful">
                          <div className="p-3 rounded-full bg-black opacity-80"></div>
                          <span className="absolute z-[2] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                            <BroomLockIcon
                              className="w-[14px] h-[14px]"
                              fillColor="#FFFFFF"
                            />
                          </span>
                        </div>
                      </div>
                    </Popover>
                  )}
                </div>
              </List.Item>
            </List>
          ))}
        </Checkbox.Group>
      </div>
      <div
        className="max-width-content-class fixed bottom-0 p-3 flex w-full justify-between items-center gap-2 z-[11] bg-[#FFFFFF]"
        ref={popoverRef}
      >
        <div className="text-lg leading-6">
          <span className="text-[#EE3244]">{valueCheckedArr?.length} </span>
          <span>{t("ss_builder_selected_product_in_selection_label")}</span>
        </div>
        <div className="flex flex-row items-center">
          <Button
            size="small"
            style={{
              border: "unset",
              padding: "3px 8px",
            }}
            disabled={valueCheckedArr?.length === 0}
            onClick={handleDeleteProducts}
          >
            <TrashIcon fillColor="#EE3244" />
          </Button>

          <div className="flex flex-row items-center gap-[15px]">
            <div className="">
              <Popover.Menu
                mode="dark"
                actions={actionsFilter}
                placement="top"
                trigger="click"
                stopPropagation={["click"]}
                getContainer={() => popoverRef.current}
                onAction={(item: Action) => handleClickPopoverItem(item)}
              >
                <Button
                  size="small"
                  style={{
                    border: "unset",
                    padding: "3px 8px",
                  }}
                  disabled={valueCheckedArr?.length === 0}
                >
                  <MoreIcon className="cursor-pointer" />
                </Button>
              </Popover.Menu>
            </div>
          </div>
        </div>
      </div>
      <PopupComponent
        visible={isOpenMoveAffiliateProductsPanel}
        onMaskClick={() => setIsOpenMoveAffiliateProductsPanel(false)}
        title={t("ss_builder_affiliate_product_moving_panel_title")}
        onClose={() => setIsOpenMoveAffiliateProductsPanel(false)}
      >
        <AffiliateProductMovingPanel
          callbackCancelAffiliateProductMovingPanel={() =>
            setIsOpenMoveAffiliateProductsPanel(false)
          }
          callbackConfirmAffiliateProductMovingPanel={
            handleConfirmAffiliateProductMovingPanel
          }
          allowedMovingAffiliateProductGroup={
            allowedMovingAffiliateProductGroup
          }
          productMovingQuantity={valueCheckedArr?.length}
        />
      </PopupComponent>
    </div>
  );
};

export default AffiliateProductSelection;
