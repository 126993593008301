import { RootState } from "@app/store";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

type DigitalProductState = {
  isDeleteItems: boolean;
  dataEdit: any;
  dataRemove: number[]
};

const initialState: DigitalProductState = {
  isDeleteItems: false,
  dataEdit: null,
  dataRemove: null,
};

const digitalProductSlice = createSlice({
  name: "digitalProduct",
  initialState,
  reducers: {
    setIsDeleteItems: (
      state: Draft<DigitalProductState>,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteItems = action.payload;
    },
    setDataEdit: (
      state: Draft<DigitalProductState>,
      action: PayloadAction<any>
    ) => {
      state.dataEdit = action.payload;
    },
    setDataRemove: (
      state: Draft<DigitalProductState>,
      action: PayloadAction<number[]>
    ) => {
      state.dataRemove = action.payload;
    },
  },
});

export const digitalProductAction = digitalProductSlice.actions;
export default digitalProductSlice.reducer;
