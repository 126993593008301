import HeaderTitle from "@design-components/HeaderTitle";
import InnerPopup from "@design-components/InnerPopup";
import { Popup } from "antd-mobile";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { List, Image } from "antd-mobile";
import IconRender from "@share/IconRender";
import { LINK_TYPE_LIST } from "@share/configs/const";

interface LinkTypeSelectorPopupPropsInterface {
  visible: boolean;
  isHiddenService: boolean;
  onSelect: (value: string) => void;
  getContainer?: () => HTMLElement;
  onClose: () => void;
}

const LINK_TYPE_ITEMS = [
  {
    value: LINK_TYPE_LIST.WEB,
    label_key: "ss_builder_link_type_web",
    icon: (
      <IconRender
        containerClass="block"
        iconName="globe.svg"
      />
    ),
  },
  {
    value: LINK_TYPE_LIST.PHONE,
    label_key: "ss_builder_link_type_phone",
    icon: (
      <IconRender
        containerClass="block"
        iconName="phone.svg"
      />
    ),
  },
  {
    value: LINK_TYPE_LIST.EMAIL,
    label_key: "ss_builder_link_type_email",
    icon: (
      <IconRender
        containerClass="block"
        iconName="envelope.svg"
      />
    ),
  },
  {
    value: LINK_TYPE_LIST.SERVICE,
    label_key: "ss_builder_link_type_service",
    icon: (
      <IconRender
        containerClass="block"
        iconName="selfie.svg"
      />
    ),
  },
];

export default function LinkTypeSelectorPopup(
  props: LinkTypeSelectorPopupPropsInterface
) {
  const {
    visible,
    isHiddenService,
    onSelect,
    onClose,
    getContainer = () => document.body,
  } = props;
  const { t } = useTranslation();
  const selectItem = (value) => {
    onSelect(value);
    onClose();
  };
  let linkTypeItem = LINK_TYPE_ITEMS;

  if (isHiddenService) {
    linkTypeItem = LINK_TYPE_ITEMS.filter(
      (LINK_TYPE_ITEMS) => LINK_TYPE_ITEMS.value !== LINK_TYPE_LIST.SERVICE
    );
  }

  return (
    <InnerPopup
      className=""
      getContainer={getContainer}
      visible={visible}
      onClose={onClose}
    >
      {visible && (
        <>
          <HeaderTitle
            onClose={onClose}
            title={t("ss_builder_select_link_type")}
          />
        </>
      )}

      <List>
        {linkTypeItem.map((item) => (
          <List.Item
            key={item.value}
            onClick={() => selectItem(item.value)}
            prefix={item.icon}
            clickable
            arrow={false}
          >
            {t(item.label_key)}
          </List.Item>
        ))}
      </List>
    </InnerPopup>
  );
}
