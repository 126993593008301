import { CreateGatewayAxios } from "@share/api/createAxios";
import {
  ListDTO
} from "../models/ParamsDTO";
import { ProductsResultInterfaceV2 } from "../models/ResultsDTO";

const baseApiUrlGW = process.env.NEXT_PUBLIC_GATEWAY_URL;
const API_URL = {
  list_product: `${baseApiUrlGW}/api/mini-store/v1/catalog/product/list/v2`,
};
const CaxiosGW = CreateGatewayAxios();

const digitalProductApi = {
  async listProduct(params: ListDTO): Promise<ProductsResultInterfaceV2> {
    const res = await CaxiosGW.get(API_URL.list_product, {
      params,
    });
    return res.data;
  },
};

export default digitalProductApi;
