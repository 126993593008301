import { PASSIO_HOME_PAGE } from "@share/configs/system";

export const AUTH_PAGE = {
  register: `${PASSIO_HOME_PAGE}/register`,
  login: `${PASSIO_HOME_PAGE}/login?redirect-to-page=passio-page`,
};

export const ONBOARDING_PAGE = {
  onboarding: `/onboarding`,
};

export const HOME_PAGE = `/`;
