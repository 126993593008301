import { useAppSelector } from "@app/hooks";
import {
  PARAGRAPH_SETTING_DEFAULT_VALUE,
  TITLE_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import { Editor } from "@tinymce/tinymce-react";
import { useMemo, useState } from "react";
// import initFullProps from "./init-config";

const EditorComp = (props) => {
  const { value, onUpdateValue, handleInit, placeholder = "" } = props;
  const siteSettings = useAppSelector<any>((state) => state.siteSettings);
  const displaySettings =
    siteSettings &&
    Object.values(siteSettings).length > 0 &&
    siteSettings.display_setting;
  const titleSetting = displaySettings?.title_setting;
  const paragraphSetting = displaySettings?.paragraph_setting;
  const {
    font_family: titleFontFamily = TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color: titleColor = TITLE_SETTING_DEFAULT_VALUE.FONT_COLOR,
    font_size: titleFontSize = TITLE_SETTING_DEFAULT_VALUE.FONT_SIZE,
    ratio: titleRatio = TITLE_SETTING_DEFAULT_VALUE.FONT_SCALE,
    font_weight: titleFontWeight = TITLE_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height: titleLineHeight = TITLE_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  } = titleSetting || {};
  const {
    font_family:
      paragraphFontFamily = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color: paragraphColor = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_COLOR,
    font_size: paragraphFontSize = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SIZE,
    ratio: paragraphRatio = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SCALE,
    font_weight:
      paragraphFontWeight = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height:
      paragraphLineHeight = PARAGRAPH_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  } = paragraphSetting || {};

  const initFullProps = useMemo(() => {
    const fontSizeH1 =
      titleFontSize *
      titleRatio *
      titleRatio *
      titleRatio *
      titleRatio *
      titleRatio;
    const fontSizeH2 =
      titleFontSize * titleRatio * titleRatio * titleRatio * titleRatio;
    const fontSizeH3 = titleFontSize * titleRatio * titleRatio * titleRatio;
    const fontSizeH4 = titleFontSize * titleRatio * titleRatio;
    const fontSizeH5 = titleFontSize * titleRatio;
    const init = {
      placeholder: placeholder,
      menubar: false,
      formats: {
        // Changes the default format for h1 to have a class of heading
        // h1: { block: 'h1', classes: 'h1' },
        // h2: { block: 'h2', classes: 'h2' },
        // h3: { block: 'h3', classes: 'h3' },
        // h4: { block: 'h4', classes: 'h4' },
        // h5: { block: 'h5', classes: 'h5' },
        // h6: { block: 'h6', classes: 'h6' },
      },
      block_formats:
        "Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6",
      preformatted: false,
      toolbar:
        "fullscreen | undo redo | formatselect | bold italic | bullist numlist | forecolor backcolor |  \
           alignleft aligncenter alignright alignjustify | removeformat ",
      lists_indent_on_tab: false,
      plugins: "fullscreen wordcount lists paste",
      branding: false,
      statusbar: false,
      body_class: "editor-class",
      height: "414px",
      content_style: `
      @import url('https://fonts.googleapis.com/css?family=${paragraphFontFamily}');
      @import url('https://fonts.googleapis.com/css?family=${titleFontFamily}');
      body {
        padding-top: 20px;
        font-family: ${paragraphFontFamily};
        line-height: ${paragraphLineHeight};
        font-size: ${paragraphFontSize}px; 
      }
      blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
        margin: 0;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      .h7 {
        line-height: ${titleLineHeight};
        margin: 1.25rem 0 0.5rem 0;
        font-family: ${titleFontFamily};
        color: unset;
        font-weight: unset;
      }

      h1 {
        font-size: ${fontSizeH1}px; 
      }

      h2 {
        font-size: ${fontSizeH2}px; 
      }
      h3 {
        font-size: ${fontSizeH3}px; 
      }
      h4 {
        font-size: ${fontSizeH4}px; 
      }
      h5 {
        font-size: ${fontSizeH5}px; 
      }
      h6 {
        font-size: ${titleFontSize}px;
      }
      .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
        `,
    };
    return init;
  }, [
    titleFontFamily,
    titleFontSize,
    titleLineHeight,
    titleRatio,
    paragraphFontFamily,
    paragraphFontSize,
    paragraphLineHeight,
  ]);

  return (
    <>
      <Editor
        id="Editor"
        tinymceScriptSrc="/static/tinymce/tinymce.min.js"
        value={value}
        init={{
          ...initFullProps,
        }}
        onInit={handleInit}
        onEditorChange={onUpdateValue}
      />
    </>
  );
};

export default EditorComp;
