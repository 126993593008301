import { BLOCK_TYPE } from "@features/block/block.conf";
import {
  CARD_ITEM_ON_ROW,
  CARD_LAYOUTS,
  CARD_STYLE,
  IMAGE_ORIENTATION_TYPE,
  SLIDE_STYLE,
} from "@share/configs/const";
import { InformationIcon } from "@share/icons";
import { Divider, Input, Selector, TextArea } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import CardLayouts from "./CardLayouts";
import CardSlideType from "./CardSlideType";
import CardStyle from "./CardStyle";
import ImageOrientation from "./ImageOrientation";
import ListItems from "./ListIems";

const BLOCK_HEADING_MAXIMUM = 50;
const BLOCK_DESCRIPTION_MAXIMUM = 250;
const SEE_MORE_BTN_MAXIMUM = 25;

const Card = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
    setShowFooterBlockEdit,
  } = props;

  const { t } = useTranslation();
  const UID = `cards-${uuidv4()}`;

  const CARD_TITLE_DEFAULT = t("ss_builder_title_block_card");
  const defaultValueEditCard =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const titleBlockDefault = CARD_TITLE_DEFAULT;

  const valueHeadingBlockDefault =
    defaultValueEditCard?.content_attributes?.block_heading || "";
  const valueDescriptionBlockDefault =
    defaultValueEditCard?.content_attributes?.block_description || "";
  const seeMoreBtnContentDefault =
    defaultValueEditCard?.content_attributes?.see_more_btn_content ||
    t("ss_builder_see_more_btn_default_title");
  const listCardDefault = defaultValueEditCard?.content_attributes?.items || [];

  const cardStyleDefault =
    defaultValueEditCard?.style_attributes?.card_style || CARD_STYLE.GRID;

  const cardSlideTypeDefault =
    defaultValueEditCard?.style_attributes?.card_slide_type ||
    SLIDE_STYLE.NOT_PARTIAL_VISIBLE;

  const cardLayoutDefault =
    defaultValueEditCard?.style_attributes?.card_layout ||
    CARD_LAYOUTS.LAYOUT_1;

  const [cardLayout, setCardLayout] = useState(cardLayoutDefault);

  const itemOnRowDefault = useMemo(() => {
    const item_on_row = defaultValueEditCard?.style_attributes?.item_on_row;
    if (
      cardLayout !== CARD_LAYOUTS.LAYOUT_1 &&
      cardLayout !== CARD_LAYOUTS.LAYOUT_2 &&
      cardLayout !== CARD_LAYOUTS.LAYOUT_3 &&
      cardLayout !== CARD_LAYOUTS.LAYOUT_4
    ) {
      return null;
    } else {
      if (item_on_row) {
        return item_on_row;
      } else return CARD_ITEM_ON_ROW.ONE;
    }
  }, [defaultValueEditCard, cardLayout]);

  const ratioDefault = defaultValueEditCard?.style_attributes?.ratio || "16/9";
  const imageOrientationDefault =
    defaultValueEditCard?.style_attributes?.image_orientation ||
    IMAGE_ORIENTATION_TYPE.LANDSCAPE;

  const [imageRatio, setImageRatio] = useState(ratioDefault);
  const [cardStyle, setCardStyle] = useState(cardStyleDefault);
  const [cardSlideType, setCardSlideType] = useState(cardSlideTypeDefault);

  const [itemOnRow, setItemOnRow] = useState(itemOnRowDefault);
  const [seeMoreBtnContent, setSeeMoreBtnContent] = useState(
    seeMoreBtnContentDefault
  );
  const [valueHeadingBlock, setValueHeadingBlock] = useState(
    valueHeadingBlockDefault
  );
  const [valueDescriptionBlock, setValueDescriptionBlock] = useState(
    valueDescriptionBlockDefault
  );
  const [imageOrientation, setImageOrientation] = useState(
    imageOrientationDefault
  );
  const [listCard, setListCard] = useState(listCardDefault);

  const listCardEnable = useMemo(() => {
    return listCard?.filter((item) => item.enable === 1);
  }, [listCard]);

  useEffect(() => {
    if (!block.uid) {
      const defaultCardData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.CARD,
        title: titleBlockDefault,
        content_attributes: {
          items: listCard,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          see_more_btn_content: seeMoreBtnContent,
        },
        style_attributes: {
          ratio: imageRatio,
          image_orientation: imageOrientation,
          item_on_row: itemOnRow,
          card_style: cardStyle,
          card_slide_type: cardSlideType,
          card_layout: cardLayout,
        },
      };
      onUpdateData(defaultCardData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          items: listCard,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          see_more_btn_content: seeMoreBtnContent,
        },
        style_attributes: {
          ratio: imageRatio,
          image_orientation: imageOrientation,
          item_on_row: itemOnRow,
          card_style: cardStyle,
          card_slide_type: cardSlideType,
          card_layout: cardLayout,
        },
      });
    }
  }, [
    imageOrientation,
    valueHeadingBlock,
    valueDescriptionBlock,
    imageRatio,
    seeMoreBtnContent,
    itemOnRow,
    cardStyle,
    cardSlideType,
    cardLayout,
    listCard,
  ]);

  useEffect(() => {
    if (
      cardLayout === CARD_LAYOUTS.LAYOUT_5 ||
      cardLayout === CARD_LAYOUTS.LAYOUT_6 ||
      cardLayout === CARD_LAYOUTS.LAYOUT_7 ||
      cardLayout === CARD_LAYOUTS.LAYOUT_8
    ) {
      setItemOnRow(null);
    } else {
      if (!itemOnRow) {
        setItemOnRow(CARD_ITEM_ON_ROW.ONE);
      }
    }
  }, [cardLayout, itemOnRow]);

  useEffect(() => {
    if (
      cardStyle === CARD_STYLE.SLIDE &&
      (cardLayout === CARD_LAYOUTS.LAYOUT_5 ||
        cardLayout === CARD_LAYOUTS.LAYOUT_6)
    ) {
      setCardLayout(CARD_LAYOUTS.LAYOUT_4);
    }
  }, [cardLayout, cardStyle]);

  const isDisableBtnSave = useMemo(() => {
    if (
      listCard?.length > 0 &&
      seeMoreBtnContent &&
      (JSON.stringify(listCard) !== JSON.stringify(listCardDefault) ||
        imageRatio !== ratioDefault ||
        valueHeadingBlock !== valueHeadingBlockDefault ||
        valueDescriptionBlock !== valueDescriptionBlockDefault ||
        imageOrientation !== imageOrientationDefault ||
        itemOnRow !== itemOnRowDefault ||
        cardStyle !== cardStyleDefault ||
        cardSlideType !== cardSlideTypeDefault ||
        cardLayout !== cardLayoutDefault ||
        seeMoreBtnContent !== seeMoreBtnContentDefault) &&
      listCardEnable.length > 0
    ) {
      return false;
    } else return true;
  }, [
    listCard,
    listCardDefault,
    imageRatio,
    ratioDefault,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    imageOrientation,
    imageOrientationDefault,
    itemOnRow,
    itemOnRowDefault,
    cardStyle,
    cardStyleDefault,
    cardSlideType,
    cardSlideTypeDefault,
    cardLayout,
    cardLayoutDefault,
    seeMoreBtnContent,
    seeMoreBtnContentDefault,
    listCardEnable,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      JSON.stringify(listCard) !== JSON.stringify(listCardDefault) ||
      imageRatio !== ratioDefault ||
      valueHeadingBlock !== valueHeadingBlockDefault ||
      valueDescriptionBlock !== valueDescriptionBlockDefault ||
      imageOrientation !== imageOrientationDefault ||
      itemOnRow !== itemOnRowDefault ||
      cardStyle !== cardStyleDefault ||
      cardSlideType !== cardSlideTypeDefault ||
      cardLayout !== cardLayoutDefault ||
      seeMoreBtnContent !== seeMoreBtnContentDefault
    ) {
      return true;
    } else return false;
  }, [
    listCard,
    listCardDefault,
    imageRatio,
    ratioDefault,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    imageOrientation,
    imageOrientationDefault,
    itemOnRow,
    itemOnRowDefault,
    cardStyle,
    cardStyleDefault,
    cardSlideType,
    cardSlideTypeDefault,
    cardLayout,
    cardLayoutDefault,
    seeMoreBtnContent,
    seeMoreBtnContentDefault,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  const itemOnRowOptions = [
    {
      label: "1",
      value: CARD_ITEM_ON_ROW.ONE,
    },
    {
      label: "2",
      value: CARD_ITEM_ON_ROW.TWO,
    },
  ];

  const handleChangeBlockHeading = (val) => {
    if (val?.length <= BLOCK_HEADING_MAXIMUM) {
      setValueHeadingBlock(val);
    }
  };

  const handleChangeBlockDescription = (val) => {
    if (val?.length <= BLOCK_DESCRIPTION_MAXIMUM) {
      setValueDescriptionBlock(val);
    }
  };

  const handleSelectItemOnRow = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      setItemOnRow(value);
    }
  };

  const handleChangeSeeMoreBtnContent = (value) => {
    if (value?.length <= SEE_MORE_BTN_MAXIMUM) {
      setSeeMoreBtnContent(value);
    }
  };

  const labelObject = {
    listTitle: t("ss_builder_list_label"),
    listEmptyLabel: t("ss_builder_list_card_empty_title"),
    listEmptyDescription: t("ss_builder_list_card_empty_content"),
    itemAdditionalBtnLabel: t("ss_builder_card_additional_btn_label"),
    deleteItemLabel: t("ss_builder_delete_card_label"),
    deleteItemSuccessfullyLabel: t("ss_builder_delete_card_successfully_label"),
    showItemLabel: t("ss_builder_show_card_label"),
    showItemSuccessfullyLabel: t("ss_builder_show_card_successfully_label"),
    hideItemLabel: t("ss_builder_hide_card_label"),
    hideItemSuccessfullyLabel: t("ss_builder_hide_card_successfully_label"),
    confirmDeleteCardModalLabel: t(
      "ss_builder_confirm_delete_card_modal_label"
    ),
    confirmDeleteCardModalDescription: t(
      "ss_builder_confirm_delete_card_modal_description"
    ),
    confirmDeleteCardModalBtnLabel: t(
      "ss_builder_confirm_delete_card_modal_btn_label"
    ),
    cancelDeleteCardModalBtnLabel: t(
      "ss_builder_cancel_delete_card_modal_btn_label"
    ),
  };
  const isCardStyleSlide = useMemo(
    () => cardStyle === CARD_STYLE.SLIDE,
    [cardStyle]
  );

  const isDisplayItemOnRow = useMemo(
    () =>
      cardLayout !== CARD_LAYOUTS.LAYOUT_5 &&
      cardLayout !== CARD_LAYOUTS.LAYOUT_6 &&
      cardLayout !== CARD_LAYOUTS.LAYOUT_7 &&
      cardLayout !== CARD_LAYOUTS.LAYOUT_8,
    [cardLayout]
  );

  return (
    <>
      <div className=" bg-white mt-3 px-4 py-[13px]">
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_heading_label")}
          </span>
          <Input
            placeholder={t("ss_builder_block_heading_placeholder")}
            onChange={handleChangeBlockHeading}
            value={valueHeadingBlock}
            maxLength={BLOCK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueHeadingBlock ? valueHeadingBlock.length : 0
          }/${BLOCK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </span>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            onChange={handleChangeBlockDescription}
            value={valueDescriptionBlock}
            maxLength={BLOCK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueDescriptionBlock ? valueDescriptionBlock.length : 0
          }/${BLOCK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
      </div>
      <div className=" bg-white mt-3 px-4 py-[13px]">
        <CardStyle
          cardStyleValue={cardStyle}
          onUpdateCardStyleValue={(val) => setCardStyle(val)}
        />
        <Divider />
        {cardStyle === CARD_STYLE.SLIDE && (
          <>
            <CardSlideType
              cardSlideValue={cardSlideType}
              onUpdateCardSlideValue={(val) => setCardSlideType(val)}
            />
            <Divider />
          </>
        )}
        <CardLayouts
          cardLayoutValue={cardLayout}
          onUpdateCardLayoutValue={(val) => setCardLayout(val)}
          isCardStyleSlide={isCardStyleSlide}
        />

        {isDisplayItemOnRow && (
          <>
            <Divider />
            <div>
              <span className="text-[15px] leading-[13px] text-[#666666]">
                {t("ss_builder_items_per_row")}
              </span>
              <Selector
                options={itemOnRowOptions}
                value={[itemOnRow]}
                onChange={handleSelectItemOnRow}
                columns={2}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </div>
          </>
        )}
        {(cardLayout === CARD_LAYOUTS.LAYOUT_5 ||
          cardLayout === CARD_LAYOUTS.LAYOUT_6) && (
          <div className="flex flex-row gap-1 items-center mt-4">
            <InformationIcon
              className="ml-1 w-[14px] h-[14px]"
              onClick={() => {}}
              fillColor="#1890FF"
            />
            <div>{t("ss_builder_card_layout_alert_1")}</div>
          </div>
        )}
        {(cardLayout === CARD_LAYOUTS.LAYOUT_7 ||
          cardLayout === CARD_LAYOUTS.LAYOUT_8) && (
          <div className="flex flex-row gap-1 items-center mt-4">
            <InformationIcon
              className="ml-1 w-[14px] h-[14px]"
              onClick={() => {}}
              fillColor="#1890FF"
            />
            <div>{t("ss_builder_card_layout_alert_2")}</div>
          </div>
        )}
        <Divider />
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_card_btn_content_label")}
          </span>
          <Input
            value={seeMoreBtnContent}
            placeholder={t(
              "ss_builder_affiliate_product_btn_see_more_placeholder"
            )}
            onChange={handleChangeSeeMoreBtnContent}
            maxLength={SEE_MORE_BTN_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            seeMoreBtnContent ? seeMoreBtnContent.length : 0
          }/${SEE_MORE_BTN_MAXIMUM}`}</div>
        </div>
        <Divider />
        <ImageOrientation
          imageOrientationDefault={imageOrientation}
          imageRatioDefault={imageRatio}
          onUpdateImageOrientationValue={(val) => setImageOrientation(val)}
          onUpdateImageRatioValue={(val) => setImageRatio(val)}
        />
      </div>
      <ListItems
        blockType={block?.block_type}
        listItemDataDefault={listCard}
        ITEM_MAXIMUM={20}
        onUpdateListDataItem={(val) => setListCard(val)}
        labelObject={labelObject}
        imageRatio={imageRatio}
        setShowFooterBlockEdit={setShowFooterBlockEdit}
      />
    </>
  );
};

export default Card;
