import React, { useEffect, useMemo, useState } from "react";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { selectProfile } from "@features/user/userSlice";
import { useAppTranslation } from "@features/mini-store/share/hook";
import { BACK_GROUND_IMAGE, MAX_LENGTH_INPUT_BUTTON, MAX_LENGTH_INPUT_TITLE,  NAME_SERVICE,} from "@features/mini-store/setting/constants";
import { Form, Input, TabBar } from "antd-mobile";
import style from "./style.module.scss";
import { getDetailBrandGift } from "@features/mini-store/share/api";
import { useQueries, useQuery } from "react-query";
import { htmlDecode } from "@share/lib";
import BrandGiftListCate from "./Component/List";
import { useAppSelector } from "@app/hooks";
import brandGiftApi from "./brandGiftApi";

export default function BrandGift(props) {
  const { onUpdateData, block, setButtonStatus, setEditStatus } = props;
  const profile = useSelector(selectProfile);
  const { t } = useAppTranslation();

  const [inputStateTitle, setInputStateTitle] = useState("");
  const [inputStateButton, setInputStateButton] = useState("");
  const [inputTitle, setInputTitle] = useState("");
  const [inputButton, setInputButton] = useState("");

  const [brandGiftArr, setBrandGiftArr] = useState([]);

  const tabs = [
    {
      key: "cashback",
      title: t("ss_brand_gift_setting_text_cashback_brand_gift"),
    },
    {
      key: "voucher",
      title: t("ss_brand_gift_setting_text_voucher_brand_gift"),
    },
    {
      key: "custom",
      title: t("ss_brand_gift_setting_text_custom_brand_gift"),
    },
  ];
  
  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const defaultValueEditBrandGift = edittingBlockData && edittingBlockData.uid ? edittingBlockData : null;

  const brandGiftDefault = useMemo(() => {
    const contentAttributes = {
      key: activeKey,
      title: inputTitle.trim(),
      button: inputButton.trim(),
      country: profile.country,
    }
    return {
      uid: `brand-gift-${uuidv4()}`,
      sort_order: 1,
      enable: 1,
      block_type: BLOCK_TYPE.BRAND_GIFT,
      title: "Brand Gift",
      content_attributes: {
        ...contentAttributes,
        cate_items: [],
      },
      style_attributes: {},
    };
  }, []);

  const [activeKey, setActiveKey] = useState(null);
  const loadDetailBrand = async () => {
    try {
      return await getDetailBrandGift(NAME_SERVICE.BRAND_GIFT);
    } catch (e) { return {} }
  };
  const loadCategory = async () => {
    try {
      const res = await brandGiftApi.listCategory({country: profile.country});
      setBrandGiftArr(res.data)
    } catch (e) { return {} }
  }
  const [{ isFetched, data: data }] = useQueries(
    [
      {queryKey: "loadDetailBrand",
      queryFn: loadDetailBrand,
      cacheTime: 0,
      refetchOnWindowFocus: false},
      {queryKey: "loadCategory",
      queryFn: loadCategory,
      cacheTime: 0,
      refetchOnWindowFocus: false},
    ]
  );

  useEffect(() => {
    if (!block?.uid) {
      onUpdateData(brandGiftDefault);
    } else {
      const contentAttributes = {
        key: activeKey,
        title: inputTitle.trim(),
        button: inputButton.trim(),
        country: profile.country,
      }
      onUpdateData({
        ...block,
        title: "Brand Gift",
        content_attributes: {
          ...contentAttributes,
          cate_items: brandGiftArr,
          hasInit: true,
        },
        style_attributes: {},
      });
    }
  }, [activeKey, inputTitle, inputButton, brandGiftArr]);

  useEffect(() => {
    if(isFetched) {
      if (data?.template_key) {
        onChangeTabBar(htmlDecode(data?.template_key));
        setInputTitle(htmlDecode(data?.block_title));
        setInputButton(htmlDecode(data?.button_text));
        setInputStateTitle(htmlDecode(data?.block_title));
        setInputStateButton(htmlDecode(data?.button_text));
      } else onChangeTabBar("cashback");
    }
  }, [data, isFetched]);

  useEffect(() => {
    if (inputTitle.trim().length <= 0 || inputButton.trim().length <= 0) setButtonStatus(true);
    else setButtonStatus(false);
  }, [inputTitle, inputButton]);

  const onChangeTabBar = (e) => {
    setActiveKey(e);
    switch (e) {
      case "cashback":
        setInputTitle(t("ss_brand_gift_setting_cashback_default_title"));
        setInputButton(t("ss_brand_gift_setting_cashback_default_text_button"));
        break;
      case "voucher":
        setInputTitle(t("ss_brand_gift_setting_voucher_default_title"));
        setInputButton(t("ss_brand_gift_setting_voucher_default_text_button"));
        break;
      case "custom":
        setInputTitle(inputStateTitle);
        setInputButton(inputStateButton);
        break;
    }
  };

  const onChangeTitle = (e) => {
    if (e.length > MAX_LENGTH_INPUT_TITLE) return
    setInputTitle(e);
    setInputStateTitle(e);
  };
  const onChangeButton = (e) => {
    if (e.length > MAX_LENGTH_INPUT_BUTTON) return;
    setInputButton(e);
    setInputStateButton(e);
  };

  return (
    <>
      <div className={"bg-white mt-4 py-3 text-center"}>
        <img className={""} alt="" src={BACK_GROUND_IMAGE.BRAND_GIFT}/>
        <div className={"text-lg"}>{t("default_title_setup_brand_gift")}</div>
        <h6 className={"font-normal"}>{t("ss_brand_gift_setting_default_content_setup")}</h6>
        <div className={style.tabContent}>
          <div className={"text-left text-[15px] mb-1 text-[#666666]"}>
            {t("ss_brand_gift_setting_label_tab_bar_brand_gift")}
          </div>
          <TabBar activeKey={activeKey}  onChange={(e) => onChangeTabBar(e)}>
            {tabs.map((tab) => (<TabBar.Item key={tab.key} title={tab.title}/>))}
          </TabBar>
        </div>
      </div>
      <Form className={"bg-white mt-3 flex flex-col gap-5"}>
        <Form.Item
          name={"title"}
          label={t("ss_brand_gift_setting_label_text_title_brand_gift")}
          rules={[{ required: activeKey == "custom" }]}
        >
          <Input
            disabled={activeKey != "custom"}
            value={htmlDecode(inputTitle)}
            onChange={(e) => onChangeTitle(e)}
            maxLength={MAX_LENGTH_INPUT_TITLE}
            placeholder={t("ss_brand_gift_setting_placeholder_text_title")}
            clearable
          />
          {activeKey == "custom" && (
            <div
              className={`text-right text-[13px] text-[#999999] ${
                inputTitle?.length > MAX_LENGTH_INPUT_TITLE && "text-"
              }`}
            >
              {inputTitle?.length}/{MAX_LENGTH_INPUT_TITLE}
            </div>
          )}
        </Form.Item>
        <Form.Item
          name={"text-button"}
          label={t("ss_brand_gift_setting_label_text_button")}
          rules={[{ required: activeKey == "custom" }]}
        >
          <Input
            disabled={activeKey != "custom"}
            value={htmlDecode(inputButton)}
            onChange={(e) => onChangeButton(e)}
            maxLength={MAX_LENGTH_INPUT_BUTTON}
            placeholder={t("ss_brand_gift_setting_placeholder_text_button")}
            clearable
          />
          {activeKey == "custom" && (
            <div className={"text-right text-[13px] text-[#999999]"}>
              {inputButton?.length}/{MAX_LENGTH_INPUT_BUTTON}
            </div>
          )}
        </Form.Item>
      </Form>
      <BrandGiftListCate 
        block={block}
        onUpdateData={onUpdateData}
        brandGiftArr={brandGiftArr}
        setBrandGiftArr={setBrandGiftArr}
      />
    </>
  );
}
