import { useAppDispatch } from "@app/hooks";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import InputCustom from "@share/components/InputCustom";
import { Checkbox } from "antd-mobile";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface ContentModalProps {
  onChange: (e) => void;
  errorMsg?: string;
  value: string;
  onCheckExtraCommission: (val) => void;
  extraCommissionCheck: boolean;
}

export default function ContentModal(props: ContentModalProps) {
  const {
    onChange,
    errorMsg,
    value,
    onCheckExtraCommission,
    extraCommissionCheck = true,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="text-[15px] font-normal leading-[140%] text-[#666666] mb-1">
        {t("ss_builder_import_site_url_modal_description")}
      </div>
      <InputCustom
        onChange={onChange}
        placeholder={t("ss_builder_enter_url_label")}
        error={errorMsg}
        value={value}
      />
      {value && !errorMsg && (
        <Checkbox
          checked={extraCommissionCheck}
          onChange={onCheckExtraCommission}
          style={{
            "--icon-size": "20px",
            "--font-size": "15px",
          }}
          className="mt-1"
        >
          <div>{t("ss_builder_earn_additional_commissions_label")}</div>
        </Checkbox>
      )}
    </>
  );
}
