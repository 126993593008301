import { put, select, takeEvery } from "redux-saga/effects";
import { ActionInterface } from "@app/store";
import { disableEnablePosition } from "../positionsSlice";
import { Position } from "../interface";
import { generateSavingProcessId } from "./saving-item";
import { pushSavingItem } from "../savingItemSlice";
import { saveAttributes } from "@features/main/api/position";

function* positionSaga() {
  yield takeEvery(
    disableEnablePosition.type,
    function* (action: ActionInterface) {
      const { payload } = action;
      const { positionSlug, enableValue } = payload;
      const positions = (yield select(
        (state) => state.positions
      )) as Position[];
      const position = positions.find((item) => item.slug == positionSlug);
      const savingProcessId = generateSavingProcessId("change-position-status");
      yield put(
        pushSavingItem({
          item: {
            id: savingProcessId,
            executer: saveAttributes,
            getArguments: (baseData) => {
              return [position.id, baseData, { enable: enableValue }];
            },
          },
        })
      );
    }
  );
}

export default positionSaga;
