import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "intro.js/introjs.css";
import ProductTourComponent from "@share/components/ProductTourComponent";
import { steps, STEP_INDEX } from "../affiliate-add-new-tour-config";
import { placeHolderImageIcon } from "@share/icons/product-tour-icon";

const ProductAddNewTour = ({ isHideStep3, handleHideAddNewTour }) => {
  const { t } = useTranslation();
  const productAddNewTourRef = useRef(null);

  const setRef = (ref) => {
    productAddNewTourRef.current = ref;
    const fullscreenContentElement = document.getElementsByClassName(
      "full-screen-popup-content"
    );
    if (productAddNewTourRef.current) {
      productAddNewTourRef.current.introJs._targetElement =
        fullscreenContentElement[fullscreenContentElement?.length - 1];
    }
  };

  const stepsDefault = steps.map((ele) => ({
    title:
      `<div class="wrapper-image">` +
      placeHolderImageIcon +
      `
        <img class="img-product-tour" src=${ele.image_header} /> </div>
        <div class="title">${t(ele.title_key)}<div/>`,
    element: ele.using_selector
      ? document.querySelector(`.${ele.element_class}`)
      : `.${ele.element_class}`,
    intro: `<div> ${t(ele.intro_key)}<div/>`,
    tooltipClass: `tooltip-product-tour ${ele.tooltip_class}`,
    position: ele?.position || "auto",
  }));

  const onExit = () => {
    handleHideAddNewTour();
  };
  const onBeforeExit = (stepIndex) => {};

  const onChangeProductTour = (nextStepIndex, nextElement) => {
    const fullScreenPopupContent = document.querySelectorAll(
      ".full-screen-popup-content"
    ) as any;

    const popupConentScrollHeight =
      fullScreenPopupContent[fullScreenPopupContent?.length - 1].scrollTop;

    const tooltipElement = document.querySelectorAll(
      ".introjs-fixedTooltip"
    ) as any;

    if (tooltipElement) {
      if (
        (isHideStep3 && nextStepIndex !== STEP_INDEX.STEP_3) ||
        (!isHideStep3 && nextStepIndex !== STEP_INDEX.STEP_4)
      ) {
        for (let i = 0; i < tooltipElement?.length; i++) {
          tooltipElement[i].style.cssText = `top:${
            nextElement.getBoundingClientRect().top +
            popupConentScrollHeight -
            5
          }px !important`;
          tooltipElement[i].style.position = "absolute";
          tooltipElement[i].style.background = "#FFFFFF";
        }
      } else {
        for (let i = 0; i < tooltipElement?.length; i++) {
          tooltipElement[i].style.cssText = "";
          tooltipElement[i].style.position = "";
          tooltipElement[i].style.background = "";
        }
      }
    }
  };

  const onCompleteProductTour = () => {
    handleHideAddNewTour();
  };

  const onBeforeChange = (nextStepIndex, nextElement) => {
    // if (nextStepIndex === STEP_INDEX.STEP_2) {
    //   nextElement.scrollIntoView();
    // }
    // if (nextStepIndex === STEP_INDEX.STEP_3) {
    //   nextElement.scrollIntoView();
    // }
  };

  const stepsFilter = isHideStep3
    ? stepsDefault?.filter((ele, idx) => idx !== STEP_INDEX.STEP_3)
    : stepsDefault;

  return (
    <ProductTourComponent
      steps={stepsFilter}
      onExit={onExit}
      onBeforeExit={onBeforeExit}
      onChange={onChangeProductTour}
      onComplete={onCompleteProductTour}
      onBeforeChange={onBeforeChange}
      setRef={setRef}
    />
  );
};

export default ProductAddNewTour;
