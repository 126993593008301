import { Selector } from "antd-mobile";
import React, { useEffect } from "react";
import DndScrollable from "../dnd-scrollable";
import cls from "classnames";

const AutoScrollSelector = (props) => {
  const {
    options,
    value,
    onChange,
    elementId,
    isMulti,
    className = null,
    style,
    isMultiple = false,
  } = props;
  const valueSelector = Array.isArray(value) ? value : [value];

  useEffect(() => {
    const timer = isMulti
      ? setTimeout(() => {
          const element = document.querySelectorAll(
            `.adm-selector-item-active`
          );
          if (element) {
            element[0].scrollIntoView({
              block: "nearest",
              inline: "center",
              behavior: "smooth",
            });
          }
        }, 200)
      : null;

    const timer1 = setTimeout(() => {
      const element = document.querySelector(
        `#${elementId} .adm-selector-item-active`
      );
      if (element) {
        element.scrollIntoView({
          block: "nearest",
          inline: "center",
          behavior: "smooth",
        });
      }
    }, 500);
    return () => {
      isMulti ? clearTimeout(timer) : null;
      clearTimeout(timer1);
    };
  }, []);

  useEffect(() => {
    const childElementId = elementId + "-" + value;
    const element = document.getElementById(childElementId);
    if (element) {
      element.scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
      });
    }
  }, [value]);

  return (
    <div id={elementId}>
      <DndScrollable className={cls(`selector-scroll`, className)}>
        <Selector
          options={options}
          value={valueSelector}
          onChange={onChange}
          multiple={isMultiple}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
            "--checked-color": "#fff",
            ...style,
          }}
        />
      </DndScrollable>
    </div>
  );
};
export default AutoScrollSelector;
