import { SampleSelectionIcon } from "@share/icons";
import { Button, Divider, Image, Selector, Switch } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import {
  ENABLE_BLOCK,
  TAB_DISPLAY,
  TAB_GHIM_STYLE,
  TAB_LAYOUT,
  TAB_POSITION,
  TAB_SETTING,
  TAB_TEMPLATE,
  TAB_TEMPLATE_LIST,
  TAB_WIDTH_DISPLAY,
} from "@share/configs/const";
import TabManagement from "./TabManagement";
import TabLayouts from "./TabLayouts";
import TabWidthDisplay from "./TabWidthDisplay";
import FullScreenPopup from "@share/components/full-screen-popup";
import TabTemplateSelection from "./TabTemplateSelection";
import { ShadowIcon } from "@features/setting/components/ThemeSetting/BlockSetting/components/Icons";
import DisplayColor from "@share/components/DisplayColor";
import { ColorPickerV2 } from "@features/setting/components/ThemeSetting/ColorPicker";
import PopupComponent from "@share/components/PopupComponent";

const TabMenu = (props) => {
  const { block, onUpdateData, setButtonStatus, setEditStatus } = props;

  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const positionState = useAppSelector((state) => state.positions);
  const positionStateBody = positionState.find((x) => x.slug === "body");
  const tabListBody =
    positionStateBody &&
    positionStateBody.block_list &&
    positionStateBody.block_list[0] &&
    positionStateBody.block_list[0].content_attributes &&
    positionStateBody.block_list[0].content_attributes.tabs;

  const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const tabUid = edittingBlock && edittingBlock.tab_uid;
  const positionSlug = edittingBlock && edittingBlock.position_uid;
  const defaultValueTabMenu =
    edittingBlockData && edittingBlockData.uid ? edittingBlockData : null;

  const titleBlockDefault = defaultValueTabMenu?.title || "Tab Menu";

  const styleTabMenuDisplayDefault =
    defaultValueTabMenu?.style_attributes?.display_style ||
    TAB_DISPLAY.TEXT_AND_ICON;

  const styleGhimTabMenuDefault =
    defaultValueTabMenu?.style_attributes?.ghim_style ||
    TAB_GHIM_STYLE.NOT_GHIM;

  const positionTabMenuDefault =
    defaultValueTabMenu?.style_attributes?.position_style || TAB_POSITION.TOP;

  const tabLayoutDefault =
    defaultValueTabMenu?.style_attributes?.tab_layout || TAB_LAYOUT.TEXT_RIGHT;

  const tabWidthDisplayDefault =
    defaultValueTabMenu?.style_attributes?.tab_width_display ||
    TAB_WIDTH_DISPLAY.AUTO;

  const tabTemplateDefault =
    defaultValueTabMenu?.style_attributes?.tab_template ||
    TAB_TEMPLATE.TEMPLATE_1;

  const tabShadowEnableDefault =
    defaultValueTabMenu?.style_attributes?.tab_shadow_enable ||
    TAB_SETTING.SHADOW_ENABLE;

  const tabShadowColorDefault =
    defaultValueTabMenu?.style_attributes?.tab_shadow_color ||
    TAB_SETTING.SHADOW_COLOR;

  const listTabsDataDefault = tabListBody;

  const [valueTitleInput, setValueTilteInput] = useState(titleBlockDefault);
  const [valueInput, setValueInput] = useState(titleBlockDefault);
  const [styleTabMenuDisplay, setStyleTabMenuDisplay] = useState(
    styleTabMenuDisplayDefault
  );
  const [styleGhimTabMenu, setStyleGhimTabMenu] = useState(
    styleGhimTabMenuDefault
  );
  const [positionTabMenu, setPositionTabMenu] = useState(
    positionTabMenuDefault
  );
  const [isEnable, setIsEnable] = useState(block?.enable ?? ENABLE_BLOCK);
  const [listTabsData, setListTabsData] = useState(listTabsDataDefault);
  const [tabLayout, setTabLayout] = useState(tabLayoutDefault);
  const [tabWidthDisplay, setTabWidthDisplay] = useState(
    tabWidthDisplayDefault
  );
  const [tabTemplate, setTabTemplate] = useState(tabTemplateDefault);
  const [tabShadowEnable, setTabShadowEnable] = useState(
    tabShadowEnableDefault
  );
  const [tabShadowColor, setTabShadowColor] = useState(tabShadowColorDefault);
  const [isOpenTemplateSelection, setIsOpenTemplateSelection] = useState(false);

  const [isOpenShadowColorPicker, setIsOpenShadowColorPicker] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const styleTabMenuOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/tab-template/phong-cach-chu-va-icon.svg"
          className="m-auto"
          width={77}
          height={77}
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_style_tab_text_and_icon_label")}
        </div>
      ),
      value: TAB_DISPLAY.TEXT_AND_ICON,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/tab-template/phong-cach-chi-chu.svg"
          className="m-auto"
          width={77}
          height={77}
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_style_tab_text_label")}</div>
      ),
      value: TAB_DISPLAY.TEXT,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/tab-template/phong-cach-chi-icon.svg"
          className="m-auto"
          width={77}
          height={77}
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_style_tab_icon_label")}</div>
      ),
      value: TAB_DISPLAY.ICON,
    },
  ];

  const styleGhimTabMenuOptions = [
    {
      label: t("ss_builder_style_not_ghim_tab_label"),
      value: TAB_GHIM_STYLE.NOT_GHIM,
    },
    {
      label: t("ss_builder_style_always_ghim_tab_label"),
      value: TAB_GHIM_STYLE.ALWAYS_GHIM,
    },
    // {
    //   label: t("ss_builder_style_auto_ghim_tab_label"),
    //   value: TAB_GHIM_STYLE.AUTO_GHIM,
    // },
  ];

  const positionTabMenuOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/tab-template/position-top.svg"
          className="m-auto"
          width={77}
          height={77}
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_top_position_tab_label")}</div>
      ),
      value: TAB_POSITION.TOP,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/tab-template/position-bottom.svg"
          className="m-auto"
          width={77}
          height={77}
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_bottom_position_tab_label")}</div>
      ),
      value: TAB_POSITION.BOTTOM,
    },
  ];

  useEffect(() => {
    onUpdateData({
      ...block,
      title: valueTitleInput,
      content_attributes: {
        items: listTabsData,
      },
      style_attributes: {
        display_style: styleTabMenuDisplay,
        ghim_style: styleGhimTabMenu,
        position_style: positionTabMenu,
        tab_layout: tabLayout,
        tab_width_display: tabWidthDisplay,
        tab_template: tabTemplate,
        tab_shadow_enable: tabShadowEnable,
        tab_shadow_color: tabShadowColor,
      },
    });
  }, [
    valueTitleInput,
    styleTabMenuDisplay,
    styleGhimTabMenu,
    positionTabMenu,
    listTabsData,
    tabLayout,
    tabWidthDisplay,
    tabTemplate,
    tabShadowEnable,
    tabShadowColor,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      JSON.stringify(listTabsData) !== JSON.stringify(listTabsDataDefault) ||
      valueInput !== titleBlockDefault ||
      styleTabMenuDisplay !== styleTabMenuDisplayDefault ||
      styleGhimTabMenu !== styleGhimTabMenuDefault ||
      tabLayout !== tabLayoutDefault ||
      tabWidthDisplay !== tabWidthDisplayDefault ||
      tabTemplate !== tabTemplateDefault ||
      tabShadowEnable !== tabShadowEnableDefault ||
      tabShadowColor !== tabShadowColorDefault ||
      (positionTabMenu && positionTabMenu !== positionTabMenuDefault)
    ) {
      return true;
    } else return false;
  }, [
    valueInput,
    titleBlockDefault,
    styleTabMenuDisplay,
    styleTabMenuDisplayDefault,
    styleGhimTabMenu,
    styleGhimTabMenuDefault,
    positionTabMenu,
    positionTabMenuDefault,
    listTabsData,
    listTabsDataDefault,
    tabLayout,
    tabLayoutDefault,
    tabWidthDisplay,
    tabWidthDisplayDefault,
    tabTemplate,
    tabTemplateDefault,
    tabShadowEnable,
    tabShadowEnableDefault,
    tabShadowColor,
    tabShadowColorDefault,
  ]);

  useEffect(() => {
    const isDisableBtnSave = !isEditDataStatus;
    setButtonStatus(isDisableBtnSave);
  }, [isEditDataStatus]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  const handleSelectStyleTabMenuDisplay = (arr) => {
    if (arr && arr.length) {
      const valueStyle = arr[0];
      setStyleTabMenuDisplay(valueStyle);
      if (valueStyle !== TAB_DISPLAY.TEXT_AND_ICON) {
        setTabLayout(null);
      } else {
        setTabLayout(tabLayoutDefault);
      }
    }
  };

  const handleSelectStyleGhimTabMenu = (arr) => {
    if (arr && arr.length) {
      const valueGhimStyle = arr[0];
      setStyleGhimTabMenu(valueGhimStyle);
      if (valueGhimStyle && valueGhimStyle !== TAB_GHIM_STYLE.ALWAYS_GHIM) {
        setPositionTabMenu(TAB_POSITION.TOP);
      }
    }
  };

  const handleSelectPositionTabMenu = (arr) => {
    if (arr && arr.length) {
      const valuePosition = arr[0];
      if (styleGhimTabMenu && styleGhimTabMenu === TAB_GHIM_STYLE.ALWAYS_GHIM) {
        setPositionTabMenu(valuePosition);
      } else setPositionTabMenu(null);
    }
  };

  const onChangeShadowColor = (color) => {
    setTabShadowColor(color);
  };
  const onUpdateShadowData = (value) => {
    setTabShadowEnable(value);
  };

  return (
    <>
      <TabManagement
        onUpdateListTabsData={(data) => setListTabsData(data)}
        listTabsDataDefault={listTabsData}
      />
      <div className="p-4 bg-white mb-3">
        <div className="text-[15px] text-[#666666] zzzz">
          {t("ss_builder_sample_selection_label")}
        </div>
        <Image
          src={TAB_TEMPLATE_LIST?.find((x) => x.value === tabTemplate)?.image}
        />
        <Button
          block
          color="default"
          size="middle"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "12px",
            gap: "5px",
            borderColor: "var(--adm-color-primary)",
            color: "var(--adm-color-primary)",
          }}
          onClick={() => setIsOpenTemplateSelection(true)}
        >
          <SampleSelectionIcon
            className="w-[17px] h-[17px]"
            fillColor="var(--adm-color-primary)"
          />
          <div>{t("ss_builder_sample_change_label")}</div>
        </Button>
      </div>
      <div className="p-4 bg-white">
        <div>
          <div className="text-[15px] text-[#666666] mb-1">
            {t("ss_builder_style_tab_selection_label")}
          </div>
          <Selector
            options={styleTabMenuOptions}
            value={[styleTabMenuDisplay]}
            onChange={handleSelectStyleTabMenuDisplay}
            columns={3}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
              "--checked-color": "#fff",
            }}
          />
        </div>
        {styleTabMenuDisplay === TAB_DISPLAY.TEXT_AND_ICON && (
          <>
            <Divider />
            <TabLayouts
              tabLayoutValue={tabLayout}
              onUpdateTabLayoutValue={(val) => setTabLayout(val)}
            />
          </>
        )}
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666] mb-1">
            {t("ss_builder_style_ghim_tab_selection_label")}
          </div>
          <Selector
            options={styleGhimTabMenuOptions}
            value={[styleGhimTabMenu]}
            onChange={handleSelectStyleGhimTabMenu}
            columns={2}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
              "--checked-color": "#fff",
            }}
          />
        </div>
        {styleGhimTabMenu === TAB_GHIM_STYLE.ALWAYS_GHIM && (
          <>
            <Divider />
            <div>
              <div className="mb-1">
                {t("ss_builder_position_ghim_tab_selection_label")}
              </div>
              <Selector
                options={positionTabMenuOptions}
                value={[positionTabMenu]}
                onChange={handleSelectPositionTabMenu}
                columns={3}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                  "--checked-color": "#fff",
                }}
              />
            </div>
          </>
        )}
        <Divider />
        <TabWidthDisplay
          tabWidthDisplayValue={tabWidthDisplay}
          onUpdateTabWidthDisplayValue={(val) => setTabWidthDisplay(val)}
        />
      </div>
      {styleGhimTabMenu !== TAB_GHIM_STYLE.ALWAYS_GHIM && (
        <div className="mt-4">
          <div className="px-4 mb-2 flex">
            <span className="mr-1">
              <ShadowIcon className={""} />
            </span>
            <span className="text-[17px] leading-[22px]">
              {t("ss_builder_theme_setting_block_shadow")}
            </span>
          </div>
          <div className="p-4 bg-white">
            <div className="text-base mb-4">
              {t("ss_builder_enable_shadow")}
            </div>
            <Switch
              defaultChecked={tabShadowEnable ?? false}
              uncheckedText={t("ss_builder_off")}
              checkedText={t("ss_builder_on")}
              onChange={(value) => onUpdateShadowData(value)}
            />
            {tabShadowEnable && (
              <>
                <Divider />
                <div className="mb-3">
                  <div className="text-base mb-4">
                    {t("ss_builder_block_shadow_color")}
                  </div>
                  <DisplayColor
                    backgroundColor={tabShadowColor}
                    onClickOpenColorPicker={setIsOpenShadowColorPicker}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <FullScreenPopup visible={isOpenTemplateSelection}>
        <TabTemplateSelection
          tabTemplateDefault={tabTemplate}
          onUpdateTabTemplate={(val) => setTabTemplate(val)}
          onCancelTabTemplate={() => setIsOpenTemplateSelection(false)}
        />
      </FullScreenPopup>
      <PopupComponent
        visible={isOpenShadowColorPicker}
        onMaskClick={() => {}}
      >
        <ColorPickerV2
          defaultColor={tabShadowColor}
          onCancel={() => {
            setIsOpenShadowColorPicker(false);
          }}
          onColorSelected={onChangeShadowColor}
        />
      </PopupComponent>
    </>
  );
};

export default TabMenu;
