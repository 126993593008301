import { BLOCK_TYPE } from "@features/block/block.conf";
import { CheckCircleIcon, InformationIcon } from "@share/icons";
import { Modal, Toast } from "antd-mobile";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import AffiliateProductGroupItems from "../AffiliateProductGroupItems";
import cls from "classnames";

const AffiliateProductGroupListItems = (props) => {
  const {
    affiliateProductGroupList,
    onUpdateAffiliateProductGroupList,
    onEditAffiliateProductGroup,
    onActiveProductGroup,
  } = props;

  const { t } = useTranslation();

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(affiliateProductGroupList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedProductList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedProductList;
  };

  const onDragEnd = (result: DropResult): void => {
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    onUpdateAffiliateProductGroupList(itemsOrder);
  };

  const onRemoveItem = (index: number) => {
    const affiliateProductClone = cloneDeep(affiliateProductGroupList);
    const newProductItems =
      affiliateProductClone &&
      Array.isArray(affiliateProductClone) &&
      affiliateProductClone.filter((ele: any, idx: number) => idx !== index);
    onUpdateAffiliateProductGroupList(newProductItems);
  };

  const onDisableEnableProduct = (index: number, productStatus: boolean) => {
    const affiliateProductClone = cloneDeep(affiliateProductGroupList);
    const affiliateProductGroupListEnable = affiliateProductClone?.filter(
      (x) => x.enable === 1
    );
    const newProductItems =
      affiliateProductClone &&
      Array.isArray(affiliateProductClone) &&
      affiliateProductClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              enable: productStatus,
            }
          : ele;
      });
    if (affiliateProductClone[index]?.enable !== 1) {
      onUpdateAffiliateProductGroupList(newProductItems);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[30px] h-[30px]"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_enable_product_group_successfully_label")}
          </div>
        ),
      });
    } else {
      if (affiliateProductGroupListEnable.length >= 2) {
        Modal.confirm({
          header: (
            <InformationIcon
              className="w-11 h-11"
              fillColor="var(--adm-color-primary)"
              onClick={() => {}}
            />
          ),
          title: (
            <div>{t("ss_builder_confirm_hide_product_group_modal_title")}</div>
          ),
          content: (
            <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
              {t("ss_builder_confirm_hide_product_group_modal_description", {
                group_name: affiliateProductClone[index]?.title,
              })}
            </div>
          ),
          confirmText: t("ss_builder_confirm_hide_product_label"),
          cancelText: t("ss_builder_cancel_hide_product_label"),
          onConfirm: () => {
            onUpdateAffiliateProductGroupList(newProductItems);
            Toast.show({
              icon: (
                <CheckCircleIcon
                  className="text-center inline-block w-[30px] h-[30px]"
                  fillColor="#00B578"
                />
              ),
              content: (
                <div className="text-center">
                  {t("ss_builder_disable_product_group_successfully_label")}
                </div>
              ),
            });
          },
          onCancel: () => {
            return;
          },
        });
      } else {
        Modal.show({
          header: (
            <InformationIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          ),
          content: (
            <div className="flex flex-col text-center items-center">
              <div className="text-lg font-bold leading-[25px]">
                {t("ss_builder_hide_product_group_error_title")}
              </div>
              <div className="">
                {t("ss_builder_hide_product_group_error_content", {
                  group_name: affiliateProductClone[index]?.title,
                })}
              </div>
            </div>
          ),
          className: "modal-error-class",
          closeOnAction: true,
          actions: [
            {
              key: "confirm",
              text: t("ss_builder_hide_product_group_error_btn_confirm_label"),
            },
          ],
        });
      }
    }
  };

  return (
    <div className="px-3">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`blocks-${BLOCK_TYPE.AFF_PRODUCT_GROUP}`}>
          {(provided, snapshot): JSX.Element => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {affiliateProductGroupList &&
                affiliateProductGroupList.length > 0 &&
                affiliateProductGroupList.map((ele, index) => (
                  <Draggable
                    draggableId={`blocks-${ele.uid}`}
                    index={index}
                    key={`blocks-${ele.uid}`}
                  >
                    {(provided, snapshot): JSX.Element => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={cls(
                          index !== 0 && "mt-2",
                          snapshot.isDragging && "adm-item-custom "
                        )}
                      >
                        <AffiliateProductGroupItems
                          affiliateProductGroupList={affiliateProductGroupList}
                          index={index}
                          productData={ele}
                          onRemoveItem={onRemoveItem}
                          onDisableEnableProduct={onDisableEnableProduct}
                          onEditAffiliateProductGroup={
                            onEditAffiliateProductGroup
                          }
                          onActiveProductGroup={onActiveProductGroup}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default AffiliateProductGroupListItems;
