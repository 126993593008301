import {
  AT_URL_REGEX,
  LINKTREE_URL_REGEX,
  MSHAKE_URL_REGEX,
  SHOPEE_AFF_URL_REGEX,
} from "../constants";

export function addParamsToUrl(requestUrl, params) {
  const url = new URL(requestUrl);
  const searchParams = new URLSearchParams();

  // Loop through the params object and append each key-value pair to the searchParams
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      searchParams.append(key, value);
    }
  }

  // Set the searchParams as the query string of the URL
  url.search = searchParams.toString();

  return url.toString();
}

export const validateATURL = (value: string) => !AT_URL_REGEX.test(value);

export const validateShopeeAffURL = (value: string) =>
  !SHOPEE_AFF_URL_REGEX.test(value);

export const validateMshakeURL = (value: string) =>
  !MSHAKE_URL_REGEX.test(value);

export const validateLinkTreeURL = (value: string) =>
  !LINKTREE_URL_REGEX.test(value);
