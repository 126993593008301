import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BehanceIcon,
  DiscordIcon,
  DribbleIcon,
  EmailIcon,
  FacebookIcon,
  GeneralLinkIcon,
  InstagramIcon,
  Lemon8Icon,
  LineChatIcon,
  LinkedInIcon,
  MessengerIcon,
  PinterestIcon,
  SnapchatIcon,
  TelIcon,
  TiktokIcon,
  TwitchIcon,
  TwitterIcon,
  WhatsappIcon,
  YoutubeIcon,
} from "@share/icons/social-network-icon-svg";
import { Divider, Selector } from "antd-mobile";
import { CloseIcon } from "@share/icons";
import { MAX_WIDTH_CONTENT_LAYOUT, SOCIAL_LINK } from "@share/configs/const";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";

type Props = {
  callbackConfirmSelectedSocialLinkIcon: any;
  callbackCancelSelectedSocialLinkIcon: any;
  valueIconDefault: string;
};

const SocialLinkSelectedIcon = (props: Props) => {
  const {
    callbackCancelSelectedSocialLinkIcon,
    callbackConfirmSelectedSocialLinkIcon,
    valueIconDefault,
  } = props;

  const { t } = useTranslation();
  const [valueIcon, setValueIcon] = useState(valueIconDefault);

  const handleCancelSelectedSocialLinkIcon = () => {
    callbackCancelSelectedSocialLinkIcon();
  };

  const listSocialLinkIconOptions = [
    {
      label: <FacebookIcon className="align-middle inline-block" />,
      description: "Facebook",
      value: SOCIAL_LINK.FACEBOOK,
    },
    {
      label: <MessengerIcon className="align-middle inline-block" />,
      description: "Messenger",
      value: SOCIAL_LINK.MESSENGER,
    },
    {
      label: <InstagramIcon className="align-middle inline-block" />,
      description: "Instagram",
      value: SOCIAL_LINK.INSTAGRAM,
    },
    {
      label: <LinkedInIcon className="align-middle inline-block" />,
      description: "LinkedIn",
      value: SOCIAL_LINK.LINKEDIN,
    },
    {
      label: <PinterestIcon className="align-middle inline-block" />,
      description: "Pinterest",
      value: SOCIAL_LINK.PINTEREST,
    },
    {
      label: <SnapchatIcon className="align-middle inline-block" />,
      description: "Snapchat",
      value: SOCIAL_LINK.SNAPCHAT,
    },
    {
      label: <LineChatIcon className="align-middle inline-block" />,
      description: "Line Chat",
      value: SOCIAL_LINK.LINECHAT,
    },
    {
      label: <TiktokIcon className="align-middle inline-block" />,
      description: "Tiktok",
      value: SOCIAL_LINK.TIKTOK,
    },
    {
      label: <TwitchIcon className="align-middle inline-block" />,
      description: "Twitch",
      value: SOCIAL_LINK.TWITCH,
    },
    {
      label: <TwitterIcon className="align-middle inline-block" />,
      description: "Twitter",
      value: SOCIAL_LINK.TWITTER,
    },
    {
      label: <WhatsappIcon className="align-middle inline-block" />,
      description: "WhatsApp",
      value: SOCIAL_LINK.WHATSAPP,
    },
    {
      label: <YoutubeIcon className="align-middle inline-block" />,
      description: "Youtube",
      value: SOCIAL_LINK.YOUTUBE,
    },
    {
      label: <DiscordIcon className="align-middle inline-block" />,
      description: "Discord",
      value: SOCIAL_LINK.DISCORD,
    },
    {
      label: <BehanceIcon className="align-middle inline-block" />,
      description: "Behance",
      value: SOCIAL_LINK.BEHANCE,
    },
    {
      label: <DribbleIcon className="align-middle inline-block" />,
      description: "Dribble",
      value: SOCIAL_LINK.DRIBBLE,
    },
    {
      label: <EmailIcon className="align-middle inline-block" />,
      description: "Email",
      value: SOCIAL_LINK.EMAIL,
    },
    {
      label: <TelIcon className="align-middle inline-block w-[32px] h-[32px]"/>,
      description: "Tel",
      value: SOCIAL_LINK.TEL,
    },
    {
      label: <Lemon8Icon className="align-middle inline-block w-[32px] h-[32px]"/>,
      description: "Lemon8",
      value: SOCIAL_LINK.LEMON8,
    },
    {
      label: <GeneralLinkIcon className="align-middle inline-block" />,
      description: "Other",
      value: SOCIAL_LINK.OTHER,
    },
  ];

  const handleSelectedSocialLinkIcon = (value) => {
    const socialLinkIconType = value[0];
    setValueIcon(socialLinkIconType);
    callbackConfirmSelectedSocialLinkIcon(socialLinkIconType);
    callbackCancelSelectedSocialLinkIcon();
  };

  return (
    <div className="h-full">
      <div className="p-3 max-h-full overflow-auto">
        <Selector
          columns={4}
          options={listSocialLinkIconOptions}
          onChange={handleSelectedSocialLinkIcon}
          value={[valueIcon]}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
            "--checked-color": "#fff",
            "--padding": "8px",
          }}
          className="selector-social-link"
        />
      </div>
    </div>
  );
};

export default SocialLinkSelectedIcon;
