export const IMAGE_UPLOAD_LAYOUTS = {
    LAYOUT_1: "layout-1",
    LAYOUT_2: "layout-2"
}

export const RESIZE = {
    MAX_WIDTH: 512,
    MAX_HEIGHT: 512,
  };

  export const DEFAULT_IMG_URL = "https://i.ecomobi.com/ssp/passio-page/image-default.png";
  export const DEFAULT_UPLOAD_IMG_URL = "https://image.passio.eco/page-builder/page-builder/images-default/image-default.png";
  export const TYPE_RESIZE = {
    LARG_DIMENSION: 1,
    SMALL_DIMENSION: 2,
    WIDTH: 3,
    HEIGHT: 4,
  }
  