import { createSlice, createSelector } from "@reduxjs/toolkit";
import { POSITION_MAIN_LAYOUT } from "@share/configs/const";
import {Block, Position, POSITION_SLUG} from "./interface";
import update from "immutability-helper";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { RootState } from "@app/store";
import { POSITION_TYPE } from "@features/block/config/interface";
import sortBy from "lodash/sortBy";

const initialState: Position[] = [];

export const positionSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    setPosition: (state, { payload: { positions } }) => {
      return positions.map((position) => {
        if (position.slug !== POSITION_MAIN_LAYOUT.BODY) {
          if (position.slug === POSITION_MAIN_LAYOUT.FOOTER) {
            const blockListFooter = position.block_list;
            const newBlockList = blockListFooter.filter((item) => {
              return item.block_type !== BLOCK_TYPE.TAB_BAR;
            });

            return {
              ...position,
              block_list: newBlockList,
            };
          }
          return position;
        }
        const blockList = position.block_list as Block[];
        const tabBlock = blockList.find(
          (block) => block.block_type == BLOCK_TYPE.TAB
        );
        const tabs = tabBlock?.content_attributes?.tabs;
        const hasIsDefault = tabs?.find((x) => x.isDefault == true);
        const newTabs = tabs.map((item, index) => {
          if (index == 0 && !hasIsDefault) {
            return { ...item, isDefault: true };
          }
          return item;
        });
        const newTabBlock = update(tabBlock, {
          content_attributes: { tabs: { $set: newTabs } },
        });
        return update(position, {
          block_list: {
            $apply: (blocks) => {
              return blocks.map((block) => {
                if (block.block_type == BLOCK_TYPE.TAB) {
                  return newTabBlock;
                }
                return block;
              });
            },
          },
        });
      });
    },
    disableEnablePosition: (
      state,
      { payload: { enableValue, positionSlug } }
    ) => {
      const positionIndex = state.findIndex((x) => x.slug === positionSlug);
      state[positionIndex] = {
        ...state[positionIndex],
        enable: enableValue,
      };
    },
    sortBlock: (state, { payload: { itemsOrder, positionSlug, tabUid } }) => {
      const index = state.findIndex((x) => x.slug === positionSlug);
      const newTabList =
        state[index] &&
        state[index].block_list &&
        state[index].block_list[0] &&
        state[index].block_list[0].content_attributes &&
        state[index].block_list[0].content_attributes.tabs &&
        state[index].block_list[0].content_attributes.tabs.map((tab) => {
          return tab.uid === tabUid
            ? {
                ...tab,
                block_list: itemsOrder,
              }
            : tab;
        });
      const newStateData =
        positionSlug === "body"
          ? {
              ...state[index],
              block_list: [
                {
                  ...state[index].block_list[0],
                  content_attributes: {
                    ...state[index].block_list[0].content_attributes,
                    tabs: newTabList,
                  },
                },
              ],
            }
          : {
              ...state[index],
              block_list: itemsOrder,
            };
      state[index] = newStateData;
    },
    addBlock: (state, { payload: { tabUid, positionSlug, blockData } }) => {
      const newBlockIndex = blockData.sort_order - 1;
      return state.map((position) => {
        if (positionSlug == position.slug) {
          if (positionSlug !== "body") {
            //Update for none body block
            const blockLists = sortBy([...position.block_list], ["sort_order"]);
            blockLists.splice(newBlockIndex, 0, blockData);
            return update(position, {
              block_list: {
                $set: blockLists,
              },
            });
          }
          // Update for tab block
          const tabBlock = position.block_list[0] || ({} as any);
          const tabList = tabBlock?.content_attributes?.tabs || [];

          const newTabList = tabList.map((tab) => {
            if (tab.uid === tabUid) {
              const blockLists = sortBy([...tab.block_list], ["sort_order"]);
              blockLists.splice(newBlockIndex, 0, blockData);
              return update(tab, {
                block_list: {
                  $set: blockLists,
                },
              });
            }
            return tab;
          });
          const newTabBlock = update(tabBlock, {
            content_attributes: { tabs: { $set: newTabList } },
          }) as Block;
          return update(position, {
            block_list: {
              $set: [newTabBlock],
            },
          });
        }
        return position;
      });
    },

    setBlock: (state, { payload: { tabUid, positionSlug, blockData } }) => {
      const newState = state.map((position) => {
        if (position.slug !== positionSlug) {
          return position;
        }

        const loadNewBlocks = (blocks: Array<any>) => {
          return blocks.map((block) => {
            if (block.id == blockData.id || block.uid == blockData.uid) {
              return { ...block, ...blockData };
            }
            return block;
          });
        };

        if (positionSlug !== "body") {
          return update(position, {
            block_list: {
              $apply: loadNewBlocks,
            },
          });
        }

        const tabBlock = position.block_list[0] || ({} as any);
        const tabList = tabBlock?.content_attributes?.tabs || [];
        const newTabList = tabList.map((tab) => {
          if (tab.uid === tabUid) {
            return update(tab, { block_list: { $apply: loadNewBlocks } });
          }
          return tab;
        });
        const newTabBlock = update(tabBlock, {
          content_attributes: { tabs: { $set: newTabList } },
        }) as Block;
        return update(position, {
          block_list: {
            $set: [newTabBlock],
          },
        });
      });
      return newState;
    },
    removeBlock: (
      state,
      { payload: { deletedBlock, positionSlug, tabUid } }
    ) => {
      const newState = state.map((position) => {
        if (position.slug !== positionSlug) {
          return position;
        }
        const removeBlocks = (blocks: Array<any>) => {
          return blocks.filter((block) => {
            return (
              block.id !== deletedBlock.id || block.uid != deletedBlock.uid
            );
          });
        };
        if (positionSlug !== "body") {
          return update(position, {
            block_list: {
              $apply: removeBlocks,
            },
          });
        }

        const tabBlock = position.block_list[0] || ({} as any);
        const tabList = tabBlock?.content_attributes?.tabs || [];
        const newTabList = tabList.map((tab) => {
          if (tab.uid === tabUid) {
            return update(tab, { block_list: { $apply: removeBlocks } });
          }
          return tab;
        });
        const newTabBlock = update(tabBlock, {
          content_attributes: { tabs: { $set: newTabList } },
        }) as Block;
        return update(position, {
          block_list: {
            $set: [newTabBlock],
          },
        });
      });
      return newState;
    },
    moveBlockToPosition: (
      state,
      {
        payload: {
          blockData,
          oldPositionSlug,
          newPositionSlug,
          oldTabUid,
          newTabUid,
        },
      }
    ) => {
      const newStateAfterRemovingBlock = state.map((position) => {
        const removeBlocks = (blocks: Array<any>) => {
          return blocks.filter((block) => {
            return block?.id !== blockData?.id || block?.uid != blockData?.uid;
          });
        };
        if (oldPositionSlug === position?.slug) {
          if (oldPositionSlug !== "body") {
            return update(position, {
              block_list: {
                $apply: removeBlocks,
              },
            });
          }
          const tabBlock = position?.block_list[0];
          const tabList = tabBlock?.content_attributes?.tabs || [];
          const newTabList = tabList.map((tab) => {
            if (tab.uid === oldTabUid) {
              return update(tab, { block_list: { $apply: removeBlocks } });
            }
            return tab;
          });
          const newTabBlock = update(tabBlock, {
            content_attributes: { tabs: { $set: newTabList } },
          }) as Block;
          return update(position, {
            block_list: {
              $set: [newTabBlock],
            },
          });
        } else return position;
      });

      const newState = newStateAfterRemovingBlock?.map((position) => {
        if (newPositionSlug === position.slug) {
          if (newPositionSlug !== "body") {
            //Update for none body block
            const listSortOrder = position.block_list.map(
              (block) => block.sort_order
            );
            const newSortOrder = listSortOrder?.length
              ? Math.max(...listSortOrder) + 1
              : 1;
            return update(position, {
              block_list: {
                $push: [{ ...blockData, sort_order: newSortOrder }],
              },
            });
          }
          // Update for tab block
          const tabBlock = position.block_list[0] || ({} as any);
          const tabList = tabBlock?.content_attributes?.tabs || [];
          const newTabList = tabList.map((tab) => {
            if (tab.uid === newTabUid) {
              const listSortOrder = tab.block_list.map(
                (block) => block.sort_order
              );
              const newSortOrder = listSortOrder?.length
                ? Math.max(...listSortOrder) + 1
                : 1;

              return update(tab, {
                block_list: {
                  $push: [{ ...blockData, sort_order: newSortOrder }],
                },
              });
            }
            return tab;
          });
          const newTabBlock = update(tabBlock, {
            content_attributes: { tabs: { $set: newTabList } },
          }) as Block;
          return update(position, {
            block_list: {
              $set: [newTabBlock],
            },
          });
        }
        return position;
      });
      return newState;
    },
    updateBlock: (
      state,
      { payload: { tabUid, positionSlug, blockData } }
    ) => {},
    deleteBlock: (
      state,
      { payload: { deletedBlock, positionSlug, tabUid } }
    ) => {},
    disableEnableBlock: (
      state,
      { payload: { positionSlug, blockUid, blockStatus, tabUid } }
    ) => {
      const index = state.findIndex((x) => x.slug === positionSlug);
      const tabsList =
        state[index].block_list &&
        state[index].block_list[0] &&
        state[index].block_list[0].content_attributes &&
        state[index].block_list[0].content_attributes.tabs;
      const blockList =
        positionSlug === "body"
          ? tabsList.find((x) => x.uid === tabUid).block_list
          : state[index].block_list;
      const newBlockList = blockList.map((block, index) => {
        return block.uid === blockUid
          ? { ...block, enable: blockStatus }
          : block;
      });
      const newTabList =
        tabsList &&
        Array.isArray(tabsList) &&
        tabsList.map((tab) => {
          return tab.uid === tabUid
            ? {
                ...tab,
                block_list: newBlockList,
              }
            : tab;
        });
      const newStateData =
        positionSlug === "body"
          ? {
              ...state[index],
              block_list: [
                {
                  ...state[index].block_list[0],
                  content_attributes: {
                    ...state[index].block_list[0].content_attributes,
                    tabs: newTabList,
                  },
                },
              ],
            }
          : {
              ...state[index],
              block_list: newBlockList,
            };
      state[index] = newStateData;
    },
    changeTitleBlock: (
      state,
      { payload: { positionSlug, blockUid, newTitleBlock, tabUid } }
    ) => {
      const index = state.findIndex((x) => x.slug === positionSlug);
      const tabsList =
        state[index].block_list &&
        state[index].block_list[0] &&
        state[index].block_list[0].content_attributes &&
        state[index].block_list[0].content_attributes.tabs;
      const blockList =
        positionSlug === "body"
          ? tabsList.find((x) => x.uid === tabUid).block_list
          : state[index].block_list;
      const newBlockList = blockList.map((block, index) => {
        return block.uid === blockUid
          ? { ...block, title: newTitleBlock }
          : block;
      });
      const newTabList =
        tabsList &&
        Array.isArray(tabsList) &&
        tabsList.map((tab) => {
          return tab.uid === tabUid
            ? {
                ...tab,
                block_list: newBlockList,
              }
            : tab;
        });
      const newStateData =
        positionSlug === "body"
          ? {
              ...state[index],
              block_list: [
                {
                  ...state[index].block_list[0],
                  content_attributes: {
                    ...state[index].block_list[0].content_attributes,
                    tabs: newTabList,
                  },
                },
              ],
            }
          : {
              ...state[index],
              block_list: newBlockList,
            };
      state[index] = newStateData;
    },
    addTab: (state, { payload: { newDataTabList } }) => {
      const index = state.findIndex((x) => x.slug === "body");
      state[index] = {
        ...state[index],
        block_list: [
          {
            ...state[index].block_list[0],
            content_attributes: {
              ...state[index].block_list[0].content_attributes,
              tabs: newDataTabList,
            },
          },
        ],
      };
    },
    sortTab: (state, { payload: { itemsOrder } }) => {
      const index = state.findIndex((x) => x.slug === "body");
      state[index] = {
        ...state[index],
        block_list: [
          {
            ...state[index].block_list[0],
            content_attributes: {
              ...state[index].block_list[0].content_attributes,
              tabs: itemsOrder,
            },
          },
        ],
      };
    },
    updateTab: (
      state,
      { payload: { valueNameTab, defaultNameTabIcon, indexTab, urlIcon } }
    ) => {
      const index = state.findIndex((x) => x.slug === "body");
      const tabsList =
        state[index].block_list &&
        state[index].block_list[0] &&
        state[index].block_list[0].content_attributes &&
        state[index].block_list[0].content_attributes.tabs;
      const newTablist =
        tabsList &&
        tabsList.map((ele, index) => {
          return indexTab === index
            ? {
                ...ele,
                name: valueNameTab,
                icon: defaultNameTabIcon,
                icon_url: urlIcon,
              }
            : ele;
        });
      state[index] = {
        ...state[index],
        block_list: [
          {
            ...state[index].block_list[0],
            content_attributes: {
              ...state[index].block_list[0].content_attributes,
              tabs: newTablist,
            },
          },
        ],
      };
    },
    deleteTab: (state, { payload: { newItems } }) => {
      const index = state.findIndex((x) => x.slug === "body");
      state[index] = {
        ...state[index],
        block_list: [
          {
            ...state[index].block_list[0],
            content_attributes: {
              ...state[index].block_list[0].content_attributes,
              tabs: newItems,
            },
          },
        ],
      };
    },
    disableEnableTab: (state, { payload: { tabUid, tabStatus } }) => {
      const index = state.findIndex((x) => x.slug === "body");
      const tabsList =
        state[index].block_list &&
        state[index].block_list[0] &&
        state[index].block_list[0].content_attributes &&
        state[index].block_list[0].content_attributes.tabs;
      const newTablist =
        tabsList &&
        tabsList.map((ele, index) => {
          return tabUid === ele.uid
            ? {
                ...ele,
                enable: tabStatus,
              }
            : ele;
        });
      state[index] = {
        ...state[index],
        block_list: [
          {
            ...state[index].block_list[0],
            content_attributes: {
              ...state[index].block_list[0].content_attributes,
              tabs: newTablist,
            },
          },
        ],
      };
    },
    setDefaultTab: (state, { payload: { indexTab, tabStatus } }) => {
      const index = state.findIndex((x) => x.slug === "body");
      const tabsList =
        state[index].block_list &&
        state[index].block_list[0] &&
        state[index].block_list[0].content_attributes &&
        state[index].block_list[0].content_attributes.tabs;
      const tabDefaultInitial = tabsList.find((x) => x.isDefault === true);
      delete tabDefaultInitial.isDefault;
      const newTablist =
        tabsList &&
        tabsList.map((ele, index) => {
          return indexTab === index
            ? {
                ...ele,
                isDefault: true,
                enable: tabStatus,
              }
            : ele;
        });
      state[index] = {
        ...state[index],
        block_list: [
          {
            ...state[index].block_list[0],
            content_attributes: {
              ...state[index].block_list[0].content_attributes,
              tabs: newTablist,
            },
          },
        ],
      };
    },
    updateTabsBody: (state, { payload: { data } }) => {
      const index = state.findIndex((x) => x.slug === "body");
      state[index] = {
        ...state[index],
        block_list: [
          {
            ...state[index].block_list[0],
            content_attributes: {
              ...state[index].block_list[0].content_attributes,
              tabs: data,
            },
          },
        ],
      };
    },
  },
});

export const {
  sortBlock,
  addBlock,
  deleteBlock,
  removeBlock,
  updateBlock,
  moveBlockToPosition,
  setBlock,
  setPosition,
  disableEnableBlock,
  disableEnablePosition,
  changeTitleBlock,
  addTab,
  sortTab,
  updateTab,
  deleteTab,
  disableEnableTab,
  setDefaultTab,
  updateTabsBody,
} = positionSlice.actions;

export const allPosition = (state: RootState) => state.positions;

type Return = (state: RootState) => any;

export const getBlocksBySlug = (
  slug: POSITION_TYPE | "all",
  includeBlockType?: Array<string>
): Return =>
  createSelector([allPosition], (positions) => {
    let blocks = [];
    if (positions) {
      if (slug === "all") {
        blocks = positions.reduce(
          (accumulator, currentValue) =>
            accumulator.concat(currentValue.block_list),
          []
        );
      } else if(slug == POSITION_SLUG.BODY){
        const listTabInBody = positions?.find(
          (x) => x?.slug === POSITION_MAIN_LAYOUT.BODY
        )?.block_list[0]?.content_attributes?.tabs;
        blocks = listTabInBody?.reduce(
          (accumulator, currentValue) =>
            accumulator.concat(currentValue.block_list),
          []
        )
      } else {
        blocks = positions.find(
          (position) => position.slug === slug
        )?.block_list;
      }
    }

    if (includeBlockType) {
      blocks =
        blocks &&
        blocks.filter((block) => includeBlockType.includes(block?.block_type));
      blocks = sortBy(blocks, ["sort_order"]);
    }
    return blocks;
  });

export default positionSlice.reducer;
