import React from "react";
import { PlaceholderProps } from "@features/design/components/DesignMainLayout/PlaceholderCustom";

interface Props {
  content: any;
  icon?: any;
  style?: any;
  placeholderProps: PlaceholderProps;
}

const PlaceholderCustomDragging = ({
  placeholderProps,
  content,
  style,
  icon,
}: Props) => {
  return (
    <div
      className="absolute flex justify-center items-center flex-row gap-1"
      style={{
        top: placeholderProps.clientY,
        left: placeholderProps.clientX,
        height: placeholderProps.clientHeight,
        width: placeholderProps.clientWidth,
        ...style,
      }}
    >
      {icon}
      <span className="text-[13px] font-medium leading-[131%]">{content}</span>
    </div>
  );
};

export default PlaceholderCustomDragging;
