import { LinkTypeEnum } from "@features/block/components/LinkInput";
import { getServiceLabelKey } from "@features/block/components/LinkInput/components/ServiceSelectorPopup";
import IconRender from "@share/IconRender";
import {
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  IconDefault,
  MoreIcon,
  TickIcon,
  XIcon,
} from "@share/icons";
import { Image, List, Popover } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

interface LinkItemProps {
  isShowIcon: boolean;
  isShowImage: boolean;
  isOnlyText: boolean;
  item: any;
  labelObject?: any;
  onDisableEnableItem?: (data: any) => void;
  onRemoveItem?: (data: any) => void;
  onEditItem?: (data: any) => void;
}

const LinkItemModeDrag: React.FunctionComponent<LinkItemProps> = (props) => {
  const {
    item: ele,
    isOnlyText,
    isShowIcon,
    isShowImage,
    labelObject,
    onDisableEnableItem,
    onRemoveItem,
    onEditItem,
  } = props;
  const { content_attributes } = ele;
  const popoverRef = useRef(null);
  const { t } = useTranslation();

  const linkDatavalue =
    content_attributes.link_url || content_attributes?.link_data?.value;
  let isEnough = true;
  if (isShowIcon) {
    isEnough = !!(linkDatavalue && ele?.content_attributes?.button_icon);
  }
  if (isShowImage) {
    isEnough = !!(linkDatavalue && ele?.content_attributes?.image);
  }
  if (isOnlyText) {
    isEnough = !!(linkDatavalue && content_attributes.link_title);
  }

  const handleClickMoreIcon = (event) => {
    // event.preventDefault();
    event.stopPropagation();
  };

  const handleClickPopoverItem = (item: Action, itemData: any) => {
    const key = item && item.key;
    switch (key) {
      case "eye-visible-item":
        onDisableEnableItem(itemData);
        break;
      case "delete-item":
        onRemoveItem(itemData);
        break;
      default:
      // to do
    }
  };

  const MoreElement = (props) => {
    const { itemData } = props;
    return (
      <div className="flex flex-row items-center gap-[15px]">
        <div
          className=""
          onClick={handleClickMoreIcon}
        >
          <Popover.Menu
            mode="dark"
            actions={[
              {
                key: "eye-visible-item",
                icon:
                  itemData?.enable !== 1 ? (
                    <EyeHiddenIcon className="" />
                  ) : (
                    <EyeVisibleIcon className="" />
                  ),
                text:
                  itemData?.enable !== 1
                    ? labelObject?.showItemLabel
                    : labelObject?.hideItemLabel,
              },
              {
                key: "delete-item",
                icon: <DeleteIcon className="" />,
                text: labelObject?.deleteItemLabel,
              },
            ]}
            placement="left"
            trigger="click"
            stopPropagation={["click"]}
            getContainer={() => popoverRef.current}
            onAction={(item: Action) => handleClickPopoverItem(item, itemData)}
          >
            <MoreIcon className="mr-3" />
          </Popover.Menu>
        </div>
      </div>
    );
  };

  return (
    <div ref={popoverRef}>
      <List className="">
        <List.Item
          prefix={<DragIcon className="" />}
          extra={<MoreElement itemData={ele} />}
          onClick={() => onEditItem(ele)}
        >
          <div
            className={cls(
              "flex items-center gap-[6px] flex-row",
              ele.enable !== 1 && "opacity-40"
            )}
          >
            {isShowImage && (
              <Image
                src={ele?.content_attributes?.image}
                width={40}
                height={40}
                fit="cover"
                style={{
                  borderRadius: "4px",
                }}
              />
            )}
            {isShowIcon && isEnough ? (
              <IconRender iconName={ele?.content_attributes?.button_icon} />
            ) : isShowIcon && !isEnough ? (
              <IconDefault className="" />
            ) : (
              <></>
            )}
            <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
              <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                {ele.content_attributes.link_title}
              </div>
              <div
                className="flex"
                style={{ marginTop: "2px" }}
              >
                {content_attributes?.use_aff_url === 1 && linkDatavalue ? (
                  <>
                    <TickIcon />
                    <span className="ml-1 text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                      {t("ss_builder_product_link_wrapped_label")}
                    </span>
                  </>
                ) : (
                  <span className="text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                    {content_attributes?.link_data?.type !==
                    LinkTypeEnum.SERVICE
                      ? linkDatavalue
                      : t(getServiceLabelKey(linkDatavalue))}
                  </span>
                )}
              </div>
            </div>
          </div>
          {!isEnough && (
            <div
              className="flex"
              style={{ marginTop: "2px" }}
            >
              <XIcon />
              <span
                style={{
                  fontSize: "12px",
                  color: "#FF3141",
                }}
              >
                {t("ss_builder_button_link_not_enought_infomation")}
              </span>
            </div>
          )}
        </List.Item>
      </List>
    </div>
  );
};

interface LinkItemModeSelectProps {
  isOnlyText: boolean;
  isShowIcon: boolean;
  isShowImage: boolean;
  enable: any;
  content_attributes: any;
}
const LinkItemModeSelect: React.FunctionComponent<LinkItemModeSelectProps> = (
  props
) => {
  const { isOnlyText, isShowIcon, isShowImage, enable, content_attributes } =
    props;

  const { t } = useTranslation();

  const linkDatavalue =
    content_attributes?.link_url || content_attributes?.link_data?.value;
  let isEnough = true;
  if (isShowIcon) {
    isEnough = !!(linkDatavalue && content_attributes?.button_icon);
  }
  if (isShowImage) {
    isEnough = !!(linkDatavalue && content_attributes?.image);
  }
  if (isOnlyText) {
    isEnough = !!(linkDatavalue && content_attributes.link_title);
  }

  return (
    <>
      <div
        className={cls(
          "flex items-center gap-[6px] flex-row",
          enable !== 1 && "opacity-40"
        )}
      >
        {isShowImage && (
          <Image
            src={content_attributes?.image}
            width={40}
            height={40}
            fit="cover"
            style={{
              borderRadius: "4px",
            }}
          />
        )}
        {isShowIcon && isEnough ? (
          <IconRender iconName={content_attributes?.button_icon} />
        ) : isShowIcon && !isEnough ? (
          <IconDefault className="" />
        ) : (
          <></>
        )}
        <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
          <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
            {content_attributes.link_title}
          </div>
          <div
            className="flex"
            style={{ marginTop: "2px" }}
          >
            {content_attributes?.use_aff_url === 1 && linkDatavalue ? (
              <>
                <TickIcon />
                <span className="ml-1 text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                  {t("ss_builder_product_link_wrapped_label")}
                </span>
              </>
            ) : (
              <span className="text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                {content_attributes?.link_data?.type !== LinkTypeEnum.SERVICE
                  ? linkDatavalue
                  : t(getServiceLabelKey(linkDatavalue))}
              </span>
            )}
          </div>
        </div>
      </div>
      {!isEnough && (
        <div
          className="flex"
          style={{ marginTop: "2px" }}
        >
          <XIcon />
          <span
            style={{
              fontSize: "12px",
              color: "#FF3141",
            }}
          >
            {t("ss_builder_button_link_not_enought_infomation")}
          </span>
        </div>
      )}
    </>
  );
};

const LinkItem = {
  LinkItemModeSelect,
  LinkItemModeDrag,
};

export default LinkItem;
