import { LIST_ECOMMERCE } from "@share/configs/const";
import validator from "validator";

export function isPhoneNumber(value) {
  const regex =
    /^(((\(\+?[0-9]{1,3}\))|(\+[0-9]{1,3}))?[0-9\-\.\s]{3,14})(?<!.*?(\s|\-|\.){2,}.*)$/;
  return regex.test(value);
}

export function isValidEmail(email: string) {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return regex.test(email);
}

export function isValidUrl(url) {
  try {
    return validator.isURL(url);
  } catch (e) {
    return false;
  }
}

export function isValidQueryString(query) {
  const regex = /^([\w-]+(=[\w@ .,-]*)?(&[\w-]+(=[\w@ .,-]*)?)*)?$/;
  return regex.test(query);
}

export function checkOpenNewTab(ecommerceOrSocial) {
  const host = ecommerceOrSocial?.host;
  const regexEcommerce =
    /(^([0-9a-z-]+.)?(shopee|lazada|tiki).[a-z]+(.[a-z]+)?$)/i;
  const regexSocialNetwork =
    /^http(s)?:\/\/([0-9a-z-]+\.)?((facebook|messenger|instagram|linkedin|pinterest|snapchat|line|tiktok|twitch|twitter|whatsapp|youtube|discord|behance|dribbble|telegram|skype|zalo|viber|wechat|discord|tumblr|quora|reddit|medium|weibo|ask)\.([a-z]{2,16})|(t\.me|youtu\.be|m\.me|wa\.me))/i;
  const check = LIST_ECOMMERCE.some((item) => host.includes(item));
  const regexForService =
    /^http(s)?:\/\/([0-9a-z-])+(\.dev)?\.passio\.eco\/[0-9a-z-]+/i;

  const regexShoppe =
    /https?:\/\/(www\.)?(shopee\.(com|vn|ph|co\.th|co\.id|sg|com\.my)|shope\.ee|shp\.ee)/i;
  const regexTiki = /https?:\/\/(www\.)?(ti\.ki|tiki\.vn)/i;
  const regexLazada =
    /https?:\/\/(www\.|s\.|c\.)?lazada\.(com|vn|com\.ph|co\.th|co\.id|sg|com\.my)/i;

  return (
    regexEcommerce.test(host) ||
    regexSocialNetwork.test(ecommerceOrSocial) ||
    regexForService.test(ecommerceOrSocial) ||
    check ||
    regexShoppe.test(ecommerceOrSocial) ||
    regexTiki.test(ecommerceOrSocial) ||
    regexLazada.test(ecommerceOrSocial)
  );
}

export const extractAttrFromString = (embedString: string) => {
  const extractAttrsRgx = RegExp(
    /(?:[a-z]+=("(.*?)"|([0-9]+))|src=(.+?)(?:\s|>|\/>)|[a-z]+)/,
    "ig"
  );
  const arr = embedString?.trim()?.match(extractAttrsRgx);
  const listAttrs =
    arr &&
    arr
      ?.filter((e) => e !== "iframe")
      ?.map((e) => e?.replaceAll('"', "")?.split("="));
  return listAttrs;
};

export const checkDirtyCodeIframe = (embedString: string) => {
  let isValid = false;

  const embedTransformed = embedString?.trim();

  const validateSrcAttr = (src: string) => /^https?:\/\/[^<>]+$/i.test(src);
  const validateWidthOrHeightAttr = (value: string) =>
    /^[0-9]{1,3}%|[0-9]+$/i.test(value);
  const validateStyleAttr = (style: string) =>
    /^[0-9a-z\s\#\+\-\*\/%:;\(\)"'_&\.,]*$/i.test(style);
  const validateTitleAttr = (title: string) => true;
  const validateAllowAttr = (allow: string) => /^[0-9a-z\s-;']*$/i.test(allow);
  const validateAllowfullscreenAttr = (allowfullscreen: string) => {
    if (!!allowfullscreen) {
      return /^(0|1|true|false|yes|no|)$/i.test(allowfullscreen);
    }
    return true;
  };
  const validateFrameBorderAttr = (frameBorder: string) =>
    /^(0|1|true|false|yes|no)$/i.test(frameBorder);
  const validateScrollingAttr = (scrolling: string) =>
    /^(auto|0|1|true|false|yes|no)$/i.test(scrolling);
  const validateLoadingAttr = (loading: string) =>
    /^(eager|lazy)$/i.test(loading);
  const validateClassAttr = (cls: string) => /^[0-9a-z\s-_]*$/i.test(cls);
  const validateIdAttr = (id: string) => /^[0-9a-z\s-_]*$/i.test(id);

  const groupFuncCheckAttr = {
    id: validateIdAttr,
    class: validateClassAttr,
    src: validateSrcAttr,
    width: validateWidthOrHeightAttr,
    height: validateWidthOrHeightAttr,
    style: validateStyleAttr,
    title: validateTitleAttr,
    allow: validateAllowAttr,
    allowfullscreen: validateAllowfullscreenAttr,
    frameborder: validateFrameBorderAttr,
    scrolling: validateScrollingAttr,
    loading: validateLoadingAttr,
  };

  const isSingleIframe =
    /^\s*<iframe\s[^<>]*?(?:title=".*?")?[^<>]*?(?:\/>|>\s*<\/iframe>)\s*$/i.test(
      embedTransformed
    );

  if (isSingleIframe) {
    const listAcceptAttr = Object.keys(groupFuncCheckAttr);
    const listAttrs = extractAttrFromString(embedTransformed);
    const notRequiredHaveValueFields = ["allowfullscreen"];
    //Kiểm tra các thuộc tính phải có dạng [attribute]=value, ngoại trừ các trường không bắt buộc ở array bên trên
    const isRequiredHaveValue = listAttrs
      ?.filter(
        (attr) =>
          !notRequiredHaveValueFields?.includes(attr[0]?.toLocaleLowerCase())
      )
      ?.every((attr) => attr[1] !== undefined && attr[1] !== null);
    //Kiểm tra các thuộc tính đều nằm trong list các thuộc tính cho phép và giá trị của chúng thỏa mãn điều kiện tương ứng
    const isAcceptAttrAll = listAttrs.every((attr) => {
      return (
        listAcceptAttr.includes(attr[0]?.toLocaleLowerCase()) &&
        groupFuncCheckAttr[attr[0]?.toLocaleLowerCase()](
          attr[1]?.toLocaleLowerCase()
        )
      );
    });
    isValid = isRequiredHaveValue && isAcceptAttrAll;
  }
  return isValid;
};

export const checkImageUrl = async (url) => { 
  return await new Promise((resolve) => { 
		const img = new Image(); 
		img.onload = function() { 
			resolve(true); 
		} 
		img.onerror = function() { 
      resolve(false); 
		} 
		img.src = url; 
	});
} 

