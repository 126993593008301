import { BLOCK_TYPE } from "@features/block/block.conf";
import { scrollToNewItem } from "@features/block/libs";
import FullScreenPopup from "@share/components/full-screen-popup";
import StickyChecking from "@share/components/StickyChecking";
import { AddIcon, StoryIcon } from "@share/icons";
import { Button, Divider, Image, Input, TextArea, Selector } from "antd-mobile";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import StoryListItem from "./StoryListItem";
import StoryNewImage from "./StoryNewImage";
import ItemsWithCheckbox from "@features/block/components/ItemsWithCheckbox";
import StoryItemSelectMode from "./StoryListItem/StoryItemSelectMode";
import { ITEMS_MODE } from "@features/block/config/interface";
import cls from "classnames";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import { getPlaceholderProps } from "@share/lib";
import {
  ROUND_PHOTO,
  SQUARE_PHOTO,
  SMAll,
  MIDDLE,
  BIG,
} from "@share/configs/const";

const IMAGE_MAX = 20;
const BLOCK_HEADING_MAXIMUM = 50;
const BLOCK_DESCRIPTION_MAXIMUM = 250;

const StoryComponent = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
    setShowFooterBlockEdit,
  } = props;
  const { t } = useTranslation();
  const UID = `story-block-${uuidv4()}`;

  const defaultValueEditStory =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const titleBlockDefault = "Spotlight";

  const imageArrDefault =
    defaultValueEditStory?.content_attributes?.items || [];
  const valueHeadingBlockDefault =
    defaultValueEditStory?.content_attributes?.block_heading || "";
  const valueDescriptionBlockDefault =
    defaultValueEditStory?.content_attributes?.block_description || "";
  const defaultTypeOfImage =
    defaultValueEditStory?.content_attributes?.type_of_image || ROUND_PHOTO;
  const defaultSizeOfImage =
    defaultValueEditStory?.content_attributes?.size_of_image || MIDDLE;

  const [isOpenAddNewImageStory, setIsOpenAddNewImageStory] = useState(false);
  const [imageArr, setImageArr] = useState(imageArrDefault);
  const [imageEditData, setImageEditData] = useState(null);
  const [indexImageEdit, setIndexImageEdit] = useState(null);
  const [valueHeadingBlock, setValueHeadingBlock] = useState(
    valueHeadingBlockDefault
  );
  const [valueDescriptionBlock, setValueDescriptionBlock] = useState(
    valueDescriptionBlockDefault
  );
  const [placeholderProps, setPlaceholderProps] = useState({});

  const [mode, setMode] = useState<ITEMS_MODE>(ITEMS_MODE.DRAG);

  const swichToDragMode = () => {
    setMode(ITEMS_MODE.DRAG);
    setShowFooterBlockEdit(true);
  };
  const switchToSelectItemsMode = () => {
    setMode(ITEMS_MODE.SELECT_ITEMS);
    setShowFooterBlockEdit(false);
  };

  const [typeOfImage, setTypeOfImage] = useState(defaultTypeOfImage);
  const [sizeOfImage, setSizeOfImage] = useState(defaultSizeOfImage);

  const option = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/spotlight-layout/Spotlight-style-1.svg"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_round_photo_label")}</div>
      ),
      value: ROUND_PHOTO,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/spotlight-layout/Spotlight-style-2.svg"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_square_photo_label")}</div>
      ),
      value: SQUARE_PHOTO,
    },
  ];

  const sizeOptions = [
    {
      label: t("ss_builder_button_link_small_size_label"),
      value: SMAll,
    },
    {
      label: t("ss_builder_button_link_middle_size_label"),
      value: MIDDLE,
    },
    {
      label: t("ss_builder_button_link_large_size_label"),
      value: BIG,
    },
  ];

  const imageSortOrder = useMemo(
    () => orderBy(imageArr, ["sort_order"], ["asc"]),
    [imageArr]
  );

  const imageEnable = useMemo(() => {
    return imageArr.filter((item) => item.enable === 1);
  }, [imageArr]);

  const isDisableBtnSave = useMemo(() => {
    if (
      imageSortOrder?.length > 0 &&
      (JSON.stringify(imageArr) !== JSON.stringify(imageArrDefault) ||
        valueHeadingBlock !== valueHeadingBlockDefault ||
        valueDescriptionBlock !== valueDescriptionBlockDefault ||
        typeOfImage !== defaultTypeOfImage ||
        sizeOfImage !== defaultSizeOfImage) &&
      imageEnable.length > 0
    ) {
      return false;
    } else return true;
  }, [
    imageSortOrder,
    imageArr,
    imageArrDefault,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    imageEnable,
    typeOfImage,
    defaultTypeOfImage,
    sizeOfImage,
    defaultSizeOfImage,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      JSON.stringify(imageArr) !== JSON.stringify(imageArrDefault) ||
      valueHeadingBlock !== valueHeadingBlockDefault ||
      valueDescriptionBlock !== valueDescriptionBlockDefault ||
      typeOfImage !== defaultTypeOfImage ||
      sizeOfImage !== defaultSizeOfImage
    ) {
      return true;
    } else return false;
  }, [
    imageArr,
    imageArrDefault,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    typeOfImage,
    defaultTypeOfImage,
    sizeOfImage,
    defaultSizeOfImage,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  useEffect(() => {
    if (!block.uid) {
      const defaultStoryData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.STORY,
        title: titleBlockDefault,
        content_attributes: {
          items: imageSortOrder,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          type_of_image: typeOfImage,
          size_of_image: sizeOfImage,
        },
        style_attributes: {},
      };
      onUpdateData(defaultStoryData);
    } else {
      onUpdateData({
        ...block,
        uid: block?.uid || UID,
        title: titleBlockDefault,
        content_attributes: {
          items: imageSortOrder,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          type_of_image: typeOfImage,
          size_of_image: sizeOfImage,
        },
        style_attributes: {},
      });
    }
  }, [
    imageSortOrder,
    valueHeadingBlock,
    valueDescriptionBlock,
    typeOfImage,
    sizeOfImage,
  ]);

  const imageRate = useMemo(
    () => imageSortOrder && `${imageSortOrder.length}/${IMAGE_MAX}`,
    [imageSortOrder]
  );

  const handleSaveStory = (data) => {
    const imageClone = cloneDeep(imageSortOrder);
    if (Array.isArray(imageClone)) {
      if (imageEditData) {
        const indexEditItems = imageClone.findIndex(
          (x) => x.uid === imageEditData.uid
        );
        imageClone[indexEditItems] = data;
      } else {
        if (imageClone.length === 0) {
          imageClone.push(data);
        } else {
          const sortIdArr = imageClone.map((x) => x.sort_order);
          imageClone.push({
            ...data,
            sort_order: Math.max(...sortIdArr) + 1,
          });
        }
      }
      setImageArr(imageClone);
      const index = imageClone?.findIndex((x) => x.uid === data?.uid);
      !imageEditData && scrollToNewItem(`blocks-${data?.uid}-${index}`);
    }
  };

  const handleOpenAddImageSlider = () => {
    setIsOpenAddNewImageStory(true);
    setImageEditData(null);
    setIndexImageEdit(null);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(imageSortOrder);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedImageList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedImageList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    setImageArr(itemsOrder);
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  const onRemoveItem = (index: number) => {
    const imageClone = cloneDeep(imageSortOrder);
    const newImageItems =
      imageClone &&
      Array.isArray(imageClone) &&
      imageClone.filter((ele: any, idx: number) => idx !== index);
    setImageArr(newImageItems);
  };

  const onDisableEnableImage = (index: number, imageStatus: boolean) => {
    const imageClone = cloneDeep(imageSortOrder);
    const newImageItems =
      imageClone &&
      Array.isArray(imageClone) &&
      imageClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              enable: imageStatus,
            }
          : ele;
      });
    setImageArr(newImageItems);
  };

  const handleOpenEditImage = (imageData, index) => {
    setIsOpenAddNewImageStory(true);
    setImageEditData(imageData);
    setIndexImageEdit(index);
  };

  const handleChangeBlockHeading = (val) => {
    if (val?.length <= BLOCK_HEADING_MAXIMUM) {
      setValueHeadingBlock(val);
    }
  };

  const handleChangeBlockDescription = (val) => {
    if (val?.length <= BLOCK_DESCRIPTION_MAXIMUM) {
      setValueDescriptionBlock(val);
    }
  };

  const handleSelectImages = (items) => {
    setImageArr(items);
  };

  const renderImagesOnDragMode = () => (
    <div className="relative">
      <StickyChecking previousStickyElementId="block-story-editor-header">
        <div className="flex flex-row justify-between px-3 pt-4 pb-3">
          <div className="grow">
            <div className="text-[15px] text-[#666666]">
              <span className="text-red-600">*</span>
              {t("ss_builder_list_label")}
            </div>
          </div>
          <div className="flex flex-grow justify-end gap-2">
            <Button
              size="small"
              fill="solid"
              color="primary"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                padding: "3px 12px 3px 12px",
                // opacity:
                //   isFulledProductsAllGroups || !isAllowedToAddProducts
                //     ? "40%"
                //     : "",
              }}
              onClick={handleOpenAddImageSlider}
              disabled={imageSortOrder.length === IMAGE_MAX}
            >
              <AddIcon className="w-[15px] h-[15px]" />
              <div>{t("ss_builder_add_new_label")}</div>
              <span>{imageRate}</span>
            </Button>
            {imageSortOrder.length > 0 && (
              <Button
                onClick={switchToSelectItemsMode}
                color="primary"
                fill="outline"
                size="small"
              >
                {t("ss_builder_select_label")}
              </Button>
            )}
          </div>
        </div>
        {imageEnable.length === 0 && imageArr.length > 0 && (
          <div
            className="px-3 pb-4 text-[13px] font-normal leading-[17px]"
            style={{ color: "var(--adm-color-danger)" }}
          >
            {t("ss_builder_need_to_display_at_least_one_spotlight")}
          </div>
        )}
      </StickyChecking>
      {imageSortOrder && imageSortOrder.length > 0 ? (
        <div>
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragUpdate={onDragUpdate}
          >
            <Droppable droppableId={`blocks-${BLOCK_TYPE.STORY}`}>
              {(provided, snapshot): JSX.Element => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="relative"
                >
                  {imageSortOrder &&
                    imageSortOrder.length > 0 &&
                    imageSortOrder.map((ele, index) => (
                      <Draggable
                        draggableId={`blocks-${ele.uid}-${index}`}
                        index={index}
                        key={`blocks-${ele.uid}-${index}`}
                      >
                        {(provided, snapshot): JSX.Element => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={cls(
                              "mt-2",
                              snapshot.isDragging && "adm-item-custom"
                            )}
                          >
                            <StoryListItem
                              index={index}
                              imageData={ele}
                              onRemoveItem={onRemoveItem}
                              onDisableEnableImage={onDisableEnableImage}
                              callbackOpenEditImage={handleOpenEditImage}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}

                  {provided.placeholder}
                  {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                    <PlaceholderCustomDragging
                      placeholderProps={placeholderProps}
                      content={t("ss_builder_placeholder_dragging_content")}
                      style={{
                        backgroundColor: "rgba(230, 247, 255, 0.75)",
                        border: "1px dashed #1890FF",
                        borderRadius: "4px",
                        color: "#1890FF",
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div className="flex flex-col text-center items-center justify-center">
          <span className="inline-block mb-2">
            <StoryIcon className="align-middle w-8 h-8" />
          </span>
          <div className="text-[#333333] text-lg leading-6">
            {t("ss_builder_spotlight_list_empty_title")}
          </div>
          <div className="text-[#8C8C8C] text-xs leading-4">
            {t("ss_builder_spotlight_list_empty_content")}
          </div>
        </div>
      )}
    </div>
  );

  const renderImagesOnSelectItemsMode = () => (
    <ItemsWithCheckbox
      propertyIndex="uid"
      onCancel={swichToDragMode}
      item={StoryItemSelectMode as any}
      onChange={handleSelectImages}
      defaultItems={imageSortOrder.map((e) => ({
        ...e,
        imageData: e,
      }))}
      header={{
        title: t("ss_builder_list_label"),
        sticky: true,
      }}
      footer={{
        countTitle: t("ss_builder_filter_select_multi_items_spotlight_count"),
      }}
      blockType={block.block_type}
    />
  );

  const handleSelectType = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      setTypeOfImage(value);
    }
  };
  const handleSelectSize = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      setSizeOfImage(value);
    }
  };

  return (
    <>
      <div className=" bg-white mt-3 px-4 py-[13px]">
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_heading_label")}
          </span>
          <Input
            placeholder={t("ss_builder_block_heading_placeholder")}
            onChange={handleChangeBlockHeading}
            value={valueHeadingBlock}
            maxLength={BLOCK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueHeadingBlock ? valueHeadingBlock.length : 0
          }/${BLOCK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </span>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            onChange={handleChangeBlockDescription}
            value={valueDescriptionBlock}
            maxLength={BLOCK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueDescriptionBlock ? valueDescriptionBlock.length : 0
          }/${BLOCK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
      </div>
      <div className=" bg-white mt-3 px-4 py-[13px]">
        <div className="text-[15px] text-[#666666]">
          {t("ss_builder_shape_label")}
        </div>
        <Selector
          options={option}
          value={[typeOfImage]}
          onChange={handleSelectType}
          columns={3}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
        <Divider />
        <div className="text-[15px] text-[#666666]">
          {t("ss_builder_button_link_size_selection_title")}
        </div>
        <Selector
          options={sizeOptions}
          value={[sizeOfImage]}
          onChange={handleSelectSize}
          columns={3}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      </div>
      {mode === ITEMS_MODE.DRAG && renderImagesOnDragMode()}
      {mode === ITEMS_MODE.SELECT_ITEMS && renderImagesOnSelectItemsMode()}
      {isOpenAddNewImageStory && (
        <FullScreenPopup
          visible={isOpenAddNewImageStory}
          onMaskClick={() => setIsOpenAddNewImageStory(false)}
        >
          <StoryNewImage
            callBackCancelImageSliderAddNew={() =>
              setIsOpenAddNewImageStory(false)
            }
            handleSaveImageSlider={handleSaveStory}
            imageEditData={imageEditData}
            indexImageEdit={indexImageEdit}
          />
        </FullScreenPopup>
      )}
    </>
  );
};

export default StoryComponent;
