import { InformationIcon } from "@share/icons";
import { Checkbox, Popover } from "antd-mobile";
import React from "react";

interface CheckBoxWithTooltipProps {
  title: string;
  tooltip?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const CheckBoxWithTooltip: React.FunctionComponent<CheckBoxWithTooltipProps> = (
  props
) => {
  const { value, tooltip, onChange, title } = props;
  return (
    <div className="flex flex-row text-center items-center leading-[22px] gap-1">
      <Checkbox
        checked={value}
        onChange={onChange}
      >
        <div className="text-left">{title}</div>
      </Checkbox>
      {tooltip && (
        <Popover
          content={tooltip}
          placement="top-start"
          mode="dark"
          trigger="click"
        >
          <InformationIcon
            className="ml-1 w-[14px] h-[14px]"
            fillColor="#1890FF"
          />
        </Popover>
      )}
    </div>
  );
};

export default CheckBoxWithTooltip;
