import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { RootState } from "@app/store";

interface SiteSettingInterface {
  id?: string;
  user_id?: string;
  type?: string;
  display_setting: any;
  has_change?: boolean;
}

const initialState: SiteSettingInterface = {
  display_setting: {
    background: {
      gradient: "",
      image: "",
      type: "",
      color: "",
    },
    primary_color: "",
    font_family: "",
  },
};

export const siteSettingSlice = createSlice({
  name: "siteSettings",
  initialState,
  reducers: {
    setSiteInfo: (state, { payload: { sites } }) => {
      return sites;
    },
    setDisplaySetting: (state, { payload: { data } }) => {
      state.display_setting = {
        ...state.display_setting,
        ...data,
      };
    },
    setVersion: (state, { payload: { version } }) => {
      return { ...state, version: version };
    },
    setHasChange: (state, { payload: { has_change } }) => {
      return { ...state, has_change: has_change };
    },
  },
});

export const block_style = (state) =>
  state.siteSettings?.display_setting?.block_setting?.block_style;

export const { setSiteInfo, setDisplaySetting, setVersion, setHasChange } =
  siteSettingSlice.actions;
export default siteSettingSlice.reducer;
