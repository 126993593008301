import { Button, Divider, Input } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import IconRender from "@share/IconRender";
import { TAB_ICON_DEFAULT } from "@share/configs/const";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";
import PopupComponent from "@share/components/PopupComponent";
import ListIconBuilder from "@share/components/ListIconBuilder";

const TAB_NAME_MAXIMUM = 25;

const NewTabComponent = (props) => {
  const { cancelTabFloatingPanel, onConfirmAddNewTab, tabValueDefault } = props;

  const UID = `tab-${uuidv4()}`;

  const { t } = useTranslation();

  const inputRef = useRef(null);

  const valueNameTabDefault = useMemo(
    () => tabValueDefault?.name,
    [tabValueDefault]
  );
  const valueIconDefault = useMemo(
    () => tabValueDefault?.icon ?? TAB_ICON_DEFAULT,
    [tabValueDefault]
  );
  const valueIconUrlDefault = useMemo(
    () => tabValueDefault?.icon_url,
    [tabValueDefault]
  );

  const [valueNameTab, setValueNameTab] = useState(valueNameTabDefault);
  const [valueIcon, setValueIcon] = useState(valueIconDefault);
  const [valueIconUrl, setValueIconUrl] = useState(valueIconUrlDefault);
  const [isOpenSelectedTabIcon, setIsOpenSelectedTabIcon] = useState(false);

  useEffect(() => {
    setValueNameTab(valueNameTabDefault);
  }, [valueNameTabDefault]);

  useEffect(() => {
    setValueIcon(valueIconDefault);
  }, [valueIconDefault]);

  useEffect(() => {
    setValueIconUrl(valueIconUrlDefault);
  }, [valueIconUrlDefault]);

  const handleConfirmAddNewTab = () => {
    const defaultTabData = {
      uid: UID,
      name: valueNameTab,
      icon: valueIcon,
      sort_order: 1,
      block_list: [],
      enable: 1,
      icon_url: valueIconUrl,
    };
    onConfirmAddNewTab(defaultTabData);
    handleCancelTabFloatingPanel();
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const handleCancelTabFloatingPanel = () => {
    cancelTabFloatingPanel();
  };

  const handleOpenSelectedTabIcon = () => {
    setIsOpenSelectedTabIcon(true);
  };

  const handleConfirmListIcon = (data) => {
    const { valueIcon, valueUrlIcon } = data;
    setValueIcon(valueIcon);
    setValueIconUrl(valueUrlIcon);
  };

  const isEnableConfirmAddNewTab = useMemo(() => {
    const valueNameTabTrim = valueNameTab && valueNameTab.trim();
    if (valueNameTabTrim && valueNameTabTrim.length > 0) {
      return true;
    }
    return false;
  }, [valueNameTab]);

  return (
    <div className="pb-6">
      <div>
        <div className="px-4 py-3 border-t-[1px] border-t-[#EEEEEE]">
          <div className="mb-1">
            <span className="text-red-600">*</span>
            {t("ss_builder_name_tab_input_title")}
          </div>
          <Input
            value={valueNameTab || ""}
            onChange={(val) => {
              if (val.length <= TAB_NAME_MAXIMUM) {
                setValueNameTab(val);
              }
            }}
            ref={inputRef}
            maxLength={TAB_NAME_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueNameTab ? valueNameTab.length : 0
          }/${TAB_NAME_MAXIMUM}`}</div>
          <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
          <div>
            <div>{t("ss_builder_icon_tab_title")}</div>
          </div>
          <div className="flex flex-row items-center gap-4">
            <Button
              style={{
                padding: valueIcon ? "7px 10px 1px 10px" : "",
                backgroundColor: "#F0F0F0",
                border: "none",
              }}
              onClick={handleOpenSelectedTabIcon}
            >
              <IconRender iconName={valueIcon} />
            </Button>
            <div className="flex flex-row items-center gap-2 grow">
              <Button
                block
                size="middle"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={handleOpenSelectedTabIcon}
              >
                <div className="text-[17px] leading-6">
                  {t("ss_builder_panel_add_icon_tab_title")}
                </div>
              </Button>
            </div>
          </div>
        </div>
        <ButtonConfirmPopup
          onConfirm={
            isEnableConfirmAddNewTab ? handleConfirmAddNewTab : () => {}
          }
          isDisable={!isEnableConfirmAddNewTab}
        />
      </div>
      <PopupComponent
        visible={isOpenSelectedTabIcon}
        onMaskClick={() => setIsOpenSelectedTabIcon(false)}
        title={t("ss_builder_panel_add_icon_tab_title")}
        onClose={() => setIsOpenSelectedTabIcon(false)}
        classNameBody="h-[80vh] popup-hard"
      >
        <ListIconBuilder
          callbackCancelListIcon={() => setIsOpenSelectedTabIcon(false)}
          callbackConfirmListIcon={handleConfirmListIcon}
          defaultNameIcon={valueIcon}
        />
      </PopupComponent>
    </div>
  );
};

export default NewTabComponent;
