import DndScrollable from "@share/components/dnd-scrollable";
import React, { CSSProperties, useRef } from "react";
import style from "./style.module.scss";

interface Props {
  className?: string;
  children: any;
}

const ListItemScrollHorizontal = (props: Props) => {
  const { className = "", children } = props;
  const ref = useRef(null);

  let styleDnScrollable: CSSProperties = {};

  return (
    <div className={`${style.dndItemContainer} ${className}`}>
      <DndScrollable
        style={styleDnScrollable}
        elementRef={ref}
        className={style.dndContainer}
      >
        {children}
      </DndScrollable>
    </div>
  );
};

export default ListItemScrollHorizontal;
