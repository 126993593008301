import {
  CheckCircleIcon,
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  InformationIcon,
  MoreIcon,
} from "@share/icons";
import { Image, List, Modal, Popover, Toast } from "antd-mobile";
import React, { useRef } from "react";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { ImageGalleryInterface } from "@features/main/store/interface";

interface ImageListItemsProps {
  imageData: ImageGalleryInterface;
  index: number;
  onRemoveItem: any;
  onDisableEnableImage: any;
  callbackOpenEditImage: any;
}

const ImageGalleryListItem = (props: ImageListItemsProps) => {
  const { t } = useTranslation();

  const {
    index,
    imageData,
    onRemoveItem,
    onDisableEnableImage,
    callbackOpenEditImage,
  } = props;

  const isEnable = !!(imageData && imageData.enable === 1);

  const popoverRef = useRef(null);

  const actions: Action[] = [
    {
      key: "eye-visible-image",
      icon: !isEnable ? (
        <EyeHiddenIcon className="" />
      ) : (
        <EyeVisibleIcon className="" />
      ),
      text: !isEnable
        ? t("ss_builder_show_image_label")
        : t("ss_builder_hide_image_label"),
    },
    {
      key: "delete-image",
      icon: <DeleteIcon className="" />,
      text: t("ss_builder_delete_image_label"),
    },
  ];

  const MoreElement = () => {
    return (
      <div className="flex flex-row items-center gap-[15px]">
        <div
          onClick={handleClickMoreIcon}
          className=""
        >
          <Popover.Menu
            mode="dark"
            actions={actions}
            placement="left"
            trigger="click"
            // stopPropagation={["click"]}
            // getContainer={() => popoverRef.current}
            onAction={(item: Action) => handleClickPopoverItem(item)}
          >
            <MoreIcon className="mr-3" />
          </Popover.Menu>
        </div>
      </div>
    );
  };

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case "eye-visible-image":
        handleEnableDisableImage();
        break;
      case "delete-image":
        confirmRemoveImage();
        break;
      default:
      // to do
    }
  };

  const confirmRemoveImage = () =>
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="var(--adm-color-primary)"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_image_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm-delete_image_label")}
        </div>
      ),
      confirmText: t("ss_builder_delete_image_label"),
      cancelText: t("ss_builder_cancel-delete_image_label"),
      onConfirm: () => {
        onRemoveItem(index);
        Toast.show({
          icon: (
            <CheckCircleIcon
              className="text-center inline-block"
              fillColor="#00B578"
            />
          ),
          content: (
            <div className="text-center">
              {t("ss_builder_delete_image_successfully_label")}
            </div>
          ),
        });
      },
      onCancel: () => {
        return;
      },
    });

  const handleEditImage = (event) => {
    if (event?.defaultPrevented) {
      return;
    }
    callbackOpenEditImage(imageData, index);
  };

  const handleEnableDisableImage = () => {
    const imageStatus = !isEnable ? 1 : 2;
    onDisableEnableImage(index, imageStatus);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {isEnable
            ? t("ss_builder_disable_image_successfully_label")
            : t("ss_builder_enable_image_successfully_label")}
        </div>
      ),
    });
  };

  const handleClickMoreIcon = (event) => {
    event.preventDefault();
  };

  return (
    <div ref={popoverRef}>
      <List className="">
        <List.Item
          prefix={<DragIcon className="" />}
          extra={<MoreElement />}
          onClick={handleEditImage}
        >
          <div
            className={cls(
              "flex items-center gap-[6px] flex-row",
              !isEnable && "opacity-40"
            )}
          >
            <Image
              src={imageData.image}
              width={40}
              height={40}
              fit="cover"
            />
            <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
              <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                {imageData?.title}
              </div>
            </div>
          </div>
        </List.Item>
      </List>
    </div>
  );
};

export default ImageGalleryListItem;
