import { useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import SpacerSetting from "./SpacerSetting";
import DividerSetting, { LAYOUT_TYPE_ENUM } from "./DividerSetting";
import { DIVIDER_LAYOUT_TYPE_ENUM } from "./DividerSetting/DefaultsLayoutSetting";
import Preview from "./Preview";

export const DEFAULT_SETTING = {
  SPACING_VALUE: 4,
  DIVIDER_TYPE: DIVIDER_LAYOUT_TYPE_ENUM.SOLID,
  DIVIDER_THICKNESS: 1,
  DIVIDER_COLOR: "#000000",
};

const SpacerAndDivider = (props) => {
  const {
    block,
    onUpdateData,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
  } = props;

  // const edittingBlock = useAppSelector((state) => state.edittingBlock);
  // const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const defaultValueEditBlock =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const UID = `${BLOCK_TYPE.SPACER_AND_DIVIDER.split("_").join(
    "-"
  )}-${uuidv4()}`;

  const { spacing_value, divider_thickness, divider_color, image } =
    defaultValueEditBlock?.content_attributes || {};

  const { divider_type, layout_type } =
    defaultValueEditBlock?.style_attributes || {};

  const enableDividerDefault = !!layout_type;

  const settingDataDefault = {
    spacing_value: spacing_value || DEFAULT_SETTING.SPACING_VALUE,
    divider_type: enableDividerDefault
      ? divider_type || DIVIDER_LAYOUT_TYPE_ENUM.SOLID
      : null,
    divider_thickness: enableDividerDefault
      ? divider_thickness || DEFAULT_SETTING.DIVIDER_THICKNESS
      : null,
    divider_color: enableDividerDefault
      ? divider_color || DEFAULT_SETTING.DIVIDER_COLOR
      : null,
    divider_image: {
      url: enableDividerDefault ? image : null,
    } as any,
    layout_type: enableDividerDefault
      ? layout_type || LAYOUT_TYPE_ENUM.DEFAULTS
      : null,
  };

  const [settingData, setSettingData] = useState(settingDataDefault);
  const [enableDivider, setEnableDivider] = useState(enableDividerDefault);

  useEffect(() => {
    if (enableDivider) {
      const { divider_type, divider_thickness, divider_color, layout_type } =
        settingData || {};
      !divider_type &&
        handleUpdateSettingData("divider_type", DIVIDER_LAYOUT_TYPE_ENUM.SOLID);
      !divider_thickness &&
        handleUpdateSettingData(
          "divider_thickness",
          DEFAULT_SETTING.DIVIDER_THICKNESS
        );
      !divider_color &&
        handleUpdateSettingData("divider_color", DEFAULT_SETTING.DIVIDER_COLOR);
      !layout_type &&
        handleUpdateSettingData("layout_type", LAYOUT_TYPE_ENUM.DEFAULTS);
    }
  }, [
    enableDivider,
    divider_type,
    divider_thickness,
    divider_color,
    layout_type,
  ]);

  useEffect(() => {
    if (!block.uid) {
      const defaultBlockData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.SPACER_AND_DIVIDER,
        content_attributes: {
          spacing_value: settingData?.spacing_value,
          divider_thickness: enableDivider
            ? settingData?.divider_thickness
            : null,
          divider_color: enableDivider ? settingData?.divider_color : null,
          image: enableDivider ? settingData?.divider_image?.url : null,
          image_base64: enableDivider
            ? settingData?.divider_image?.base64
            : undefined,
          image_name: enableDivider
            ? settingData?.divider_image?.file_name
            : undefined,
        },
        style_attributes: {
          divider_type: enableDivider ? settingData?.divider_type : null,
          layout_type: enableDivider ? settingData?.layout_type : null,
        },
      };
      onUpdateData(defaultBlockData);
    } else {
      onUpdateData({
        ...block,
        content_attributes: {
          spacing_value: settingData?.spacing_value,
          divider_thickness: enableDivider
            ? settingData?.divider_thickness
            : null,
          divider_color: enableDivider ? settingData?.divider_color : null,
          image: enableDivider ? settingData?.divider_image?.url : null,
          image_base64: enableDivider
            ? settingData?.divider_image?.base64
            : undefined,
          image_name: enableDivider
            ? settingData?.divider_image?.file_name
            : undefined,
        },
        style_attributes: {
          divider_type: enableDivider ? settingData?.divider_type : null,
          layout_type: enableDivider ? settingData?.layout_type : null,
        },
      });
    }
  }, [settingData, enableDivider]);

  const handleUpdateSettingData = (key, value) => {
    setSettingData((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const isDisableBtnSave = useMemo(() => {
    let isChecked = true;
    if (defaultValueEditBlock) {
      if (enableDividerDefault) {
        if (
          !enableDivider ||
          (settingData?.layout_type === LAYOUT_TYPE_ENUM.DEFAULTS &&
            (settingData.divider_color !== settingDataDefault.divider_color ||
              settingData.divider_thickness !==
                settingDataDefault.divider_thickness ||
              settingData.divider_type !== settingDataDefault.divider_type ||
              settingData.spacing_value !==
                settingDataDefault.spacing_value)) ||
          (settingData.spacing_value !== settingDataDefault.spacing_value &&
            settingData?.layout_type === LAYOUT_TYPE_ENUM.IMAGE &&
            settingData?.divider_image?.url) ||
          settingData.divider_image.url !==
            settingDataDefault.divider_image.url ||
          (settingData.layout_type !== settingDataDefault.layout_type &&
            settingData?.layout_type === LAYOUT_TYPE_ENUM.IMAGE &&
            settingData?.divider_image?.url) ||
          (settingData.layout_type !== settingDataDefault.layout_type &&
            settingData?.layout_type === LAYOUT_TYPE_ENUM.DEFAULTS)
        ) {
          isChecked = false;
        } else isChecked = true;
      } else {
        if (
          (!enableDivider &&
            settingData.spacing_value !== settingDataDefault.spacing_value) ||
          (enableDivider &&
            settingData?.layout_type === LAYOUT_TYPE_ENUM.DEFAULTS) ||
          (settingData.spacing_value !== settingDataDefault.spacing_value &&
            settingData?.layout_type === LAYOUT_TYPE_ENUM.DEFAULTS) ||
          (settingData.spacing_value !== settingDataDefault.spacing_value &&
            settingData?.layout_type === LAYOUT_TYPE_ENUM.IMAGE &&
            settingData?.divider_image?.url) ||
          (enableDivider &&
            settingData?.layout_type === LAYOUT_TYPE_ENUM.IMAGE &&
            settingData?.divider_image?.url &&
            settingData?.divider_image?.url !==
              settingDataDefault?.divider_image?.url)
        ) {
          isChecked = false;
        } else isChecked = true;
      }
    } else {
      if (
        !enableDivider ||
        (enableDivider &&
          settingData?.layout_type === LAYOUT_TYPE_ENUM.DEFAULTS) ||
        (settingData.spacing_value !== settingDataDefault.spacing_value &&
          settingData?.layout_type === LAYOUT_TYPE_ENUM.IMAGE &&
          settingData?.divider_image?.url) ||
        (enableDivider &&
          settingData?.layout_type === LAYOUT_TYPE_ENUM.IMAGE &&
          settingData?.divider_image?.url &&
          settingData?.divider_image?.url !==
            settingDataDefault?.divider_image?.url)
      ) {
        isChecked = false;
      } else isChecked = true;
    }

    return isChecked;
  }, [
    settingData,
    defaultValueEditBlock,
    enableDivider,
    settingDataDefault,
    enableDividerDefault,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      enableDivider !== enableDividerDefault ||
      JSON.stringify(settingData) !== JSON.stringify(settingDataDefault)
    ) {
      return true;
    } else return false;
  }, [settingData, enableDivider, settingDataDefault, enableDividerDefault]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  return (
    <>
      <Preview block={block} />
      <SpacerSetting
        spacing_value={settingData?.spacing_value}
        onUpdateSettingData={handleUpdateSettingData}
      />
      <DividerSetting
        enableDivider={enableDivider}
        divider_type={settingData?.divider_type}
        divider_thickness={settingData?.divider_thickness}
        divider_color={settingData?.divider_color}
        divider_image={settingData?.divider_image}
        layout_type={settingData?.layout_type}
        onUpdateSettingData={handleUpdateSettingData}
        onCheckDividerEnable={(val) => setEnableDivider(val)}
      />
    </>
  );
};

export default SpacerAndDivider;
