import { stepsInterface } from "@share/components/ProductTourComponent/interface";

export const PRODUCT_TOUR_CLASS_NAME = {
  STEP_1: "product-tour-design",
  STEP_2: "product-tour-new-block",
  STEP_3: "product-tour-quick-action",
  STEP_4: "product-tour-publish",
  STEP_5: "product-tour-tab-list",
  STEP_6: "product-tour-header",
  STEP_7: "product-tour-body",
  STEP_8: "product-tour-footer",
  STEP_9: "product-tour-restore-edit",
  STEP_10: "product-tour-apply-theme-content",
  STEP_11: "product-tour-preview",
  STEP_12: "product-tour-move-block-position",
  STEP_13: "product-tour-edit-block",
  STEP_14: "product-tour-background-setting",
  STEP_15: "product-tour-theme-store",
  STEP_16: "product-tour-advanced-settings",
  STEP_17: "product-tour-user-manual",
};

export const STEP_INDEX = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2,
  STEP_4: 3,
  STEP_5: 4,
  STEP_6: 5,
  STEP_7: 6,
  STEP_8: 7,
  STEP_9: 8,
  STEP_10: 9,
  STEP_11: 10,
  STEP_12: 11,
  STEP_13: 12,
  STEP_14: 13,
  STEP_15: 14,
  STEP_16: 15,
  STEP_17: 16,
};

export const steps: stepsInterface[] = [
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-1.gif",
    title_key: "ss_builder_product_tour_title_step_1",
    intro_key: "ss_builder_product_tour_content_step_1",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_1,
    tooltip_class: "show-label-text",
    position: "",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-2.gif",
    title_key: "ss_builder_product_tour_title_step_2",
    intro_key: "ss_builder_product_tour_content_step_2",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_2,
    tooltip_class: "",
    position: "",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-3.gif",
    title_key: "ss_builder_product_tour_title_step_3",
    intro_key: "ss_builder_product_tour_content_step_3",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_3,
    tooltip_class: "auto",
    position: "bottom-right-aligned",
    tooltip_position: "bottom-right-aligned",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-4.gif",
    title_key: "ss_builder_product_tour_title_step_4",
    intro_key: "ss_builder_product_tour_content_step_4",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_4,
    tooltip_class: "",
    position: "bottom-right-aligned",
    tooltip_position: "bottom-right-aligned",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-5.gif",
    title_key: "ss_builder_product_tour_title_step_5",
    intro_key: "ss_builder_product_tour_content_step_5",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_5,
    tooltip_class: "",
    position: "top",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-6.gif",
    title_key: "ss_builder_product_tour_title_step_6",
    intro_key: "ss_builder_product_tour_content_step_6",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_6,
    tooltip_class: "",
    position: "top",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-7.gif",
    title_key: "ss_builder_product_tour_title_step_7",
    intro_key: "ss_builder_product_tour_content_step_7",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_7,
    tooltip_class: "",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-8.gif",
    title_key: "ss_builder_product_tour_title_step_8",
    intro_key: "ss_builder_product_tour_content_step_8",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_8,
    tooltip_class: "",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-9.gif",
    title_key: "ss_builder_product_tour_title_step_9",
    intro_key: "ss_builder_product_tour_content_step_9",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_9,
    tooltip_class: "",
    position: "bottom",
    highlight_class: "product-tour-restore-edit",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-10.gif",
    title_key: "ss_builder_product_tour_title_step_10",
    intro_key: "ss_builder_product_tour_content_step_10",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_10,
    tooltip_class: "",
    position: "bottom",
    highlight_class: "product-tour-apply-theme-content",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-11.gif",
    title_key: "ss_builder_product_tour_title_step_11",
    intro_key: "ss_builder_product_tour_content_step_11",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_11,
    tooltip_class: "",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-12.gif",
    title_key: "ss_builder_product_tour_title_step_12",
    intro_key: "ss_builder_product_tour_content_step_12",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_12,
    tooltip_class: "",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-13.gif",
    title_key: "ss_builder_product_tour_title_step_13",
    intro_key: "ss_builder_product_tour_content_step_13",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_13,
    tooltip_class: "",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-14.gif",
    title_key: "ss_builder_product_tour_title_step_14",
    intro_key: "ss_builder_product_tour_content_step_14",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_14,
    tooltip_class: "",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-15.gif",
    title_key: "ss_builder_product_tour_title_step_15",
    intro_key: "ss_builder_product_tour_content_step_15",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_15,
    tooltip_class: "",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-16.gif",
    title_key: "ss_builder_product_tour_title_step_16",
    intro_key: "ss_builder_product_tour_content_step_16",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_16,
    tooltip_class: "product-tour-custom",
    position: "bottom",
    using_selector: true,
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-17.gif",
    title_key: "ss_builder_product_tour_title_step_17",
    intro_key: "ss_builder_product_tour_content_step_17",
    element_class: PRODUCT_TOUR_CLASS_NAME.STEP_17,
    tooltip_class: "show-label-text",
    position: "bottom",
    using_selector: true,
  },
];
