import React, { useEffect, useMemo, useState } from "react";
import {
  LINK_TEMPLATE,
  LINK_TEMPLATE_ARR,
  MAX_WIDTH_CONTENT_LAYOUT,
} from "@share/configs/const";
import { Button, Image, Selector } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { SampleSelectionIcon } from "@share/icons";

const LinkTemplateSelection = (props) => {
  const { linkTemplateDefault, onUpdateLinkTemplate, onCancelLinkTemplate } =
    props;
  const { t } = useTranslation();

  const [linkTemplate, setLinkTemplate] = useState(linkTemplateDefault);

  useEffect(() => {
    if (linkTemplateDefault) setLinkTemplate(linkTemplateDefault);
  }, [linkTemplateDefault]);

  const linkLayoutsOptions = LINK_TEMPLATE_ARR.map((ele) => ({
    label: (
      <Image
        fit="cover"
        src={ele.image}
      />
    ),
    value: ele.value,
  }));

  const handleSelectLinkTemplate = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      setLinkTemplate(value);
    }
  };

  const handleSaveLinkTemplate = () => {
    onUpdateLinkTemplate(linkTemplate);
    onCancelLinkTemplate();
  };

  const isDisableBtnSave = useMemo(() => {
    if (!linkTemplate || linkTemplate === linkTemplateDefault) {
      return true;
    } else return false;
  }, [linkTemplate, linkTemplateDefault]);

  return (
    <div>
      <div
        className="flex flex-row text-center items-center justify-center py-3 gap-[10px] relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <SampleSelectionIcon className="" />
        <div className="text-lg leading-6">
          {t("ss_builder_sample_selection_label")}
        </div>
      </div>
      <Selector
        options={linkLayoutsOptions}
        value={[linkTemplate]}
        onChange={handleSelectLinkTemplate}
        columns={1}
        style={{
          "--border": "solid transparent 1px",
          "--checked-border": "solid var(--adm-color-primary) 1px",
          margin: "16px 12px",
        }}
      />
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={onCancelLinkTemplate}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={isDisableBtnSave}
            color={"primary"}
            onClick={handleSaveLinkTemplate}
          >
            {t("ss_builder_done_panel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LinkTemplateSelection;
