import { BLOCK_TYPE } from "@features/block/block.conf";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { IMAGE_POPUP_ACTION } from "../helpers";
import { ImagePopup } from "../ImagePopupForm";
import ImagePopupItem from "./components/ImagePopupItem";
import cls from "classnames";
import isEmpty from "lodash/isEmpty";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import { PlaceholderProps } from "@features/design/components/DesignMainLayout/PlaceholderCustom";
import { useTranslation } from "react-i18next";

interface ImagePopupListProps {
  onDragEnd: (result: DropResult) => void;
  listImgPopup: Array<ImagePopup>;
  onOpenEditImgPopup: (item: ImagePopup) => void;
  onChangeItem: (status: IMAGE_POPUP_ACTION, item: ImagePopup) => void;
  onDragUpdate: (result: DropResult) => void;
  placeholderProps: PlaceholderProps;
}

const ImagePopupList: React.FunctionComponent<
  React.PropsWithChildren<ImagePopupListProps>
> = (props) => {
  const {
    onDragEnd,
    listImgPopup,
    onChangeItem,
    onOpenEditImgPopup,
    onDragUpdate,
    placeholderProps,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragUpdate={onDragUpdate}
      >
        <Droppable droppableId={`blocks-${BLOCK_TYPE.IMAGE_POPUP}`}>
          {(provided, snapshot): JSX.Element => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="relative"
            >
              {listImgPopup &&
                listImgPopup.length > 0 &&
                listImgPopup.map((ele, index) => (
                  <Draggable
                    draggableId={`blocks-${ele.uid}`}
                    index={index}
                    key={`blocks-${ele.uid}`}
                  >
                    {(provided, snapshot): JSX.Element => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={cls(
                            "mt-2",
                            snapshot.isDragging && "adm-item-custom"
                          )}
                        >
                          <ImagePopupItem
                            imgPopup={ele}
                            onOpenEditImgPopup={onOpenEditImgPopup}
                            onChangeItem={onChangeItem}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <PlaceholderCustomDragging
                  placeholderProps={placeholderProps}
                  content={t("ss_builder_placeholder_dragging_content")}
                  style={{
                    backgroundColor: "rgba(230, 247, 255, 0.75)",
                    border: "1px dashed #1890FF",
                    borderRadius: "4px",
                    color: "#1890FF",
                  }}
                />
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ImagePopupList;
