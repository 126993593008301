import { htmlDecode } from "@share/lib";
import { Button, Modal, Popover } from "antd-mobile";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { ACTION_KEY, MOVE } from "../../constants";
import { ArrowIcon, DownLastIcon, EditIcon, EyeHiddenIcon, EyeVisibleIcon, MoveIcon, ThreeDot, UpFirstIcon, WarningIcon } from "../../icons";
import { Action } from "antd-mobile/es/components/popover";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ListBrand from "../ListBrand";
import FullScreenPopup from "@share/components/full-screen-popup";
import { PreviousImageSliderIcon } from "@share/icons";
  
export default function Item({
    item,
    handleMove,
    popoverActive,
    setPopoverActive,
    number,
    lastList,
    handleHide,
    category,
    onUpdateData,
    arr,
    setArr,
}) {
    const { t } = useTranslation()
    const popoverRef = useRef(null);
    const [cate] = useState(category)
    const itemId = category ? item.category_id : item.id
    const isEnable = category ? item.category_enable : item.brand_enable
    const name = category ? item.category_name : item.brand_name
    const [listBrand, setListBrand] = useState([])
    const [isOpenListBrand, setIsOpenListBrand] = useState(false)
    const [listBrandTitle, setListBrandTitle] = useState('')

    useEffect(() => {
        listBrandTitle && arr.find((item) => {
            if (item.category_name === listBrandTitle) item.list = listBrand
        })
        setArr(arr)
    }, [listBrand])

    const actions: Action[] = [
        {
            key: ACTION_KEY.HIDE_DISPLAY,
            icon: !isEnable ? <EyeHiddenIcon className="" />: <EyeVisibleIcon className="" />,
            text: isEnable 
                ? category ? t('ss_brand_gift_display_hide_category') : t('ss_brand_gift_display_hide_brand')
                : category ? t('ss_brand_gift_display_show_category') : t('ss_brand_gift_display_show_brand')
            ,
            onClick : () => {
                if (isEnable) {
                    setPopoverActive(null)
                    Modal.confirm({
                        content: <div>
                            <WarningIcon className="mx-auto" />
                            <div className="font-bold text-lg text-center mt-3">
                                {category ? t('ss_brand_gift_hide_category') : t('ss_brand_gift_hide_brand')}
                            </div>
                            <div className="mt-2 text-center">
                                {category ? t('ss_brand_gift_hide_category_content', {name: name}) : t('ss_brand_gift_hide_brand_content', {name: name})}
                            </div>
                        </div>,
                        confirmText: category ? t('ss_brand_gift_confirm_hide_group') : t('ss_brand_gift_confirm_hide_brand'),
                        cancelText: t('ss_brand_gift_cancel_hide'),
                        onConfirm: () => handleHide(itemId),
                    })
                } else handleHide(itemId)
            }
        }
    ];
    if (number !== 0) {
        actions.push({key: ACTION_KEY.UP_FIRST, icon: <UpFirstIcon/>, text: t('ss_builder_move_to_top_label')})
    }
    if (number !== lastList) {
        actions.push({key: ACTION_KEY.DOWN_LAST, icon: <DownLastIcon/>, text: t('ss_builder_move_to_bottom_label')})
    }
    const handleClickPopoverItem = (node) => {
        switch (node.key) {
            case ACTION_KEY.UP_FIRST: handleMove(itemId, MOVE.UP_FIRST)
                break;
            case ACTION_KEY.DOWN_LAST: handleMove(itemId, MOVE.DOWN_LAST)
                break;
        }
    }
    const handleClickThreeDot = () => {
        if(popoverActive === itemId) setPopoverActive(null)
        else setPopoverActive(itemId)
    }
    const showListBrand = (id) => {
        const index = arr.find((item) => item.category_id === id)
        setPopoverActive(null)
        setListBrandTitle(index.category_name)
        setListBrand(index.list)
        setIsOpenListBrand(true)
    }

    return <div>
        <div
            className={`flex justify-between bg-white p-3 items-center rounded ${category ? 'cursor-pointer' : ''}`}
        >
            <div className={`flex items-center ${category ? 'flex-1' : 'max-w-[95%]'} ${isEnable ? '' : 'opacity-50'}`} onClick={() => {category && showListBrand(itemId)}}>
                <MoveIcon width={22} height={23}/>
                {!cate ? <LazyLoadImage
                    src={item?.image}
                    className="rounded-lg ml-3 w-10 h-10 bg-cover aspect-square object-cover"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src ="https://i.ecomobi.com/ssp/passio-page/image-default.png";
                    }}
                /> : <></>}
                <div className='px-2 text-base font-normal text-ellipsis overflow-hidden whitespace-nowrap line-clamp-1 max-w-[80%]'>
                    {htmlDecode(category ? item.category_name : item.brand_name)}
                    {category ? ` (${item?.list.length})` : <></>}
                </div>
            </div>
            <div className="flex gap-3">
                <div ref={popoverRef}>
                    <Popover.Menu
                        mode="dark"
                        actions={actions}
                        placement="bottomRight"
                        trigger="click"
                        stopPropagation={["click"]}
                        getContainer={() => popoverRef.current}
                        visible={popoverActive === itemId}
                        onAction={(node) => handleClickPopoverItem(node)}
                        destroyOnHide
                    >
                        <div className="cursor-pointer" onClick={handleClickThreeDot}><ThreeDot /></div>
                    </Popover.Menu>
                </div>
                {category ? <div className="cursor-pointer" onClick={() => showListBrand(itemId)}><ArrowIcon /></div> : <></>}
            </div>
        </div>
        <FullScreenPopup visible={isOpenListBrand} onMaskClick={() => setIsOpenListBrand(false)} heightFitContent={true}>
            <div
                className="flex flex-row text-center items-center justify-center py-3 bg-white sticky top-0"
                ref={popoverRef}
                style={{boxShadow: "inset 0px -1px 0px #EEEEEE",}}
            >
                <div className="flex flex-row justify-between absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full">
                    <Button className="p-0.5" style={{border: 'none'}} onClick={() => {setIsOpenListBrand(false)}} >
                        <PreviousImageSliderIcon />
                    </Button>
                </div>
                <div className="flex gap-1">
                    <EditIcon />
                    <div className="text-lg leading-6 flex">{listBrandTitle} ({listBrand && listBrand?.length})</div>
                </div>
            </div>
            <ListBrand 
                onUpdateData={onUpdateData}
                arrBrand={listBrand}
                setArrBrand={setListBrand}
                listBrandTitle={listBrandTitle}
            />
        </FullScreenPopup>
    </div>
}