import { useEffect, useRef, useState, useMemo } from "react";
import { useAppDispatch } from "@app/hooks";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import { ModalIconConfig, useModal } from "@share/hooks/modal";
import {
  DEFAULT_AFF_BLOCK,
  DEFAULT_OTHER_AFF_BLOCK,
  INDEX_NOT_FOUND,
  IMG_PRODUCT_DEFAULT,
  TYPE_JSON,
} from "./constant";
import { useTranslation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import { checkImageUrl } from "@share/helper/validator";
import { v4 as uuidv4 } from "uuid";
import { PRODUCT_URL_CHECKING, STATUS } from "@share/configs/const";
import { Modal, SpinLoading } from "antd-mobile";
import styles from "./style.module.scss";
import { sleep } from "antd-mobile/es/utils/sleep";
import {
  compareSameEcomerceUrl,
  convertProductType1,
  convertProductType2,
  getSourceUrl,
  validateProductUrl,
} from "./helper";
import ProductUploadFromSiteUrl from "../ProductUploadFromSiteUrl";
import { requestDetectProductUrl } from "@share/api";
import { genLink } from "@features/user/userApi";

type ProductUploadFileInterface = {
  isUploadProductFile: boolean;
  isUploadProductFromSiteUrl: boolean;
  onChange: (data) => void;
  affiliateProductGroupData: any;
};

const ProductUploadFile = ({
  isUploadProductFile,
  isUploadProductFromSiteUrl,
  affiliateProductGroupData,
  onChange,
}: ProductUploadFileInterface) => {
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();
  const { ErrorModal } = useModal();
  const { t } = useTranslation();
  const OTHER_PRODUCT_GROUP = {
    ...DEFAULT_OTHER_AFF_BLOCK,
    content_attributes: {
      ...DEFAULT_OTHER_AFF_BLOCK.content_attributes,
      title_product_group: t("ss_builder_affiliate_product_group_other"),
    },
    title: t("ss_builder_affiliate_product_group_other"),
  };
  const [visibleLoadingUpload, setVisibleLoadingUpload] = useState(false);
  const [totalUploadItem, setTotalUploadItem] = useState(0);
  const [successNumberItem, setSuccessNumberItem] = useState(0);

  const existedProductLists = useMemo(() => {
    let existedProductArr = [];
    affiliateProductGroupData.forEach((x) => {
      const affiliateProductArr = x?.content_attributes?.items || [];
      existedProductArr = [...existedProductArr, ...affiliateProductArr];
    });
    return existedProductArr;
  }, [affiliateProductGroupData]);

  useEffect(() => {
    if (isUploadProductFile) {
      inputRef.current.click();
      dispatch(
        setDynamicParams({
          data: { isUploadProductFile: false },
        })
      );
    }
  }, [isUploadProductFile]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file?.type !== TYPE_JSON) {
      ErrorModal({
        title: t("ss_builder_affiliate_upload_product_error_label"),
        icon: ModalIconConfig.ERROR_PRIMARY_CIRCLE,
        description: t("ss_builder_affiliate_upload_product_error_content"),
        confirmText: t(
          "ss_builder_delete_product_group_error_btn_confirm_label"
        ),
      });
      inputRef.current.value = "";
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const contents = String(e.target.result);
      try {
        const data = JSON.parse(contents);
        handleConvertData(data);
      } catch (error) {
        console.log(error);
      }
      inputRef.current.value = "";
    };
    reader.readAsText(file);
  };

  const handleConvertData = async (data) => {
    setVisibleLoadingUpload(true);
    setTotalUploadItem(0);
    const productLinks = data?.productLinks;
    const categories = data?.categories;
    const groupList = data?.data?.landingPageBaseInfo?.groupList;
    let result = data;
    //Xử lý khi dữ liệu đầu vào là một mảng các đường dẫn sản phẩm
    if (productLinks) {
      const { listProduct, detectUrlList, validUrlList } =
        await convertBaseMultiLinks(data);
      onChange({
        listProduct,
        detectUrlList,
        validUrlList,
      });
      return;
    }
    //Xử lý khi dữ liệu đầu vào là dữ liệu JSON hoặc các dạng dữ liệu object phức tạp
    if (categories?.length > 0) {
      result = await convertProductType1(data);
    } else if (groupList) {
      result = await convertProductType2(data);
    }
    const { listProduct, detectUrlList, validUrlList } =
      await convertBaseJsonFile(result);
    onChange({
      listProduct,
      detectUrlList,
      validUrlList,
    });
  };

  const convertBaseJsonFile = async (data) => {
    let listProduct = cloneDeep(affiliateProductGroupData);
    const allProducts = listProduct?.reduce(
      (acc, product) => acc.concat(product?.content_attributes?.items),
      []
    );
    let prod = cloneDeep(data?.products);
    let detectUrlList = [];
    let validUrlList = [];
    let count = 0;
    let sortOrder =
      affiliateProductGroupData[affiliateProductGroupData?.length - 1]
        ?.sort_order;
    setTotalUploadItem(prod?.length);
    while (count < prod?.length) {
      //wait to set state
      await sleep(1);
      const validateUrl = validateProductUrl(
        prod[count],
        allProducts,
        detectUrlList
      );
      detectUrlList.push(validateUrl.url);
      if (prod[count]?.url && prod[count]?.product_name && validateUrl.valid) {
        const idxOther = listProduct.findIndex(
          (x) =>
            x?.content_attributes?.title_product_group ===
            OTHER_PRODUCT_GROUP?.content_attributes?.title_product_group
        );
        const idxProduct = listProduct.findIndex(
          (x) =>
            x?.content_attributes?.title_product_group?.toUpperCase() ===
            prod[count]?.category_name?.toUpperCase()
        );
        //Create data source to use api postlink
        const sourceUrl = getSourceUrl(prod[count]?.url);
        validUrlList.push(prod[count]?.url);
        prod[count].id = prod[count]?.id || uuidv4();
        prod[count].uid = prod[count]?.id || uuidv4();
        prod[count].image = (await checkImageUrl(prod[count]?.product_image))
          ? prod[count]?.product_image
          : IMG_PRODUCT_DEFAULT;
        prod[count].title = prod[count]?.product_name;
        prod[count].name = prod[count]?.product_name;
        prod[count].enable = STATUS.ENABLE;
        // prod[count].use_aff_url = STATUS.ENABLE;
        prod[count].origin_link = prod[count]?.url;
        if (isUploadProductFromSiteUrl) {
          prod[count].is_import_by_url = true;
        } else {
          prod[count].is_import_json = true;
        }
        prod[count] = { ...prod[count], ...sourceUrl };
        prod[count] = omit(prod[count], [
          "product_image",
          "product_name",
          "url",
        ]);
        const prodUid = `affiliate-product-${uuidv4()}`;
        if (
          !prod[count].hasOwnProperty("category_name") ||
          prod[count].category_name === ""
        ) {
          //Find group product other, if it not found, create product other
          if (idxOther === INDEX_NOT_FOUND) {
            sortOrder = sortOrder + 1;
            listProduct.push({
              ...OTHER_PRODUCT_GROUP,
              content_attributes: {
                ...OTHER_PRODUCT_GROUP.content_attributes,
                items: [{ ...prod[count] }],
              },
              uid: prodUid,
              sort_order: sortOrder,
            });
          } else if (idxOther > INDEX_NOT_FOUND) {
            listProduct[idxOther]?.content_attributes.items.push(prod[count]);
          }
        } else if (idxProduct > INDEX_NOT_FOUND) {
          // If found category name, add it into group category product
          listProduct[idxProduct]?.content_attributes.items?.push(prod[count]);
        } else if (idxProduct === INDEX_NOT_FOUND) {
          // If not found category name, create group category product
          sortOrder = sortOrder + 1;
          listProduct.push({
            ...DEFAULT_AFF_BLOCK,
            content_attributes: {
              ...DEFAULT_AFF_BLOCK.content_attributes,
              items: [{ ...prod[count] }],
              title_product_group: prod[count]?.category_name,
            },
            title: prod[count]?.category_name,
            uid: prodUid,
            sort_order: sortOrder,
          });
        }
      }
      count++;
      setSuccessNumberItem(validUrlList.length);
    }
    setVisibleLoadingUpload(false);
    setSuccessNumberItem(0);
    return {
      listProduct,
      detectUrlList,
      validUrlList,
    };
  };

  const convertBaseMultiLinks = async (data) => {
    const productLinks = data?.productLinks;
    const use_aff_url = data?.use_aff_url;
    let listProduct = cloneDeep(affiliateProductGroupData);
    const detectUrlList = [];
    const validUrlList = [];
    const detectedProductIdArr = [];
    let count = 0;
    let sortOrder =
      affiliateProductGroupData[affiliateProductGroupData?.length - 1]
        ?.sort_order;
    setTotalUploadItem(productLinks?.length);
    while (count < productLinks?.length) {
      //wait to set state
      await sleep(1);
      let detectedResponse = null;
      try {
        //Xử lý khi api chạy đến 10s mà chưa trả về kết quả sẽ tự động bỏ qua, không ghi nhận kết quả api đó nữa
        const timeoutPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(null);
          }, 30000);
        });
        //Nếu trong 10s mà trả về response thì sẽ lấy response đó, ngược lại sẽ trả về null
        detectedResponse = await Promise.race([
          timeoutPromise,
          requestDetectProductUrl(productLinks[count]),
        ]);
      } catch (error) {
        console.log("Request error", error);
      }
      if (
        detectedResponse &&
        /^20[0-9]$/.test(detectedResponse?.status?.toString()) &&
        detectedResponse?.data?.data
      ) {
        const productDetectData = {
          ...detectedResponse?.data?.data?.product,
          product_id: detectedResponse?.data?.data?.product_id,
        };
        //Kiểm tra xem sản phẩm vừa gọi api detect đã tồn tại từ trước hay chưa?
        const isCheckExistProduct = !!(
          (Array.isArray(existedProductLists) &&
            existedProductLists?.length > 0 &&
            existedProductLists?.some(
              (x) =>
                String(x.uid) === String(productDetectData.id) ||
                compareSameEcomerceUrl(x?.origin_link, productLinks[count])
            )) ||
          detectedProductIdArr.includes(productDetectData?.id)
        );
        //Nếu đã tồn tại thì báo trùng lặp, ngược lai thì tiếp tục xử lý
        if (isCheckExistProduct) {
          detectUrlList.push({
            url: productLinks[count],
            valid_type: PRODUCT_URL_CHECKING.DUPLICATE,
          });
        } else {
          const sourceUrl = getSourceUrl(productLinks[count]);
          const isSuccess = detectedResponse?.data?.data?.success;
          let shorternLink = null;
          try {
            shorternLink = await genLink(productLinks[count], {
              sub1: "ig",
              sub2: "true",
              traffic_source: "ss-landing-copy",
            });
          } catch (e) {
            console.log(e);
          }
          const currentTime = new Date();
          const createdAtTime = currentTime.toISOString();
          const newProduct = {
            ...productDetectData,
            ...sourceUrl,
            id: productDetectData?.id || uuidv4(),
            uid: productDetectData?.id || uuidv4(),
            title: productDetectData?.name,
            enable: STATUS.ENABLE,
            shortern_link: shorternLink,
            use_aff_url: use_aff_url,
            origin_link: productLinks[count],
            warning_code: isSuccess
              ? detectedResponse?.data?.data?.warning_code
              : null,
            error_code: !isSuccess ? detectedResponse?.data?.error_code : null,
            createdAt: createdAtTime,
          };
          const idxOther = listProduct.findIndex(
            (x) =>
              x?.content_attributes?.title_product_group ===
              OTHER_PRODUCT_GROUP?.content_attributes?.title_product_group
          );
          const prodUid = `affiliate-product-${uuidv4()}`;
          //Find group product other, if it not found, create product other
          if (idxOther === INDEX_NOT_FOUND) {
            sortOrder = sortOrder + 1;
            listProduct.push({
              ...OTHER_PRODUCT_GROUP,
              content_attributes: {
                ...OTHER_PRODUCT_GROUP.content_attributes,
                items: [newProduct],
              },
              uid: prodUid,
              sort_order: sortOrder,
            });
          } else if (idxOther > INDEX_NOT_FOUND) {
            listProduct[idxOther]?.content_attributes.items.push(newProduct);
          }
          validUrlList.push(productLinks[count]);
          detectedProductIdArr.push(productDetectData?.id);
          detectUrlList.push({
            url: productLinks[count],
            valid_type: PRODUCT_URL_CHECKING.SUCCESS,
          });
        }
      } else {
        detectUrlList.push({
          url: productLinks[count],
          valid_type: PRODUCT_URL_CHECKING.NOT_ADDED_YET,
        });
      }
      count++;
      setSuccessNumberItem(validUrlList?.length);
    }
    setVisibleLoadingUpload(false);
    setSuccessNumberItem(0);
    return {
      listProduct,
      detectUrlList,
      validUrlList,
    };
  };

  const handleUpdateDataFromSiteUrl = (data) => {
    handleConvertData(data);
  };

  return (
    <>
      {isUploadProductFromSiteUrl && (
        <ProductUploadFromSiteUrl onUpdateData={handleUpdateDataFromSiteUrl} />
      )}
      <div className={styles.uploadProductFile}>
        <input
          ref={inputRef}
          type="file"
          accept=".json"
          className="invisible h-[0] w-[0]"
          onChange={handleFileSelect}
        />
      </div>
      <Modal
        visible={visibleLoadingUpload}
        style={{
          width: 151,
          height: 118,
        }}
        content={
          <div className="text-center p-4">
            <div className="mb-3 flex justify-center">
              <SpinLoading
                color="#EE3244"
                style={{
                  "--size": "32px",
                }}
              />
            </div>
            {
              <div className="text-[15px] text-[#333333]">
                {!!totalUploadItem
                  ? t("ss_builder_multi_product_url_importing_label", {
                      successfullyUrlQuantity: successNumberItem,
                      detectedUrlQuantity: totalUploadItem,
                    })
                  : t("ss_builder_information_handler_alert")}
              </div>
            }
          </div>
        }
      />
    </>
  );
};

export default ProductUploadFile;
