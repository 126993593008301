import React, { useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";
import InputCustom from "@share/components/InputCustom";
import { BIO_BOOKING_PROFILE_FIELD, FINISH_ONBOARDING } from "../const";
import { Collapse, Divider, Popover, Switch, Toast } from "antd-mobile";
import styles from "./style.module.scss";
import {
  CheckCircleIcon,
  CoppyAltIcon,
  DropDownIcon1,
  DropDownIcon2,
  InfomationCircleIcon,
} from "@share/icons";
import { copyToClipboard } from "@share/helper/browser";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { ModalIconConfig, useModal } from "@share/hooks/modal";

type BookingProfileLinkProp = {
  data: {
    bio_booking_profile_display: boolean;
    bio_booking_profile_title: string;
    bio_booking_profile_description: string;
    bio_booking_profile_button_label: string;
  };
  onChange: (value) => void;
};

const BookingProfileLink = ({ data, onChange }: BookingProfileLinkProp) => {
  const { t } = useTranslation();
  const domain = useSelector<RootState, any>(
    (state) => state?.user?.kol?.domain
  );
  const bookingProfileSeller = useSelector<RootState, any>(
    (state) => state?.bookingProfile
  );
  const bookingProfileLink = useMemo(() => {
    let link = "";
    if (domain) {
      link = `https://${domain}/booking-profile`;
    }
    return link;
  }, [domain]);
  const { ErrorModal } = useModal();

  const handleChange = (nameField, value) => {
    const dataCurrent = cloneDeep(data);
    onChange({
      ...dataCurrent,
      [nameField]: value,
    });
  };

  const handleClickCoppyLink = () => {
    copyToClipboard(bookingProfileLink);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_block_link_copy_successfully_label")}
        </div>
      ),
    });
  };

  const handleChangeSwitch = (nameField, val) => {
    if (val) {
      if (bookingProfileSeller?.finish_onboard !== FINISH_ONBOARDING) {
        ErrorModal({
          title: t("ss_builder_block_booking_file_alert_title"),
          icon: ModalIconConfig.INFO_WARNING_TRIANGLE,
          description: t("ss_builder_block_booking_file_alert_content"),
          confirmText: t("ss_builder_modal_button_close"),
        });
        return;
      }
    }
    const dataCurrent = cloneDeep(data);
    onChange({
      ...dataCurrent,
      [nameField]: val,
    });
  };

  return (
    <div className={styles.bookingProfile}>
      <Collapse
        defaultActiveKey={["booking-profile"]}
        accordion={false}
        arrow={(active) => (active ? <DropDownIcon1 /> : <DropDownIcon2 />)}
      >
        <Collapse.Panel
          key="booking-profile"
          title={
            <>
              <div className="text-[17px] leading-[22px] font-normal">
                {t("ss_builder_block_booking_file_label")}
              </div>
            </>
          }
        >
          <div className="bg-white w-full px-4 py-3">
            <div className="">
              <div className="flex items-center gap-x-1">
                <div className="text-[15px] text-[#666666] font-normal">
                  {t("ss_builder_enable_shadow")}
                </div>
                <div>
                  <Popover
                    content={t("ss_builder_block_booking_file_switch_tooltip")}
                    placement="top-start"
                    mode="dark"
                    trigger="click"
                  >
                    <div className="w-[21px] h-[21px] flex items-center justify-center">
                      <InfomationCircleIcon
                        fillColor="#1890FF"
                        className="w-[15px] h-[15px] cursor-pointer"
                      />
                    </div>
                  </Popover>
                </div>
              </div>
              <Switch
                defaultChecked={false}
                checked={data?.bio_booking_profile_display || false}
                onChange={(val) =>
                  handleChangeSwitch(
                    BIO_BOOKING_PROFILE_FIELD.BIO_BOOKING_PROFILE_FIELD_DISPLAY,
                    val
                  )
                }
              />
            </div>
            {data?.bio_booking_profile_display && (
              <div className="bg-white pt-3">
                <Divider style={{ margin: "0px" }} />
                <div className="pt-3">
                  <InputCustom
                    label={t("ss_builder_block_booking_profile_setting_title")}
                    value={data.bio_booking_profile_title}
                    maxLength={50}
                    placeholder={t("ss_builder_block_description_placeholder")}
                    onChange={(value) =>
                      handleChange(
                        BIO_BOOKING_PROFILE_FIELD.BIO_BOOKING_PROFILE_FIELD_TITLE,
                        value
                      )
                    }
                    hasNoBorder={true}
                    showCountCharacter={true}
                    hasBottomDivider={true}
                  />
                  <InputCustom
                    label={t(
                      "ss_builder_block_booking_profile_setting_description"
                    )}
                    value={data.bio_booking_profile_description}
                    maxLength={250}
                    placeholder={t("ss_builder_block_description_placeholder")}
                    onChange={(value) =>
                      handleChange(
                        BIO_BOOKING_PROFILE_FIELD.BIO_BOOKING_PROFILE_FIELD_DESCRIPTION,
                        value
                      )
                    }
                    hasNoBorder={true}
                    isMultiline={true}
                    showCountCharacter={true}
                    hasBottomDivider={true}
                  />
                  <InputCustom
                    label={t("ss_builder_block_booking_profile_button_label")}
                    value={data.bio_booking_profile_button_label}
                    maxLength={25}
                    placeholder={t("ss_builder_block_description_placeholder")}
                    onChange={(value) =>
                      handleChange(
                        BIO_BOOKING_PROFILE_FIELD.BIO_BOOKING_PROFILE_FIELD_LABEL,
                        value
                      )
                    }
                    hasNoBorder={true}
                    required={true}
                    showCountCharacter={true}
                    hasBottomDivider={true}
                  />
                  <div className="overflow-hidden">
                    <div className="flex items-center gap-x-1 mb-1">
                      <div className="text-[#666666] text-[15px] leading-[150%]">
                        {t("ss_builder_block_booking_file_label")}
                      </div>
                      <Popover
                        content={t(
                          "ss_builder_block_booking_file_link_tooltip"
                        )}
                        placement="top-start"
                        mode="dark"
                        trigger="click"
                      >
                        <div className="w-[21px] h-[21px] flex items-center justify-center">
                          <InfomationCircleIcon
                            fillColor="#1890FF"
                            className="w-[15px] h-[15px] cursor-pointer"
                          />
                        </div>
                      </Popover>
                    </div>
                    <div className="flex items-center w-full justify-between">
                      <div
                        className="text-[17px] leading-[150%] truncate"
                        style={{
                          color: "rgba(51, 51, 51, 0.4)",
                        }}
                      >
                        {bookingProfileLink}
                      </div>
                      <div
                        className="w-[24px] h-[24px] cursor-pointer flex items-center justify-center"
                        onClick={handleClickCoppyLink}
                      >
                        <CoppyAltIcon
                          width={16}
                          height={16}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default BookingProfileLink;
