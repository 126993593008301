import { saveSettingDonation, updateStore } from "@share/api";
import { fetchStoreToken } from "@features/mini-store/share/api";

const DONATION_ON = 1;
export const DonationMiddleWare = async (data, extraData) => {
  if (data) {
    const params =
      data.content_attributes && data.content_attributes.dataParams;
    try {
      const store = await fetchStoreToken();
      if (store && store.donation_status != DONATION_ON) {
        const dataUpdate = {
          donation_status: DONATION_ON,
          id: store.id,
        };
        await updateStore(dataUpdate);
        if (!store.product_donation_status) {
          await saveSettingDonation(params);
        }
      }
    } catch (e) {
      return data;
    }
  }
  return data;
};
