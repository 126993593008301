import { put, takeEvery, select, all } from "redux-saga/effects";
import {
  addBlock,
  updateBlock,
  setBlock,
  deleteBlock,
  removeBlock,
  sortBlock,
  disableEnableBlock,
  updateTabsBody,
  moveBlockToPosition,
} from "@features/main/store/positionsSlice";
import {
  deleteBlock as callDeleteBlock,
  saveAttributes,
  saveBlock,
  saveTabsAttributes,
  sortBlock as callSortBlock,
  SortDataInterface,
} from "@features/block/api";
import {
  PendingProcessDataInterface,
  addPendingProcessData,
  removePendingProcessData,
} from "@features/main/store/dynamicSlice";
import { Block, POSITION_SLUG } from "@features/main/store/interface";
import { findBlock, getParentId } from "../helper";
import { ActionInterface } from "@app/store";
import {
  callBackSavingItem,
  pushSavingItem,
} from "@features/main/store/savingItemSlice";
import { generateSavingProcessId } from "@features/main/store/saga/saving-item";
import { BLOCK_TYPE } from "@features/block/block.conf";
import pick from "lodash/pick";
import {
  FirstGuideStateEnum,
  setFirstGuideState,
} from "@features/design/store/externalStateSlice";

function* handleSavingAction() {
  yield takeEvery(updateBlock.type, function* (action: ActionInterface) {
    yield put(setBlock(action.payload));
    const blockData = action.payload.blockData;
    const savingProcessId = generateSavingProcessId("update-block");
    yield put(
      addPendingProcessData({ id: savingProcessId, data: action.payload })
    );
    yield put(
      pushSavingItem({
        item: {
          id: savingProcessId,
          executer: saveBlock,
          getArguments: (baseUpdateData) => {
            return [baseUpdateData, blockData];
          },
        },
      })
    );
  });

  yield takeEvery(deleteBlock.type, function* (action: ActionInterface) {
    yield put(removeBlock(action.payload));
    if (action?.payload?.deletedBlock?.id) {
      const savingProcessId = generateSavingProcessId("delete-block");
      yield put(
        pushSavingItem({
          item: {
            id: savingProcessId,
            executer: callDeleteBlock,
            getArguments: (baseUpdateData) => {
              return [baseUpdateData, action.payload.deletedBlock.id];
            },
          },
        })
      );
    }
  });

  yield takeEvery(addBlock.type, function* (action: ActionInterface) {
    let blockLists = [];
    const positions = yield select((state) => state.positions);
    const { blockData, positionSlug, tabUid } = action.payload;
    const tabs = positions
      .find((position) => position.slug == POSITION_SLUG.BODY)
      .block_list.find((item) => item.block_type == BLOCK_TYPE.TAB)
      ?.content_attributes?.tabs;
    positions.forEach((position) => {
      if (position?.slug !== positionSlug) return;
      if (positionSlug !== POSITION_SLUG.BODY) {
        blockLists = position?.block_list;
      } else {
        blockLists = tabs?.find((x) => x?.uid === tabUid)?.block_list;
      }
    });
    const blockListsSortOrder = blockLists?.map((block, idx) => ({
      ...block,
      sort_order: idx + 1,
    }));
    const savingProcessId = generateSavingProcessId("add-block");
    yield put(
      addPendingProcessData({ id: savingProcessId, data: action.payload })
    );
    yield put(
      pushSavingItem({
        item: {
          id: savingProcessId,
          executer: saveBlock,
          getArguments: (baseUpdateData) => {
            return [baseUpdateData, blockData];
          },
        },
      })
    );
    yield put(
      sortBlock({ itemsOrder: blockListsSortOrder, positionSlug, tabUid })
    );
  });

  yield takeEvery(sortBlock.type, function* (action: ActionInterface) {
    const blockList = action.payload.itemsOrder as Block[];
    const sortData: SortDataInterface[] = blockList
      ?.filter((x) => x.id)
      ?.map((block) => ({
        id: block.id,
        sort_order: block.sort_order,
      }));
    const savingProcessId = generateSavingProcessId("sort-block");
    yield put(
      pushSavingItem({
        item: {
          id: savingProcessId,
          executer: callSortBlock,
          getArguments: (baseUpdateData) => {
            return [baseUpdateData, sortData];
          },
        },
      })
    );
  });

  yield takeEvery(disableEnableBlock.type, function* (action: ActionInterface) {
    const positions = yield select((state) => state.positions);
    const { blockStatus, blockUid, positionSlug, tabUid } = action.payload;
    const block = findBlock(positions, {
      uid: blockUid,
      positionSlug: positionSlug,
      tabUid,
    });
    const savingProcessId = generateSavingProcessId("change-block-status");
    yield put(
      pushSavingItem({
        item: {
          id: savingProcessId,
          executer: saveAttributes,
          getArguments: (baseUpdateData) => {
            return [baseUpdateData, block.id, { enable: blockStatus }];
          },
        },
      })
    );
  });

  yield takeEvery(
    moveBlockToPosition.type,
    function* (action: ActionInterface) {
      const { blockData, newPositionSlug, newTabUid } = action.payload;
      const positions = yield select((state) => state.positions);
      const block = findBlock(positions, {
        uid: blockData?.uid,
        positionSlug: newPositionSlug,
        tabUid: newTabUid,
      });
      const positionId =
        newPositionSlug !== "body"
          ? positions?.find((x) => x?.slug === newPositionSlug)?.id
          : "0";
      const parentId = getParentId(positions, {
        positionSlug: newPositionSlug,
        tabUid: newTabUid,
      });
      const savingProcessId = generateSavingProcessId("move-block-to-position");
      yield put(
        pushSavingItem({
          item: {
            id: savingProcessId,
            executer: saveAttributes,
            getArguments: (baseUpdateData) => {
              return [
                baseUpdateData,
                block.id,
                {
                  tab_uid: newTabUid || "0",
                  position_id: positionId,
                  parent_id: parentId,
                  sort_order: block?.sort_order,
                },
              ];
            },
          },
        })
      );
    }
  );

  yield takeEvery(addBlock.type, function* (action: ActionInterface) {
    const firstGuidState = yield select(
      (state) => state.externalState.guide_state
    );
    if (firstGuidState == FirstGuideStateEnum.ADD_BLOCK) {
      yield put(setFirstGuideState(FirstGuideStateEnum.PREVIEW));
    }
  });

  yield takeEvery(updateTabsBody.type, function* (action: ActionInterface) {
    const positions = yield select((state) => state.positions);
    const tabs = positions
      .find((position) => position.slug == "body")
      .block_list.find((item) => item.block_type == BLOCK_TYPE.TAB);
    const blockId = tabs.id;
    const data = action.payload.data as any[];
    const updateTabsData = data.map((item) =>
      pick(item, [
        "enable",
        "icon",
        "icon_url",
        "isDefault",
        "name",
        "sort_order",
        "uid",
      ])
    );
    const savingProcessId = generateSavingProcessId("update-tabs-info");
    yield put(
      pushSavingItem({
        item: {
          id: savingProcessId,
          executer: saveTabsAttributes,
          getArguments: (baseUpdateData) => {
            return [baseUpdateData, blockId, updateTabsData];
          },
        },
      })
    );
  });
}

function* handleCallBack() {
  yield takeEvery(callBackSavingItem.type, function* (action: ActionInterface) {
    const { payload } = action;
    const id: string = payload.id;
    if (id && (id.startsWith("add-block") || id.startsWith("update-block"))) {
      const processDataState = (yield select(
        (state) => state.dynamicConfig.pendingProcessData
      )) as PendingProcessDataInterface[];
      const pendingProcessData = processDataState.find((item) => item.id == id);
      const res = payload.data;
      const blockData = res.data.data;
      // const newBlockData = { ...blockData, id: blockData._id, _id: undefined }
      yield put(setBlock({ ...pendingProcessData.data, blockData: blockData }));
      yield put(removePendingProcessData({ id }));
    }
  });
}

function* blockSaga() {
  yield all([handleSavingAction(), handleCallBack()]);
}

export default blockSaga;
