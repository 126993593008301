import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useAppTranslation = () => {
  const { t } = useTranslation();

  const translation = useCallback(
    (keyword: string, replace?: object) => {
      let result = t(keyword);
      if (!replace) return result;
      for (let [key, value] of Object.entries(replace)) {
        result = result
          .replace(":" + key, value)
          .replace("{" + key + "}", value);
      }
      return result;
    },
    [t]
  );

  return { t: translation };
};
