import { createSlice } from "@reduxjs/toolkit";
import { CreatorProfileInterface } from "./interface";
import { INITIAL_ONBOARDING } from "@share/configs/const";

const initialState: CreatorProfileInterface = {
  finish_product_tour: null,
  finish_product_tour_at: null,
  finish_onboard: INITIAL_ONBOARDING,
  finish_onboard_at: null,
  user_id: null,
  categories: [],
  selected_template_id: null,
  createdAt: null,
  country: null,
  username: null,
  background: {
    color: null,
    gradient: null,
    type: null,
    color_type: null,
  },
  banner: null,
  image: null,
  name: null,
  slug: null,
  last_publish_at: null,
  id: null,
  categoriesInitial: [],
};

export const creatorProfileSlice = createSlice({
  name: "creatorProfile",
  initialState,
  reducers: {
    setCreatorProfile: (state, { payload: { data } }) => {
      return { ...state, ...data };
    },
  },
});

export const { setCreatorProfile } = creatorProfileSlice.actions;
export default creatorProfileSlice.reducer;
