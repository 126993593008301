import { Slider } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

const SpacerSetting = (props) => {
  const { onUpdateSettingData, spacing_value } = props;
  const { t } = useTranslation();
  return (
    <div className="mb-3 bg-white px-4 py-3">
      <div className="mb-4 flex flex-row justify-between items-center">
        <span className="text-[15px]">{t("ss_builder_spacer_label")}</span>
        <span className="text-[13px] text-[#EE3244]">{spacing_value || 4}</span>
      </div>
      <Slider
        popover
        onChange={(val) =>
          onUpdateSettingData("spacing_value", parseInt(val.toString()))
        }
        // ticks={true}
        marks={{
          4: 4,
          6: "",
          8: "",
          10: "",
          12: "",
          14: "",
          16: "",
          18: "",
          20: "",
          22: "",
          24: "",
          26: "",
          28: "",
          30: "",
          32: "",
          34: "",
          36: "",
          38: "",
          40: "",
          42: "",
          44: "",
          46: "",
          48: "",
          50: "",
          52: "",
          54: "",
          56: "",
          58: "",
          60: "",
          62: "",
          64: 64,
        }}
        min={4}
        step={1}
        max={64}
        value={spacing_value}
      />
    </div>
  );
};

export default SpacerSetting;
