import { MAX_PRODUCTS_ALL_GROUPS, PRODUCT_MAX } from "@share/configs/const";
import { AddIcon, InformationTriangleIcon, PackageIcon } from "@share/icons";
import { Button, Modal } from "antd-mobile";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import React, { useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import ProductListItems from "../ProductListItems";
import cls from "classnames";
import { AFFILIATE_SETTING_TOUR_CLASS_NAME } from "../AffiliateProductTourOpening/ProductSettingTour/setting-tour-config";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import { getPlaceholderProps } from "@share/lib";

const AffiliateProductLists = (props) => {
  const {
    affiliateProductGroupItem,
    callbackOpenAddAffiliateProduct,
    callbackOpenEditProduct,
    callBackMoveProductItem,
    callBackUpdateAffiliateProductGroupData,
    callbackOpenAffiliateProductSelection,
    isSticky,
    isFulledProductsAllGroups,
    isEnableSticky,
    stickyTop,
    isOnlyProductGroup,
  } = props;
  const { t } = useTranslation();
  const [placeholderProps, setPlaceholderProps] = useState({});

  const affiliateProductArr = useMemo(
    () => affiliateProductGroupItem?.content_attributes?.items ?? [],
    [affiliateProductGroupItem]
  );

  const isAllowedToAddProducts = useMemo(
    () =>
      affiliateProductArr?.filter((x) => x?.is_cleanup_lock === 1)?.length <
      PRODUCT_MAX,
    [affiliateProductArr]
  );

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(affiliateProductArr);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedProductList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedProductList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    const newAffiliateProductGroupData = {
      ...affiliateProductGroupItem,
      content_attributes: {
        ...affiliateProductGroupItem.content_attributes,
        items: itemsOrder,
      },
    };
    callBackUpdateAffiliateProductGroupData(newAffiliateProductGroupData);
  };

  const onRemoveProductItem = (index: number) => {
    const affiliateProductClone = cloneDeep(affiliateProductArr);
    const newProductItems =
      affiliateProductClone &&
      Array.isArray(affiliateProductClone) &&
      affiliateProductClone.filter((ele: any, idx: number) => idx !== index);
    const newProductItemsSortOrder = newProductItems?.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    const newAffiliateProductGroupData = {
      ...affiliateProductGroupItem,
      content_attributes: {
        ...affiliateProductGroupItem.content_attributes,
        items: newProductItemsSortOrder,
      },
    };
    callBackUpdateAffiliateProductGroupData(newAffiliateProductGroupData);
  };

  const onDisableEnableProduct = (index: number, productStatus: boolean) => {
    const currentTime = new Date();
    const updatedAtTime = currentTime.toISOString();
    const affiliateProductClone = cloneDeep(affiliateProductArr);
    const newProductItems =
      affiliateProductClone &&
      Array.isArray(affiliateProductClone) &&
      affiliateProductClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              enable: productStatus,
              updatedAt: updatedAtTime,
            }
          : ele;
      });
    const newAffiliateProductGroupData = {
      ...affiliateProductGroupItem,
      content_attributes: {
        ...affiliateProductGroupItem.content_attributes,
        items: newProductItems,
      },
    };
    callBackUpdateAffiliateProductGroupData(newAffiliateProductGroupData);
  };

  const productRate = useMemo(() => {
    return `${affiliateProductArr?.length}/${PRODUCT_MAX}`;
  }, [affiliateProductArr]);

  const handleOpenAddAffiliateProduct = () => {
    if (isFulledProductsAllGroups) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_product_total_limitation_warning_title")}
            </div>
            <div className="">
              {t("ss_builder_product_total_limitation_warning_description", {
                max_products_all_groups: MAX_PRODUCTS_ALL_GROUPS,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_confirm_replace_theme_template_understood"),
          },
        ],
      });
    } else if (!isAllowedToAddProducts) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_product_total_limitation_warning_title")}
            </div>
            <div className="">
              {t("ss_builder_product_group_limitation_warning_description", {
                max_products: PRODUCT_MAX,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_confirm_replace_theme_template_understood"),
          },
        ],
      });
    } else {
      callbackOpenAddAffiliateProduct();
    }
  };

  const handleOpenEditProduct = (productData) => {
    callbackOpenEditProduct(productData);
  };

  const onMoveProductItem = (productId) => {
    callBackMoveProductItem(productId);
  };

  const onMoveToTopProductItem = (productId) => {
    const affiliateProductClone = cloneDeep(affiliateProductArr);
    const productData = affiliateProductClone.find((x) => x.uid === productId);
    const productListsAfterRemoveItem =
      affiliateProductClone &&
      Array.isArray(affiliateProductClone) &&
      affiliateProductClone.filter((x) => x.uid !== productId);

    const newProductLists = [productData, ...productListsAfterRemoveItem];

    const newAffiliateProductGroupData = {
      ...affiliateProductGroupItem,
      content_attributes: {
        ...affiliateProductGroupItem.content_attributes,
        items: newProductLists?.map((ele, idx) => ({
          ...ele,
          sort_order: idx + 1,
        })),
      },
    };
    callBackUpdateAffiliateProductGroupData(newAffiliateProductGroupData);
  };

  const onMoveToBottomProductItem = (productId) => {
    const affiliateProductClone = cloneDeep(affiliateProductArr);
    const productData = affiliateProductClone.find((x) => x.uid === productId);
    const productListsAfterRemoveItem =
      affiliateProductClone &&
      Array.isArray(affiliateProductClone) &&
      affiliateProductClone.filter((x) => x.uid !== productId);

    productListsAfterRemoveItem.push(productData);

    const newAffiliateProductGroupData = {
      ...affiliateProductGroupItem,
      content_attributes: {
        ...affiliateProductGroupItem.content_attributes,
        items: productListsAfterRemoveItem?.map((ele, idx) => ({
          ...ele,
          sort_order: idx + 1,
        })),
      },
    };
    callBackUpdateAffiliateProductGroupData(newAffiliateProductGroupData);
  };

  const onCleanupLockProduct = (index, cleanupLockStatus) => {
    const affiliateProductClone = cloneDeep(affiliateProductArr);
    const newProductItems =
      affiliateProductClone &&
      Array.isArray(affiliateProductClone) &&
      affiliateProductClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              is_cleanup_lock: cleanupLockStatus,
            }
          : ele;
      });
    const newAffiliateProductGroupData = {
      ...affiliateProductGroupItem,
      content_attributes: {
        ...affiliateProductGroupItem.content_attributes,
        items: newProductItems,
      },
    };
    callBackUpdateAffiliateProductGroupData(newAffiliateProductGroupData);
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  return (
    <div>
      <div
        id={AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_6}
        className={cls(
          AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_6,
          "affiliate-product-list-header flex flex-row justify-between p-4",
          isEnableSticky && "sticky z-10"
        )}
        style={{
          backgroundColor: isSticky ? "white" : "",
          boxShadow: isSticky ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "",
          top: `${stickyTop}px`,
        }}
      >
        <div className="grow">
          {/* <Badge
            color="var(--adm-color-primary)"
            content={productRate}
            style={{ "--top": "50%", "--right": "-22px" }}
          > */}
          <div className="text-[15px] text-[#666666]">
            {/*<span className="text-red-600">*</span>*/}
            {t("ss_builder_affiliate_product_list_title")}
          </div>
          {/* </Badge> */}
        </div>
        <div className="flex flex-grow justify-end gap-2">
          <Button
            size="small"
            fill="solid"
            color="primary"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              padding: "3px 12px 3px 12px",
              opacity:
                isFulledProductsAllGroups || !isAllowedToAddProducts
                  ? "40%"
                  : "",
            }}
            onClick={handleOpenAddAffiliateProduct}
            disabled={affiliateProductArr.length >= PRODUCT_MAX}
          >
            <AddIcon className="w-[15px] h-[15px]" />
            <div>{t("ss_builder_add_new_label")}</div>
            <span>{productRate}</span>
          </Button>
          {affiliateProductArr?.length > 0 && (
            <Button
              fill="outline"
              color="primary"
              size="small"
              onClick={callbackOpenAffiliateProductSelection}
            >
              {t("ss_builder_select_label")}
            </Button>
          )}
        </div>
      </div>
      {affiliateProductArr?.length > 0 ? (
        <div className="px-4">
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragUpdate={onDragUpdate}
          >
            <Droppable droppableId={affiliateProductGroupItem?.uid}>
              {(provided, snapshot): JSX.Element => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="relative"
                >
                  {affiliateProductArr?.map((ele, index) => (
                    <Draggable
                      draggableId={
                        ele?.post_link?.id
                          ? `product-${ele?.post_link?.id}`
                          : `product-${ele?.uid}`
                      }
                      index={index}
                      key={
                        ele?.post_link?.id
                          ? `product-${ele?.post_link?.id}`
                          : `product-${ele?.uid}`
                      }
                    >
                      {(provided, snapshot): JSX.Element => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={cls(
                            "mt-2",
                            !ele?.title && "adm-item-error",
                            snapshot.isDragging && "adm-item-custom"
                          )}
                        >
                          <ProductListItems
                            isFinalItem={
                              index === affiliateProductArr?.length - 1
                            }
                            isOnlyItem={affiliateProductArr?.length === 1}
                            isOnlyProductGroup={isOnlyProductGroup}
                            index={index}
                            productData={ele}
                            onRemoveProductItem={onRemoveProductItem}
                            onDisableEnableProduct={onDisableEnableProduct}
                            callbackOpenEditProduct={handleOpenEditProduct}
                            onMoveProductItem={onMoveProductItem}
                            onMoveToTopProductItem={onMoveToTopProductItem}
                            onMoveToBottomProductItem={
                              onMoveToBottomProductItem
                            }
                            onCleanupLockProduct={onCleanupLockProduct}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                    <PlaceholderCustomDragging
                      placeholderProps={placeholderProps}
                      content={t("ss_builder_placeholder_dragging_content")}
                      style={{
                        backgroundColor: "rgba(230, 247, 255, 0.75)",
                        border: "1px dashed #1890FF",
                        borderRadius: "4px",
                        color: "#1890FF",
                      }}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div className="flex flex-col text-center items-center justify-center my-5">
          <span className="inline-block mb-2">
            <PackageIcon
              className="w-8 h-8"
              fillColor="#333333"
            />
          </span>
          <div className="text-[#333333] text-lg leading-6">
            {t("ss_builder_affiliate_product_list_empty_title")}
          </div>
          <div className="text-[#8C8C8C] text-xs leading-4">
            {t("ss_builder_affiliate_product_list_empty_content")}
          </div>
        </div>
      )}
    </div>
  );
};

export default AffiliateProductLists;
