import { requestValidateUrl } from "@share/api";
import {
  isPhoneNumber,
  isValidEmail,
  isValidQueryString,
  isValidUrl,
} from "@share/helper/validator";
import { LinkDataInterface, LinkTypeEnum } from "..";

interface ValidateLinkResultInterface {
  warning_key: string | null;
  error_key: string | null;
  target_url?: string;
  allow_aff?: boolean;
}

export const getIconName = (type) => {
  if (type == LinkTypeEnum.WEB) {
    return "globe.svg";
  } else if (type == LinkTypeEnum.PHONE) {
    return "phone.svg";
  } else if (type == LinkTypeEnum.EMAIL) {
    return "envelope.svg";
  } else if (type == LinkTypeEnum.SERVICE) {
    return "selfie.svg";
  }
  return "globe.svg";
};

export const getPlaceHolderKey = (type) => {
  if (type == LinkTypeEnum.WEB) {
    return "ss_builder_url_placeholder";
  } else if (type == LinkTypeEnum.PHONE) {
    return "ss_builder_phone_placeholder";
  } else if (type == LinkTypeEnum.EMAIL) {
    return "ss_builder_email_placeholder";
  } else if (type == LinkTypeEnum.SERVICE) {
    return "ss_builder_service_placeholder";
  }
  return "ss_builder_url_placeholder";
};

export const getLabelKey = (type, reduce = false) => {
  if (type == LinkTypeEnum.WEB) {
    return "ss_builder_link_type_web";
  } else if (type == LinkTypeEnum.PHONE) {
    if (reduce) {
      return "ss_builder_link_type_tel";
    }
    return "ss_builder_link_type_phone";
  } else if (type == LinkTypeEnum.EMAIL) {
    return "ss_builder_link_type_email";
  } else if (type == LinkTypeEnum.SERVICE) {
    return "ss_builder_link_type_service";
  }
  return "ss_builder_link_type_web";
};

export function validateEmailUrl(url: string) {
  if (url) {
    if (url.indexOf("?") === -1) {
      return isValidEmail(url.toLowerCase());
    }
    const data = url.split("?");
    return (
      isValidEmail(data[0].toLocaleLowerCase()) &&
      isValidQueryString(`${data[1]}`)
    );
  }
}

export const validateLink = async (
  data: LinkDataInterface,
  checkApiError = false
): Promise<ValidateLinkResultInterface> => {
  if (data.value) {
    let errorKey = null;
    let warningKey = null;
    const trimValue = data.value.replaceAll(" ", "");
    if (data.type == LinkTypeEnum.EMAIL) {
      if (!validateEmailUrl(trimValue)) {
        errorKey = "ss_builder_error_invalid_email_message";
      }
    }
    if (data.type == LinkTypeEnum.PHONE) {
      if (!isPhoneNumber(trimValue)) {
        errorKey = "ss_builder_error_invalid_phone_message";
      }
    }
    if (data.type == LinkTypeEnum.WEB) {
      if (!isValidUrl(trimValue)) {
        errorKey = "ss_builder_invalid_url";
      }
    }
    if (!errorKey && data.type == LinkTypeEnum.WEB) {
      const response = (await requestValidateUrl(trimValue)) as any;
      const isSuccess = response?.data?.success;
      if (isSuccess) {
        const isAccepted = response?.data?.data?.accepted;
        const targetUrl = response?.data?.data?.target_url;
        if (!isAccepted) {
          const warningCode = response?.data?.data?.warning_code;
          if (warningCode) {
            warningKey = `ss_validate_origin_url_warning_${warningCode}`;
          }
          return {
            error_key: null,
            warning_key: warningKey,
            target_url: targetUrl,
            allow_aff: isAccepted,
          };
        } else {
          return {
            error_key: null,
            warning_key: null,
            target_url: targetUrl,
            allow_aff: isAccepted,
          };
        }
      } else {
        const errorCode = response?.data?.error_code;
        let errorKey = null;
        if (errorCode) {
          errorKey = `ss_validate_origin_url_error_${errorCode}`;
        }
        return {
          error_key: checkApiError ? errorKey : null,
          warning_key: null,
        };
      }
    } else {
      return {
        error_key: errorKey,
        warning_key: null,
      };
    }
  }
  return {
    error_key: null,
    warning_key: null,
  };
};
