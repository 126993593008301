import React from "react";
import { Toast } from "antd-mobile";
import { CheckCircleIcon, ExclamationTriangleIcon } from "@share/icons";

export const useToast = () => {
  const SuccessToast = (label: string) =>
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block"
          fillColor="#00B578"
        />
      ),
      content: <div className="text-center">{label}</div>,
    });
  const FailedToast = (label: string) =>
    Toast.show({
      icon: (
        <ExclamationTriangleIcon
          className="text-center inline-block"
          fillColor="var(--adm-color-warning)"
          width={24}
          height={24}
        />
      ),
      content: <div className="text-center">{label}</div>,
    });
  return { SuccessToast, FailedToast };
};
