import { BackArrow } from "@features/setting/components/Icons";
import {
  AddIcon,
  InformationTriangleIcon,
  QuestionIcon,
  SampleSelectionIcon,
} from "@share/icons";
import { Button, Modal } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AffiliateProductGroupAddNew from "./AffiliateProductGroupAddNew";
import AffiliateProductGroupListItems from "./AffiliateProductGroupListItems";
import cloneDeep from "lodash/cloneDeep";
import { AffiliateProductInterface } from "@features/main/store/interface";
import PopupComponent from "@share/components/PopupComponent";
import AffiliateProductGroupTour from "./AffiliateProductGroupTour";
import { AFFILIATE_GROUP_MANAGEMENT_TOUR_CLASS_NAME } from "./AffiliateProductGroupTour/group-tour-config";
import { useAppDispatch } from "@app/hooks";
import { setDynamicParams } from "@features/main/store/dynamicSlice";

type Props = {
  onCancelAffiliateProductGroupManagement: any;
  onUpdateAffiliateProductGroup: any;
  affiliateProductGroupData: AffiliateProductInterface[];
  productGroupMaximum: number;
  onActiveProductGroup: any;
};

const AffiliateProductGroupManagement = (props: Props) => {
  const {
    onCancelAffiliateProductGroupManagement,
    onUpdateAffiliateProductGroup,
    affiliateProductGroupData,
    productGroupMaximum,
    onActiveProductGroup,
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isOpenAddNewProductGroup, setIsOpenAddNewProductGroup] =
    useState(false);
  const [affiliateProductGroupList, setAffiliateProductGroupList] = useState(
    affiliateProductGroupData
  );
  const [affiliateProductGroupDefault, setAffiliateProductGroupDefault] =
    useState(null);
  const [isOpenGroupManagementTour, setIsOpenGroupManagementTour] =
    useState(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const stickyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = stickyRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        // console.log(e);
        setIsSticky(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    observer.observe(ref);
    return () => observer.unobserve(ref);
  }, [stickyRef]);

  useEffect(() => {
    dispatch(
      setDynamicParams({
        data: { isOpenAffiliateGroupTour: isOpenGroupManagementTour },
      })
    );
    const timer = setTimeout(() => {
      const settingTourOverlay = document.getElementsByClassName(
        "introjs-overlay"
      )[0] as any;
      if (isOpenGroupManagementTour) {
        settingTourOverlay.style.display = "none";
      } else {
        settingTourOverlay?.remove();
      }
    }, 100);
    () => clearTimeout(timer);
  }, [isOpenGroupManagementTour]);

  const handleOpenAddNewProductGroup = () => {
    if (affiliateProductGroupData?.length >= productGroupMaximum) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_product_group_total_limitation_warning_title")}
            </div>
            <div className="">
              {t("ss_builder_product_group_total_limitation_warning_content", {
                group_quantity_maximum: productGroupMaximum,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_confirm_replace_theme_template_understood"),
          },
        ],
      });
    } else {
      setAffiliateProductGroupDefault(null);
      setIsOpenAddNewProductGroup(true);
    }
  };

  const handleSaveAffiliateProductGroupAddNew = (data) => {
    let affiliateProductGroupListClone = cloneDeep(affiliateProductGroupList);
    const checkExistProductIdx =
      affiliateProductGroupListClone &&
      affiliateProductGroupListClone.findIndex((x) => x.uid === data.uid);
    if (checkExistProductIdx >= 0) {
      affiliateProductGroupListClone[checkExistProductIdx] = data;
    } else {
      affiliateProductGroupListClone = [...affiliateProductGroupList, data];
    }
    const newAffiliateProductGroupDataSortOrder =
      affiliateProductGroupListClone.map((ele, idx) => ({
        ...ele,
        sort_order: idx + 1,
      }));
    setAffiliateProductGroupList(newAffiliateProductGroupDataSortOrder);
  };

  useEffect(() => {
    onUpdateAffiliateProductGroup(affiliateProductGroupList);
  }, [affiliateProductGroupList]);

  const handleUpdateAffiliateProductGroupList = (data) => {
    setAffiliateProductGroupList(data);
  };

  const handleEditAffiliateProductGroup = (data) => {
    setIsOpenAddNewProductGroup(true);
    setAffiliateProductGroupDefault(data);
  };

  const productGroupRate = useMemo(
    () => `${affiliateProductGroupList.length}/${productGroupMaximum}`,
    [affiliateProductGroupList, productGroupMaximum]
  );

  const popupAddNewTitle = affiliateProductGroupDefault
    ? t("ss_builder_edit_name_affiliate_product_group_title")
    : t("ss_builder_add_new_affiliate_product_group_btn_label");

  return (
    <div
      className="pb-5 relative"
      style={{
        pointerEvents: isOpenGroupManagementTour ? "none" : "unset",
      }}
    >
      <div
        className="text-lg text-[#333333] font-normal flex text-center items-center gap-1 p-3 bg-white sticky top-0 z-10"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <span
          onClick={() => onCancelAffiliateProductGroupManagement()}
          className="cursor-pointer"
        >
          <BackArrow />
        </span>
        <span className="flex-1 text-center">
          <span className="flex items-center justify-center">
            <SampleSelectionIcon className="w-[22px] h-[22px]" />
            {t("ss_builder_affiliate_product_group_management_title")}
          </span>
        </span>
        <span onClick={() => setIsOpenGroupManagementTour(true)}>
          <QuestionIcon
            width={20}
            height={20}
            className="cursor-pointer"
          />
        </span>
      </div>
      <div id="product-group-scroll-element"></div>
      <div
        className="h-[52px] absolute w-full z-[9] top-0 left-0"
        ref={stickyRef}
      ></div>
      <div
        className="affiliate-product-list-header flex flex-row justify-between py-4 px-3 sticky top-[52px] z-[9]"
        style={{
          backgroundColor: isSticky ? "white" : "",
          boxShadow: isSticky ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "",
        }}
      >
        <div className="grow">
          <div className="text-[15px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_affiliate_product_list_title")}
          </div>
        </div>
        <div className={AFFILIATE_GROUP_MANAGEMENT_TOUR_CLASS_NAME.STEP_1}>
          <Button
            size="small"
            fill="solid"
            color="primary"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              padding: "3px 12px 3px 12px",
              opacity:
                affiliateProductGroupList.length >= productGroupMaximum
                  ? "40%"
                  : "",
            }}
            onClick={handleOpenAddNewProductGroup}
          >
            <AddIcon className="w-[15px] h-[15px]" />
            <div>{t("ss_builder_add_new_label")}</div>
            <span>{productGroupRate}</span>
          </Button>
        </div>
      </div>
      <AffiliateProductGroupListItems
        affiliateProductGroupList={affiliateProductGroupList}
        onUpdateAffiliateProductGroupList={
          handleUpdateAffiliateProductGroupList
        }
        onEditAffiliateProductGroup={handleEditAffiliateProductGroup}
        onActiveProductGroup={onActiveProductGroup}
      />
      <PopupComponent
        visible={isOpenAddNewProductGroup}
        // onMaskClick={() => setIsOpenAddNewProductGroup(false)}
        title={popupAddNewTitle}
        onClose={() => setIsOpenAddNewProductGroup(false)}
      >
        <AffiliateProductGroupAddNew
          onCancelAffiliateProductGroupAddNew={() =>
            setIsOpenAddNewProductGroup(false)
          }
          affiliateProductGroupDefault={affiliateProductGroupDefault}
          onSaveAffiliateProductGroupAddNew={
            handleSaveAffiliateProductGroupAddNew
          }
          affiliateProductGroupList={affiliateProductGroupList}
        />
      </PopupComponent>
      {isOpenGroupManagementTour && (
        <AffiliateProductGroupTour
          handleHideProductGroupTour={() => setIsOpenGroupManagementTour(false)}
        />
      )}
    </div>
  );
};

export default AffiliateProductGroupManagement;
