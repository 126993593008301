export enum SelectionStateEnum {
  ENABLE = 1,
  DISABLE = 2,
  HIDDEN = 3,
}

export const SelectionState = {
  ENABLE: 1,
  DISABLE: 2,
  HIDDEN: 3,
};

export enum POSITION_TYPE {
  HEADER = "header",
  BODY = "body",
  FOOTER = "footer",
  EXPAND = "expand",
}

export enum ITEMS_MODE {
  DRAG,
  SELECT_ITEMS,
}
