import React, { useEffect, useState } from "react";
import { LIST_FRAME, TRANSPARENT_IMAGE } from "./const";
import { CheckCircleIconV2 } from "@share/icons";
import isBoolean from "lodash/isBoolean";
import { getHorizontalResizeUrl } from "@share/helper/image";

const ListFrame = (props) => {
  const { setFrame, frame, setUsingFromList, usingFromList, onClose } = props;
  const [frameSelected, setFrameSelected] = useState<any>(frame);
  useEffect(() => {
    if (isBoolean(usingFromList) && !usingFromList) setFrameSelected({});
  }, [usingFromList]);

  return (
    <div className="grid grid-cols-3 gap-3 p-3 pt-0 mb-5">
      {LIST_FRAME.map((item) => {
        return (
          <div
            className="relative w-full h-full"
            onClick={() => {
              setFrameSelected(item);
              setFrame(item);
              setUsingFromList(true);
              onClose();
            }}
          >
            <div
              style={{
                backgroundImage: `url(${getHorizontalResizeUrl(
                  item.urlEU,
                  160
                )}) , url(${TRANSPARENT_IMAGE})`,
                backgroundPosition: "left bottom, top left",
                backgroundRepeat: "no-repeat, repeat",
                backgroundSize: "cover, auto",
                aspectRatio: "1/1",
                borderRadius: "4px",
                border:
                  item.url === frame?.url && usingFromList
                    ? "2px solid #EE3244"
                    : "",
              }}
            ></div>

            {item.id === frameSelected?.id && (
              <div className="absolute top-2 right-2 rounded-full bg-[#EE3244]">
                <CheckCircleIconV2
                  className="w-5 h-5 "
                  fillColor="white"
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ListFrame;
