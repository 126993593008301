import { useAppSelector } from "@app/hooks";
import FullScreenPopup from "@share/components/full-screen-popup";
import PopupComponent from "@share/components/PopupComponent";
import { MAX_PRODUCTS_ALL_GROUPS } from "@share/configs/const";
import { CheckCircleIcon } from "@share/icons";
import { Toast } from "antd-mobile";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AffiliateProductAddNew from "../AffiliateProductAddNew";
import AffiliateProductEdit from "../AffiliateProductEdit";
import AffiliateProductLists from "../AffiliateProductLists";
import AffiliateProductMovingPanel from "../AffiliateProductMovingPanel";
import AffiliateProductSelection from "../AffiliateProductSelection";

const ProductListLayoutHandler = (props) => {
  const {
    affiliateProductGroupData,
    activeProductGroup,
    onUpdateProductGroupData,
    isSticky,
    productGroupMaximum,
    onCheckProductSelectionOpening,
    stickyTop,
  } = props;

  const { t } = useTranslation();

  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const isOpenAffiliateSettingTour = dynamicConfig?.isOpenAffiliateSettingTour;

  const [isOpenAffiliateProductSelection, setIsOpenAffiliateProductSelection] =
    useState(false);
  const [isOpenAddAffiliateProduct, setIsOpenAddAffiliateProduct] =
    useState(false);
  const [isOpenAffiliateProductEdit, setIsOpenAffiliateProductEdit] =
    useState(false);
  const [isOpenMoveAffiliateProductPanel, setIsOpenMoveAffiliateProductPanel] =
    useState(false);
  const [productEditData, setProductEditData] = useState(null);
  const [productSelectedId, setProductSelectedId] = useState(null);

  useEffect(() => {
    onCheckProductSelectionOpening &&
      onCheckProductSelectionOpening(isOpenAffiliateProductSelection);
  }, [isOpenAffiliateProductSelection]);

  const activedProductGroupData = useMemo(
    () => affiliateProductGroupData?.find((x) => x.uid === activeProductGroup),
    [affiliateProductGroupData, activeProductGroup]
  );

  const handleDeleteProducts = (data) => {
    const affiliateProductGroupDataClone = cloneDeep(affiliateProductGroupData);
    const activedAffiliateProductGroupData =
      affiliateProductGroupDataClone?.find((x) => x.uid === activeProductGroup);
    const newProductListsData =
      activedAffiliateProductGroupData?.content_attributes?.items?.filter(
        (y) => !data.includes(y.uid)
      );
    const newAffiliateProductGroupData = affiliateProductGroupDataClone?.map(
      (ele) => {
        return ele.uid === activeProductGroup
          ? {
              ...activedAffiliateProductGroupData,
              content_attributes: {
                ...activedAffiliateProductGroupData.content_attributes,
                items: newProductListsData,
              },
            }
          : ele;
      }
    );
    onUpdateProductGroupData(newAffiliateProductGroupData);
    setIsOpenAffiliateProductSelection(false);
  };

  const handleEnableProducts = (data) => {
    handleUpdateDataField(data, "enable", 1);
  };

  const handleHideProducts = (data) => {
    handleUpdateDataField(data, "enable", 2);
  };

  const handleCleanupUnlockProducts = (data) => {
    handleUpdateDataField(data, "is_cleanup_lock", 2);
  };

  const handleCleanupLockProducts = (data) => {
    handleUpdateDataField(data, "is_cleanup_lock", 1);
  };

  const handleEnableTrackingLinkProducts = (data) => {
    handleUpdateDataField(data, "use_aff_url", 1);
  };

  const handleMoveProducts = useCallback(
    (data, productGroupId) => {
      const affiliateProductGroupDataClone = cloneDeep(
        affiliateProductGroupData
      );
      const productsAddNew = affiliateProductGroupDataClone
        ?.find((a) => a.uid === activeProductGroup)
        ?.content_attributes?.items?.filter((x) => data?.includes(x.uid));
      //Add more products into the new product group
      const productGroupDataAfterAddingProductId =
        affiliateProductGroupDataClone.map((element) => {
          if (element.uid === productGroupId) {
            const productList = element?.content_attributes?.items;
            const newProductLists = [...productsAddNew, ...productList];
            const newProductListSortOrder = newProductLists.map(
              (ele, index) => ({
                ...ele,
                sort_order: index + 1,
              })
            );
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: newProductListSortOrder,
              },
            };
          } else return element;
        });

      //Remove product from the old product group
      const productGroupDataAfterRemovingProductId =
        productGroupDataAfterAddingProductId.map((element) => {
          if (element.uid === activeProductGroup) {
            const productList = element?.content_attributes?.items;
            const productListRemovedProductId = productList.filter(
              (x) => !data?.includes(x.uid)
            );
            const productListRemovedProductIdSortOrder =
              productListRemovedProductId.map((ele, index) => ({
                ...ele,
                sort_order: index + 1,
              }));
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: productListRemovedProductIdSortOrder,
              },
            };
          } else return element;
        });
      onUpdateProductGroupData(productGroupDataAfterRemovingProductId);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_move_product_successfully_label")}
          </div>
        ),
      });
      setIsOpenAffiliateProductSelection(false);
    },
    [affiliateProductGroupData, productSelectedId, activeProductGroup]
  );

  const handleUpdateDataField = (data, key, value) => {
    const affiliateProductGroupDataClone = cloneDeep(affiliateProductGroupData);
    const activedAffiliateProductGroupData =
      affiliateProductGroupDataClone?.find((x) => x.uid === activeProductGroup);
    const newProductListsData =
      activedAffiliateProductGroupData?.content_attributes?.items?.map(
        (ele) => {
          return data.includes(ele.uid)
            ? {
                ...ele,
                [key]: value,
              }
            : ele;
        }
      );
    const newAffiliateProductGroupData = affiliateProductGroupDataClone?.map(
      (ele) => {
        return ele.uid === activeProductGroup
          ? {
              ...activedAffiliateProductGroupData,
              content_attributes: {
                ...activedAffiliateProductGroupData.content_attributes,
                items: newProductListsData,
              },
            }
          : ele;
      }
    );
    onUpdateProductGroupData(newAffiliateProductGroupData);
    setIsOpenAffiliateProductSelection(false);
  };

  const allowedMovingAffiliateProductGroup = useMemo(
    () =>
      affiliateProductGroupData?.filter((x) => x.uid !== activeProductGroup),
    [affiliateProductGroupData, activeProductGroup]
  );

  const isFulledProductsAllGroups = useMemo(() => {
    let existedProductArr = [];
    affiliateProductGroupData.forEach((x) => {
      const affiliateProductArr = x?.content_attributes?.items || [];
      existedProductArr = [...existedProductArr, ...affiliateProductArr];
    });
    return !!(existedProductArr?.length >= MAX_PRODUCTS_ALL_GROUPS);
  }, [affiliateProductGroupData]);

  const handleOpenAddAffiliateProduct = () => {
    setIsOpenAddAffiliateProduct(true);
    setProductEditData(null);
  };

  const handleOpenEditProduct = (productData) => {
    setIsOpenAffiliateProductEdit(true);
    setProductEditData(productData);
  };

  const handleMoveAffiliateProductItem = (productId) => {
    setIsOpenMoveAffiliateProductPanel(true);
    setProductSelectedId(productId);
  };

  const handleCancelAffiliateProductMovingPanel = () => {
    setIsOpenMoveAffiliateProductPanel(false);
  };

  const handleConfirmAffiliateProductMovingPanel = useCallback(
    (productGroupId) => {
      let productDataAddNew = null;
      const affiliateProductGroupDataClone = cloneDeep(
        affiliateProductGroupData
      );
      affiliateProductGroupDataClone.forEach((element) => {
        if (element.uid === activeProductGroup) {
          const productList = element?.content_attributes?.items;
          productDataAddNew = productList.find(
            (x) => x.uid === productSelectedId
          );
        }
      });
      //Add more product into the new product group
      const productGroupDataAfterAddingProductId =
        affiliateProductGroupDataClone.map((element) => {
          if (element.uid === productGroupId) {
            const productList = element?.content_attributes?.items;
            productDataAddNew && productList.unshift(productDataAddNew);

            const newProductListSortOrder = productList.map((ele, index) => ({
              ...ele,
              sort_order: index + 1,
            }));
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: newProductListSortOrder,
              },
            };
          } else return element;
        });

      //Remove product from the old product group
      const productGroupDataAfterRemovingProductId =
        productGroupDataAfterAddingProductId.map((element) => {
          if (element.uid === activeProductGroup) {
            const productList = element?.content_attributes?.items;
            const productListRemovedProductId = productList.filter(
              (x) => x.uid !== productSelectedId
            );
            const productListRemovedProductIdSortOrder =
              productListRemovedProductId.map((ele, index) => ({
                ...ele,
                sort_order: index + 1,
              }));
            return {
              ...element,
              content_attributes: {
                ...element.content_attributes,
                items: productListRemovedProductIdSortOrder,
              },
            };
          } else return element;
        });
      onUpdateProductGroupData(productGroupDataAfterRemovingProductId);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_move_product_successfully_label")}
          </div>
        ),
      });
    },
    [affiliateProductGroupData, productSelectedId, activeProductGroup]
  );

  const onUpdateAffiliateProductGroupData = (data) => {
    const affiliateProductGroupDataClone = cloneDeep(affiliateProductGroupData);
    const newAffiliateProductGroupData = affiliateProductGroupDataClone?.map(
      (ele) => {
        return ele.uid === data.uid ? data : ele;
      }
    );
    onUpdateProductGroupData(newAffiliateProductGroupData);
  };

  const handleCancelAffiliateProductAddNew = () => {
    setIsOpenAddAffiliateProduct(false);
  };

  const handleSaveAffiliateProductAddNew = (data) => {
    onUpdateProductGroupData(data);
    setIsOpenAddAffiliateProduct(false);
  };

  return (
    <>
      {isOpenAffiliateProductSelection ? (
        <AffiliateProductSelection
          affiliateProductLists={
            activedProductGroupData?.content_attributes?.items
          }
          isSticky={isSticky}
          callBackCancelAffiliateProductSelection={() =>
            setIsOpenAffiliateProductSelection(false)
          }
          onDeleteProducts={handleDeleteProducts}
          onEnableProducts={handleEnableProducts}
          onHideProducts={handleHideProducts}
          onMoveProducts={handleMoveProducts}
          onCleanupLockProducts={handleCleanupLockProducts}
          onCleanupUnlockProducts={handleCleanupUnlockProducts}
          onEnableTrackingLinkProducts={handleEnableTrackingLinkProducts}
          allowedMovingAffiliateProductGroup={
            allowedMovingAffiliateProductGroup
          }
          isOnlyProductGroup={affiliateProductGroupData?.length === 1}
          stickyTop={stickyTop}
        />
      ) : (
        <AffiliateProductLists
          callbackOpenAddAffiliateProduct={() =>
            handleOpenAddAffiliateProduct()
          }
          callbackOpenEditProduct={handleOpenEditProduct}
          affiliateProductGroupItem={activedProductGroupData}
          callBackMoveProductItem={handleMoveAffiliateProductItem}
          callBackUpdateAffiliateProductGroupData={
            onUpdateAffiliateProductGroupData
          }
          callbackOpenAffiliateProductSelection={() =>
            setIsOpenAffiliateProductSelection(true)
          }
          isSticky={isSticky}
          isFulledProductsAllGroups={isFulledProductsAllGroups}
          isOnlyProductGroup={affiliateProductGroupData?.length === 1}
          isEnableSticky={!isOpenAffiliateSettingTour}
          stickyTop={stickyTop}
        />
      )}
      <PopupComponent
        visible={isOpenMoveAffiliateProductPanel}
        onMaskClick={() => handleCancelAffiliateProductMovingPanel()}
        title={t("ss_builder_affiliate_product_moving_panel_title")}
        onClose={() => handleCancelAffiliateProductMovingPanel()}
      >
        <AffiliateProductMovingPanel
          callbackCancelAffiliateProductMovingPanel={
            handleCancelAffiliateProductMovingPanel
          }
          callbackConfirmAffiliateProductMovingPanel={
            handleConfirmAffiliateProductMovingPanel
          }
          allowedMovingAffiliateProductGroup={affiliateProductGroupData?.filter(
            (x) => x.uid !== activeProductGroup
          )}
          productMovingQuantity={1}
        />
      </PopupComponent>
      {isOpenAffiliateProductEdit && (
        <FullScreenPopup
          visible={isOpenAffiliateProductEdit}
          onMaskClick={() => setIsOpenAffiliateProductEdit(false)}
        >
          <AffiliateProductEdit
            productData={productEditData}
            activeProductGroup={activeProductGroup}
            callBackCancelAffiliateProductEdit={() =>
              setIsOpenAffiliateProductEdit(false)
            }
            callBackSaveAffiliateProductEdit={(val) =>
              onUpdateProductGroupData(val)
            }
            affiliateProductGroupData={affiliateProductGroupData}
            callbackAddNewAffiliateProductGroup={(val) =>
              onUpdateProductGroupData(val)
            }
            productGroupMaximum={productGroupMaximum}
          />
        </FullScreenPopup>
      )}
      {isOpenAddAffiliateProduct && (
        <FullScreenPopup
          visible={isOpenAddAffiliateProduct}
          onMaskClick={() => {
            setIsOpenAddAffiliateProduct(false);
          }}
        >
          <AffiliateProductAddNew
            callbackCancelAffiliateProductAddNew={
              handleCancelAffiliateProductAddNew
            }
            callbackSaveAffiliateProductAddNew={
              handleSaveAffiliateProductAddNew
            }
            affiliateProductGroupData={affiliateProductGroupData}
            activeProductGroup={activeProductGroup}
            callbackAddNewAffiliateProductGroup={(val) =>
              onUpdateProductGroupData(val)
            }
            productGroupMaximum={productGroupMaximum}
          />
        </FullScreenPopup>
      )}
    </>
  );
};

export default ProductListLayoutHandler;
