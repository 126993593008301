import React, { useEffect, useRef, useState } from "react";

interface Props {
  currentStickyElementId?: string;
  children: any;
  heightStickyCondition?: number;
  onCheckSticky?: (val) => void;
  previousStickyElementId?: string;
  stickyStyles?: Object;
}

const StickyChecking = ({
  children,
  heightStickyCondition,
  onCheckSticky,
  previousStickyElementId,
  currentStickyElementId,
  stickyStyles,
}: Props) => {
  const stickyRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [previousStickyElementTop, setPreviousStickyElementTop] = useState(0);
  const [previousStickyElementHeight, setPreviousStickyElementHeight] =
    useState(0);

  useEffect(() => {
    onCheckSticky && onCheckSticky(isSticky);
  }, [isSticky]);

  useEffect(() => {
    const ref = stickyRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    observer.observe(ref);
    return () => observer.unobserve(ref);
  }, [stickyRef.current]);

  useEffect(() => {
    if (previousStickyElementId) {
      setTimeout(() => {
        const previousStickyElement = document.querySelector(
          `#${previousStickyElementId}`
        ) as any;
        const previousStickyElementHeight =
          previousStickyElement?.getBoundingClientRect()?.height ||
          previousStickyElement?.clientHeight;
        const previousStickyElementTop =
          previousStickyElement?.getBoundingClientRect()?.top ||
          parseFloat(previousStickyElement?.style?.top);
        if (previousStickyElementHeight)
          setPreviousStickyElementHeight(previousStickyElementHeight);
        if (previousStickyElementTop)
          setPreviousStickyElementTop(previousStickyElementTop);
      }, 100);
    }
  });

  const heightStickyChecking =
    heightStickyCondition ||
    previousStickyElementHeight + previousStickyElementTop;

  return (
    <>
      <div
        ref={stickyRef}
        className="absolute left-0 w-1"
        style={{
          height: `${heightStickyChecking}px`,
          top: `-${heightStickyChecking}px`,
        }}
      ></div>
      <div
        id={currentStickyElementId}
        className="sticky z-10"
        style={{
          backgroundColor: isSticky ? "white" : "",
          boxShadow: isSticky ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "",
          top: `${heightStickyChecking}px`,
          ...stickyStyles,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default StickyChecking;
