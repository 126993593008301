import { ClosePopupIcon } from "@share/icons";
import React from "react";

interface HeaderTitleInterface {
  title: string;
  enableClose?: boolean;
  onClose?: () => void;
}

export default function HeaderTitle(props: HeaderTitleInterface) {
  const { title, enableClose = true, onClose = () => {} } = props;
  return (
    <div className="panel-header px-2 py-3 text-center relative items-center border-b">
      <span className="text-[15px]">{title}</span>
      {enableClose ? (
        <span
          className="cursor-pointer absolute right-[12px] top-[50%] translate-y-[-50%] tr p-1"
          onClick={onClose}
        >
          <ClosePopupIcon />
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}
