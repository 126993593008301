import { Button } from "antd-mobile";
import { useTranslation } from "react-i18next";

export default function ButtonConfirmPopup(props) {
  const { t } = useTranslation();
  const {
    onConfirm,
    isDisable = false,
    title = t("ss_builder_popup_confirm_button"),
    loading = false,
  } = props;

  return (
    <div className="btn-popup-confirm">
      <Button
        block
        color="primary"
        style={{
          fontSize: "17px",
        }}
        onClick={onConfirm}
        disabled={isDisable}
        loading={loading}
      >
        {title}
      </Button>
    </div>
  );
}
