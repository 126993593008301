import Donation from "@features/mini-store/block/Donation";
import DonationDesign from "@features/mini-store/design/DonationDesign";
import TruthOrDare from "@features/mini-store/block/TruthOrDare";
import BlockTruthOrDare from "@features/mini-store/design/BlockTruthOrDare";
import TruthOrDareFooter from "@features/mini-store/block/TruthOrDareFooter";
import BrandGiftFooter from "@features/mini-store/block/BrandGiftFooter";
import GiftFooter from "@features/mini-store/block/GiftFooter";
import BlockVirtualGiftFooter from "@features/mini-store/design/BlockVirtualGiftFooter";
import BlockTruthOrDareFooter from "@features/mini-store/design/BlockTruthOrDareFooter";
import BlockBrandGiftFooter from "@features/mini-store/design/BlockBrandGiftFooter";
import { DonationMiddleWare } from "@features/mini-store/middleware/DonationMiddleWare";
import { TODMiddleWare } from "@features/mini-store/middleware/TODMiddleWare";
import BrandGift from "@features/mini-store/block/BrandGift";
import BlockBrandGift from "@features/mini-store/design/BlockBrandGift";
import { BrandGiftMiddleWare } from "@features/mini-store/middleware/BrandGiftMiddleWare";
import BrandRating from "@features/mini-store/block/BrandRating";
import BlockBrandRating from "@features/mini-store/design/BlockBrandRating";

export const MINI_STORE_BLOCK_TYPE = {
  DONATION: "donation",
  TRUTH_OR_DARE: "tod",
  TRUTH_OR_DARE_FOOTER: "tod_footer",
  GIFT_FOOTER: "gift_footer",
  BRAND_GIFT: "brand_gift",
  BRAND_GIFT_FOOTER: "brand_gift_footer",
  BRAND_RATING: "brand_rating",
};
export const MINI_STORE_DESIGN_COMPONENT = {
  [MINI_STORE_BLOCK_TYPE.DONATION]: DonationDesign,
  // [MINI_STORE_BLOCK_TYPE.TRUTH_OR_DARE]: BlockTruthOrDare,
  [MINI_STORE_BLOCK_TYPE.GIFT_FOOTER]: BlockVirtualGiftFooter,
  // [MINI_STORE_BLOCK_TYPE.TRUTH_OR_DARE_FOOTER]: BlockTruthOrDareFooter,
  [MINI_STORE_BLOCK_TYPE.BRAND_GIFT]: BlockBrandGift,
  [MINI_STORE_BLOCK_TYPE.BRAND_GIFT_FOOTER]: BlockBrandGiftFooter,
  [MINI_STORE_BLOCK_TYPE.BRAND_RATING]: BlockBrandRating,
};

export const MINI_STORE_BLOCK = {
  [MINI_STORE_BLOCK_TYPE.DONATION]: Donation,
  // [MINI_STORE_BLOCK_TYPE.TRUTH_OR_DARE]: TruthOrDare,
  // [MINI_STORE_BLOCK_TYPE.TRUTH_OR_DARE_FOOTER]: TruthOrDareFooter,
  [MINI_STORE_BLOCK_TYPE.GIFT_FOOTER]: GiftFooter,
  [MINI_STORE_BLOCK_TYPE.BRAND_GIFT]: BrandGift,
  [MINI_STORE_BLOCK_TYPE.BRAND_GIFT_FOOTER]: BrandGiftFooter,
  [MINI_STORE_BLOCK_TYPE.BRAND_RATING]: BrandRating,
};
export const MINI_STORE_MIDDLEWARE = {
  [MINI_STORE_BLOCK_TYPE.DONATION]: DonationMiddleWare,
  // [MINI_STORE_BLOCK_TYPE.TRUTH_OR_DARE]: TODMiddleWare,
  [MINI_STORE_BLOCK_TYPE.BRAND_GIFT]: BrandGiftMiddleWare,
};
