import FormInput from "@features/block/components/FormInput";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import { ImageInterface } from "@features/main/store/interface";
import { ENABLE_BLOCK, LIST_ECOMMERCE, STATUS } from "@share/configs/const";
import { Button, Divider } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import CheckBoxWithTooltip from "./components/CheckBoxWithTooltip";
import ImageUpload, { OutputImg } from "./components/ImageUpload";

export interface ImagePopup extends Omit<ImageInterface, "image_description"> {
  is_open_new_tab: boolean;
  image_base64?: any;
  image_name?: string;
  link: LinkDataInterface;
}

interface ImagePopupFormProps {
  value?: ImagePopup;
  onCancel: () => void;
  onSubmit: (value: ImagePopup) => void;
}

const ImagePopupForm: React.FunctionComponent<
  React.PropsWithChildren<ImagePopupFormProps>
> = (props) => {
  const { value, onCancel, onSubmit } = props;
  const { t } = useTranslation();

  const defaultUseAffiliateUrl =
    value?.use_aff_url ??
    (Object.is(typeof value?.use_aff_url, "undefined")
      ? STATUS.ENABLE
      : STATUS.DISABLE);

  const defaultOpenNewTab = value?.is_open_new_tab;
  const defaultOriginUrl = value?.link?.value || "";
  const defaultLink = value?.link ?? {
    type: LinkTypeEnum.WEB,
    value: value?.link?.value || "",
  };
  const defaultImg = {
    url: value?.image,
  };

  const [useAffiliateUrl, setUseAffiliateUrl] = useState<STATUS>(
    defaultUseAffiliateUrl
  );
  const [isOpenNewTab, setIsOpenNewTab] = useState<boolean>(defaultOpenNewTab);
  const [link, setLink] = useState<LinkDataInterface>(defaultLink);
  const [image, setImage] = useState<OutputImg>(defaultImg);

  const [isLoadingSubmitBtn, setIsLoadingSubmitBtn] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [originUrl, setOriginUrl] = useState<string>(defaultOriginUrl);
  const [isEcomerce, setIsEcomerce] = useState<boolean>(false);

  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    setOriginUrl(linkData.type == LinkTypeEnum.WEB ? linkData.value : null);
    setLink(linkData);
  };

  const handleChangeUseAffiliateUrl = (value) =>
    setUseAffiliateUrl(value ? STATUS.ENABLE : STATUS.DISABLE);

  const handleSubmitForm = () => {
    onSubmit({
      is_open_new_tab: isEcomerce ? undefined : !!isOpenNewTab,
      uid: value?.uid ?? `image-${uuidv4()}`,
      sort_order: value?.sort_order ?? 0,
      enable: value?.enable ?? ENABLE_BLOCK,
      image: image?.url ?? value?.image,
      image_base64: value?.image_base64 ?? image?.base64,
      image_name: value?.image_name ?? image?.file_name,
      use_aff_url: useAffiliateUrl,
      origin_url: link.value,
      target_url: link.value,
      link: link,
    });
  };

  useEffect(() => {
    let url =
      link && link.type == LinkTypeEnum.WEB ? link.target_url : originUrl;
    if (url) {
      const ecom =
        link && link.type == LinkTypeEnum.WEB && url && new URL(url)?.host;
      setIsEcomerce(LIST_ECOMMERCE.some((item) => ecom && ecom.includes(item)));
    }
  }, [originUrl, link]);

  const isImagePopupChanged = useMemo(() => {
    const isLinkChanged =
      JSON.stringify({
        value: link.value,
        type: link.type,
      }) !==
      JSON.stringify({
        value: defaultLink.value,
        type: defaultLink.type,
      });
    const isImgChanged = JSON.stringify(image) !== JSON.stringify(defaultImg);
    const isOpenNewTabChanged = isOpenNewTab !== defaultOpenNewTab;
    const isUseAffUrlChanged = useAffiliateUrl !== defaultUseAffiliateUrl;

    return (
      isLinkChanged || isImgChanged || isOpenNewTabChanged || isUseAffUrlChanged
    );
  }, [useAffiliateUrl, isOpenNewTab, image, link]);

  const isDisableSubmitBtn =
    !!!image?.url || isLoadingSubmitBtn || !isImagePopupChanged || isError;

  return (
    <div className="z-[2]">
      <div className="flex flex-row text-center items-center justify-center py-3 relative bg-white text-lg">
        <h5>{t("ss_builder_add_image_of_image_popup_tilte")}</h5>
      </div>
      <div className="mb-[12px]">
        <ImageUpload
          value={image}
          onChange={setImage}
        />
      </div>

      <div className="bg-white p-4">
        <FormInput
          label={t("ss_builder_button_link_icon_origin_url_label")}
          isRequire={false}
        >
          <LinkInput
            item={link}
            onChange={onUpdateLinkData}
            onLoading={setIsLoadingSubmitBtn}
            onError={(errorMessage) => {
              setIsError(!!errorMessage);
            }}
          />
        </FormInput>
        <Divider />
        {Object.is(link.type, LinkTypeEnum.WEB) && (
          <>
            <CheckBoxWithTooltip
              value={Object.is(useAffiliateUrl, STATUS.ENABLE)}
              onChange={handleChangeUseAffiliateUrl}
              title={t("ss_builder_use_affiliate_link_label")}
              tooltip={t("ss_builder_use_affiliate_link_description")}
            />
            {!isEcomerce && (
              <>
                <Divider />
                <CheckBoxWithTooltip
                  value={isOpenNewTab}
                  onChange={setIsOpenNewTab}
                  title={t("ss_builder_open_new_tab_label")}
                  tooltip={t("ss_builder_open_new_tab_description")}
                />
              </>
            )}
          </>
        )}
      </div>

      <div className="fixed bottom-0 p-2 flex max-w-[486px] w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={onCancel}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={isDisableSubmitBtn}
            color={"primary"}
            onClick={handleSubmitForm}
            loading={isLoadingSubmitBtn}
          >
            {t("ss_builder_editor_btn-save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImagePopupForm;
