import { useAppSelector } from "@app/hooks";
import React from "react";
import {
  BLOCK_SETTING_DEFAULT_VALUE,
  BACKGROUND_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import CurrentBlock from "./CurrentBlock/indesx";
import SpacerAndDividerSample from "./SpacerAndDividerSample";
import cls from "classnames";
import { LAYOUT_TYPE_ENUM } from "../DividerSetting";

const BACKGROUND_TYPE_IMAGE = "image";
const BACKGROUND_TYPE_SOLID = "solid";
const BACKGROUND_TYPE_GRADENT = "gradient";
const BACKGROUND_TYPE_COLOR = "color";

export default function Preview({ block }) {
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { background, block_setting } = siteSettings?.display_setting || {};
  const { layout_type } = block?.style_attributes || {};

  const {
    type = BACKGROUND_SETTING_DEFAULT_VALUE.BACKGROUND_TYPE,
    color = BACKGROUND_SETTING_DEFAULT_VALUE.COLOR_SOLID,
    gradient,
    image = BACKGROUND_SETTING_DEFAULT_VALUE.IMAGE,
    enable_overlay = BACKGROUND_SETTING_DEFAULT_VALUE.OVERLAY_ENABLE,
    overlay_color = BACKGROUND_SETTING_DEFAULT_VALUE.OVERLAY_COLOR,
    blur: blurBackgroundSetting = BACKGROUND_SETTING_DEFAULT_VALUE.BLUR,
  } = background || {};

  const { block_style } = block_setting || {};

  const {
    block_space = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE,
    outside_space = BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE,
  } = block_style || {};

  const styleBackgroundImage =
    type === BACKGROUND_TYPE_IMAGE
      ? {
          backgroundPosition: "center center",
          filter: `blur(${blurBackgroundSetting}px) `,
          WebkitFilter: `blur(${blurBackgroundSetting}px) `,
          width: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          height: `calc(100% + ${blurBackgroundSetting * 2}px) `,
          transform: `translate(-${blurBackgroundSetting}px, -${blurBackgroundSetting}px)`,
        }
      : "";
  const checkStyleBackgroundImage = () => {
    switch (type) {
      case BACKGROUND_TYPE_IMAGE:
        return `url("${image}")`;
      case BACKGROUND_TYPE_GRADENT:
        return gradient;
      default:
        return "";
    }
  };
  const styleOutsideSpace = outside_space
    ? {
        marginLeft: `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`,
        marginRight: `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`,
        width: `calc(100% - ${
          BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER * 2
        }px) `,
      }
    : "";

  return (
    <>
      <div
        className={cls(
          "bg-white mb-2 top-[45px] z-[11]",
          layout_type === LAYOUT_TYPE_ENUM.DEFAULTS && "sticky"
        )}
        style={{
          top: block.enable === 1 ? "45px" : "83px"
        }}
      >
        <div className="mb-3 pt-4 pb-4 w-full h-full relative overflow-hidden">
          {type === BACKGROUND_TYPE_IMAGE && enable_overlay === true ? (
            <div
              className="absolute top-0 left-[50%] translate-x-[-50%] block w-full h-full z-[1]"
              style={{ backgroundColor: overlay_color }}
            ></div>
          ) : (
            ""
          )}

          <div
            className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat object-cover"
            style={{
              backgroundColor:
                type === BACKGROUND_TYPE_SOLID || type === BACKGROUND_TYPE_COLOR
                  ? color
                  : "",
              backgroundImage: `${checkStyleBackgroundImage()}`,
              borderBottom: "1px solid var(--adm-color-border)",
              ...styleBackgroundImage,
            }}
          ></div>
          <div
            className="relative z-[3]"
            style={{ ...styleOutsideSpace }}
          >
            <CurrentBlock siteSettings={siteSettings} index={1}/>
            <div style={{ height: `${block_space / 2}px` }}></div>
            <SpacerAndDividerSample block={block} />
            <div style={{ height: `${block_space / 2}px` }}></div>
            <CurrentBlock siteSettings={siteSettings} index={2}/>
          </div>
        </div>
      </div>
    </>
  );
}
