export const HOME_PAGE_SSP =
  process.env.NEXT_PUBLIC_HOME_PAGE_SSP || "https://ssp.ecomobi.com";
export const PASSIO_HOME_PAGE =
  process.env.NEXT_PUBLIC_KEY_PASSIO_HOME_PAGE || "https://account.passio.eco/";
export const KEY_TOKEN =
  process.env.NEXT_PUBLIC_KEY_TOKEN || "passio_access_token";
export const KEY_GATEWAY_TOKEN =
  process.env.NEXT_PUBLIC_KEY_GATEWAY_TOKEN || "passio_gw_access_token";
export const PASSIO_MAIN_DOMAIN =
  process.env.NEXT_PUBLIC_PASSIO_MAIN_DOMAIN || "passio.eco";
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || "local";
