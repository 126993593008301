import React from "react";
import { Popup, SafeArea } from "antd-mobile";
import cls from "classnames";
import { MAX_WIDTH_CONTENT_LAYOUT } from "@share/configs/const";

export default function FullScreenPopup({
  visible,
  onMaskClick,
  children,
  position = "bottom",
  heightPopup = "100%",
  popupPanel = false,
  isLimitWidth = true,
  style = {},
  className = "",
  heightFitContent = false
}: {
  visible: boolean;
  onMaskClick?: () => void;
  [x: string]: any;
}) {
  let stylePopup = style;
  if (popupPanel) {
    stylePopup = {
      ...stylePopup,
      maxWidth: `${MAX_WIDTH_CONTENT_LAYOUT}px`,
      borderRadius: "8px 8px 0 0",
    };
  }

  return (
    visible && (
      <Popup
        visible={visible}
        onMaskClick={onMaskClick}
        position={position}
        className={
          popupPanel ? `${className}` : `${className} full-screen-popup`
        }
        bodyClassName={
          popupPanel
            ? "adm-popup-body-position-bottom-panel"
            : "full-screen-popup-body m-auto"
        }
        bodyStyle={{
          backgroundColor: "none",
        }}
      >
        <SafeArea position="top" />
        <div
          className={cls(
            "full-screen-popup-content bg-[#f5f5f5] m-auto relative"
          )}
          style={{
            overflow: "overlay",
            height: heightPopup ? heightPopup : "100%",
            ...stylePopup,
          }}
        >
          <div
            className={cls(
              isLimitWidth && "max-width-content-class",
              "w-full h-full m-auto",
              heightFitContent && 'h-fit'
            )}
          >
            {children}
          </div>
        </div>
        <SafeArea position="bottom" />
      </Popup>
    )
  );
}
