import { Divider, Input, Popover, TextArea } from "antd-mobile";
import styles from "./style.module.scss";
import cls from "classnames";
import { CSSProperties, ReactElement, useMemo } from "react";
import { InformationIcon } from "@share/icons";

type InputCustomInterface = {
  label?: string;
  placeholder: string;
  maxLength?: number;
  isMultiline?: boolean;
  hasBottomDivider?: boolean;
  classNameText?: string;
  error?: string;
  required?: boolean;
  styleLabel?: CSSProperties;
  onChange: (value: string) => void;
  type?: string;
  value?: string;
  numberLine?: number;
  showCountCharacter?: boolean;
  hasNoBorder?: boolean;
  onKeyDown?: (e: any) => void;
  extra?: string | ReactElement;
  textTooltip?: string;
  inputMode?:
    | "email"
    | "search"
    | "text"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal";
};

const InputCustom = ({
  label,
  placeholder,
  maxLength,
  required = false,
  isMultiline = false,
  hasBottomDivider = false,
  classNameText = "",
  error,
  styleLabel,
  onChange,
  type = "text",
  value,
  numberLine,
  showCountCharacter = false,
  hasNoBorder = false,
  onKeyDown,
  extra,
  textTooltip,
  inputMode = "text",
}: InputCustomInterface) => {
  const handleChange = (value) => {
    if (maxLength) {
      if (value?.length <= maxLength) {
        onChange(value?.substring(0, maxLength));
      }
    } else {
      onChange(value);
    }
  };

  const countCharacter = useMemo(() => {
    let result = "";
    if (maxLength && showCountCharacter)
      result = `${value?.length || 0}/${maxLength}`;
    return result;
  }, [maxLength, showCountCharacter, value]);

  return (
    <>
      <div
        className={cls(styles.inputCustom, {
          [styles.inputNoBorder]: hasNoBorder,
        })}
      >
        {label && (
          <div className="flex items-center">
            <div
              className="text-[15px] text-[#666666] mb-1"
              style={styleLabel}
            >
              {required && (
                <span className="text-[15px] text-[#FF3141] ml-[-7px] font-simsun">
                  *
                </span>
              )}
              {label}
            </div>
            {textTooltip && (
              <div className="ml-1 mt-[-5px]">
                <Popover
                  content={textTooltip}
                  placement="top-start"
                  mode="dark"
                  trigger="click"
                >
                  <InformationIcon
                    className="ml-1 w-[14px] h-[14px]"
                    onClick={() => {}}
                    fillColor="#1890FF"
                  />
                </Popover>
              </div>
            )}
          </div>
        )}

        {isMultiline && (
          <div className="flex flex-row items-center">
            <TextArea
              className={`${classNameText}`}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={handleChange}
              value={value}
              rows={numberLine}
              autoComplete="false"
            />
            {extra && <span className="lead-2">{extra}</span>}
          </div>
        )}
        {!isMultiline && (
          <div className="flex flex-row items-center">
            <Input
              className={`${classNameText}`}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={handleChange}
              type={type}
              value={value}
              autoComplete="false"
              autoCorrect="off"
              onKeyDown={onKeyDown}
              inputMode={inputMode}
            />
            {extra && <span className="lead-2">{extra}</span>}
          </div>
        )}
        {showCountCharacter && countCharacter && (
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right pt-1">
            {countCharacter}
          </div>
        )}
      </div>
      {error && (
        <div
          className="mt-1 text-[#FF3141] text-left "
          style={{ fontSize: 13 }}
        >
          {error}
        </div>
      )}
      {hasBottomDivider && (
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      )}
    </>
  );
};

export default InputCustom;
