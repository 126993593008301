import AutoScrollSelector from "@share/components/AutoScrollSelector";
import { DOMAIN_RESIZE_IMG, LABEL_PRODUCT } from "@share/configs/const";
import { copyToClipboard, openInNewBrowser } from "@share/helper/browser";
import { checkOpenNewTab } from "@share/helper/validator";
import {
  BroomLockIcon,
  CheckCircleIcon,
  CopyIcon,
  FavouriteIcon,
  GlobalIcon,
  HotIcon,
  InformationIcon,
  InformationTriangleIcon,
  QuestionCircleIcon,
  SaleIcon,
  TrashIcon,
  WarningIcon,
} from "@share/icons";
import { mockUpload } from "@share/lib";
import {
  Button,
  Checkbox,
  Divider,
  Ellipsis,
  ImageUploader,
  Input,
  Popover,
  Toast,
} from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AFFILIATE_ADD_NEW_TOUR_CLASS_NAME } from "../AffiliateProductAddNew/ProductAddNewTourOpening/affiliate-add-new-tour-config";
import AntImage from "@share/components/AntImage";
import cls from "classnames";
import { useAffiliateContext } from "@features/block/block-editor/AffiliateProductGroup/context";
import { getHorizontalResizeUrl } from "@share/helper/image";

export interface Product {
  id: string;
  origin_link: string;
  use_aff_url: number;
  shortern_link: string;
  name: string;
  title: string;
  image: string;
  warning_code?: string;
  error_code?: string;
  gen_link_err_code?: string;
  label_product?: string;
  open_new_tab?: boolean;
  image_base64?: string;
  is_cleanup_lock: number;
}

interface ProductItemProps {
  item: Product;
  index?: number;
  onUpdateName: (name: string) => void;
  onUpdateImage: (image: any) => void;
  onCheckUseAffiliateUrl: (checked: boolean) => void;
  onSelectLabelProduct: (labelProduct: any) => void;
  onCheckOpenNewTab: (openNewTab: boolean) => void;
  isValidFileFormat?: boolean;
  isValidFileSize?: boolean;
  onDelete?: () => void;
  isEdit?: boolean;
}

const PRODUCT_NAME_MAX_LENGTH = 250;

export default function ProductItem(props: ProductItemProps) {
  const {
    item,
    index,
    onUpdateName,
    onUpdateImage,
    onCheckUseAffiliateUrl,
    onCheckOpenNewTab,
    onSelectLabelProduct,
    isValidFileFormat = false,
    isValidFileSize = false,
    onDelete = false,
    isEdit = false,
  } = props;

  const { frameData, usingFrame } = useAffiliateContext();

  const { t } = useTranslation();
  const ref = useRef();
  const cleanupPopoverRef = useRef(null);
  const labelProductDefault = item?.label_product ?? [];
  const isCleanupLock = !!(item?.is_cleanup_lock === 1);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [labelProduct, setLabelProduct] = useState(labelProductDefault);

  const openInNewTabDefault = item?.open_new_tab || false;
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);
  const [isEcommerce, setIsEcommerce] = useState(false);

  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
    onCheckOpenNewTab(data);
  };
  const checkEcomerce = (data) => {
    if (data) {
      try {
        const ecommerceOrSocial = new URL(data);
        setIsEcommerce(checkOpenNewTab(ecommerceOrSocial));
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    if (item?.origin_link) {
      checkEcomerce(item?.origin_link);
    }
  }, [item?.origin_link]);

  const copyLinkProduct = (value) => {
    copyToClipboard(value);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_personal_link_coppy_successfully_label")}
        </div>
      ),
    });
  };

  const displayLink = useMemo(() => {
    if (!item?.shortern_link) {
      return item.origin_link;
    }
    if (item.use_aff_url != 1) {
      return item.origin_link;
    }
    return item?.shortern_link;
  }, [item]);

  const productTitle = item?.name || item?.title;
  const labelProductOptions = [
    {
      label: (
        <div className="flex items-center gap-1">
          <HotIcon
            width={16}
            height={16}
          />
          {t("ss_builder_affiliate_product_label_product_hot")}
        </div>
      ),
      value: LABEL_PRODUCT.HOT,
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <SaleIcon
            width={16}
            height={16}
          />
          {t("ss_builder_affiliate_product_label_product_discount")}
        </div>
      ),
      value: LABEL_PRODUCT.SALE,
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <FavouriteIcon
            width={16}
            height={16}
          />
          {t("ss_builder_affiliate_product_label_product_like")}
        </div>
      ),
      value: LABEL_PRODUCT.FAVOURITE,
    },
  ];
  const handleSelectLabelProduct = (arr, extend) => {
    setLabelProduct(arr);
    onSelectLabelProduct(arr);
  };

  const imageResizing = useMemo(() => {
    if (!item?.image_base64 && item.image?.startsWith("https://passio-prod")) {
      return `${DOMAIN_RESIZE_IMG}/160x160/${
        item.image?.split("/passio-prod/")[1]
      }`;
    } else return item.image;
  }, [item.image, item.image_base64]);

  return (
    <div
      ref={ref}
      className={cls(isEdit && "pb-[100px]")}
    >
      <div className="px-3 py-5 flex flex-row gap-3 text-center items-center justify-between">
        <Ellipsis
          className="text-[#999999] text-[15px] leading-5 text-ellipsis"
          content={displayLink}
        />
        <div className="flex">
          <div
            onClick={() => copyLinkProduct(displayLink)}
            className="p-1 cursor-pointer mr-2"
          >
            <CopyIcon className="cursor-pointer" />
          </div>
          <div
            onClick={() => openInNewBrowser(displayLink)}
            className="mr-2 p-1 cursor-pointer"
          >
            <GlobalIcon className="cursor-pointer" />
          </div>
          {onDelete && (
            <div className="p-1 cursor-pointer mr-2">
              <TrashIcon
                // className="w-5 h-5 cursor-pointer"
                fillColor="#333"
                onClick={onDelete}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="p-[12px_16px] bg-[#FFFFFF] mx-3 rounded-lg mb-6"
        style={{
          border: productTitle ? "none" : "2px solid #ee3244ff",
        }}
      >
        <div
          className={index === 0 && AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_3}
        >
          <div>
            <div className="text-[15px] leading-[23px] text-[#666666]">
              <span className="text-red-600">*</span>
              {t("ss_builder_add_new_affiliate_product_name_title")}
            </div>
            <Input
              value={productTitle}
              onChange={(val) => {
                if (val?.length <= PRODUCT_NAME_MAX_LENGTH) onUpdateName(val);
              }}
              maxLength={PRODUCT_NAME_MAX_LENGTH}
              placeholder={t(
                "ss_builder_placeholder_affiliate_product_enter_product_name"
              )}
            />
            <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
              productTitle ? productTitle.length : 0
            }/${PRODUCT_NAME_MAX_LENGTH}`}</div>
            {!productTitle && (
              <span className="text-[#FF3141] mt-1 text-[13px] leading-[131%]">
                {t("ss_builder_product_title_missing_warning")}
              </span>
            )}
          </div>
          <Divider />
          <div>
            <div className="text-[15px] leading-[23px] text-[#666666] flex flex-row gap-1 text-center items-center">
              <div>{t("ss_builder_add_new_affiliate_product_image_title")}</div>
              <Popover
                content={t(
                  "ss_builder_affiliate_product_image_popup_infomation_label"
                )}
                trigger="click"
                placement="top"
                mode="dark"
                stopPropagation={["click"]}
                getContainer={() => ref.current}
              >
                <QuestionCircleIcon className="w-[15px] h-[15px] cursor-pointer" />
              </Popover>
            </div>
            <div>
              <div className="grid grid-cols-[120px_auto] gap-4">
                <div className="relative">
                  <AntImage
                    src={
                      imageResizing ||
                      "https://image.passio.eco/160x160/page-builder/page-builder/images-default/product-default.jpeg"
                    }
                    fit="cover"
                    style={{
                      borderRadius: "8px",
                    }}
                    width={120}
                    height={120}
                  />
                  {usingFrame && frameData && (
                    <div
                      className="absolute w-[120px] h-[120px] top-0 left-0"
                      style={{
                        background: `url("${getHorizontalResizeUrl(
                          frameData.urlEU,
                          160
                        )}") no-repeat  bottom left / contain`,
                        aspectRatio: "1/1",
                        borderRadius: "4px",
                      }}
                    ></div>
                  )}

                  {isCleanupLock && (
                    <Popover
                      content={t(
                        "ss_builder_affiliate_product_clean_up_popover_content"
                      )}
                      trigger="click"
                      placement="bottom"
                      mode="dark"
                      stopPropagation={["click"]}
                      getContainer={() => cleanupPopoverRef.current}
                    >
                      <div
                        className="absolute bottom-1 right-1 cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="relative rounded-ful">
                          <div className="p-3 rounded-full bg-black opacity-80"></div>
                          <span className="absolute z-[2] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                            <BroomLockIcon
                              className="w-[14px] h-[14px]"
                              fillColor="#FFFFFF"
                            />
                          </span>
                        </div>
                      </div>
                    </Popover>
                  )}
                </div>
                <div>
                  <div className="mb-12 leading-4">
                    {t("ss_builder_theme_setting_background_content")}
                  </div>
                  <div className="flex gap-2">
                    <ImageUploader
                      onChange={onUpdateImage}
                      upload={(file) => mockUpload(file, 514, 980)}
                      className="grow"
                      value={uploadedImageArr}
                      children={
                        <Button
                          color="primary"
                          fill="solid"
                          size="middle"
                          className="w-full"
                        >
                          {t("ss_builder_change_image_button_label")}
                        </Button>
                      }
                    />
                  </div>
                </div>
              </div>
              {isValidFileFormat && (
                <div className="flex flex-row gap-1 items-center mt-1">
                  <InformationTriangleIcon
                    fillColor="#FF3141"
                    className="w-4 h-4"
                  />
                  <div className="text-[#FF3141]">
                    {t("ss_builder_upload_file_format_invalid_content")}
                  </div>
                </div>
              )}
              {isValidFileSize && (
                <div className="flex flex-row gap-1 items-center mt-1">
                  <InformationTriangleIcon
                    fillColor="#FF3141"
                    className="w-4 h-4"
                  />
                  <div className="text-[#FF3141]">
                    {t("ss_builder_upload_file_size_invalid_content")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Divider />
        <div>
          <div className="text-[15px] text-[#666666] mb-1 leading-[150%]">
            {t("ss_builder_affiliate_product_label_product_title")}
          </div>
          <AutoScrollSelector
            options={labelProductOptions}
            onChange={handleSelectLabelProduct}
            isMultiple={true}
            value={labelProduct}
            className="auto-scroll-label-product"
          />
        </div>
        <Divider />
        <div className="flex flex-row text-center items-center leading-[22px] gap-1">
          <Checkbox
            checked={!!(item.use_aff_url === 1)}
            onChange={onCheckUseAffiliateUrl}
          >
            <div className="text-left">
              {t("ss_builder_use_affiliate_link_label")}
            </div>
          </Checkbox>
          <Popover
            content={t("ss_builder_use_affiliate_link_description")}
            placement="top-start"
            mode="dark"
            trigger="click"
            stopPropagation={["click"]}
            getContainer={() => ref.current}
          >
            <span className="w-[22px] h-[22px] flex justify-center items-center">
              <InformationIcon
                className="w-4 h-4 cursor-pointer"
                onClick={() => {}}
                fillColor="#1890FF"
              />
            </span>
          </Popover>
        </div>
        {!isEcommerce && (
          <>
            <Divider />
            <div className="flex flex-row text-center items-center leading-[22px] gap-1">
              <Checkbox
                checked={!!openNewTab}
                onChange={handleChangeOpenNewTab}
              >
                <div className="text-left">
                  {t("ss_builder_open_new_tab_label")}
                </div>
              </Checkbox>
              <Popover
                content={t("ss_builder_open_new_tab_description")}
                placement="top-start"
                mode="dark"
                trigger="click"
              >
                <InformationIcon
                  className="ml-1 w-[14px] h-[14px]"
                  onClick={() => {}}
                  fillColor="#1890FF"
                />
              </Popover>
            </div>
          </>
        )}
        {item.warning_code && (
          <>
            <Divider />
            <div className="flex flex-row text-left items-center text-[13px] leading-[17px] text-[#F59020] gap-2 bg-[#FFF9ED] p-[8px_12px] rounded">
              <InformationIcon
                className="w-[18px] h-[18px]"
                fillColor="#FF6010"
              />
              {t(`ss_validate_origin_url_warning_${item.warning_code}`)}
            </div>
          </>
        )}
        {(item?.gen_link_err_code || item?.error_code) && (
          <>
            <Divider />
            {item?.gen_link_err_code && (
              <div
                className="flex gap-[8px] font-normal leading-[21px] text-[15px] "
                style={{
                  border: "1px dashed #FFD591",
                  backgroundColor: "#FFF9ED",
                  color: "#FF6010",
                  borderRadius: "4px",
                  padding: "8px 12px",
                }}
              >
                <div className="my-auto">
                  <WarningIcon className="m-auto" />
                </div>
                {t(
                  `ss_builder_validate_gen_link_error_${item.gen_link_err_code}`
                )}
              </div>
            )}
            {item?.error_code && (
              <div className="text-[13px] leading-[17px] text-[#FF3141] ">
                {t(`ss_builder_validate_origin_url_error_${item.error_code}`)}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
