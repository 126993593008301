import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useDebounce, useIsFirstRender } from "usehooks-ts";
import LinkTypeSelectorPopup from "./components/LinkTypeSelectorPopup";
import ServiceSelectorPopup, {
  getServiceLabelKey,
  LinkServiceEnum,
} from "./components/ServiceSelectorPopup";
import IconRender from "@share/IconRender";
import {
  getIconName,
  getLabelKey,
  getPlaceHolderKey,
  validateLink,
} from "./helper";

const LINK_TYPE_WEB = "web";
const LINK_TYPE_PHONE = "phone";
const LINK_TYPE_EMAIL = "email";
const LINK_TYPE_SERVICE = "service";

export enum LinkTypeEnum {
  WEB = "web",
  PHONE = "phone",
  EMAIL = "email",
  SERVICE = "service",
}
export interface LinkDataInterface {
  type: LinkTypeEnum;
  value: string;
  target_url?: string | null;
  allow_aff?: boolean | null;
}

interface LinkInputInterface {
  item: LinkDataInterface;
  onChange: (value: LinkDataInterface) => void;
  onLoading?: (isLoading: boolean) => void;
  onError?: (errorMessage: string | null) => void;
  onWarning?: (warningMessage: string | null) => void;
  getPopupRef?: () => any;
  isHiddenService?: boolean;
}

const DownIcon = () => (
  <svg
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.62426 2.93668H1.37625C1.2327 2.93668 1.09351 2.98579 0.982157 3.07452C0.713341 3.28991 0.671584 3.68104 0.889072 3.94726L4.01308 7.76903C4.04092 7.80263 4.07136 7.83364 4.10616 7.86121C4.37498 8.07659 4.76907 8.03524 4.98743 7.76903L8.11056 3.94726C8.20104 3.83698 8.24976 3.69914 8.24976 3.55698C8.25062 3.21409 7.96963 2.93668 7.62426 2.93668Z"
      fill="#CCCCCC"
    />
  </svg>
);

const ArrowIcon = () => (
  <svg
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.79794 0.703245L7.85859 6.55293C8.04317 6.73671 8.05403 7.02795 7.89116 7.22437L7.85859 7.26004L1.79821 13.2937C1.77322 13.3185 1.7394 13.3325 1.70414 13.3325H0.661898C0.58826 13.3325 0.528564 13.2728 0.528564 13.1992C0.528564 13.1637 0.542692 13.1297 0.567822 13.1047L6.77307 6.92641L0.574246 0.894742C0.521469 0.843388 0.520316 0.758974 0.571669 0.706197C0.596771 0.6804 0.631236 0.665848 0.66723 0.665848H1.70534C1.73989 0.665848 1.77309 0.679255 1.79794 0.703245Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default function LinkInput(props: LinkInputInterface) {
  const {
    item,
    onChange,
    onError = () => {},
    onWarning = () => {},
    onLoading = () => {},
    isHiddenService = false,
  } = props;

  const { t } = useTranslation();
  const debounceValue = useDebounce(item, 1000);
  const containerRef = useRef();
  const { getPopupRef = () => containerRef.current } = props;
  const [showLinkSelectorPopup, setShowLinkSelectorPopup] = useState(false);
  const [showServiceSelectorPopup, setShowServiceSelectorPopup] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);

  const iconName = getIconName(item.type);
  const placeHolderKey = getPlaceHolderKey(item.type);
  const onUpdateType = (type) => {
    if (type != item.type) {
      onLoading(false);
      const value =
        type !== LinkTypeEnum.SERVICE ? "" : LinkServiceEnum.DONATION;
      onChange({ type: type, value: value });
    } else {
      onChange({ ...item, type: type });
    }
  };

  const valueDisplayElement = useMemo(() => {
    if (item.type !== LinkTypeEnum.SERVICE) {
      let type = 'url';
      if(item.type === LinkTypeEnum.EMAIL)
        type = 'email';
      if(item.type === LinkTypeEnum.PHONE)
        type = 'tel';

      return (
        <Input
          type = {type}
          placeholder={t(placeHolderKey)}
          value={item.value || ""}
          onChange={(val) => {
            onLoading(true);
            onChange({ ...item, value: val });
          }}
        />
      );
    }
    return (
      <span
        onClick={() => setShowServiceSelectorPopup(true)}
        className="text-lg w-full flex items-center justify-between pr-5"
      >
        <span>{t(getServiceLabelKey(item.value))}</span>
        <span>
          <ArrowIcon />
        </span>
      </span>
    );
  }, [item, placeHolderKey]);

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    if (debounceValue.value) {
      onLoading(true);
      validateLink(debounceValue).then((result) => {
        if (result.warning_key) {
          setWarningMessage(result.warning_key ? t(result.warning_key) : null);
        } else {
          setWarningMessage(null);
        }
        if (result.error_key) {
          setErrorMessage(result.error_key ? t(result.error_key) : null);
        } else {
          setErrorMessage(null);
        }
        onChange({
          ...item,
          target_url: result?.target_url || debounceValue.value,
          allow_aff: result?.allow_aff,
        });
        onLoading(false);
      });
    } else {
      setWarningMessage(null);
      setErrorMessage(null);
      onLoading(false);
    }
  }, [debounceValue.type, debounceValue.value]);

  useEffect(() => {
    onWarning(warningMessage);
  }, [warningMessage]);

  useEffect(() => {
    onError(errorMessage);
  }, [errorMessage]);

  return (
    <div ref={containerRef}>
      <div className="flex">
        <span
          className="flex items-center border-r cursor-pointer"
          onClick={() => setShowLinkSelectorPopup(true)}
        >
          <span className="mr-[6px]">
            <IconRender
              className="w-d h-4"
              containerClass="flex"
              iconName={iconName}
            />
          </span>
          <span className="mr-[6px] text-lg">
            {t(getLabelKey(item.type, true))}
          </span>
          <span className="mr-[6px]">
            <DownIcon />
          </span>
        </span>
        <span className="grow pl-3">{valueDisplayElement}</span>
      </div>
      {errorMessage && (
        <div className="text-[13px] leading-[17px] text-[#FF3141]">
          {errorMessage}
        </div>
      )}
      {warningMessage && (
        <div className="text-[13px] leading-[17px] text-[#F59020]">
          {warningMessage}
        </div>
      )}
      <LinkTypeSelectorPopup
        getContainer={getPopupRef}
        visible={showLinkSelectorPopup}
        onSelect={onUpdateType}
        onClose={() => setShowLinkSelectorPopup(false)}
        isHiddenService={isHiddenService}
      />
      <ServiceSelectorPopup
        defaultValue={item.value}
        getContainer={getPopupRef}
        visible={showServiceSelectorPopup}
        onSelect={(value) => onChange({ ...item, value: value })}
        onClose={() => setShowServiceSelectorPopup(false)}
      />
    </div>
  );
}
