import React, { useEffect } from "react";
import "../styles/globals.css";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "@app/i18n";
i18n.changeLanguage("en").then();

import store from "../app/store";
import { ENVIRONMENT } from "@share/configs/system";

const queryClient = new QueryClient();

export default function MyApp({ Component, pageProps }) {
  
  useEffect(() => {
    try {
      if (ENVIRONMENT == "production") {
        (function (h: any, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 3101377, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </QueryClientProvider>
  );
}