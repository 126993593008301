export const ACTION_KEY = {
    HIDE_DISPLAY: "hide-display",
    UP_FIRST: "up-first",
    DOWN_LAST: "down-last"
}

export const IFRAME_ACTION = {
    CLOSE_IFRAME: "close-iframe",
    LIST_DATA: "list-data",
    GET_DATA: "get-data",
    RECIVED_DATA: "received-data"
}

export const MOVE = {
    UP_FIRST: "up-first",
    DOWN_LAST: "down-last"
}