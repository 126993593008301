import { Toast } from "antd-mobile";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { MOVE } from "../../constants";
import { Image } from "antd-mobile";
import Item from "../Item";
import { CheckCircleIcon } from "@share/icons";

const BrandGiftListCate = (props) => {
  const {brandGiftArr, setBrandGiftArr, onUpdateData} = props;
  const { t } = useTranslation();

  const [popoverActive, setPopoverActive] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const newList = reorder(brandGiftArr, result.source.index, result.destination.index)
    setBrandGiftArr([...newList])
  }

  const handleMove = (id, move) => {
    const result = Array.from(brandGiftArr.filter(Boolean))
    const findIndex = result.findIndex((item: any) => item.category_id === id)
    const [removed] = result.splice(findIndex, 1)
    if (move === MOVE.UP_FIRST) result.splice(0, 0, removed)
    else result.splice(result.length, 0, removed)
    setPopoverActive(null)
    setBrandGiftArr([...result])
  }

  const handleHide = (id) => {
    const result = Array.from(brandGiftArr.filter(Boolean))
    const findIndex = brandGiftArr.find((item) => item.category_id === id)
    findIndex.category_enable = findIndex.category_enable === 1 ? 0 : 1
    setBrandGiftArr([...result])
    setPopoverActive(null)
    Toast.show({
      icon:  <CheckCircleIcon className="text-center inline-block w-[30px] h-[30px]" fillColor="#00B578"/>,
      content: (
        <div className="text-center">
          {findIndex.category_enable !== 1
            ? t("ss_brand_gift_disable_successfully")
            : t("ss_brand_gift_enable_successfully")}
        </div>
      ),
    });
  }

  const emtpy = () => {
    return (
        <div className="flex flex-col text-center items-center justify-center mt-4">
            <Image src="https://i.ecomobi.com/ssp/passio-page/empty_digital_product.png"/>
            <div className="text-[#333333] text-lg leading-6 pb-[2px]">
                {t("ss_builder_affiliate_product_list_empty_title")}
            </div>
            <div className="text-[#8C8C8C] text-xs leading-4">
                {t("ss_builder_affiliate_product_list_empty_content")}
            </div>
        </div>
        )
  }

  return (
    <div className="mt-3">
      <div className="p-3 bg-white">
        <div className="text-[#EE3244] text-[17px] font-normal border-b-2 border-[#EE3244] w-fit">
          {t('ss_brand_gift_list_category')}
        </div>
      </div>
      {(brandGiftArr && brandGiftArr?.length > 0) ? (
        <div className="flex flex-col gap-2 px-3 mt-3">
          <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId='droppable'>
              {droppableProvided => (
                  <div ref={droppableProvided.innerRef} className={`flex flex-col gap-2`}>
                  {brandGiftArr.filter(Boolean).map((item, index: number) => (
                      <Draggable key={item.category_id} draggableId={item.category_id.toString()} index={index}>
                      {(provided, snapshot) => (
                          <div 
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style, opacity: snapshot.isDragging ? 0.8 : 1 }}
                          >
                          <Item
                            item={item}
                            handleMove={handleMove}
                            popoverActive={popoverActive}
                            setPopoverActive={setPopoverActive}
                            number={index}
                            lastList={brandGiftArr.filter(Boolean).length -1}
                            handleHide={handleHide}
                            category={true}
                            onUpdateData={onUpdateData}
                            arr={brandGiftArr}
                            setArr={setBrandGiftArr}
                          />
                          </div>
                      )}
                      </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                  </div>
              )}
              </Droppable>
          </DragDropContext>
        </div>
        ) : emtpy()
      }
    </div>
  );
};

export default BrandGiftListCate;