import { LINK_STYLE_DISPLAY } from "@share/configs/const";
import { Image, Selector } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AutoScrollSelector from "@share/components/AutoScrollSelector";

const LinkStyleDisplay = (props) => {
  const { styleLinkDisplay, onUpdateStyleLinkDisplay, isMulti } = props;
  const { t } = useTranslation();
  const elementId = useMemo(() => {
    return "e" + Math.random().toString(36).slice(2, 7);
  }, []);
  const styleLinkOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/text-and-icon-style.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_style_tab_text_and_icon_label")}
        </div>
      ),
      value: LINK_STYLE_DISPLAY.TEXT_AND_ICON,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/text-and-image-style.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_style_tab_text_and_image_label")}
        </div>
      ),
      value: LINK_STYLE_DISPLAY.TEXT_AND_IMAGE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/only-text-style.png"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_style_tab_text_label")}</div>
      ),
      value: LINK_STYLE_DISPLAY.ONLY_TEXT,
    },
  ];

  const styleLinkOptionsForMulti = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/TextAndIconMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_STYLE_DISPLAY.TEXT_AND_ICON}
          className="mt-1"
        >
          {t("ss_builder_style_tab_text_and_icon_label")}
        </div>
      ),
      value: LINK_STYLE_DISPLAY.TEXT_AND_ICON,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/TextAndImageMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_STYLE_DISPLAY.TEXT_AND_IMAGE}
          className="mt-1"
        >
          {t("ss_builder_style_tab_text_and_image_label")}
        </div>
      ),
      value: LINK_STYLE_DISPLAY.TEXT_AND_IMAGE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/OnlyTextMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_STYLE_DISPLAY.ONLY_TEXT}
          className="mt-1"
        >
          {t("ss_builder_style_tab_text_label")}
        </div>
      ),
      value: LINK_STYLE_DISPLAY.ONLY_TEXT,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/OnlyIconMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_STYLE_DISPLAY.ONLY_ICON}
          className="mt-1"
        >
          {t("ss_builder_style_tab_only_icon")}
        </div>
      ),
      value: LINK_STYLE_DISPLAY.ONLY_ICON,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/OnlyImageMulti.png"
        />
      ),
      description: (
        <div
          id={elementId + "-" + LINK_STYLE_DISPLAY.ONLY_IMAGE}
          className="mt-1"
        >
          {t("ss_builder_style_tab_only_image")}
        </div>
      ),
      value: LINK_STYLE_DISPLAY.ONLY_IMAGE,
    },
  ];

  const handleSelectStyleLinkDisplay = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      onUpdateStyleLinkDisplay(value);
    }
  };

  return (
    <div>
      <div className="text-[15px] text-[#666666]">
        {/*{isMulti ? <span className="text-red-600">*</span> : null}*/}
        {t("ss_builder_style")}
      </div>
      {isMulti ? (
        <AutoScrollSelector
          elementId={elementId}
          options={styleLinkOptionsForMulti}
          value={styleLinkDisplay}
          onChange={handleSelectStyleLinkDisplay}
          isMulti
        />
      ) : (
        <Selector
          options={styleLinkOptions}
          value={[styleLinkDisplay]}
          onChange={handleSelectStyleLinkDisplay}
          columns={3}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      )}
    </div>
  );
};

export default LinkStyleDisplay;
