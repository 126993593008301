import UploadImage from "@share/components/UploadImage";
import { InformationTriangleIcon } from "@share/icons";
import { mockUpload } from "@share/lib";
import { Button, ImageUploader } from "antd-mobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

enum IMAGE_STATUS {
  INVALID_SIZE = "INVALID_SIZE",
  INVALID_FORMAT = "INVALID_FORMAT",
  NONE = "NONE",
  DONE = "DONE",
}

const RESIZE = {
  MAX_WIDTH: 512,
  MAX_HEIGHT: 512,
};

export type OutputImg = {
  base64?: any;
  url: string;
  file_name?: string;
};

interface ImageUploadProps {
  value: OutputImg;
  onChange: (img: OutputImg) => void;
}

const ImageUpload: React.FunctionComponent<
  React.PropsWithChildren<ImageUploadProps>
> = (props) => {
  const { t } = useTranslation();
  const { value, onChange } = props;

  const errorMsg = {
    INVALID_SIZE: t("ss_builder_upload_file_size_invalid_content"),
    INVALID_FORMAT: t("ss_builder_upload_file_format_invalid_content"),
  };

  const [imgStatus, setImgStatus] = useState<IMAGE_STATUS>(IMAGE_STATUS.NONE);
  const [listImg, setListImg] = useState([]);

  const handleChangeImgs = (imgs) => {
    const lastImg = imgs && imgs[imgs.length - 1];
    const isImgFormatInvalid = lastImg?.isValidFormat;
    const isImgSizeInvalid = lastImg?.isValidSize;

    if (isImgFormatInvalid) {
      setImgStatus(IMAGE_STATUS.INVALID_FORMAT);
    } else if (isImgSizeInvalid) {
      setImgStatus(IMAGE_STATUS.INVALID_SIZE);
    } else {
      setImgStatus(IMAGE_STATUS.DONE);
      setListImg(imgs);

      onChange({
        base64: lastImg.dataBase64,
        url: lastImg.url,
        file_name: lastImg.fileName,
      });
    }
  };

  return (
    <>
      <div className="p-4 bg-white mt-[1px]">
        <div className="text-[15px] text-[#666666]">
          <span className="text-red-600">*</span>
          {t("ss_builder_add_new_image_of_image_popup_title")}
        </div>
        <UploadImage
          valueUrlImage={value?.url ?? [...listImg]?.pop()?.url}
          imageRatio={"16/9"}
          // isUseBorderErr={true}
          // error={[
          // 	IMAGE_STATUS.INVALID_FORMAT,
          // 	IMAGE_STATUS.INVALID_SIZE,
          // ].includes(imgStatus)}
        />
        {errorMsg[imgStatus] && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">{errorMsg[imgStatus]}</div>
          </div>
        )}

        <div className="flex gap-2 mt-4 w-full">
          <ImageUploader
            onChange={handleChangeImgs}
            upload={(file) =>
              mockUpload(file, RESIZE.MAX_WIDTH, RESIZE.MAX_HEIGHT)
            }
            className="w-full"
            value={listImg}
            children={
              <Button
                block
                color="primary"
                fill="solid"
                size="middle"
                className="w-full grow"
              >
                {t("ss_builder_upload_button_label")}
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
