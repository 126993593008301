import { BLOCK_TYPE } from "@features/block/block.conf";
import { scrollToNewItem } from "@features/block/libs";
import PopupComponent from "@share/components/PopupComponent";
import StickyChecking from "@share/components/StickyChecking";
import { social_link_MAX } from "@share/configs/const";
import { AddIcon, ShareIcon } from "@share/icons";
import { Button } from "antd-mobile";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import React, { useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import SocialLinkAddNew from "../SocialLinkAddNew";
import SocialLinkItems from "../SocialLinkItems";
import cls from "classnames";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import { getPlaceholderProps } from "@share/lib";

type Props = {
  socialLinkListSortOrder: any;
  callBackUpdateSocialLinkItems: any;
};

const socialLinkList = (props: Props) => {
  const { socialLinkListSortOrder, callBackUpdateSocialLinkItems } = props;
  const { t } = useTranslation();
  const [placeholderProps, setPlaceholderProps] = useState({});

  const [isOpenSocialLinkAddNew, setIsOpenSocialLinkAddNew] = useState(false);
  const [socialLinkEditData, setSocialLinkEditData] = useState(null);

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(socialLinkListSortOrder);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedImageList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedImageList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    callBackUpdateSocialLinkItems(itemsOrder);
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  const handleOpenAddSocialLink = () => {
    setIsOpenSocialLinkAddNew(true);
  };

  const onRemoveItem = (index: number) => {
    const socialLinkListClone = cloneDeep(socialLinkListSortOrder);
    const newItems =
      socialLinkListClone &&
      Array.isArray(socialLinkListClone) &&
      socialLinkListClone.filter((ele: any, idx: number) => idx !== index);
    callBackUpdateSocialLinkItems(newItems);
  };
  const onDisableEnableSocialLink = (
    index: number,
    socialLinkStatus: number
  ) => {
    const socialLinkListClone = cloneDeep(socialLinkListSortOrder);
    const newItems =
      socialLinkListClone &&
      Array.isArray(socialLinkListClone) &&
      socialLinkListClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              enable: socialLinkStatus,
            }
          : ele;
      });
    callBackUpdateSocialLinkItems(newItems);
  };

  const handleConfirmSocialLinkAddNew = (data) => {
    const indexItemEdit =
      socialLinkListSortOrder &&
      socialLinkListSortOrder.findIndex((x) => x.uid === data.uid);
    const newSocialLinkArr = cloneDeep(socialLinkListSortOrder);
    if (indexItemEdit >= 0) {
      newSocialLinkArr[indexItemEdit] = data;
      callBackUpdateSocialLinkItems(newSocialLinkArr);
    } else {
      newSocialLinkArr.push(data);
      const newSocialLinkReSortOrder = newSocialLinkArr.map((ele, idx) => ({
        ...ele,
        sort_order: idx + 1,
      }));
      callBackUpdateSocialLinkItems(newSocialLinkReSortOrder);
      const index = newSocialLinkReSortOrder?.findIndex(
        (x) => x.uid === data?.uid
      );
      scrollToNewItem(`bio-${data?.uid}-${index}`);
    }
  };

  const handleOpenEditSocialLink = (data) => {
    setIsOpenSocialLinkAddNew(true);
    setSocialLinkEditData(data);
  };

  const handleCancelSocialLinkAddNew = () => {
    setIsOpenSocialLinkAddNew(false);
    setSocialLinkEditData(null);
  };

  const socialLinkRate = useMemo(
    () =>
      socialLinkListSortOrder &&
      `${socialLinkListSortOrder.length}/${social_link_MAX}`,
    [socialLinkListSortOrder]
  );

  return (
    <>
      <div className="relative">
        <StickyChecking previousStickyElementId="block-bio-editor-header">
          <div className="flex flex-row justify-between px-3 py-4">
            <div className="grow">
              <div className="text-[15px] text-[#666666]">
                {t("ss_builder_social_links_list_title")}
              </div>
            </div>
            <div className="flex flex-grow justify-end gap-2">
              <Button
                size="small"
                fill="solid"
                color="primary"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  padding: "3px 12px 3px 12px",
                  // opacity:
                  //   isFulledProductsAllGroups || !isAllowedToAddProducts
                  //     ? "40%"
                  //     : "",
                }}
                onClick={handleOpenAddSocialLink}
                disabled={socialLinkListSortOrder.length === social_link_MAX}
              >
                <AddIcon className="w-[15px] h-[15px]" />
                <div>{t("ss_builder_add_new_label")}</div>
                <span>{socialLinkRate}</span>
              </Button>
            </div>
          </div>
        </StickyChecking>
        {socialLinkListSortOrder?.length > 0 ? (
          <div className="px-3">
            <DragDropContext
              onDragEnd={onDragEnd}
              onDragUpdate={onDragUpdate}
            >
              <Droppable droppableId={`blocks-${BLOCK_TYPE.BIO}`}>
                {(provided, snapshot): JSX.Element => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="relative"
                  >
                    {socialLinkListSortOrder?.map((ele, index) => {
                      return (
                        <Draggable
                          draggableId={
                            ele?.uid
                              ? `bio-${ele?.uid}-${index}`
                              : `bio-${ele?.id}-${index}`
                          }
                          index={index}
                          key={
                            ele?.uid
                              ? `bio-${ele?.uid}-${index}`
                              : `bio-${ele?.id}-${index}`
                          }
                        >
                          {(provided, snapshot): JSX.Element => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={cls(
                                "mt-2",
                                snapshot.isDragging && "adm-item-custom"
                              )}
                            >
                              <SocialLinkItems
                                index={index}
                                socialLinkData={ele}
                                onRemoveItem={onRemoveItem}
                                onDisableEnableSocialLink={
                                  onDisableEnableSocialLink
                                }
                                callbackOpenEditSocialLink={
                                  handleOpenEditSocialLink
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                    {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                      <PlaceholderCustomDragging
                        placeholderProps={placeholderProps}
                        content={t("ss_builder_placeholder_dragging_content")}
                        style={{
                          backgroundColor: "rgba(230, 247, 255, 0.75)",
                          border: "1px dashed #1890FF",
                          borderRadius: "4px",
                          color: "#1890FF",
                        }}
                      />
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div className="flex flex-col text-center items-center justify-center">
            <span className="inline-block">
              <ShareIcon className="align-middle w-8 h-8" />
            </span>
            <div className="text-[#333333] text-lg leading-6">
              {t("ss_builder_empty_social_link_list_title")}
            </div>
            <div className="text-[#8C8C8C] text-xs leading-4">
              {t("ss_builder_empty_social_link_list_content")}
            </div>
          </div>
        )}
      </div>
      <PopupComponent
        visible={isOpenSocialLinkAddNew}
        onMaskClick={() => handleCancelSocialLinkAddNew()}
        title={t("ss_builder_bio_add_social_link_title")}
        onClose={() => handleCancelSocialLinkAddNew()}
      >
        <SocialLinkAddNew
          callbackCancelSocialLinkAddNew={handleCancelSocialLinkAddNew}
          callbackConfirmSocialLinkAddNew={handleConfirmSocialLinkAddNew}
          socialLinkEditData={socialLinkEditData}
        />
      </PopupComponent>
    </>
  );
};

export default socialLinkList;
