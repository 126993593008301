import { ICON_RENDER_URL } from "@share/configs/const";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as DOMPurify from "dompurify";
import { EmptyIcon } from "@share/icons/line-icon";
import cls from "classnames";

export enum TypeIconEnum {
  ICON = "icon",
  SETTING = "setting",
}

type Props = {
  iconName: string;
  fillColor?: string;
  className?: string;
  containerClass?: string;
  type?: TypeIconEnum;
};

const IconRender = (props: Props) => {
  const {
    iconName,
    fillColor,
    className,
    containerClass = "inline-block",
    type = TypeIconEnum.ICON,
  } = props;
  const [valueIcon, setValueIcon] = useState(null);

  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current && className) {
      const valueClassArr =
        typeof className === "string" && className.split(" ");
      valueClassArr.forEach(
        (ele) =>
          containerRef.current.firstElementChild &&
          containerRef.current.firstElementChild.classList.add(ele)
      );
    }
  }, [className, containerRef.current, valueIcon]);

  useEffect(() => {
    if (iconName) {
      fetch(`${ICON_RENDER_URL}/${iconName}`)
        .then((response) => {
          if (response.status && /^20[0-9]$/.test(response.status.toString())) {
            return response.text();
          } else return null;
        })
        .then((data) => {
          setValueIcon(data);
        })
        .catch((error) => console.log(error));
    }
  }, [iconName]);

  const valueIconFill = useMemo(() => {
    if (fillColor) {
      const pattern = new RegExp(/fill=\"[a-zA-Z0-9\#!]+\"/g);
      const result =
        valueIcon && valueIcon.replace(pattern, `fill=${fillColor}`);
      return result;
    } else {
      return valueIcon;
    }
  }, [valueIcon, fillColor]);
  const cleanValueIconFill = DOMPurify.sanitize(valueIconFill);

  return (
    <>
      {!cleanValueIconFill && type === TypeIconEnum.SETTING ? (
        <div
          ref={containerRef}
          className={cls(containerClass, "w-[24px] h-[24px]")}
          style={{
            opacity: "0.4",
          }}
        >
          <EmptyIcon />
        </div>
      ) : (
        <div
          ref={containerRef}
          className={cls(
            containerClass,
            !cleanValueIconFill ? "w-[24px] h-[24px]" : ""
          )}
          dangerouslySetInnerHTML={{ __html: cleanValueIconFill }}
        ></div>
      )}
    </>
  );
};

export default IconRender;
