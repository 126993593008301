import { InformationTriangleIcon } from "@share/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const AutoCleanupProductsWarning = ({ isShowWarning }) => {
  const { t } = useTranslation();
  return (
    <>
      {isShowWarning && (
        <div
          className="p-[12px_16px] bg-[#FFFFFF]"
          style={{
            border: "1px solid #EEEEEE",
          }}
        >
          <div
            className="flex flex-row text-left items-center text-[13px] leading-[17px] gap-2 bg-[#FFF9ED] p-3 rounded-lg"
            style={{
              border: "1px dashed #FFD591",
            }}
          >
            <span>
              <InformationTriangleIcon
                className="w-4 h-4"
                fillColor="#FF8F1F"
              />
            </span>
            <div>
              <div>
                {t("ss_builder_auto_cleanup_products_warning_content_1")}
              </div>
              <div>
                {t("ss_builder_auto_cleanup_products_warning_content_2")}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AutoCleanupProductsWarning;
