import { POSITION_TYPE } from "@features/block/config/interface";
import { MAX_NUMBER_BLOCK } from "@features/share/configs/const";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PositionOption from "./BlockLayoutComponent/PositionOption";

const AddNewBlock = (props) => {
  const { t } = useTranslation();
  const {
    position,
    onSelectBlock,
    tabUid,
    positionSlug,
    selectedBlock,
    isHideExpandPosition,
  } = props;

  const headerPosition = position?.find((x) => x.slug === POSITION_TYPE.HEADER);
  const bodyPosition = position?.find((x) => x.slug === POSITION_TYPE.BODY);
  const footerPosition = position?.find((x) => x.slug === POSITION_TYPE.FOOTER);
  const expandPosition = position?.find((x) => x.slug === POSITION_TYPE.EXPAND);

  const extraProps = {
    tab_uid: tabUid,
    position_slug: positionSlug,
  };

  const bodyTabs = position?.find((x) => x?.slug === POSITION_TYPE.BODY)
    ?.block_list[0]?.content_attributes?.tabs;

  const handleSelectPosition = (slug?: string, id?: string) => {
    let position = slug as POSITION_TYPE;
    let uid = "";

    if (Object.is(position, POSITION_TYPE.BODY)) {
      let defaultTab = bodyTabs && bodyTabs[0];
      uid = id ? id : defaultTab?.uid;
    }
    onSelectBlock({
      position,
      uid,
    });
  };

  const positionArrRender = useMemo(() => {
    const positionArrDefault = [headerPosition, bodyPosition, footerPosition];
    if (!isHideExpandPosition) {
      positionArrDefault.push(expandPosition);
    }
    return positionArrDefault;
  }, [
    headerPosition,
    bodyPosition,
    footerPosition,
    expandPosition,
    isHideExpandPosition,
  ]);

  return (
    <div className="floating-new-block-position adm-default h-full">
      <div className="px-5 pt-0 new-block-position overflow-auto">
        {positionArrRender.map((e) => (
          <PositionOption
            key={e.slug}
            position={{
              ...e,
              translated_title: t(`ss_builder_add_block_${e.slug}_title`),
              block_maximum: MAX_NUMBER_BLOCK[`${e.slug.toUpperCase()}`],
              limited_msg: t("ss_builder_limit_reached"),
            }}
            onSelect={handleSelectPosition}
            selected={selectedBlock}
            extraProps={extraProps}
          />
        ))}
      </div>
    </div>
  );
};

export default AddNewBlock;
