import { useAppTranslation } from "@features/mini-store/share/hook";
import BlockWrapper from "@features/design/components/BlockWrapper";
import { Block } from "@features/main/store/interface";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { openPopup } from "@features/block/store/edittingBlockSlice";
import { KEY_BLOCK } from "@share/configs/const";
import { getDetailBrandGift } from "@features/mini-store/share/api";
import { useQueries, useQuery } from "react-query";
import {
  BACK_GROUND_IMAGE,
  NAME_SERVICE,
} from "@features/mini-store/setting/constants";
import { Button, Loading } from "antd-mobile";
import { dataService } from "@share/configs/rxjs";
import { htmlDecode } from "@share/lib";
import { useSelector } from "react-redux";
import { selectProfile } from "@features/user/userSlice";
type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};
interface data {
  loading: boolean;
}
export default function BlockBrandGift(props: Props) {
  const profile = useSelector(selectProfile);
  const { t } = useAppTranslation();
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const dispatch = useAppDispatch();
  const enable = blockData && blockData.enable;
  const [loadApi, setLoadApi] = useState(true);
  const allowStore = window["AllowStore"];

  const loadDetailBrand = async () => {
    try {
      const response = await getDetailBrandGift(NAME_SERVICE.BRAND_GIFT);
      if (response) {
        setLoadApi(false);
        return response;
      }
    } catch (e) {
      return {};
    }
  };
  const [data] = useQueries([
    {
      queryKey: ["loadDetailBrand", loadApi],
      queryFn: loadDetailBrand,
      refetchOnWindowFocus: loadApi,
    },
  ]);
  useEffect(() => {
    const subscription = dataService.getObservable().subscribe((data: data) => {
      if (data.loading) {
        setLoadApi(true);
      }
    });
    return () => subscription.unsubscribe();
  }, []);
  const handlerClick = (event) => {
    if (event?.defaultPrevented) {
      return;
    }
    dispatch(
      openPopup({
        block: blockData,
        position_uid: positionSlug,
        tab_uid: tabUid,
        KEY: KEY_BLOCK.EDIT,
      })
    );
  };
  if (data?.isLoading) return <Loading />;
  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <div className={"text-center"}>
        <img
          width={512}
          src={BACK_GROUND_IMAGE.BRAND_GIFT}
          className={!allowStore?.allow_view_brand_gift && "opacity-50"}
          alt=""
        />
        {allowStore?.allow_view_brand_gift && (
          <>
            <h3>{htmlDecode(data?.data?.block_title)}</h3>
            <Button className="adm-button adm-button-default adm-button-shape-default w-full button-border-radius button-background-color button-border-width button-border-color button-border-style button-text-color button-box-shadow">
              {htmlDecode(data?.data?.button_text)}
            </Button>
          </>
        )}
      </div>
    </BlockWrapper>
  );
}
