import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, elementId }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return mounted
    ? createPortal(children, document.querySelector(`#${elementId}`))
    : null;
};

export default Portal;
