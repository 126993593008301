import MoreActionsCommon from "@features/block/components/MoreActionsCommon";
import { ProductInterface } from "@features/main/store/interface";
import React from "react";
import { STATUS } from "@share/configs/const";
import {
  BroomIcon,
  BroomLockIcon,
  CheckCircleIcon,
  DeleteIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  InformationIcon,
} from "@share/icons";
import { useTranslation } from "react-i18next";
import { ActionEnum } from "../../ProductListItems";
import { Modal, Toast } from "antd-mobile";
import { useToast } from "@share/hooks/toast";
import { useModal } from "@share/hooks/modal";

type Props = {
  productData: ProductInterface;
  onUpdateProductData: (productData: ProductInterface) => void;
  onDeleteProduct: () => void;
};

const MoreActions = ({
  productData,
  onUpdateProductData,
  onDeleteProduct,
}: Props) => {
  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const { ConfirmModal } = useModal();

  const isEnable = !!(productData.enable === STATUS.ENABLE);
  const isCleanUpLock = !!(productData.is_cleanup_lock === STATUS.ENABLE);

  const onDisableEnableProduct = () => {
    const newStatus = isEnable ? STATUS.DISABLE : STATUS.ENABLE;
    onUpdateProductData({ ...productData, enable: newStatus });
    SuccessToast(
      t(
        isEnable
          ? "ss_builder_disable_product_successfully_label"
          : "ss_builder_enable_product_successfully_label"
      )
    );
  };
  const onCleanUpLockProduct = () => {
    const newStatus = isCleanUpLock ? STATUS.DISABLE : STATUS.ENABLE;
    onUpdateProductData({ ...productData, is_cleanup_lock: newStatus });
    SuccessToast(
      t(
        !isCleanUpLock
          ? "ss_builder_cleanup_lock_product_successfully_label"
          : "ss_builder_cleanup_unlock_product_successfully_label"
      )
    );
  };

  const confirmDeleteProduct = () => {
    const onConfirm = () => {
      onDeleteProduct();
      SuccessToast(t("ss_builder_delete_product_successfully_label"));
    };
    ConfirmModal({
      title: t("ss_builder_confirm_delete_product_modal_title"),
      description: t("ss_builder_confirm_delete_product_modal_content"),
      confirmText: t("ss_builder_confirm_delete_product_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const options = [
    {
      key: ActionEnum.VISIBLE_HIDE_PRODUCT,
      icon: isEnable ? (
        <EyeVisibleIcon
          className="fill-white"
          width={20}
          height={20}
        />
      ) : (
        <EyeHiddenIcon
          className="fill-white"
          width={20}
          height={20}
        />
      ),
      text: isEnable
        ? t("ss_builder_hide_product_label")
        : t("ss_builder_show_product_label"),
      action: onDisableEnableProduct,
    },
    {
      key: ActionEnum.DELETE_PRODUCT,
      icon: (
        <DeleteIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_delete_product_label"),
      action: confirmDeleteProduct,
    },
    {
      key: ActionEnum.CLEANUP_LOCK_PRODUCT,
      icon: !isCleanUpLock ? (
        <BroomIcon
          className="w-5 h-5"
          fillColor="white"
        />
      ) : (
        <BroomLockIcon
          className="w-5 h-5"
          fillColor="white"
        />
      ),
      text: !isCleanUpLock
        ? t("ss_builder_affiliate_product_cleanup_lock_label")
        : t("ss_builder_affiliate_product_cleanup_unlock_label"),
      action: onCleanUpLockProduct,
    },
  ];

  return <MoreActionsCommon options={options} />;
};

export default MoreActions;
