import DndScrollable from "@share/components/dnd-scrollable";
import { TAB_LAYOUT } from "@share/configs/const";
import { Image, Selector } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  tabLayoutValue: string;
  onUpdateTabLayoutValue: any;
};

const TabLayouts = (props: Props) => {
  const { tabLayoutValue, onUpdateTabLayoutValue } = props;
  const { t } = useTranslation();
  const tabLayoutOptions = useMemo(
    () => [
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/tab-text-right-layout.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: <div className="mt-1">{t("ss_builder_tab_layout_1")}</div>,
        value: TAB_LAYOUT.TEXT_RIGHT,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/layout-types/tab-text-under-layout.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: <div className="mt-1">{t("ss_builder_tab_layout_2")}</div>,
        value: TAB_LAYOUT.TEXT_UNDER,
      },
    ],
    []
  );

  const handleSelectTabLayout = (arr) => {
    if (arr && arr.length) {
      const valueRatio = arr[0];
      onUpdateTabLayoutValue(valueRatio);
    }
  };

  return (
    <div>
      <div className="text-[15px] text-[#666666] mb-1">
        {t("ss_builder_card_layout_label")}
      </div>
      <DndScrollable className="selector-scroll">
        <Selector
          options={tabLayoutOptions}
          value={[tabLayoutValue]}
          onChange={handleSelectTabLayout}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
            "--checked-color": "#fff",
          }}
        />
      </DndScrollable>
    </div>
  );
};

export default TabLayouts;
