import { Image } from "antd-mobile";
import React from "react";

const DividerImage = ({ divider_image }) => {
  return (
    <Image
      src={divider_image}
      className="divider-image"
    />
  );
};

export default DividerImage;
