import { Steps } from "intro.js-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "intro.js/introjs.css";
import { nextTourIcon, prevTourIcon } from "@share/icons/product-tour-icon";

interface stepsInterface {
  element: string | Element;
  title: string;
  intro: string;
  tooltipClass: string;
  position?: string;
}

interface Props {
  steps: stepsInterface[];
  onExit?: (stepIndex?: any) => void;
  onBeforeExit?: (stepIndex?: any, nextElement?: any) => void;
  onChange?: (stepIndex: any, nextElement?: any) => void;
  onComplete?: () => void;
  onBeforeChange?: (stepIndex: any, nextElement?: any) => void;
  setRef?: any;
}

const ProductTourComponent = (props: Props) => {
  const {
    steps,
    onExit,
    onBeforeExit,
    onChange,
    onComplete,
    onBeforeChange,
    setRef,
  } = props;

  const { t } = useTranslation();
  const options = {
    hideNext: false,
    hidePrev: true,
    showBullets: false,
    showStepNumbers: true,
    nextToDone: true,
    exitOnOverlayClick: false,
    exitOnEsc: false,
    stepNumbersOfLabel: "/",
    doneLabel: t("ss_builder_product_tour_done_label"),
    prevLabel: `<span class="prev-label">
        ${prevTourIcon}
      </span>`,
    nextLabel:
      `<span class="next-label"><span class="text-label-intro hidden">` +
      t("ss_builder_product_tour_next_label") +
      `</span>
      ${nextTourIcon}
    </span>`,
    skipLabel: t("ss_builder_product_tour_skip_label"),
  };

  return (
    <>
      <Steps
        enabled={true}
        steps={steps}
        initialStep={0}
        onExit={onExit}
        onBeforeExit={onBeforeExit}
        options={options}
        onChange={onChange}
        onComplete={onComplete}
        onBeforeChange={onBeforeChange as any}
        ref={setRef}
      />
    </>
  );
};

export default ProductTourComponent;
