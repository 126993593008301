import { createSlice } from "@reduxjs/toolkit";
import { Block } from "../../main/store/interface";

interface EdittingBlock {
  open: boolean;
  block_data: Block | null;
  position_uid: string | null;
  tab_uid: string | null;
  KEY: string | null;
  position_id: string | null;
  sort_order: number | null;
  block_type?: string;
}

const initialState: EdittingBlock = {
  open: false,
  block_data: null,
  position_uid: null,
  tab_uid: null,
  position_id: null,
  KEY: null,
  sort_order: null,
};

const edittingBlockSlice = createSlice({
  name: "edittingBlock",
  initialState: initialState,
  reducers: {
    openPopup: (
      state,
      {
        payload: {
          block,
          position_uid = null,
          tab_uid = null,
          KEY = null,
          position_id = null,
          sort_order = null,
        },
      }
    ) => {
      state.open = true;
      state.block_data = block;
      state.position_uid = position_uid;
      (state.position_id = position_id), (state.tab_uid = tab_uid);
      state.KEY = KEY;
      state.sort_order = sort_order;
    },
    closePopup: (state) => {
      state.open = false;
      state.block_data = null;
      state.position_uid = null;
      state.tab_uid = null;
      state.KEY = null;
      state.sort_order = null;
    },
  },
});

export const { openPopup, closePopup } = edittingBlockSlice.actions;
export default edittingBlockSlice.reducer;
