import React from "react";
import { Modal } from "antd-mobile";
import {
  CloseCircleIconV2,
  InformationIcon,
  InformationTriangleIcon,
} from "@share/icons";
import { useTranslation } from "react-i18next";

interface ErrorModalInterface {
  title: string;
  icon?: string;
  image?: string;
  description: string;
  confirmText: string;
  className?: string;
  onClose?: () => void;
}

interface ConfirmModalInterface {
  title: string;
  icon?: string;
  image?: string;
  className?: string;
  description: string;
  confirmText: string;
  cancelText?: string;
  onCancel?: () => void;
  onConfirm: () => void;
}

interface AlertModalInterface {
  title: string;
  icon?: string;
  image?: string;
  description: string;
  confirmText: string;
  className?: string;
  onConfirm?: () => void;
}

interface ContentCustomModalInterface {
  title: string;
  icon?: string;
  image?: string;
  content: any;
  confirmText: string;
  className?: string;
  onConfirm?: () => void;
}

export enum ModalIconConfig {
  INFO_PRIMARY_CIRCLE = "info-primary-circle",
  INFO_WARNING_CIRCLE = "info-warning-circle",
  INFO_PRIMARY_TRIANGLE = "info-primary-triangle",
  INFO_WARNING_TRIANGLE = "info-warning-triangle",
  ERROR_PRIMARY_CIRCLE = "error-primary-circle",
}

export const ICON_RENDER = {
  [ModalIconConfig.INFO_PRIMARY_CIRCLE]: (
    <InformationIcon
      className="w-12 h-12"
      fillColor="#EE3244"
    />
  ),
  [ModalIconConfig.INFO_WARNING_CIRCLE]: (
    <InformationIcon
      className="w-12 h-12"
      fillColor="#FF8F1F"
    />
  ),
  [ModalIconConfig.INFO_PRIMARY_TRIANGLE]: (
    <InformationTriangleIcon
      className="w-12 h-12"
      fillColor="#EE3244"
    />
  ),
  [ModalIconConfig.INFO_WARNING_TRIANGLE]: (
    <InformationTriangleIcon
      className="w-12 h-12"
      fillColor="#FF8F1F"
    />
  ),
  [ModalIconConfig.ERROR_PRIMARY_CIRCLE]: (
    <CloseCircleIconV2
      width={48}
      height={48}
      fillColor="#FF3141"
    />
  ),
};

export const useModal = () => {
  const { t } = useTranslation();

  //Error Modal
  const ErrorModal = ({
    title,
    image,
    icon = ModalIconConfig.INFO_WARNING_TRIANGLE,
    description,
    confirmText,
    className,
    onClose = () => null,
  }: ErrorModalInterface) =>
    Modal.show({
      className: `modal-error-class ${className}`,
      image: image,
      header: ICON_RENDER[icon],
      content: (
        <div className="flex flex-col text-center items-center">
          <div className="text-lg font-bold leading-[25px]">{title}</div>
          <div className="">{description}</div>
        </div>
      ),
      closeOnAction: true,
      actions: [
        {
          key: "confirm",
          text: confirmText,
        },
      ],
      onClose: onClose,
    });

  //Confirm Modal
  const ConfirmModal = ({
    title,
    icon = ModalIconConfig.INFO_WARNING_TRIANGLE,
    image,
    className,
    description,
    confirmText,
    cancelText = t("ss_builder_cancel"),
    onConfirm,
    onCancel = () => {
      return;
    },
  }: ConfirmModalInterface) =>
    Modal.confirm({
      image: image,
      header: ICON_RENDER[icon],
      title: <div>{title}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {description}
        </div>
      ),
      className: className,
      confirmText: confirmText,
      cancelText: cancelText,
      onConfirm: onConfirm,
      onCancel: onCancel,
    });

  //Alert Modal
  const AlertModal = ({
    title,
    image,
    icon,
    description,
    confirmText,
    className,
    onConfirm,
  }: AlertModalInterface) =>
    Modal.alert({
      image: image,
      title: title,
      header: ICON_RENDER[icon] || <></>,
      className: className,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {description}
        </div>
      ),
      confirmText: confirmText,
      onConfirm: onConfirm,
    });

    //Alert Modal
  const ContentCustomModal = ({
    title,
    image,
    icon,
    content,
    confirmText,
    className,
    onConfirm,
  }: ContentCustomModalInterface) =>
    Modal.alert({
      image: image,
      title: title,
      header: ICON_RENDER[icon] || <></>,
      className: className,
      content: content,
      confirmText: confirmText,
      onConfirm: onConfirm,
    });

  return { ErrorModal, ConfirmModal, AlertModal, ContentCustomModal };
};
