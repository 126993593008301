import {
  CheckCircleIcon,
  DeleteIcon,
  DragIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  InformationIcon,
  MoreIcon,
} from "@share/icons";
import { Image, List, Modal, Popover, Toast } from "antd-mobile";
import React, { useRef } from "react";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { ImageGalleryInterface } from "@features/main/store/interface";

interface ImageSelectModeProps {
  image: any;
  enable: any;
  title: any;
}

const ImageSelectMode = (props: ImageSelectModeProps) => {
  const { t } = useTranslation();

  const { image, enable, title } = props;

  const isEnable = enable === 1;

  return (
    <div
      className={cls(
        "flex items-center gap-[6px] flex-row",
        !isEnable && "opacity-40"
      )}
    >
      <Image
        src={image}
        width={40}
        height={40}
        fit="cover"
      />
      <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
        <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
          {title}
        </div>
      </div>
    </div>
  );
};

export default ImageSelectMode;
