import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import cls from "classnames";
import {
  BLOCK_TYPE_CONFIG,
  EDITOR_BLOCK_TEXT_MAX_LENGTH,
} from "@share/configs/const";
import { DISABLE_BLOCK, ENABLE_BLOCK } from "@share/configs/const";
import EditorComp from "./EditorComp";
import draftToHtml from "draftjs-to-html";
import { Input } from "antd-mobile";
const BLOCK_HEADING_MAXIMUM = 50;

const TextV2 = (props) => {
  const {
    block,
    onUpdateData,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
  } = props;
  const defaultValueEditText = defaultBlockData?.uid
    ? defaultBlockData
    : null;
  const valueHeadingBlockDefault =
    defaultValueEditText?.content_attributes?.block_heading || "";
  const UID =
  defaultBlockData && `${defaultBlockData?.block_type}-${uuidv4()}`;
  const { draft_content } = defaultValueEditText?.content_attributes || {};

  const defaultValue =
    typeof draft_content === "string"
      ? draft_content
      : draftToHtml(draft_content);

  const [contentState, setContentState] = useState(defaultValue);
  const [count, setCount] = useState(0);
  const [isEnable, setIsEnable] = useState(block?.enable ?? ENABLE_BLOCK);
  const [valueHeadingBlock, setValueHeadingBlock] = useState(
    valueHeadingBlockDefault
  );

  useEffect(() => {
    setContentState(defaultValue);
  }, [defaultValue]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!block.uid) {
      const defaultBlockData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE_CONFIG.TEXT,
        title: "Text",
        content_attributes: {
          block_heading: valueHeadingBlock,
          draft_content: contentState,
        },
        style_attibutes: {},
      };
      onUpdateData(defaultBlockData);
    } else {
      onUpdateData({
        ...block,
        content_attributes: {
          block_heading: valueHeadingBlock,
          draft_content: contentState,
        },
      });
    }
  }, [contentState, valueHeadingBlock]);

  const isDisableBtnSave = useMemo(() => {
    if (
      contentState.length > 0 &&
      contentState.trim().length > 0 &&
      count <= EDITOR_BLOCK_TEXT_MAX_LENGTH &&
      (valueHeadingBlock !== valueHeadingBlockDefault ||
        defaultValue !== contentState)
    ) {
      return false;
    }
    return true;
  }, [
    contentState,
    defaultValue,
    count,
    valueHeadingBlock,
    valueHeadingBlockDefault,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      valueHeadingBlock !== valueHeadingBlockDefault ||
      defaultValue !== contentState
    )
      return true;
    else return false;
  }, [contentState, defaultValue, valueHeadingBlock, valueHeadingBlockDefault]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  const charCount = (editor) => editor.getContent({ format: "text" }).length;

  const handleUpdateValue = (value, editor) => {
    const cCount = charCount(editor);
    setContentState(value);
    setCount(cCount);
  };
  const handleInitEditor = (value, editor) => {
    setCount(charCount(editor));
  };

  const handleChangeBlockHeading = (val) => {
    if (val?.length <= BLOCK_HEADING_MAXIMUM) {
      setValueHeadingBlock(val);
    }
  };

  return (
    <div>
      <div className="px-4 pt-4 pb-5 bg-white mb-3">
        <div>
          <span className="text-[15px] leading-[150%] text-[#666666] mb-1">
            {t("ss_builder_block_heading_label")}
          </span>
          <Input
            placeholder={t("ss_builder_block_heading_placeholder")}
            onChange={handleChangeBlockHeading}
            value={valueHeadingBlock}
            maxLength={BLOCK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueHeadingBlock ? valueHeadingBlock.length : 0
          }/${BLOCK_HEADING_MAXIMUM}`}</div>
        </div>
      </div>
      <div className="px-4 pt-4 pb-5 bg-white">
        <div className="text-[15px] leading-[150%] text-[#666666] mb-[5px]">
          <span className="text-red-600">*</span>
          {t("ss_builder_content_editor_title")}
        </div>
        <EditorComp
          value={contentState}
          onUpdateValue={handleUpdateValue}
          handleInit={handleInitEditor}
          placeholder={t("ss_builder_block_description_placeholder")}
        />
        {count <= EDITOR_BLOCK_TEXT_MAX_LENGTH ? (
          <div
            className={cls(
              "text-[13px] leading-[17px] w-full text-right mt-2 text-[#999999]"
            )}
          >
            <span>{`${count}/${EDITOR_BLOCK_TEXT_MAX_LENGTH}`}</span>
          </div>
        ) : (
          <div
            className={cls(
              "text-[13px] leading-[17px] w-full d-flex justify-between mt-2 gap-4 text-[red]"
            )}
          >
            <span>
              {t("ss_builder_text_editor_max_length", {
                editor_max_length: EDITOR_BLOCK_TEXT_MAX_LENGTH,
              })}
            </span>
            <span>{`${count}/${EDITOR_BLOCK_TEXT_MAX_LENGTH}`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextV2;
