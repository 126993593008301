import { AffiliateProductInterface } from "@features/main/store/interface";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";
import PopupComponent from "@share/components/PopupComponent";
import { PRODUCT_MAX } from "@share/configs/const";
import { AddIcon, EyeVisibleIcon, InformationTriangleIcon } from "@share/icons";
import { Button, CheckList, Modal } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AffiliateProductGroupAddNew from "../AffiliateProductGroupManagement/AffiliateProductGroupAddNew";

type Props = {
  affiliateProductGroupData: AffiliateProductInterface[];
  visible: boolean;
  onCancelAffiliateProductGroupSelection: any;
  defaultValue: string;
  onSelectAffiliateProductGroup: any;
  onAddNewAffiliateProductGroup: any;
  productGroupMaximum: number;
  listProducts?: any;
};
const AffiliateProductGroupPickerView = (props: Props) => {
  const {
    affiliateProductGroupData,
    visible,
    onCancelAffiliateProductGroupSelection,
    defaultValue,
    onSelectAffiliateProductGroup,
    onAddNewAffiliateProductGroup,
    productGroupMaximum,
    listProducts,
  } = props;

  const { t } = useTranslation();
  const [groupPickerValue, setGroupPickerValue] = useState(defaultValue);
  const [isOpenAddMoreProductGroup, setIsOpenAddMoreProductGroup] =
    useState(false);

  const columns = affiliateProductGroupData?.map((x) => ({
    label: (
      <div className="flex flex-row gap-2 items-center">
        <span>
          {x?.content_attributes?.title_product_group}{" "}
          {`(${x?.content_attributes?.items?.length}/${PRODUCT_MAX})`}
        </span>
        {x?.enable !== 1 && (
          <span className="icon-checklist">
            <EyeVisibleIcon
              className="w-4 h-4"
              fillColor="#333333"
            />
          </span>
        )}
      </div>
    ),
    value: x?.uid,
  }));

  const handleSaveAffiliateProductGroupAddNew = async (data) => {
    let affiliateProductGroupListClone = cloneDeep(affiliateProductGroupData);
    const checkExistProductIdx =
      affiliateProductGroupListClone &&
      affiliateProductGroupListClone.findIndex((x) => x.uid === data.uid);
    if (checkExistProductIdx >= 0) {
      affiliateProductGroupListClone[checkExistProductIdx] = data;
    } else {
      affiliateProductGroupListClone = [...affiliateProductGroupData, data];
    }
    const newAffiliateProductGroupDataSortOrder =
      affiliateProductGroupListClone.map((ele, idx) => ({
        ...ele,
        sort_order: idx + 1,
      }));
    onAddNewAffiliateProductGroup(newAffiliateProductGroupDataSortOrder);
    setGroupPickerValue(data?.uid);

    await sleep(500);
    const element = document.getElementsByClassName(data?.uid) as any;
    if (element[0]) {
      element[0].scrollIntoView();
    }
  };

  const newColumns = [
    // {
    //   value: "",
    //   label: "",
    // },
    ...columns,
  ];

  const isFullProductsInGroup = useMemo(
    () =>
      !!(
        affiliateProductGroupData
          ?.find((x) => x.uid === groupPickerValue)
          ?.content_attributes?.items?.filter((x) => x?.is_cleanup_lock === 1)
          ?.length >= PRODUCT_MAX
      ),
    [affiliateProductGroupData, groupPickerValue]
  );

  const isNotEnoughSpaceGroup = useMemo(
    () =>
      !!(
        affiliateProductGroupData
          ?.find((x) => x.uid === groupPickerValue)
          ?.content_attributes?.items?.filter((x) => x?.is_cleanup_lock === 1)
          ?.length +
          listProducts?.length >=
        PRODUCT_MAX
      ),
    [affiliateProductGroupData, groupPickerValue, listProducts]
  );

  const handleConfirmProductGroupPicker = (val) => {
    if (isFullProductsInGroup) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_group_is_full_of_product_modal_title")}
            </div>
            <div className="">
              {t("ss_builder_group_is_full_of_product_modal_description")}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_delete_product_group_error_btn_confirm_label"),
          },
        ],
      });
    } else if (isNotEnoughSpaceGroup) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationTriangleIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px] mb-2">
              {t("ss_builder_not_enough_space_product_group_modal_title")}
            </div>
            <div className="">
              {t("ss_builder_not_enough_space_product_group_modal_content", {
                num_products: listProducts?.length,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_delete_product_group_error_btn_confirm_label"),
          },
        ],
      });
    } else onSelectAffiliateProductGroup(val);
  };
  const handleCancelProductGroup = () => {
    onCancelAffiliateProductGroupSelection();
  };
  const handleSelectProductGroup = () => {
    handleConfirmProductGroupPicker(groupPickerValue);
    handleCancelProductGroup();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementsByClassName(groupPickerValue) as any;
      if (element[0]) {
        element[0].scrollIntoView();
      }
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <PopupComponent
        visible={isOpenAddMoreProductGroup ? !visible : visible}
        onMaskClick={() => onCancelAffiliateProductGroupSelection()}
        title={t("ss_builder_product_group_selection_title")}
        onClose={() => onCancelAffiliateProductGroupSelection()}
        classNameBody="max-h-[60vh] min-h-[30vh]"
      >
        <div className="h-full">
          <div className="check-list-aff-group ">
            <CheckList
              value={[groupPickerValue]}
              onChange={(val) => (val[0] ? setGroupPickerValue(val[0]) : {})}
              className="pb-[110px] overflow-y-auto"
            >
              {newColumns.map((ele, index) => (
                <CheckList.Item
                  value={ele.value}
                  key={index}
                  className={ele.value}
                >
                  {ele.label}
                </CheckList.Item>
              ))}
            </CheckList>
          </div>

          <div className="absolute bottom-0 left-0 w-full bg-white box-btn-aff-group">
            <Button
              className="w-full"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                border: "none",
              }}
              color={"primary"}
              onClick={() => {
                setIsOpenAddMoreProductGroup(true);
              }}
              disabled={columns.length >= productGroupMaximum}
              fill="outline"
            >
              <AddIcon
                fillColor="#EE3244"
                className="w-[18px] h-[18px]"
              />
              {t("ss_builder_product_group_add_new_btn_label")}
              <span>{`${columns.length}/${productGroupMaximum}`}</span>
            </Button>
            <ButtonConfirmPopup
              isDisable={groupPickerValue ? false : true}
              onConfirm={handleSelectProductGroup}
            />
          </div>
        </div>
      </PopupComponent>

      <PopupComponent
        visible={isOpenAddMoreProductGroup}
        onMaskClick={() => setIsOpenAddMoreProductGroup(false)}
        title={t("ss_builder_add_new_affiliate_product_group_btn_label")}
        onClose={() => setIsOpenAddMoreProductGroup(false)}
      >
        <AffiliateProductGroupAddNew
          onCancelAffiliateProductGroupAddNew={() =>
            setIsOpenAddMoreProductGroup(false)
          }
          onSaveAffiliateProductGroupAddNew={
            handleSaveAffiliateProductGroupAddNew
          }
          affiliateProductGroupList={affiliateProductGroupData}
        />
      </PopupComponent>
    </div>
  );
};

export default AffiliateProductGroupPickerView;
