import React from "react";
import { LAYOUT_TYPE_ENUM } from "../../DividerSetting";
import DividerImage from "./DividerImage";
import DividerLine from "./DividerLine";

const SpacerAndDividerSample = ({ block }) => {
  const {
    spacing_value,
    divider_thickness,
    divider_color,
    image: divider_image,
  } = block?.content_attributes || {};

  const { divider_type, layout_type } = block?.style_attributes || {};

  if (!layout_type) return <div style={{ height: `${spacing_value}px` }}></div>;

  return (
    <>
      <div style={{ height: `${spacing_value / 2}px` }}></div>
      {layout_type === LAYOUT_TYPE_ENUM.DEFAULTS && (
        <DividerLine
          divider_thickness={divider_thickness}
          divider_color={divider_color}
          divider_type={divider_type}
        />
      )}
      {layout_type === LAYOUT_TYPE_ENUM.IMAGE && (
        <DividerImage divider_image={divider_image} />
      )}
      <div style={{ height: `${spacing_value / 2}px` }}></div>
    </>
  );
};

export default SpacerAndDividerSample;
