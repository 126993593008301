import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import {
  Button,
  DotLoading,
  Image,
  InfiniteScroll,
  SearchBar,
  Selector,
  Tabs,
} from "antd-mobile";
import IconRender from "@share/IconRender";

import { sleep } from "antd-mobile/es/utils/sleep";
import { fetchCategoryBuilder, fetchIconBuilder, fetchIcons } from "@share/api";
import ButtonConfirmPopup from "../ButtonConfirmPopup";
import { FilterIcon } from "@share/icons/line-icon";
import {
  STYLE_ICON,
  STYLE_ICON_TITLE,
  VISIBLE_ICON,
} from "@share/configs/const";
import { useDebounce } from "usehooks-ts";
import PopupComponent from "../PopupComponent";
import FullScreenPopup from "../full-screen-popup";
import PopupHorizontalComponent from "../PopupHorizontalComponent";

type Props = {
  callbackCancelListIcon: any;
  defaultNameIcon: string;
  callbackConfirmListIcon: any;
};
const PER_PAGE = 80;

const ListIconBuilder = (props: Props) => {
  const { callbackCancelListIcon, callbackConfirmListIcon, defaultNameIcon } =
    props;

  const firstLoad = useRef(1);
  const { t } = useTranslation();
  const [valueIcon, setValueIcon] = useState(defaultNameIcon);
  const [valueUrlIcon, setValueUrlIcon] = useState(null);
  const [listIconLine, setListIconLine] = useState([]);
  const [hasMoreIconLine, setHasMoreIconLine] = useState(true);
  const [listIconSolid, setListIconSolid] = useState([]);
  const [hasMoreIconSolid, setHasMoreIconSolid] = useState(true);
  const [style, setStyle] = useState(STYLE_ICON.LINE);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [emptySearch, setEmptySearch] = useState(false);
  const [isOpenSelectedCategory, setIsOpenSelectedCategory] = useState(false);
  const [valueCategory, setValueCategory] = useState(null);
  const [listCategories, setListCategories] = useState([]);
  const [disableTabLine, setDisableTabLine] = useState(false);
  const [disableTabSolid, setDisableTabSolid] = useState(false);
  useEffect(() => {
    setValueIcon(defaultNameIcon);
  }, [defaultNameIcon]);

  const handleConfirmAddIcon = () => {
    handleCancelSelectedIcon();
    callbackConfirmListIcon({
      valueIcon,
      valueUrlIcon,
    });
  };

  const handleCancelSelectedIcon = () => {
    callbackCancelListIcon();
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("ss_builder_loading_label")}</span>
            <DotLoading />
          </>
        ) : null}
      </>
    );
  };

  async function loadMoreIconLine(keyWord = null) {
    // if (page === 1) {
    //   setListIconLine([]);
    // }
    await sleep(1000);
    const params = {
      page: page,
      per_page: PER_PAGE,
      key_word: keyWord || inputValue,
      style: style,
      category: valueCategory,
      visible: VISIBLE_ICON.SHOW,
    };
    const response = await fetchIconBuilder(params);
    if (response) setListIconLine((value) => [...value, ...response]);
    handleSetPage(page + 1);
    if (Array.isArray(response) && response.length === 0)
      setHasMoreIconLine(false);
  }

  async function loadMoreIconSolid(keyWord = null) {
    // if (page === 1) {
    //   setListIconSolid([]);
    // }
    await sleep(1000);
    const params = {
      page: page,
      per_page: PER_PAGE,
      key_word: keyWord || inputValue,
      style: style,
      category: valueCategory,
      visible: VISIBLE_ICON.SHOW,
    };
    const response = await fetchIconBuilder(params);
    if (response) setListIconSolid((value) => [...value, ...response]);
    handleSetPage(page + 1);
    if (Array.isArray(response) && response.length === 0)
      setHasMoreIconSolid(false);
  }
  console.log("listIconLine", listIconLine);

  const listIconOptionsLine =
    listIconLine &&
    Array.isArray(listIconLine) &&
    listIconLine.map((ele) => ({
      label: <IconRender iconName={ele.icon_file_name} />,
      value: ele.icon_file_name,
    }));
  console.log("listIconSolid", listIconSolid);
  const listIconOptionsSolid =
    listIconSolid &&
    Array.isArray(listIconSolid) &&
    listIconSolid.map((ele) => ({
      label: <IconRender iconName={ele.icon_file_name} />,
      value: ele.icon_file_name,
    }));

  const firstCategory = {
    label: t("ss_builder_box_icon_filter_category_all"),
    value: null,
  };
  const listCategoryOptions =
    listCategories &&
    Array.isArray(listCategories) &&
    listCategories.map((ele) => ({
      label: ele.name,
      value: ele.name,
    }));
  listCategoryOptions.unshift(firstCategory);

  const handleSelectedIconLine = (value) => {
    if (value && value.length) {
      setValueIcon(value[0]);
      const urlIcon = listIconLine.find(
        (x) => x.icon_file_name === value[0]
      ).url;
      setValueUrlIcon(urlIcon);
    }
  };
  const handleSelectedIconSolid = (value) => {
    if (value && value.length) {
      setValueIcon(value[0]);
      const urlIcon = listIconSolid.find(
        (x) => x.icon_file_name === value[0]
      ).url;
      setValueUrlIcon(urlIcon);
    }
  };

  const handleRequestSearchIcon = async (value) => {
    let response = null;
    const params = {
      page: 1,
      per_page: PER_PAGE,
      key_word: value,
      style: style,
      category: valueCategory,
      visible: VISIBLE_ICON.SHOW,
    };
    try {
      response = await fetchIconBuilder(params);
    } catch (error) {
      console.log(error);
    }
    setEmptySearch(response.length === 0);

    // if (style === STYLE_ICON.LINE) {
    //   setListIconLine(response);
    //   if (Array.isArray(response) && response.length === 0)
    //     setHasMoreIconLine(false);
    // } else {
    //   setListIconSolid(response);
    //   if (Array.isArray(response) && response.length === 0)
    //     setHasMoreIconSolid(false);
    // }
    // if (style === STYLE_ICON.LINE) {
    //   loadMoreIconLine(value);
    // } else {
    //   loadMoreIconSolid(value);
    // }
  };
  const handleSearchIcon = (value = null) => {
    setInputValue(value);
  };
  const handleClearSearchIcon = () => {
    setInputValue(null);
    // handleRequestSearchIcon(null);
  };
  const handleSetPage = (page: number) => {
    setPage(page);
  };
  const handleSetActiveTab = (value) => {
    if (value === STYLE_ICON.LINE) {
      setDisableTabSolid(true);
    } else {
      setDisableTabLine(true);
    }
    handleSetPage(1);
    setStyle(value);
  };
  const useDebounceSearch = useDebounce(inputValue, 700);
  const useDebounceCategory = useDebounce(valueCategory, 700);
  const useDebounceStyle = useDebounce(style, 700);
  useEffect(() => {
    handleSetPage(1);
    if (style === STYLE_ICON.LINE) {
      setListIconLine([]);
      setHasMoreIconLine(true);
      setDisableTabSolid(false);
    } else {
      setListIconSolid([]);
      setHasMoreIconSolid(true);
      setDisableTabLine(false);
    }
    handleRequestSearchIcon(inputValue);
  }, [useDebounceSearch, useDebounceCategory, useDebounceStyle, style]);

  const handleOpenListCategories = (value: boolean) => {
    setIsOpenSelectedCategory(value);
  };

  const handleFetchCategories = async (value = null) => {
    let response = null;
    const params = {
      page: 1,
      per_page: 100,
      key_word: value,
    };
    try {
      response = await fetchCategoryBuilder(params);
    } catch (error) {
      console.log(error);
    }
    setListCategories(response);
  };
  const handleOpenPopupFilter = () => {
    handleOpenListCategories(true);
    handleFetchCategories();
  };
  const handleSelectedCategory = (value) => {
    if (value && value.length) {
      handleSetPage(1);
      if (style === STYLE_ICON.LINE) {
        setHasMoreIconLine(true);
      } else {
        setHasMoreIconSolid(true);
      }
      setValueCategory(value[0]);
      handleOpenListCategories(false);
    }
  };

  const EmptySearch = () => {
    return (
      <div className="result-empty flex flex-col justify-center text-center gap-2 mt-5">
        <Image
          src="https://image.passio.eco/page-builder/ssp-landing-page/product-searching-empty-image.png"
          width={260}
          height={128}
          className="m-auto"
        />
        <div className="title">{t("ss_builder_box_icon_empty_search")}</div>
        <div className="sub-title">
          {t("ss_builder_box_icon_empty_searching_description")}
        </div>
      </div>
    );
  };

  return (
    <div className="h-full">
      <div className="max-h-full overflow-auto">
        <div className="box-icon p-3 pt-0">
          <div className="search-bar-box">
            <SearchBar
              placeholder={t("ss_builder_box_icon_search_bar_placeholder")}
              style={{ "--height": "36px" }}
              onSearch={(val) => handleSearchIcon(val)}
              onChange={(val) => handleSearchIcon(val)}
              onClear={() => handleClearSearchIcon()}
            />
            <Button
              size="small"
              className="button-filter"
              fill="none"
              onClick={() => handleOpenPopupFilter()}
            >
              <FilterIcon
                width={15}
                height={15}
                fillColor={valueCategory ? "var(--adm-color-primary)" : "#333"}
              />
              <span
                className={cls(
                  `button-title`,
                  valueCategory ? "text-primary" : "text-[#333]"
                )}
              >
                {t("ss_builder_box_icon_button_filter")}
              </span>
            </Button>
          </div>
          <Tabs
            defaultActiveKey={style}
            activeLineMode="auto"
            stretch={false}
            onChange={(key) => handleSetActiveTab(key)}
          >
            <Tabs.Tab
              title={STYLE_ICON_TITLE.LINE}
              key={STYLE_ICON.LINE}
              disabled={disableTabLine}
            >
              {!emptySearch ? (
                <>
                  <Selector
                    columns={4}
                    options={listIconOptionsLine}
                    onChange={handleSelectedIconLine}
                    value={[valueIcon]}
                    style={{
                      "--border": "solid transparent 1px",
                      "--checked-border": "solid var(--adm-color-primary) 1px",
                      "--checked-color": "#fff",
                      "--color": "#f5f5f5",
                      "--border-radius": "4px",
                    }}
                  />
                  <InfiniteScroll
                    loadMore={loadMoreIconLine}
                    hasMore={hasMoreIconLine}
                  >
                    <InfiniteScrollContent hasMore={hasMoreIconLine} />
                  </InfiniteScroll>
                </>
              ) : (
                <EmptySearch />
              )}
            </Tabs.Tab>
            <Tabs.Tab
              title={STYLE_ICON_TITLE.SOLID}
              key={STYLE_ICON.SOLID}
              disabled={disableTabSolid}
            >
              {!emptySearch ? (
                <>
                  <Selector
                    columns={4}
                    options={listIconOptionsSolid}
                    onChange={handleSelectedIconSolid}
                    value={[valueIcon]}
                    style={{
                      "--border": "solid transparent 1px",
                      "--checked-border": "solid var(--adm-color-primary) 1px",
                      "--checked-color": "#fff",
                      "--color": "#f5f5f5",
                      "--border-radius": "4px",
                    }}
                  />
                  <InfiniteScroll
                    loadMore={loadMoreIconSolid}
                    hasMore={hasMoreIconSolid}
                  >
                    <InfiniteScrollContent hasMore={hasMoreIconSolid} />
                  </InfiniteScroll>
                </>
              ) : (
                <EmptySearch />
              )}
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
      <ButtonConfirmPopup
        onConfirm={valueIcon ? handleConfirmAddIcon : () => {}}
      />
      <PopupHorizontalComponent
        visible={isOpenSelectedCategory}
        onMaskClick={() => handleOpenListCategories(false)}
        title={t("ss_builder_box_icon_filter_category_title")}
        subTitle={t("ss_builder_box_icon_filter_category_sub_title")}
        onClose={() => handleOpenListCategories(false)}
        classNameBody=""
        mask={false}
      >
        <div className="p-2 bg-[#f5f5f5] h-full">
          <Selector
            columns={2}
            options={listCategoryOptions}
            onChange={handleSelectedCategory}
            value={[valueCategory]}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
              "--checked-color": "#fff",
              "--color": "#fff",
              "--border-radius": "4px",
            }}
          />
        </div>
      </PopupHorizontalComponent>
    </div>
  );
};

export default ListIconBuilder;
