import DndScrollable from "@share/components/dnd-scrollable";
import { CARD_STYLE, SLIDE_STYLE } from "@share/configs/const";
import { Image, Selector } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  cardSlideValue: string;
  onUpdateCardSlideValue: any;
};

const CardSlideType = (props: Props) => {
  const { cardSlideValue, onUpdateCardSlideValue } = props;
  const { t } = useTranslation();
  const cardSlideTypeOptions = [
    // {
    //   label: (
    //     <Image
    //       fit="cover"
    //       src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-1.png"
    //     />
    //   ),
    //   description: (
    //     <div className="mt-1">
    //       {t("ss_builder_partial_visible_slide_type_1")}
    //     </div>
    //   ),
    //   value: SLIDE_STYLE.PARTIAL_VISIBLE_RIGHT,
    // },
    // {
    //   label: (
    //     <Image
    //       fit="cover"
    //       src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-2.png"
    //     />
    //   ),
    //   description: (
    //     <div className="mt-1">
    //       {t("ss_builder_partial_visible_slide_type_2")}
    //     </div>
    //   ),
    //   value: SLIDE_STYLE.PARTIAL_VISIBLE_BOTH_SIDES,
    // },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-3.png"
          width={96}
          height={96}
          className="m-auto"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_partial_visible_slide_type_3")}
        </div>
      ),
      value: SLIDE_STYLE.NOT_PARTIAL_VISIBLE,
    },
    // {
    //   label: (
    //     <Image
    //       fit="cover"
    //       src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-4.png"
    //     />
    //   ),
    //   description: (
    //     <div className="mt-1">
    //       {t("ss_builder_partial_visible_slide_type_4")}
    //     </div>
    //   ),
    //   value: SLIDE_STYLE.POSTERS,
    // },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-5.png"
          width={96}
          height={96}
          className="m-auto"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_partial_visible_slide_type_5")}
        </div>
      ),
      value: SLIDE_STYLE.PANORAMA,
    },
  ];
  const handleSelectCardSlideType = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      onUpdateCardSlideValue(value);
    }
  };
  return (
    <div>
      <span className="text-[15px] leading-[13px] text-[#666666]">
        {t("ss_builder_slide_type_label")}
      </span>
      <DndScrollable className="selector-scroll">
        <Selector
          options={cardSlideTypeOptions}
          value={[cardSlideValue]}
          onChange={handleSelectCardSlideType}
          //   columns={4}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      </DndScrollable>
    </div>
  );
};

export default CardSlideType;
