import { RootState } from "@app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Kol from "./models/Kol";

interface userState {
  kol: Kol;
}

const initialState: userState = {
  kol: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile(state: userState, action: PayloadAction<Kol>) {
      state.kol = action.payload;
    },
  },
});

export function getProfile(state: RootState) {
  return state.user.kol;
}

export const { setProfile } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.user.kol;

const userReducer = profileSlice.reducer;

export default userReducer;
