import { BLOCK_TYPE } from "@features/block/block.conf";
import ItemsWithCheckbox from "@features/block/components/ItemsWithCheckbox";
import { ITEMS_MODE } from "@features/block/config/interface";
import { scrollToNewItem } from "@features/block/libs";
import DndScrollable from "@share/components/dnd-scrollable";
import FieldTitle from "@share/components/FieldTitle";
import FullScreenPopup from "@share/components/full-screen-popup";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import StickyChecking from "@share/components/StickyChecking";
import {
  IMAGE_ORIENTATION_TYPE,
  SLIDE_STYLE,
  STYLE_SLIDE_IMAGE,
} from "@share/configs/const";
import { AddIcon, ImageGalleryIcon } from "@share/icons";
import { getPlaceholderProps } from "@share/lib";
import { Button, Divider, Image, Input, Selector, TextArea } from "antd-mobile";
import cls from "classnames";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import ImageGalleryListItem from "./ImageListItem";
import ImageSelectMode from "./ImageListItem/ImageSelectMode";
import ImageNewImage from "./ImageNewImage";

const IMAGE_MAX = 30;
const IMAGE_RATIO_VALUE_DEFAULT = "16/9";
const NUMBER_PER_ROW_DEFAULT = 2;
const NUMBER_ITEM_PER_ROW_SLIDE_DEFAULT = 1;

const ImageGallery = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
    setShowFooterBlockEdit,
  } = props;
  const DESCRIPTION_MAXIMUM = 250;
  const TITLE_MAXIMUM = 50;
  const BLOCK_TITLE_DEFAULT = "Image Gallery";
  const UID = `image-gallery-${uuidv4()}`;

  const [mode, setMode] = useState<ITEMS_MODE>(ITEMS_MODE.DRAG);

  const { t } = useTranslation();
  const defaultValueEditImageGallery =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const titleBlockDefault = BLOCK_TITLE_DEFAULT;

  const imageArrDefault =
    (defaultValueEditImageGallery &&
      defaultValueEditImageGallery.content_attributes &&
      defaultValueEditImageGallery.content_attributes.items) ||
    [];
  const imageGalleryTitleDefault =
    defaultValueEditImageGallery?.content_attributes?.title_image_gallery ?? "";

  const imageGalleryDescriptionDefault =
    defaultValueEditImageGallery?.content_attributes
      ?.description_image_gallery ?? "";

  const numberItemsPerRowDefault =
    defaultValueEditImageGallery?.style_attributes?.number_items_per_row ?? 1;

  const imageOrientationDefault =
    defaultValueEditImageGallery?.style_attributes?.image_orientation ??
    IMAGE_ORIENTATION_TYPE.LANDSCAPE;

  const slideStyleDefault =
    defaultValueEditImageGallery?.style_attributes?.slide_style ??
    SLIDE_STYLE.NOT_PARTIAL_VISIBLE;

  const imageRatioDefault = useMemo(() => {
    let valueRatio =
      defaultValueEditImageGallery?.style_attributes?.ratio ||
      IMAGE_RATIO_VALUE_DEFAULT;
    if (imageOrientationDefault === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [defaultValueEditImageGallery, imageOrientationDefault]);

  const styleImageGalleryDisplayDefault = useMemo(() => {
    const styleDisPlay =
      defaultValueEditImageGallery?.style_attributes?.style_display ??
      STYLE_SLIDE_IMAGE.GRID;
    if (styleDisPlay && styleDisPlay.includes(STYLE_SLIDE_IMAGE.GRID)) {
      return STYLE_SLIDE_IMAGE.GRID;
    } else return styleDisPlay;
  }, [defaultValueEditImageGallery]);

  const [isOpenAddNewImageImage, setIsOpenAddNewImageImage] = useState(false);
  const [imageArr, setImageArr] = useState(imageArrDefault);

  const [imageEditData, setImageEditData] = useState(null);
  const [indexImageEdit, setIndexImageEdit] = useState(null);
  const [imageGalleryTitle, setImageGalleryTitle] = useState(
    imageGalleryTitleDefault
  );
  const [imageGalleryDescription, setImageGalleryDescription] = useState(
    imageGalleryDescriptionDefault
  );
  const [styleImageGalleryDisplay, setStyleImageGalleryDisplay] = useState(
    styleImageGalleryDisplayDefault
  );

  const [numberItemsPerRow, setNumberItemsPerRow] = useState(
    numberItemsPerRowDefault
  );
  const [imageOrientation, setImageOrientation] = useState(
    imageOrientationDefault
  );
  const [imageRatio, setImageRatio] = useState(imageRatioDefault);
  const [slideStyle, setSlideStyle] = useState(slideStyleDefault);

  const [placeholderProps, setPlaceholderProps] = useState({});

  const handleChangeImageGalleryTitle = (value) => {
    if (value?.length <= TITLE_MAXIMUM) {
      setImageGalleryTitle(value);
    }
  };
  const handleChangeImageGalleryDescription = (value) => {
    if (value?.length <= DESCRIPTION_MAXIMUM) {
      setImageGalleryDescription(value);
    }
  };
  const imageSortOrder = useMemo(
    () => orderBy(imageArr, ["sort_order"], ["asc"]),
    [imageArr]
  );

  const imageEnable = useMemo(() => {
    return imageArr?.filter((item) => item.enable === 1);
  }, [imageArr]);

  const imageRatioDetection = useMemo(() => {
    let valueRatio = imageRatio;
    if (imageOrientation === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [imageOrientation, imageRatio]);

  useEffect(() => {
    if (imageSortOrder.length) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [imageSortOrder]);

  useEffect(() => {
    setButtonStatus(true);
  }, []);

  useEffect(() => {
    if (!block.uid) {
      const defaultImageData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.IMAGE_GALLERY,
        title: titleBlockDefault,
        content_attributes: {
          items: imageSortOrder,
          title_image_gallery: imageGalleryTitle,
          description_image_gallery: imageGalleryDescription,
        },
        style_attributes: {
          style_display: styleImageGalleryDisplay,
          number_items_per_row: numberItemsPerRow,
          image_orientation: imageOrientation,
          ratio: imageRatioDetection,
          slide_style: slideStyle,
        },
      };
      onUpdateData(defaultImageData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          items: imageSortOrder,
          title_image_gallery: imageGalleryTitle,
          description_image_gallery: imageGalleryDescription,
        },
        style_attributes: {
          style_display: styleImageGalleryDisplay,
          number_items_per_row: numberItemsPerRow,
          image_orientation: imageOrientation,
          ratio: imageRatioDetection,
          slide_style: slideStyle,
        },
      });
    }
  }, [
    imageSortOrder,
    imageGalleryTitle,
    imageGalleryDescription,
    imageRatioDetection,
    styleImageGalleryDisplay,
    numberItemsPerRow,
    imageOrientation,
    imageRatioDetection,
    slideStyle,
  ]);

  const imageRate = useMemo(
    () => imageSortOrder && `${imageSortOrder.length}/${IMAGE_MAX}`,
    [imageSortOrder]
  );

  const handleSaveImage = (data) => {
    const imageClone = cloneDeep(imageSortOrder);
    if (Array.isArray(imageClone)) {
      if (imageEditData) {
        const indexEditItems = imageClone.findIndex(
          (x) => x.uid === imageEditData.uid
        );
        imageClone[indexEditItems] = data;
      } else {
        if (imageClone.length === 0) {
          imageClone.push(data);
        } else {
          const sortIdArr = imageClone.map((x) => x.sort_order);
          imageClone.push({
            ...data,
            sort_order: Math.max(...sortIdArr) + 1,
          });
        }
      }
      setImageArr(imageClone);
      setEditStatus(true);
      !imageEditData && scrollToNewItem(`blocks-${data?.uid}`);
    }
  };

  const handleOpenAddImageSlider = () => {
    setIsOpenAddNewImageImage(true);
    setImageEditData(null);
    setIndexImageEdit(null);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(imageSortOrder);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedImageList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedImageList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    setImageArr(itemsOrder);
    setButtonStatus(false);
    setEditStatus(true);
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  const onRemoveItem = (index: number) => {
    const imageClone = cloneDeep(imageSortOrder);
    const newImageItems =
      imageClone &&
      Array.isArray(imageClone) &&
      imageClone.filter((ele: any, idx: number) => idx !== index);
    setImageArr(newImageItems);
  };

  const onDisableEnableImage = (index: number, imageStatus: boolean) => {
    const imageClone = cloneDeep(imageSortOrder);
    const newImageItems =
      imageClone &&
      Array.isArray(imageClone) &&
      imageClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              enable: imageStatus,
            }
          : ele;
      });
    setImageArr(newImageItems);
  };

  const handleSelectStyleImageGalleryOptions = (arr) => {
    if (arr && arr.length) {
      setStyleImageGalleryDisplay(arr[0]);
      if (arr[0] === STYLE_SLIDE_IMAGE.MASONRY) {
        setNumberItemsPerRow(NUMBER_PER_ROW_DEFAULT);
      }
      if (arr[0] === STYLE_SLIDE_IMAGE.SLIDE) {
        setNumberItemsPerRow(NUMBER_ITEM_PER_ROW_SLIDE_DEFAULT);
      }
    }
  };
  const handleSelectItemsPerRowOptions = (arr) => {
    if (arr && arr.length) {
      setNumberItemsPerRow(arr[0]);
    }
  };
  const handleSelectImageOrientationOptions = (arr) => {
    if (arr && arr.length) {
      setImageOrientation(arr[0]);
    }
  };
  const handleSelectImageRatioOptions = (arr) => {
    if (arr && arr.length) {
      setImageRatio(arr[0]);
    }
  };
  const handleSelectSlideStyleOptions = (arr) => {
    if (arr && arr.length) {
      setSlideStyle(arr[0]);
    }
  };

  const isDisableBtnSave = useMemo(() => {
    if (
      imageSortOrder?.length > 0 &&
      (JSON.stringify(imageArr) !== JSON.stringify(imageArrDefault) ||
        imageGalleryTitle !== imageGalleryTitleDefault ||
        imageGalleryDescription !== imageGalleryDescriptionDefault ||
        styleImageGalleryDisplay !== styleImageGalleryDisplayDefault ||
        numberItemsPerRow !== numberItemsPerRowDefault ||
        imageOrientation !== imageOrientationDefault ||
        slideStyle !== slideStyleDefault ||
        imageRatio !== imageRatioDefault) &&
      imageEnable.length > 0
    ) {
      return false;
    }
    return true;
  }, [
    imageGalleryTitle,
    imageGalleryTitleDefault,
    imageGalleryDescription,
    imageGalleryDescriptionDefault,
    styleImageGalleryDisplay,
    styleImageGalleryDisplayDefault,
    numberItemsPerRow,
    numberItemsPerRowDefault,
    imageOrientation,
    imageRatioDetection,
    imageOrientationDefault,
    slideStyle,
    slideStyleDefault,
    imageEnable,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      JSON.stringify(imageArr) !== JSON.stringify(imageArrDefault) ||
      imageGalleryTitle !== imageGalleryTitleDefault ||
      imageGalleryDescription !== imageGalleryDescriptionDefault ||
      styleImageGalleryDisplay !== styleImageGalleryDisplayDefault ||
      numberItemsPerRow !== numberItemsPerRowDefault ||
      imageOrientation !== imageOrientationDefault ||
      slideStyle !== slideStyleDefault ||
      imageRatio !== imageRatioDefault
    ) {
      return true;
    }
    return false;
  }, [
    imageGalleryTitle,
    imageGalleryTitleDefault,
    imageGalleryDescription,
    imageGalleryDescriptionDefault,
    styleImageGalleryDisplay,
    styleImageGalleryDisplayDefault,
    numberItemsPerRow,
    numberItemsPerRowDefault,
    imageOrientation,
    imageRatioDetection,
    imageOrientationDefault,
    slideStyle,
    slideStyleDefault,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  const handleOpenEditImage = (imageData, index) => {
    setIsOpenAddNewImageImage(true);
    setImageEditData(imageData);
    setIndexImageEdit(index);
  };
  const styleImageGalleryOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/image-gallery-grid.png"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_style_grid_label")}</div>
      ),
      value: STYLE_SLIDE_IMAGE.GRID,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/image-gallery-slide.png"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_style_slide_label")}</div>
      ),
      value: STYLE_SLIDE_IMAGE.SLIDE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/image-gallery-interlaced.png"
        />
      ),
      description: (
        <div className="mt-1">{t("ss_builder_style_masonry_label")}</div>
      ),
      value: STYLE_SLIDE_IMAGE.MASONRY,
    },
  ];

  const numberItemsPerRowOptions = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
  ];
  const numberItemsPerRowOptionsSlide = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
  ];
  const imageOrientationOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/image-gallery-landscape.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_landscape_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.LANDSCAPE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/layout-types/image-gallery-portrait.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_portrait_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.PORTRAIT,
    },
  ];
  const imageRatioOptions = [
    {
      label: "1:1",
      value: "1/1",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "4:3" : "3:4",
      value: "4/3",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:2" : "2:3",
      value: "3/2",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "16:9" : "9:16",
      value: "16/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "21:9" : "9:21",
      value: "21/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:1" : "1:3",
      value: "3/1",
    },
  ];

  const slideStyleOptions = [
    // {
    //   label: (
    //     <Image
    //     width={96}
    //     height={96}
    //       fit="cover"
    //       src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-1.png"
    //     />
    //   ),
    //   description: (
    //     <div className="mt-1">
    //       {t("ss_builder_partial_visible_slide_type_1")}
    //     </div>
    //   ),
    //   value: SLIDE_STYLE.PARTIAL_VISIBLE_RIGHT,
    // },
    // {
    //   label: (
    //     <Image
    //     width={96}
    //     height={96}
    //       fit="cover"
    //       src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-2.png"
    //     />
    //   ),
    //   description: (
    //     <div className="mt-1">
    //       {t("ss_builder_partial_visible_slide_type_2")}
    //     </div>
    //   ),
    //   value: SLIDE_STYLE.PARTIAL_VISIBLE_BOTH_SIDES,
    // },
    {
      label: (
        <Image
          width={96}
          height={96}
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-3.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_partial_visible_slide_type_3")}
        </div>
      ),
      value: SLIDE_STYLE.NOT_PARTIAL_VISIBLE,
    },
    // {
    //   label: (
    //     <Image
    //     width={96}
    //     height={96}
    //       fit="cover"
    //       src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-4.png"
    //     />
    //   ),
    //   description: (
    //     <div className="mt-1">
    //       {t("ss_builder_partial_visible_slide_type_4")}
    //     </div>
    //   ),
    //   value: SLIDE_STYLE.POSTERS,
    // },
    {
      label: (
        <Image
          width={96}
          height={96}
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/partial-visible-style-5.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_partial_visible_slide_type_5")}
        </div>
      ),
      value: SLIDE_STYLE.PANORAMA,
    },
  ];

  const renderLinksSelectItemsMode = () => (
    <div className="mt-6">
      <ItemsWithCheckbox
        propertyIndex="uid"
        onCancel={swichToDragMode}
        item={ImageSelectMode as any}
        onChange={setImageArr}
        defaultItems={imageSortOrder.map((e) => ({
          id: e.uid,
          ...e,
        }))}
        header={{
          title: t("ss_builder_list_label"),
          sticky: true,
        }}
        footer={{
          countTitle: t("ss_builder_filter_select_multi_items_gallery_count"),
        }}
        blockType={block.block_type}
      />
    </div>
  );

  const swichToDragMode = () => {
    setMode(ITEMS_MODE.DRAG);
    setShowFooterBlockEdit(true);
  };
  const switchToSelectItemsMode = () => {
    setMode(ITEMS_MODE.SELECT_ITEMS);
    setShowFooterBlockEdit(false);
  };

  return (
    <>
      <div className="mt-4 p-4 bg-white">
        <div className="text-[15px] text-[#666666]">
          {t("ss_builder_affiliate_product_group_title")}
        </div>

        <Input
          placeholder={t("ss_builder_block_description_placeholder")}
          onChange={handleChangeImageGalleryTitle}
          value={imageGalleryTitle}
          maxLength={TITLE_MAXIMUM}
        />
        <div className="py-1 text-gray-500 text-sm text-right">
          {imageGalleryTitle ? imageGalleryTitle.length : 0}/{TITLE_MAXIMUM}
        </div>
        <Divider style={{ margin: "10px 0" }} />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </div>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            onChange={handleChangeImageGalleryDescription}
            value={imageGalleryDescription}
            maxLength={DESCRIPTION_MAXIMUM}
          />
          <div className="py-1 text-gray-500 text-sm text-right">
            {imageGalleryDescription ? imageGalleryDescription.length : 0}/
            {DESCRIPTION_MAXIMUM}
          </div>
        </div>
        <Divider style={{ margin: "10px 0" }} />
      </div>
      <div className="mt-3 p-4 bg-white">
        <div>
          <div className="text-[15px] text-[#666666] mb-1">
            {t("ss_builder_style")}
          </div>
          <Selector
            options={styleImageGalleryOptions}
            value={[styleImageGalleryDisplay]}
            onChange={handleSelectStyleImageGalleryOptions}
            columns={3}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
            }}
          />
        </div>
        <Divider />
        {styleImageGalleryDisplay === STYLE_SLIDE_IMAGE.SLIDE && (
          <>
            <div className="text-[15px] text-[#666666] mb-1">
              {t("ss_builder_slide_type_label")}
            </div>
            <DndScrollable className="selector-scroll scroll-style-slide">
              <Selector
                options={slideStyleOptions}
                value={[slideStyle]}
                onChange={handleSelectSlideStyleOptions}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </DndScrollable>
            <Divider />
          </>
        )}
        {styleImageGalleryDisplay !== STYLE_SLIDE_IMAGE.MASONRY && (
          <>
            <div>
              <div className="text-[15px] text-[#666666] mb-1">
                {t("ss_builder_items_per_row")}
              </div>
              <Selector
                options={
                  styleImageGalleryDisplay !== STYLE_SLIDE_IMAGE.SLIDE
                    ? numberItemsPerRowOptions
                    : numberItemsPerRowOptionsSlide
                }
                value={[numberItemsPerRow]}
                onChange={handleSelectItemsPerRowOptions}
                columns={3}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </div>
            <Divider />
            <div>
              <div className="text-[15px] text-[#666666] mb-1">
                {t("ss_builder_image_orientation")}
              </div>
              <Selector
                options={imageOrientationOptions}
                value={[imageOrientation]}
                onChange={handleSelectImageOrientationOptions}
                columns={3}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </div>
            <Divider />
            <div className="mb-2">
              <div className="text-[15px] text-[#666666] mb-1">
                {t("ss_builder_image_slider_ratio_selected_title")}
              </div>
              <Selector
                options={imageRatioOptions}
                value={[imageRatio]}
                onChange={handleSelectImageRatioOptions}
                columns={4}
                style={{
                  "--border": "solid transparent 1px",
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                }}
              />
            </div>
          </>
        )}
      </div>
      {mode === ITEMS_MODE.DRAG && (
        <div className="relative">
          <StickyChecking previousStickyElementId="block-image-gallery-editor-header">
            <div className="flex flex-row justify-between px-3 pt-4 pb-3">
              <div className="grow">
                <div className="text-[15px] text-[#666666]">
                  <FieldTitle
                    title={t("ss_builder_list_label")}
                    isRequire={true}
                  />
                </div>
              </div>
              <div className="flex flex-grow justify-end gap-2">
                <Button
                  size="small"
                  fill="solid"
                  color="primary"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    padding: "3px 12px 3px 12px",
                  }}
                  onClick={handleOpenAddImageSlider}
                  disabled={imageSortOrder.length === IMAGE_MAX}
                >
                  <AddIcon className="w-[15px] h-[15px]" />
                  <div>{t("ss_builder_add_new_label")}</div>
                  <span>{imageRate}</span>
                </Button>
                {imageSortOrder.length > 0 && (
                  <Button
                    onClick={switchToSelectItemsMode}
                    color="primary"
                    fill="outline"
                    size="small"
                  >
                    {t("ss_builder_select_label")}
                  </Button>
                )}
              </div>
            </div>

            {imageEnable.length === 0 && imageArr.length > 0 && (
              <div
                className="px-3 pb-4 text-[13px] font-normal leading-[17px]"
                style={{ color: "var(--adm-color-danger)" }}
              >
                {t("ss_builder_need_to_display_at_least_one_image")}
              </div>
            )}
          </StickyChecking>
          {imageSortOrder && imageSortOrder.length > 0 ? (
            <div>
              <DragDropContext
                onDragEnd={onDragEnd}
                onDragUpdate={onDragUpdate}
              >
                <Droppable droppableId={`blocks-${BLOCK_TYPE.IMAGE_GALLERY}`}>
                  {(provided, snapshot): JSX.Element => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="relative"
                    >
                      {imageSortOrder &&
                        imageSortOrder.length > 0 &&
                        imageSortOrder.map((ele, index) => (
                          <Draggable
                            draggableId={`blocks-${ele.uid}`}
                            index={index}
                            key={`blocks-${ele.uid}`}
                          >
                            {(provided, snapshot): JSX.Element => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={cls(
                                  "mt-2",
                                  snapshot.isDragging && "adm-item-custom"
                                )}
                              >
                                <ImageGalleryListItem
                                  index={index}
                                  imageData={ele}
                                  onRemoveItem={onRemoveItem}
                                  onDisableEnableImage={onDisableEnableImage}
                                  callbackOpenEditImage={handleOpenEditImage}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}

                      {provided.placeholder}
                      {!isEmpty(placeholderProps) &&
                        snapshot.isDraggingOver && (
                          <PlaceholderCustomDragging
                            placeholderProps={placeholderProps}
                            content={t(
                              "ss_builder_placeholder_dragging_content"
                            )}
                            style={{
                              backgroundColor: "rgba(230, 247, 255, 0.75)",
                              border: "1px dashed #1890FF",
                              borderRadius: "4px",
                              color: "#1890FF",
                            }}
                          />
                        )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <div className="flex flex-col text-center items-center justify-center">
              <span className="inline-block mb-2">
                <ImageGalleryIcon className="align-middle w-8 h-8" />
              </span>
              <div className="text-[#333333] text-lg leading-6">
                {t("ss_builder_image_list_empty_title")}
              </div>
              <div className="text-[#8C8C8C] text-xs leading-4">
                {t("ss_builder_image_list_empty_content")}
              </div>
            </div>
          )}
        </div>
      )}
      {mode === ITEMS_MODE.SELECT_ITEMS && renderLinksSelectItemsMode()}

      {isOpenAddNewImageImage && (
        <FullScreenPopup
          visible={isOpenAddNewImageImage}
          onMaskClick={() => setIsOpenAddNewImageImage(false)}
        >
          <ImageNewImage
            imageRatio={imageRatioDetection}
            callBackCancelImageSliderAddNew={() =>
              setIsOpenAddNewImageImage(false)
            }
            handleSaveImageSlider={handleSaveImage}
            imageEditData={imageEditData}
            indexImageEdit={indexImageEdit}
          />
        </FullScreenPopup>
      )}
    </>
  );
};

export default ImageGallery;
