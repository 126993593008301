import React, { useEffect, useMemo, useRef, useState } from "react";
import HeaderTitle from "@design-components/HeaderTitle";
import InnerPopup from "@design-components/InnerPopup";
import { useTranslation } from "react-i18next";
import { Button, List } from "antd-mobile";
import {
  DigitalProducticonV2,
  GiftIconV2,
  IconBrandGift,
  IconDonation,
  TruthOrDareIcon,
} from "@share/icons/block-icons";
import IconRender from "@share/IconRender";
import { getLabelKey } from "../../helper";

interface ServiceSelectorPopupInterface {
  visible: boolean;
  onSelect: (value: string) => void;
  defaultValue?: string;
  getContainer?: () => HTMLElement;
  onClose: () => void;
}

export enum LinkServiceEnum {
  DIGITAL = "digital",
  DONATION = "donation",
  GIFT = "gift",
  BRAND_GIFT = "brand_gift",
  TRUTH_OR_DARE = "tod",
}

const SERVICE_ITEMS = [
  // {
  //     value: LinkServiceEnum.DIGITAL,
  //     label_key: 'ss_builder_title_block_digital_product',
  //     icon: <DigitalProducticonV2 className="align-middle inline-block w-6 h-6 rounded-lg" />
  // },
  {
    value: LinkServiceEnum.DONATION,
    label_key: "ss_builder_title_block_donation",
    icon: (
      <IconDonation className="align-middle inline-block w-6 h-6 rounded-lg" />
    ),
  },
  {
    value: LinkServiceEnum.GIFT,
    label_key: "ss_builder_title_block_virtual_gift",
    icon: (
      <GiftIconV2 className="align-middle inline-block w-6 h-6 rounded-lg" />
    ),
  },
  {
    value: LinkServiceEnum.BRAND_GIFT,
    label_key: "ss_builder_title_block_brand_gift",
    icon: (
      <IconBrandGift className="align-middle inline-block w-6 h-6 rounded-lg" />
    ),
  },
  {
    value: LinkServiceEnum.TRUTH_OR_DARE,
    label_key: "ss_builder_title_block_truth_or_dare",
    icon: (
      <TruthOrDareIcon className="align-middle rounded-lg inline-block w-6 h-6 " />
    ),
  },
];

export const getServiceLabelKey = (value) => {
  const item = SERVICE_ITEMS.find((item) => item.value == value);
  if (item) {
    return item.label_key;
  }
  return "None";
};

export default function ServiceSelectorPopup(
  props: ServiceSelectorPopupInterface
) {
  const {
    visible,
    onSelect,
    onClose,
    getContainer = () => document.body,
    defaultValue = LinkServiceEnum.DIGITAL,
  } = props;
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const containerRef = useRef<HTMLDivElement>();
  const buttonRef = useRef<HTMLDivElement>();
  const selectItem = (value) => {
    setSelectedValue(value);
    // onSelect(value);
    // onClose();
  };

  useEffect(() => {
    if (visible) {
      setSelectedValue(defaultValue);
    }
  }, [visible]);
  const maxServiceHeight = useMemo(() => {
    if (containerRef?.current?.offsetHeight && buttonRef.current.offsetHeight) {
      return (
        containerRef?.current?.offsetHeight - buttonRef.current.offsetHeight
      );
    }
    return "100%";
  }, [
    containerRef.current && containerRef.current?.offsetHeight,
    buttonRef.current ?? buttonRef.current?.offsetHeight,
  ]);

  return (
    <InnerPopup
      className="rounded-t-lg"
      getContainer={getContainer}
      visible={visible}
      onClose={onClose}
    >
      {visible && (
        <div
          ref={containerRef}
          className="relative rounded-t-lg"
        >
          <HeaderTitle
            onClose={onClose}
            title={t("ss_builder_link_select_service")}
          />
          <div
            style={{ maxHeight: maxServiceHeight }}
            className="h-full border-b"
          >
            <List>
              {SERVICE_ITEMS.map((item) => (
                <List.Item
                  key={item.value}
                  onClick={() => selectItem(item.value)}
                  prefix={item.icon}
                  clickable
                  arrow={false}
                  className="relative"
                >
                  <div className="flex justify-between text-[17px]">
                    {t(item.label_key)}
                    {selectedValue == item.value && (
                      <IconRender
                        className="w-4 h-4"
                        containerClass="block"
                        fillColor="#EE3244"
                        iconName="check.svg"
                      />
                    )}
                  </div>
                </List.Item>
              ))}
            </List>
          </div>
          <div
            ref={buttonRef}
            className="py-3 text-center bottom-0 px-4 bg-white"
          >
            <Button
              className="w-full"
              onClick={() => {
                onSelect(selectedValue);
                onClose();
              }}
              disabled={!selectedValue}
              type="button"
              color="primary"
            >
              {t("ss_builder_popup_confirm_button")}
            </Button>
          </div>
        </div>
      )}
    </InnerPopup>
  );
}
