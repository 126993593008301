import {
  CheckCircleIcon,
  DeleteIcon,
  DragIcon,
  EditTabIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  InformationIcon,
  MoreIcon,
} from "@share/icons";
import { Image, List, Modal, Popover, Toast } from "antd-mobile";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { AffiliateProductInterface } from "@features/main/store/interface";
import { PRODUCT_MAX } from "@share/configs/const";
import { AFFILIATE_GROUP_MANAGEMENT_TOUR_CLASS_NAME } from "../AffiliateProductGroupTour/group-tour-config";
import { useAppSelector } from "@app/hooks";

interface AffiliateProductGroupItemsProps {
  productData: AffiliateProductInterface;
  index: number;
  onRemoveItem: any;
  onDisableEnableProduct: any;
  onEditAffiliateProductGroup: any;
  affiliateProductGroupList: AffiliateProductInterface[];
  onActiveProductGroup: any;
}

const AffiliateProductGroupItems = (props: AffiliateProductGroupItemsProps) => {
  const { t } = useTranslation();

  const {
    index,
    productData,
    onRemoveItem,
    onDisableEnableProduct,
    onEditAffiliateProductGroup,
    affiliateProductGroupList,
    onActiveProductGroup,
  } = props;
  const isOpenAffiliateGroupTour = useAppSelector(
    (state) => state?.dynamicConfig?.isOpenAffiliateGroupTour
  );
  const isEnable = !!(productData && productData.enable === 1);

  const popoverContainerRef = useRef(null);
  const popoverRef = useRef(null);

  const defaultGroupName = productData?.title;

  const affProductLists = productData?.content_attributes?.items ?? [];

  const affiliateProductGroupListEnable = useMemo(
    () => affiliateProductGroupList?.filter((x) => x.enable === 1),
    [affiliateProductGroupList]
  );

  const [forceVisiblePopoverProductGroup, setForceVisiblePopoverProductGroup] =
    useState(false);

  useEffect(() => {
    const castWindow = window as any;
    let isOpen = false;
    if (affiliateProductGroupList?.length > 1) {
      isOpen = !!(index === 1);
    } else {
      isOpen = !!(index === 0);
    }
    if (isOpen) {
      castWindow.triggerVisibleAffiliatePopoverProductGroup =
        setForceVisiblePopoverProductGroup;
      () => {
        if (castWindow.triggerVisibleAffiliatePopoverProductGroup) {
          castWindow.triggerVisibleAffiliatePopoverProductGroup = undefined;
          setForceVisiblePopoverProductGroup(false);
        }
      };
    }
  }, [affiliateProductGroupList]);

  const actions: Action[] = [
    {
      key: "edit-product-group",
      icon: <EditTabIcon className="" />,
      text: t("ss_builder_edit_product_group_label"),
    },
    {
      key: "eye-visible-product-group",
      icon: !isEnable ? (
        <EyeHiddenIcon className="" />
      ) : (
        <EyeVisibleIcon className="" />
      ),
      text: !isEnable
        ? t("ss_builder_show_product_group_label")
        : t("ss_builder_hide_product_group_label"),
    },
  ];
  affiliateProductGroupList?.length >= 2 &&
    actions.push({
      key: "delete-product-group",
      icon: <DeleteIcon className="" />,
      text: t("ss_builder_delete_product_group_label"),
    });

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case "edit-product-group":
        handleEditProductGroup();
        break;
      case "eye-visible-product-group":
        handleEnableDisableProductGroup();
        break;
      case "delete-product-group":
        confirmRemoveProductGroup();
        break;
      default:
      // to do
    }
  };

  const handleEditProductGroup = () => {
    onEditAffiliateProductGroup(productData);
  };

  const confirmRemoveProductGroup = () => {
    if (affiliateProductGroupListEnable.length <= 1 && isEnable) {
      Modal.show({
        className: "modal-error-class",
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        content: (
          <div className="flex flex-col text-center items-center">
            <div className="text-lg font-bold leading-[25px]">
              {t("ss_builder_delete_product_group_error_title")}
            </div>
            <div className="">
              {t("ss_builder_delete_product_group_error_content", {
                group_name: defaultGroupName,
              })}
            </div>
          </div>
        ),
        closeOnAction: true,
        actions: [
          {
            key: "confirm",
            text: t("ss_builder_delete_product_group_error_btn_confirm_label"),
          },
        ],
      });
    } else {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="var(--adm-color-primary)"
            onClick={() => {}}
          />
        ),
        title: (
          <div>{t("ss_builder_confirm_delete_product_group_modal_title")}</div>
        ),
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_confirm_delete_product_group_modal_description", {
              group_name: defaultGroupName,
            })}
          </div>
        ),
        confirmText: t("ss_builder_confirm_delete_product_label"),
        cancelText: t("ss_builder_cancel_delete_product_label"),
        onConfirm: () => {
          onRemoveItem(index);
          Toast.show({
            icon: (
              <CheckCircleIcon
                className="text-center inline-block"
                fillColor="#00B578"
              />
            ),
            content: (
              <div className="text-center">
                {t("ss_builder_delete_product_group_successfully_label")}
              </div>
            ),
          });
        },
        onCancel: () => {
          return;
        },
      });
    }
  };

  const handleEnableDisableProductGroup = () => {
    const productStatus = !isEnable ? 1 : 2;
    onDisableEnableProduct(index, productStatus);
  };

  const handleClickMoreIcon = (event) => {
    event.stopPropagation();
  };

  const visibleStep2Checking = useMemo(() => {
    let value = {};
    if (isOpenAffiliateGroupTour) {
      value =
        forceVisiblePopoverProductGroup &&
        ((affiliateProductGroupList?.length <= 1 && index === 0) ||
          (affiliateProductGroupList?.length > 1 && index === 1))
          ? { visible: true }
          : { visible: false };
    }
    return value;
  }, [
    isOpenAffiliateGroupTour,
    forceVisiblePopoverProductGroup,
    affiliateProductGroupList,
    index,
  ]);

  return (
    <div
      ref={popoverContainerRef}
      onClick={() => onActiveProductGroup(productData?.uid)}
      className="cursor-pointer"
      id={index === 0 && AFFILIATE_GROUP_MANAGEMENT_TOUR_CLASS_NAME.STEP_2}
    >
      <List className="">
        <List.Item
          prefix={<DragIcon className="" />}
          extra={
            <div className="flex flex-row items-center gap-[15px] cursor-pointer">
              <div
                className=""
                onClick={handleClickMoreIcon}
              >
                <Popover.Menu
                  className={
                    ((affiliateProductGroupList?.length <= 1 && index === 0) ||
                      (affiliateProductGroupList?.length > 1 && index === 1)) &&
                    AFFILIATE_GROUP_MANAGEMENT_TOUR_CLASS_NAME.STEP_2
                  }
                  mode="dark"
                  actions={actions}
                  placement="left"
                  trigger="click"
                  stopPropagation={["click"]}
                  getContainer={() => popoverContainerRef.current}
                  onAction={(item: Action) => handleClickPopoverItem(item)}
                  ref={popoverRef}
                  {...visibleStep2Checking}
                >
                  <MoreIcon className="mr-3" />
                </Popover.Menu>
              </div>
            </div>
          }
        >
          <div
            className={cls(
              "flex items-center gap-[6px] flex-row",
              !isEnable && "opacity-40"
            )}
          >
            <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
              <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
                {productData.title}
              </div>
              <span
                className={cls(
                  `text-ellipsis whitespace-nowrap overflow-hidden text-xs leading-4`,
                  affProductLists.length >= PRODUCT_MAX
                    ? "text-[#FF3141]"
                    : "text-[#8C8C8C]"
                )}
              >
                {t("ss_builder_affiliate_product_group_quantity_label", {
                  product_quantity: `${affProductLists.length}/${PRODUCT_MAX}`,
                })}
              </span>
            </div>
          </div>
        </List.Item>
      </List>
    </div>
  );
};

export default AffiliateProductGroupItems;
