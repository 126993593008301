import ImageUploadCommon from "@share/components/ImageUploadCommon";
import { IMAGE_UPLOAD_LAYOUTS } from "@share/components/ImageUploadCommon/constants";
import React from "react";
import { useTranslation } from "react-i18next";

const ImageLayoutSetting = (props) => {
  const { onUpdateSettingData, divider_image } = props;
  const { t } = useTranslation();
  return (
    <ImageUploadCommon
      value={divider_image}
      onChange={(img) => onUpdateSettingData("divider_image", img)}
      layout_type={IMAGE_UPLOAD_LAYOUTS.LAYOUT_1}
      label={t("ss_builder_divider_image_label")}
      isRequired={true}
    />
  );
};

export default ImageLayoutSetting;
