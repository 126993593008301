import React from "react";
import { BOOKING_PROFILE_FIELD } from "../../constant";
import SwitchFieldCustom from "@share/components/SwitchFieldCustom";
import { useTranslation } from "react-i18next";
import { Divider } from "antd-mobile";

type Props = {
  handleChange: (field: string, value: any) => void;
  openNewTab: boolean;
  applyBlockSettings: boolean;
};

const EmbedCodeStyle = ({
  handleChange,
  openNewTab,
  applyBlockSettings,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="px-4 py-3 bg-white mb-3">
        <SwitchFieldCustom
          checked={openNewTab}
          onChange={(val) =>
            handleChange(BOOKING_PROFILE_FIELD.OPEN_NEW_TAB, val)
          }
          label={t("ss_builder_bp_open_new_tab_title")}
          helperContent={t("ss_builder_bp_open_new_tab_instruction")}
        />
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        <SwitchFieldCustom
          checked={applyBlockSettings}
          onChange={(val) =>
            handleChange(BOOKING_PROFILE_FIELD.APPLY_BLOCK_SETTINGS, val)
          }
          label={t("ss_builder_block_setting_title")}
          helperContent={t("ss_builder_bp_apply_block_settings_instruction")}
        />
      </div>
    </>
  );
};

export default EmbedCodeStyle;
