import { useAppDispatch } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import FullScreenPopup from "@share/components/full-screen-popup";
import {
  LINK_LAYOUTS,
  LINK_STYLE_DISPLAY,
  LINK_TEMPLATE,
  TAB_ICON_DEFAULT,
  CARD_ITEM_ON_ROW,
  ENABLE_BLOCK,
} from "@share/configs/const";
import { Divider, Input, Selector, TextArea } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import LinkLayouts from "../ButtonLink/LinkLayouts";
import LinkStyleDisplay from "../ButtonLink/LinkStyleDisplay";
import ListItems from "./ListItems";
import PopupComponent from "@share/components/PopupComponent";
import ListIconBuilder from "@share/components/ListIconBuilder";

const LINK_HEADING_MAXIMUM = 50;
const LINK_DESCRIPTION_MAXIMUM = 250;

const itemOnRowOptions = [
  {
    label: "1",
    value: CARD_ITEM_ON_ROW.ONE,
  },
  {
    label: "2",
    value: CARD_ITEM_ON_ROW.TWO,
  },
];

const itemOnRowOptionsV2 = [
  {
    label: "1",
    value: CARD_ITEM_ON_ROW.ONE,
  },
  {
    label: "2",
    value: CARD_ITEM_ON_ROW.TWO,
  },
  {
    label: "3",
    value: CARD_ITEM_ON_ROW.THREE,
  },
  {
    label: "4",
    value: CARD_ITEM_ON_ROW.FOUR,
  },
  {
    label: "5",
    value: CARD_ITEM_ON_ROW.FIVE,
  },
  {
    label: "6",
    value: CARD_ITEM_ON_ROW.SIX,
  },
];

const ButtonMultiLinks = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    setButtonLoading,
    defaultBlockData,
    setShowFooterBlockEdit,
  } = props;
  const { t } = useTranslation();
  const UID = `button-link-${uuidv4()}`;

  const dispatch = useAppDispatch();

  // const edittingBlock = useAppSelector((state) => {
  //   return state.edittingBlock;
  // });
  // const KEY = edittingBlock && edittingBlock.KEY;
  // const edittingBlockData = edittingBlock && edittingBlock.block_data;
  // const tabUid = edittingBlock && edittingBlock.tab_uid;
  // const positionSlug = edittingBlock && edittingBlock.position_uid;
  const defaultValueEdit =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const titleBlockDefault = "Multi Links";

  const originUrlDefault =
    defaultValueEdit?.content_attributes?.origin_url || "";

  const listLinkDefault = defaultValueEdit?.content_attributes?.items || [];

  const affiliateUrlDefault =
    defaultValueEdit?.content_attributes?.target_url || "";
  const buttonSizeDefault =
    defaultValueEdit?.style_attributes?.button_size || "middle";

  const buttonContentDefault =
    defaultValueEdit?.content_attributes?.button_content || "";

  const buttonLinkDescriptionDefault =
    defaultValueEdit?.content_attributes?.button_description || "";

  const buttonIconDefault =
    defaultValueEdit?.content_attributes?.button_icon || TAB_ICON_DEFAULT;

  const buttonIconUrlDefault =
    defaultValueEdit?.content_attributes?.button_icon_url || null;

  const hasAffiliateUrlDefault = defaultValueEdit?.content_attributes
    ?.use_aff_url
    ? !!(defaultValueEdit?.content_attributes?.use_aff_url === 1)
    : true;

  const imageDefault = defaultValueEdit?.content_attributes?.image || null;

  const styleLinkDisplayDefault =
    defaultValueEdit?.style_attributes?.button_link_style ||
    LINK_STYLE_DISPLAY.TEXT_AND_ICON;

  const linkLayoutDefault =
    defaultValueEdit?.style_attributes?.button_link_layout ||
    LINK_LAYOUTS.TEXT_RIGHT;

  const linkTemplateDefault =
    defaultValueEdit?.style_attributes?.button_link_template ||
    LINK_TEMPLATE.TEMPLATE1;
  const itemOnRowDefault =
    defaultValueEdit?.style_attributes?.item_on_row || "1";
  const [buttonSize, setButtonSize] = useState(buttonSizeDefault);
  const [buttonContent, setButtonContent] = useState(buttonContentDefault);
  const [buttonLinkDescription, setButtonLinkDescription] = useState(
    buttonLinkDescriptionDefault
  );
  const [buttonIcon, setButtonIcon] = useState(buttonIconDefault);
  const [buttonIconUrl, setButtonIconUrl] = useState(buttonIconUrlDefault);
  const [isOpenSelectedIcon, setIsOpenSelectedIcon] = useState(false);

  const [isOpenTemplateSelection, setIsOpenTemplateSelection] = useState(false);

  const [originUrl, setOriginUrl] = useState(originUrlDefault);
  const [affiliateUrl, setAffiliateUrl] = useState(affiliateUrlDefault);
  const [hasAffiliateUrl, setHasAffiliateUrl] = useState(
    hasAffiliateUrlDefault
  );
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [warningCode, setWarningCode] = useState(null);
  const [isCheckDisableBtnSave, setIsCheckDisableBtnSave] = useState(false);
  const [styleLinkDisplay, setStyleLinkDisplay] = useState(
    styleLinkDisplayDefault
  );
  const [linkLayout, setLinkLayout] = useState(linkLayoutDefault);
  const [linkTemplate, setLinkTemplate] = useState(linkTemplateDefault);

  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [valueUrlImage, setValueUrlImage] = useState(imageDefault);
  const [valueNameImage, setValueNameImage] = useState(null);
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [itemOnRow, setItemOnRow] = useState(itemOnRowDefault);
  const [listLink, setListLink] = useState(listLinkDefault);
  const [styleOfParent, setStyleOfParent] = useState(null);
  const [isEnable, setIsEnable] = useState(block?.enable ?? ENABLE_BLOCK);
  const [checkIsEnought, setCheckIsEnought] = useState(false);
  const labelObject = {
    listTitle: t("ss_builder_list_button_multi_link_label"),
    listEmptyLabel: t("ss_builder_list_link_empty_title"),
    listEmptyDescription: t("ss_builder_list_link_empty_content"),
    itemAdditionalBtnLabel: t("ss_builder_link_additional_btn_label"),
    deleteItemLabel: t("ss_builder_delete_link_label"),
    deleteItemSuccessfullyLabel: t("ss_builder_delete_link_successfully_label"),
    showItemLabel: t("ss_builder_show_link_label"),
    showItemSuccessfullyLabel: t("ss_builder_show_link_successfully_label"),
    hideItemLabel: t("ss_builder_hide_link_label"),
    hideItemSuccessfullyLabel: t("ss_builder_hide_link_successfully_label"),
    confirmDeleteLinkModalLabel: t("ss_builder_delete_link_label"),
    confirmDeleteLinkModalDescription: t(
      "ss_builder_confirm_delete_link_description"
    ),
    confirmDeleteLinkModalBtnLabel: t("ss_builder_delete_link_label"),
    cancelDeleteLinkModalBtnLabel: t(
      "ss_builder_cancel_delete_link_modal_btn_label"
    ),
  };

  const listLinkEnable = useMemo(() => {
    return listLink?.filter((item) => item?.enable === 1);
  }, [listLink]);

  useEffect(() => {
    if (buttonSize || linkLayout || styleLinkDisplay || itemOnRow)
      setStyleOfParent({
        style_attributes: {
          button_size: buttonSize || "",
          button_link_layout: linkLayout || "",
          button_link_style: styleLinkDisplay || "",
          item_on_row: itemOnRow || "",
        },
      });
  }, [buttonSize, linkLayout, styleLinkDisplay, itemOnRow]);

  useEffect(() => {
    if (!block.uid) {
      const defaultData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.BUTTON_MULTI_LINK,
        title: titleBlockDefault,
        content_attributes: {
          items: listLink?.map((link) => ({
            ...link,
            style_attributes: {
              button_size: buttonSize,
              button_link_template:
                link?.style_attributes?.button_link_template,
              button_link_layout: linkLayout,
              button_link_style: styleLinkDisplay,
              item_on_row:
                linkLayout === LINK_LAYOUTS.TEXT_ZIG_ZAG ? 1 : itemOnRow,
            },
          })),
          button_content: buttonContent,
          button_description: buttonLinkDescription,
        },
        style_attributes: {
          button_size: buttonSize,
          button_link_template: linkTemplate,
          button_link_layout: linkLayout,
          button_link_style: styleLinkDisplay,
          item_on_row: linkLayout === LINK_LAYOUTS.TEXT_ZIG_ZAG ? 1 : itemOnRow,
        },
      };
      onUpdateData(defaultData);
    } else {
      onUpdateData({
        ...block,
        enable: isEnable,
        title: titleBlockDefault,
        content_attributes: {
          button_content: buttonContent,
          button_description: buttonLinkDescription,
          items: listLink?.map((link) => {
            return {
              ...link,
              style_attributes: {
                button_size: buttonSize,
                button_link_template:
                  link?.style_attributes?.button_link_template,
                button_link_layout: linkLayout,
                button_link_style: styleLinkDisplay,
                item_on_row:
                  linkLayout === LINK_LAYOUTS.TEXT_ZIG_ZAG ? 1 : itemOnRow,
              },
            };
          }),
        },
        style_attributes: {
          button_size: buttonSize,
          button_link_template: linkTemplate,
          button_link_layout: linkLayout,
          button_link_style: styleLinkDisplay,
          item_on_row: linkLayout === LINK_LAYOUTS.TEXT_ZIG_ZAG ? 1 : itemOnRow,
        },
      });
    }
  }, [
    buttonIconUrl,
    buttonContent,
    buttonIcon,
    buttonSize,
    hasAffiliateUrl,
    affiliateUrl,
    originUrl,
    buttonLinkDescription,
    valueImageBase64,
    valueUrlImage,
    valueNameImage,
    linkTemplate,
    linkLayout,
    styleLinkDisplay,
    listLink,
    itemOnRow,
    isEnable,
  ]);

  const isDisableBtnSave = useMemo(() => {
    if (
      (buttonContent !== buttonContentDefault ||
        buttonLinkDescription !== buttonLinkDescriptionDefault ||
        buttonIcon !== buttonIconDefault ||
        buttonSize !== buttonSizeDefault ||
        originUrl !== originUrlDefault ||
        linkTemplate !== linkTemplateDefault ||
        styleLinkDisplay !== styleLinkDisplayDefault ||
        linkLayout !== linkLayoutDefault ||
        valueUrlImage !== imageDefault ||
        itemOnRow !== itemOnRowDefault ||
        (typeof hasAffiliateUrl === "boolean" &&
          hasAffiliateUrl !== hasAffiliateUrlDefault) ||
        JSON.stringify(listLink) !== JSON.stringify(listLinkDefault)) &&
      !isValidUrl &&
      !isCheckDisableBtnSave &&
      listLink.length !== 0 &&
      checkIsEnought &&
      listLinkEnable.length > 0
    ) {
      return false;
    } else return true;
  }, [
    buttonContent,
    buttonContentDefault,
    originUrl,
    originUrlDefault,
    buttonIcon,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    buttonIconDefault,
    isValidUrl,
    buttonSize,
    buttonSizeDefault,
    isCheckDisableBtnSave,
    buttonLinkDescription,
    buttonLinkDescriptionDefault,
    linkTemplate,
    linkTemplateDefault,
    styleLinkDisplay,
    styleLinkDisplayDefault,
    valueUrlImage,
    imageDefault,
    linkLayout,
    linkLayoutDefault,
    listLink,
    itemOnRow,
    itemOnRowDefault,
    checkIsEnought,
    listLinkEnable,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      buttonContent !== buttonContentDefault ||
      originUrl !== originUrlDefault ||
      buttonIcon !== buttonIconDefault ||
      buttonSize !== buttonSizeDefault ||
      linkTemplate !== linkTemplateDefault ||
      styleLinkDisplay !== styleLinkDisplayDefault ||
      linkLayout !== linkLayoutDefault ||
      valueUrlImage !== imageDefault ||
      itemOnRow !== itemOnRowDefault ||
      buttonLinkDescription !== buttonLinkDescriptionDefault ||
      (typeof hasAffiliateUrl === "boolean" &&
        hasAffiliateUrl !== hasAffiliateUrlDefault) ||
      JSON.stringify(listLink) !== JSON.stringify(listLinkDefault)
    ) {
      return true;
    } else return false;
  }, [
    buttonContent,
    buttonContentDefault,
    originUrl,
    originUrlDefault,
    buttonIcon,
    buttonIconDefault,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    buttonSize,
    buttonSizeDefault,
    linkTemplate,
    linkTemplateDefault,
    styleLinkDisplay,
    styleLinkDisplayDefault,
    valueUrlImage,
    imageDefault,
    linkLayout,
    linkLayoutDefault,
    buttonLinkDescription,
    buttonLinkDescriptionDefault,
    listLink,
    listLinkDefault,
    itemOnRow,
    itemOnRowDefault,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  useEffect(() => {
    setButtonLoading(isLoadingBtn);
  }, [isLoadingBtn]);

  useEffect(() => {
    const { content_attributes, style_attributes } = block;
    const isShowIcon =
      style_attributes?.button_link_style === LINK_STYLE_DISPLAY.ONLY_ICON ||
      style_attributes?.button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_ICON;
    const isShowImage =
      style_attributes?.button_link_style === LINK_STYLE_DISPLAY.ONLY_IMAGE ||
      style_attributes?.button_link_style === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE;
    const isOnlytext =
      style_attributes?.button_link_style === LINK_STYLE_DISPLAY.ONLY_TEXT;

    const listItem = content_attributes?.items;
    const listItemFilter = listItem?.filter((item) => {
      const linkDatavalue =
        item.content_attributes.link_url ||
        item.content_attributes?.link_data?.value;
      if (isShowIcon) {
        return item.content_attributes.button_icon && linkDatavalue;
      }
      if (isShowImage) {
        return item.content_attributes.image && linkDatavalue;
      }
      if (isOnlytext) {
        return item.content_attributes.link_title && linkDatavalue;
      }
    });
    if (listItemFilter?.length === listItem?.length) {
      setCheckIsEnought(true);
    } else setCheckIsEnought(false);
  }, [listLink, block]);

  const buttonSizeOptions = [
    {
      label: t("ss_builder_button_link_small_size_label"),
      value: "small",
    },
    {
      label: t("ss_builder_button_link_middle_size_label"),
      value: "middle",
    },
    {
      label: t("ss_builder_button_link_large_size_label"),
      value: "large",
    },
  ];

  const handleSelectButtonSize = (arr) => {
    if (arr && arr.length) {
      const valueSize = arr[0];
      setButtonSize(valueSize);
    }
  };

  const handleConfirmListIcon = (data) => {
    const { valueIcon, valueUrlIcon } = data;
    setButtonIcon(valueIcon);
    setButtonIconUrl(valueUrlIcon);
  };

  const handleChangeButtonContent = (data) => {
    if (data?.length <= LINK_HEADING_MAXIMUM) {
      setButtonContent(data);
    }
  };

  const handleChangeButtonLinkDescription = (data) => {
    if (data?.length <= LINK_DESCRIPTION_MAXIMUM) {
      setButtonLinkDescription(data);
    }
  };

  const handleCancelListIcon = () => {
    setIsOpenSelectedIcon(false);
  };

  const handleSelectItemOnRow = (arr) => {
    if (arr && arr.length) {
      const value = arr[0];
      setItemOnRow(value);
    }
  };

  return (
    <>
      <div className="p-4 bg-white my-3">
        <div>
          <div className="text-[15px] text-[#666666]">
            {styleLinkDisplay === LINK_STYLE_DISPLAY.ONLY_TEXT && (
              <span className="text-red-600">*</span>
            )}
            {t("ss_builder_block_heading_label")}
          </div>
          <Input
            placeholder={t("ss_builder_button_link_placeholder_content_title")}
            value={buttonContent || ""}
            onChange={(val) => {
              handleChangeButtonContent(val);
            }}
            maxLength={LINK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${buttonContent.length}/${LINK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </div>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            value={buttonLinkDescription || ""}
            onChange={(val) => {
              handleChangeButtonLinkDescription(val);
            }}
            maxLength={LINK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${buttonLinkDescription.length}/${LINK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
        <Divider />
      </div>
      <div className="p-4 bg-white my-3">
        <div className=" bg-white my-3">
          <LinkStyleDisplay
            styleLinkDisplay={styleLinkDisplay}
            onUpdateStyleLinkDisplay={(val) => {
              if (
                val === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE ||
                val === LINK_STYLE_DISPLAY.TEXT_AND_ICON ||
                val === LINK_STYLE_DISPLAY.ONLY_TEXT
              ) {
                const a = Number(itemOnRowDefault) > 3 ? "1" : itemOnRowDefault;
                setItemOnRow(
                  Number(itemOnRowDefault) > 3 ? "1" : `${itemOnRowDefault}`
                );
              }
              if (
                val === LINK_STYLE_DISPLAY.ONLY_IMAGE ||
                val === LINK_STYLE_DISPLAY.ONLY_ICON
              ) {
                setLinkLayout(LINK_LAYOUTS.TEXT_UNDER);
              }
              setStyleLinkDisplay(val);
            }}
            isMulti
          />

          {(styleLinkDisplay === LINK_STYLE_DISPLAY.TEXT_AND_ICON ||
            styleLinkDisplay === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE) && (
            <>
              <Divider />
              <LinkLayouts
                linkLayout={linkLayout}
                onUpdateLinkLayout={(val) => {
                  if (val !== LINK_LAYOUTS.TEXT_ZIG_ZAG) {
                    setItemOnRow(itemOnRowDefault && "1");
                  }
                  setLinkLayout(val);
                }}
                isMultiLink
              />
            </>
          )}
          <Divider />
          {linkLayout !== LINK_LAYOUTS.TEXT_ZIG_ZAG && (
            <>
              <div>
                <span className="text-[15px] leading-[13px] text-[#666666]">
                  {/*<span className="text-red-600">*</span>*/}
                  {t("ss_builder_items_per_row")}
                </span>
                <Selector
                  options={
                    styleLinkDisplay === LINK_STYLE_DISPLAY.ONLY_ICON ||
                    styleLinkDisplay === LINK_STYLE_DISPLAY.ONLY_IMAGE
                      ? itemOnRowOptionsV2
                      : itemOnRowOptions
                  }
                  value={[itemOnRow]}
                  onChange={handleSelectItemOnRow}
                  columns={
                    styleLinkDisplay === LINK_STYLE_DISPLAY.ONLY_ICON ||
                    styleLinkDisplay === LINK_STYLE_DISPLAY.ONLY_IMAGE
                      ? 6
                      : 2
                  }
                  style={{
                    "--border": "solid transparent 1px",
                    "--checked-border": "solid var(--adm-color-primary) 1px",
                  }}
                />
              </div>
              <Divider />
            </>
          )}

          <div>
            <div className="text-[15px] text-[#666666]">
              {/*<span className="text-red-600">*</span>{" "}*/}
              {t("ss_builder_button_link_size_selection_title")}
            </div>
            <Selector
              options={buttonSizeOptions}
              value={[buttonSize]}
              onChange={handleSelectButtonSize}
              columns={3}
              style={{
                "--border": "solid transparent 1px",
                "--checked-border": "solid var(--adm-color-primary) 1px",
              }}
            />
          </div>
        </div>
      </div>

      <ListItems
        blockType={block?.block_type}
        listItemDataDefault={listLink}
        ITEM_MAXIMUM={30}
        onUpdateListDataItem={(val) => {
          setListLink(val);
        }}
        labelObject={labelObject}
        imageRatio={{}}
        styleOfParent={styleOfParent}
        setShowFooterBlockEdit={setShowFooterBlockEdit}
      />
      <PopupComponent
        visible={isOpenSelectedIcon}
        onMaskClick={() => handleCancelListIcon()}
        title={t("ss_builder_panel_add_icon_tab_title")}
        onClose={() => handleCancelListIcon()}
        classNameBody="h-[80vh] popup-hard"
      >
        <ListIconBuilder
          callbackCancelListIcon={handleCancelListIcon}
          callbackConfirmListIcon={handleConfirmListIcon}
          defaultNameIcon={buttonIcon}
        />
      </PopupComponent>
      <FullScreenPopup visible={isOpenTemplateSelection}></FullScreenPopup>
    </>
  );
};

export default ButtonMultiLinks;
