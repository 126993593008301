import axios from "axios";
import { CreateGatewayAxios } from "@share/api/createAxios";
import {
  DetailProduct,
  TruthOrDareModel,
} from "@features/mini-store/setting/interface";
import {
  AllowStoreInterface,
  MiniStoreInteface,
} from "@features/mini-store/setting/store-interface";

const baseUrl = process.env.NEXT_PUBLIC_BUILDER_API_PS1SIO_URL;
const STORE_PATH = {
  GET_DETAIL_TOD: `/api/mini-store/v1/catalog/gateway/product/trust-or-dare/detail`,
  GET_DETAIL_DONATION: `/api/mini-store/v1/catalog/gateway/donation/detail`,
  GET_STORE: "/api/mini-store/v1/store/creator",
  POST_SAVE_BRAND: "/api/mini-store/v1/creator/creator-setup-service/save",
  GET_DETAIL_BRAND: "api/mini-store/v1/creator/creator-setup-service/detail",
  GET_ALLOW_STORE: "/api/mini-store/v1/public/store/allow-store",
};

const instanceAxios = axios.create({
  baseURL: baseUrl,
});

const gatewayAxious = CreateGatewayAxios();

export const getDetailTOD = async (): Promise<TruthOrDareModel> => {
  const response = await gatewayAxious.get(STORE_PATH.GET_DETAIL_TOD);
  if (response.data.success) {
    return response.data.data;
  }
};
export const getDetailDonation = async (): Promise<DetailProduct> => {
  const response = await gatewayAxious.get(STORE_PATH.GET_DETAIL_DONATION);
  if (response.data.success) {
    return response.data.data;
  }
};
export const fetchStoreToken = async (): Promise<MiniStoreInteface> => {
  const response = await gatewayAxious.get(STORE_PATH.GET_STORE);
  if (response.data.success) {
    return response.data.data;
  }
};
export const postSaveBrandGift = async (data) => {
  const response = await gatewayAxious.post(STORE_PATH.POST_SAVE_BRAND, data);
  return response;
};
export const getDetailBrandGift = async (service) => {
  const response = await gatewayAxious.get(STORE_PATH.GET_DETAIL_BRAND, {
    params: {
      service: service,
    },
  });
  if (response.data.success) {
    return response.data.data;
  } else {
    return {};
  }
};
export const fetchAllowStore = async (
  cps_user_id
): Promise<AllowStoreInterface> => {
  const response = await gatewayAxious.get(STORE_PATH.GET_ALLOW_STORE, {
    params: {
      cps_user_id: cps_user_id,
    },
  });
  if (response.data.success) {
    return response.data.data;
  }
};
