import * as generateColor from "@share/lib/generateColor";

export const scrollToNewItem = (elementId) => {
  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const domQuery = `[${queryAttr}='${elementId}']`;
  setTimeout(() => {
    const addNewElement = document.querySelector(domQuery);
    addNewElement?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, 300);
};

export function _delete(obj, prop) {
  if (obj && obj[prop] && !obj[prop].length) delete obj[prop];
}

export function object_equals(x, y) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!object_equals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export function generateColorFunc(color: string) {
  const COLOR_LENGTH_DEFAULT = 9;
  const opacityColor =
    color?.length == COLOR_LENGTH_DEFAULT ? color.slice(-2) : "ff";
  const parseColorValues = generateColor.parseColorValues(color) || [];
  const calculateShades = generateColor.calculateShades(
    parseColorValues[0] ?? color.replace("#", "")
  );
  const calculateTints = generateColor.calculateTints(
    parseColorValues[0] ?? color.replace("#", "")
  );
  return [...calculateTints.reverse(), ...calculateShades].map(
    (ele) => `${ele}${opacityColor}`
  );
}

export const getMeta = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });

export const isExistedProductFromJson = (products) =>
  !!products?.some(
    (product) =>
      product.is_import_json === true || product?.is_import_by_url === true
  );

export const validateImageUrl = (url: string) => {
  const shopeeRegex =
    /https?:\/\/(.+\.)?(shopee\.[a-z\.]{2,}|shope\.ee|shp\.ee)\/file\//g;
  const lzdRegex = /https?:\/\/(?:.+\.)?(?:slatic|alicdn)\.[a-z\.]{2,}\//g;
  const tikiRegex = /https?:\/\/(?:.+\.)?tikicdn\.[a-z\.]{2,}\//g;
  return (
    shopeeRegex.test(url) ||
    lzdRegex.test(url) ||
    tikiRegex.test(url) ||
    validateEcoServerImageUrl(url)
  );
};

export const validateEcoServerImageUrl = (url: string) => {
  return (
    url?.startsWith("https://s.dev.ecomobi.com") ||
    url?.startsWith("https://file-dev.ecomobi.com") ||
    url?.startsWith("https://i.ecomobi.com") ||
    url?.startsWith("https://ss.ecomobi.com") ||
    url?.startsWith("https://passio-prod.s3-ap-southeast-1.amazonaws.com") ||
    url?.startsWith("https://image.passio.eco")
  );
};

export const isExistedInvalidImageUrl = (products) =>
  !!products?.some((product) => !validateImageUrl(product?.image));
