import React from "react";
import { Image } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { DEFAULT_UPLOAD_IMG_URL, RESIZE } from "@share/components/ImageUploadCommon/constants";

interface UploadImageProps {
  valueUrlImage: string;
  imageRatio: string;
  textHelper?: string;
  isUseBorderErr?: boolean;
  error?: boolean;
  hasFit?: boolean;
}

const UploadImage = (props: UploadImageProps) => {
  const { t } = useTranslation();
  const {
    valueUrlImage,
    imageRatio,
    textHelper = "ss_builder_support_upload_image",
    isUseBorderErr,
    error,
    hasFit = true,
  } = props;

  return valueUrlImage ? (
    <Image
      src={valueUrlImage}
      fit={hasFit ? "cover" : "contain"}
      style={{
        aspectRatio: imageRatio,
        border: `1px solid #D9D9D9`,
        borderRadius: "2px"
      }}
    />
  ) : (
    <div
      style={{
        border: !isUseBorderErr ? " 1px solid #EEEEEE" : `1px solid ${!error ? "#D9D9D9" : "#FF3141"}`,
        height: "200px",
        borderRadius: "2px"
      }}
      className="adm-image rounded-sm"
    >
      <div className="flex justify-center items-center">
        <Image
          src={DEFAULT_UPLOAD_IMG_URL}
          fit="cover"
          style={{
            backgroundSize: "cover",
            aspectRatio: imageRatio,
            width: "150px",
            height: "115px",
          }}
        />
      </div>
      <h5
        className="text-center font-medium mt-5 text-[#000000] text-[15.6px] leading-[130%] mb-0.5"
      >
        {t("ss_builder_no_photo_has_been_selected")}
      </h5>
      <div
        className="text-center text-[13px] text-[#8C8C8C] leading-[131%]"
      >
        {t(textHelper)}
      </div>
    </div>
  );
};

export default UploadImage;
