import { RightArrowIcon } from "@share/icons";
import { Input } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import { AFFILIATE_ADD_NEW_TOUR_CLASS_NAME } from "../ProductAddNewTourOpening/affiliate-add-new-tour-config";
const ProductGroupSelection = (props) => {
  const {
    productGroupPickerValue,
    placeholder,
    onOpenProductGroupSelection,
    isDisabledSelection,
  } = props;
  const { t } = useTranslation();
  return (
    <div
      className={cls(
        AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_1,
        "p-[12px_16px] bg-[#FFFFFF] flex flex-row items-center justify-between"
      )}
      onClick={() =>
        !isDisabledSelection ? onOpenProductGroupSelection() : {}
      }
      style={{
        opacity: isDisabledSelection ? "50%" : "",
        cursor: !isDisabledSelection ? "pointer" : "",
      }}
    >
      <div className="grow">
        <div className="text-[15px] leading-[23px] text-[#666666]">
          <span className="text-red-600">*</span>
          {t("ss_builder_product_group_selection_title")}
        </div>
        <Input
          value={productGroupPickerValue}
          placeholder={placeholder}
          readOnly
          className="w-full text-ellipsis overflow-hidden whitespace-nowrap"
        />
      </div>
      <RightArrowIcon
        className="w-4 h-4"
        fillColor="#333333"
      />
    </div>
  );
};

export default ProductGroupSelection;
