import { Selector } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";
import { BOOKING_PROFILE_STYLE } from "../../constant";

interface Props {
  onUpdateData: (val?: string) => void;
  data: string;
}

const StyleSelector = ({ onUpdateData, data }: Props) => {
  const { t } = useTranslation();
  const styleOptions = [
    {
      label: t("ss_builder_booking_profile_style_1"),
      value: BOOKING_PROFILE_STYLE.CARD_CONTENT,
    },
    {
      label: t("ss_builder_booking_profile_style_2"),
      value: BOOKING_PROFILE_STYLE.EMBED_CODE,
    },
  ];
  const handleSelectStyle = (arr) => {
    if (arr && arr.length) {
      const valueSize = arr[0];
      onUpdateData(valueSize);
    }
  };
  return (
    <div className="px-4 py-3 bg-white mb-3">
      <div className="text-[15px] text-[#666666]">
        {t("ss_builder_style")}
      </div>
      <Selector
        options={styleOptions}
        value={[data]}
        onChange={handleSelectStyle}
        columns={2}
        style={{
          "--border": "solid transparent 1px",
          "--checked-border": "solid var(--adm-color-primary) 1px",
          "--checked-color": "#FFFFFF"
        }}
      />
    </div>
  );
};

export default StyleSelector;
