import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "./components/layouts";

enum IMAGE_STATUS {
  INVALID_SIZE = "INVALID_SIZE",
  INVALID_FORMAT = "INVALID_FORMAT",
  NONE = "NONE",
  DONE = "DONE",
}

export type OutputImg = {
  base64?: any;
  url: string;
  file_name?: string;
};

interface ImageUploadCommonProps {
  value: OutputImg;
  onChange: (img: OutputImg) => void;
  layout_type: string;
  label?: string;
  isRequired?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  resizeType?: number;
  defaultImg?: string;
  hasFit?: boolean;
  className?: string;
  onError?: (val) => void;
  required?: boolean;
}

const ImageUploadCommon: React.FunctionComponent<
  React.PropsWithChildren<ImageUploadCommonProps>
> = (props) => {
  const { t } = useTranslation();
  const { 
    value, 
    onChange, 
    layout_type, 
    label, 
    isRequired = false,
    maxHeight,
    maxWidth,
    resizeType,
    defaultImg,
    hasFit,
    className,
    onError,
  } = props;

  const errorMsg = {
    INVALID_SIZE: t("ss_builder_upload_file_size_invalid_content"),
    INVALID_FORMAT: t("ss_builder_upload_file_format_invalid_content"),
  };

  const [imgStatus, setImgStatus] = useState<IMAGE_STATUS>(IMAGE_STATUS.NONE);
  const [listImg, setListImg] = useState([]);

  const handleChangeImgs = (imgs) => {
    const lastImg = imgs && imgs[imgs.length - 1];
    const isImgFormatInvalid = lastImg?.isValidFormat;
    const isImgSizeInvalid = lastImg?.isValidSize;

    if (isImgFormatInvalid) {
      setImgStatus(IMAGE_STATUS.INVALID_FORMAT);
      onError(true);
    } else if (isImgSizeInvalid) {
      setImgStatus(IMAGE_STATUS.INVALID_SIZE);
      onError(true);
    } else {
      setImgStatus(IMAGE_STATUS.DONE);
      setListImg(imgs);
      onChange({
        base64: lastImg.dataBase64,
        url: lastImg.url,
        file_name: lastImg.fileName,
      });
      onError(false);
    }
  };

  return (
    <>
      <div className={`p-4 bg-white ${className}`}>
        <div className="text-[15px] text-[#666666] pb-1 ">
          {isRequired && <span className="text-[15px] text-[#FF3141] ml-[-7px] font-simsun">*</span>}
          {label}
        </div>
        <Layout
          listImg={listImg}
          handleChangeImgs={handleChangeImgs}
          imgStatus={imgStatus}
          errorMsg={errorMsg}
          value={value}
          layout_type={layout_type}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          resizeType={resizeType}
          isRequired={isRequired}
          defaultImg={defaultImg}
          hasFit={hasFit}
          className={className}
        />
      </div>
    </>
  );
};

export default ImageUploadCommon;
