import React, { useCallback } from "react";
import { IMAGE_UPLOAD_LAYOUTS } from "../../constants";
import Layout1 from "./Layout1";
import Layout2 from "./Layout2";

export const IMAGE_UPLOAD_LAYOUT_COMPONENT = {
  [IMAGE_UPLOAD_LAYOUTS.LAYOUT_1]: Layout1,
  [IMAGE_UPLOAD_LAYOUTS.LAYOUT_2]: Layout2,
};

const Layout = (props) => {
  const LayoutRender = useCallback((props) => {
    let Element = <></>;
    if (IMAGE_UPLOAD_LAYOUT_COMPONENT[props?.layout_type]) {
      const RenderComponent = IMAGE_UPLOAD_LAYOUT_COMPONENT[props?.layout_type];
      return <RenderComponent {...props} />;
    }
    return Element;
  }, []);

  return <LayoutRender {...props}/>;
};

export default Layout;
