import { isValidUrl } from "@share/helper/validator";
import {
  requestGetDataFromShopeeAff,
  requestGetDataFromUrl,
} from "../ProductUploadFromSiteUrl/api";
import { addParamsToUrl } from "../ProductUploadFromSiteUrl/libs";
import { REG_ECOMERCE_ID, SOURCE_URL_TYPE } from "./constant";
import { PRODUCT_URL_CHECKING } from "@share/configs/const";

// export const convertProductType1 = (data) => {
//   let result;
//   if (data?.productAll && data?.categories) {
//     data?.productAll.forEach((prod) => {
//       data?.categories.forEach((cat) => {
//         if (
//           prod?.category_id === cat?.id ||
//           prod?.category_other_id === cat?.id
//         )
//           prod.category_name = cat?.name;
//       });
//       prod.url = prod?.product_url;
//       prod.product_name = prod?.product_title;
//       prod.product_image = prod?.image_url;
//     });
//     result = {
//       products: data?.productAll,
//     };
//   }
//   return result;
// };

export const convertProductType1 = async (data) => {
  const atDomain = data?.atDomain;
  const categories = data?.categories || [];
  const influencer_id = data?.landingPageInfo?.influencer_id;
  const landingpage_id = data?.landingPageInfo?.id;
  const use_aff_url = data?.use_aff_url;
  const url = `${atDomain}/api/v1/profile/list-product-paginate`;
  const products = await Promise.all(
    categories.map(async (cat) => {
      let category_id = cat?.id;
      let category_other_id = null;
      const performance_category_id = cat?.performance_category_id;
      if (performance_category_id) {
        category_id = performance_category_id;
        category_other_id = cat?.id;
      }
      const params = {
        influencer_id,
        landingpage_id,
        category_id,
        page: 1,
        category_other_id,
      };
      let response = null;
      try {
        response = await getATProductsWithCategory(url, params);
      } catch (err) {
        console.log("err", err);
      }
      const productsOfCat = response?.map((prod) => ({
        ...prod,
        category_name: cat?.name,
        url: prod?.product_url,
        product_name: prod?.product_title,
        product_image: prod?.image_url,
        use_aff_url,
      }));
      return productsOfCat || [];
    })
  );

  const productTotal = products.reduce((total, currentArray) => {
    if (Array.isArray(total)) {
      return total.concat(currentArray);
    }
  }, []);
  const result = {
    products: productTotal,
  };
  return result;
};

export const convertProductType2 = async (dataProd) => {
  let result = null;
  const urlSuffix = dataProd?.urlSuffix;
  const use_aff_url = dataProd?.use_aff_url;
  if (urlSuffix) {
    const groupLists = dataProd?.data?.landingPageBaseInfo?.groupList || [];
    if (groupLists?.length > 0) {
      const products = await Promise.all(
        groupLists.map(async (group) => {
          let response = null;
          const params = {
            urlSuffix: urlSuffix,
            groupId: group.groupId,
          };
          try {
            response = await requestGetDataFromShopeeAff(params);
          } catch (err) {
            console.log("err", err);
          }
          if (response?.data?.data?.landingPageLinkList?.linkList) {
            const linkList = response.data.data.landingPageLinkList.linkList;
            return linkList.map((prod) => ({
              ...prod,
              id: prod?.linkId,
              url: prod?.link,
              product_name: prod?.linkName,
              product_image: prod?.image,
              category_name: group.groupName,
              use_aff_url,
            }));
          }
        })
      );
      const productTotal = products.reduce((total, currentArray) => {
        if (Array.isArray(total)) {
          return total.concat(currentArray);
        }
      }, []);

      result = {
        products: productTotal,
      };
    }
  } else {
    let arrProd = [];
    if (dataProd?.data?.landingPageLinkList?.linkList) {
      dataProd?.data?.landingPageLinkList?.linkList.forEach((prod) => {
        arrProd.push({
          ...prod,
          id: prod?.linkId,
          url: prod?.link,
          product_name: prod?.linkName,
          product_image: prod?.image,
          use_aff_url,
        });
      });
      result = {
        products: arrProd,
      };
    }
  }
  return result;
};

export const getSourceUrl = (urlString) => {
  const url = new URL(urlString);
  let domainURL = "";
  let sourceType = SOURCE_URL_TYPE.OTHER;
  domainURL = url?.hostname;
  for (let key in SOURCE_URL_TYPE) {
    if (domainURL.includes(SOURCE_URL_TYPE[key]))
      sourceType = SOURCE_URL_TYPE[key];
  }

  return {
    source_url: url,
    domain: domainURL,
    source_type: sourceType,
  };
};

export const removeProtocolAndQueryParamUrl = (url) => {
  const urlWithoutProtocol = url.replace(/https?:\/\/(?:www\.)?/i, "");
  const urlWithoutQueryParams = urlWithoutProtocol.replace(/\/?\?.*/i, "");
  return urlWithoutQueryParams;
};

export const getEcomerceUrlId = (url) => {
  if (!url) return null;
  let result = "";
  let regx = null;
  if (REG_ECOMERCE_ID.shopee.test(url)) {
    regx = url.match(REG_ECOMERCE_ID.shopee);
    result = regx?.[1];
  } else if (REG_ECOMERCE_ID.lazada.test(url)) {
    regx = url.match(REG_ECOMERCE_ID.lazada);
    result = regx?.[1];
  } else if (REG_ECOMERCE_ID.tiki.test(url)) {
    regx = url.match(REG_ECOMERCE_ID.tiki);
    result = regx?.[1];
  }
  return result;
};

export const compareSameEcomerceUrl = (url1, url2) => {
  if (!url1 || !url2) return false;
  const ecomerceId1 = getEcomerceUrlId(url1);
  const ecomerceId2 = getEcomerceUrlId(url2);
  const checkUrl1 = removeProtocolAndQueryParamUrl(url1);
  const checkUrl2 = removeProtocolAndQueryParamUrl(url2);

  if (ecomerceId1 && ecomerceId2) {
    return ecomerceId1 === ecomerceId2;
  }
  return checkUrl1 === checkUrl2;
};

export async function getATProductsWithCategory(
  url,
  params,
  productTotal = []
) {
  const requestUrl = addParamsToUrl(url, params);
  const response = await requestGetDataFromUrl(requestUrl);
  if (response.success) {
    const products = response?.data?.products?.data || [];
    if (Array.isArray(products) && products.length > 0) {
      const newProductTotal = [...productTotal, ...products];
      // Nếu data không rỗng, tiếp tục đệ quy gọi api với page tăng lên 1
      const nextPageData = await getATProductsWithCategory(
        url,
        {
          ...params,
          page: params?.page + 1,
        },
        newProductTotal
      );
      // Kết hợp kết quả của các page
      return nextPageData;
    } else {
      // Nếu data rỗng, dừng đệ quy và trả về mảng data đã thu thập được
      return productTotal;
    }
  }
}

export const validateProductUrl = (prod, allProducts, detectUrlList) => {
  let result = {
    valid: false,
    url: {},
  };

  if (prod?.url && prod?.product_name && isValidUrl(prod?.url)) {
    const productId = String(prod?.id || prod?.uid)?.trim();
    const isExistedInAllProducts = allProducts?.some(
      (x) =>
        String(x?.id || x?.uid)?.trim() === productId ||
        compareSameEcomerceUrl(x?.origin_link, prod?.url)
    );
    const isExistedInDetectedUrlList = !!detectUrlList?.find((x) =>
      compareSameEcomerceUrl(x?.url, prod?.url)
    );

    const checkExist = isExistedInAllProducts || isExistedInDetectedUrlList;

    result = {
      valid: !checkExist,
      url: {
        url: prod?.url,
        valid_type: checkExist
          ? PRODUCT_URL_CHECKING.DUPLICATE
          : PRODUCT_URL_CHECKING.SUCCESS,
      },
    };
  } else if (prod?.url && prod?.product_name && !isValidUrl(prod?.url)) {
    result.url = {
      url: prod?.url,
      valid_type: PRODUCT_URL_CHECKING.INVALID,
    };
  } else if (!prod?.url || !prod?.product_name) {
    result.url = {
      url: prod?.url,
      valid_type: PRODUCT_URL_CHECKING.MISSING_REQUIRED,
    };
  } else {
    result.url = {
      url: prod?.url,
      valid_type: PRODUCT_URL_CHECKING.NOT_ADDED_YET,
    };
  }
  return result;
};

export const findIndexDuplicateLinks = (arr) => {
  const arrIndex = arr.map((item, index) => {
    if (arr.indexOf(item) != index) return index;
  });
  return arrIndex?.filter((x) => typeof x === "number");
};

export function detectMultiProductLinks(productLinks: string[]) {
  const duplicatedUrlIndexArr = findIndexDuplicateLinks(productLinks);
}
