import DndScrollable from "@share/components/dnd-scrollable";
import { CARD_STYLE } from "@share/configs/const";
import { Image, Selector } from "antd-mobile";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  cardStyleValue: string;
  onUpdateCardStyleValue: any;
};

const CardStyle = (props: Props) => {
  const { cardStyleValue, onUpdateCardStyleValue } = props;
  const { t } = useTranslation();
  const cardStyleOptions = useMemo(
    () => [
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/display-styles/card-grid-type.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: (
          <div className="mt-1">{t("ss_builder_style_grid_label")}</div>
        ),
        value: CARD_STYLE.GRID,
      },
      {
        label: (
          <Image
            fit="cover"
            src="https://image.passio.eco/page-builder/page-builder/display-styles/card-slide-type.png"
            height={96}
            width={96}
            className="m-auto"
          />
        ),
        description: (
          <div className="mt-1">{t("ss_builder_style_slide_label")}</div>
        ),
        value: CARD_STYLE.SLIDE,
      },
    ],
    []
  );
  const handleSelectCardStyle = (arr) => {
    if (arr && arr.length) {
      const valueRatio = arr[0];
      onUpdateCardStyleValue(valueRatio);
    }
  };
  return (
    <div>
      <span className="text-[15px] leading-[13px] text-[#666666]">
        {t("ss_builder_style")}
      </span>
      <DndScrollable className="selector-scroll">
        <Selector
          options={cardStyleOptions}
          value={[cardStyleValue]}
          onChange={handleSelectCardStyle}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      </DndScrollable>
    </div>
  );
};

export default CardStyle;
