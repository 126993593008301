import { stepsInterface } from "@share/components/ProductTourComponent/interface";

export const AFFILIATE_SETTING_TOUR_CLASS_NAME = {
  STEP_1: "affiliate-setting-tour-more-options-header",
  STEP_2: "affiliate-setting-tour-heading-area",
  STEP_3: "affiliate-setting-tour-layout-config",
  STEP_4: "affiliate-setting-tour-group-management-button",
  STEP_5: "affiliate-setting-tour-group-tab-list",
  STEP_6: "affiliate-setting-tour-product-addition",
  STEP_7: "affiliate-setting-product-more-options",
  STEP_8: "affiliate-setting-saving-button",
  STEP_9: "affiliate-setting-user-manual",
};

export const STEP_INDEX = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2,
  STEP_4: 3,
  STEP_5: 4,
  STEP_6: 5,
  STEP_7: 6,
  STEP_8: 7,
  STEP_9: 8,
};

export const steps: stepsInterface[] = [
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-1.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_1",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_1",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_1,
    tooltip_class: "show-label-text",
    position: "bottom",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-2.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_2",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_2",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_2,
    tooltip_class: "",
    position: "bottom-middle-aligned",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-3.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_3",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_3",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_3,
    tooltip_class: "auto",
    position: "top",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-4.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_4",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_4",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_4,
    tooltip_class: "",
    position: "top",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-5.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_5",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_5",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_5,
    tooltip_class: "",
    position: "bottom",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-6.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_6",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_6",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_6,
    tooltip_class: "",
    position: "bottom",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-7.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_7",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_7",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_7,
    tooltip_class: "",
    position: "top",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-setting-tour-step-8.gif",
    title_key: "ss_builder_affiliate_setting_tour_title_step_8",
    intro_key: "ss_builder_affiliate_setting_tour_content_step_8",
    element_class: AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_8,
    tooltip_class: "show-label-text product-tour-custom",
    position: "top",
  },
];
