export const placeHolderImageIcon = `<svg class="img-placeholder" width="52" height="61" viewBox="0 0 52 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1467_525870)">
<path d="M38.3866 7.38672C41.1049 7.38672 43.3081 9.58497 43.3081 12.2975V45.333C43.3081 48.0456 41.1049 50.2439 38.3866 50.2439H14.2255C12.1315 50.2439 10.3596 48.9582 9.64387 47.119C9.42889 46.5654 9.30371 45.9582 9.30371 45.333V12.2975C9.30371 9.58497 11.5069 7.38672 14.2255 7.38672L38.3866 7.38672Z" fill="#F5F5F5"/>
<path d="M22.7261 23.4583C22.7261 25.4306 21.1236 27.0298 19.1466 27.0298C17.1699 27.0298 15.5674 25.4306 15.5674 23.4583C15.5674 21.4859 17.1699 19.8867 19.1466 19.8867C21.1236 19.8867 22.7261 21.4859 22.7261 23.4583Z" fill="url(#paint0_linear_1467_525870)"/>
<path d="M37.1515 50.2433H14.2255C12.1315 50.2433 10.3596 48.9576 9.64387 47.1184C9.42889 46.5648 9.30371 45.9576 9.30371 45.3325V38.5291L15.1382 32.7077C16.3553 31.4933 18.3595 31.4933 19.5766 32.7077L37.1515 50.2433Z" fill="url(#paint1_linear_1467_525870)"/>
<path d="M43.3078 34.9577V45.3326C43.3078 48.0471 41.1066 50.2434 38.3863 50.2434H14.2252C12.1312 50.2434 10.3593 48.9577 9.64355 47.1185L30.3503 26.4577C31.5674 25.2433 33.572 25.2433 34.7887 26.4577L43.3078 34.9577Z" fill="url(#paint2_linear_1467_525870)"/>
</g>
<defs>
<filter id="filter0_d_1467_525870" x="0.651364" y="0.734372" width="51.3086" height="60.1621" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="4.32617"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1467_525870"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1467_525870" result="shape"/>
</filter>
<linearGradient id="paint0_linear_1467_525870" x1="19.1467" y1="19.8867" x2="19.1467" y2="27.0298" gradientUnits="userSpaceOnUse">
<stop stop-color="#BEBEBE"/>
<stop offset="1" stop-color="#707070"/>
</linearGradient>
<linearGradient id="paint1_linear_1467_525870" x1="23.2276" y1="31.7969" x2="23.2276" y2="50.2433" gradientUnits="userSpaceOnUse">
<stop stop-color="#BEBEBE"/>
<stop offset="1" stop-color="#707070"/>
</linearGradient>
<linearGradient id="paint2_linear_1467_525870" x1="26.4757" y1="25.5469" x2="26.4757" y2="50.2434" gradientUnits="userSpaceOnUse">
<stop stop-color="#BEBEBE"/>
<stop offset="1" stop-color="#707070"/>
</linearGradient>
</defs>
</svg>
`;

export const prevTourIcon = `<svg
width="17"
height="17"
viewBox="0 0 24 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M17 11.0002H9.41002L12.71 7.71019C12.8983 7.52188 13.0041 7.26649 13.0041 7.00019C13.0041 6.73388 12.8983 6.47849 12.71 6.29019C12.5217 6.10188 12.2663 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019L6.29002 11.2902C6.19898 11.3853 6.12761 11.4974 6.08002 11.6202C5.98 11.8636 5.98 12.1367 6.08002 12.3802C6.12761 12.5029 6.19898 12.6151 6.29002 12.7102L11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5065 17.8783 12.6171 17.8039 12.71 17.7102C12.8037 17.6172 12.8781 17.5066 12.9289 17.3848C12.9797 17.2629 13.0058 17.1322 13.0058 17.0002C13.0058 16.8682 12.9797 16.7375 12.9289 16.6156C12.8781 16.4937 12.8037 16.3831 12.71 16.2902L9.41002 13.0002H17C17.2652 13.0002 17.5196 12.8948 17.7071 12.7073C17.8947 12.5198 18 12.2654 18 12.0002C18 11.735 17.8947 11.4806 17.7071 11.2931C17.5196 11.1055 17.2652 11.0002 17 11.0002Z"
  fill="#EE3244"
/>
</svg>`;

export const nextTourIcon = `<svg
width="17"
height="17"
viewBox="0 0 24 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z"
  fill="#fff"
/>
</svg>`;
