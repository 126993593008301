import { useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import FormInput from "@features/block/components/FormInput";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import FullScreenPopup from "@share/components/full-screen-popup";
import ListIconBuilder from "@share/components/ListIconBuilder";
import PopupComponent from "@share/components/PopupComponent";
import {
  LINK_LAYOUTS,
  LINK_STYLE_DISPLAY,
  LINK_TEMPLATE,
  LINK_TEMPLATE_ARR,
  TAB_ICON_DEFAULT,
} from "@share/configs/const";
import { checkOpenNewTab } from "@share/helper/validator";
import IconRender, { TypeIconEnum } from "@share/IconRender";
import {
  AddIcon,
  InformationIcon,
  InformationTriangleIcon,
  SampleSelectionIcon,
} from "@share/icons";
import { mockUpload } from "@share/lib";
import {
  Button,
  Checkbox,
  Divider,
  Image,
  ImageUploader,
  Input,
  Popover,
  Selector,
  TextArea,
} from "antd-mobile";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import LinkLayouts from "./LinkLayouts";
import LinkStyleDisplay from "./LinkStyleDisplay";
import LinkTemplateSelection from "./LinkTemplateSelection";

const LINK_HEADING_MAXIMUM = 100;
const LINK_DESCRIPTION_MAXIMUM = 250;
const BLOCK_TITLE_MAXIMUM = 50;
const BLOCK_DESCRIPTION_MAXIMUM = 250;

const ButtonLink = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    setButtonLoading,
    handleCancelBlock,
    defaultBlockData,
  } = props;
  const { t } = useTranslation();
  const UID = `button-link-${uuidv4()}`;

  // const edittingBlock = useAppSelector((state) => state.edittingBlock);
  // const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const defaultValueEdit =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const titleBlockDefault = "Link";

  const originUrlDefault =
    defaultValueEdit?.content_attributes?.origin_url || "";

  const affiliateUrlDefault =
    defaultValueEdit?.content_attributes?.target_url || "";
  const buttonSizeDefault =
    defaultValueEdit?.style_attributes?.button_size || "middle";

  const buttonContentDefault =
    defaultValueEdit?.content_attributes?.button_content || "";

  const buttonLinkDescriptionDefault =
    defaultValueEdit?.content_attributes?.button_description || "";

  const buttonIconDefault =
    defaultValueEdit?.content_attributes?.button_icon || TAB_ICON_DEFAULT;

  const buttonIconUrlDefault =
    defaultValueEdit?.content_attributes?.button_icon_url || null;

  const hasAffiliateUrlDefault = defaultValueEdit?.content_attributes
    ?.use_aff_url
    ? !!(defaultValueEdit?.content_attributes?.use_aff_url === 1)
    : true;
  const openInNewTabDefault =
    defaultValueEdit?.content_attributes?.open_new_tab || false;
  const [isEcomerce, setIsEcomerce] = useState(false);
  const imageDefault = defaultValueEdit?.content_attributes?.image || null;

  const defaultLinkData = defaultValueEdit?.content_attributes?.link_data || {
    type: LinkTypeEnum.WEB,
    value: originUrlDefault,
  };

  const styleLinkDisplayDefault =
    defaultValueEdit?.style_attributes?.button_link_style ||
    LINK_STYLE_DISPLAY.TEXT_AND_ICON;

  const linkLayoutDefault =
    defaultValueEdit?.style_attributes?.button_link_layout ||
    LINK_LAYOUTS.TEXT_RIGHT;

  const linkTemplateDefault =
    defaultValueEdit?.style_attributes?.button_link_template ||
    LINK_TEMPLATE.TEMPLATE1;

  const blockTitleDefault =
    defaultValueEdit?.content_attributes?.block_title || "";
  const blockDescriptionDefault =
    defaultValueEdit?.content_attributes?.block_description || "";

  const [buttonSize, setButtonSize] = useState(buttonSizeDefault);
  const [buttonContent, setButtonContent] = useState(buttonContentDefault);
  const [buttonLinkDescription, setButtonLinkDescription] = useState(
    buttonLinkDescriptionDefault
  );
  const [buttonIcon, setButtonIcon] = useState(buttonIconDefault);
  const [buttonIconUrl, setButtonIconUrl] = useState(buttonIconUrlDefault);
  const [isOpenSelectedIcon, setIsOpenSelectedIcon] = useState(false);
  const [isOpenTemplateSelection, setIsOpenTemplateSelection] = useState(false);
  const [originUrl, setOriginUrl] = useState(originUrlDefault);
  const [affiliateUrl, setAffiliateUrl] = useState(affiliateUrlDefault);
  const [hasAffiliateUrl, setHasAffiliateUrl] = useState(
    hasAffiliateUrlDefault
  );

  const [linkData, setLinkData] = useState<LinkDataInterface>(defaultLinkData);
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isCheckDisableBtnSave, setIsCheckDisableBtnSave] = useState(false);
  const [styleLinkDisplay, setStyleLinkDisplay] = useState(
    styleLinkDisplayDefault
  );
  const [linkLayout, setLinkLayout] = useState(linkLayoutDefault);
  const [linkTemplate, setLinkTemplate] = useState(linkTemplateDefault);

  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [valueUrlImage, setValueUrlImage] = useState(imageDefault);
  const [valueNameImage, setValueNameImage] = useState(null);
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);

  const [blockTitle, setBlockTitle] = useState(blockTitleDefault);
  const [blockDescription, setBlockDescription] = useState(
    blockDescriptionDefault
  );

  const checkEcomerce = (data) => {
    if (data) {
      try {
        const ecommerceOrSocial = new URL(data);
        setIsEcomerce(checkOpenNewTab(ecommerceOrSocial));
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    if (linkData && linkData.type == LinkTypeEnum.WEB) {
      checkEcomerce(linkData.target_url);
    } else if (originUrl) checkEcomerce(originUrl);
  }, [originUrl, linkData]);

  useEffect(() => {
    if (!block.uid) {
      const defaultImageData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.BUTTON_LINK,
        title: titleBlockDefault,
        content_attributes: {
          button_content: buttonContent,
          button_icon: buttonIcon,
          button_icon_url: buttonIconUrl,
          use_aff_url: hasAffiliateUrl === true ? 1 : 2,
          target_url: affiliateUrl,
          origin_url: originUrl,
          button_description: buttonLinkDescription,
          image: valueImageBase64 || valueUrlImage,
          image_name: valueNameImage,
          link_data: linkData,
          image_base64: valueImageBase64,
          open_new_tab: isEcomerce ? true : openNewTab,
          is_ecomerce: isEcomerce,
          block_title: blockTitle,
          block_description: blockDescription,
        },
        style_attributes: {
          button_size: buttonSize,
          button_link_template: linkTemplate,
          button_link_layout: linkLayout,
          button_link_style: styleLinkDisplay,
        },
      };
      onUpdateData(defaultImageData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          button_content: buttonContent,
          button_icon: buttonIcon,
          button_icon_url: buttonIconUrl,
          use_aff_url: hasAffiliateUrl === true ? 1 : 2,
          target_url: affiliateUrl,
          origin_url: originUrl,
          button_description: buttonLinkDescription,
          image: valueImageBase64 || valueUrlImage,
          image_name: valueNameImage,
          image_base64: valueImageBase64,
          open_new_tab: isEcomerce ? true : openNewTab,
          is_ecomerce: isEcomerce,
          link_data: linkData,
          block_title: blockTitle,
          block_description: blockDescription,
        },
        style_attributes: {
          button_size: buttonSize,
          button_link_template: linkTemplate,
          button_link_layout: linkLayout,
          button_link_style: styleLinkDisplay,
        },
      });
    }
  }, [
    buttonIconUrl,
    buttonContent,
    buttonIcon,
    buttonSize,
    hasAffiliateUrl,
    affiliateUrl,
    originUrl,
    buttonLinkDescription,
    valueImageBase64,
    valueUrlImage,
    valueNameImage,
    linkTemplate,
    linkLayout,
    styleLinkDisplay,
    linkData,
    openNewTab,
    isEcomerce,
    blockDescription,
    blockTitle,
  ]);

  const isDisableBtnSave = useMemo(() => {
    if (
      (originUrl || linkData.value) &&
      ((buttonContent && styleLinkDisplay === LINK_STYLE_DISPLAY.ONLY_TEXT) ||
        (buttonIcon && styleLinkDisplay === LINK_STYLE_DISPLAY.TEXT_AND_ICON) ||
        (valueUrlImage &&
          styleLinkDisplay === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE)) &&
      (buttonContent !== buttonContentDefault ||
        buttonLinkDescription !== buttonLinkDescriptionDefault ||
        buttonIcon !== buttonIconDefault ||
        buttonSize !== buttonSizeDefault ||
        originUrl !== originUrlDefault ||
        JSON.stringify(linkData) !== JSON.stringify(defaultLinkData) ||
        linkTemplate !== linkTemplateDefault ||
        styleLinkDisplay !== styleLinkDisplayDefault ||
        linkLayout !== linkLayoutDefault ||
        valueUrlImage !== imageDefault ||
        openNewTab !== openInNewTabDefault ||
        blockDescription !== blockDescriptionDefault ||
        blockTitle !== blockTitleDefault ||
        (typeof hasAffiliateUrl === "boolean" &&
          hasAffiliateUrl !== hasAffiliateUrlDefault)) &&
      !isValidUrl &&
      !isCheckDisableBtnSave
    ) {
      return false;
    } else return true;
  }, [
    buttonContent,
    buttonContentDefault,
    originUrl,
    originUrlDefault,
    buttonIcon,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    buttonIconDefault,
    isValidUrl,
    buttonSize,
    buttonSizeDefault,
    isCheckDisableBtnSave,
    buttonLinkDescription,
    buttonLinkDescriptionDefault,
    linkTemplate,
    linkTemplateDefault,
    styleLinkDisplay,
    styleLinkDisplayDefault,
    valueUrlImage,
    imageDefault,
    linkLayout,
    linkLayoutDefault,
    openNewTab,
    openInNewTabDefault,
    linkData.value,
    blockDescription,
    blockDescriptionDefault,
    blockTitle,
    blockTitleDefault,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      buttonContent !== buttonContentDefault ||
      originUrl !== originUrlDefault ||
      buttonIcon !== buttonIconDefault ||
      buttonSize !== buttonSizeDefault ||
      linkTemplate !== linkTemplateDefault ||
      styleLinkDisplay !== styleLinkDisplayDefault ||
      linkLayout !== linkLayoutDefault ||
      valueUrlImage !== imageDefault ||
      buttonLinkDescription !== buttonLinkDescriptionDefault ||
      blockDescription !== blockDescriptionDefault ||
      blockTitle !== blockTitleDefault ||
      (typeof hasAffiliateUrl === "boolean" &&
        hasAffiliateUrl !== hasAffiliateUrlDefault) ||
      !isEqual(linkData, defaultLinkData)
    ) {
      return true;
    } else return false;
  }, [
    buttonContent,
    buttonContentDefault,
    originUrl,
    originUrlDefault,
    buttonIcon,
    buttonIconDefault,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    buttonSize,
    buttonSizeDefault,
    linkTemplate,
    linkTemplateDefault,
    styleLinkDisplay,
    styleLinkDisplayDefault,
    valueUrlImage,
    imageDefault,
    linkLayout,
    linkLayoutDefault,
    buttonLinkDescription,
    buttonLinkDescriptionDefault,
    blockDescription,
    blockDescriptionDefault,
    blockTitle,
    blockTitleDefault,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  useEffect(() => {
    setButtonLoading(isLoadingBtn);
  }, [isLoadingBtn]);

  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    if (linkData.type == LinkTypeEnum.WEB) {
      setOriginUrl(linkData.value);
    } else {
      setOriginUrl(null);
    }
    setLinkData(linkData);
  };

  const buttonSizeOptions = [
    {
      label: t("ss_builder_button_link_small_size_label"),
      value: "small",
    },
    {
      label: t("ss_builder_button_link_middle_size_label"),
      value: "middle",
    },
    {
      label: t("ss_builder_button_link_large_size_label"),
      value: "large",
    },
  ];

  const handleSelectButtonSize = (arr) => {
    if (arr && arr.length) {
      const valueSize = arr[0];
      setButtonSize(valueSize);
    }
  };

  useEffect(() => {
    setAffiliateUrl(linkData.target_url);
  }, [linkData.target_url]);

  const handleCheckAffiliateUrl = (data) => {
    setHasAffiliateUrl(data);
  };

  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
  };

  const handleOpenSelectedIcon = () => {
    setIsOpenSelectedIcon(true);
  };

  const handleConfirmListIcon = (data) => {
    const { valueIcon, valueUrlIcon } = data;
    setButtonIcon(valueIcon);
    setButtonIconUrl(valueUrlIcon);
  };

  const handleChangeButtonContent = (data) => {
    if (data?.length <= LINK_HEADING_MAXIMUM) {
      setButtonContent(data);
    }
  };

  const handleChangeButtonLinkDescription = (data) => {
    if (data?.length <= LINK_DESCRIPTION_MAXIMUM) {
      setButtonLinkDescription(data);
    }
  };

  const handleChangeBlockTitle = (data) => {
    if (data?.length <= BLOCK_TITLE_MAXIMUM) setBlockTitle(data);
  };
  const handleChangeBlockDescription = (data) => {
    if (data?.length <= BLOCK_DESCRIPTION_MAXIMUM) setBlockDescription(data);
  };

  const handleCancelListIcon = () => {
    setIsOpenSelectedIcon(false);
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
      setIsValidFileFormat(false);
      setIsValidFileSize(false);
    }
  };

  const onAffLoading = useCallback(
    (isLoading) => {
      setIsCheckDisableBtnSave(isLoading);
      setIsLoadingBtn(isLoading);
    },
    [setIsCheckDisableBtnSave, setIsLoadingBtn]
  );

  return (
    <>
      <div className="p-4 bg-white my-3">
        <div className="text-[15px] text-[#666666]">
          {t("ss_builder_sample_selection_label")}
        </div>
        <div>
          <Image
            src={
              LINK_TEMPLATE_ARR?.find((x) => x.value === linkTemplate)?.image
            }
          />
        </div>
        <div>
          <Button
            block
            color="default"
            size="middle"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
              gap: "5px",
              borderColor: "var(--adm-color-primary)",
              color: "var(--adm-color-primary)",
            }}
            onClick={() => setIsOpenTemplateSelection(true)}
          >
            <SampleSelectionIcon
              className="w-[17px] h-[17px]"
              fillColor="var(--adm-color-primary)"
            />
            <div>{t("ss_builder_sampling_change_label")}</div>
          </Button>
        </div>
      </div>
      <div className="p-4 bg-white my-3">
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_block_heading_label")}
          </div>
          <Input
            placeholder={t("ss_builder_button_link_placeholder_content_title")}
            value={blockTitle || ""}
            onChange={(val) => {
              handleChangeBlockTitle(val);
            }}
            maxLength={BLOCK_TITLE_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${blockTitle.length}/${BLOCK_TITLE_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_block_description")}
          </div>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            value={blockDescription || ""}
            onChange={(val) => {
              handleChangeBlockDescription(val);
            }}
            maxLength={BLOCK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${blockDescription.length}/${BLOCK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
        <Divider />
      </div>
      <div className="p-4 bg-white my-3">
        <LinkStyleDisplay
          styleLinkDisplay={styleLinkDisplay}
          onUpdateStyleLinkDisplay={(val) => setStyleLinkDisplay(val)}
        />
        {styleLinkDisplay === LINK_STYLE_DISPLAY.TEXT_AND_ICON && (
          <>
            <Divider />
            <div>
              <div className="text-[15px] text-[#666666]">
                <span className="text-red-600">*</span>
                {t("ss_builder_button_link_icon_title")}
              </div>
              <div className="flex flex-row items-center gap-4">
                <Button
                  style={{
                    padding: buttonIcon
                      ? "7px 10px 1px 10px"
                      : "7px 10px 3px 10px",
                    backgroundColor: "#F0F0F0",
                    border: "none",
                  }}
                  onClick={handleOpenSelectedIcon}
                >
                  <IconRender
                    type={TypeIconEnum.SETTING}
                    iconName={buttonIcon ?? TAB_ICON_DEFAULT}
                  />
                </Button>
                <div className="flex flex-row gap-2 justify-between grow">
                  <Button
                    block
                    color="primary"
                    size="middle"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    onClick={handleOpenSelectedIcon}
                  >
                    <AddIcon className="w-[18px] h-[18px]" />
                    <div className="text-[17px] leading-6">
                      {t("ss_builder_button_link_icon_btn_selection_label")}
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {styleLinkDisplay === LINK_STYLE_DISPLAY.TEXT_AND_IMAGE && (
          <>
            <Divider />
            <div className="">
              <div className="text-[15px] leading-[150%] text-[#666666]">
                <span className="text-red-600">*</span>
                {t("ss_builder_image_selection_label")}
              </div>
              <div className="grid grid-cols-[120px_auto] gap-4">
                <Image
                  src={
                    valueUrlImage ??
                    "https://i.ecomobi.com/ssp/passio-page/image-default.png"
                  }
                  fit="cover"
                  style={{
                    borderRadius: "8px",
                    maxWidth: "120px",
                    maxHeight: "120px",
                    backgroundColor: "#EEEEEE",
                    border: "1px solid #EEEEEE",
                  }}
                />
                <div>
                  <div className="mb-8 leading-4 text-[#8C8C8C] text-xs">
                    {t("ss_builder_theme_setting_background_content")}
                  </div>
                  <div className="flex gap-2">
                    <ImageUploader
                      onChange={handleImageUrl}
                      upload={(file) => mockUpload(file, 514, 980)}
                      className="flex-grow"
                      value={uploadedImageArr}
                      children={
                        <Button
                          color="primary"
                          fill="solid"
                          size="middle"
                          className="w-full"
                        >
                          {t("ss_builder_upload_button_label")}
                        </Button>
                      }
                    />
                  </div>
                </div>
              </div>
              {isValidFileFormat && (
                <div className="flex flex-row gap-1 items-center mt-1">
                  <InformationTriangleIcon
                    fillColor="#FF3141"
                    className="w-4 h-4"
                  />
                  <div className="text-[#FF3141]">
                    {t("ss_builder_upload_file_format_invalid_content")}
                  </div>
                </div>
              )}
              {isValidFileSize && (
                <div className="flex flex-row gap-1 items-center mt-1">
                  <InformationTriangleIcon
                    fillColor="#FF3141"
                    className="w-4 h-4"
                  />
                  <div className="text-[#FF3141]">
                    {t("ss_builder_upload_file_size_invalid_content")}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {styleLinkDisplay !== LINK_STYLE_DISPLAY.ONLY_TEXT && (
          <>
            <Divider />
            <LinkLayouts
              linkLayout={linkLayout}
              onUpdateLinkLayout={(val) => setLinkLayout(val)}
            />
          </>
        )}
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_button_link_size_selection_title")}
          </div>
          <Selector
            options={buttonSizeOptions}
            value={[buttonSize]}
            onChange={handleSelectButtonSize}
            columns={3}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
            }}
          />
        </div>
      </div>
      <div className="p-4 bg-white my-3">
        <div>
          <div className="text-[15px] text-[#666666]">
            {styleLinkDisplay === LINK_STYLE_DISPLAY.ONLY_TEXT && (
              <span className="text-red-600">*</span>
            )}
            {t("ss_builder_button_link_content_title")}
          </div>
          <Input
            placeholder={t("ss_builder_button_link_placeholder_content_title")}
            value={buttonContent || ""}
            onChange={(val) => {
              handleChangeButtonContent(val);
            }}
            maxLength={LINK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${buttonContent.length}/${LINK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_button_link_description")}
          </div>
          <Input
            placeholder={t("ss_builder_block_description_placeholder")}
            value={buttonLinkDescription || ""}
            onChange={(val) => {
              handleChangeButtonLinkDescription(val);
            }}
            maxLength={LINK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${buttonLinkDescription.length}/${LINK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <FormInput
            label={t("ss_builder_button_link_icon_origin_url_label")}
            isRequire={true}
          >
            <LinkInput
              item={linkData}
              onChange={(item) => {
                onUpdateLinkData(item);
              }}
              onLoading={onAffLoading}
              onError={(errorMessage) => {
                setIsValidUrl(!!errorMessage);
              }}
            />
          </FormInput>
        </div>
        <Divider />
        {linkData.type === LinkTypeEnum.WEB && (
          <>
            <div className="flex flex-row text-center items-center leading-[22px] gap-1">
              <Checkbox
                checked={
                  typeof hasAffiliateUrl !== "boolean" ? true : hasAffiliateUrl
                }
                onChange={handleCheckAffiliateUrl}
              >
                <div className="text-left">
                  {t("ss_builder_use_affiliate_link_label")}
                </div>
              </Checkbox>
              <Popover
                content={t("ss_builder_use_affiliate_link_description")}
                placement="top-start"
                mode="dark"
                trigger="click"
              >
                <InformationIcon
                  className="ml-1 w-[14px] h-[14px]"
                  onClick={() => {}}
                  fillColor="#1890FF"
                />
              </Popover>
            </div>
            <Divider />
            {!isEcomerce && (
              <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                <Checkbox
                  checked={!!openNewTab}
                  onChange={handleChangeOpenNewTab}
                >
                  <div className="text-left">
                    {t("ss_builder_open_new_tab_label")}
                  </div>
                </Checkbox>
                <Popover
                  content={t("ss_builder_open_new_tab_description")}
                  placement="top-start"
                  mode="dark"
                  trigger="click"
                >
                  <InformationIcon
                    className="ml-1 w-[14px] h-[14px]"
                    onClick={() => {}}
                    fillColor="#1890FF"
                  />
                </Popover>
              </div>
            )}
          </>
        )}
      </div>
      <PopupComponent
        visible={isOpenSelectedIcon}
        onMaskClick={() => handleCancelListIcon()}
        title={t("ss_builder_panel_add_icon_tab_title")}
        onClose={() => handleCancelListIcon()}
        classNameBody="h-[80vh] popup-hard"
      >
        <ListIconBuilder
          callbackCancelListIcon={handleCancelListIcon}
          callbackConfirmListIcon={handleConfirmListIcon}
          defaultNameIcon={buttonIcon}
        />
      </PopupComponent>

      <FullScreenPopup visible={isOpenTemplateSelection}>
        <LinkTemplateSelection
          linkTemplateDefault={linkTemplate}
          onUpdateLinkTemplate={(val) => setLinkTemplate(val)}
          onCancelLinkTemplate={() => setIsOpenTemplateSelection(false)}
        />
      </FullScreenPopup>
    </>
  );
};

export default ButtonLink;
