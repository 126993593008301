import React from "react";
import { Checkbox, List } from "antd-mobile";
import { ItemUIProps } from "./ListItemWithCheckbox";
import { IconIsChecked, IconIsNotChecked } from "@share/icons";

interface ItemWithCheckboxProps {
  itemUI: React.FunctionComponent<ItemUIProps>;
  data: ItemUIProps;
  checked: boolean;
  onChecked: (value: boolean) => void;
}

const ItemWithCheckbox: React.FunctionComponent<ItemWithCheckboxProps> = (
  props
) => {
  const { itemUI: Item, checked, onChecked, data } = props;

  return (
    <List className="mt-2">
      <List.Item
        prefix={
          <Checkbox
            checked={checked}
            onChange={onChecked}
            icon={(checked) =>
              checked ? <IconIsChecked /> : <IconIsNotChecked />
            }
          />
        }
      >
        <Item {...data} />
      </List.Item>
    </List>
  );
};

export default ItemWithCheckbox;
