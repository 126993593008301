import { Divider, Input, Toast } from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { v4 as uuidv4 } from "uuid";
import { CheckCircleIcon } from "@share/icons";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";

type Props = {
  onCancelAffiliateProductGroupAddNew: any;
  affiliateProductGroupDefault?: any;
  onSaveAffiliateProductGroupAddNew: any;
  affiliateProductGroupList: any;
};

const PRODUCT_GROUP_NAME_MAX_LENGTH = 25;

const AffiliateProductGroupAddNew = (props: Props) => {
  const {
    onCancelAffiliateProductGroupAddNew,
    affiliateProductGroupDefault,
    onSaveAffiliateProductGroupAddNew,
    affiliateProductGroupList,
  } = props;

  const valueInputDefault = useMemo(
    () => affiliateProductGroupDefault?.title,
    [affiliateProductGroupDefault]
  );

  const [valueInput, setValueInput] = useState(valueInputDefault || "");
  const { t } = useTranslation();

  useEffect(() => {
    if (valueInputDefault) {
      setValueInput(valueInputDefault);
    }
  }, [valueInputDefault]);

  const handleConfirmAffiliateProductGroupAddNew = useCallback(() => {
    const defaultAffiliateProductData = {
      uid: `affiliate-product-${uuidv4()}`,
      sort_order: 1,
      enable: 1,
      block_type: BLOCK_TYPE.AFF_PRODUCT,
      title: valueInput,
      content_attributes: {
        items: [],
        title_product_group: valueInput,
      },
      style_attributes: {},
    };
    if (!affiliateProductGroupDefault) {
      onSaveAffiliateProductGroupAddNew(
        defaultAffiliateProductData,
        defaultAffiliateProductData.uid
      );
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_add_new_product_group_successfully_label")}
          </div>
        ),
      });
    } else {
      onSaveAffiliateProductGroupAddNew(
        {
          ...affiliateProductGroupDefault,
          title: valueInput,
          content_attributes: {
            ...affiliateProductGroupDefault?.content_attributes,
            title_product_group: valueInput,
          },
        },
        affiliateProductGroupDefault.uid
      );
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_change_name_product_group_successfully_label")}
          </div>
        ),
      });
    }
    onCancelAffiliateProductGroupAddNew();
  }, [valueInput]);

  const isExistProductGroup = useMemo(() => {
    if (
      affiliateProductGroupList
        ?.filter((e) => e?.uid !== affiliateProductGroupDefault?.uid)
        ?.find((x) => x?.title?.toLowerCase() === valueInput.toLowerCase())
    ) {
      return true;
    } else return false;
  }, [affiliateProductGroupList, valueInput, affiliateProductGroupDefault]);

  const isDisableConfirmAffiliateProductGroupAddNew = useMemo(() => {
    if (
      valueInput &&
      valueInput !== valueInputDefault &&
      !isExistProductGroup
    ) {
      return false;
    } else return true;
  }, [valueInput, valueInputDefault, isExistProductGroup]);

  return (
    <div>
      <div
        className="flex flex-col gap-1 py-3 px-4"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <div className="text-[15px] text-[#666666] leading-[150%]">
          <span className="text-red-600">*</span>
          {t("ss_builder_edit_affiliate_product_group_title")}
        </div>
        <Input
          onChange={(val) => {
            setValueInput(val);
          }}
          placeholder={t(
            "ss_builder_affiliate_product_btn_see_more_placeholder"
          )}
          value={valueInput || ""}
          style={{
            // border: "1px solid #F1F1F1",
            padding: "5px",
            borderRadius: "5px",
          }}
          autoFocus
          maxLength={PRODUCT_GROUP_NAME_MAX_LENGTH}
        />
        <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${valueInput.length}/${PRODUCT_GROUP_NAME_MAX_LENGTH}`}</div>
        {isExistProductGroup && (
          <div className="text-[13px] leading-[131%] text-[#FF3141]">
            {t("ss_builder_existed_product_group_label")}
          </div>
        )}
      </div>
      <ButtonConfirmPopup
        onConfirm={
          !isDisableConfirmAffiliateProductGroupAddNew
            ? handleConfirmAffiliateProductGroupAddNew
            : () => {}
        }
        title={t(
          "ss_builder_confirm_affiliate_product_group_add_new_panel_label"
        )}
        isDisable={isDisableConfirmAffiliateProductGroupAddNew}
      />
    </div>
  );
};

export default AffiliateProductGroupAddNew;
