import { BaseUpdateDataInterface } from "@share/api";
import { CreateGatewayAxios } from "@share/api/createAxios";

const API_PATH = {
  SAVE_DISPlAY_SETTING: "/api/v1/builder/site-setting/display-setting-v2",
};

// interface SaveAttributeData {
//     title?: string
//     enable?: number
// }

const gatewayInstance = CreateGatewayAxios();

export const saveDiplaySetting = (
  id,
  baseData: BaseUpdateDataInterface,
  data: any
) => {
  return gatewayInstance.post(API_PATH.SAVE_DISPlAY_SETTING, {
    id,
    ...baseData,
    site_setting_id: undefined,
    data: { display_setting: data },
  });
};
