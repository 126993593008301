import { BaseUpdateDataInterface } from "@share/api";
import { CreateGatewayAxios } from "@share/api/createAxios";

const API_PATH = {
  SAVE_ATTRIBUTES: "/api/v1/builder/position/update-attributes",
};

interface SaveAttributeData {
  title?: string;
  enable?: number;
}

const gatewayInstance = CreateGatewayAxios();

export const saveAttributes = (
  id,
  baseData: BaseUpdateDataInterface,
  data: SaveAttributeData
) => {
  return gatewayInstance.post(API_PATH.SAVE_ATTRIBUTES, {
    ...baseData,
    data: { id: id, update_data: data },
  });
};
