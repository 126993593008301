import { MAX_WIDTH_SCREEN_SIZE, USER_DEVICE } from "@share/configs/const";
import Bowser from "bowser";
import { useState, useLayoutEffect, useEffect } from "react";
import { install } from "resize-observer";

export const usePopup = () => {
  const [visible, setVisible] = useState(false);
  const openPopup = () => setVisible(true);
  const closePopup = () => setVisible(false);
  return { visible, openPopup, closePopup };
};

export const useDetectdevice = () => {
  const [isDevice, setIsDevice] = useState("");

  const handleResize = () => {
    setIsDevice(detectScreenSize(window.innerWidth));
  };

  const detectScreenSize = (windowWidth) => {
    let detectWidthDevice = "";
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    if (Bowser.parse(userAgent)?.platform?.type)
      return Bowser.parse(userAgent)?.platform?.type;
    // if library not detect device
    if (windowWidth <= MAX_WIDTH_SCREEN_SIZE.MOBILE) {
      detectWidthDevice = USER_DEVICE.MOBILE;
    } else if (windowWidth > MAX_WIDTH_SCREEN_SIZE.DESKTOP) {
      detectWidthDevice = USER_DEVICE.DESKTOP;
    } else {
      detectWidthDevice = USER_DEVICE.TABLET;
    }
    return detectWidthDevice;
  };

  useLayoutEffect(() => {
    setIsDevice(detectScreenSize(window.innerWidth));
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isDevice;
};

export const useResizeObserveElement = (ref) => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  if (typeof window !== "undefined") {
    install();
  }

  useEffect(() => {
    if (!ref?.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (ref?.current?.offsetHeight) setHeight(ref.current.offsetHeight);
      if (ref?.current?.offsetWidth) setWidth(ref.current.offsetWidth);
    });
    resizeObserver.observe(ref?.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return { width, height };
};
