import { useAppDispatch, useAppSelector } from "@app/hooks";
import BlockWrapper from "@features/design/components/BlockWrapper";
import { Block } from "@features/main/store/interface";
import { getDetailDonation } from "@features/mini-store/share/api";
import { useAppTranslation } from "@features/mini-store/share/hook";
import { htmlDecode } from "@share/lib";
import { Button } from "antd-mobile";
import React from "react";
import { useQuery } from "react-query";
type Props = {
  blockData: Block;
  positionSlug: string;
  tabUid: string;
  index?: number;
  listBlocksSortOrder?: any;
  isFirstItem?: boolean;
  isApplyBlockSettings?: boolean;
  isHideMoreActions?: boolean;
};
export default function DonationDesign(props: Props) {
  const {
    blockData,
    positionSlug,
    tabUid,
    index,
    listBlocksSortOrder,
    isFirstItem,
    isApplyBlockSettings,
    isHideMoreActions,
  } = props;
  const { t } = useAppTranslation();

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const { inner_space_number, outside_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};

  const dispatch = useAppDispatch();
  const enable = blockData && blockData.enable;
  const allowService = window["AllowStore"];
  const loadDetailDonation = async () => {
    try {
      const res = await getDetailDonation();
      return res;
    } catch (e) {
      console.log(e);
    }
  };
  const { isLoading, data: detailDonate } = useQuery(
    "LoadDetailDonation",
    loadDetailDonation,
    { refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return <>loading</>;
  }
  return (
    <BlockWrapper
      blockType={blockData?.block_type}
      isEnable={enable}
      positionSlug={positionSlug}
      tabUid={tabUid}
      blockUid={blockData?.uid}
      index={index}
      listBlocksSortOrder={listBlocksSortOrder}
      outsideSpace={outside_space}
      insideSpace={inner_space_number}
      isFirstItem={isFirstItem}
      isApplyBlockSettings={isApplyBlockSettings}
      isHideMoreActions={isHideMoreActions}
    >
      <div className="flex flex-col justify-center items-center">
        <img
          className={
            (!detailDonate.name || !allowService?.allow_view_donation) &&
            "opacity-50"
          }
          src={"https://i.ecomobi.com/ssp/mini-store/donation/image-frame.png"}
          alt=""
        />
        {!!detailDonate.name && allowService?.allow_view_donation && (
          <>
            <h4 className={"w-full pt-2 pb-4 break-words text-center"}>
              {htmlDecode(detailDonate.name)}
            </h4>
            <Button
              // className={`w-full button-border-radius button-background-color button-border-width button-border-color button-border-style button-text-color button-box-shadow middle-button`}
              className={`w-full pp-button`}
            >
              {htmlDecode(detailDonate.btn_donation_title) || "hello"}
            </Button>
          </>
        )}
      </div>
    </BlockWrapper>
  );
}
