import { LinkTypeEnum } from "@features/block/components/LinkInput";
import { getServiceLabelKey } from "@features/block/components/LinkInput/components/ServiceSelectorPopup";
import { ImageInterface } from "@features/main/store/interface";
import { TickIcon } from "@share/icons";
import { Image } from "antd-mobile";
import cls from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

interface ImageListItemsProps {
  imageData: ImageInterface;
  index: number;
}

const StoryItemSelectMode: React.FunctionComponent<ImageListItemsProps> = (
  props: ImageListItemsProps
) => {
  const { t } = useTranslation();
  const { index, imageData } = props;

  const isEnable = !!(imageData && imageData.enable === 1);
  const linkDatavalue = imageData.origin_url || imageData?.link_data?.value;
  return (
    <div
      className={cls(
        "flex items-center gap-[6px] flex-row",
        !isEnable && "opacity-40"
      )}
    >
      <Image
        src={imageData.image}
        width={40}
        height={40}
        fit="cover"
      />
      <div className="flex flex-col w-[30vw] md:w-[10vw] grow">
        <div className="text-ellipsis whitespace-nowrap overflow-hidden text-lg leading-6 font-normal">
          {imageData.title}
        </div>
        <div
          className="flex"
          style={{ marginTop: "2px" }}
        >
          {imageData?.use_aff_url === 1 && linkDatavalue ? (
            <>
              <TickIcon />
              <span className="ml-1 text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
                {t("ss_builder_product_link_wrapped_label")}
              </span>
            </>
          ) : (
            <span className="text-[12px] text-[#8C8C8C] leading-4 font-normal  text-ellipsis whitespace-nowrap overflow-hidden">
              {imageData?.link_data?.type !== LinkTypeEnum.SERVICE
                ? linkDatavalue
                : t(getServiceLabelKey(linkDatavalue))}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryItemSelectMode;
