import { stepsInterface } from "@share/components/ProductTourComponent/interface";

export const AFFILIATE_ADD_NEW_TOUR_CLASS_NAME = {
  STEP_1: "affiliate-add-new-group-selection",
  STEP_2: "affiliate-add-new-link-detect-input",
  STEP_3: "affiliate-add-new-product-detect-information",
  STEP_4: "affiliate-add-new-button-group",
};
export const STEP_INDEX = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2,
  STEP_4: 3,
};

export const steps: stepsInterface[] = [
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-add-new-tour-step-1.gif",
    title_key: "ss_builder_affiliate_add_new_tour_title_step_1",
    intro_key: "ss_builder_affiliate_add_new_tour_content_step_1",
    element_class: AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_1,
    tooltip_class: "show-label-text",
    position: "bottom",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-add-new-tour-step-2.gif",
    title_key: "ss_builder_affiliate_add_new_tour_title_step_2",
    intro_key: "ss_builder_affiliate_add_new_tour_content_step_2",
    element_class: AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_2,
    tooltip_class: "",
    position: "bottom-middle-aligned",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-add-new-tour-step-3.gif",
    title_key: "ss_builder_affiliate_add_new_tour_title_step_3",
    intro_key: "ss_builder_affiliate_add_new_tour_content_step_3",
    element_class: AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_3,
    tooltip_class: "",
    position: "top",
  },
  {
    image_header:
      "https://image.passio.eco/page-builder/page-builder/affiliate-setting-tour/affiliate-add-new-tour-step-4.gif",
    title_key: "ss_builder_affiliate_add_new_tour_title_step_4",
    intro_key: "ss_builder_affiliate_add_new_tour_content_step_4",
    element_class: AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_4,
    tooltip_class: "show-label-text product-tour-custom",
    position: "top",
  },
];
