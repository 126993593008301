import { Image } from "antd-mobile";
import React, { useState } from "react";
export default function AntImage({
  src,
  ...rest
}: {
  src: string;
  [x: string]: any;
}) {
  const defaultImage =
    "https://image.passio.eco/page-builder/page-builder/images-default/product-default.jpeg";
  const [isError, setIsError] = useState(false);
  const imgSrc = isError ? defaultImage : src;
  return (
    <Image
      src={imgSrc}
      {...rest}
      onError={() => {
        setIsError(true);
      }}
    />
  );
}
