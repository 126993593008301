import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { POSITION_TYPE } from "@features/block/config/interface";
import { openPopup } from "@features/block/store/edittingBlockSlice";
import {
  IntroduceDoubleTapEnum,
  setIntroduceDoubleTapState,
} from "@features/design/store/externalStateSlice";
import {
  deleteBlock,
  disableEnableBlock,
  moveBlockToPosition,
  sortBlock,
} from "@features/main/store/positionsSlice";
import {
  ACTION_BLOCK,
  BLOCK_SETTING_DEFAULT_VALUE,
  DISABLE_BLOCK,
  ENABLE_BLOCK,
  MAX_WIDTH_CONTENT_LAYOUT,
  MAX_WIDTH_OUTSIDE_ON,
  POSITION_MAIN_LAYOUT,
} from "@features/share/configs/const";
import PopupComponent from "@share/components/PopupComponent";
import { KEY_BLOCK } from "@share/configs/const";
import Portal from "@share/HOC/portal";
import {
  ArrowDown,
  ArrowUp,
  CheckCircleIcon,
  CloseCircleIconV2,
  DeleteIcon,
  EditTabIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  FolderArrowRightIcon,
  MoreIcon,
  PenIcon,
} from "@share/icons";
import { handleCapitalizeFirstLetter } from "@share/lib";
import gaEvents, {
  ACTION_CONFIG,
  BLOCK_TYPE_EVENT_CONFIG,
  CATEGORY_CONFIG,
  EVENT_CONFIG,
} from "@share/lib/ga-events";
import { Checkbox, Input, Modal, Popover, Toast } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import cls from "classnames";
import sortBy from "lodash/sortBy";
import { default as React, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDoubleTap } from "use-double-tap";
import { useDebounce } from "usehooks-ts";
import AddNewBlock from "../AddNewBlock/AddNewBlock";
import { PRODUCT_TOUR_CLASS_NAME } from "../ProductTour/product-tour-config";
import { useResizeObserveElement } from "@share/hooks";

enum MOVE_ACTION {
  UP = "up",
  DOWN = "down",
}

interface CardProps {
  blockType: string;
  onClick?: any;
  children: any;
  description?: any;
  isEnable?: number;
  positionSlug?: string;
  blockUid?: string;
  tabUid?: string;
  index?: number;
  listBlocksSortOrder?: any;
  outsideSpace?: string | number;
  insideSpace?: string | number;
  isFirstItem?: boolean;
  extraStyle?: {
    [key: string]: string;
  };
  isApplyBlockSettings?: boolean;
  // useAdvancedSetting?: boolean;
  isHideMoreActions?: boolean;
  advancedSetting?: any;
}

const BlockWrapper = (props: CardProps) => {
  const {
    blockType,
    // onClick,
    children,
    isEnable,
    positionSlug,
    blockUid,
    tabUid,
    index,
    listBlocksSortOrder = [],
    // outsideSpace,
    // insideSpace,
    isFirstItem,
    isApplyBlockSettings = true,
    // useAdvancedSetting,
    isHideMoreActions = false,
    advancedSetting,
  } = props;
  const CONFIRM_TEXT = "OK";

  const BLOCKS_DONT_MOVE_TO_ANOTHER_POSITION = [
    BLOCK_TYPE.TAB,
    BLOCK_TYPE.TAB_BAR,
  ];

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const popoverRef = useRef(null);
  const blockRef = useRef(null);
  const positionState = useAppSelector((state) => state.positions);
  const siteSettings = useAppSelector((state) => state.siteSettings);
  const introduceDoubleTap = useAppSelector(
    (state) => state?.externalState?.introduce_double_tap
  );

  const { inner_space_number, outside_space, inner_space } =
    siteSettings?.display_setting?.block_setting?.block_style || {};
  const [isOpenConfirmEnterText, setIsOpenConfirmEnterText] = useState(false);
  const [isOpenPositionSelection, setIsOpenPositionSelection] = useState(false);
  const [valueConfirmText, setValueConfirmText] = useState(null);
  const [forceVisible, setForceVisible] = useState(false);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const [selectedBlock, setSelectedBlock] = useState<{
    position: POSITION_TYPE;
    uid: string;
  }>();
  const debouncedValueConfirmText = useDebounce(valueConfirmText, 500);
  const selectedBlockData = listBlocksSortOrder?.find(
    (x) => x?.uid === blockUid
  );
  const blockTypeEvent =
    BLOCK_TYPE_EVENT_CONFIG[blockType?.toUpperCase()] ||
    handleCapitalizeFirstLetter(blockType);

  const { height: blockHeight } = useResizeObserveElement(blockRef);
  const isValidBlockHeight = !!(blockHeight <= 32);

  const isEnableConfirm = useMemo(
    () =>
      !(
        typeof debouncedValueConfirmText === "string" &&
        debouncedValueConfirmText.toLowerCase() === CONFIRM_TEXT.toLowerCase()
      ),
    [debouncedValueConfirmText]
  );
  const actions: Action[] = [
    {
      key: ACTION_BLOCK.VISIBLE_BLOCK,
      icon:
        isEnable === ENABLE_BLOCK ? (
          <EyeVisibleIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ) : (
          <EyeHiddenIcon
            className="fill-white"
            width={20}
            height={20}
          />
        ),
      text:
        isEnable === ENABLE_BLOCK
          ? t("ss_builder_hide_block_label")
          : t("ss_builder_show_block_label"),
    },
    {
      key: ACTION_BLOCK.MOVE_UP_BLOCK,
      icon: (
        <ArrowUp
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_move_up_block_label"),
    },
    {
      key: ACTION_BLOCK.MOVE_DOWN_BLOCK,
      icon: (
        <ArrowDown
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_move_down_block_label"),
    },
    {
      key: ACTION_BLOCK.EDIT_BLOCK,
      icon: (
        <EditTabIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_edit_block_label"),
    },
    {
      key: ACTION_BLOCK.MOVE_BLOCK,
      icon: (
        <FolderArrowRightIcon
          className="w-5 h-5"
          fillColor="white"
        />
      ),
      text: t("ss_builder_move_product_label"),
    },
    {
      key: ACTION_BLOCK.DELETE_BLOCK,
      icon: (
        <DeleteIcon
          className="fill-white"
          width={20}
          height={20}
          fillColor="#fff"
        />
      ),
      text: t("ss_builder_delete_block_label"),
    },
  ];

  const actionFilterOptions = useMemo(() => {
    let actionFilter = actions;

    if (listBlocksSortOrder.length === 1) {
      actionFilter = actionFilter.filter(
        (actions) =>
          actions.key !== ACTION_BLOCK.MOVE_UP_BLOCK &&
          actions.key !== ACTION_BLOCK.MOVE_DOWN_BLOCK
      );
    }
    if (index === 0) {
      actionFilter = actionFilter.filter(
        (actions) => actions.key !== ACTION_BLOCK.MOVE_UP_BLOCK
      );
    }
    if (index === listBlocksSortOrder.length - 1) {
      actionFilter = actionFilter.filter(
        (actions) => actions.key !== ACTION_BLOCK.MOVE_DOWN_BLOCK
      );
    }
    if (
      BLOCKS_DONT_MOVE_TO_ANOTHER_POSITION.includes(blockType) ||
      positionSlug === POSITION_MAIN_LAYOUT.EXPAND
    ) {
      actionFilter = actionFilter.filter(
        (actions) => actions.key !== ACTION_BLOCK.MOVE_BLOCK
      );
    }

    if (positionSlug === POSITION_TYPE.EXPAND) {
      if ([BLOCK_TYPE.IMAGE_POPUP].includes(blockType)) {
        actionFilter = actionFilter.filter(
          (e) =>
            ![
              ACTION_BLOCK.MOVE_BLOCK,
              ACTION_BLOCK.MOVE_DOWN_BLOCK,
              ACTION_BLOCK.MOVE_UP_BLOCK,
            ].includes(e.key as any)
        );
      } else if (
        [
          BLOCK_TYPE.GIFT_FOOTER,
          BLOCK_TYPE.TRUTH_OR_DARE_FOOTER,
          BLOCK_TYPE.BRAND_GIFT_FOOTER,
        ].includes(blockType)
      ) {
        const floatingBlocks = positionState
          .find((position) => position.slug === POSITION_TYPE.EXPAND)
          .block_list.filter((block) =>
            [
              BLOCK_TYPE.GIFT_FOOTER,
              BLOCK_TYPE.TRUTH_OR_DARE_FOOTER,
              BLOCK_TYPE.BRAND_GIFT_FOOTER,
            ].includes(block.block_type)
          );
        if (floatingBlocks.length === 1) {
          actionFilter = actionFilter.filter(
            (e) =>
              ![
                ACTION_BLOCK.MOVE_BLOCK,
                ACTION_BLOCK.MOVE_DOWN_BLOCK,
                ACTION_BLOCK.MOVE_UP_BLOCK,
              ].includes(e.key as any)
          );
        } else {
          actionFilter = actionFilter.filter(
            (e) => ![ACTION_BLOCK.MOVE_BLOCK].includes(e.key as any)
          );
          if (index === 0) {
            actionFilter = actionFilter.filter(
              (actions) => actions.key !== ACTION_BLOCK.MOVE_UP_BLOCK
            );
          }
          if (index === floatingBlocks.length - 1) {
            actionFilter = actionFilter.filter(
              (actions) => actions.key !== ACTION_BLOCK.MOVE_DOWN_BLOCK
            );
          }
        }
      }
    }

    return actionFilter;
  }, [listBlocksSortOrder, index, blockType]);

  const moreElementRight = useMemo(() => {
    let value = "";
    if (!advancedSetting?.use_advanced_setting) {
      if (typeof outside_space === "boolean") {
        value = outside_space
          ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER + 4}px`
          : "4px";
      } else {
        value = `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER + 4}px`;
      }
    } else {
      value = advancedSetting?.use_block_space ? "16px" : "4px";
    }
    return value;
  }, [
    outside_space,
    advancedSetting?.use_advanced_setting,
    advancedSetting?.use_block_space,
  ]);

  const outsideSpaceMemo = useMemo(() => {
    let value = "";
    if (typeof outside_space === "boolean") {
      value = outside_space
        ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
        : "0px";
    } else {
      value = `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`;
    }
    return value;
  }, [outside_space]);

  const insideSpaceMemo = useMemo(() => {
    let value = "";
    if (typeof inner_space_number === "number") {
      value = `${inner_space_number}px`;
    } else {
      if (typeof inner_space === "boolean") {
        value = inner_space
          ? `${BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER}px`
          : "0px";
      } else {
        value = `${BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER}px`;
      }
    }
    return value;
  }, [inner_space_number, inner_space]);

  const blockHiddenOverlayWidth = useMemo(() => {
    let value = "";
    if (typeof outside_space === "boolean") {
      value = `calc(100% - ${outside_space ? "24px" : "0px"})`;
    } else {
      value = "calc(100% - 24px)";
    }
    return value;
  }, [outside_space]);

  const bindDoubleTapToBlock = useDoubleTap((event) => {
    openBlockEditor(event);
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.BLOCK,
      `Block_GoEditQuick_${blockTypeEvent}`,
      blockTypeEvent,
      ACTION_CONFIG.START_EDITING_QUICK
    );
  });

  const handleChangeBlockStatus = () => {
    if (isEnable === ENABLE_BLOCK) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK,
        `Block_Hide_${blockTypeEvent}`,
        blockTypeEvent,
        ACTION_CONFIG.HIDE
      );
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK,
        `Block_Show_${blockTypeEvent}`,
        blockTypeEvent,
        ACTION_CONFIG.SHOW
      );
    }
    const blockStatus =
      isEnable === ENABLE_BLOCK ? DISABLE_BLOCK : ENABLE_BLOCK;
    dispatch(
      disableEnableBlock({
        positionSlug,
        blockUid,
        blockStatus,
        tabUid,
      })
    );

    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {isEnable === ENABLE_BLOCK
            ? t("ss_builder_disable_block_successfully_label")
            : t("ss_builder_enable_block_successfully_label")}
        </div>
      ),
    });
  };

  const handleCloseModalConfirmText = () => {
    setIsOpenConfirmEnterText(false);
    setValueConfirmText(null);
  };

  const handleClickMoreIcon = (event) => {
    event.preventDefault();
  };

  const onRemoveItem = (index: number) => {
    const deletedBlock = listBlocksSortOrder.find(
      (ele, idx) => ele.uid === blockUid
    );
    dispatch(deleteBlock({ deletedBlock, positionSlug, tabUid }));
  };

  const openPositionSelection = () => setIsOpenPositionSelection(true);

  const openConfirmRemoveBlockPopup = () => {
    const isAffProduct =
      listBlocksSortOrder[index].block_type === BLOCK_TYPE.AFF_PRODUCT ||
      listBlocksSortOrder[index].block_type === BLOCK_TYPE.AFF_PRODUCT_GROUP;

    Modal.confirm({
      header: (
        <CloseCircleIconV2
          width={48}
          height={48}
          fillColor="var(--adm-color-primary)"
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_block_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {isAffProduct ? (
            <span className="break-words">
              {t("ss_builder_if_delete")},
              <span className="text-[#ee3244ff]">
                {" "}
                {t("ss_builder_all_setting_and_product")}{" "}
              </span>
              {t("ss_builder_in_block_delete_forever_are_you_sure")}
            </span>
          ) : (
            t("ss_builder_confirm-delete_block_label")
          )}
        </div>
      ),
      confirmText: t("ss_builder_confirm_delete_block_btn_label"),
      cancelText: t("ss_builder_cancel-delete_block_label"),
      onConfirm: () => {
        if (
          blockType === BLOCK_TYPE.AFF_PRODUCT ||
          blockType === BLOCK_TYPE.AFF_PRODUCT_GROUP
        ) {
          setIsOpenConfirmEnterText(true);
        } else {
          onRemoveItem(index);
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.BLOCK,
            `Block_Delete_${blockTypeEvent}`,
            blockTypeEvent,
            ACTION_CONFIG.DELETE
          );
          Toast.show({
            icon: (
              <CheckCircleIcon
                className="text-center inline-block w-[30px] h-[30px]"
                fillColor="#00B578"
              />
            ),
            content: (
              <div className="text-center">
                {t("ss_builder_delete_block_successfully_label")}
              </div>
            ),
          });
        }
      },
      onCancel: () => {
        return;
      },
    });
  };

  const handleUpdateStatusIntroduceDoubleTap = () => {
    dispatch(setIntroduceDoubleTapState(IntroduceDoubleTapEnum.FALSE));
  };
  const openModalIntroduceDoubleTap = () => {
    let isDontShowAgain = false;
    Modal.alert({
      image:
        "https://image.passio.eco/page-builder/page-builder/position-modal/introduce-edit-block.svg",
      title: t("ss_builder_introduce_double_tap_title"),
      className: "modal-product-tour modal-default",
      content: (
        <div>
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_introduce_double_tap_content")}
          </div>
          <Checkbox
            block
            onChange={(val) => (isDontShowAgain = val)}
            className="mt-5 radio_introduce"
          >
            {t("ss_builder_introduce_double_tap_label_check")}
          </Checkbox>
        </div>
      ),
      confirmText: (
        <span className="flex items-center w-full justify-center gap-2 leading-[140%] text-[17px]">
          <PenIcon
            width={17}
            height={17}
            fillColor="#fff"
          />{" "}
          {t("ss_builder_introduce_double_tap_button")}
        </span>
      ),
      onConfirm: () => {
        if (isDontShowAgain) {
          handleUpdateStatusIntroduceDoubleTap();
        }
        openBlockEditor();
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.BLOCK,
          `Block_GoEditPopover_${blockTypeEvent}`,
          blockTypeEvent,
          ACTION_CONFIG.START_EDITING_POPOVER
        );
        return;
      },
    });
  };

  const handleSelectBlockAction = (item: Action) => {
    const key = item?.key;
    switch (key) {
      case ACTION_BLOCK.VISIBLE_BLOCK:
        handleChangeBlockStatus();
        break;
      case ACTION_BLOCK.MOVE_UP_BLOCK:
        handleChangeBlockOrder(MOVE_ACTION.UP);
        break;
      case ACTION_BLOCK.MOVE_DOWN_BLOCK:
        handleChangeBlockOrder(MOVE_ACTION.DOWN);
        break;
      case ACTION_BLOCK.EDIT_BLOCK:
        if (introduceDoubleTap == IntroduceDoubleTapEnum.TRUE) {
          openModalIntroduceDoubleTap();
        } else {
          openBlockEditor();
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.BLOCK,
            `Block_GoEditPopover_${blockTypeEvent}`,
            blockTypeEvent,
            ACTION_CONFIG.START_EDITING_POPOVER
          );
        }
        break;
      case ACTION_BLOCK.MOVE_BLOCK:
        openPositionSelection();
        break;
      case ACTION_BLOCK.DELETE_BLOCK:
        openConfirmRemoveBlockPopup();
        break;
      default:
        if (introduceDoubleTap == IntroduceDoubleTapEnum.TRUE) {
          openModalIntroduceDoubleTap();
        } else {
          openBlockEditor();
          gaEvents.submitEventV2(
            passioPageProfile,
            CATEGORY_CONFIG.BLOCK,
            `Block_GoEditPopover_${blockTypeEvent}`,
            blockTypeEvent,
            ACTION_CONFIG.START_EDITING_POPOVER
          );
        }
    }
  };

  const handleRemoveBlock = () => {
    onRemoveItem(index);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_delete_block_successfully_label")}
        </div>
      ),
    });
  };

  const reorder = (startIndex: number, endIndex: number) => {
    let result = Array.from(listBlocksSortOrder);
    if (positionSlug === POSITION_TYPE.EXPAND) {
      const imgPopup = result.filter((e: any) =>
        [BLOCK_TYPE.IMAGE_POPUP].includes(e.block_type)
      );
      const floating = result.filter((e: any) =>
        [
          BLOCK_TYPE.TRUTH_OR_DARE_FOOTER,
          BLOCK_TYPE.GIFT_FOOTER,
          BLOCK_TYPE.BRAND_GIFT_FOOTER,
        ].includes(e.block_type)
      );

      let updatedOrderExpandBlock = sortBy(imgPopup, ["sort_order"])
        .concat(sortBy(floating, ["sort_order"]))
        .map((e: any, index) => ({
          ...e,
          sort_order: index,
        }));
      result = [...updatedOrderExpandBlock];
    }
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedBlockList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    const sortedBlocks = sortBy(orderedBlockList, ["sort_order"]).map(
      (e, index) => ({
        ...e,
        sort_order: index,
      })
    );
    return sortedBlocks;
  };

  const handleChangeBlockOrder = (action: MOVE_ACTION) => {
    const sortOrder = selectedBlockData.sort_order;
    const startIndex =
      positionSlug === POSITION_TYPE.EXPAND ? sortOrder : index;
    const endIndex =
      action === MOVE_ACTION.DOWN ? startIndex + 1 : startIndex - 1;
    if (endIndex < 0 || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    dispatch(sortBlock({ itemsOrder, positionSlug, tabUid }));
    if (action === MOVE_ACTION.UP) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK,
        `Block_MoveUp_${blockTypeEvent}`,
        blockTypeEvent,
        ACTION_CONFIG.MOVE_UP
      );
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.BLOCK,
        `Block_MoveDown_${blockTypeEvent}`,
        blockTypeEvent,
        ACTION_CONFIG.MOVE_DOWN
      );
    }
  };

  const handleMoveBlockToOtherPosition = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.BLOCK,
      `${EVENT_CONFIG.MOVE_TO}${blockTypeEvent}`,
      blockTypeEvent,
      ACTION_CONFIG.MOVE_TO
    );
    if (selectedBlock) {
      if (selectedBlock?.position !== "body") {
        dispatch(
          moveBlockToPosition({
            blockData: selectedBlockData,
            oldPositionSlug: positionSlug,
            newPositionSlug: selectedBlock?.position,
            oldTabUid: tabUid,
            newTabUid: null,
          })
        );
      }
      if (selectedBlock?.uid) {
        dispatch(
          moveBlockToPosition({
            blockData: selectedBlockData,
            oldPositionSlug: positionSlug,
            newPositionSlug: "body",
            oldTabUid: tabUid,
            newTabUid: selectedBlock?.uid,
          })
        );
      }
    }

    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_move_block_successfully_label")}
        </div>
      ),
    });
  };

  const openBlockEditor = (event?: any) => {
    if (event?.defaultPrevented) {
      return;
    }
    dispatch(
      openPopup({
        block: selectedBlockData,
        position_uid: positionSlug,
        tab_uid: tabUid,
        KEY: KEY_BLOCK.EDIT,
      })
    );
  };

  useEffect(() => {
    const castWindow = window as any;
    if (isFirstItem) {
      castWindow.triggerVisiblePopoverBlock = setForceVisible;
    }
    () => {
      if (castWindow.triggerVisiblePopoverBlock) {
        castWindow.triggerVisiblePopoverBlock = undefined;
        setForceVisible(false);
      }
    };
  }, [isFirstItem]);

  const styleChecking = useMemo(() => {
    let marginValue = outsideSpaceMemo;
    if (advancedSetting?.use_advanced_setting) {
      marginValue = advancedSetting?.use_block_space ? "12px" : null;
    }
    return {
      marginLeft: marginValue,
      marginRight: marginValue,
      paddingLeft: !advancedSetting?.use_advanced_setting
        ? insideSpaceMemo
        : null,
      paddingRight: !advancedSetting?.use_advanced_setting
        ? insideSpaceMemo
        : null,
    };
  }, [advancedSetting, outsideSpaceMemo, insideSpaceMemo]);

  if (!isApplyBlockSettings) {
    return (
      <div
        className={cls("relative", `block-${blockType?.split("_")?.join("-")}`)}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className="relative"
      style={{
        cursor: "auto",
        minHeight: isValidBlockHeight ? "32px" : "",
      }}
      ref={popoverRef}
    >
      <div
        className={
          isFirstItem
            ? `${PRODUCT_TOUR_CLASS_NAME.STEP_12} ${PRODUCT_TOUR_CLASS_NAME.STEP_13}`
            : ""
        }
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          maxHeight: "70vh",
          zIndex: -1,
        }}
      ></div>
      <div
        {...bindDoubleTapToBlock}
        className={cls(
          `block-${blockType?.split("_")?.join("-")}`,
          "overflow-hidden h-full block-dragging",
          !advancedSetting?.use_advanced_setting && "block-class",
          blockType !== BLOCK_TYPE.COMBINED && "bg-block-drag-drop"
        )}
        id={blockUid}
        style={{
          minHeight: isValidBlockHeight ? "32px" : "",
          display: isValidBlockHeight ? "flex" : "",
          alignItems: isValidBlockHeight ? "center" : "",
          ...styleChecking,
          ...props.extraStyle,
        }}
        ref={blockRef}
      >
        {children}
      </div>
      {isEnable !== 1 && (
        <div
          className="absolute top-0 block w-full h-full text-center block-border-radius z-[5]"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            width: blockHiddenOverlayWidth,
            left: "50%",
            transform: "translateX(-50%)",
          }}
          {...bindDoubleTapToBlock}
        >
          <div
            style={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <EyeVisibleIcon
              className="align-middle inline-block fill-black"
              fillColor="#333"
            />
            <div className="text-[#333]">
              {t("ss_builder_block_design_hiding_label")}
            </div>
          </div>
        </div>
      )}
      {!isHideMoreActions && (
        <div
          className={cls(
            `flex flex-row items-center justify-center absolute w-[32px] h-[32px] gap-[15px] cursor-pointer z-[5]`
          )}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            borderRadius: "100px",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            right: moreElementRight,
            top: isValidBlockHeight ? "0px" : "4px",
          }}
          onClick={handleClickMoreIcon}
        >
          <div
            className="relative"
            ref={popoverRef}
          >
            <Popover.Menu
              {...(forceVisible ? { visible: true } : {})}
              className={
                isFirstItem
                  ? `popover-block ${PRODUCT_TOUR_CLASS_NAME.STEP_3}`
                  : "popover-block"
              }
              mode="dark"
              actions={actionFilterOptions}
              placement="bottom-end"
              trigger="click"
              stopPropagation={["click"]}
              getContainer={() => popoverRef.current}
              onAction={handleSelectBlockAction}
            >
              <MoreIcon
                fillColor="#fff"
                className="fill-white"
              />
            </Popover.Menu>
          </div>
        </div>
      )}

      {isOpenConfirmEnterText && (
        <Portal elementId="modalPortal">
          <Modal
            visible={isOpenConfirmEnterText}
            content={
              <>
                <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
                  {t("ss_builder_confirm_delete_aff_block_content")}
                </div>
                <div className="mt-3 mb-1 text-[#666]">
                  {t("ss_builder_confirm_delete_aff_block_label_enter_ok")}
                </div>
                <Input
                  placeholder={t(
                    "ss_builder_confirm_delete_aff_block_input_placeholder"
                  )}
                  onChange={setValueConfirmText}
                  className="input-rounded"
                  maxLength={20}
                  minLength={1}
                />
              </>
            }
            closeOnAction
            onClose={handleCloseModalConfirmText}
            className="confirm-text-preview-apply-content"
            header={
              <CloseCircleIconV2
                width={48}
                height={48}
                fillColor="#FF3141"
              />
            }
            title={
              <div>{t("ss_builder_confirm_delete_aff_block_warining")}</div>
            }
            actions={[
              {
                key: "confirm",
                text: t("ss_builder_confirm_delete_aff_block_understood"),
                className: "confirm-text",
                primary: true,
                disabled: isEnableConfirm,
                onClick: handleRemoveBlock,
              },
              {
                key: "cancel",
                text: t("ss_builder_cancel_delete_aff_block_btn_label"),
                className: "cancel-text",
              },
            ]}
          />
        </Portal>
      )}
      <PopupComponent
        visible={isOpenPositionSelection}
        onMaskClick={() => setIsOpenPositionSelection(false)}
        title={t("ss_builder_move_product_label")}
        onClose={() => setIsOpenPositionSelection(false)}
        style={{
          borderRadius: "8px 8px 0px 0px",
          maxWidth: outside_space
            ? `${MAX_WIDTH_OUTSIDE_ON}px`
            : `${MAX_WIDTH_CONTENT_LAYOUT}px`,
        }}
        showConfirmButton={true}
        onConfirmButton={handleMoveBlockToOtherPosition}
        confirmButtonLabel={t("ss_builder_popup_confirm_button")}
        disabledConfirmButton={!selectedBlock}
      >
        <AddNewBlock
          position={positionState}
          onSelectBlock={(data) => setSelectedBlock(data)}
          tabUid={tabUid}
          positionSlug={positionSlug}
          selectedBlock={selectedBlock}
          isHideExpandPosition={true}
        />
      </PopupComponent>
    </div>
  );
};

export default BlockWrapper;
