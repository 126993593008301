import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import BlockEditTitle from "@features/block/components/BlockEditTilte";
import { changeTitleBlock } from "@features/main/store/positionsSlice";
import { EditIcon, EmbedIcon } from "@share/icons";
import { Divider, Input, TextArea } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { DISABLE_BLOCK, ENABLE_BLOCK, KEY_BLOCK } from "@share/configs/const";
import MoreElement from "@features/block/components/MoreElement";
import { EyeVisibleIcon } from "@share/icons";
import HeaderBlockEditor from "@features/block/components/HeaderBlockEditor";
import { isValidUrl } from "@share/helper/validator";
import {
  checkDirtyCodeIframe as isEmbedValid,
  extractAttrFromString,
} from "@features/share/helper/validator";

const BLOCK_HEADING_MAXIMUM = 50;
const BLOCK_DESCRIPTION_MAXIMUM = 250;

const Embed = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
  } = props;
  const { t } = useTranslation();
  const UID = `emabed-${uuidv4()}`;

  // const dispatch = useAppDispatch();

  // const edittingBlock = useAppSelector((state) => state.edittingBlock);
  // const KEY = edittingBlock && edittingBlock.KEY;
  // const edittingBlockData = edittingBlock && edittingBlock.block_data;
  // const tabUid = edittingBlock && edittingBlock.tab_uid;
  // const positionSlug = edittingBlock && edittingBlock.position_uid;
  const defaultValueEditEmbed = defaultBlockData?.uid
    ? defaultBlockData
    : null;
  const titleBlockDefault = "Embed";
  const valueHeadingBlockDefault =
    defaultValueEditEmbed?.content_attributes?.block_heading || "";
  const valueDescriptionBlockDefault =
    defaultValueEditEmbed?.content_attributes?.block_description || "";
  const valueEmbedCodeDefault =
    defaultValueEditEmbed?.content_attributes?.embed_code || "";

  const [valueHeadingBlock, setValueHeadingBlock] = useState(
    valueHeadingBlockDefault
  );
  const [valueDescriptionBlock, setValueDescriptionBlock] = useState(
    valueDescriptionBlockDefault
  );
  const [valueEmbedCode, setValueEmbedCode] = useState(valueEmbedCodeDefault);
  const [embedCodeValid, setEmbedCodeValid] = useState(null);
  const [isOpenEditTitleImageBlock, setIsOpenEditTitleImageBlock] =
    useState(false);
  const [isEnable, setIsEnable] = useState(block?.enable ?? ENABLE_BLOCK);

  useEffect(() => {
    if (!block.uid) {
      const defaultEmbedData = {
        uid: UID,
        sort_order: 1,
        enable: ENABLE_BLOCK,
        block_type: BLOCK_TYPE.EMBED,
        title: titleBlockDefault,
        content_attributes: {
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          embed_code: transformEmbedContent(valueEmbedCode)?.trim(),
        },
        style_attributes: {},
      };
      onUpdateData(defaultEmbedData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
          embed_code: transformEmbedContent(valueEmbedCode)?.trim(),
        },
        style_attributes: {},
      });
    }
  }, [valueHeadingBlock, valueDescriptionBlock, valueEmbedCode]);

  const handleClickEditIcon = () => {
    setIsOpenEditTitleImageBlock(true);
  };

  const handleChangeBlockHeading = (val) => {
    if (val?.length <= BLOCK_HEADING_MAXIMUM) {
      setValueHeadingBlock(val);
    }
  };

  const handleChangeBlockDescription = (val) => {
    if (val?.length <= BLOCK_DESCRIPTION_MAXIMUM) {
      setValueDescriptionBlock(val);
    }
  };

  const transformEmbedContent = (embedString: string) => {
    const listAttrs = extractAttrFromString(embedString);
    const titleAttr = listAttrs && listAttrs.find((e) => e[0] === "title");

    const titleValueClean =
      titleAttr &&
      titleAttr.length > 1 &&
      titleAttr[1]
        .replaceAll(">", "&gt;")
        .replaceAll("<", "&lt;")
        .replaceAll('"', "&quot;")
        .replaceAll("'", "&#39;");

    const newEmbedString = embedString.replace(
      /(?:title=(.+?)"+)/,
      `title="${titleValueClean}"`
    );
    return newEmbedString;
  };

  const handleChangeEmbedCode = (embed) => {
    setValueEmbedCode(embed);
    setEmbedCodeValid(!isEmbedValid(embed));
  };

  const isDisableBtnSave = useMemo(() => {
    if (
      valueEmbedCode &&
      (valueEmbedCode !== valueEmbedCodeDefault ||
        valueHeadingBlock !== valueHeadingBlockDefault ||
        valueDescriptionBlock !== valueDescriptionBlockDefault) &&
      !embedCodeValid
    ) {
      return false;
    } else return true;
  }, [
    titleBlockDefault,
    valueEmbedCode,
    valueEmbedCodeDefault,
    valueHeadingBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlock,
    valueDescriptionBlockDefault,
    embedCodeValid,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      valueEmbedCode !== valueEmbedCodeDefault ||
      valueHeadingBlock !== valueHeadingBlockDefault ||
      valueDescriptionBlock !== valueDescriptionBlockDefault
    ) {
      return true;
    } else return false;
  }, [
    valueEmbedCode,
    valueEmbedCodeDefault,
    valueHeadingBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlock,
    valueDescriptionBlockDefault,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  const handeSetEnableBlock = () => {
    setIsEnable(isEnable === ENABLE_BLOCK ? DISABLE_BLOCK : ENABLE_BLOCK);
  };

  return (
    <>
      <div className=" bg-white mt-3 px-4 py-[13px]">
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_heading_label")}
          </span>
          <Input
            placeholder={t("ss_builder_block_heading_placeholder")}
            onChange={handleChangeBlockHeading}
            value={valueHeadingBlock}
            maxLength={BLOCK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueHeadingBlock ? valueHeadingBlock.length : 0
          }/${BLOCK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </span>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            onChange={handleChangeBlockDescription}
            value={valueDescriptionBlock}
            maxLength={BLOCK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueDescriptionBlock ? valueDescriptionBlock.length : 0
          }/${BLOCK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
      </div>
      <div className=" bg-white mt-3 px-4 py-[13px]">
        <span className="text-red-600">*</span>
        <span className="text-[15px] leading-[13px] text-[#666666]">
          {t("ss_builder_embed_code_label")}
        </span>
        <TextArea
          placeholder={t("ss_builder_embed_code_placeholder")}
          onChange={handleChangeEmbedCode}
          value={valueEmbedCode}
          rows={8}
        />
        {embedCodeValid && (
          <div className="text-[13px] leading-[17px] text-[#FF3141]">
            {t("ss_builder_malformed_embed_code_warning")}
          </div>
        )}
      </div>
    </>
  );
};

export default Embed;
