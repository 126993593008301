import {
  BLOCK_SETTING_DEFAULT_VALUE,
  PARAGRAPH_SETTING_DEFAULT_VALUE,
  TITLE_SETTING_DEFAULT_VALUE,
} from "@share/configs/const";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const CurrentBlock = ({ siteSettings, index }) => {
  const { t } = useTranslation();
  const { paragraph_setting, title_setting, block_setting } =
    siteSettings?.display_setting || {};

  const {
    font_family: font_family_title = TITLE_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color: font_color_title = TITLE_SETTING_DEFAULT_VALUE.FONT_COLOR,
    scale: scale_title = TITLE_SETTING_DEFAULT_VALUE.FONT_SCALE,
    font_size: font_size_title = TITLE_SETTING_DEFAULT_VALUE.FONT_SIZE,
    font_weight: font_weight_title = TITLE_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height: line_height_title = TITLE_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  } = title_setting || {};

  const {
    font_family:
      font_family_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_FAMILY,
    font_color:
      font_color_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_COLOR,
    font_size: font_size_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_SIZE,
    font_weight:
      font_weight_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.FONT_WEIGHT,
    line_height:
      line_height_paragraph = PARAGRAPH_SETTING_DEFAULT_VALUE.LINE_HEIGHT,
  } = paragraph_setting || {};

  const { block_style, block_background, block_border, block_shadow } =
    block_setting || {};

  const {
    radius_type = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_STYLE,
    radius_value = BLOCK_SETTING_DEFAULT_VALUE.BORDER_RADIUS,
    inner_space = BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE,
    inner_space_number = BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER,
  } = block_style || {};
  const {
    color: blockBackgroundColor = BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_COLOR,
    blur = BLOCK_SETTING_DEFAULT_VALUE.BACKGROUND_BLUR,
  } = block_background || {};
  const {
    enable: borderEnable = BLOCK_SETTING_DEFAULT_VALUE.BORDER_ENABLE,
    border_width = BLOCK_SETTING_DEFAULT_VALUE.BORDER_WIDTH,
    color: borderColor = BLOCK_SETTING_DEFAULT_VALUE.BORDER_COLOR,
    type: borderType = BLOCK_SETTING_DEFAULT_VALUE.BORDER_STYLE,
  } = block_border || {};
  const {
    enable: shadowEnable = BLOCK_SETTING_DEFAULT_VALUE.SHADOW_ENABLE,
    color: shadowColor = BLOCK_SETTING_DEFAULT_VALUE.SHADOW_COLOR,
  } = block_shadow || {};

  const font_size_title_block =
    font_size_title * scale_title * scale_title * scale_title;

  const boxShadowStyle =
    shadowEnable === true ? { boxShadow: "0px 4px 4px " + shadowColor } : {};

  const borderRadiusValue = useMemo(() => {
    let value = "0px";
    if (radius_type === "rounded") {
      value = "8px";
    } else if (radius_type === "custom") {
      value = `${radius_value}px`;
    }
    return value;
  }, [radius_type, radius_value]);

  const styleBorder =
    borderEnable === true
      ? {
          borderWidth: border_width + "px",
          borderStyle: borderType,
          borderColor: borderColor,
        }
      : { border: "none" };

  const innerSpaceNumber =
    typeof inner_space_number === "number"
      ? `${inner_space_number}px`
      : inner_space
      ? `${BLOCK_SETTING_DEFAULT_VALUE.INNER_SPACE_NUMBER}px`
      : 0;

  return (
    <div
      className="pt-3 pb-3"
      style={{
        backgroundColor: blockBackgroundColor,
        borderRadius: borderRadiusValue,
        backdropFilter: `blur(${blur}px) `,
        WebkitBackdropFilter: `blur(${blur}px) `,
        paddingLeft: innerSpaceNumber,
        paddingRight: innerSpaceNumber,
        ...styleBorder,
        ...boxShadowStyle,
      }}
    >
      <h3
        className="text-titleFontSize"
        style={{
          color: font_color_title,
          fontSize: font_size_title_block,
          lineHeight: line_height_title,
          fontWeight: font_weight_title,
          fontFamily: font_family_title,
        }}
      >
        {t(`ss_builder_${index}st_block_preview_sample_title`)}
      </h3>
      <div
        className="content-block-preview"
        style={{
          color: font_color_paragraph,
          fontSize: font_size_paragraph,
          lineHeight: line_height_paragraph,
          fontWeight: font_weight_paragraph,
          fontFamily: font_family_paragraph,
        }}
      >
        {t(`ss_builder_${index}st_block_preview_sample_description`)}
      </div>
    </div>
  );
};

export default CurrentBlock;
