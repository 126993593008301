import React from "react";
import { Image } from "antd-mobile";
import { useTranslation } from "react-i18next";

interface UploadImageProps {
  valueUrlImage: string;
  imageRatio: string;
  textHelper?: string;
  isUseBorderErr?: boolean;
  error?: boolean;
}

const UploadImage = (props: UploadImageProps) => {
  const { t } = useTranslation();
  const {
    valueUrlImage,
    imageRatio,
    textHelper = "ss_builder_support_upload_image",
    isUseBorderErr,
    error,
  } = props;
  return valueUrlImage ? (
    <Image
      src={valueUrlImage}
      fit="cover"
      style={{
        backgroundSize: "cover",
        aspectRatio: imageRatio,
        border: `1px solid #D9D9D9`,
      }}
    />
  ) : (
    <div
      style={{
        border: !isUseBorderErr
          ? " 1px solid #EEEEEE"
          : `1px solid ${!error ? "#D9D9D9" : "#FF3141"}`,
        height: "200px",
      }}
      className="adm-image "
    >
      <div className="flex justify-center items-center">
        <Image
          src={
            "https://image.passio.eco/page-builder/page-builder/images-default/image-default.png"
          }
          fit="cover"
          style={{
            backgroundSize: "cover",
            aspectRatio: imageRatio,
            // border: "1px solid #D9D9D9",
            width: "150px",
            height: "115px",
          }}
        />
      </div>
      <h5
        className="flex justify-center items-center"
        style={{
          fontWeight: "var(--heading-font-weight)",
          fontSize: "var(--heading-font-size)",
          fontFamily: "var(--heading-font-family)",
          lineHeight: "var(--heading-line-height)",
          marginTop: "20px",
        }}
      >
        {t("ss_builder_no_photo_has_been_selected")}
      </h5>
      <div
        className="flex justify-center items-center"
        style={{
          fontWeight: "var(--paragraph-font-weight)",
          fontSize: "var(--paragraph-font-size)",
          fontFamily: "var(--paragraph-font-family)",
          lineHeight: "var(--paragraph-line-height)",
          // width: "263px",
          // height: "34px"
        }}
      >
        {t(textHelper)}
      </div>
    </div>
  );
};

export default UploadImage;
