import PopupComponent from "@share/components/PopupComponent";
import { AddIcon, CheckCircleIcon } from "@share/icons";
import { Button, Toast } from "antd-mobile";
import dynamic from "next/dynamic";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NewTabComponent from "./NewTabComponent";
import gaEvents, { ACTION_CONFIG, CATEGORY_CONFIG } from "@share/lib/ga-events";
import {useAppSelector} from "@app/hooks";
import cloneDeep from "lodash/cloneDeep"

const TAB_QUANTITY_MAX = 4;

const ListTabsComponent = dynamic(import("./ListTabs"));

function DragListComponent(props) {
  const [winReady, setwinReady] = useState(false);
  useEffect(() => {
    setwinReady(true);
  }, []);
  return <div>{winReady ? <ListTabsComponent {...props} /> : null}</div>;
}

const TabManagement = (props) => {
  const { onUpdateListTabsData, listTabsDataDefault } = props;
  const { t } = useTranslation();

  const [isOpenTabFloatingPanel, setIsOpenTabFloatingPanel] = useState(false);
  const [tabValueEdit, setTabValueEdit] = useState(null);

  const blockRate = useMemo(
    () => `${listTabsDataDefault.length}/${TAB_QUANTITY_MAX}`,
    [listTabsDataDefault]
  );

  const isDisableBtn = useMemo(
    () => listTabsDataDefault.length === TAB_QUANTITY_MAX,
    [listTabsDataDefault]
  );
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleOpenFloatingPanel = () => {
    setIsOpenTabFloatingPanel(true);
    setTabValueEdit(null);
  };

  const handleConfirmAddNewTab = (data) => {
    const listTabsDataDefaultClone = cloneDeep(listTabsDataDefault);
    if (!tabValueEdit) {
      //Add new tab
      if (Array.isArray(listTabsDataDefaultClone)) {
        if (listTabsDataDefaultClone.length === 0) {
          listTabsDataDefaultClone.push(data);
        } else {
          const sortIdArr = listTabsDataDefaultClone.map((x) => x.sort_order);
          listTabsDataDefaultClone.push({
            ...data,
            sort_order: Math.max(...sortIdArr) + 1,
          });
        }
      }
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.TAB,
        `Tab_Add_Tab${listTabsDataDefaultClone.length}`,
        `Tab ${listTabsDataDefaultClone.length}`,
        ACTION_CONFIG.ADD
      );
      onUpdateListTabsData(listTabsDataDefaultClone);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[30px] h-[30px]"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_add_new_tab_successfully_label")}
          </div>
        ),
      });
    } else {
      //Edit old tab
      const newListTabsData = listTabsDataDefaultClone?.map((ele) => {
        return ele?.uid === tabValueEdit?.uid
          ? {
              ...tabValueEdit,
              icon: data?.icon,
              icon_url: data?.icon_url,
              name: data?.name,
            }
          : ele;
      });
      const index =
        listTabsDataDefaultClone.findIndex(
          (item) => item?.uid === tabValueEdit?.uid
        ) + 1;
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.TAB,
        `Tab_Rename_Tab${index}`,
        `Tab ${index}`,
        ACTION_CONFIG.RENAME
      );
      onUpdateListTabsData(newListTabsData);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[30px] h-[30px]"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_edit_tab_successfully_label")}
          </div>
        ),
      });
    }
  };

  return (
    <>
      <div
        style={{
          padding: "20px 12px 24px",
        }}
        className="tab-component-class"
      >
        <div>
          <div className="flex flex-row justify-between items-center">
            <div className="text-[15px] text-[#666666] mb-1">
              {t("ss_builder_manage_tab_title")}
            </div>
            <div className="flex flex-grow justify-end gap-2">
              <Button
                size="small"
                fill="solid"
                color="primary"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  padding: "3px 12px 3px 12px",
                }}
                onClick={handleOpenFloatingPanel}
                disabled={isDisableBtn}
              >
                <AddIcon className="w-[15px] h-[15px]" />
                <div>{t("ss_builder_add_new_label")}</div>
                <span>{blockRate || ""}</span>
              </Button>
            </div>
          </div>
          <DragListComponent
            tabListBody={listTabsDataDefault}
            callbackOpenEditTab={(data) => {
              setIsOpenTabFloatingPanel(true);
              setTabValueEdit(data);
            }}
            onUpdateListTabsData={onUpdateListTabsData}
          />
        </div>
      </div>
      <PopupComponent
        visible={isOpenTabFloatingPanel}
        onMaskClick={() => setIsOpenTabFloatingPanel(false)}
        title={t("ss_builder_panel_add_new_tab_title")}
        onClose={() => setIsOpenTabFloatingPanel(false)}
      >
        <NewTabComponent
          cancelTabFloatingPanel={() => setIsOpenTabFloatingPanel(false)}
          onConfirmAddNewTab={handleConfirmAddNewTab}
          tabValueDefault={tabValueEdit}
        />
      </PopupComponent>
    </>
  );
};

export default TabManagement;
