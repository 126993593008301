const  vi =  {
 translation: {
  "button_confirm_exit_TOD": "Thoát",
  "button_confirm_off_TOD": "Tắt",
  "button_title_default_donation": "Ủng hộ 💵 cho {name} bạn nhé!",
  "cashback_default_text_button": "Nhận hoàn tiền ngay",
  "cashback_default_title": "Mua sắm thả ga, hoàn tiền cực đã",
  "cm_home": "Home",
  "default_content_setup_brand_gift": "Bạn hãy chọn hoặc tạo ra nội dung hấp dẫn cho fan nhé",
  "default_content_setup_donation": "Dịch vụ đang được cài đặt mặc định. Nếu bạn muốn thay đổi, vui lòng truy cập dịch vụ Ủng hộ tại account.passio.eco",
  "default_title_setup_brand_gift": "Thiết lập",
  "default_title_setup_donation": "Thiết lập dịch vụ",
  "error_thumbnail_size_TOD": "Ảnh quá dung lượng cho phép",
  "invitation_default_donation": "Bạn yêu quý {name}?",
  "label_tab_bar_brand_gift": "Mẫu nội dung",
  "label_text_button_brand_gift": "Nút",
  "label_text_title_brand_gift": "Tiêu đề",
  "login": "Đăng nhập",
  "message_default_donation": "Cảm ơn bạn đã ủng hộ {name}. Hi vọng chúng ta sẽ có nhiều dịp tương tác với nhau nữa nhé!",
  "placeholder_text_button_brand_gift": "Nhập nội dung nút",
  "placeholder_text_title_brand_gift": "Nhập nội dung tiêu đề",
  "ss_background_TOD": "Ảnh backgound",
  "ss_brand_cpc_bonus": "Hoa hồng",
  "ss_brand_cpc_campaign_title": "Nhận hoa hồng",
  "ss_brand_cpc_description": "Mô tả chương trình",
  "ss_brand_cpc_download": "Tải xuống",
  "ss_brand_cpc_from": "Từ",
  "ss_brand_cpc_join": "Tham gia",
  "ss_brand_cpc_join_now": "Tham khảo ngay",
  "ss_brand_cpc_join_successfully_label": "Bạn đã tham gia chương trình thành công",
  "ss_brand_cpc_no_access_allow": "Bạn không được phép truy cập vào link này",
  "ss_brand_cpc_notice": "Lưu ý",
  "ss_brand_cpc_notice_content": "Hoa hồng sẽ được trả về tài khoản của bạn sau 7 ngày kể từ khi được nghiệm thu.",
  "ss_brand_cpc_reject": "Ảnh nghiệm thu của bạn đã bị từ chối. Bạn có thể tải lại ảnh.",
  "ss_brand_cpc_requirement": "Yêu cầu",
  "ss_brand_cpc_step_1": "Bước 1: Tải ảnh dưới đây về và up lên 1 nền tảng mạng xã hội.",
  "ss_brand_cpc_step_1_note": "Lưu ý: up ảnh trong khoảng thời gian",
  "ss_brand_cpc_step_2": "Bước 2: Tải ảnh nghiệm thu (ảnh chụp màn hình bài đăng của bạn ở bước 1)",
  "ss_brand_cpc_step_2_note": "Lưu ý: ảnh nghiệm thu thể hiện thời gian đã đăng ảnh tối thiểu là 12h. VD: Ảnh chụp màn hình story đã đăng 12h trước trở lên.",
  "ss_brand_cpc_success": "Đã nghiệm thu thành công",
  "ss_brand_cpc_text_content": "đang gửi cho bạn 01 lời đề nghị tham gia vào chương trình treo banner - nhận tiền thưởng.",
  "ss_brand_cpc_text_title": "Nhận tiền dễ dàng cùng",
  "ss_brand_cpc_time": "Thời gian",
  "ss_brand_cpc_time_end": "Thời gian kết thúc",
  "ss_brand_cpc_time_start": "Thời gian bắt đầu",
  "ss_brand_cpc_to": "Đến",
  "ss_brand_cpc_upload_image": "Tải ảnh nghiệm thu",
  "ss_brand_cpc_upload_image_button": "Tải ảnh lên",
  "ss_brand_cpc_upload_image_successfully": "Bạn đã gửi ảnh nghiệm thu thành công",
  "ss_brand_cpc_upload_image_title": "Tải ảnh nghiệm thu",
  "ss_brand_gift_cancel_hide": "Hủy bỏ",
  "ss_brand_gift_confirm_hide_brand": "Xác nhận",
  "ss_brand_gift_confirm_hide_group": "Xác nhận ẩn nhóm",
  "ss_brand_gift_disable_deal_successfully": "Ẩn Deal thành công",
  "ss_brand_gift_disable_successfully": "Ẩn nhóm sản phẩm thành công",
  "ss_brand_gift_display_hide_brand": "Ẩn Deal",
  "ss_brand_gift_display_hide_category": "Ẩn nhóm sản phẩm",
  "ss_brand_gift_display_show_brand": "Hiện Deal",
  "ss_brand_gift_display_show_category": "Hiện nhóm sản phẩm",
  "ss_brand_gift_empty_title": "Chưa có sản phẩm nào",
  "ss_brand_gift_enable_deal_successfully": "Hiện Deal thành công",
  "ss_brand_gift_enable_successfully": "Hiện nhóm sản phẩm thành công",
  "ss_brand_gift_hide_brand": "Bạn có muốn ẩn Deal?",
  "ss_brand_gift_hide_brand_content": "Deal {{name}} sẽ không hiển thị ở giao diện của Fan. Bạn có muốn ẩn deal này?",
  "ss_brand_gift_hide_category": "Bạn muốn ẩn nhóm?",
  "ss_brand_gift_hide_category_content": "Nhóm {{name}} và toàn bộ dữ liệu của các sản phẩm bên trong sẽ bị ẩn. Bạn có chắc muốn ẩn nhóm?",
  "ss_brand_gift_list_category": "Danh sách nhóm sản phẩm",
  "ss_brand_gift_list_empty_content": "Hiện giờ Admin chưa setup dữ liệu sản phẩm",
  "ss_brand_gift_setting_cashback_default_text_button": "Nhận hoàn tiền ngay",
  "ss_brand_gift_setting_cashback_default_title": "Mua sắm thả ga, hoàn tiền cực đã",
  "ss_brand_gift_setting_default_content_setup": "Bạn hãy chọn hoặc tạo ra nội dung hấp dẫn cho fan nhé",
  "ss_brand_gift_setting_float_icon_description": "Bạn hãy lưu cài đặt floating icon để giúp bạn tăng tương tác với Fan của mình nhiều hơn",
  "ss_brand_gift_setting_float_icon_footer_description": "Bật tính năng để tăng tương tác với Fan",
  "ss_brand_gift_setting_label_tab_bar_brand_gift": "Mẫu nội dung",
  "ss_brand_gift_setting_label_text_button": "Nút",
  "ss_brand_gift_setting_label_text_title_brand_gift": "Tiêu đề",
  "ss_brand_gift_setting_placeholder_text_button": "Nhập nội dung nút",
  "ss_brand_gift_setting_placeholder_text_title": "Nhập nội dung tiêu đề",
  "ss_brand_gift_setting_text_cashback_brand_gift": "Hoàn tiền",
  "ss_brand_gift_setting_text_custom_brand_gift": "Cá nhân hóa",
  "ss_brand_gift_setting_text_voucher_brand_gift": "Voucher",
  "ss_brand_gift_setting_title_block_footer": "Brand Gift Icon",
  "ss_brand_gift_setting_voucher_default_text_button": "Nhận voucher ngay",
  "ss_brand_gift_setting_voucher_default_title": "Chạm lẹ thanh toán, hoàn tiền 5%",
  "ss_brand_rating_setting_default_content_setup_donation": "Danh sách các chương trình hoàn tiền giảm giá dành cho fan của bạn. Bật tính năng để nhận hoa hồng ngay!",
  "ss_build_collapse_block_label": "Thu gọn",
  "ss_build_expand_block_label": "Mở rộng",
  "ss_build_no_blocks_have_been_added": "Chưa có khối nào được thêm",
  "ss_builde_theme_setting_block": "Khối",
  "ss_builder_1st_block_preview_sample_description": "Đây là nội dung ví dụ của khối, bạn có thể thấy cách phông chữ, màu sắc hiển thị trong khối.",
  "ss_builder_1st_block_preview_sample_title": "Khối 1",
  "ss_builder_2st_block_preview_sample_description": "Đây là nội dung ví dụ của khối, bạn có thể thấy cách phông chữ, màu sắc hiển thị trong khối. Khối thứ 2 để thấy khoảng cách giữa các khối.",
  "ss_builder_2st_block_preview_sample_title": "Khối 2",
  "ss_builder_add_affiliate_product_list_btn_label": "Thêm sản phẩm",
  "ss_builder_add_block_body_title": "Thân trang",
  "ss_builder_add_block_expand_title": "Mở rộng",
  "ss_builder_add_block_footer_title": "Cuối trang",
  "ss_builder_add_block_header_title": "Đầu trang",
  "ss_builder_add_block_in_template": "Thêm khối mẫu",
  "ss_builder_add_block_in_template_successfully": "Thêm khối mẫu thành công",
  "ss_builder_add_block_label": "Thêm khối",
  "ss_builder_add_block_to": "Thêm khối vào:",
  "ss_builder_add_image_of_image_popup_tilte": "Thêm ảnh",
  "ss_builder_add_new_affiliate_product_btn_label": "Thêm mới sản phẩm",
  "ss_builder_add_new_affiliate_product_group_btn_label": "Thêm nhóm sản phẩm",
  "ss_builder_add_new_affiliate_product_image_title": "Ảnh sản phẩm",
  "ss_builder_add_new_affiliate_product_list_empty_content": "Để thêm sản phẩm vui lòng nhập đường dẫn vào ô bên dưới",
  "ss_builder_add_new_affiliate_product_name_title": "Tên sản phẩm",
  "ss_builder_add_new_affiliate_product_title": "Thêm sản phẩm",
  "ss_builder_add_new_block_successfully_label": "Thêm mới khối thành công",
  "ss_builder_add_new_image": "Thêm mới ảnh",
  "ss_builder_add_new_image_of_image_popup_title": "Ảnh",
  "ss_builder_add_new_image_of_image_slider_title": "Ảnh",
  "ss_builder_add_new_image_popup_btn_label": "Thêm",
  "ss_builder_add_new_image_slider_title": "Thêm ảnh",
  "ss_builder_add_new_label": "Thêm",
  "ss_builder_add_new_product_group_successfully_label": "Thêm nhóm sản phẩm thành công",
  "ss_builder_add_new_tab_successfully_label": "Thêm tab mới thành công",
  "ss_builder_add_new_tag_error_duplicate": "Tag đã tồn tại",
  "ss_builder_add_new_template_btn_label": "Lưu mới",
  "ss_builder_add_new_template_image_title": "Ảnh mẫu",
  "ss_builder_add_new_template_label": "Tạo mới mẫu",
  "ss_builder_add_new_template_name_placeholder": "Nhập tên mẫu",
  "ss_builder_add_new_template_name_title": "Tên mẫu",
  "ss_builder_add_new_theme_btn_label": "Lưu mới",
  "ss_builder_add_new_theme_selection_panel_description": "Chọn phương thức tạo giao diện mẫu để xuất bản lên Cửa hàng giao diện",
  "ss_builder_add_new_theme_selection_panel_label": "Tạo giao diện mẫu",
  "ss_builder_add_new_theme_template_image_title": "Ảnh giao diện mẫu",
  "ss_builder_add_new_theme_template_name_placeholder": "Nhập tên giao diện mẫu",
  "ss_builder_add_new_theme_template_name_title": "Tên giao diện mẫu",
  "ss_builder_add_story": "Thêm mới",
  "ss_builder_add_tab_label": "Thêm Tab",
  "ss_builder_added_product_url_label": "Đường dẫn đã được thêm",
  "ss_builder_advanced_group_description": "Các khối nâng cao",
  "ss_builder_advanced_setting": "Thiết lập nâng cao",
  "ss_builder_advanced_title": "Nâng cao",
  "ss_builder_aff_frame_max_size_content": "Kích thước tệp tối đa: 30MB.",
  "ss_builder_aff_frame_select_from_library": "Chọn từ thư viện",
  "ss_builder_aff_frame_support_type_file_content": "Định dạng hỗ trợ: gif, png, svg",
  "ss_builder_affiliate_add_new_tour_content_step_1": "Lựa chọn nhóm sản phẩm để phân loại sản phẩm của bạn. Các sản phẩm bên dưới sẽ được thêm vào nhóm bạn chọn.",
  "ss_builder_affiliate_add_new_tour_content_step_2": "Nhập đường dẫn liên kết tới sản phẩm, hệ thống sẽ tự động lấy thông tin của sản phẩm.",
  "ss_builder_affiliate_add_new_tour_content_step_3": "Bạn có thể thay đổi tên, hình ảnh, nhãn... sản phẩm.",
  "ss_builder_affiliate_add_new_tour_content_step_4": "Nhấn nút “Thêm” để thêm danh sách các sản phẩm bên trên vào nhóm.",
  "ss_builder_affiliate_add_new_tour_title_step_1": "Chọn nhóm sản phẩm",
  "ss_builder_affiliate_add_new_tour_title_step_2": "Đường dẫn liên kết",
  "ss_builder_affiliate_add_new_tour_title_step_3": "Thông tin sản phẩm",
  "ss_builder_affiliate_add_new_tour_title_step_4": "Thêm sản phẩm",
  "ss_builder_affiliate_group_tour_content_step_1": "Thêm nhóm sản phẩm để phân loại danh mục sản phẩm của bạn",
  "ss_builder_affiliate_group_tour_content_step_2": "Chỉnh sửa và các tùy chọn của từng nhóm, cho phép bạn tùy chỉnh khối theo ý muốn.",
  "ss_builder_affiliate_group_tour_title_step_1": "Thêm nhóm sản phẩm",
  "ss_builder_affiliate_group_tour_title_step_2": "Tác vụ nhanh",
  "ss_builder_affiliate_product_btn_see_more_placeholder": "Nhập nội dung",
  "ss_builder_affiliate_product_btn_see_more_title": "Nội dung nút xem thêm",
  "ss_builder_affiliate_product_clean_up_popover_content": "Sản phẩm này sẽ không được dọn dẹp tự động khi nhóm đầy sản phẩm",
  "ss_builder_affiliate_product_cleanup_lock_label": "Khóa dọn dẹp",
  "ss_builder_affiliate_product_cleanup_unlock_label": "Mở khóa dọn dẹp",
  "ss_builder_affiliate_product_frame_panel_title": "Chọn khung ảnh",
  "ss_builder_affiliate_product_group_add_new_panel_title": "Thông tin nhóm",
  "ss_builder_affiliate_product_group_list_selector_label": "Danh sách nhóm sản phẩm",
  "ss_builder_affiliate_product_group_management_title": "Quản lý nhóm sản phẩm",
  "ss_builder_affiliate_product_group_other": "Khác",
  "ss_builder_affiliate_product_group_placeholder": "Nhập tiêu đề khối",
  "ss_builder_affiliate_product_group_quantity_label": "{{product_quantity}} sản phẩm",
  "ss_builder_affiliate_product_group_quantity_warning_1": "Không thể thêm",
  "ss_builder_affiliate_product_group_quantity_warning_2": "Vượt quá số lượng còn trống",
  "ss_builder_affiliate_product_group_title": "Tiêu đề khối",
  "ss_builder_affiliate_product_image_popup_infomation_label": "Ảnh sản phẩm mặc định được lấy từ sàn. Bạn có thể thay đổi ảnh mong muốn.",
  "ss_builder_affiliate_product_label_product_discount": "Giảm giá",
  "ss_builder_affiliate_product_label_product_hot": "Hot",
  "ss_builder_affiliate_product_label_product_like": "Yêu thích",
  "ss_builder_affiliate_product_label_product_title": "Nhãn sản phẩm",
  "ss_builder_affiliate_product_list_empty_content": "Để thêm sản phẩm vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_affiliate_product_list_empty_title": "Chưa có sản phẩm nào được thêm",
  "ss_builder_affiliate_product_list_title": "Danh sách",
  "ss_builder_affiliate_product_moving_panel_title": "Di chuyển sản phẩm",
  "ss_builder_affiliate_product_quantity_on_row_title": "Số sản phẩm trên một hàng",
  "ss_builder_affiliate_product_searching_placeholder": "Tìm kiếm sản phẩm theo tên...",
  "ss_builder_affiliate_product_style_title": "Phong cách",
  "ss_builder_affiliate_setting_popover_manual_content": "Để xem hướng dẫn sử dụng cho khối Affiliate vui lòng nhấn nút “Bắt đầu khám phá”",
  "ss_builder_affiliate_setting_popover_manual_title": "Hướng dẫn sử dụng",
  "ss_builder_affiliate_setting_tour_content_step_1": "Thực hiện các thao tác như Ẩn/Hiện, Xóa khối.",
  "ss_builder_affiliate_setting_tour_content_step_2": "Thiết lập Tiêu đề và Mô tả cho khối, thông tin sẽ được hiển thị tương ứng sau khi lưu.",
  "ss_builder_affiliate_setting_tour_content_step_3": "Lựa chọn phong cách hiển thị của các sản phẩm trong khối. Sau khi thay đổi và lưu khối bạn sẽ thấy phong cách hiển thị thay đổi ở màn hình thiết kế.",
  "ss_builder_affiliate_setting_tour_content_step_4": "Tạo mới và quản lý Nhóm sản phẩm để phân chia danh sách sản phẩm thành từng nhóm. Fan sẽ dễ dàng phân loại và lọc sản phẩm theo từng nhóm. Ví dụ: Quần áo, Giày dép, Phụ kiện,...",
  "ss_builder_affiliate_setting_tour_content_step_5": "Dễ dàng truy cập vào từng nhóm sản phẩm để thêm mới và quản lý các sản phẩm bên trong nhóm.",
  "ss_builder_affiliate_setting_tour_content_step_6": "Thêm mới sản phẩm vào từng nhóm bằng cách nhấn vào nút “Thêm”.",
  "ss_builder_affiliate_setting_tour_content_step_7": "Các tùy chọn nhanh với từng sản phẩm cho phép thao tác nhanh với các sản phẩm trong danh sách.",
  "ss_builder_affiliate_setting_tour_content_step_8": "Sau khi chỉnh sửa, thêm nội dung, sản phẩm vào khối của bạn nhấn nút “Lưu” để lưu lại và sẽ thấy các thay đổi ở màn hình “Thiết kế”.",
  "ss_builder_affiliate_setting_tour_title_step_1": "Tác vụ nhanh",
  "ss_builder_affiliate_setting_tour_title_step_2": "Tiêu đề và Mô tả",
  "ss_builder_affiliate_setting_tour_title_step_3": "Cấu hình hiển thị sản phẩm",
  "ss_builder_affiliate_setting_tour_title_step_4": "Quản lý nhóm sản phẩm",
  "ss_builder_affiliate_setting_tour_title_step_5": "Nhóm sản phẩm",
  "ss_builder_affiliate_setting_tour_title_step_6": "Thêm sản phẩm",
  "ss_builder_affiliate_setting_tour_title_step_7": "Tùy chọn từng sản phẩm",
  "ss_builder_affiliate_setting_tour_title_step_8": "Lưu sản phẩm",
  "ss_builder_affiliate_upload_product_error_content": "Tệp tải lên cần là tệp json và có định dạng phù hợp",
  "ss_builder_affiliate_upload_product_error_label": "Tệp không hợp lệ",
  "ss_builder_affiliate_upload_product_label": "Tải tệp sản phẩm",
  "ss_builder_all_setting_and_product": "các thiết lập và tất cả sản phẩm",
  "ss_builder_all_the_blocks_are_in_your_list_by_the_blocks_of_the_template": "tất cả các khối đang có trong danh sách của bạn bằng các khối của mẫu",
  "ss_builder_allowed_domain_label": "Tên miền chưa tồn tại, bạn có thể dùng tên miền này",
  "ss_builder_an_unexpected_error_has_occurred": "Đã xảy ra lỗi không mong muốn từ hệ thống.",
  "ss_builder_apply_preview_theme_template_btn_label": "Áp dụng",
  "ss_builder_apply_template_successfully_label": "Áp dụng mẫu thành công",
  "ss_builder_apply_template_title": "Bạn muốn áp dụng mẫu này?",
  "ss_builder_apply_theme_premium_warning": "Giao diện dành riêng cho tài khoản trả phí. Vui lòng nâng cấp tài khoản của bạn để áp dụng mẫu này.",
  "ss_builder_apply_theme_premium_warning_title": "Bạn muốn áp dụng giao diện này?",
  "ss_builder_apply_theme_template_successfully_label": "Áp dụng giao diện mẫu thành công",
  "ss_builder_auto_cleanup_products_warning_content_1": "Vượt quá số lượng sản phẩm. Hệ thống sẽ tự dọn dẹp những sản phẩm ở cuối danh sách khi bạn thêm mới.",
  "ss_builder_auto_cleanup_products_warning_content_2": "Lưu ý: Những sản phẩm đang “Khóa dọn dẹp” sẽ không được tự động dọn dẹp.",
  "ss_builder_back": "Quay lại",
  "ss_builder_background_block": "Nền khối",
  "ss_builder_background_enable_overlay": "Lớp phủ",
  "ss_builder_background_opacity": "Độ làm mờ",
  "ss_builder_background_overlay_color": "Màu lớp phủ",
  "ss_builder_background_popover_manual_content": "Để xem hướng dẫn sử dụng cho thiết lập nền vui lòng nhấn nút “Bắt đầu khám phá”",
  "ss_builder_background_popover_manual_title": "Hướng dẫn sử dụng",
  "ss_builder_background_setting_title": "Thiết lập nền",
  "ss_builder_background_tour_content_step_1": "Phần xem trước cho các thiết lập bên dưới, cập nhật thời gian thực theo các thay đổi.",
  "ss_builder_background_tour_content_step_2": "Bạn có thể thiết lập ảnh nền cho trang của bạn bằng “Ảnh” hoặc “Màu sắc”. Với màu sắc bạn có thể lựa chọn “Màu đặc” hoặc “Dải màu”",
  "ss_builder_background_tour_title_step_1": "Phần xem trước",
  "ss_builder_background_tour_title_step_2": "Nền bằng ảnh hoặc màu sắc",
  "ss_builder_basic_group_description": "Các khối cơ bản",
  "ss_builder_basic_title": "Cơ bản",
  "ss_builder_bio_add_social_link_title": "Thêm mạng xã hội",
  "ss_builder_bio_avatar_title": "Ảnh đại diện",
  "ss_builder_bio_layout_title": "Bố cục",
  "ss_builder_bio_name_sub_placeholder": "Nhập tiêu đề phụ",
  "ss_builder_bio_name_title": "Tên hiển thị",
  "ss_builder_bio_name_title_placeholder": "Nhập tên hiển thị",
  "ss_builder_bio_social_link_btn_select_icon_label": "Chọn icon",
  "ss_builder_bio_social_link_icon_title": "Biểu tượng",
  "ss_builder_bio_social_link_url_title": "Nhập đường dẫn",
  "ss_builder_bio_sub_title": "Tiêu đề phụ",
  "ss_builder_bio_text_title": "Giới thiệu bản thân",
  "ss_builder_block_affiliate_description": "Giới thiệu các sản phẩm Affiliate đến Fan của bạn",
  "ss_builder_block_background_blur": "Độ làm mờ",
  "ss_builder_block_background_color": "Màu nền",
  "ss_builder_block_bio_description": "Ảnh đại diện, thông tin giới thiệu và các liên kết mạng xã hội",
  "ss_builder_block_block_card_description": "Hiển thị danh sách hình ảnh kèm nhiều thông tin với bố cục đa dạng và có thể trượt",
  "ss_builder_block_booking_file_alert_content": "Vui lòng tạo Booking Profile trước khi thiết lập",
  "ss_builder_block_booking_file_alert_title": "Bạn chưa tạo Booking Profile",
  "ss_builder_block_booking_file_button_label_default": "Hồ sơ công việc",
  "ss_builder_block_booking_file_label": "Đường dẫn Booking Profile",
  "ss_builder_block_booking_file_link_tooltip": "Khối Booking Profile sẽ liên kết đến đường dẫn này",
  "ss_builder_block_booking_file_switch_tooltip": "Hiển thị liên kết tới Booking Profile của bạn ở khu vực Bio",
  "ss_builder_block_booking_profile_button_label": "Nội dung nút",
  "ss_builder_block_booking_profile_setting_description": "Mô tả",
  "ss_builder_block_booking_profile_setting_title": "Tiêu đề",
  "ss_builder_block_border_color": "Màu viền",
  "ss_builder_block_border_style": "Phong cách",
  "ss_builder_block_brand_gift_description": "Bạn hãy lưu cài đặt floating icon để giúp bạn tăng tương tác với Fan của mình nhiều hơn",
  "ss_builder_block_brand_gift_footer_description": "Bật tính năng để tăng tương tác với Fan",
  "ss_builder_block_button_link_description": "Liên kết đơn đến trang yêu thích của bạn trên internet",
  "ss_builder_block_button_multi_description": "Danh sách các liên kết đơn đến các trang yêu thích của bạn trên internet",
  "ss_builder_block_button_story_description": "Hiển thị các liên kết nổi bật trên site của bạn bằng hình ảnh và chữ",
  "ss_builder_block_combined_description": "Kết hợp các khối có sẵn thành một kiểu khối mới",
  "ss_builder_block_combined_description_label": "Kết hợp các khối có sẵn thành một kiểu khối mới",
  "ss_builder_block_combined_description_placeholder": "Nhập nội dung",
  "ss_builder_block_combined_heading_label": "Tiêu đề khối",
  "ss_builder_block_description": "Mô tả khối",
  "ss_builder_block_description_label": "Mô tả",
  "ss_builder_block_description_placeholder": "Nhập nội dung",
  "ss_builder_block_design_hiding_label": "Khối đang bị ẩn",
  "ss_builder_block_digital_product_description": "Bán sản phẩm số trên Passio Page",
  "ss_builder_block_donation_description": "Nhận tiền ủng hộ từ Fan",
  "ss_builder_block_heading_label": "Tiêu đề khối",
  "ss_builder_block_heading_placeholder": "Nhập nội dung",
  "ss_builder_block_image_description": "Thêm một hình ảnh vào trang của bạn",
  "ss_builder_block_image_embed_description": "Nhúng bất kỳ mã nhúng nào vào site của bạn: video, âm thanh, form,...",
  "ss_builder_block_image_gallery_description": "Danh sách hình ảnh với bố cục đa dạng và có thể phóng to",
  "ss_builder_block_image_popup_description": "Ảnh sẽ được hiển thị dạng popup khi fan truy cập vào trang cá nhân của bạn",
  "ss_builder_block_image_slider_description": "Danh sách hình ảnh dạng trượt ngang",
  "ss_builder_block_is_hiding_label": "Khối đang bị ẩn",
  "ss_builder_block_link_copy_successfully_label": "Đã sao chép",
  "ss_builder_block_list_combined_description": "Kết hợp các khối có sẵn thành một kiểu khối mới",
  "ss_builder_block_main_color": "Màu chính",
  "ss_builder_block_outside_space": "Khoảng cách ngoài khối",
  "ss_builder_block_rating_answer_1": "Giao diện",
  "ss_builder_block_rating_answer_2": "Thời gian tải trang",
  "ss_builder_block_rating_answer_3": "Trải nghiệm",
  "ss_builder_block_rating_answer_4": "Hỗ trợ",
  "ss_builder_block_rating_answer_5": "Khác",
  "ss_builder_block_rating_button_submit_rating": "Gửi đánh giá",
  "ss_builder_block_rating_button_title_open_popup": "Gửi đánh giá",
  "ss_builder_block_rating_content_rating": "Nội dung đánh giá",
  "ss_builder_block_rating_content_rating_placeholder": "Hãy để lại góp ý cho Passio Page nhé",
  "ss_builder_block_rating_description": "Sẽ thật tuyệt vời nếu Passio Page nhận được đánh giá của bạn để nâng cao chất lượng dịch vụ",
  "ss_builder_block_rating_description_auto_show": "Đánh giá của bạn về Passio Page",
  "ss_builder_block_rating_description_star_1": "Bạn thấy chưa hài lòng vì",
  "ss_builder_block_rating_description_star_2": "Bạn thấy chưa hài lòng vì",
  "ss_builder_block_rating_description_star_3": "Passio Page cần cải tiến về",
  "ss_builder_block_rating_description_star_4": "Bạn thấy hài lòng vì",
  "ss_builder_block_rating_description_star_5": "Bạn thấy hài lòng vì",
  "ss_builder_block_rating_star_1": "Thất vọng",
  "ss_builder_block_rating_star_2": "Chán nhỉ",
  "ss_builder_block_rating_star_3": "Dùng được",
  "ss_builder_block_rating_star_4": "Tốt đó",
  "ss_builder_block_rating_star_5": "Xuất sắc",
  "ss_builder_block_rating_title": "Đánh giá của bạn",
  "ss_builder_block_rating_toast_success_rating": "Cảm ơn bạn đã đánh giá",
  "ss_builder_block_second_color": "Màu phụ",
  "ss_builder_block_setting_title": "Thiết lập khối",
  "ss_builder_block_shadow_color": "Màu đổ bóng",
  "ss_builder_block_space": "Khoảng cách giữa các khối",
  "ss_builder_block_spacer_and_divider_description": "Thêm khoảng cách hoặc dải phân cách giữa các khối của bạn",
  "ss_builder_block_text_description": "Thêm nội dung vào trang của bạn bằng văn bản có định dạng",
  "ss_builder_block_text_see_more_btn_default_title": "Xem thêm",
  "ss_builder_block_truth_or_dare_description": "Chơi tới bến với game Truth or Dare",
  "ss_builder_block_truth_or_dare_footer_description": "Hiển thị icon Truth or Dare ở góc màn hình",
  "ss_builder_block_virtual_gift_description": "Bật tính năng để nhận quà tặng từ Fan",
  "ss_builder_block_virtual_gift_footer_description": "Hiển thị icon nhận quà ở góc màn hình",
  "ss_builder_blocks_general_settings_label": "Thiết lập chung các khối",
  "ss_builder_blocks_general_settings_popover_content": "Bật để áp dụng “Thiết lập khối” trong phần thiết lập toàn trang cho các khối ở danh sách phía dưới",
  "ss_builder_blocks_label": "Khối",
  "ss_builder_blocks_list": "Danh sách",
  "ss_builder_blocks_list_empty_content": "Để thêm khối vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_blocks_list_empty_title": "Chưa có khối nào được thêm",
  "ss_builder_body_description": "Có thể sử dụng các Tabs để phân chia danh sách các khối thành từng phần",
  "ss_builder_body_introduction_description": "\"Thân trang\" là phần nội dung chính của trang, chứa các Khối và được phân chia, điều hướng bởi Tabs. Ví dụ như: nội dung, hình ảnh, sản phẩm,...",
  "ss_builder_body_introduction_title": "Vị trí Thân trang",
  "ss_builder_body_title": "Thân trang",
  "ss_builder_bold": "Đậm",
  "ss_builder_booking_profile_style_1": "Thẻ nội dung",
  "ss_builder_booking_profile_style_2": "Mã nhúng",
  "ss_builder_border_dash": "Nét đứt",
  "ss_builder_border_dot": "Nét chấm",
  "ss_builder_border_radius_label": "Độ bo góc",
  "ss_builder_border_radius_style": "Kiểu bo góc",
  "ss_builder_border_solid": "Nét liền",
  "ss_builder_bottom_position_tab_label": "Dưới cùng",
  "ss_builder_box_icon_button_filter": "Lọc",
  "ss_builder_box_icon_empty_search": "Không thấy kết quả nào",
  "ss_builder_box_icon_empty_searching_description": "Hãy thử với từ khóa khác",
  "ss_builder_box_icon_filter_category_all": "Tất cả",
  "ss_builder_box_icon_filter_category_sub_title": "Chọn danh mục icon",
  "ss_builder_box_icon_filter_category_title": "Bộ lọc",
  "ss_builder_box_icon_search_bar_placeholder": "Tìm kiếm icon theo tên...",
  "ss_builder_bp_apply_block_settings_instruction": "Tắt để hiển thị toàn chiều rộng màn hình. Bật để hiển thị theo thiết lập khối như các khối thông thường.",
  "ss_builder_bp_open_new_tab_instruction": "Bật để hiển thị icon mở cửa sổ mới ở góc phải",
  "ss_builder_bp_open_new_tab_title": "Mở cửa sổ mới",
  "ss_builder_brand_gift_introduction_popup_title": "Thêm icon Brand Gift",
  "ss_builder_browser_opening_label": "Mở trên trình duyệt",
  "ss_builder_button_background_color": "Màu nền",
  "ss_builder_button_border_color": "Màu viền",
  "ss_builder_button_border_width": "Độ dày viền (px)",
  "ss_builder_button_close_introduction_service": "Đóng",
  "ss_builder_button_font_size": "Kích thước chữ (px)",
  "ss_builder_button_font_weight": "Độ đậm chữ",
  "ss_builder_button_line_height": "Chiều cao dòng",
  "ss_builder_button_link_content_title": "Tiêu đề liên kết",
  "ss_builder_button_link_description": "Mô tả liên kết",
  "ss_builder_button_link_icon_btn_selection_label": "Chọn icon",
  "ss_builder_button_link_icon_origin_url_label": "Đường dẫn liên kết",
  "ss_builder_button_link_icon_title": "Chọn icon",
  "ss_builder_button_link_large_size_label": "Lớn",
  "ss_builder_button_link_middle_size_label": "Vừa",
  "ss_builder_button_link_not_enought_infomation": "Link chưa đủ thông tin",
  "ss_builder_button_link_placeholder_content_title": "Nhập nội dung",
  "ss_builder_button_link_size_selection_title": "Chọn kích thước",
  "ss_builder_button_link_small_size_label": "Nhỏ",
  "ss_builder_button_setting_title": "Thiết lập nút bấm",
  "ss_builder_button_text_color": "Màu chữ",
  "ss_builder_button_title": "Tiêu đề nút",
  "ss_builder_cancel": "Hủy bỏ",
  "ss_builder_cancel-delete_block_label": "Hủy bỏ",
  "ss_builder_cancel-delete_image_label": "Tiếp tục chỉnh sửa",
  "ss_builder_cancel-delete_social_link_btn_label": "Hủy bỏ",
  "ss_builder_cancel-delete_tab_label": "Hủy bỏ",
  "ss_builder_cancel-restore_theme_label": "Hủy bỏ",
  "ss_builder_cancel-set_default_tab_label": "Hủy bỏ",
  "ss_builder_cancel_affiliate_product_group_add_new_panel_label": "Hủy bỏ",
  "ss_builder_cancel_apply_theme_template_btn_label": "Hủy bỏ",
  "ss_builder_cancel_block_btn_label": "Thoát",
  "ss_builder_cancel_delete_aff_block_btn_label": "Hủy bỏ",
  "ss_builder_cancel_delete_card_modal_btn_label": "Tiếp tục chỉnh sửa",
  "ss_builder_cancel_delete_link_modal_btn_label": "Tiếp tục chỉnh sửa",
  "ss_builder_cancel_delete_product_label": "Hủy bỏ",
  "ss_builder_cancel_delete_theme_template_modal_btn_label": "Hủy bỏ",
  "ss_builder_cancel_edit_block_content": "Các chỉnh sửa của bạn sẽ không được lưu. Bạn có chắc chắn muốn thoát không?",
  "ss_builder_cancel_edit_block_title": "Thoát chỉnh sửa?",
  "ss_builder_cancel_hide_product_label": "Hủy bỏ",
  "ss_builder_cancel_image_slider_add_new_btn_label": "Hủy bỏ",
  "ss_builder_cancel_overwrite_theme_template_btn_label": "Hủy bỏ",
  "ss_builder_cancel_preview_theme_template_btn_label": "Đóng",
  "ss_builder_cancel_product_addition_modal_confirm_btn_label": "Tiếp tục thêm sản phẩm",
  "ss_builder_cancel_product_addition_modal_description": "Các sản phẩm đã thêm tại màn hình này sẽ không được lưu.",
  "ss_builder_cancel_product_addition_modal_title": "Hủy thêm sản phẩm?",
  "ss_builder_cancel_replace_theme_template_btn_label": "Hủy bỏ",
  "ss_builder_cancel_select_multi_items": "Huỷ bỏ",
  "ss_builder_cancel_theme_selection_panel_label": "Hủy bỏ",
  "ss_builder_cancel_upload_image": "Huỷ bỏ",
  "ss_builder_card_add_new_description_label": "Mô tả",
  "ss_builder_card_add_new_label": "Nhãn",
  "ss_builder_card_add_new_subtitle_label": "Phụ đề",
  "ss_builder_card_add_new_title_label": "Tiêu đề",
  "ss_builder_card_add_new_url_label": "Đường dẫn liên kết",
  "ss_builder_card_additional_btn_label": "Thêm card",
  "ss_builder_card_btn_content_label": "Nội dung nút",
  "ss_builder_card_layout_alert_1": "Bố cục này sẽ không hiển thị mô tả, nhãn và nút",
  "ss_builder_card_layout_alert_2": "Bố cục này sẽ không hiển thị mô tả và nút",
  "ss_builder_card_layout_label": "Bố cục",
  "ss_builder_card_layout_label_1": "Chữ dưới ảnh trái",
  "ss_builder_card_layout_label_2": "Chữ dưới ảnh giữa",
  "ss_builder_card_layout_label_3": "Chữ trên ảnh giữa",
  "ss_builder_card_layout_label_4": "Chữ trên ảnh trái",
  "ss_builder_card_layout_label_5": "Chữ phải",
  "ss_builder_card_layout_label_6": "Chữ trái",
  "ss_builder_card_layout_label_7": "Chữ trên ảnh trái 2",
  "ss_builder_card_layout_label_8": "Chữ trên ảnh giữa 2",
  "ss_builder_card_url_placeholder": "http://domain.com",
  "ss_builder_category_14_up": "14+",
  "ss_builder_category_all": "Tất cả",
  "ss_builder_category_art": "Art",
  "ss_builder_category_creative": "Sáng tạo",
  "ss_builder_category_doctor": "Doctor",
  "ss_builder_category_education": "Giáo dục",
  "ss_builder_category_entertainment": "Entertainment",
  "ss_builder_category_fashion": "Thời trang",
  "ss_builder_category_fitness": "Fitness",
  "ss_builder_category_food_fmcg": "Food & FMCG",
  "ss_builder_category_game": "Game",
  "ss_builder_category_health_and_beauty": "Sức khỏe và làm đẹp",
  "ss_builder_category_ict": "ICT",
  "ss_builder_category_life_style": "Phong cách sống",
  "ss_builder_category_lifestyle": "Phong cách sống",
  "ss_builder_category_mom_and_kid": "Mẹ và bé",
  "ss_builder_category_others": "Khác",
  "ss_builder_category_pet": "Pet",
  "ss_builder_category_spirit": "Spirit",
  "ss_builder_change_image_button_label": "Đổi ảnh",
  "ss_builder_change_name_product_group_successfully_label": "Đổi tên nhóm sản phẩm thành công",
  "ss_builder_change_theme_label": "Thay đổi giao diện",
  "ss_builder_choose_a_prototying_method": "Chọn phương thức tạo mẫu",
  "ss_builder_choose_at_least_category": "Chọn ít nhất 1 lĩnh vực và chúng tôi sẽ gợi ý giao diện dựa trên lựa chọn của bạn 👇",
  "ss_builder_choose_image_below": "Để thêm mục mới vui lòng nhấn nút \"Thêm mới\" ở phía dưới",
  "ss_builder_choose_one_theme_preview_you_can_customize_it_later": "Chọn một chủ đề phù hợp, bạn có thể tùy chỉnh sau khi áp dụng✨",
  "ss_builder_choose_the_look_that_right_for_you": "Chọn giao diện",
  "ss_builder_choose_this_template": "Áp dụng",
  "ss_builder_cleanup_lock_product_successfully_label": "Khóa dọn dẹp sản phẩm thành công",
  "ss_builder_cleanup_unlock_product_successfully_label": "Mở khóa dọn dẹp sản phẩm thành công",
  "ss_builder_close_panel": "Hủy bỏ",
  "ss_builder_close_panel_affiliate_product-selection-label": "Hủy bỏ",
  "ss_builder_close_panel_edit_text_block": "Đóng",
  "ss_builder_collapse": "Thu gọn",
  "ss_builder_color_1": "Màu 1",
  "ss_builder_color_2": "Màu 2",
  "ss_builder_color_picker_panel_title": "Chọn màu",
  "ss_builder_confirm-cancel_image_slider_add_new_content": "Các thao tác của bạn chưa được lưu.\nBạn có chắc muốn thoát không ?",
  "ss_builder_confirm-cancel_image_slider_add_new_title": "Thoát chỉnh sửa?",
  "ss_builder_confirm-delete_block_label": "Nếu bạn xóa khối, các thiết lập khối và dữ liệu đã được thêm vào khối sẽ bị xóa vĩnh viễn. Bạn có chắc muốn xóa không?",
  "ss_builder_confirm-delete_block_title": "Bạn muốn xóa khối này?",
  "ss_builder_confirm-delete_image_label": "Bạn có chắc muốn xóa ảnh này?",
  "ss_builder_confirm-delete_image_title": "Xóa ảnh",
  "ss_builder_confirm-delete_social_link_content": "Bạn có chắc muốn xóa mục này?",
  "ss_builder_confirm-delete_social_link_title": "Xóa mạng xã hội?",
  "ss_builder_confirm_affiliate_product_group_add_new_panel_label": "Lưu",
  "ss_builder_confirm_apply_template_description": "Việc áp dụng mẫu sẽ thay thế tất cả các khối đang có trong danh sách của bạn bằng các khối của mẫu.",
  "ss_builder_confirm_apply_template_label": "Thay thế",
  "ss_builder_confirm_apply_theme_template_btn_label": "Xác nhận áp dụng",
  "ss_builder_confirm_apply_theme_template_content": "Việc áp dụng giao diện bạn chọn sẽ thay đổi toàn bộ giao diện trang của bạn",
  "ss_builder_confirm_apply_theme_template_title": "Bạn muốn áp dụng giao diện này?",
  "ss_builder_confirm_cancel_image_slider_add_new_btn_label": "Thoát",
  "ss_builder_confirm_cleanup_lock_product_btn_label": "Xác nhận khóa",
  "ss_builder_confirm_cleanup_lock_product_selection_modal_content": "Bạn có chắc muốn khóa dọn dẹp {{product_quantity}} sản phẩm này?",
  "ss_builder_confirm_cleanup_unlock_product_btn_label": "Xác nhận mở",
  "ss_builder_confirm_cleanup_unlock_product_selection_modal_content": "Bạn có chắc muốn mở khóa dọn dẹp {{product_quantity}} sản phẩm này?",
  "ss_builder_confirm_delete_aff_block_content": "Thao tác này không thể hoàn tác. Hãy xác nhận, bạn hiểu việc xóa khối này sẽ xóa toàn bộ các sản phẩm bên trong.",
  "ss_builder_confirm_delete_aff_block_input_placeholder": "OK",
  "ss_builder_confirm_delete_aff_block_label_enter_ok": "Nhập “OK” để xác nhận",
  "ss_builder_confirm_delete_aff_block_understood": "Tôi đã hiểu",
  "ss_builder_confirm_delete_aff_block_warining": "Cảnh báo",
  "ss_builder_confirm_delete_block_btn_label": "Xác nhận xóa",
  "ss_builder_confirm_delete_card_modal_btn_label": "Xóa card",
  "ss_builder_confirm_delete_card_modal_description": "Bạn có chắc muốn xóa card này?",
  "ss_builder_confirm_delete_card_modal_label": "Xóa card",
  "ss_builder_confirm_delete_combined_label": "Bạn có chắc muốn xoá khối {{type}}?",
  "ss_builder_confirm_delete_image_popup_image_label": "Xác nhận xóa",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_content": "Bạn có chắc muốn xoá {{quantity}} liên kết này?",
  "ss_builder_confirm_delete_item_checkbox_button_multi_link_modal_title": "Xoá liên kết",
  "ss_builder_confirm_delete_item_checkbox_card_modal_content": "Bạn có chắc muốn xoá {{quantity}} thẻ nội dung này?",
  "ss_builder_confirm_delete_item_checkbox_card_modal_title": "Xoá thẻ nội dung",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_content": "Bạn có chắc muốn xoá {{quantity}} khối này?",
  "ss_builder_confirm_delete_item_checkbox_combined_modal_title": "Xoá khối",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_content": "Bạn có chắc muốn xoá {{quantity}} ảnh này?",
  "ss_builder_confirm_delete_item_checkbox_image_gallery_modal_title": "Xoá ảnh",
  "ss_builder_confirm_delete_item_checkbox_story_modal_content": "Bạn có chắc muốn xoá {{quantity}} tiêu điểm này?",
  "ss_builder_confirm_delete_item_checkbox_story_modal_title": "Xoá tiêu điểm",
  "ss_builder_confirm_delete_link_description": "Bạn có chắc muốn xóa link này?",
  "ss_builder_confirm_delete_product_group_modal_description": "Nhóm \"{{group_name}}\" và toàn bộ dữ liệu của các sản phẩm bên trong sẽ bị xóa vĩnh viễn. Bạn có chắc muốn xóa?",
  "ss_builder_confirm_delete_product_group_modal_title": "Bạn muốn xóa nhóm?",
  "ss_builder_confirm_delete_product_label": "Xác nhận xóa",
  "ss_builder_confirm_delete_product_modal_content": "Bạn có chắc muốn xóa sản phẩm này?",
  "ss_builder_confirm_delete_product_modal_title": "Xóa sản phẩm",
  "ss_builder_confirm_delete_product_selection_modal_content": "Bạn có chắc muốn xóa {{product_quantity}} sản phẩm này?",
  "ss_builder_confirm_delete_tab_label": "Xác nhận xóa",
  "ss_builder_confirm_delete_template_modal_content": "Xóa vĩnh viễn mẫu này khỏi danh sách mẫu, các khối sử dụng mẫu này trước đó sẽ không bị ảnh hưởng",
  "ss_builder_confirm_delete_template_modal_title": "Bạn muốn xóa mẫu?",
  "ss_builder_confirm_delete_theme_template_modal_btn_label": "Xác nhận xóa",
  "ss_builder_confirm_delete_theme_template_modal_content": "Xóa vĩnh viễn giao diện mẫu khỏi cửa hàng giao diện, những theme được tạo ra từ giao diện mẫu này sẽ không bị ảnh hưởng",
  "ss_builder_confirm_delete_theme_template_modal_title": "Bạn muốn xóa giao diện mẫu?",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_content": "Bạn có chắc muốn ẩn {{quantity}} liên kết này?",
  "ss_builder_confirm_disable_item_checkbox_button_multi_link_modal_title": "Ẩn liên kết",
  "ss_builder_confirm_disable_item_checkbox_card_modal_content": "Bạn có chắc muốn ẩn {{quantity}} thẻ nội dung này?",
  "ss_builder_confirm_disable_item_checkbox_card_modal_title": "Ẩn thẻ nội dung",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_content": "Bạn có chắc muốn ẩn {{quantity}} khối này?",
  "ss_builder_confirm_disable_item_checkbox_combined_modal_title": "Ẩn khối",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_content": "Bạn có chắc muốn ẩn {{quantity}} ảnh này?",
  "ss_builder_confirm_disable_item_checkbox_image_gallery_modal_title": "Ẩn ảnh",
  "ss_builder_confirm_disable_item_checkbox_story_modal_content": "Bạn có chắc muốn ẩn {{quantity}} tiêu điểm này?",
  "ss_builder_confirm_disable_item_checkbox_story_modal_title": "Ẩn tiêu điểm",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_content": "Bạn có chắc muốn hiện {{quantity}} liên kết này?",
  "ss_builder_confirm_enable_item_checkbox_button_multi_link_modal_title": "Hiện liên kết",
  "ss_builder_confirm_enable_item_checkbox_card_modal_content": "Bạn có chắc muốn hiện {{quantity}} thẻ nội dung này?",
  "ss_builder_confirm_enable_item_checkbox_card_modal_title": "Hiện thẻ nội dung",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_content": "Bạn có chắc muốn hiện {{quantity}} khối này?",
  "ss_builder_confirm_enable_item_checkbox_combined_modal_title": "Hiện khối",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_content": "Bạn có chắc muốn hiện {{quantity}} ảnh này?",
  "ss_builder_confirm_enable_item_checkbox_image_gallery_modal_title": "Hiện ảnh",
  "ss_builder_confirm_enable_item_checkbox_story_modal_content": "Bạn có chắc muốn hiện {{quantity}} tiêu điểm này?",
  "ss_builder_confirm_enable_item_checkbox_story_modal_title": "Hiện tiêu điểm",
  "ss_builder_confirm_enable_product_selection_modal_content": "Bạn có chắc muốn hiện {{product_quantity}} sản phẩm này?",
  "ss_builder_confirm_hide_product_btn_label": "Xác nhận ẩn",
  "ss_builder_confirm_hide_product_group_modal_description": "Nhóm \"{{group_name}}\" và toàn bộ dữ liệu của các sản phẩm bên trong sẽ bị ẩn. Bạn có chắc muốn ẩn nhóm?",
  "ss_builder_confirm_hide_product_group_modal_title": "Bạn muốn ẩn nhóm?",
  "ss_builder_confirm_hide_product_label": "Xác nhận ẩn nhóm",
  "ss_builder_confirm_hide_product_selection_modal_content": "Bạn có chắc muốn ẩn {{product_quantity}} sản phẩm này?",
  "ss_builder_confirm_overwrite": "Xác nhận lưu đè",
  "ss_builder_confirm_overwrite_theme_template_btn_label": "Xác nhận lưu đè",
  "ss_builder_confirm_overwrite_theme_template_modal_content": "Lưu đè sẽ thay thế toàn bộ dữ liệu và thiết lập của Theme mẫu bằng dữ liệu và thiết lập của Theme hiện tại",
  "ss_builder_confirm_overwrite_theme_template_modal_title": "Bạn muốn lưu đè?",
  "ss_builder_confirm_panel": "Xác nhận",
  "ss_builder_confirm_panel_affiliate_product-selection-label": "Xác nhận",
  "ss_builder_confirm_panel_edit_text_block": "Xác nhận",
  "ss_builder_confirm_replace_theme_template_btn_label": "Áp dụng nội dung mẫu",
  "ss_builder_confirm_replace_theme_template_content": "Thao tác này không thể hoàn tác.\nHãy xác nhận, bạn hiểu việc áp dụng nội dung mẫu sẽ thay thế toàn bộ dữ liệu đã có trên trang.",
  "ss_builder_confirm_replace_theme_template_input_placeholder": "OK",
  "ss_builder_confirm_replace_theme_template_label_enter_ok": "Nhập “OK” để xác nhận",
  "ss_builder_confirm_replace_theme_template_understood": "Tôi đã hiểu",
  "ss_builder_confirm_replace_theme_template_warining": "Cảnh báo",
  "ss_builder_confirm_restore_theme_label": "Khôi phục chỉnh sửa",
  "ss_builder_confirm_set_default_tab_label": "Đặt mặc định",
  "ss_builder_confirm_show_product_btn_label": "Xác nhận hiện",
  "ss_builder_confirm_theme_template_preview_mode_btn_label": "Áp dụng",
  "ss_builder_confirm_to_edit_label": "Tiếp tục chỉnh sửa",
  "ss_builder_content_1_introduction_service": "Brand Gift là hình thức quảng bá các CTKM, deal hot từ thương hiệu dành cho fan/followers nhằm tăng cao tỉ lệ \"chốt đơn\" và mang về hoa hồng \"khủng\" cho creators.. Với mỗi lượt lưu voucher thành công từ link Brand Gift, bạn sẽ được nhận được chính sách hoa hồng siêu hấp dẫn:",
  "ss_builder_content_2_introduction_service": "Bật Brand Gift để nhận tiền thưởng luôn kẻo lỡ!",
  "ss_builder_content_creators_joined_the_passio_system": "100K+ nhà sáng tạo nội dung đã tham gia vào hệ thống Passio",
  "ss_builder_content_editor_title": "Nội dung",
  "ss_builder_content_list_1_introduction_service": "Hoa hồng sàn",
  "ss_builder_content_list_2_introduction_service": "Hoa hồng thương hiệu (up to 30%)",
  "ss_builder_content_list_3_introduction_service": "500+ thương hiệu lớn thuộc đa lĩnh vực với nhiều chương trình bonus",
  "ss_builder_continue_add_brand_gift": "Khám phá ngay",
  "ss_builder_continue_edit_block_label": "Tiếp tục chỉnh sửa",
  "ss_builder_continue_edit_image_popup_image_label": "Hủy bỏ",
  "ss_builder_continue_to_add_block": "Tiếp tục",
  "ss_builder_convert_and_go_to_new_version": "Đồng bộ và đến Passio Page",
  "ss_builder_cover_image": "Ảnh bìa",
  "ss_builder_create_bio_page_free_fast": "Tạo Biopage miễn phí & nhanh chóng",
  "ss_builder_create_link_with_your_name_and_imprint": "Tạo trang cá nhân mang tên và dấu ấn của bạn. Kho giao diện nhiều chủ đề đa dạng, có thể tùy biến dễ dàng ngay trên điện thoại.",
  "ss_builder_create_new_theme_template_label": "Tạo giao diện mẫu mới",
  "ss_builder_create_template": "Tạo mẫu",
  "ss_builder_cusom": "Tùy chỉnh",
  "ss_builder_custom": "Tùy chỉnh",
  "ss_builder_dashed_type_label": "Nét đứt",
  "ss_builder_data_auto_convert": "Hệ thống sẽ tự động đồng bộ dữ liệu từ Landing Page cũ sang Passio Page mới.",
  "ss_builder_data_can_be_convert": "Bạn có thể đồng bộ lại dữ liệu từ Landing Page cũ sang Passio Page mới.",
  "ss_builder_data_will_be_override": "Lưu ý: Nếu đồng bộ lại sẽ ghi đè lên dữ liệu đang có trên Passio Page",
  "ss_builder_deep_blue_sky": "Bầu trời trong xanh thăm thẳm",
  "ss_builder_default_meta_description": "Passio Page - Ngôi nhà của {{creator_name}}",
  "ss_builder_defaults_label": "Mặc định",
  "ss_builder_delete_block_label": "Xóa khối",
  "ss_builder_delete_block_successfully_label": "Xóa khối thành công",
  "ss_builder_delete_card_label": "Xóa card",
  "ss_builder_delete_card_successfully_label": "Xóa card thành công",
  "ss_builder_delete_image_label": "Xóa ảnh",
  "ss_builder_delete_image_successfully_label": "Xóa ảnh thành công",
  "ss_builder_delete_item_checkbox_button_multi_link_successfully_label": "Xoá liên kết thành công",
  "ss_builder_delete_item_checkbox_card_successfully_label": "Xoá thẻ nội dung thành công",
  "ss_builder_delete_item_checkbox_combined_successfully_label": "Xoá khối thành công",
  "ss_builder_delete_item_checkbox_image_gallery_successfully_label": "Xoá ảnh thành công",
  "ss_builder_delete_item_checkbox_story_successfully_label": "Xoá tiêu điểm thành công",
  "ss_builder_delete_link_label": "Xóa link",
  "ss_builder_delete_link_successfully_label": "Xóa link thành công",
  "ss_builder_delete_product_group_error_btn_confirm_label": "Đóng",
  "ss_builder_delete_product_group_error_content": "Nhóm \"{{group_name}}\" không thể bị xóa, cần tối thiểu hiển thị 1 nhóm.",
  "ss_builder_delete_product_group_error_title": "Lỗi xóa nhóm",
  "ss_builder_delete_product_group_label": "Xóa nhóm",
  "ss_builder_delete_product_group_successfully_label": "Xóa nhóm sản phẩm thành công",
  "ss_builder_delete_product_label": "Xóa sản phẩm",
  "ss_builder_delete_product_successfully_label": "Xóa sản phẩm thành công",
  "ss_builder_delete_social_link_btn_label": "Xác nhận xóa",
  "ss_builder_delete_social_link_label": "Xóa",
  "ss_builder_delete_social_link_successfully_label": "Xóa mạng xã hội thành công",
  "ss_builder_delete_tab_content": "Tab \"{{tab_name}}\" và toàn bộ dữ liệu của các khối bên trong sẽ bị xóa vĩnh viễn. Bạn có chắc muốn xóa?",
  "ss_builder_delete_tab_label": "Xóa",
  "ss_builder_delete_tab_successfully_label": "Xóa tab thành công",
  "ss_builder_delete_tab_title": "Bạn muốn xóa tab?",
  "ss_builder_delete_template_successfully_label": "Xóa mẫu thành công",
  "ss_builder_delete_theme_template_label": "Xóa",
  "ss_builder_delete_theme_template_successfully_label": "Xóa giao diện mẫu thành công",
  "ss_builder_description": "Mô tả",
  "ss_builder_description_page_builder": "Xây dựng trang cá nhân chỉ với 1 click và bắt đầu kiếm tiền ngay",
  "ss_builder_description_replace_template_content": "Áp dụng nội dung mẫu sẽ sử dụng toàn bộ nội dung từ giao diện mẫu thay thế cho nội dung đang có trên trang của bạn",
  "ss_builder_design_group_floating_title": "Floating icons",
  "ss_builder_design_group_image_popup_title": "Popup",
  "ss_builder_design_label": "Thiết kế",
  "ss_builder_dialog_error_confirm_text": "Làm mới",
  "ss_builder_dialog_error_content": "Đã có thay đổi thiết kế từ một thiết bị khác. Bạn cần làm mới lại trang.",
  "ss_builder_dialog_error_title": "Có thay đổi xảy ra",
  "ss_builder_digital_product_free_label": "Miễn phí",
  "ss_builder_digital_product_list_title": "Danh sách sản phẩm",
  "ss_builder_direction_of_background_image_label": "Vị trí ảnh nền",
  "ss_builder_disable_block_successfully_label": "Ẩn khối thành công",
  "ss_builder_disable_image_successfully_label": "Ẩn ảnh thành công",
  "ss_builder_disable_item_checkbox_button_multi_link_successfully_label": "Ẩn liên kết thành công",
  "ss_builder_disable_item_checkbox_card_successfully_label": "Ẩn thẻ nội dung thành công",
  "ss_builder_disable_item_checkbox_combined_successfully_label": "Ẩn khối thành công",
  "ss_builder_disable_item_checkbox_image_gallery_successfully_label": "Ẩn ảnh thành công",
  "ss_builder_disable_item_checkbox_story_successfully_label": "Ẩn tiêu điểm thành công",
  "ss_builder_disable_product_group_successfully_label": "Ẩn nhóm sản phẩm thành công",
  "ss_builder_disable_product_successfully_label": "Ẩn sản phẩm thành công",
  "ss_builder_disable_social_link_successfully_label": "Ẩn mạng xã hội thành công",
  "ss_builder_disable_tab_successfully_label": "Ẩn tab thành công",
  "ss_builder_disable_theme_successfully_label": "Ẩn mẫu thành công",
  "ss_builder_discover_now": "Khám phá ngay",
  "ss_builder_distance_blocks": "Khoảng cách giữa các khối",
  "ss_builder_divider_color_label": "Màu dải phân cách",
  "ss_builder_divider_image_label": "Ảnh dải phân cách",
  "ss_builder_divider_label": "Dải phân cách",
  "ss_builder_divider_thickness_label": "Độ dầy nét (px)",
  "ss_builder_do_you_want_replace_sample_content": "Bạn muốn áp dụng nội dung giao diện mẫu?",
  "ss_builder_domain_site_setting_alert_infomation": "Nếu bạn đổi tên miền, bạn sẽ phải đợi 30 ngày cho lần tiếp theo",
  "ss_builder_domain_site_setting_rule": "Bắt đầu bằng chữ, chỉ chứa số và chữ",
  "ss_builder_done_panel": "Xong",
  "ss_builder_dont_add_block_from_template_description": "Không thể thêm {{number_block}} khối từ mẫu do giới hạn 10 khối trong danh sách. Vui lòng xóa bớt khối đã có để thêm khối mẫu.",
  "ss_builder_dont_add_block_from_template_title": "Không thể thêm khối",
  "ss_builder_dont_add_product_exist_at_add_new_content": "Sản phẩm bạn muốn thêm đã có trong danh sách sản phẩm thêm mới.",
  "ss_builder_dont_add_product_exist_at_list_content": "Sản phẩm bạn muốn thêm đã có trong nhóm sản phẩm \"{{group_name}}\".",
  "ss_builder_dont_add_same_affiliate_product_confirm_label": "Đồng ý",
  "ss_builder_dont_add_same_affiliate_product_modal_label": "Không thêm được sản phẩm",
  "ss_builder_dont_apply_template_content_modal_description": "Quá trình áp dụng mẫu không thể thực hiện được do mẫu không còn tồn tại.",
  "ss_builder_dont_apply_theme_content_modal_btn_confirm_label": "Đóng",
  "ss_builder_dont_apply_theme_content_modal_description": "Quá trình áp dụng dữ liệu mẫu không thể thực hiện được do giao diện mẫu không còn tồn tại ở cửa hàng giao diện.",
  "ss_builder_dont_apply_theme_content_modal_label": "Không thể áp dụng",
  "ss_builder_dot_strokes_type_label": "Nét chấm",
  "ss_builder_dp_title_intro": "Sản phẩm số",
  "ss_builder_drop_here_label": "Thêm khối vào đây",
  "ss_builder_duplicated_product_url_label": "Đường dẫn bị trùng lặp",
  "ss_builder_duplicated_theme_template_name_warning": "Tên giao diện mẫu đã tồn tại. Vui lòng nhập tên khác!",
  "ss_builder_earn_additional_commissions_label": "Kiếm extra commission khi sử dụng link affiliate của Passio",
  "ss_builder_edit_affiliate_product_group_title": "Tên nhóm",
  "ss_builder_edit_affiliate_product_title": "Chỉnh sửa sản phẩm",
  "ss_builder_edit_block_label": "Chỉnh sửa",
  "ss_builder_edit_block_successfully_label": "Chỉnh sửa khối thành công",
  "ss_builder_edit_image_of_image_popup_title": "Chỉnh sửa ảnh popup",
  "ss_builder_edit_name_affiliate_product_group_title": "Đổi tên nhóm sản phẩm",
  "ss_builder_edit_name_block_successfully_label": "Cập nhật tên khối thành công",
  "ss_builder_edit_product_group_label": "Đổi tên nhóm",
  "ss_builder_edit_tab_label": "Đổi tên",
  "ss_builder_edit_tab_successfully_label": "Cập nhật tab thành công",
  "ss_builder_edit_template_btn_label": "Lưu",
  "ss_builder_edit_template_title": "Sửa thông tin mẫu",
  "ss_builder_edit_text_block_label": "Tên khối",
  "ss_builder_edit_theme_btn_label": "Lưu",
  "ss_builder_edit_theme_template_label": "Sửa thông tin",
  "ss_builder_edit_theme_template_successfully_label": "Cập nhật thành công",
  "ss_builder_edit_theme_template_title": "Sửa thông tin giao diện mẫu",
  "ss_builder_editor_btn-add-more": "Thêm",
  "ss_builder_editor_btn-cancel": "Hủy bỏ",
  "ss_builder_editor_btn-save": "Lưu",
  "ss_builder_email_placeholder": "Nhập email",
  "ss_builder_embed_code_label": "Mã nhúng",
  "ss_builder_embed_code_placeholder": "Vui lòng nhập mã nhúng",
  "ss_builder_empty_block": "Chưa có khối",
  "ss_builder_empty_search": "Không thấy kết quả nào",
  "ss_builder_empty_social_link_list_content": "Để thêm mạng xã hội vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_empty_social_link_list_title": "Chưa có mạng xã hội nào được thêm",
  "ss_builder_empty_template_label": "Chưa có mẫu nào",
  "ss_builder_enable_block_successfully_label": "Hiện khối thành công",
  "ss_builder_enable_border": "Hiển thị",
  "ss_builder_enable_button_border": "Hiển thị viền",
  "ss_builder_enable_image_successfully_label": "Hiện ảnh thành công",
  "ss_builder_enable_item_checkbox_button_multi_link_successfully_label": "Hiện liên kết thành công",
  "ss_builder_enable_item_checkbox_card_successfully_label": "Hiện thẻ nội dung thành công",
  "ss_builder_enable_item_checkbox_combined_successfully_label": "Hiện khối thành công",
  "ss_builder_enable_item_checkbox_image_gallery_successfully_label": "Hiện ảnh thành công",
  "ss_builder_enable_item_checkbox_story_successfully_label": "Hiện tiêu điểm thành công",
  "ss_builder_enable_product_group_successfully_label": "Hiện nhóm sản phẩm thành công",
  "ss_builder_enable_product_successfully_label": "Hiện sản phẩm thành công",
  "ss_builder_enable_shadow": "Hiển thị",
  "ss_builder_enable_social_link_successfully_label": "Hiện mạng xã hội thành công",
  "ss_builder_enable_tab_successfully_label": "Hiện tab thành công",
  "ss_builder_enable_theme_successfully_label": "Hiện mẫu thành công",
  "ss_builder_enable_tracking_link_product_label": "Bật tracking link",
  "ss_builder_enable_tracking_link_product_successfully_label": "Bật tracking link thành công",
  "ss_builder_enter_domain_site_setting_label": "Đường dẫn tên miền riêng",
  "ss_builder_enter_font_name": "Nhập tên phông",
  "ss_builder_enter_url_label": "Nhập đường dẫn",
  "ss_builder_enter_your_url": "Nhập đường dẫn của bạn",
  "ss_builder_error_block_label": "Lỗi hiển thị khối",
  "ss_builder_error_invalid_email_message": "Email không đúng định dạng",
  "ss_builder_error_invalid_phone_message": "Số điện thoại không đúng định dạng",
  "ss_builder_error_network_confirm_btn_label": "Đóng",
  "ss_builder_error_network_content": "Hệ thống đang bị quá tải. Vui lòng thử lại.",
  "ss_builder_error_network_title": "Hệ thống quá tải",
  "ss_builder_error_page_title": "Passio Page Builder - {{country}} - Error Page",
  "ss_builder_exceeding_label": "Vượt quá giới hạn",
  "ss_builder_existed_product_group_label": "Nhóm đã tồn tại",
  "ss_builder_expand": "Xem thêm",
  "ss_builder_expand_introduction_description": "Vị trí “Mở rộng” chứa các Khối không nằm cố định trên trang. Ví dụ như: Popup, Icon nổi",
  "ss_builder_expand_introduction_title": "Vị trí Mở rộng",
  "ss_builder_filter_select_combined_count": "khối đã chọn",
  "ss_builder_filter_select_multi_items": "{{num_items}} cuối",
  "ss_builder_filter_select_multi_items_card_count": "thẻ nội dung đã chọn",
  "ss_builder_filter_select_multi_items_gallery_count": "ảnh đã chọn",
  "ss_builder_filter_select_multi_items_multi_links_count": "liên kết đã chọn",
  "ss_builder_filter_select_multi_items_spotlight_count": "tiêu điểm đã chọn",
  "ss_builder_first_guide_add_block_btn_title": "Thêm khối",
  "ss_builder_first_guide_add_block_description": "Nhấn hoặc Kéo & Thả nút \"Thêm khối\" đến vị trí mong muốn để thêm Khối mới vào trang của bạn. Rất nhiều kiểu Khối có sẵn, giúp bạn sáng tạo trang cá nhân đẹp mắt, đa mục đích. Hãy khám phá nhé!",
  "ss_builder_first_guide_add_block_title": "Nút Thêm Khối",
  "ss_builder_first_guide_preview_btn_title": "Xem trước trang",
  "ss_builder_first_guide_preview_description": "Xem trước trang để thấy các thay đổi hiện tại của trang. Chỉ nên Xuất bản khi bạn đã xem trước và hài lòng với các thay đổi.",
  "ss_builder_first_guide_preview_title": "Nút xem trước trang",
  "ss_builder_first_guide_publish_btn_title": "Xuất bản",
  "ss_builder_first_guide_publish_description": "Các chỉnh sửa Thiết kế luôn ở chế độ \"Bản nháp\". Các thay đổi sẽ được hiển thị cho Fan của bạn sau khi bạn quyết định \"Xuất bản\".",
  "ss_builder_first_guide_publish_title": "Nút xuất bản thiết kế",
  "ss_builder_floating_full": "Chỉ được thêm tối đa hai khối trong nhóm Floating icons",
  "ss_builder_floating_introduction_description": "Các khối nằm trong vị trí này sẽ hiển thị dạng icon nổi ở góc dưới, bên phải của màn hình. Luôn hiển thị để dễ dàng truy cập tới.",
  "ss_builder_floating_introduction_title": "Icon nổi",
  "ss_builder_font_name_not_exist": "Phông chữ không tồn tại",
  "ss_builder_font_option_panel_title": "Kiểu chữ",
  "ss_builder_footer_description": "Các khối này sẽ luôn xuất hiện ở mọi Tabs",
  "ss_builder_footer_introduction_description": "“Cuối trang” chứa các Khối, luôn xuất hiện ở phần dưới cùng của trang. Ví dụ như: liên kết nhanh, thông tin trang...",
  "ss_builder_footer_introduction_title": "Vị trí Cuối trang",
  "ss_builder_footer_title": "Cuối trang",
  "ss_builder_frame_aff": "Khung ảnh sản phẩm",
  "ss_builder_general_setting_title": "Thiết lập chung",
  "ss_builder_generate_income_from_your_own_fan_community": "Tạo thu nhập từ chính cộng đồng fans của mình!",
  "ss_builder_gift_warning": "Chỉ được thêm một khối này trên trang",
  "ss_builder_go_back_to_site": "Trở về trang chính",
  "ss_builder_google_font": "Google Fonts",
  "ss_builder_gradient": "Dải màu",
  "ss_builder_group_is_full_of_product_modal_description": "Nhóm sản phẩm bạn chọn đã đầy. Vui lòng chọn nhóm khác",
  "ss_builder_group_is_full_of_product_modal_title": "Không thể thêm",
  "ss_builder_group_is_hiding_label": "Nhóm sản phẩm đang bị ẩn",
  "ss_builder_hard_shadow": "Đổ bóng cứng",
  "ss_builder_header_description": "Các khối này sẽ luôn xuất hiện ở mọi Tabs",
  "ss_builder_header_introduction_description": "“Đầu trang” chứa các Khối, luôn xuất hiện ở phần trên cùng của trang. Ví dụ như: ảnh đại diện, phần giới thiệu...",
  "ss_builder_header_introduction_title": "Vị trí Đầu trang",
  "ss_builder_header_title": "Đầu trang",
  "ss_builder_heading_and_subtitle_site_setting_label": "Tiêu đề và mô tả trang",
  "ss_builder_heading_site_setting_description": "Được hiển thị bởi các công cụ tìm kiếm trong bản xem trước của họ về trang của bạn. Điều này quan trọng đối với cả SEO và chia sẻ qua mạng xã hội.",
  "ss_builder_heading_site_setting_label": "Tiêu đề trang",
  "ss_builder_hide_block_label": "Ẩn khối",
  "ss_builder_hide_card_label": "Ẩn card",
  "ss_builder_hide_card_successfully_label": "Ẩn card thành công",
  "ss_builder_hide_image_label": "Ẩn ảnh",
  "ss_builder_hide_link_label": "Ẩn link",
  "ss_builder_hide_link_successfully_label": "Ẩn link thành công",
  "ss_builder_hide_product_group_error_btn_confirm_label": "Đóng",
  "ss_builder_hide_product_group_error_content": "Nhóm \"{{group_name}}\" không thể bị ẩn, cần tối thiểu hiển thị 1 nhóm.",
  "ss_builder_hide_product_group_error_title": "Lỗi ẩn nhóm",
  "ss_builder_hide_product_group_label": "Ẩn nhóm",
  "ss_builder_hide_product_label": "Ẩn sản phẩm",
  "ss_builder_hide_social_link_label": "Ẩn",
  "ss_builder_hide_tab_label": "Ẩn Tab",
  "ss_builder_hide_template_successfully_label": "Ẩn mẫu thành công",
  "ss_builder_hide_theme_template_label": "Ẩn mẫu",
  "ss_builder_hightlight_block_label": "Nổi bật",
  "ss_builder_icon_tab_title": "Biểu tượng",
  "ss_builder_if_delete": "Nếu bạn xóa khối Affiliate",
  "ss_builder_image": "Ảnh",
  "ss_builder_image_description_place_holder": "Nhập nội dung",
  "ss_builder_image_gallery": "Image gallery",
  "ss_builder_image_list_empty_content": "Để thêm ảnh vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_image_list_empty_title": "Chưa có ảnh nào được thêm",
  "ss_builder_image_orientation": "Hướng ảnh",
  "ss_builder_image_orientation_label": "Hướng ảnh",
  "ss_builder_image_popup_introduction_description": "Các khối nằm trong vị trí này sẽ bung lên dạng popup khi Fan truy cập vào trang của bạn. Popup dùng để hiển thị thông báo quan trọng, kêu gọi hành động, banner quảng cáo...",
  "ss_builder_image_popup_introduction_title": "Popup",
  "ss_builder_image_popup_warning": "Chỉ được thêm một khối này trên trang",
  "ss_builder_image_ratio_title": "Chọn tỷ lệ",
  "ss_builder_image_selection_label": "Chọn ảnh",
  "ss_builder_image_slider_arrow_switch_title": "Mũi tên",
  "ss_builder_image_slider_auto_play_switch_title": "Tự động chuyển",
  "ss_builder_image_slider_dot_switch_title": "Dot slider",
  "ss_builder_image_slider_list_title": "Danh sách ảnh",
  "ss_builder_image_slider_ratio_selected_title": "Tỷ lệ ảnh",
  "ss_builder_image_title_place_holder": "Nhập nội dung",
  "ss_builder_import_multi_product_url_popover_content": "Bạn có thể nhập một hoặc nhiều đường dẫn. Mỗi đường dẫn trên một dòng.",
  "ss_builder_import_site_url_label": "Import sản phẩm từ URL",
  "ss_builder_import_site_url_modal_button_label": "Thêm sản phẩm",
  "ss_builder_import_site_url_modal_description": "Nhập đường dẫn để import, hỗ trợ: koc.asia, mycollection.shop, linktr.ee, msha.ke",
  "ss_builder_import_site_url_modal_title": "Đường dẫn trang sản phẩm",
  "ss_builder_in_block_delete_forever_are_you_sure": "trong khối sẽ bị xóa vĩnh viễn. Bạn có chắc muốn xóa không?",
  "ss_builder_information_handler_alert": "Đang xử lý thông tin. Vui lòng chờ trong giây lát.",
  "ss_builder_inner_space": "Khoảng cách trong khối",
  "ss_builder_intro_brand_gift_floating_continue": "Thêm ngay",
  "ss_builder_intro_brand_gift_floating_description": "Thêm Brand Gift icon ngay để giúp followers săn deal chốt đơn thành công",
  "ss_builder_intro_brand_gift_floating_reject": "Từ chối",
  "ss_builder_intro_brand_gift_floating_title": "X2 thu nhập cùng Brand Gift Floating Icon",
  "ss_builder_intro_brand_rating_floating_description": "Thêm tính năng ngay để giúp Fan săn deal thành công và tăng thu nhập",
  "ss_builder_intro_brand_rating_floating_title": "Brand Ratings",
  "ss_builder_introduce_double_tap_button": "Tiếp tục chỉnh sửa",
  "ss_builder_introduce_double_tap_content": "Ngoài cách chọn “Chỉnh sửa” ở từng khối bạn có thể nhấn đúp trực tiếp vào khối để chỉnh sửa.",
  "ss_builder_introduce_double_tap_label_check": "Không hiển thị lại lần sau",
  "ss_builder_introduce_double_tap_title": "Mẹo chỉnh sửa khối",
  "ss_builder_invalid_product_url_label": "Đường dẫn không hợp lệ",
  "ss_builder_invalid_url": "Đường dẫn không đúng định dạng",
  "ss_builder_items_per_row": "Số item trên một hàng",
  "ss_builder_keep_the_content": "Giữ lại nội dung",
  "ss_builder_landscape_image_orientation_label": "Theo chiều ngang",
  "ss_builder_limit_reached": "Đã đạt tới giới hạn",
  "ss_builder_link_additional_btn_label": "Thêm Link",
  "ss_builder_link_select_service": "Chọn dịch vụ",
  "ss_builder_link_text_left_layout": "Chữ trái",
  "ss_builder_link_text_right_layout": "Chữ phải",
  "ss_builder_link_text_under_layout": "Chữ dưới",
  "ss_builder_link_text_zig_zag_layout": "Zig Zag",
  "ss_builder_link_type_email": "Email",
  "ss_builder_link_type_phone": "Số điện thoại",
  "ss_builder_link_type_service": "Dịch vụ",
  "ss_builder_link_type_tel": "Tel",
  "ss_builder_link_type_web": "Web",
  "ss_builder_link_url": "Đường dẫn liên kết",
  "ss_builder_list_button_multi_link_label": "Danh sách",
  "ss_builder_list_card_empty_content": "Để thêm thẻ nội dung vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_list_card_empty_title": "Chưa có thẻ nội dung nào được thêm",
  "ss_builder_list_card_label": "Danh sách card",
  "ss_builder_list_image_popup_title": "Danh sách",
  "ss_builder_list_label": "Danh sách",
  "ss_builder_list_link_empty_content": "Để thêm liên kết vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_list_link_empty_title": "Chưa có liên kết nào được thêm",
  "ss_builder_list_title_digital_product": "Danh sách",
  "ss_builder_loading_label": "Đang tải",
  "ss_builder_malformed_domain_warning": "Tên miền không đúng định dạng cho phép",
  "ss_builder_malformed_embed_code_warning": "Mã nhúng không hợp lệ",
  "ss_builder_manage_block_description": "Ss Builder Manage Block Description",
  "ss_builder_manage_block_title": "Quản lý khối",
  "ss_builder_manage_tab_description": "Tabs dùng để phân chia danh sách các khối thành từng phần",
  "ss_builder_manage_tab_title": "Quản lý Tab",
  "ss_builder_management_digital_product_description": "Danh sách sản phẩm số của bạn",
  "ss_builder_management_digital_product_title": "Digital Product",
  "ss_builder_management_label": "Quản trị",
  "ss_builder_medium": "Vừa",
  "ss_builder_missing_required_product_url_label": "Thiếu thông tin bắt buộc",
  "ss_builder_modal_button_close": "Đóng",
  "ss_builder_move_block_successfully_label": "Di chuyển khối thành công",
  "ss_builder_move_down_block_label": "Chuyển xuống dưới",
  "ss_builder_move_product_label": "Di chuyển tới",
  "ss_builder_move_product_quantity_to_group_alert_label": "Di chuyển {{product_quantity}} sản phẩm tới nhóm",
  "ss_builder_move_product_successfully_label": "Di chuyển sản phẩm thành công",
  "ss_builder_move_to_bottom_label": "Xuống dưới cùng",
  "ss_builder_move_to_top_label": "Lên trên cùng",
  "ss_builder_move_up_block_label": "Chuyển lên trên",
  "ss_builder_multi_product_url_imported_label": "Đã thêm {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} đường dẫn",
  "ss_builder_multi_product_url_importing_label": "Đang thêm {{successfullyUrlQuantity}}/{{detectedUrlQuantity}} đường dẫn",
  "ss_builder_name_tab_input_title": "Tên Tab",
  "ss_builder_need_to_display_at_least_one_block": "Cần hiển thị tối thiểu một khối",
  "ss_builder_need_to_display_at_least_one_card": "Cần hiển thị tối thiểu một thẻ nội dung",
  "ss_builder_need_to_display_at_least_one_image": "Cần hiển thị tối thiểu một ảnh",
  "ss_builder_need_to_display_at_least_one_link": "Cần hiển thị tối thiểu một liên kết",
  "ss_builder_need_to_display_at_least_one_spotlight": "Cần hiển thị tối thiểu một tiêu điểm",
  "ss_builder_next": "Tiếp theo",
  "ss_builder_no_image_selected": "Danh sách rỗng",
  "ss_builder_no_photo_has_been_selected": "Chưa có ảnh nào được chọn",
  "ss_builder_no_shadow": "Không đổ bóng",
  "ss_builder_no_themes_found_theme_store": "Không có giao diện nào",
  "ss_builder_none_of_image_description": "Để thêm ảnh popup vui lòng nhấn nút \"Thêm\" ở phía trên",
  "ss_builder_none_of_image_popup_alert": "Chưa có ảnh nào",
  "ss_builder_not_add_yet_product_url_label": "Đường dẫn chưa được thêm",
  "ss_builder_not_enough_space_product_group_modal_content": "Nhóm sản phẩm bạn chọn không đủ chỗ chứa {{num_products}} sản phẩm trong danh sách thêm. Vui lòng xóa bớt sản phẩm trong danh sách thêm hoặc chọn nhóm khác.",
  "ss_builder_not_enough_space_product_group_modal_title": "Không thể chọn nhóm",
  "ss_builder_notes": "Mô tả chi tiết lỗi",
  "ss_builder_off": "Tắt",
  "ss_builder_on": "Bật",
  "ss_builder_onboarding_choose_a_suitable_theme": "Chọn một chủ đề phù hợp, bạn có thể tùy chỉnh sau khi áp dụng✨",
  "ss_builder_onboarding_choose_the_look_for_you": "Chọn giao diện phù hợp với bạn!",
  "ss_builder_onboarding_create_a_personal_page": "Tạo trang cá nhân, làm Affliate Marketing, nhận Booking, Donation, Bán sản phẩm số (tài liệu, hình ảnh, video, thuyết trình,...)",
  "ss_builder_onboarding_no_need_know_technology": "Không cần hiểu biết về công nghệ vẫn có thể dễ dàng sử dụng bộ công cụ để tạo nên bio page.",
  "ss_builder_onboarding_theme_store_template": "Kho giao diện mẫu",
  "ss_builder_open_browser_label": "Mở trên trình duyệt",
  "ss_builder_open_link_in_new_tab": "Mở liên kết trong tab mới",
  "ss_builder_open_link_in_new_tab_description": "Mở liên kết trong tab mới",
  "ss_builder_open_new_tab_description": "Bỏ tích chọn để mở liên kết tại trang. Tích chọn để mở liên kết trong cửa sổ mới.",
  "ss_builder_open_new_tab_label": "Mở liên kết trong cửa sổ mới",
  "ss_builder_optional_color_panel_label": "Màu tùy chọn",
  "ss_builder_overwrite_description": "Việc lưu đè sẽ thay thế toàn bộ thiết lập của mẫu được chọn",
  "ss_builder_overwrite_template_btn_label": "Lưu đè",
  "ss_builder_overwrite_template_successfully_label": "Lưu đè thành công",
  "ss_builder_overwrite_template_title": "Lưu đè mẫu",
  "ss_builder_overwrite_theme_btn_label": "Lưu đè",
  "ss_builder_overwrite_theme_template_successfully_label": "Lưu đè thành công",
  "ss_builder_overwrite_theme_template_title": "Lưu đè giao diện mẫu",
  "ss_builder_overwrite_title": "Bạn muốn lưu đè?",
  "ss_builder_panel_add_icon_tab_title": "Chọn icon",
  "ss_builder_panel_add_new_tab_title": "Thông tin Tab",
  "ss_builder_panel_edit_text_block_title": "Sửa tên khối",
  "ss_builder_panel_search_label": "Tìm kiếm...",
  "ss_builder_panel_title": "Chọn kiểu khối",
  "ss_builder_paragraph_setting_font_available": "Phông có sẵn",
  "ss_builder_paragraph_setting_font_custom": "Phông tùy chỉnh",
  "ss_builder_paragraph_setting_option": "Tùy chọn",
  "ss_builder_paragraph_setting_title": "Thiết lập đoạn văn",
  "ss_builder_partial_visible_slide_type_1": "Lấp ló phải",
  "ss_builder_partial_visible_slide_type_2": "Lấp ló hai bên",
  "ss_builder_partial_visible_slide_type_3": "Không lấp ló",
  "ss_builder_partial_visible_slide_type_4": "Posters",
  "ss_builder_partial_visible_slide_type_5": "Panorama",
  "ss_builder_passio_page_ready": "Passio Page đã sẵn sàng",
  "ss_builder_passio_page_ready_description": "Passio Page đã sẵn sàng để sử dụng và thay thế cho hệ thống Landing Page cũ.",
  "ss_builder_passio_premium": "Passio Premium",
  "ss_builder_personal_link_coppy_label": "Sao chép",
  "ss_builder_personal_link_coppy_successfully_label": "Đã sao chép",
  "ss_builder_personal_link_sharing_title": "Chia sẻ trang cá nhân của bạn",
  "ss_builder_personal_link_title": "Đường dẫn trang cá nhân của bạn",
  "ss_builder_phone_placeholder": "Nhập số điện thoại",
  "ss_builder_placeholder_affiliate_product_enter_product_name": "Nhập tên sản phẩm",
  "ss_builder_placeholder_dragging_content": "Thả vào đây",
  "ss_builder_please_wait_for_the_product_to_be_updated": "Hãy chờ sản phẩm được cập nhật nhé",
  "ss_builder_popover_manual_button": "Bắt đầu khám phá",
  "ss_builder_popover_manual_button_close": "Đóng",
  "ss_builder_popover_manual_content": "Để xem hướng dẫn sử dụng tính năng Thiết kế vui lòng nhấn nút “Bắt đầu”",
  "ss_builder_popover_manual_title": "Hướng dẫn sử dụng",
  "ss_builder_popover_review_manual_content": "Bạn có thể xem lại hướng dẫn sử dụng bất kỳ khi nào, bằng cách nhấn vào nút trợ giúp này.",
  "ss_builder_popular_font_families": "Phông chữ thông dụng",
  "ss_builder_popup_confirm_button": "Xác nhận",
  "ss_builder_popup_management_title": "Quản trị",
  "ss_builder_portrait_image_orientation_label": "Theo chiều dọc",
  "ss_builder_position_ghim_tab_selection_label": "Vị trí",
  "ss_builder_powered_by_passio": "Cung cấp bởi Ecomobi",
  "ss_builder_preview": "Xem trước",
  "ss_builder_preview_image_label": "Xem trước",
  "ss_builder_preview_theme_template_title": "Xem trước ảnh",
  "ss_builder_product_design_empty_description": "Hãy chờ sản phẩm được cập nhật nhé",
  "ss_builder_product_design_empty_title": "Chưa có sản phẩm",
  "ss_builder_product_detect_link_malformed_error": "Đường dẫn không đúng định dạng",
  "ss_builder_product_detect_link_placeholder": "Nhập đường dẫn sản phẩm",
  "ss_builder_product_detect_link_title": "Đường dẫn liên kết",
  "ss_builder_product_empty_searching_description": "Hãy thử với từ khóa khác",
  "ss_builder_product_empty_searching_title": "Không tìm thấy kết quả nào",
  "ss_builder_product_exceeding_allowed_amount_content": "Để thêm sản phẩm mới vui lòng xóa bớt sản phẩm cũ",
  "ss_builder_product_exceeding_allowed_amount_title": "Quá số lượng sản phẩm được thêm",
  "ss_builder_product_group_add_new_btn_label": "Thêm nhóm sản phẩm",
  "ss_builder_product_group_default_title": "Sản phẩm",
  "ss_builder_product_group_limitation_warning_description": "Tổng số sản phẩm trong nhóm đã đạt tới giới hạn {{max_products}} sản phẩm. Vui lòng xóa bớt sản phẩm đã có nếu bạn muốn thêm mới.",
  "ss_builder_product_group_selection_placeholder": "Vui lòng chọn nhóm sản phẩm",
  "ss_builder_product_group_selection_title": "Chọn nhóm sản phẩm",
  "ss_builder_product_group_total_limitation_warning_content": "Tổng số nhóm đã đạt tới giới hạn {{group_quantity_maximum}} nhóm. Vui lòng xóa bớt nhóm đã có nếu bạn muốn thêm mới.",
  "ss_builder_product_group_total_limitation_warning_title": "Không thể thêm nhóm",
  "ss_builder_product_is_hiding_label": "Sản phẩm đang bị ẩn",
  "ss_builder_product_link_wrapped_label": "Đã bọc link",
  "ss_builder_product_list_style_grid_label": "Dạng lưới",
  "ss_builder_product_list_style_grid_one_label": "1 sản phẩm",
  "ss_builder_product_list_style_grid_three_label": "3 sản phẩm",
  "ss_builder_product_list_style_grid_two_label": "2 sản phẩm",
  "ss_builder_product_list_style_slide_label": "Dạng trượt",
  "ss_builder_product_list_style_text_over_image_label": "Chữ trên ảnh",
  "ss_builder_product_list_style_zig_zag_label": "Zigzag",
  "ss_builder_product_searching_description": "Nhập từ khóa ở trên để tìm kiếm nhé",
  "ss_builder_product_searching_title": "Tìm kiếm sản phẩm",
  "ss_builder_product_selection_option_1": "10 cuối",
  "ss_builder_product_selection_option_2": "20 cuối",
  "ss_builder_product_selection_option_3": "50 cuối",
  "ss_builder_product_selection_option_4": "5 cuối",
  "ss_builder_product_swiper_see_all": "Xem tất cả",
  "ss_builder_product_title_error": "Thiếu tên sản phẩm",
  "ss_builder_product_title_missing_warning": "Nhập tên sản phẩm",
  "ss_builder_product_total_limitation_warning_description": "Tổng số sản phẩm trong toàn bộ nhóm đã đạt tới giới hạn {{max_products_all_groups}} sản phẩm. Vui lòng xóa bớt sản phẩm đã có nếu bạn muốn thêm mới.",
  "ss_builder_product_total_limitation_warning_title": "Không thể thêm sản phẩm",
  "ss_builder_product_tour_button_step_0": "Bắt đầu khám phá",
  "ss_builder_product_tour_content_step_0": "Bắt đầu theo hướng dẫn của chúng tôi để trải nghiệm những tính năng hấp dẫn của Passio Page nhé!",
  "ss_builder_product_tour_content_step_1": "Thỏa sức sáng tạo với màn hình Thiết kế. Dễ dàng chỉnh sửa, sắp xếp vị trí các Khối tùy ý theo phong cách của riêng bạn.",
  "ss_builder_product_tour_content_step_10": "Thay thế toàn bộ nội dung trang của bạn giống hệt nội dung của giao diện mẫu đang áp dụng. Giúp trang của bạn có nội dung giống hệt như mẫu.",
  "ss_builder_product_tour_content_step_11": "Xem trước trang để thấy các thay đổi hiện tại của trang. Chỉ nên Xuất bản khi bạn đã xem trước và hài lòng với các thay đổi.",
  "ss_builder_product_tour_content_step_12": "Bằng cách nhấn giữ và kéo thả để di chuyển hoặc sử dụng “Tác vụ nhanh” để di chuyển lên/xuống một khối.",
  "ss_builder_product_tour_content_step_13": "Chọn “Chỉnh sửa” trong “Tác vụ nhanh” hoặc nhấn trực tiếp vào khối để chỉnh sửa.",
  "ss_builder_product_tour_content_step_14": "Thay đổi nền nhanh chóng bằng hình ảnh, màu sắc hoặc dải màu đầy cá tính.",
  "ss_builder_product_tour_content_step_15": "Nhiều mẫu giao diện đa dạng chủ đề, đẹp mắt và theo xu hướng để bạn thoả sức lựa chọn và sử dụng.",
  "ss_builder_product_tour_content_step_16": "Các thiết lập nâng cao giúp tinh chỉnh giao diện, thay đổi tên miền, thương hiệu cá nhân,...",
  "ss_builder_product_tour_content_step_17": "Bạn có thể xem lại hướng dẫn sử dụng bất kỳ khi nào, bằng cách nhấn vào nút trợ giúp này.",
  "ss_builder_product_tour_content_step_2": "Rất nhiều kiểu Khối sẵn sàng để thêm vào trang của bạn, giúp bạn sáng tạo trang cá nhân đẹp mắt, đa mục đích. Nhấn hoặc Kéo & Thả nút này đến vị trí mong muốn để thêm Khối mới. Hãy khám phá nhé!",
  "ss_builder_product_tour_content_step_3": "Chỉnh sửa và các tùy chọn của từng khối, cho phép bạn tùy chỉnh khối theo ý muốn.",
  "ss_builder_product_tour_content_step_4": "Các chỉnh sửa Thiết kế luôn ở chế độ \"Bản nháp\". Các thay đổi sẽ được hiển thị cho Fan của bạn sau khi bạn quyết định \"Xuất bản\".",
  "ss_builder_product_tour_content_step_5": "Tab dùng để tổ chức nội dung “Thân trang” theo nhiều phần, ví dụ: Tab Sản phẩm, Tab Video, Tab Mã giảm giá,... Nhấn vào từng Tab để xem và thay đổi nội dung bên trong. Chỉnh sửa khối này để thêm mới, quản lý Tabs.",
  "ss_builder_product_tour_content_step_6": "“Đầu trang” chứa các Khối, luôn xuất hiện ở phần trên cùng của trang. Ví dụ như: ảnh đại diện, phần giới thiệu...",
  "ss_builder_product_tour_content_step_7": "\"Thân trang\" là phần nội dung chính của trang, chứa các Khối và được phân chia, điều hướng bởi Tabs. Ví dụ như: nội dung, hình ảnh, sản phẩm,...",
  "ss_builder_product_tour_content_step_8": "“Cuối trang” chứa các Khối, luôn xuất hiện ở phần dưới cùng của trang. Ví dụ như: liên kết nhanh, thông tin trang...",
  "ss_builder_product_tour_content_step_9": "Sử dụng tính năng này, nếu các thay đổi không làm bạn vừa ý. Hệ thống sẽ khôi phục màn hình Thiết kế của bạn trở về như trang đang xuất bản.",
  "ss_builder_product_tour_done_label": "Xong",
  "ss_builder_product_tour_next_label": "Tiếp theo",
  "ss_builder_product_tour_prev_label": "Quay lại",
  "ss_builder_product_tour_skip_label": "Bỏ qua",
  "ss_builder_product_tour_title_step_0": "Chào mừng đến Passio Page",
  "ss_builder_product_tour_title_step_1": "Thiết kế giao diện",
  "ss_builder_product_tour_title_step_10": "Áp dụng nội dung mẫu",
  "ss_builder_product_tour_title_step_11": "Xem trước trang",
  "ss_builder_product_tour_title_step_12": "Di chuyển vị trí Khối",
  "ss_builder_product_tour_title_step_13": "Chỉnh sửa Khối",
  "ss_builder_product_tour_title_step_14": "Thay đổi hình nền",
  "ss_builder_product_tour_title_step_15": "Cửa hàng mẫu giao diện",
  "ss_builder_product_tour_title_step_16": "Thiết lập nâng cao",
  "ss_builder_product_tour_title_step_17": "Hướng dẫn sử dụng",
  "ss_builder_product_tour_title_step_2": "Thêm một Khối mới",
  "ss_builder_product_tour_title_step_3": "Tác vụ nhanh",
  "ss_builder_product_tour_title_step_4": "Xuất bản thiết kế",
  "ss_builder_product_tour_title_step_5": "Tab menu",
  "ss_builder_product_tour_title_step_6": "Vị trí Đầu trang",
  "ss_builder_product_tour_title_step_7": "Vị trí Thân trang",
  "ss_builder_product_tour_title_step_8": "Vị trí Cuối trang",
  "ss_builder_product_tour_title_step_9": "Khôi phục chỉnh sửa",
  "ss_builder_product_tour_use_manual": "Hướng dẫn sử dụng",
  "ss_builder_product_tour_use_manual_content": "Để xem hướng dẫn sử dụng màn hình Thiết kế vui lòng nhấn nút “Bắt đầu khám phá”",
  "ss_builder_product_url_lists_label": "Danh sách đường dẫn",
  "ss_builder_publish_button_label": "Xuất Bản",
  "ss_builder_publish_result_description": "Chúc mừng bạn đã xuất bản thành công Passio Page.\nTrang của bạn có thể được truy cập qua đường dẫn bên dưới.",
  "ss_builder_publish_result_successfully": "Xuất bản thành công!",
  "ss_builder_publish_result_title": "Kết quả xuất bản",
  "ss_builder_publish_saving": "Đang lưu",
  "ss_builder_publish_share": "Chia sẻ",
  "ss_builder_publish_view_page": "Xem trang",
  "ss_builder_randomize": "Chọn ngẫu nhiên",
  "ss_builder_ready_to_build_your_personal_brand": "Sẵn sàng xây dựng thương hiệu cá nhân cùng Passio ngay nào 💖",
  "ss_builder_recently_color_panel_label": "Màu sử dụng gần đây",
  "ss_builder_redo_theme_label": "Làm lại",
  "ss_builder_reload_label": "Làm mới",
  "ss_builder_replace": "thay thế",
  "ss_builder_replace_preview_theme_template_btn_label": "Áp dụng",
  "ss_builder_replace_theme_label": "Áp dụng nội dung mẫu",
  "ss_builder_replace_theme_modal_content": "Áp dụng nội dung mẫu sẽ thay thế tất cả các khối trên trang của bạn bằng các khối từ giao diện mẫu",
  "ss_builder_replace_theme_modal_title": "Bạn muốn áp dụng nội dung giao diện mẫu?",
  "ss_builder_replace_theme_template_successfully_label": "Áp dụng thành công",
  "ss_builder_report_sent_report_successfully": "Gửi báo lỗi thành công",
  "ss_builder_report_sent_report_successfully_description": "Cảm ơn bạn! Thông báo lỗi đã được gửi thành công tới bộ phận hỗ trợ",
  "ss_builder_restore_theme_content": "Toàn bộ cấu hình của giao diện và nội dung của các khối bên trong sẽ khôi phục lại như giao diện đã xuất bản. Bạn có chắc muốn khôi phục chỉnh sửa?",
  "ss_builder_restore_theme_label": "Khôi phục chỉnh sửa",
  "ss_builder_restore_theme_successfully_label": "Khôi phục chỉnh sửa thành công",
  "ss_builder_restore_theme_title": "Bạn muốn khôi phục chỉnh sửa?",
  "ss_builder_revenue_group_description": "Bắt đầu kiếm tiền ngay với các dịch vụ của Passio Page",
  "ss_builder_revenue_title": "Kiếm tiền",
  "ss_builder_round_photo_label": "Ảnh tròn",
  "ss_builder_rounded": "Bo góc",
  "ss_builder_sample_change_label": "Đổi mẫu",
  "ss_builder_sample_selection_label": "Chọn mẫu",
  "ss_builder_sampling_change_label": "Chọn mẫu",
  "ss_builder_save": "Lưu",
  "ss_builder_save_new_template_title": "Lưu mới mẫu",
  "ss_builder_save_new_theme_template_successfully_label": "Lưu mới thành công",
  "ss_builder_save_new_theme_template_title": "Lưu mới giao diện mẫu",
  "ss_builder_save_succesfully_description": "Những thay đổi của bạn đã được lưu lại.",
  "ss_builder_save_succesfully_label": "Lưu thành công",
  "ss_builder_search_results_theme_store": "Kết quả tìm kiếm",
  "ss_builder_search_type_block": "Tìm kiếm kiểu khối theo tên",
  "ss_builder_see_less": "Rút gọn",
  "ss_builder_see_more": "Xem thêm",
  "ss_builder_see_more_btn_default_title": "Xem thêm",
  "ss_builder_select_label": "Chọn",
  "ss_builder_select_link_type": "Chọn loại đường dẫn",
  "ss_builder_select_multi_items": "Chọn",
  "ss_builder_select_position_add_block": "Chọn vị trí thêm khối",
  "ss_builder_select_ratio_title": "Chọn tỷ lệ",
  "ss_builder_select_theme_setting_primary_color_label": "Chọn màu chủ đạo",
  "ss_builder_selected_product_in_selection_label": "sản phẩm đã chọn",
  "ss_builder_send": "Gửi",
  "ss_builder_send_report": "Gửi báo lỗi",
  "ss_builder_service_placeholder": "Chọn dịch vụ",
  "ss_builder_set_default_tab_disable_content": "Tab được đặt mặc định sẽ được thấy đầu tiên khi người dùng vào trang của bạn. Tab \"{{tab_name}}\" đang bị ẩn. Nếu đặt làm mặc định sẽ chuyển trạng thái tab này thành hiển thị. Bạn có muốn đặt tab \"{{tab_name}}\" làm mặc định không?",
  "ss_builder_set_default_tab_enable_content": "Tab được đặt mặc định sẽ được thấy đầu tiên khi người dùng vào trang của bạn. Bạn có muốn đặt tab \"{{tab_name}}\" làm mặc định không?",
  "ss_builder_set_default_tab_label": "Đặt mặc định",
  "ss_builder_set_default_tab_successfully_label": "Đặt mặc định thành công",
  "ss_builder_set_default_tab_title": "Xác nhận đặt mặc định",
  "ss_builder_setting_font_weight": "Độ đậm",
  "ss_builder_setting_line_height": "Chiều cao dòng",
  "ss_builder_setting_popover_manual_content": "Để xem hướng dẫn sử dụng cho Thiết lập vui lòng nhấn nút “Bắt đầu khám phá”",
  "ss_builder_setting_popover_manual_title": "Hướng dẫn sử dụng",
  "ss_builder_setting_saving": "Đang lưu thiết lập",
  "ss_builder_setting_text_font": "Phông chữ",
  "ss_builder_setting_text_scale": "Tỉ lệ",
  "ss_builder_setting_text_size": "Kích thước chữ (px)",
  "ss_builder_setting_title": "Cài đặt",
  "ss_builder_setting_tour_content_step_1": "Đây là đường dẫn trang của bạn. Bạn có thể sao chép, mở hoặc chia sẻ đường dẫn trang. Ngoài ra cũng có thể chỉnh sửa nhanh favicon và tên miền của trang.",
  "ss_builder_setting_tour_content_step_2": "Bạn có thể tùy chỉnh các thiết lập chung cho giao diện ở đây. Các thiết lập này sẽ áp dụng lên toàn trang của bạn.",
  "ss_builder_setting_tour_content_step_3": "Bạn có thể thiết lập thương hiệu cá nhân, các thiết lập SEO, cũng như tên miền cho trang của bạn ở đây.",
  "ss_builder_setting_tour_title_step_1": "Đường dẫn trang",
  "ss_builder_setting_tour_title_step_2": "Thiết lập giao diện",
  "ss_builder_setting_tour_title_step_3": "Thiết lập trang",
  "ss_builder_settings_label": "Thiết lập",
  "ss_builder_setup_site_setting": "Thiết lập trang",
  "ss_builder_setup_theme_setting": "Thiết lập giao diện",
  "ss_builder_shadows_style": "Đổ bóng",
  "ss_builder_shape_label": "Hình dạng",
  "ss_builder_share_label": "Chia sẻ",
  "ss_builder_show_block_label": "Hiện khối",
  "ss_builder_show_card_label": "Hiện card",
  "ss_builder_show_card_successfully_label": "Hiện card thành công",
  "ss_builder_show_image_label": "Hiện ảnh",
  "ss_builder_show_link_label": "Hiện link",
  "ss_builder_show_link_successfully_label": "Hiện link thành công",
  "ss_builder_show_product_group_label": "Hiện nhóm",
  "ss_builder_show_product_label": "Hiện sản phẩm",
  "ss_builder_show_social_link_label": "Hiện",
  "ss_builder_show_tab_label": "Hiện Tab",
  "ss_builder_show_template_successfully_label": "Hiện mẫu thành công",
  "ss_builder_show_theme_template_label": "Hiện mẫu",
  "ss_builder_site_brand_setting_label": "Thương hiệu cá nhân",
  "ss_builder_site_domain_setting_label": "Tên miền",
  "ss_builder_site_setting_browser_label": "Trình duyệt",
  "ss_builder_site_setting_coppy_label": "Sao chép",
  "ss_builder_site_setting_favicon_title": "Favicon",
  "ss_builder_site_setting_favicon_upload_description": "Favicon được hiển thị bên cạnh đường link của trang. Tải lên bất kỳ một hình ảnh yêu thích của bạn để làm Favicon nhé.",
  "ss_builder_site_setting_label": "Cài đặt trang",
  "ss_builder_site_setting_premium": "Cao cấp",
  "ss_builder_site_setting_share_label": "Chia sẻ",
  "ss_builder_site_setting_standard": "Cơ bản",
  "ss_builder_site_setting_upgrade": "Nâng cấp",
  "ss_builder_skip": "Bỏ qua",
  "ss_builder_slide_type_label": "Kiểu trượt",
  "ss_builder_social_link_btn_add_new_label": "Thêm mạng xã hội",
  "ss_builder_social_links_list_title": "Danh sách",
  "ss_builder_social_network_label": "Mạng xã hội",
  "ss_builder_soft_shadow": "Đổ bóng mềm",
  "ss_builder_solid": "Màu đặc",
  "ss_builder_solid_type_label": "Nét liền",
  "ss_builder_something_went_wrong": "Xin lỗi, có lỗi xảy ra",
  "ss_builder_spacer_label": "Khoảng cách (px)",
  "ss_builder_spotlight_list_empty_content": "Để thêm tiêu điểm vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_spotlight_list_empty_title": "Chưa có tiêu điểm nào được thêm",
  "ss_builder_square": "Vuông",
  "ss_builder_square_photo_label": "Ảnh vuông",
  "ss_builder_start_use_passio_page": "Bắt đầu sử dụng Passio Page ngay nào!",
  "ss_builder_stop_label": "Dừng",
  "ss_builder_story": "Spotlight",
  "ss_builder_story_title_place_holder": "Tiêu đề Spotlight",
  "ss_builder_style": "Phong cách",
  "ss_builder_style_always_ghim_tab_label": "Luôn ghim",
  "ss_builder_style_auto_ghim_tab_label": "Tự động ghim",
  "ss_builder_style_ghim_tab_selection_label": "Ghim thành phần",
  "ss_builder_style_grid_label": "Dạng lưới",
  "ss_builder_style_masonry_label": "Dạng lưới xen kẽ",
  "ss_builder_style_not_ghim_tab_label": "Không ghim",
  "ss_builder_style_slide_label": "Dạng trượt",
  "ss_builder_style_tab_icon_label": "Chỉ Icon",
  "ss_builder_style_tab_only_icon": "Chỉ icon",
  "ss_builder_style_tab_only_image": "Chỉ ảnh",
  "ss_builder_style_tab_selection_label": "Phong cách",
  "ss_builder_style_tab_text_and_icon_label": "Chữ & Icon",
  "ss_builder_style_tab_text_and_image_label": "Chữ & ảnh",
  "ss_builder_style_tab_text_label": "Chỉ chữ",
  "ss_builder_subtitle_site_setting_description": "Đưa ra một cái nhìn tổng quan về nội dung và thông tin mà trang web cung cấp. Công cụ tìm kiếm sử dụng điều này để hiển thị mô tả trang web.",
  "ss_builder_subtitle_site_setting_label": "Mô tả trang",
  "ss_builder_suggested_color_panel_label": "Bảng màu gợi ý",
  "ss_builder_support_upload_image": "Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, tối đa 30MB",
  "ss_builder_support_upload_image_v2": "Hỗ trợ: JPG, JPEG, GIF, PNG, BMP tối đa 30MB",
  "ss_builder_support_upload_large_image": "Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, tối đa 50MB",
  "ss_builder_swiper_setting_premium_description_1": "Tính năng không giới hạn",
  "ss_builder_swiper_setting_premium_title_1": "Tài khoản Premium",
  "ss_builder_swiper_setting_premium_title_2": "Kho theme độc quyền",
  "ss_builder_swiper_setting_premium_title_3": "Tên miền riêng yourdomain.com",
  "ss_builder_swiper_setting_premium_title_4": "Chiến dịch thương hiệu độc quyền",
  "ss_builder_swiper_setting_premium_title_5": "Rút tiền nhanh chóng",
  "ss_builder_swiper_setting_premium_title_6": "Tiết kiệm 25% phí nền tảng",
  "ss_builder_tab_layout_1": "Chữ phải",
  "ss_builder_tab_layout_2": "Chữ dưới",
  "ss_builder_tabs_label": "Tabs",
  "ss_builder_template_overwrite_title": "Lưu đè mẫu",
  "ss_builder_template_search_bar_placeholder": "Tìm kiếm mẫu theo tên...",
  "ss_builder_template_store_instruction_subtitle": "Dữ liệu và thiết lập của mẫu được chọn sẽ được áp dụng vào khối hiện tại",
  "ss_builder_template_store_instruction_title": "Hãy chọn mẫu khối bạn muốn!",
  "ss_builder_template_title": "Mẫu",
  "ss_builder_text_editor_max_length": "Nhập tối đa {{editor_max_length}} ký tự",
  "ss_builder_text_spin_loading": "Đang tải trang",
  "ss_builder_the_application_of_the_template_will": "Việc áp dụng mẫu sẽ",
  "ss_builder_theme_big_button_setting": "Nút bấm to",
  "ss_builder_theme_button_setting_general": "Chung",
  "ss_builder_theme_display_setting": "Cài đặt giao diện",
  "ss_builder_theme_medium_button_setting": "Nút bấm vừa",
  "ss_builder_theme_setting_background": "Nền",
  "ss_builder_theme_setting_background_color_preview_title": "Xem trước",
  "ss_builder_theme_setting_background_color_selector_label": "Màu sắc",
  "ss_builder_theme_setting_background_content": "Dung lượng tệp tối đa: 30MB. \nĐịnh dạng hỗ trợ: jpg, jpeg, gif, png, bmp, webp",
  "ss_builder_theme_setting_background_content_v2": "Dung lượng tệp tối đa: 30MB. \nĐịnh dạng hỗ trợ: jpg, jpeg, gif, png, bmp",
  "ss_builder_theme_setting_background_image_preview_label": "Ảnh nền",
  "ss_builder_theme_setting_background_image_selector_label": "Ảnh",
  "ss_builder_theme_setting_background_image_title": "Hình nền",
  "ss_builder_theme_setting_block_background": "Màu nền",
  "ss_builder_theme_setting_block_border": "Đường viền",
  "ss_builder_theme_setting_block_shadow": "Đổ bóng",
  "ss_builder_theme_setting_block_style": "Phong cách",
  "ss_builder_theme_setting_button": "Nút bấm",
  "ss_builder_theme_setting_font_title": "Thiết lập chữ",
  "ss_builder_theme_setting_font_type_content": "Chọn kiểu chữ để áp dụng kiểu chữ lên toàn trang của bạn",
  "ss_builder_theme_setting_font_type_title": "Kiểu chữ",
  "ss_builder_theme_setting_gadient_color": "Màu",
  "ss_builder_theme_setting_general": "Chung",
  "ss_builder_theme_setting_gradient_color_direction_label": "Hướng",
  "ss_builder_theme_setting_hard_shadow": "Đổ bóng cứng",
  "ss_builder_theme_setting_intensity_color_label": "Cường độ màu",
  "ss_builder_theme_setting_label": "Cài đặt giao diện",
  "ss_builder_theme_setting_no_shadow": "Không đổ bóng",
  "ss_builder_theme_setting_paragraph": "Đoạn văn",
  "ss_builder_theme_setting_primary_color": "Màu",
  "ss_builder_theme_setting_primary_color_content": "Chọn màu chủ đạo để áp dụng màu lên toàn trang của bạn",
  "ss_builder_theme_setting_primary_color_title": "Màu chủ đạo",
  "ss_builder_theme_setting_save_failed_content": "Đã xảy ra lỗi không mong muốn. Vui lòng thử lại.",
  "ss_builder_theme_setting_save_failed_title": "Đã xảy ra lỗi",
  "ss_builder_theme_setting_save_successfully_label": "Lưu thiết lập thành công",
  "ss_builder_theme_setting_select_background_color_label": "Chọn kiểu màu",
  "ss_builder_theme_setting_select_gradient_color_label": "Chọn dải màu",
  "ss_builder_theme_setting_select_solid_color_label": "Chọn màu",
  "ss_builder_theme_setting_shadow": "Đổ bóng",
  "ss_builder_theme_setting_shadow_color": "Màu đổ bóng",
  "ss_builder_theme_setting_soft_shadow": "Đổ bóng mềm",
  "ss_builder_theme_setting_text": "Chữ trong nút bấm",
  "ss_builder_theme_setting_title": "Tiêu đề",
  "ss_builder_theme_small_button_setting": "Nút bấm nhỏ",
  "ss_builder_theme_store_label": "Mẫu",
  "ss_builder_theme_store_popover_manual_content": "Để xem hướng dẫn sử dụng màn hình Cửa hàng giao diện vui lòng nhấn nút “Bắt đầu khám phá”",
  "ss_builder_theme_store_popover_manual_title": "Hướng dẫn sử dụng",
  "ss_builder_theme_store_tour_content_step_1": "Tìm kiếm giao diện nhanh chóng theo tên giao diện",
  "ss_builder_theme_store_tour_content_step_2": "Passio Page cung cấp rất nhiều giao diện mẫu cho bạn lựa chọn. Hãy áp dụng một mẫu bạn ưng ý để trải nghiệm nhé.",
  "ss_builder_theme_store_tour_title_step_1": "Tìm kiếm giao diện mẫu",
  "ss_builder_theme_store_tour_title_step_2": "Danh sách gian diện mẫu",
  "ss_builder_theme_template_format_create_label": "Tạo",
  "ss_builder_theme_template_label_tag": "Tags",
  "ss_builder_theme_template_label_tag_placeholder": "Nhập tags",
  "ss_builder_theme_template_no_option": "Không có kết quả",
  "ss_builder_theme_template_preview_info": "Xem trước giao diện trang của bạn sau khi áp dụng mẫu",
  "ss_builder_theme_template_preview_mode_title": "Chế độ xem trước",
  "ss_builder_theme_template_search_bar_placeholder": "Tìm kiếm mẫu giao diện theo tên...",
  "ss_builder_theme_template_store_instruction_subtitle": "Thiết lập giao diện sẽ được áp dụng theo chủ đề lên toàn trang",
  "ss_builder_theme_template_store_instruction_title": "Hãy chọn chủ đề bạn muốn!",
  "ss_builder_theme_template_store_title": "Cửa hàng giao diện",
  "ss_builder_theme_template_theme_store": "{{num_results}} giao diện mẫu",
  "ss_builder_theme_template_view_theme_info": "Giao diện mẫu với dữ liệu mẫu",
  "ss_builder_theme_template_view_theme_title": "Xem mẫu",
  "ss_builder_title": "Tiêu đề",
  "ss_builder_title_block_affiliate_product": "Affiliate",
  "ss_builder_title_block_bio": "Bio",
  "ss_builder_title_block_booking_profile": "Booking Profile",
  "ss_builder_title_block_booking_profile_description": "Thêm đường dẫn tới trang Booking Profile của bạn hoặc hiển thị Booking Profile bên trong Passio Page",
  "ss_builder_title_block_booking_profile_warning": "Tạo Booking Profile để sử dụng khối này",
  "ss_builder_title_block_brand_gift": "Brand Gift",
  "ss_builder_title_block_brand_gift_description": "Hiển thị icon quà tặng từ nhãn hàng giúp fan truy cập mua hàng nhanh chóng",
  "ss_builder_title_block_brand_gift_footer": "Brand Gift Icon",
  "ss_builder_title_block_brand_rating": "Brand Ratings",
  "ss_builder_title_block_brand_rating_description": "Bật tính năng để tăng tương tác với Fan",
  "ss_builder_title_block_button_link": "Liên kết",
  "ss_builder_title_block_button_multi_link": "Multi Links",
  "ss_builder_title_block_card": "Thẻ nội dung",
  "ss_builder_title_block_digital_product": "Sản phẩm số",
  "ss_builder_title_block_donation": "Ủng hộ",
  "ss_builder_title_block_embed": "Embed",
  "ss_builder_title_block_image": "Image",
  "ss_builder_title_block_image_gallery": "Image Gallery",
  "ss_builder_title_block_image_popup": "Ảnh popup",
  "ss_builder_title_block_image_slider": "Image Slider",
  "ss_builder_title_block_story": "Tiêu điểm",
  "ss_builder_title_block_tab_bar": "Tab Menu",
  "ss_builder_title_block_text": "Văn bản",
  "ss_builder_title_block_truth_or_dare": "Truth Or Dare",
  "ss_builder_title_block_truth_or_dare_footer": "Truth Or Dare Icon",
  "ss_builder_title_block_video_shoutout": "Video Shoutout",
  "ss_builder_title_block_video_shoutout_description": "Video quà tặng từ thần tượng của bạn",
  "ss_builder_title_block_virtual_gift": "Virtual Gift",
  "ss_builder_title_block_virtual_gift_footer": "Virtual Gift Icon",
  "ss_builder_title_brand_gift_footer": "Brand Gift Icon",
  "ss_builder_title_color": "Màu chữ",
  "ss_builder_title_combined": "Khối kết hợp",
  "ss_builder_title_introduction_service": "Thông tin dịch vụ",
  "ss_builder_title_page_builder": "Passio Page Builder",
  "ss_builder_title_setting_title": "Thiết lập tiêu đề",
  "ss_builder_title_spacer_and_divider": "Spacer & Divider",
  "ss_builder_to_add_products_please_press_the_add_button_above": "Để thêm sản phẩm vui lòng nhấn nút “Thêm” ở phía trên",
  "ss_builder_to_new_version": "Đi đến Passio Page",
  "ss_builder_top_position_tab_label": "Trên cùng",
  "ss_builder_transparent": "Độ trong suốt",
  "ss_builder_truth_or_dare_warning": "Chỉ được thêm một khối này trên trang",
  "ss_builder_try_another_keyword_theme_store": "Không có giao diện mẫu nào phù hợp với từ khóa của bạn. Hãy thử một từ khóa khác.",
  "ss_builder_try_new_key": "Hãy thử với từ khóa khác",
  "ss_builder_type_label": "Kiểu",
  "ss_builder_type_of_color": "Kiểu màu",
  "ss_builder_undo_theme_label": "Hoàn tác",
  "ss_builder_update_account": "Nâng cấp tài khoản",
  "ss_builder_upload_button_label": "Tải ảnh lên",
  "ss_builder_upload_file_format_invalid_content": "Ảnh không đúng định dạng. Vui lòng chọn một trong các định dạng: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_file_format_invalid_content_for_gif_png_svg": "Ảnh không đúng định dạng. Vui lòng chọn một trong các định dạng: GIF, PNG, SVG",
  "ss_builder_upload_file_format_invalid_content_v2": "Ảnh không đúng định dạng. Vui lòng chọn một trong các định dạng: JPG, JPEG, GIF, PNG, BMP",
  "ss_builder_upload_file_size_invalid_content": "Vui lòng chọn file có dung lượng tối đa 30MB",
  "ss_builder_upload_file_size_invalid_content_min_256": "Vui lòng chọn ảnh có kích thước tối thiểu 256x256px",
  "ss_builder_upload_large_file_format_invalid_content": "Ảnh không đúng định dạng. Vui lòng chọn một trong các định dạng: JPG, JPEG, GIF, PNG, BMP, WebP",
  "ss_builder_upload_large_file_size_invalid_content": "Vui lòng chọn file có dung lượng tối đa 50MB",
  "ss_builder_uploading_image": "Đang tải ảnh",
  "ss_builder_url_error_content": "Không thể lấy thông tin. Vui lòng kiểm tra lại đường dẫn.",
  "ss_builder_url_placeholder": "Nhập đường dẫn liên kết",
  "ss_builder_use_advanced_setting_description": "Bật thiết lập nâng cao để tùy chỉnh cài đặt hiển thị riêng cho Khối kết hợp này",
  "ss_builder_use_affiliate_link_description": "Tích chọn để sử dụng Link Affiliate của Ecomobi Passio. Bỏ tick chọn để sử dụng link của bạn.",
  "ss_builder_use_affiliate_link_label": "Sử dụng tracking link của Ecomobi Passio",
  "ss_builder_use_ecomobi_tracking_link": "Sử dụng tracking link của Ecomobi Passio",
  "ss_builder_validate_gen_link_error_E1": "Token không hợp lệ",
  "ss_builder_validate_gen_link_error_E2": "Tài khoản của bạn đã bị khóa, bạn sẽ không nhận được hoa hồng.",
  "ss_builder_validate_gen_link_error_E3": "Liên kết này không được hỗ trợ affiliate, bạn sẽ không nhận được hoa hồng.",
  "ss_builder_validate_gen_link_error_E5": "Liên kết không nhận được hoa hồng do chiến dịch chưa được duyệt. Bạn cần đăng ký tham gia chiến dịch.",
  "ss_builder_validate_gen_link_error_E6": "Liên kết không nhận được hoa hồng do bạn đã bị chặn khỏi chiến dịch này.",
  "ss_builder_validate_gen_link_error_E7": "Không tạo được liên kết rút gọn do có lỗi từ hệ thống.",
  "ss_builder_validate_gen_link_error_E8": "Liên kết không nhận được hoa hồng do chiến dịch đã kết thúc.",
  "ss_builder_video_shoutout_answer_method_description": "Nếu chấp nhận yêu cầu của Fan Creator cần trả lời lại bằng một video",
  "ss_builder_video_shoutout_answer_method_title": "Phương thức trả lời",
  "ss_builder_video_shoutout_btn_setup": "Thiết lập ngay",
  "ss_builder_video_shoutout_des_intro_fan": "Bạn có 7 ngày để quyết định thực hiện hoặc từ chối yêu cầu của fan. Nếu bạn từ chối không thực hiện thì Ecomobi sẽ hoàn lại toàn bộ số tiền đã thanh toán tới người yêu cầu trong vòng 30 ngày.",
  "ss_builder_video_shoutout_des_intro_gift": "Thông qua Passio, fan của bạn có thể gửi tới bạn những yêu cầu thực hiện video cho các dịp đặc biệt : sinh nhật, chúc mừng, ngày kỷ niệm, video động viên, truyền cảm hứng... với một khoản phí nhất định do chính bạn đề ra.",
  "ss_builder_video_shoutout_des_intro_policy": "Bạn và fan có thể chia sẻ những video ý nghĩa này với mọi người thông qua các trang mạng xã hội.",
  "ss_builder_video_shoutout_how_to_work": "Video ShoutOut hoạt động như thế nào?",
  "ss_builder_video_shoutout_introduction": "Video ShoutOut là dịch vụ cho phép Fan gửi tới Creator những yêu cầu về một video cá nhân hóa cho các dịp cụ thể như: Sinh nhật, tốt nghiệp, chúc mừng,... Creator sẽ phản hồi lại các yêu cầu đó bằng một video",
  "ss_builder_video_shoutout_participate_description": "Creator có thể chọn thực hiện hoặc từ chối yêu cầu của Fan",
  "ss_builder_video_shoutout_participate_title": "Quyền tham gia ở bạn",
  "ss_builder_video_shoutout_response_description": "Sau khi Fan gửi yêu cầu, Creator có 7 ngày để thực hiện Video. Sau thời gian này, số tiền của yêu cầu đó sẽ được hoàn trả lại Fan",
  "ss_builder_video_shoutout_response_title": "Thời gian phản hồi",
  "ss_builder_video_shoutout_title_intro": "Giới thiệu dịch vụ",
  "ss_builder_video_shoutout_title_intro_fan": "Thực hiện Video",
  "ss_builder_video_shoutout_title_intro_gift": "Nhận yêu cầu từ fan",
  "ss_builder_video_shoutout_title_intro_policy": "Chia sẻ những khoảnh khắc ý nghĩa.",
  "ss_builder_virtual_gift_list_title": "Danh sách quà tặng",
  "ss_builder_wait_for_saving": "Quá trình lưu lại thiết lập chưa hoàn tất. Vui lòng đợi trong giây lát.",
  "ss_builder_warning_brand_gift_floating_continue": "Tiếp tục",
  "ss_builder_warning_brand_gift_floating_description": "Hiện tại vị trí floating icon của bạn đã hết. Bạn cần phải xóa bớt block trong nhóm floating icon ở vị trí mở rộng. Xem",
  "ss_builder_warning_brand_gift_floating_description_link": "hướng dẫn chi tiết",
  "ss_builder_warning_brand_gift_floating_reject": "Từ chối",
  "ss_builder_warning_brand_gift_floating_title": "Floating icon đã hết vị trí trống",
  "ss_builder_warning_brand_gift_rating_description": "Hiện tại thân trang đã đạt tới giới hạn hiển thị. Bạn cần xoá bớt khối để bật tính năng Brand Ratings",
  "ss_builder_warning_brand_rating_floating_continue": "Đã hiểu",
  "ss_builder_warning_brand_rating_floating_title": "Brand Ratings",
  "ss_builder_web_browser": "Trình duyệt web",
  "ss_builder_web_label": "Ss Builder Web Label",
  "ss_builder_website_config_site_title_description": "Luôn hiển thị ngay trên đầu trang khi fan truy cập.",
  "ss_builder_website_config_site_title_label": "Tên trang",
  "ss_builder_website_config_site_title_placeholder": "Nhập nội dung",
  "ss_builder_what_field_are_you_operating_in": "Bạn đang hoạt động trong lĩnh vực nào?",
  "ss_builder_what_went_wrong": "Nội dung báo lỗi",
  "ss_builder_width_tab_setting_label": "Độ rộng Tab",
  "ss_builder_width_tab_type_1": "Tự động",
  "ss_builder_width_tab_type_2": "Chia đều",
  "ss_builder_your_page_is_ready": "Trang cá nhân của bạn đã sẵn sàng!",
  "ss_cancle_delete": "Huỷ bỏ",
  "ss_challenge": "Thách thức",
  "ss_confirm_delete": "Xác nhận xoá",
  "ss_content_confirm_delete": "Bạn có chắc muốn xóa sản phẩm khỏi danh sách này?",
  "ss_content_delete_forever": "Để xóa sản phẩm vui lòng quay về trang quản trị sản phẩm",
  "ss_content_intro_dp": "Sản phẩm số được hiểu là những sản phẩm trực tuyến, không cầm nắm được, chứa nội dung hữu ích cho người sử dụng. Ví dụ: Video, Hình ảnh, Ebook, Khóa học online,..",
  "ss_content_item1_dp": "Tùy thuộc vào thế mạnh và mong muốn của bạn, Passio cung cấp đầy đủ các loại sản phẩm số như: Ảnh, Video, Khóa học online, Ebook, Sản phẩm dạng link,...",
  "ss_content_item2_dp": "Chỉ với chưa đến 3 phút, bạn đã có thể cấu hình và đăng bán một sản phẩm số mới. Hơn nữa, sau khi người mua thanh thoán thành công, sản phẩm sẽ được gửi ngay tới email của họ, giúp đơn giản hóa việc vận chuyển và tiết kiệm thời gian",
  "ss_content_success_TOD": "Bạn đã cài đặt tính năng Truth or Dare thành công.",
  "ss_cover_image": "Ảnh bìa",
  "ss_cover_image_empty": "Chưa có ảnh nào được chọn",
  "ss_cover_image_helper": "Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, tối đa 4MB",
  "ss_cover_image_upload": "Tải ảnh lên",
  "ss_delete_forever": "Xóa vĩnh viễn",
  "ss_delete_product": "Xoá hiển thị",
  "ss_display_delete": "Xoá hiển thị",
  "ss_donation_currency_shortcut": "₫",
  "ss_donation_setting_default_content_setup_donation": "Dịch vụ đang được cài đặt mặc định. Nếu bạn muốn thay đổi, vui lòng truy cập dịch vụ Ủng hộ tại account.passio.eco",
  "ss_donation_setting_default_title_setup_donation": "Thiết lập dịch vụ",
  "ss_dp_add": "Thêm",
  "ss_dp_cat_course": "Khóa học/ Tutorial",
  "ss_dp_cat_ebook_document": "Ebook/ Tài liệu",
  "ss_dp_cat_online_consultion": "Tư vấn online 1-1",
  "ss_dp_cat_random_product": "Sản phẩm bất kỳ",
  "ss_dp_cat_ticket_account": "Vé/ Tài khoản",
  "ss_dp_cat_video": "Video độc quyền",
  "ss_dp_sell_photo": "Ảnh",
  "ss_edit_TOD": "Chỉnh sửa",
  "ss_example_TOD": "Ví dụ: 100,000",
  "ss_exit_TOD": "Thoát",
  "ss_first_person": "Trở thành người đầu tiên thử thách ngay nào!",
  "ss_gift_button_preview": "Gửi quà liền tay!",
  "ss_gift_invitation": "Tặng quà ngay cho {user} để mình có thêm nhiều động lực nhé!",
  "ss_had": "Đã có",
  "ss_iframe_add_new_product": "Thêm sản phẩm mới",
  "ss_iframe_add_product": "Thêm sản phẩm",
  "ss_iframe_choose_product_from_store": "Chọn từ kho dữ liệu",
  "ss_inline_image_empty": "Trường này không được để trống",
  "ss_inline_txtbtn_max_length": "Trường này vượt quá 40 ký tự.",
  "ss_input_placehoder": "Nhập nội dung",
  "ss_invitation_TOD": "Nội dung mời chào thực hiện thử thách",
  "ss_invitation_TOD_placeholder": "Nhập nội dung mời fan tham gia",
  "ss_logout": "Đăng xuất",
  "ss_modal_cancel_confirm": "Hủy bỏ",
  "ss_modal_text_confirm": "Đi đến trang quản trị",
  "ss_no": "Không",
  "ss_placeholder_message_TOD": "Nhập nội dung thách thức",
  "ss_preview_TOD": "Tiếp tục",
  "ss_profile": "Hồ sơ",
  "ss_push_TOD": "Đăng tải",
  "ss_rount_tod": "Lượt thử thách",
  "ss_service_introduction": "Giới thiệu dịch vụ",
  "ss_setting_TOD_background_tooltip": "Chọn ảnh nền tỷ lệ 3:2 với dung lượng tối đa 4 MB",
  "ss_setting_TOD_field_image_background": "Thêm hình nền",
  "ss_setting_TOD_field_image_background_bottom": "Hình nền",
  "ss_setting_TOD_field_welcome_tooltip": "Nội dung Lời kêu gọi fan thử thách sẽ tự động điền nếu bạn bỏ trống.",
  "ss_setup_now": "Thiết lập ngay",
  "ss_shoutout_btn_content": "Nội dung nút",
  "ss_shoutout_change_file": "Đổi ảnh/video",
  "ss_shoutout_create_request": "Tạo yêu cầu ngay",
  "ss_shoutout_description": "Mô tả",
  "ss_shoutout_description_placeholder": "Nhập vào nội dung giới thiệu",
  "ss_shoutout_error_price_required": "Vui lòng nhập vào giá tiền",
  "ss_shoutout_error_upload": "Có lỗi xảy ra, tải lên thất bại",
  "ss_shoutout_error_video_size": "Vui lòng tải lên hình ảnh/ video tối đa 500MB",
  "ss_shoutout_error_video_type": "Vui lòng tải lên file là một trong các định dạng JPG, JPEG, GIF, PNG, BMP, WebP, HEIF, HEIC, MP4, MOV",
  "ss_shoutout_file_intro": "Ảnh/video giới thiệu",
  "ss_shoutout_file_intro_note": "Tải lên hình ảnh hoặc video để kêu gọi Fan sử dụng dịch vụ Video Shoutout từ bạn",
  "ss_shoutout_no_file_selected": "Chưa có ảnh/video nào được chọn",
  "ss_shoutout_price": "Giá tiền",
  "ss_shoutout_price_placeholder": "Nhập vào giá tiền",
  "ss_shoutout_select_file": "Chọn ảnh/video",
  "ss_shoutout_type_support": "Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, HEIF, HEIC, MP4, MOV tối đa 500MB",
  "ss_shoutout_upload_success": "Tải video lên thành công",
  "ss_success_TOD": "Thành công",
  "ss_success_off_TOD": "Bạn đã tắt tính năng Truth or Dare thành công.",
  "ss_success_on_TOD": "Bạn đã bật tính năng Truth or Dare thành công.",
  "ss_success_setup_TOD": "Bạn đã cài đặt tính năng Truth or Dare thành công.",
  "ss_title_intro_dp": "Sản phẩm số là gì",
  "ss_title_item1_dp": "Đa dạng các loại sản phẩm cho bạn lựa chọn",
  "ss_title_item2_dp": "Dễ dàng quản lý sản phẩm và đơn hàng bằng một vài thao tác",
  "ss_tod_challenge_number": "Số thử thách",
  "ss_tod_content": "Nội dung mời chào",
  "ss_tod_dare_content": "Lời kêu gọi fan gửi thử thách",
  "ss_tod_dare_fee": "Số tiền tối thiểu fan thanh toán đơn Dare",
  "ss_tod_default_content": "Thử luôn sợ gì!",
  "ss_tod_default_dare_content": "Cao thủ có gặp thách đấu? Thử thách thức mình xem!",
  "ss_tod_min_price_dare_alias": "Số tiền tối thiểu fan thanh toán đơn Dare",
  "ss_tod_min_price_dare_note": "Thanh toán tối thiểu là 1,000 ₫",
  "ss_tod_setting_cover_image": "Ảnh bìa",
  "ss_tod_setting_cover_image_helper": "Hỗ trợ: JPG, JPEG, GIF, PNG, BMP, WebP, tối đa 4MB",
  "ss_tod_setting_field_welcome_tooltip": "Nội dung Lời kêu gọi fan thử thách sẽ tự động điền nếu bạn bỏ trống.",
  "ss_tod_setting_input_placehoder": "Nhập nội dung",
  "ss_tod_tooltip_content": "Nội dung lời mời chào giới hạn tối đa 60 ký tự.",
  "ss_tod_tooltip_min_price_dare": "Nội dung lời mời chào tối đa 60 ký tự.",
  "ss_txt_btn_TOD": "Lời kêu gọi fan gửi thử thách",
  "ss_validate_domain_error_E1": "Tên miền đã được đổi trong vòng 30 ngày, không thể đổi",
  "ss_validate_domain_error_E2": "Tên miền đã tồn tại, xin vui lòng thử tên khác",
  "ss_validate_domain_error_E3": "Tên miền này bị cấm",
  "ss_validate_origin_url_error_E203": "Không thể tìm thấy tên miền này. Vui lòng kiểm tra và thử lại.",
  "ss_validate_origin_url_error_E5": "Bạn chưa được phê duyệt chiến dịch này, vui lòng yêu cầu phê duyệt chiến dịch",
  "ss_validate_origin_url_error_E6": "Bạn không được tham gia chiến dịch này, vui lòng kiểm tra lại với nhân viên hỗ trợ",
  "ss_validate_origin_url_error_E7": "Hệ thống không hỗ trợ URL này",
  "ss_validate_origin_url_error_E9": "Chiến dịch đã kết thúc, vui lòng chọn URL khác",
  "ss_validate_origin_url_error_V0": "Url không hợp lệ",
  "ss_validate_origin_url_error_sys": "Vui lòng nhập một URL hợp lệ",
  "ss_validate_origin_url_warning_E5": "Bạn chưa được phê duyệt chiến dịch này. Order và Commission sẽ không được ghi nhận.",
  "ss_validate_origin_url_warning_E7": "Liên kết không được hỗ trợ. Order và Commission sẽ không được ghi nhận.",
  "ss_validate_origin_url_warning_E9": "Chiến dịch này đã kết thúc. Order và Commission sẽ không được ghi nhận.",
  "text_cashback_brand_gift": "Hoàn tiền",
  "text_custom_brand_gift": "Cá nhân hóa",
  "text_voucher_brand_gift": "Voucher",
  "undefined": "Undefined",
  "voucher_default_text_button": "Nhận voucher ngay",
  "voucher_default_title": "Chạm lẹ thanh toán, hoàn tiền 5%",
  "warning_text_TOD": "Cài đặt của bạn chưa được lưu. Bạn vẫn muốn thoát chứ?",
  "warning_text_switch_TOD": "Bạn có muốn tắt tính năng Truth or Dare không?"
} 
} 
export default vi