import { useAppDispatch } from "@app/hooks";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import { QuestionIcon } from "@share/icons";
import React, { useEffect, useState } from "react";
import ProductAddNewTour from "./ProductAddNewTour";

const ProductAddNewTourOpening = ({ isHideStep3 }) => {
  const [isOpenProductAddNewTour, setIsOpenProductAddNewTour] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setDynamicParams({
        data: { isOpenAffiliateAddNewTour: isOpenProductAddNewTour },
      })
    );
    const timer = setTimeout(() => {
      const settingTourOverlay = document.getElementsByClassName(
        "introjs-overlay"
      )[0] as any;
      const affiliateAddNewWrapper = document.querySelector(
        ".affiliate-add-new-wrapper"
      ) as any;
      if (isOpenProductAddNewTour) {
        settingTourOverlay.style.display = "none";
      } else {
        settingTourOverlay?.remove();
      }
      if (isOpenProductAddNewTour) {
        affiliateAddNewWrapper.style.cssText = "pointer-events: none;";
      } else {
        affiliateAddNewWrapper.style.cssText = "";
      }
    }, 100);
    () => clearTimeout(timer);
  }, [isOpenProductAddNewTour]);

  const handleHideAddNewTour = () => {
    setIsOpenProductAddNewTour(false);
  };

  return (
    <>
      <span onClick={() => setIsOpenProductAddNewTour(true)}>
        <QuestionIcon
          width={20}
          height={20}
          className="cursor-pointer"
        />
      </span>
      {isOpenProductAddNewTour && (
        <ProductAddNewTour
          isHideStep3={isHideStep3}
          handleHideAddNewTour={handleHideAddNewTour}
        />
      )}
    </>
  );
};

export default ProductAddNewTourOpening;
