import { POSITION_TYPE } from "@features/block/config/interface";
import IconRender from "@share/IconRender";
import { EyeVisibleIcon, HomeIcon } from "@share/icons";
import { Button, Divider, Selector } from "antd-mobile";
import cls from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

const boxStyle = {
  "--border-color": "#eee",
  "--background-color": "#fff",
  "--text-color": "#333333",
};

export interface SingleBox {
  id?: string;
  count: number;
  max: number;
  disabled: boolean;
  message: string;
  title: string;
  enable: boolean;
  hidden: boolean;
}

export interface MultiBox {
  id: string;
  title: string;
  listBox: Array<SingleBox & { active: boolean; icon: string }>;
}

export interface SingleBoxComponentProps {
  item: SingleBox;
  onSelect: (slug?: string) => void;
  selected?: {
    position: POSITION_TYPE;
    uid: string;
  };
}

export interface MultiBoxComponentProps {
  item: MultiBox;
  onSelect: (slug?: string, id?: string) => void;
  selected?: {
    position: POSITION_TYPE;
    uid: string;
  };
}

const SingleBoxComponent: React.FunctionComponent<SingleBoxComponentProps> = (
  props
) => {
  const { t } = useTranslation();
  const { onSelect, item, selected } = props;
  const getTitle = useMemo(() => {
    const id = item && item.id;
    switch (id) {
      case POSITION_TYPE.HEADER:
        return t("ss_builder_header_title");
      case POSITION_TYPE.FOOTER:
        return t("ss_builder_footer_title");
      case POSITION_TYPE.EXPAND:
        return t("ss_build_expand_block_label");
      case POSITION_TYPE.BODY:
        return t("ss_builder_body_title");
      default:
      // return t("ss_builder_body_title");
      // to do
    }
  }, [item]);
  if (item?.hidden) return <></>;
  return (
    <>
      <Divider
        contentPosition="left"
        className={cls(
          "divider-design divider-design-popup adm-default",
          item.id === POSITION_TYPE.EXPAND && "expand-position-title"
        )}
        style={{ margin: "0px" }}
      >
        <div className="flex justify-center items-center font-normal">
          {getTitle}
        </div>
      </Divider>
      <div
        onClick={() => !item.disabled && onSelect(item?.id)}
        className={cls(
          "p-[10px] bg-[#F5F5F5] mb-3 rounded-[2px]",
          item.disabled ? "cursor-not-allowed" : "cursor-pointer "
        )}
      >
        <Selector
          className="w-full"
          style={{
            "--checked-border": "solid var(--adm-color-primary) 1px",
            "--color": "#FFFFFF",
          }}
          options={[
            {
              label: (
                <>
                  {item.title} ({`${item.count}/${item.max}`})
                  {item.disabled && (
                    <div className={cls("text-[#FF3141] text-[13px]")}>
                      {item.message}
                    </div>
                  )}
                </>
              ),
              value: item.id,
              disabled: item.disabled,
            },
          ]}
          onChange={(val) => onSelect(val[0])}
          value={[selected?.position]}
        />
      </div>
    </>
  );
};

const MultiBoxComponent: React.FunctionComponent<MultiBoxComponentProps> = (
  props
) => {
  const { t } = useTranslation();
  const { onSelect, item, selected } = props;
  const style = {
    maxWidth: "calc(100% - 20px)",
  };
  return (
    <>
      <Divider
        contentPosition="left"
        className="divider-design divider-design-popup adm-default"
        style={{ margin: "0px" }}
      >
        <div className="flex justify-center items-center font-normal">
          {t("ss_builder_body_title")}
        </div>
      </Divider>
      <div className="p-[10px] pb-[2px] mb-3 bg-[#F5F5F5] rounded-[2px] text-[15px] text-[#999] selector-body">
        {item.listBox.map((ele, index) => {
          if (ele?.hidden) return <></>;
          return (
            <div
              className="mb-2"
              key={ele.id || index}
            >
              <Selector
                className="w-full"
                style={{
                  "--checked-border": "solid var(--adm-color-primary) 1px",
                  "--color": "#FFFFFF",
                }}
                options={[
                  {
                    label: (
                      <>
                        <div className="flex justify-between items-center">
                          <div
                            className="flex items-center"
                            style={style}
                          >
                            <div className="mr-2 h-[18px] flex items-center">
                              <IconRender iconName={ele.icon} />
                            </div>
                            <div className="text-ellipsis whitespace-nowrap overflow-hidden max-w-[80%] pr-1">
                              {ele.title}
                            </div>
                            ({ele.count}/{ele.max})
                          </div>
                          <div className="w-[20px]">
                            {ele.active && (
                              <HomeIcon
                                width={16}
                                height={16}
                              />
                            )}
                            {ele.enable && (
                              <EyeVisibleIcon
                                fillColor="#999"
                                width={16}
                                height={16}
                              />
                            )}
                          </div>
                        </div>
                        {ele.disabled && (
                          <div className="text-[#FF3141] text-[13px] text-left">
                            {ele.message}
                          </div>
                        )}
                      </>
                    ),
                    value: ele.id,
                    disabled: ele.disabled,
                  },
                ]}
                onChange={(val) => !ele.disabled && onSelect(item?.id, val[0])}
                value={[selected?.uid]}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export const Box = {
  SingleBox: SingleBoxComponent,
  MultiBox: MultiBoxComponent,
};
