import { AffiliateProductInterface } from "@features/main/store/interface";
import { genLink } from "@features/user/userApi";
import { requestDetectProductUrl } from "@share/api";
import FullScreenPopup from "@share/components/full-screen-popup";
import {
  MAX_PRODUCTS_ALL_GROUPS,
  PRODUCT_URL_CHECKING,
  PRODUCT_MAX,
  STOP_DETECTING,
} from "@share/configs/const";
import {
  ExclamationTriangleIcon,
  InformationIcon,
  InformationTriangleIcon,
  RightArrowIcon,
  CheckCircleIconV3,
} from "@share/icons";
import { formatDetectedUrls, validURL } from "@share/lib";
import { Badge, Button, Modal, NoticeBar, SpinLoading } from "antd-mobile";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AffiliateProductGroupPickerView from "../AffiliateProductGroupPickerView";
import ProductItem from "../ProductItem";
import AutoCleanupProductsWarning from "./AutoCleanupProductsWarning";
import ProductAddNewInput from "./ProductAddNewInput";
import ProductGroupSelection from "./ProductGroupSelection";
import DetectedUrlLists from "./DetectedUrlLists/index";
import cls from "classnames";
import ProductAddNewTourOpening from "./ProductAddNewTourOpening";
import { AFFILIATE_ADD_NEW_TOUR_CLASS_NAME } from "./ProductAddNewTourOpening/affiliate-add-new-tour-config";
import { checkOpenNewTab } from "@share/helper/validator";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { scrollToNewItem } from "@features/block/libs";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import {
  compareSameEcomerceUrl,
  findIndexDuplicateLinks,
} from "../ProductUploadFile/helper";
import { PASSIO_DEFAULT_IMAGE } from "@share/configs/image";

type Props = {
  callbackCancelAffiliateProductAddNew: any;
  callbackSaveAffiliateProductAddNew: any;
  affiliateProductGroupData: AffiliateProductInterface[];
  activeProductGroup: string;
  callbackAddNewAffiliateProductGroup: any;
  productGroupMaximum: number;
};

const AffiliateProductAddNew = (props: Props) => {
  const {
    callbackCancelAffiliateProductAddNew,
    callbackSaveAffiliateProductAddNew,
    affiliateProductGroupData,
    activeProductGroup,
    callbackAddNewAffiliateProductGroup,
    productGroupMaximum,
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isOpenAffiliateAddNewTour = useAppSelector(
    (state) => state.dynamicConfig.isOpenAffiliateAddNewTour
  );

  const isStopDetectingUrl = useRef(false);

  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isCheckQuantityProducts, setIsCheckQuantityProductts] =
    useState(false);
  const [isOpenProductGroupSelection, setIsOpenProductGroupSelection] =
    useState(false);
  const [isOpenDetectedUrlLists, setIsOpenDetectedUrlLists] = useState(false);
  const [listProducts, setListProducts] = useState([]);
  const [detectProductUrl, setDetectProductUrl] = useState("");
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [productResponseArrDefault, setProductResponseArrDefault] = useState(
    []
  );
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);
  const [productIdValidArr, setProductIdValidArr] = useState([]);
  const [productGroupValue, setProductGroupValue] =
    useState(activeProductGroup);
  const [detectedUrlLists, setDetectedUrlLists] = useState([]);
  const [detectedUrlQuantity, setDetectedUrlQuantity] = useState(0);
  const successfulDetectedProductArr = useRef([]);

  useEffect(() => {
    if (activeProductGroup) {
      setProductGroupValue(activeProductGroup);
    }
  }, [activeProductGroup]);

  const activedTabProductLists = useMemo(() => {
    const activedProductGroup = affiliateProductGroupData?.find(
      (x) => x.uid === productGroupValue
    );
    return activedProductGroup?.content_attributes?.items ?? [];
  }, [affiliateProductGroupData, productGroupValue]);

  const listProductsDisplay = useMemo(() => {
    const productIdArr = listProducts?.map((x) => x?.id);
    const arrIndex = productIdArr
      .map((item, index) => {
        if (productIdArr.indexOf(item) != index) return index;
      })
      ?.filter((x) => typeof x === "number");
    return listProducts?.filter((ele, idx) => !arrIndex?.includes(idx));
  }, [listProducts]);

  const existedProductLists = useMemo(() => {
    let existedProductArr = [];
    affiliateProductGroupData.forEach((x) => {
      const affiliateProductArr = x?.content_attributes?.items || [];
      existedProductArr = [...existedProductArr, ...affiliateProductArr];
    });
    return existedProductArr;
  }, [affiliateProductGroupData]);

  const handleDetectInfomationProduct = useCallback(
    (productId, url) => {
      let productInfomation = null;
      for (const product of affiliateProductGroupData) {
        const listProducts = product?.content_attributes?.items ?? [];
        for (const item of listProducts) {
          if (
            String(item.uid) === String(productId) ||
            compareSameEcomerceUrl(item?.origin_link, url)
          ) {
            productInfomation = {
              productGroupName:
                product?.title ||
                product?.content_attributes?.title_product_group,
              productId: productId,
            };
          }
        }
      }
      return productInfomation;
    },
    [affiliateProductGroupData]
  );

  const checkValidUrl = (url) => validURL(url);

  const detectAffiliateProductUrl = async (url, isDuplicated, isMultiUrls) => {
    const existedProductUrlArrs = existedProductLists?.map(
      (x) => x?.origin_link
    );
    const listProductUrlArrs = successfulDetectedProductArr.current?.map(
      (x) => x?.origin_link
    );
    const isCheckUrl = checkValidUrl(url);
    const checkQuantityProducts = !!(
      activedTabProductLists?.filter((x) => x?.is_cleanup_lock === 1)?.length +
        successfulDetectedProductArr.current?.length >=
      PRODUCT_MAX
    );
    let response = null;
    try {
      if (!isCheckUrl && isMultiUrls) {
        return PRODUCT_URL_CHECKING.INVALID;
      } else if (
        isMultiUrls &&
        (isDuplicated ||
          existedProductUrlArrs?.some((x) => compareSameEcomerceUrl(x, url)) ||
          listProductUrlArrs?.some((x) => compareSameEcomerceUrl(x, url)))
      ) {
        return PRODUCT_URL_CHECKING.DUPLICATE;
      } else {
        if (
          checkQuantityProducts ||
          (!productGroupValue &&
            successfulDetectedProductArr.current?.length >= PRODUCT_MAX)
        ) {
          setIsCheckQuantityProductts(true);
          setIsLoadingBtn(false);
          setDetectProductUrl("");
          return STOP_DETECTING;
        }
        //Xử lý khi api chạy đến 10s mà chưa trả về kết quả sẽ tự động bỏ qua, không ghi nhận kết quả api đó nữa
        const timeoutPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(null);
          }, 30000);
        });
        //Nếu trong 10s mà trả về response thì sẽ lấy response đó, ngược lại sẽ trả về null
        response = await Promise.race([
          timeoutPromise,
          requestDetectProductUrl(url),
        ]);
      }
    } catch (error) {
      console.log("Request error", error);
    }
    if (
      response &&
      /^20[0-9]$/.test(response?.status?.toString()) &&
      response?.data?.data
    ) {
      const isSuccess = response?.data?.data?.success;
      const productDetectData = {
        ...response?.data?.data?.product,
        cat_id: response?.data?.data?.cat_id,
        image: response?.data?.data?.product?.image || PASSIO_DEFAULT_IMAGE,
      };

      const isCheckExistProductAddNew =
        Array.isArray(listProductsDisplay) &&
        listProductUrlArrs?.length > 0 &&
        listProductsDisplay.some(
          (x) =>
            String(x.id) === String(productDetectData.id) ||
            compareSameEcomerceUrl(x?.origin_link, url.trim())
        );
      const isCheckExistProductSelection =
        Array.isArray(existedProductLists) &&
        existedProductLists?.length > 0 &&
        existedProductLists?.some(
          (x) =>
            String(x.uid) === String(productDetectData.id) ||
            compareSameEcomerceUrl(x?.origin_link, url.trim())
        );
      const isDuplicatedWhenDetectingMultiUrl =
        isMultiUrls &&
        (successfulDetectedProductArr.current
          ?.map((x) => x?.id)
          ?.includes(productDetectData?.id) ||
          isCheckExistProductAddNew ||
          isCheckExistProductSelection);
      if (isDuplicatedWhenDetectingMultiUrl) {
        return PRODUCT_URL_CHECKING.DUPLICATE;
      }
      successfulDetectedProductArr.current?.push(productDetectData);
      const existProductInformation = handleDetectInfomationProduct(
        productDetectData.id,
        url?.trim()
      );
      let shorternLink = null;
      try {
        shorternLink = await genLink(url, {
          sub1: "ig",
          sub2: "true",
          traffic_source: "ss-landing-copy",
        });
      } catch (e) {
        console.log(e);
      }
      if (
        !isMultiUrls &&
        (isCheckExistProductSelection || isCheckExistProductAddNew)
      ) {
        Modal.alert({
          header: (
            <InformationIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          ),
          content: (
            <div className="flex flex-col text-center items-center">
              <div className="text-lg font-bold leading-[25px]">
                {t("ss_builder_dont_add_same_affiliate_product_modal_label")}
              </div>
              <div className="">
                {isCheckExistProductSelection
                  ? t("ss_builder_dont_add_product_exist_at_list_content", {
                      group_name: existProductInformation?.productGroupName,
                    })
                  : t("ss_builder_dont_add_product_exist_at_add_new_content")}
              </div>
            </div>
          ),
          confirmText: t(
            "ss_builder_dont_add_same_affiliate_product_confirm_label"
          ),
          onConfirm: () => {
            return;
          },
        });
        return true;
      }
      const currentTime = new Date();
      const createdAtTime = currentTime.toISOString();
      setListProducts((value) => [
        {
          ...productDetectData,
          shortern_link: shorternLink,
          use_aff_url: 1,
          origin_link: url,
          warning_code: isSuccess ? response?.data?.data?.warning_code : null,
          error_code: !isSuccess ? response?.data?.error_code : null,
          createdAt: createdAtTime,
        },
        ...value,
      ]);
      setProductResponseArrDefault((data) => [
        {
          ...productDetectData,
        },
        ...data,
      ]);
      return PRODUCT_URL_CHECKING.SUCCESS;
    } else {
      if (isMultiUrls) {
        return PRODUCT_URL_CHECKING.NOT_ADDED_YET;
      } else {
        //Hiển thị modal báo lỗi và cho kết thúc việc phân tích sản phẩm với trường hợp 1 url
        dispatch(
          setDynamicParams({
            data: { isErrorNetwork: true },
          })
        );
        setIsLoadingBtn(false);
        setDetectProductUrl("");
        return STOP_DETECTING;
      }
    }
  };

  const validateDetectProductUrl = useCallback(
    async (valueInput) => {
      isStopDetectingUrl.current = false;
      setDetectedUrlLists([]);
      setIsLoadingBtn(true);
      const isFulledProductsAllGroups = !!(
        existedProductLists?.length + listProductsDisplay?.length + 1 >
        MAX_PRODUCTS_ALL_GROUPS
      );
      if (isFulledProductsAllGroups) {
        Modal.show({
          className: "modal-error-class",
          header: (
            <InformationTriangleIcon
              className="w-12 h-12"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          ),
          content: (
            <div className="flex flex-col text-center items-center">
              <div className="text-lg font-bold leading-[25px] mb-2">
                {t("ss_builder_product_total_limitation_warning_title")}
              </div>
              <div className="">
                {t("ss_builder_product_total_limitation_warning_description", {
                  max_products_all_groups: MAX_PRODUCTS_ALL_GROUPS,
                })}
              </div>
            </div>
          ),
          closeOnAction: true,
          actions: [
            {
              key: "confirm",
              text: t("ss_builder_confirm_replace_theme_template_understood"),
            },
          ],
        });
      } else {
        if (valueInput?.trim()?.length > 0) {
          const formatedUrls = formatDetectedUrls(valueInput?.trim());
          setDetectedUrlQuantity(formatedUrls?.length);
          const duplicatedUrlIndexArr = findIndexDuplicateLinks(formatedUrls);
          const isMultiUrls = formatedUrls?.length > 1;
          let count = 0;
          let res = null;
          // await sleep(1000);
          while (count < formatedUrls?.length) {
            if (isStopDetectingUrl.current) break;
            const url = formatedUrls.slice(count, count + 1);
            const isDuplicated = duplicatedUrlIndexArr?.includes(count);
            if (url[0] && (isMultiUrls || (!isMultiUrls && validURL(url[0])))) {
              try {
                res = await detectAffiliateProductUrl(
                  url[0],
                  isDuplicated,
                  isMultiUrls
                );
              } catch (error) {
                console.log(error);
              }
              if (res) {
                if (res === STOP_DETECTING) break;
                if (isMultiUrls) {
                  setDetectedUrlLists((state) => [
                    ...state,
                    {
                      url: url[0],
                      valid_type: res,
                    },
                  ]);
                }
                count++;
              } else break;
              if (count >= formatedUrls?.length) {
                setIsLoadingBtn(false);
                setDetectProductUrl("");
              }
            } else {
              setIsValidUrl(true);
              setIsLoadingBtn(false);
              break;
            }
          }
          const notYetDetectedUrlLists = formatedUrls
            ?.slice(count, formatedUrls?.length)
            ?.map((ele) => ({
              url: ele,
              valid_type: PRODUCT_URL_CHECKING.NOT_ADDED_YET,
            }));
          setDetectedUrlLists((state) => [...state, ...notYetDetectedUrlLists]);
        } else {
          setIsLoadingBtn(false);
          // setIsValidUrl(true);
        }
      }
    },
    [existedProductLists, activedTabProductLists, listProductsDisplay]
  );

  const handleDeleteProduct = useCallback(
    (productId) => {
      const newListProducts = listProductsDisplay.filter(
        (x) => x.id !== productId
      );
      successfulDetectedProductArr.current = newListProducts;
      const newProductResponseDefault = productResponseArrDefault.filter(
        (x) => x.id !== productId
      );
      setListProducts(newListProducts);
      setProductResponseArrDefault(newProductResponseDefault);
      if (
        activedTabProductLists?.filter((x) => x?.is_cleanup_lock === 1)
          ?.length +
          successfulDetectedProductArr.current?.length >=
        PRODUCT_MAX
      ) {
        setIsCheckQuantityProductts(true);
      } else {
        setIsCheckQuantityProductts(false);
      }
    },
    [listProductsDisplay, activedTabProductLists, productResponseArrDefault]
  );

  const isSaveButtonEnable = useMemo(() => {
    const isCheckHasTitleProduct = listProductsDisplay.every(
      (x) => x.name && x.name.trim()
    );
    // const isCheckHasImageProduct = listProducts.every((x) => x.image);
    if (
      listProductsDisplay.length > 0 &&
      isCheckHasTitleProduct &&
      // isCheckHasImageProduct &&
      productGroupValue &&
      !isLoadingBtn
    ) {
      return true;
    }
    return false;
  }, [listProductsDisplay, productGroupValue, isLoadingBtn]);

  const isExceedMaximumProducts = useMemo(
    () =>
      activedTabProductLists?.length > 0 &&
      listProductsDisplay?.length + activedTabProductLists?.length >=
        PRODUCT_MAX,
    [listProductsDisplay, activedTabProductLists]
  );

  const handleSaveAffiliateProductAddNew = useCallback(() => {
    if (activedTabProductLists && activedTabProductLists.length === 0) {
      const dataConfirm =
        listProductsDisplay &&
        Array.isArray(listProductsDisplay) &&
        listProductsDisplay.map((ele, idx) => ({
          ...ele,
          uid: ele.id || "aff-item-" + uuidv4(),
          sort_order: idx + 1,
          title: ele.name,
          enable: 1,
          is_cleanup_lock: 2,
          image_name: ele.image_name || "",
          image_base64: ele.image_base64,
        }));

      const newAffiliateProductGroupData = affiliateProductGroupData?.map(
        (ele) => {
          return ele.uid !== productGroupValue
            ? ele
            : {
                ...ele,
                content_attributes: {
                  ...ele.content_attributes,
                  items: dataConfirm,
                },
              };
        }
      );
      callbackSaveAffiliateProductAddNew(
        newAffiliateProductGroupData,
        productGroupValue
      );
      scrollToNewItem(`product-${dataConfirm[0]?.uid}`);
    } else {
      const dataConfirm =
        listProductsDisplay &&
        Array.isArray(listProductsDisplay) &&
        listProductsDisplay.map((ele, idx) => ({
          ...ele,
          uid: ele.id,
          sort_order: idx + 1,
          title: ele.name,
          enable: 1,
          is_cleanup_lock: 2,
          image_name: ele.image_name || "",
          image_base64: ele.image_base64,
        }));
      let newListProducts = [...dataConfirm, ...activedTabProductLists];
      if (newListProducts?.length > PRODUCT_MAX) {
        const excessProductsLength = newListProducts?.length - PRODUCT_MAX;
        const removedProductsArr = newListProducts
          ?.filter((x) => x?.is_cleanup_lock !== 1)
          ?.slice(-excessProductsLength);
        newListProducts = newListProducts?.filter(
          (x) => !removedProductsArr?.some((y) => y?.uid === x?.uid)
        );
      }
      const newListProductsReSortOrder = newListProducts.map((ele, idx) => ({
        ...ele,
        sort_order: idx + 1,
      }));
      const newAffiliateProductGroupData = affiliateProductGroupData?.map(
        (ele) => {
          return ele.uid !== productGroupValue
            ? ele
            : {
                ...ele,
                content_attributes: {
                  ...ele.content_attributes,
                  items: newListProductsReSortOrder,
                },
              };
        }
      );
      callbackSaveAffiliateProductAddNew(
        newAffiliateProductGroupData,
        productGroupValue
      );
      scrollToNewItem(`product-${dataConfirm[0]?.uid}`);
    }
  }, [
    productGroupValue,
    activedTabProductLists,
    affiliateProductGroupData,
    listProductsDisplay,
  ]);

  const handleCancelAffiliateProductAddNew = () => {
    if (listProductsDisplay?.length >= 1) {
      Modal.confirm({
        header: (
          <InformationTriangleIcon
            className="w-12 h-12"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_product_addition_modal_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_product_addition_modal_description")}
          </div>
        ),
        confirmText: t(
          "ss_builder_cancel_product_addition_modal_confirm_btn_label"
        ),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          isStopDetectingUrl.current = true;
          callbackCancelAffiliateProductAddNew();
        },
      });
    } else {
      isStopDetectingUrl.current = true;
      callbackCancelAffiliateProductAddNew();
    }
  };

  const handleCheckAffiliateUrl = (productId) => {
    const newListProducts = listProductsDisplay.map((ele) => {
      return productId === ele.id
        ? {
            ...ele,
            use_aff_url: ele.use_aff_url === 1 ? 2 : 1,
          }
        : ele;
    });
    setListProducts(newListProducts);
  };

  const handleCheckOpenNewTab = (productId, openNewTab) => {
    const newListProducts = listProductsDisplay.map((ele) => {
      const isEcommerce = checkOpenNewTab(new URL(ele?.origin_link));
      return productId === ele.id
        ? {
            ...ele,
            open_new_tab: isEcommerce ? true : openNewTab,
          }
        : ele;
    });
    setListProducts(newListProducts);
  };

  const handleChangeNameProduct = (value, productId) => {
    const newListProducts = listProductsDisplay.map((ele) => {
      return productId === ele.id
        ? {
            ...ele,
            name: value,
          }
        : ele;
    });
    setListProducts(newListProducts);
  };
  const handleSelectLabelProduct = (data, productId) => {
    if (data && Array.isArray(data)) {
      const newListProducts = listProducts.map((ele) => {
        return productId === ele.id
          ? {
              ...ele,
              label_product: data,
            }
          : ele;
      });
      setListProducts(newListProducts);
    }
  };

  const handleImageUrl = (data, productId) => {
    const imgFileItems = data && data[data.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
      if (!productIdValidArr?.includes(productId)) {
        setProductIdValidArr([...productIdValidArr, productId]);
      }
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
      if (!productIdValidArr?.includes(productId)) {
        setProductIdValidArr([...productIdValidArr, productId]);
      }
    } else {
      setUploadedImageArr(data);
      const newListProducts = listProductsDisplay.map((ele) => {
        return productId === ele.id
          ? {
              ...ele,
              image: imgFileItems.dataBase64,
              image_name: imgFileItems.fileName,
              image_base64: imgFileItems.dataBase64,
            }
          : ele;
      });
      setListProducts(newListProducts);
      if (productIdValidArr?.includes(productId)) {
        const newProductIdValidArr = productIdValidArr?.filter(
          (x) => x !== productId
        );
        setProductIdValidArr(newProductIdValidArr);
      }
    }
  };

  const productRatioAdded = `${
    activedTabProductLists.length + listProductsDisplay.length < PRODUCT_MAX
      ? activedTabProductLists.length + listProductsDisplay.length
      : PRODUCT_MAX
  }/${PRODUCT_MAX}`;

  const productGroupPickerValue = useMemo(
    () =>
      productGroupValue
        ? affiliateProductGroupData?.find((x) => x?.uid === productGroupValue)
            ?.content_attributes?.title_product_group
        : "",
    [productGroupValue, affiliateProductGroupData]
  );

  const onChangeProductLink = (e) => {
    e.preventDefault();
    setDetectProductUrl(e.target.value);
    if (e.target.value?.trim()?.length === 0) {
      setIsValidUrl(false);
    } else {
      const formatedUrls = formatDetectedUrls(e.target.value?.trim());
      const isInvalid = formatedUrls?.every((x) => !validURL(x));
      setIsValidUrl(isInvalid);
    }
  };

  const onPasteProductLink = (event) => {
    event.preventDefault();
    const textareaElement = document?.querySelector(
      ".detect-product-link-textarea"
    ) as HTMLInputElement;
    if (isLoadingBtn) {
      return;
    }
    if (detectProductUrl?.trim()?.length > 0) {
      if (document?.activeElement === textareaElement) {
        const start = textareaElement?.selectionStart;
        const end = textareaElement?.selectionEnd;
        const part1 = detectProductUrl?.slice(0, start) || "";
        const part2 = detectProductUrl?.slice(end) || "";
        const newDetectProductUrl = `${part1}${event.clipboardData.getData(
          "text"
        )}${part2}`;
        const formatedUrls = formatDetectedUrls(newDetectProductUrl?.trim());
        const isInvalid = formatedUrls?.every((x) => !validURL(x));
        setIsValidUrl(isInvalid);
        setDetectProductUrl(newDetectProductUrl);
      }
    } else {
      const formatedUrls = formatDetectedUrls(
        event.clipboardData.getData("text")?.trim()
      );
      const isInvalid = formatedUrls?.every((x) => !validURL(x));
      setIsValidUrl(isInvalid);
      setDetectProductUrl(event.clipboardData.getData("text"));
      if (!isInvalid) {
        validateDetectProductUrl(event.clipboardData.getData("text"));
      }
    }
  };

  const successfullyUrlQuantity =
    detectedUrlLists?.filter(
      (x) => x?.valid_type === PRODUCT_URL_CHECKING.SUCCESS
    )?.length || 0;

  const handleStopDetectUrl = () => {
    isStopDetectingUrl.current = true;
    setIsLoadingBtn(false);
    setDetectProductUrl("");
  };

  return (
    <div className="affiliate-add-new-wrapper">
      <div
        className="flex flex-row text-center items-center justify-between gap-1 py-[10px] bg-white px-3"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <span className="grow text-center">
          <Badge
            color="var(--adm-color-primary)"
            content={productRatioAdded}
            style={{ "--top": "50%", "--right": "-25px" }}
          >
            <div className="text-lg leading-6">
              {t("ss_builder_add_new_affiliate_product_title")}
            </div>
          </Badge>
        </span>
        <ProductAddNewTourOpening
          isHideStep3={listProductsDisplay?.length <= 0}
        />
      </div>
      <div
        className={cls(
          "w-full h-full",
          isOpenAffiliateAddNewTour ? "pb-[300px]" : "pb-[100px]"
        )}
      >
        <ProductGroupSelection
          productGroupPickerValue={productGroupPickerValue}
          placeholder={
            !productGroupValue &&
            t("ss_builder_product_group_selection_placeholder")
          }
          onOpenProductGroupSelection={() =>
            setIsOpenProductGroupSelection(true)
          }
          isDisabledSelection={isLoadingBtn}
        />
        <AutoCleanupProductsWarning
          isShowWarning={isExceedMaximumProducts && !isCheckQuantityProducts}
        />
        {isCheckQuantityProducts ? (
          <div className="flex flex-col text-center items-center justify-center py-[20px]">
            <span className="inline-block">
              <ExclamationTriangleIcon
                className="align-middle w-8 h-8"
                fillColor="var(--adm-color-primary)"
              />
            </span>
            <div className="text-[#333333] text-lg leading-6">
              {t("ss_builder_product_exceeding_allowed_amount_title")}
            </div>
            <div className="text-[#8C8C8C] text-xs leading-4">
              {t("ss_builder_product_exceeding_allowed_amount_content")}
            </div>
          </div>
        ) : (
          <ProductAddNewInput
            detectProductUrl={detectProductUrl}
            onChangeProductLink={onChangeProductLink}
            onPasteProductLink={onPasteProductLink}
            disabledInput={isLoadingBtn}
            disabledAddMoreBtn={
              isValidUrl || detectProductUrl?.trim()?.length === 0
            }
            onClickAddMoreBtn={() => validateDetectProductUrl(detectProductUrl)}
            onClickStopBtn={handleStopDetectUrl}
            isValidUrl={isValidUrl}
            isLoadingBtn={isLoadingBtn}
          />
        )}
        {detectedUrlQuantity > 1 && (
          <div
            className="cursor-pointer mx-3"
            onClick={() =>
              !isLoadingBtn ? setIsOpenDetectedUrlLists(true) : {}
            }
          >
            <NoticeBar
              extra={
                !isLoadingBtn && (
                  <RightArrowIcon
                    className="w-[18px] h-[18px]"
                    fillColor="#389E0D"
                  />
                )
              }
              icon={
                !isLoadingBtn ? (
                  <CheckCircleIconV3
                    className="w-[18px] h-[18px]"
                    fillColor="#389E0D"
                  />
                ) : (
                  <SpinLoading
                    color="#1677FF"
                    style={{ "--size": "16px" }}
                  />
                )
              }
              content={
                isLoadingBtn
                  ? t("ss_builder_multi_product_url_importing_label", {
                      successfullyUrlQuantity: successfullyUrlQuantity,
                      detectedUrlQuantity: detectedUrlQuantity,
                    })
                  : t("ss_builder_multi_product_url_imported_label", {
                      successfullyUrlQuantity: successfullyUrlQuantity,
                      detectedUrlQuantity: detectedUrlQuantity,
                    })
              }
              style={{
                borderRadius: "8px",
                "--background-color": isLoadingBtn ? "#D0E4FF" : "#D9F7BE",
                "--text-color": isLoadingBtn ? "#1677FF" : "#389E0D",
                "--border-color": isLoadingBtn ? "#D0E4FF" : "#D9F7BE",
              }}
            />
          </div>
        )}
        {listProductsDisplay?.length > 0 ? (
          listProductsDisplay.map((ele, index) => (
            <ProductItem
              item={ele}
              index={index}
              key={ele.id}
              onUpdateName={function (name: string): void {
                handleChangeNameProduct(name, ele.id);
              }}
              onUpdateImage={function (image: any): void {
                handleImageUrl(image, ele.id);
              }}
              onCheckUseAffiliateUrl={function (checked: boolean): void {
                handleCheckAffiliateUrl(ele.id);
              }}
              onCheckOpenNewTab={function (openNewTab: boolean): void {
                handleCheckOpenNewTab(ele.id, openNewTab);
              }}
              onSelectLabelProduct={function (labelProduct: any): void {
                handleSelectLabelProduct(labelProduct, ele.id);
              }}
              onDelete={function (): void {
                handleDeleteProduct(ele.id);
              }}
              isValidFileFormat={
                !!(productIdValidArr.includes(ele.id) && isValidFileFormat)
              }
              isValidFileSize={
                !!(productIdValidArr.includes(ele.id) && isValidFileSize)
              }
            />
          ))
        ) : (
          <></>
        )}
      </div>
      <div>
        <div
          className={cls(
            AFFILIATE_ADD_NEW_TOUR_CLASS_NAME.STEP_4,
            "max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]"
          )}
        >
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              onClick={handleCancelAffiliateProductAddNew}
            >
              {t("ss_builder_editor_btn-cancel")}
            </Button>
          </div>
          <div className="w-1/2 text-center">
            <Button
              className="w-full"
              disabled={!isSaveButtonEnable}
              color={"primary"}
              onClick={handleSaveAffiliateProductAddNew}
              // loading={isLoadingBtn}
            >
              {t("ss_builder_editor_btn-add-more")}
            </Button>
          </div>
        </div>
      </div>
      {isOpenProductGroupSelection && (
        <AffiliateProductGroupPickerView
          affiliateProductGroupData={affiliateProductGroupData}
          visible={isOpenProductGroupSelection}
          onCancelAffiliateProductGroupSelection={() =>
            setIsOpenProductGroupSelection(false)
          }
          defaultValue={productGroupValue}
          onSelectAffiliateProductGroup={(val) => setProductGroupValue(val)}
          onAddNewAffiliateProductGroup={callbackAddNewAffiliateProductGroup}
          productGroupMaximum={productGroupMaximum}
          listProducts={listProductsDisplay}
        />
      )}
      {isOpenDetectedUrlLists && (
        <FullScreenPopup
          visible={isOpenDetectedUrlLists}
          onMaskClick={() => {
            setIsOpenDetectedUrlLists(false);
          }}
        >
          <DetectedUrlLists
            onCancel={() => setIsOpenDetectedUrlLists(false)}
            urlLists={detectedUrlLists}
          />
        </FullScreenPopup>
      )}
    </div>
  );
};

export default AffiliateProductAddNew;
