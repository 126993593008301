import { BaseUpdateDataInterface } from "@share/api";
import { CreateGatewayAxios } from "@share/api/createAxios";
import { BLOCK_TYPE } from "../block.conf";
import { isExistedInvalidImageUrl, isExistedProductFromJson } from "../libs";
import { CHUNK_PAYLOAD_SIZE, LIMITED_PAYLOAD_SIZE } from "../config/constants";

const API_PATH = {
  BLOCK: "/api/v1/builder/block",
  SORT_BLOCK: "/api/v1/builder/block/sort",
  SAVE_ATTRIBUTES: "api/v1/builder/block/save-attributes",
  SAVE_TABS_ATTRIBUTE: "api/v1/builder/block/save-tabs-attribute",
  SAVE_INTRO_DIGITAL_PRODUCT: "/api/mini-store/v1/catalog/save-has-intro-dp",
  CHECK_INTRO_DIGITAL_PRODUCT: "/api/mini-store/v1/catalog/check-has-intro-dp",
  UPLOAD_IMAGE_URL_FROM_JSON_FILE:
    "/api/v1/builder/block/aff/update-image-from-json",
};

interface SaveAttributeData {
  position_id?: string;
  parent_id?: string;
  tab_uid?: string;
  sort_order?: number;
  title?: string;
  enable?: number;
}
export interface SortDataInterface {
  id: string;
  sort_order: number;
}

const gatewayInstance = CreateGatewayAxios();

export const saveBlock = async (
  baseUpdateData: BaseUpdateDataInterface,
  block
) => {
  const blockString = JSON.stringify(block);
  const byteSize = new TextEncoder().encode(blockString).length;
  let response = null;
  //Xử lý khi dữ liệu gửi lên quá lớn sẽ cần phải chunk payload ra từng phần nhỏ và gửi lên.
  if (byteSize >= LIMITED_PAYLOAD_SIZE) {
    const totalChunks = Math.ceil(blockString?.length / CHUNK_PAYLOAD_SIZE);
    for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
      const start = chunkNumber * CHUNK_PAYLOAD_SIZE;
      const end = (chunkNumber + 1) * CHUNK_PAYLOAD_SIZE;
      const chunk = blockString?.slice(start, end);
      response = await gatewayInstance.post(API_PATH.BLOCK, {
        ...baseUpdateData,
        payloadChunk: chunk,
        chunkNumber,
        totalChunks,
      });
    }
  } else {
    response = await gatewayInstance.post(API_PATH.BLOCK, {
      ...baseUpdateData,
      data: block,
    });
  }
  const blockDataResponse = response?.data?.data;
  //Đồng bộ đường dẫn ảnh từ file Json của site khác về site của mình. Chỉ áp dụng cho affiliate product
  if (blockDataResponse?.block_type === BLOCK_TYPE.AFF_PRODUCT_GROUP) {
    const isUpdateImageUrlJson =
      blockDataResponse?.content_attributes?.items?.some((group) => {
        if (group) {
          const products = group?.content_attributes?.items || [];
          return !!(
            isExistedProductFromJson(products) &&
            isExistedInvalidImageUrl(products)
          );
        }
      });
    if (isUpdateImageUrlJson) {
      await requestUploadImageUrlFromJson();
    }
  }
  return response;
};

export const deleteBlock = (
  baseUpdateData: BaseUpdateDataInterface,
  blockId
) => {
  return gatewayInstance.delete(API_PATH.BLOCK, {
    params: { ...baseUpdateData, id: blockId },
  });
};

export const sortBlock = (
  baseUpdateData: BaseUpdateDataInterface,
  sortData: SortDataInterface[]
) => {
  return gatewayInstance.post(API_PATH.SORT_BLOCK, {
    ...baseUpdateData,
    data: sortData,
  });
};

export const saveAttributes = (
  baseUpdateData: BaseUpdateDataInterface,
  id,
  data: SaveAttributeData
) => {
  return gatewayInstance.post(API_PATH.SAVE_ATTRIBUTES, {
    ...baseUpdateData,
    data: { id, data },
  });
};

export const saveTabsAttributes = (
  baseUpdateData: BaseUpdateDataInterface,
  id,
  data: any
) => {
  return gatewayInstance.post(API_PATH.SAVE_TABS_ATTRIBUTE, {
    ...baseUpdateData,
    data: { id, tabs: data },
  });
};

export const saveIntroDigitalProduct = () => {
  return gatewayInstance.post(API_PATH.SAVE_INTRO_DIGITAL_PRODUCT);
};
export const checkIntroDigitalProduct = () => {
  return gatewayInstance.get(API_PATH.CHECK_INTRO_DIGITAL_PRODUCT);
};

export const requestUploadImageUrlFromJson = () => {
  return gatewayInstance.put(API_PATH.UPLOAD_IMAGE_URL_FROM_JSON_FILE);
};
