import useHandleItems from "@features/block/hooks/useHandleItems";
import StickyChecking from "@share/components/StickyChecking";
import { useModal } from "@share/hooks/modal";
import { useToast } from "@share/hooks/toast";
import {
  CloseCircleIcon,
  EyeHiddenIcon,
  EyeVisibleIcon,
  MoreIcon,
  TrashIcon,
  IconIsChecked,
  IndeterminateCheckboxIcon,
  IconIsNotChecked,
} from "@share/icons";
import { Button, Popover, Checkbox } from "antd-mobile";
import { Action } from "antd-mobile/es/components/popover";
import cloneDeep from "lodash/cloneDeep";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ListItemWithCheckbox from "./components/ListItemWithCheckbox";

enum ActionEnum {
  VISIBLE,
  HIDE,
}
export interface ItemUIProps {
  id: string;
  checked?: boolean;
  [key: string]: any;
}

interface ItemsWithCheckboxProps {
  defaultItems: any;
  propertyIndex: string;
  item: React.FunctionComponent<ItemUIProps>;
  onChange: (list: Array<ItemUIProps>) => void;
  onCancel: () => void;
  header?: {
    title: string;
    sticky: boolean;
  };
  footer?: {
    countTitle: string;
  };
  blockType: string;
  previousStickyElementId?: string;
}

const ItemsWithCheckbox: React.FunctionComponent<ItemsWithCheckboxProps> = (
  props
) => {
  const {
    defaultItems,
    item: Item,
    onChange,
    header,
    onCancel,
    propertyIndex,
    blockType,
    previousStickyElementId,
  } = props;

  const { t } = useTranslation();
  const { SuccessToast } = useToast();
  const { ConfirmModal } = useModal();
  const defaultItemsClone = cloneDeep(defaultItems);
  const { onReOrder, onRemove, onDisable, onEnable, onAdd, onReset } =
    useHandleItems(defaultItemsClone, "uid");

  const popoverRef = useRef(null);

  const [selectedItems, setSelectedItem] = useState(defaultItemsClone);

  const actions: Action[] = [
    {
      key: ActionEnum.VISIBLE,
      icon: <EyeHiddenIcon className="" />,
      text: t(
        `ss_builder_confirm_enable_item_checkbox_${blockType}_modal_title`
      ),
    },
    {
      key: ActionEnum.HIDE,
      icon: <EyeVisibleIcon className="" />,
      text: t(
        `ss_builder_confirm_disable_item_checkbox_${blockType}_modal_title`
      ),
    },
  ];

  const handleClickPopoverItem = (item: Action) => {
    const key = item && item.key;
    switch (key) {
      case ActionEnum.VISIBLE:
        confirmEnable();
        break;
      case ActionEnum.HIDE:
        confirmDisable();
        break;
    }
  };

  const checkedItem = selectedItems?.filter((x) => x?.checked === true);

  const handleAction = (action: any) => {
    const ids = cloneDeep(selectedItems)
      ?.filter((x) => x?.checked === true)
      ?.map((e) => e[propertyIndex]);
    const newItems = action(ids);
    onChange(newItems);
    onCancel();
  };

  const handleSelect = (list: any) => {
    const newData = defaultItems?.map((item) => {
      const checkedItem = list?.find((x) => x.uid === item.uid);
      return checkedItem || item;
    });
    setSelectedItem(newData);
  };

  const confirmEnable = () => {
    const onConfirm = () => {
      handleAction(onEnable);
      SuccessToast(
        t(`ss_builder_enable_item_checkbox_${blockType}_successfully_label`)
      );
    };
    ConfirmModal({
      title: t(
        `ss_builder_confirm_enable_item_checkbox_${blockType}_modal_title`
      ),
      description: t(
        `ss_builder_confirm_enable_item_checkbox_${blockType}_modal_content`,
        {
          quantity: checkedItem.length,
        }
      ),
      confirmText: t("ss_builder_confirm_show_product_btn_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const confirmRemove = () => {
    const onConfirm = () => {
      handleAction(onRemove);
      SuccessToast(
        t(`ss_builder_delete_item_checkbox_${blockType}_successfully_label`)
      );
    };
    ConfirmModal({
      title: t(
        `ss_builder_confirm_delete_item_checkbox_${blockType}_modal_title`
      ),
      description: t(
        `ss_builder_confirm_delete_item_checkbox_${blockType}_modal_content`,
        {
          quantity: checkedItem.length,
        }
      ),
      confirmText: t("ss_builder_confirm_delete_product_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const confirmDisable = () => {
    const onConfirm = () => {
      handleAction(onDisable);
      SuccessToast(
        t(`ss_builder_disable_item_checkbox_${blockType}_successfully_label`)
      );
    };
    ConfirmModal({
      title: t(
        `ss_builder_confirm_disable_item_checkbox_${blockType}_modal_title`
      ),
      description: t(
        `ss_builder_confirm_disable_item_checkbox_${blockType}_modal_content`,

        {
          quantity: checkedItem.length,
        }
      ),
      confirmText: t("ss_builder_confirm_hide_product_btn_label"),
      cancelText: t("ss_builder_cancel_delete_product_label"),
      onConfirm,
    });
  };

  const previousStickyElementIdMemo = useMemo(
    () => previousStickyElementId || `block-${blockType}-editor-header`,
    [previousStickyElementId, blockType]
  );

  const handleCheckAll = (value: boolean) => {
    const newList = defaultItems?.map((item) => ({
      ...item,
      checked: value,
    }));
    setSelectedItem(newList);
  };

  const handleSelectLast = (count) => {
    if (defaultItems?.length >= count) {
      const checkedData = defaultItems
        ?.slice(Math.max(defaultItems.length - count, 0))
        ?.map((item) => ({
          ...item,
          checked: true,
        }));
      const newData = defaultItems?.map((item) => {
        const checkedItem = checkedData?.find((x) => x.uid === item.uid);
        return checkedItem || item;
      });
      setSelectedItem(newData);
      return;
    }
    setSelectedItem(
      defaultItems?.map((item) => ({
        ...item,
        checked: true,
      }))
    );
  };

  const indeterminate = useMemo(() => {
    return checkedItem.length > 0 && checkedItem.length < defaultItems.length;
  }, [checkedItem, defaultItems]);

  const isCheckAll = useMemo(() => {
    return selectedItems.every((e) => e.checked);
  }, [selectedItems]);

  return (
    <section className="relative">
      <StickyChecking
        previousStickyElementId={previousStickyElementIdMemo}
        currentStickyElementId="header-list-items-checkbox"
      >
        <header className="flex justify-between items-center z-10 px-3 py-4 ">
          <div className="text-[15px] text-[#666666]">
            <span className="text-red-600">*</span>
            {header.title}
          </div>
          <Button
            onClick={onCancel}
            size="small"
            fill="solid"
            color="primary"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <CloseCircleIcon
              className="w-[15px] h-[15px]"
              fillColor="white"
            />
            <div>{t("ss_builder_cancel_select_multi_items")}</div>
          </Button>
        </header>
        <div className="flex flex-row gap-4 items-center ml-[10px] pb-4 z-10">
          <Checkbox
            indeterminate={indeterminate}
            checked={isCheckAll}
            onChange={handleCheckAll}
            icon={(checked) =>
              checked ? (
                <IconIsChecked />
              ) : indeterminate ? (
                <IndeterminateCheckboxIcon />
              ) : (
                <IconIsNotChecked />
              )
            }
          />
          <div className="flex flex-row gap-2 items-center">
            <span>{t("ss_builder_select_multi_items")}:</span>
            <div className="flex flex-row gap-2 items-center">
              {[5, 10, 20].map((e) => (
                <Button
                  shape="rounded"
                  fill="solid"
                  size="small"
                  onClick={() => handleSelectLast(e)}
                >
                  {t("ss_builder_filter_select_multi_items", {
                    num_items: e,
                  })}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </StickyChecking>
      <ListItemWithCheckbox
        propertyIndex="uid"
        defaultItems={selectedItems}
        item={Item}
        onSelect={handleSelect}
      />
      <div
        className="max-width-content-class fixed bottom-0 p-3 flex w-full justify-between items-center gap-2 z-[11] bg-[#FFFFFF]"
        ref={popoverRef}
      >
        <div className="text-lg leading-6">
          <span className="text-[#EE3244]">{checkedItem?.length} </span>
          <span>{props?.footer?.countTitle || ""}</span>
        </div>
        <div className="flex flex-row items-center">
          <Button
            size="small"
            style={{
              border: "unset",
              padding: "3px 8px",
            }}
            disabled={checkedItem?.length === 0}
            onClick={confirmRemove}
          >
            <TrashIcon fillColor="#EE3244" />
          </Button>
          <div
            className=""
            style={{
              opacity: checkedItem?.length === 0 && "0.4",
            }}
          >
            <Popover.Menu
              mode="dark"
              actions={actions}
              placement="top"
              trigger="click"
              stopPropagation={["click"]}
              getContainer={() => popoverRef.current}
              onAction={(item: Action) => handleClickPopoverItem(item)}
              {...(checkedItem?.length === 0 ? { visible: false } : {})}
            >
              <Button
                size="small"
                style={{
                  border: "unset",
                  padding: "3px 8px",
                }}
                disabled={selectedItems?.length === 0}
              >
                <MoreIcon className=" cursor-pointer" />
              </Button>
            </Popover.Menu>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItemsWithCheckbox;
