import { Popup } from "antd-mobile";
import { PopupProps } from "antd-mobile/es/components/popup";
import React from "react";

export default function InnerPopup(props: PopupProps) {
  const { className } = props;
  return (
    <Popup
      {...props}
      className={
        className
          ? `${className} inner-popup eco-popup`
          : `inner-popup eco-popup`
      }
    >
      <div className="m-auto w-full bg-white max-w-[500px] rounded-t-lg">
        {props.children}
      </div>
    </Popup>
  );
}
