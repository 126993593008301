import { useAppDispatch } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { scrollToNewItem } from "@features/block/libs";
import FullScreenPopup from "@share/components/full-screen-popup";
import StickyChecking from "@share/components/StickyChecking";
import { IMAGE_ORIENTATION_TYPE } from "@share/configs/const";
import { AddIcon, ImageSliderIcon } from "@share/icons";
import {
  Button,
  Divider,
  Image,
  Input,
  Selector,
  Switch,
  TextArea,
} from "antd-mobile";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import ImageListItems from "./ImageListItems";
import ImageSliderAddNew from "./ImageSliderAddNew";
import cls from "classnames";
import PlaceholderCustomDragging from "@share/components/PlaceholderCustomDragging";
import { getPlaceholderProps } from "@share/lib";

const IMAGE_MAX = 5;
const BLOCK_HEADING_MAXIMUM = 50;
const BLOCK_DESCRIPTION_MAXIMUM = 250;

const ImageSliderComponent = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
  } = props;
  const { t } = useTranslation();
  const UID = `image-slider-${uuidv4()}`;

  const defaultValueEditImageSlider =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;
  const titleBlockDefault = "Image Slider";

  const imageSliderArrDefault =
    defaultValueEditImageSlider?.content_attributes?.items || [];
  const valueHeadingBlockDefault =
    defaultValueEditImageSlider?.content_attributes?.block_heading || "";
  const valueDescriptionBlockDefault =
    defaultValueEditImageSlider?.content_attributes?.block_description || "";

  const enableDotDefault =
    defaultValueEditImageSlider?.style_attributes?.enable_dot !== undefined
      ? defaultValueEditImageSlider?.style_attributes?.enable_dot
      : true;

  const enableArrowDefault =
    defaultValueEditImageSlider?.style_attributes?.enable_arrow !== undefined
      ? defaultValueEditImageSlider?.style_attributes?.enable_arrow
      : false;

  const enableAutoPlayDefault =
    defaultValueEditImageSlider?.style_attributes?.enable_auto_play !==
    undefined
      ? defaultValueEditImageSlider?.style_attributes?.enable_auto_play
      : false;

  const imageOrientationDefault =
    defaultValueEditImageSlider?.style_attributes?.image_orientation ||
    IMAGE_ORIENTATION_TYPE.LANDSCAPE;

  const ratioDefault = useMemo(() => {
    let valueRatio =
      defaultValueEditImageSlider?.style_attributes?.ratio || "16/9";
    if (imageOrientationDefault === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [defaultValueEditImageSlider, imageOrientationDefault]);

  const [imageRatio, setImageRatio] = useState(ratioDefault);
  const [isOpenAddNewImageSlider, setIsOpenAddNewImageSlider] = useState(false);
  const [imageSliderArr, setImageSliderArr] = useState(imageSliderArrDefault);

  const [enableDot, setEnableDot] = useState(enableDotDefault);
  const [enableArrow, setEnableArrow] = useState(enableArrowDefault);
  const [enableAutoPlay, setEnableAutoPlay] = useState(enableAutoPlayDefault);
  const [imageEditData, setImageEditData] = useState(null);
  const [indexImageEdit, setIndexImageEdit] = useState(null);
  const [valueHeadingBlock, setValueHeadingBlock] = useState(
    valueHeadingBlockDefault
  );
  const [imageOrientation, setImageOrientation] = useState(
    imageOrientationDefault
  );
  const [valueDescriptionBlock, setValueDescriptionBlock] = useState(
    valueDescriptionBlockDefault
  );
  const [placeholderProps, setPlaceholderProps] = useState({});

  const imageSliderSortOrder = useMemo(
    () => orderBy(imageSliderArr, ["sort_order"], ["asc"]),
    [imageSliderArr]
  );

  const imageEnable = useMemo(() => {
    return imageSliderArr.filter((item) => item.enable === 1);
  }, [imageSliderArr]);

  const imageSliderEnable = imageSliderSortOrder.filter((x) => x.enable === 1);

  const imageRatioDetection = useMemo(() => {
    let valueRatio = imageRatio;
    if (imageOrientation === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [imageOrientation, imageRatio]);

  useEffect(() => {
    if (!block.uid) {
      const defaultImageSliderData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.IMAGE_SLIDER,
        title: titleBlockDefault,
        content_attributes: {
          items: imageSliderSortOrder,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
        },
        style_attributes: {
          enable_dot: enableDot,
          enable_arrow: enableArrow,
          enable_auto_play: enableAutoPlay,
          ratio: imageRatioDetection,
          image_orientation: imageOrientation,
        },
      };
      onUpdateData(defaultImageSliderData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          items: imageSliderSortOrder,
          block_heading: valueHeadingBlock,
          block_description: valueDescriptionBlock,
        },
        style_attributes: {
          enable_dot: enableDot,
          enable_arrow: enableArrow,
          enable_auto_play: enableAutoPlay,
          ratio: imageRatioDetection,
          image_orientation: imageOrientation,
        },
      });
    }
  }, [
    imageSliderSortOrder,
    enableDot,
    enableArrow,
    enableAutoPlay,
    imageRatioDetection,
    valueHeadingBlock,
    valueDescriptionBlock,
    imageOrientation,
  ]);

  const isDisableBtnSave = useMemo(() => {
    if (
      imageSliderEnable &&
      imageSliderEnable.length > 0 &&
      (JSON.stringify(imageSliderArr) !==
        JSON.stringify(imageSliderArrDefault) ||
        imageRatio !== ratioDefault ||
        enableDot !== enableDotDefault ||
        enableArrow !== enableArrowDefault ||
        enableAutoPlay !== enableAutoPlayDefault ||
        valueHeadingBlock !== valueHeadingBlockDefault ||
        valueDescriptionBlock !== valueDescriptionBlockDefault ||
        imageOrientation !== imageOrientationDefault) &&
      imageEnable.length > 0
    ) {
      return false;
    } else return true;
  }, [
    imageSliderSortOrder,
    imageSliderArr,
    imageSliderArrDefault,
    imageRatio,
    ratioDefault,
    enableDot,
    enableArrow,
    enableArrowDefault,
    enableAutoPlay,
    enableAutoPlayDefault,
    enableDotDefault,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    imageOrientation,
    imageOrientationDefault,
    imageEnable,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      JSON.stringify(imageSliderArr) !==
        JSON.stringify(imageSliderArrDefault) ||
      imageRatio !== ratioDefault ||
      enableDot !== enableDotDefault ||
      enableArrow !== enableArrowDefault ||
      enableAutoPlay !== enableAutoPlayDefault ||
      valueHeadingBlock !== valueHeadingBlockDefault ||
      valueDescriptionBlock !== valueDescriptionBlockDefault ||
      imageOrientation !== imageOrientationDefault
    ) {
      return true;
    } else return false;
  }, [
    imageSliderArr,
    imageSliderArrDefault,
    imageRatio,
    ratioDefault,
    enableDot,
    enableArrow,
    enableArrowDefault,
    enableAutoPlay,
    enableAutoPlayDefault,
    enableDotDefault,
    valueHeadingBlock,
    valueDescriptionBlock,
    valueHeadingBlockDefault,
    valueDescriptionBlockDefault,
    imageOrientation,
    imageOrientationDefault,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  const imageRate = useMemo(
    () => imageSliderSortOrder && `${imageSliderSortOrder.length}/${IMAGE_MAX}`,
    [imageSliderSortOrder]
  );

  const handleSaveImageSlider = (data) => {
    const imageSliderClone = cloneDeep(imageSliderSortOrder);
    if (Array.isArray(imageSliderClone)) {
      if (imageEditData) {
        const indexEditItems = imageSliderClone.findIndex(
          (x) => x.uid === imageEditData.uid
        );
        imageSliderClone[indexEditItems] = data;
      } else {
        if (imageSliderClone.length === 0) {
          imageSliderClone.push(data);
        } else {
          const sortIdArr = imageSliderClone.map((x) => x.sort_order);
          imageSliderClone.push({
            ...data,
            sort_order: Math.max(...sortIdArr) + 1,
          });
        }
      }
      setImageSliderArr(imageSliderClone);
      !imageEditData && scrollToNewItem(`blocks-${data?.uid}`);
    }
  };

  const imageSliderOptions = [
    {
      label: "1:1",
      value: "1/1",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "4:3" : "3:4",
      value: "4/3",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:2" : "2:3",
      value: "3/2",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "16:9" : "9:16",
      value: "16/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "21:9" : "9:21",
      value: "21/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:1" : "1:3",
      value: "3/1",
    },
  ];

  const imageOrientationOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://i.ecomobi.com/ssp/passio-page/config/landscape.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_landscape_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.LANDSCAPE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://i.ecomobi.com/ssp/passio-page/config/portrait.png"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_portrait_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.PORTRAIT,
    },
  ];

  const handleSelectImageRatio = (arr) => {
    if (arr && arr.length) {
      const valueRatio = arr[0];
      setImageRatio(valueRatio);
    }
  };

  const handleOpenAddImageSlider = () => {
    setIsOpenAddNewImageSlider(true);
    setImageEditData(null);
    setIndexImageEdit(null);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(imageSliderSortOrder);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedImageList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedImageList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    const startIndex = result && result.source && result.source.index;
    const endIndex = result && result.destination && result.destination.index;
    if (!result.destination || startIndex === endIndex) {
      return;
    }
    const itemsOrder = reorder(startIndex, endIndex);
    setImageSliderArr(itemsOrder);
  };

  const onDragUpdate = (event: DropResult): void => {
    if (!event.destination) {
      return;
    }
    const placeholderProps = getPlaceholderProps(event);
    setPlaceholderProps(placeholderProps);
  };

  const onRemoveItem = (index: number) => {
    const imageSliderClone = cloneDeep(imageSliderSortOrder);
    const newImageItems =
      imageSliderClone &&
      Array.isArray(imageSliderClone) &&
      imageSliderClone.filter((ele: any, idx: number) => idx !== index);
    setImageSliderArr(newImageItems);
  };

  const onDisableEnableImage = (index: number, imageStatus: boolean) => {
    const imageSliderClone = cloneDeep(imageSliderSortOrder);
    const newImageItems =
      imageSliderClone &&
      Array.isArray(imageSliderClone) &&
      imageSliderClone.map((ele, idx) => {
        return idx === index
          ? {
              ...ele,
              enable: imageStatus,
            }
          : ele;
      });
    setImageSliderArr(newImageItems);
  };

  const handleOpenEditImage = (imageData, index) => {
    setIsOpenAddNewImageSlider(true);
    setImageEditData(imageData);
    setIndexImageEdit(index);
  };

  const handleChangeBlockHeading = (val) => {
    if (val?.length <= BLOCK_HEADING_MAXIMUM) {
      setValueHeadingBlock(val);
    }
  };

  const handleChangeBlockDescription = (val) => {
    if (val?.length <= BLOCK_DESCRIPTION_MAXIMUM) {
      setValueDescriptionBlock(val);
    }
  };

  const handleSelectImageOrientation = (arr) => {
    if (arr && arr.length) {
      const imageOrientation = arr[0];
      setImageOrientation(imageOrientation);
    }
  };

  return (
    <>
      <div className=" bg-white mt-3 px-4 py-[13px]">
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_heading_label")}
          </span>
          <Input
            placeholder={t("ss_builder_block_heading_placeholder")}
            onChange={handleChangeBlockHeading}
            value={valueHeadingBlock}
            maxLength={BLOCK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueHeadingBlock ? valueHeadingBlock.length : 0
          }/${BLOCK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </span>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            onChange={handleChangeBlockDescription}
            value={valueDescriptionBlock}
            maxLength={BLOCK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueDescriptionBlock ? valueDescriptionBlock.length : 0
          }/${BLOCK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
      </div>
      <div className="bg-white mt-3 px-4 py-[13px]">
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_image_orientation_label")}
          </span>
          <Selector
            options={imageOrientationOptions}
            value={[imageOrientation]}
            onChange={handleSelectImageOrientation}
            columns={4}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
            }}
          />
        </div>
        <Divider />
        <div className="text-[15px] text-[#666666]">
          {t("ss_builder_image_slider_ratio_selected_title")}
        </div>
        <Selector
          options={imageSliderOptions}
          value={[imageRatio]}
          onChange={handleSelectImageRatio}
          columns={4}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_image_slider_dot_switch_title")}
          </div>
          <Switch
            defaultChecked={true}
            checked={enableDot}
            onChange={(val) => setEnableDot(val)}
          />
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_image_slider_arrow_switch_title")}
          </div>
          <Switch
            defaultChecked={true}
            checked={enableArrow}
            onChange={(val) => setEnableArrow(val)}
          />
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_image_slider_auto_play_switch_title")}
          </div>
          <Switch
            defaultChecked={true}
            checked={enableAutoPlay}
            onChange={(val) => setEnableAutoPlay(val)}
          />
        </div>
      </div>
      <>
        <div className="relative">
          <StickyChecking previousStickyElementId="block-image-slider-editor-header">
            <div className="flex flex-row justify-between px-3 pt-4 pb-3">
              <div className="grow">
                <div className="text-[15px] text-[#666666]">
                  <span className="text-red-600">*</span>
                  {t("ss_builder_list_label")}
                </div>
              </div>
              <div className="flex flex-grow justify-end gap-2">
                <Button
                  size="small"
                  fill="solid"
                  color="primary"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    padding: "3px 12px 3px 12px",
                  }}
                  onClick={handleOpenAddImageSlider}
                  disabled={imageSliderSortOrder.length === IMAGE_MAX}
                >
                  <AddIcon className="w-[15px] h-[15px]" />
                  <div>{t("ss_builder_add_new_label")}</div>
                  <span>{imageRate}</span>
                </Button>
              </div>
            </div>
            {imageEnable.length === 0 && imageSliderArr.length > 0 && (
              <div
                className="px-3 pb-4 text-[13px] font-normal leading-[17px]"
                style={{ color: "var(--adm-color-danger)" }}
              >
                {t("ss_builder_need_to_display_at_least_one_image")}
              </div>
            )}
          </StickyChecking>
          {imageSliderSortOrder && imageSliderSortOrder.length > 0 ? (
            <div>
              <DragDropContext
                onDragEnd={onDragEnd}
                onDragUpdate={onDragUpdate}
              >
                <Droppable droppableId={`blocks-${BLOCK_TYPE.IMAGE_SLIDER}`}>
                  {(provided, snapshot): JSX.Element => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="relative"
                    >
                      {imageSliderSortOrder &&
                        imageSliderSortOrder.length > 0 &&
                        imageSliderSortOrder.map((ele, index) => (
                          <Draggable
                            draggableId={`blocks-${ele.uid}`}
                            index={index}
                            key={`blocks-${ele.uid}`}
                          >
                            {(provided, snapshot): JSX.Element => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={cls(
                                  "mt-2",
                                  snapshot.isDragging && "adm-item-custom"
                                )}
                              >
                                <ImageListItems
                                  index={index}
                                  imageData={ele}
                                  onRemoveItem={onRemoveItem}
                                  onDisableEnableImage={onDisableEnableImage}
                                  callbackOpenEditImage={handleOpenEditImage}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      {!isEmpty(placeholderProps) &&
                        snapshot.isDraggingOver && (
                          <PlaceholderCustomDragging
                            placeholderProps={placeholderProps}
                            content={t(
                              "ss_builder_placeholder_dragging_content"
                            )}
                            style={{
                              backgroundColor: "rgba(230, 247, 255, 0.75)",
                              border: "1px dashed #1890FF",
                              borderRadius: "4px",
                              color: "#1890FF",
                            }}
                          />
                        )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <div className="flex flex-col text-center items-center justify-center">
              <span className="inline-block mb-2">
                <ImageSliderIcon className="align-middle w-8 h-8" />
              </span>
              <div className="text-[#333333] text-lg leading-6">
                {t("ss_builder_image_list_empty_title")}
              </div>
              <div className="text-[#8C8C8C] text-xs leading-4">
                {t("ss_builder_image_list_empty_content")}
              </div>
            </div>
          )}
        </div>
      </>
      <FullScreenPopup
        visible={isOpenAddNewImageSlider}
        onMaskClick={() => setIsOpenAddNewImageSlider(false)}
      >
        <ImageSliderAddNew
          imageRatio={imageRatioDetection}
          callBackCancelImageSliderAddNew={() =>
            setIsOpenAddNewImageSlider(false)
          }
          handleSaveImageSlider={handleSaveImageSlider}
          imageEditData={imageEditData}
          indexImageEdit={indexImageEdit}
        />
      </FullScreenPopup>
    </>
  );
};

export default ImageSliderComponent;
