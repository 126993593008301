import { formatNumberToCurrencyDonation, formatUnitSymbol, htmlDecode } from "@share/lib";
import { Ellipsis, Image, Modal, Popover } from "antd-mobile";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { ACTION_KEY, MOVE, URL_IFRAME } from "../../constants";
import { CourseIcon, DeleteForeverIcon, DeleteIcon, DownLastIcon, EbookExclusiveIcon, EditIcon, MoveIcon, OnlineConsultionIcon, PhotoCatIcon, RandomProductIcon, ThreeDot, TicketAccountIcon, UpFirstIcon, VideoExclusiveIcon, WarningIcon } from "../../icons";
import { Action } from "antd-mobile/es/components/popover";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { categoryBackgroundMap, categoryNameMap, categoryTextColorMap } from "../../config/category";
import { CAT_ID } from "../../config/type";
import style from "./style.module.scss";


const ICON_MAP = {
    [CAT_ID.RANDOM_PRODUCT]: <RandomProductIcon />,
    [CAT_ID.ONLINE_CONSULTION]: <OnlineConsultionIcon />,
    [CAT_ID.COURSE]: <CourseIcon />,
    [CAT_ID.VIDEO]: <VideoExclusiveIcon />,
    [CAT_ID.EXCLUSIVE_EBOOK]: <EbookExclusiveIcon />,
    [CAT_ID.TICKET_ACCOUNT]: <TicketAccountIcon />,
    [CAT_ID.PHOTO]: <PhotoCatIcon />,
  };

  
export default function ProductItem({
    item,
    countryCode,
    handleMove,
    handleDelete,
    popoverActive,
    setPopoverActive,
    number,
    lastList,
    handleChooseIframe
}) {
    const { t } = useTranslation()
    const popoverRef = useRef(null);
    const actions: Action[] = [
        {
            key: ACTION_KEY.EDIT,
            icon: <EditIcon />,
            text: t('ss_builder_edit_block_label')
        },
        {
            key: ACTION_KEY.DELETE_DISPLAY,
            icon: <DeleteIcon />,
            text: t('ss_display_delete'),
            onClick : () => handleClickDelete()
        }
    ];
    if (number !== 0) {
        actions.push({ key: ACTION_KEY.UP_FIRST, icon: <UpFirstIcon />, text: t('ss_builder_move_to_top_label') })
    }
    if (number !== lastList) {
        actions.push({ key: ACTION_KEY.DOWN_LAST, icon: <DownLastIcon />, text: t('ss_builder_move_to_bottom_label') })
    }
    actions.push({ key: ACTION_KEY.DELETE, icon: <DeleteForeverIcon />, text: t('ss_delete_forever') })

    const handleClickPopoverItem = (node) => {
        switch (node.key) {
            case ACTION_KEY.UP_FIRST:
                handleMove(item.id, MOVE.UP_FIRST)
                break;
            case ACTION_KEY.DOWN_LAST:
                handleMove(item.id, MOVE.DOWN_LAST)
                break;
            case ACTION_KEY.EDIT:
                handleEditProduct(item)
                break;
            case ACTION_KEY.DELETE:
                handleClickDeleteForever()
                break;
        }
    }

    const handleClickThreeDot = () => {
        if(popoverActive === item.id) {
            setPopoverActive(null)
        } else {
            setPopoverActive(item.id)
        }
    }

    const handleShowIcon = (item) => {
        return ICON_MAP[item] || null;
    }

    const handleClickDelete = () => {
        Modal.confirm({
            content: <div>
                <div><WarningIcon className="mx-auto" /></div>
                <div className="font-bold text-lg text-center mt-3">{t('ss_delete_product')}</div>
                <div className="mt-2 text-center">{t('ss_content_confirm_delete')}</div>
            </div>,
            confirmText: t('ss_confirm_delete'),
            cancelText: t('ss_cancle_delete'),
            onConfirm: () => {
                handleDelete(item.id);
            }
        })
    }

    const handleClickDeleteForever = () => {
        setPopoverActive(null);
        Modal.confirm({
            header: <div>
                <img src="https://i.ecomobi.com/ssp/builder/digital-product/delete-product.gif" />
            </div>,
            content: <div>
                <div className={`${style['title-modal-delete-forever']}`}>{t('ss_delete_forever')}</div>
                <div className={`${style['content-modal-delete-forever']}`}>{t('ss_content_delete_forever')}</div>
            </div>,
            confirmText: <div className={`${style['button-confirm-delete-forever']}`}>
                <span className={`${style['text-confirm']}`}>{t('ss_modal_text_confirm')}</span>
            </div>,
            cancelText: <div className={`${style['button-cancel-delete-forever']}`}>
                <span className={`${style['text-cancel']}`}>{t('ss_modal_cancel_confirm')}</span>
            </div>,
            closeOnMaskClick: true,
            className: style['container-modal-delete-forever'],
            onConfirm: () => {
                const params = {
                    isSetting:true
                }
                handleChooseIframe(URL_IFRAME.IFRAME, params)
            }
        })
    }

    const handleEditProduct = (item) => {
        const params = {
            actionCloseIframe: true,
        }
        if(item.category.id == CAT_ID.COURSE) {
            handleChooseIframe(`${URL_IFRAME.COURSE}/${item.id}`, params)
        } else if (item.category.id == CAT_ID.PHOTO) {
            handleChooseIframe(`${URL_IFRAME.PHOTO}/${item.id}`, params)
        } else if (item.category.id == CAT_ID.TICKET_ACCOUNT) {
            handleChooseIframe(`${URL_IFRAME.TICKET_ACCOUNT}/${item.id}`, params)
        } else {
            handleChooseIframe(`${URL_IFRAME.EDIT}/${item.id}`, params)
        }
    }

    useEffect(() => {
        const handleClickOutsidePopover = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
              const isDropdownItem = event.target.closest('.adm-popover-menu-item');
              const isThreeDot = event.target.closest('.clickable-area');
              if (!isThreeDot) {
                if (!isDropdownItem) {
                    setPopoverActive(null);
                }
              }
            }
          };
        document.addEventListener("mousedown", handleClickOutsidePopover);
        
        return () => {
          document.removeEventListener("mousedown", handleClickOutsidePopover);
        };
      }, [popoverRef]);

   

    return <div>
        <div className="flex justify-between bg-white p-3 items-center rounded">
            <div className=" flex items-center">
                <div>
                    <MoveIcon />
                </div>
                 <LazyLoadImage
                src={item.category?.id === CAT_ID.PHOTO ? item?.cover_image?.url : item.thumbnail ? item.thumbnail : (item?.thumbnails?.length ? item?.thumbnails[0].url : "")}
                className="rounded-lg ml-3 w-10 h-10"
                style={{
                  backgroundSize: "cover",
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  width: "100%",
                  maxWidth: "40px",
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                    "https://i.ecomobi.com/ssp/passio-page/image-default.png";
                }}
              />
                <div className="ml-2">
                    <div
                        style={{
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            wordBreak: 'break-word'
                        }}
                        className='text-base font-normal'
                    >{htmlDecode(item.name)}
                    </div>
                    <div className="text-base pb-1 leading-[100%] font-normal text-ellipsis whitespace-nowrap overflow-hidden">
                        {item.price > 0 ? (
                            <span className="text-red-600 font-medium">{`${formatNumberToCurrencyDonation(
                                item.price
                            )} ${formatUnitSymbol(countryCode)}`}</span>
                        ) : (
                            <span className="text-green-500 font-medium">
                                {t("ss_builder_digital_product_free_label")}
                            </span>
                        )}
                    </div>
                    <div
                      className='px-1 py-[2px] rounded text-[11px] font-[500] whitespace-nowrap w-fit'
                      style={{
                        backgroundColor: categoryBackgroundMap[item.category.id],
                        color: categoryTextColorMap[item.category.id]
                      }}
                    >
                        <div className="flex items-center justify-center">
                            {handleShowIcon(item.category.id)} 
                            <span className="ml-1">{t(categoryNameMap[item.category.id])}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popover-content" ref={popoverRef}>
                <Popover.Menu
                    mode="dark"
                    actions={actions}
                    placement="bottomRight"
                    trigger="click"
                    stopPropagation={["click"]}
                    getContainer={() => popoverRef.current}
                    visible={popoverActive === item.id}
                    onAction={(node) => handleClickPopoverItem(node)}
                    destroyOnHide
                >
                    <div className="cursor-pointer three-dot-svg" onClick={handleClickThreeDot}><ThreeDot /></div>
                </Popover.Menu>
            </div>
        </div>
        <div className="h-3" />
    </div>
}