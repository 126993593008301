import { EyeVisibleIcon } from "@share/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const HidddenWarningUI = ({ label }: { label: string }) => {
  const { t } = useTranslation();
  return (
    <div
      className="bg-[#FFF3E9] flex justify-center items-center p-2 gap-[10px]"
      style={{
        borderWidth: "1px 0px",
        borderStyle: "solid",
        borderColor: "#FFF3E9",
      }}
    >
      <EyeVisibleIcon
        className="align-middle inline-block fill-black"
        fillColor="#FF6010"
        width={18}
        height={18}
      />
      <div className="text-[#FF6010]">{label}</div>
    </div>
  );
};

export default HidddenWarningUI;
