import { Button } from "antd-mobile";
import React, { useEffect, useMemo, useRef } from "react";

const ProductGroupTabItemDetail = (props: {
  item: any;
  onSelect: (HTMLSpanElement) => void;
  selected: boolean;
  isDisabledSelection: boolean;
  onClickAction: any;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const { item, onSelect, selected, isDisabledSelection, onClickAction } =
    props;

  useEffect(() => {
    if (selected) {
      onSelect(ref.current);
    }
  }, [selected]);

  const isHideProductGroup = useMemo(
    () => !!((!selected && isDisabledSelection) || item?.enable !== 1),
    [selected, isDisabledSelection, item]
  );

  return (
    <>
      <span ref={ref}>
        <Button
          block
          size="middle"
          style={{
            whiteSpace: "nowrap",
            border: "none",
            color: selected ? "#EE3244" : "",
            borderBottom: selected ? "2px solid #EE3244" : "2px solid #ffffff",
            borderRadius: "0px",
            opacity: isHideProductGroup ? "40%" : "",
          }}
          onClick={(e) => {
            if (!isDisabledSelection) {
              e.stopPropagation();
              onSelect(ref.current);
              onClickAction();
            }
          }}
        >
          <h6>{item.title}</h6>
        </Button>
      </span>
    </>
  );
};

export default ProductGroupTabItemDetail;
