export const BACK_GROUND_IMAGE = {
  BRAND_GIFT: "https://i.ecomobi.com/ssp/mini-store/brand-gift/background-brand-gift.png",
  BRAND_RATING: "https://i.ecomobi.com/ssp/mini-store/brand-deal/BrandRatingsPP.jpg"
};
export const MAX_LENGTH_INPUT_TITLE = 60;
export const MAX_LENGTH_INPUT_BUTTON = 40;

export const NAME_SERVICE = {
  BRAND_GIFT: "brand_gift",
};
