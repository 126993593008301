import { PRODUCT_URL_CHECKING } from "@share/configs/const";
import { copyToClipboard } from "@share/helper/browser";
import {
  BackAngelIcon,
  CheckCircleIcon,
  CircleIcon,
  CoppyIcon,
  DuplicatedIcon,
  RenameIcon,
} from "@share/icons";
import { NavBar, Popover, Toast } from "antd-mobile";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { InfomationCircleIcon } from "../../../../../share/icons/index";

const DetectedUrlLists = (props) => {
  const { onCancel, urlLists } = props;
  const { t } = useTranslation();
  const popoverRef = useRef(null);

  const styleRender = (data) => {
    let style = {
      marginTop: "12px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#FFFFFF",
    } as any;
    switch (data) {
      case PRODUCT_URL_CHECKING.DUPLICATE:
        style = {
          ...style,
          border: "1px solid #FF8F1F",
        };
        break;
      case PRODUCT_URL_CHECKING.INVALID:
        style = {
          ...style,
          border: "1px solid #FF3141",
        };
        break;
      case PRODUCT_URL_CHECKING.NOT_ADDED_YET:
        style = {
          ...style,
          border: "1px solid #EEEEEE",
        };
        break;
      case PRODUCT_URL_CHECKING.SUCCESS:
        style = {
          ...style,
          border: "1px solid #00B578",
        };
        break;
      case PRODUCT_URL_CHECKING.MISSING_REQUIRED:
        style = {
          ...style,
          border: "1px solid #1890FF",
        };
        break;
      default:
        style = {
          ...style,
          border: "1px solid #00B578",
        };
        break;
    }
    return style;
  };

  const iconRender = (data) => {
    let iconElement = null;
    let contentPopover = "";
    switch (data) {
      case PRODUCT_URL_CHECKING.DUPLICATE:
        iconElement = (
          <DuplicatedIcon
            className="w-[15px] h-[15px]"
            fillColor="#FF8F1F"
          />
        );
        contentPopover = t("ss_builder_duplicated_product_url_label");
        break;
      case PRODUCT_URL_CHECKING.INVALID:
        iconElement = (
          <InfomationCircleIcon
            className="w-[15px] h-[15px]"
            fillColor="#FF3141"
          />
        );
        contentPopover = t("ss_builder_invalid_product_url_label");
        break;
      case PRODUCT_URL_CHECKING.NOT_ADDED_YET:
        iconElement = <CircleIcon className="w-[15px] h-[15px]" />;
        contentPopover = t("ss_builder_not_add_yet_product_url_label");
        break;
      case PRODUCT_URL_CHECKING.SUCCESS:
        iconElement = (
          <CheckCircleIcon
            className="w-[15px] h-[15px]"
            fillColor="#00B578"
          />
        );
        contentPopover = t("ss_builder_added_product_url_label");
        break;
      case PRODUCT_URL_CHECKING.MISSING_REQUIRED:
        iconElement = (
          <RenameIcon
            className="w-[15px] h-[15px]"
            fillColor="#1890FF"
          />
        );
        contentPopover = t("ss_builder_missing_required_product_url_label");
        break;
      default:
        iconElement = (
          <CheckCircleIcon
            className="w-[15px] h-[15px]"
            fillColor="#00B578"
          />
        );
        contentPopover = t("ss_builder_added_product_url_label");
        break;
    }
    return (
      <Popover
        content={contentPopover}
        trigger="click"
        placement="top"
        mode="dark"
        stopPropagation={["click"]}
        getContainer={() => popoverRef.current}
      >
        {iconElement}
      </Popover>
    );
  };

  const copyLinkProduct = (value) => {
    copyToClipboard(value);
    Toast.show({
      icon: (
        <CheckCircleIcon
          className="text-center inline-block w-[30px] h-[30px]"
          fillColor="#00B578"
        />
      ),
      content: (
        <div className="text-center">
          {t("ss_builder_personal_link_coppy_successfully_label")}
        </div>
      ),
    });
  };

  return (
    <>
      <NavBar
        backArrow={<BackAngelIcon />}
        onBack={onCancel}
        className="max-width-content-class w-full bg-[#FFFFFF] fixed top-0"
      >
        <div className="flex flex-row text-center items-center justify-center gap-[10px] text-[13px]">
          <div className="text-titleFontSize">
            {t("ss_builder_product_url_lists_label")}
          </div>
        </div>
      </NavBar>
      <div className="pb-5 px-3 pt-[45px]">
        {urlLists?.map((ele, index) => (
          <div
            key={index}
            style={styleRender(ele?.valid_type)}
            ref={popoverRef}
          >
            <span
              className="py-[10px] px-[11px]"
              style={{
                borderRight: "1px solid #EEEEEE",
              }}
            >
              {index < 9 ? `0${index + 1}` : index + 1}
            </span>
            <div className="flex flex-row items-center gap-1 py-[10px] px-[11px] grow w-[20vw] md:w-[84%]">
              <span className="cursor-pointer">
                {iconRender(ele?.valid_type)}
              </span>
              <span className="text-ellipsis whitespace-nowrap overflow-hidden text-[15px] grow">
                {ele?.url}
              </span>
            </div>
            <span
              className="cursor-pointer py-[10px] px-[11px]"
              onClick={() => copyLinkProduct(ele?.url)}
            >
              <CoppyIcon className="w-4 h-4" />
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default DetectedUrlLists;
