import { checkIntroDigitalProduct, saveIntroDigitalProduct } from "@features/block/api";
import { useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import { DotLoading, List, Popup, SpinLoading } from "antd-mobile";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import digitalProductApi from "./api";
import ProductItem from "./components/ProductItem";
import { IFRAME_ACTION, MOVE, URL_IFRAME } from "./constants";
import { AddProductPlusIcon, ChooseDataStoreIcon, PlusIcon, RequiredIcon } from "./icons";
import Intro from "./intro";
import { Image } from "antd-mobile";
import { digitalProductAction } from "./store/digitalProductSlice";
import { useDispatch } from "react-redux";
import style from "./style.module.scss";

let dataSendIframe = []

const DigitalProduct = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    handleCancelBlock,
  } = props;
  const url_Iframe = process.env.NEXT_PUBLIC_PASSIO_IFRAME_URL;
  const { t } = useTranslation();
  const UID = `digital-product-${uuidv4()}`;

  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const user = useAppSelector((state) => state.user);
  const dataEditRedux = useAppSelector((state) => state.digitalProduct.dataEdit);
  const dataRemoveRedux = useAppSelector((state) => state.digitalProduct.dataRemove);
  const [showIntro, setShowIntro] = useState<boolean>(null)
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [urlIframe, setUrlIframe] = useState<string>("")
  const countryCode = user?.kol?.country;
  const edittingBlockData = edittingBlock && edittingBlock.block_data;
  const dispatch = useDispatch();

  const defaultValueEditDigitalProduct =
    edittingBlockData && edittingBlockData.uid ? edittingBlockData : null;


  const digitalProductDefault = useMemo(() => {
    return {
      uid: `digital-product-${uuidv4()}`,
      sort_order: 1,
      enable: 1,
      block_type: BLOCK_TYPE.DIGITAL_PRODUCT,
      title: t("ss_builder_product_group_default_title"),
      content_attributes: {
        dp_items: [],
        dp_data: [],
      },
      style_attributes: {},
    };
  }, []);

  const digitalProductGroupArrDefault = 
  (defaultValueEditDigitalProduct?.content_attributes?.dp_data ? defaultValueEditDigitalProduct?.content_attributes?.dp_data : 
    defaultValueEditDigitalProduct?.content_attributes?.dp_items) ?? [];

  const titleBlockDefault = "Digital Product";

  const [digitalProductArr, setDigitalProductArr] = useState<any>([]);
  const [popoverActive, setPopoverActive] = useState(null);
  const [openIframe, setOpenIframe] = useState<boolean>(false);
  const [loadingIframe, setLoadingIframe] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasRemove, setHasRemove] = useState<boolean>(false);
  const [ digitalProductDefaultNotItemsDelete, setDigitalProductDefaultNotItemsDelete] = useState<any>([]);

  
  const fetchDataProductApi = async (listID? : Array<number>) => {
    const params = listID ? { ids: listID.join(',') } : { status: 1 }
    try {
      setLoading(true)
      const res = await digitalProductApi.listProduct(params);
      if (res.success) {
        const sortResponse = listID 
        ? listID.map(id => res.data.find(item => item.id === id)).filter(Boolean)
        : res.data;
        setDigitalProductArr(sortResponse);
        return res.data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const fetchProduct = async (dataFetch) => {
    if (dataFetch) {
      fetchDataProductApi(dataFetch)
      return;
    }
    if (block.content_attributes === undefined) {
      setDigitalProductArr([]);
      return;
    }
    if (Object.keys(block.content_attributes).length === 0) {
      fetchDataProductApi()
      return;
    }
    if (block.content_attributes?.items && block.content_attributes?.items.length === 0) {
      fetchDataProductApi()
      return;
    }
    if (!block.content_attributes?.dp_items.length) {
      setDigitalProductArr([]);
      return;
    }
  }

  const checkOnlyIdInside = (array) => {
    if (array) {
      return array.every(element => {
        return typeof element === 'number';
      });
    } else {
      return false
    }
  }

  const isEditDataStatus = useMemo(() => {
    if (typeof digitalProductGroupArrDefault !== "object" && JSON.stringify(digitalProductArr.filter(Boolean).map((item) => item.id)) ===
      JSON.stringify(digitalProductDefaultNotItemsDelete)) {
      return false;
    }
    if (showIntro) {
      return false;
    } else if ((digitalProductGroupArrDefault.content_attributes?.dp_data.length === 0 || digitalProductGroupArrDefault.content_attributes?.dp_items.length === 0) && digitalProductArr.filter(Boolean).length === 0) {
      return false;
    } else if (JSON.stringify(digitalProductArr.filter(Boolean).map((item) => item.id)) !== 
    (checkOnlyIdInside(digitalProductGroupArrDefault) ? JSON.stringify(digitalProductGroupArrDefault) : 
    JSON.stringify(digitalProductGroupArrDefault.map((item => item.id)))) ) {
      return true;
    } else return false;
  }, [
    digitalProductArr,
    digitalProductGroupArrDefault,
    showIntro
  ]);

  useEffect(() => {
    if (typeof digitalProductGroupArrDefault !== "object" && digitalProductArr.length > 0) {
      const filterArrDefault = digitalProductGroupArrDefault.map(id => digitalProductArr.find(item => item.id === id))
      setDigitalProductDefaultNotItemsDelete(filterArrDefault.filter(Boolean))
    }
  }, [digitalProductArr]);

  useEffect(() => {
    setButtonStatus(false);
  }, []);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  useEffect(() => {
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage)
  }, [dataEditRedux, dataRemoveRedux, block])


  useEffect(() => {
    if (!block.uid) {
      const defaultDigitalProductData = {
        uid: UID,
        sort_order: 1,
        enable: 1,
        block_type: BLOCK_TYPE.DIGITAL_PRODUCT,
        title: titleBlockDefault,
        content_attributes: {
          dp_items: [],
          dp_data: [],
        },
        style_attributes: {},
      };
      onUpdateData(defaultDigitalProductData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          dp_items: digitalProductArr.filter(Boolean).map((item) => item.id),
          dp_data: digitalProductArr,
          hasInit: true,
          hasRemove: hasRemove,
          use_extra_function: hasRemove,
        },
        style_attributes: {},
      });
    }
  }, [digitalProductArr]);

  const handleMessage = (event) => {
    switch (event.data.key) {
      case IFRAME_ACTION.CLOSE_IFRAME:
        // props.handleCancelBlock()
        handleCloseIframe(event.data);
        break;
      case IFRAME_ACTION.GET_DATA:
        handleLoadingSuccess()
        break;
      case IFRAME_ACTION.RECIVED_DATA:
        handleRecivedMessage(event.data.data)
        break;
      case IFRAME_ACTION.SAVE_INTRO:
        handleSetUp()
        break;
    }
  }

  const handleCloseIframe = (dataIframeClose) => {
    setOpenIframe(false)
    setLoadingIframe(false);
    if (dataIframeClose?.data?.isIntro) {
      props.handleCancelBlock()
    }
    if (dataIframeClose?.data?.dataRemove.length > 0 || dataIframeClose?.data?.dataEdit.length > 0) {
      setHasRemove(true)
      const dataRemove = dataRemoveRedux ? [...dataRemoveRedux] : []
      const dataEdit = dataEditRedux ? [...dataEditRedux] : []
      dataIframeClose?.data?.dataEdit.forEach((item, index) => {
        const findItem = dataEdit.findIndex(x => x.id === item.id);
        if (findItem !== -1) {
          dataEdit[findItem] = item;
        } else {
          dataEdit.push(item);
        }
      });
      if (dataIframeClose?.data?.dataEdit) {
        dispatch(digitalProductAction.setDataEdit([...dataEdit]));
      }
      dataIframeClose?.data?.dataRemove.forEach((item, index) => {
        const findItem = dataRemove.findIndex(x => x === item);
        if (findItem === -1) {
          dataRemove.push(item);
        }
      });
      if (dataIframeClose?.data?.dataRemove) {
        dispatch(digitalProductAction.setDataRemove([...dataRemove]));
      }
      const newData = [];
      block.content_attributes?.dp_data.forEach((item) => {
        const newItem = Object.assign({}, item);
        const editIndex = dataEdit.findIndex((edit) => edit.id === item.id);
        if (editIndex !== -1) {
          Object.assign(newItem, dataEdit[editIndex]);
        }

        const removeIndex = dataRemove.findIndex((removeId) => removeId === item.id);
        if (removeIndex === -1) {
          newData.push(newItem)
        }
      });
      if(dataIframeClose?.data?.dataNew) {
        const dataNew = dataIframeClose?.data?.dataNew;
        const checkDuplicate = newData.find(x => x.id === dataNew.id);
        if(!checkDuplicate) {
          const dataConvert = {
            currency: dataNew.currency,
            id: dataNew.id,
            category: { id: dataNew.category?.id },
            ordinal_number_lucky_card: dataNew.ordinal_number_lucky_card,
            original_price: dataNew.original_price,
            price: dataNew.price,
            name: dataNew.name,
            product_type: dataNew.product_type,
            thumbnail: dataNew.thumbnails?.length > 0 ? dataNew.thumbnails[0].url_small : null,
            cover_image: dataNew?.cover_image ? dataNew?.cover_image : null,
          }
          newData.push(dataConvert);
        }
      }
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          dp_data: newData,
          hasInit: true,
          hasRemove: hasRemove,
          use_extra_function: hasRemove,
        },
        style_attributes: {},
      });
      setDigitalProductArr(newData)
    }
  }

  const handleRecivedMessage = (dataIframe) => {
    if (dataIframe?.isChange) {
      setHasRemove(true)
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          hasRemove: true,
          use_extra_function: true,
        },
        style_attributes: {},
      });
    }
    setDigitalProductArr(dataIframe.dataEdit)
    if(!dataIframe?.noCloseIframe) {
      setOpenIframe(false)
    }
    if(dataIframe?.closeLoadingIframe) {
      setLoadingIframe(false)
    }
  }


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const newList = reorder(digitalProductArr, result.source.index, result.destination.index)
    setDigitalProductArr([...newList])
  }

  const handleMove = (id, move) => {
    const result = Array.from(digitalProductArr.filter(Boolean))
    const findIndex = result.findIndex((item: any) => item.id === id)
    const [removed] = result.splice(findIndex, 1)
    if (move === MOVE.UP_FIRST) {
      result.splice(0, 0, removed)
    } else {
      result.splice(result.length, 0, removed)
    }
    setPopoverActive(null)
    setDigitalProductArr([...result])
  }

  const handleDelete = (id) => {
    const result = Array.from(digitalProductArr.filter(Boolean))
    const findIndex = result.findIndex((item: any) => item.id === id)
    result.splice(findIndex, 1)
    setDigitalProductArr([...result])
  }

  const handleOpenIframeLoading = () => {
    dataSendIframe = digitalProductArr.filter(Boolean)
    setShowPopup(false)
    setOpenIframe(true)
    setLoadingIframe(true)
    setPopoverActive(null)
  }

  const handleChooseIframe = (url, params = null) => {
    if(params) {
      const paramsString = new URLSearchParams(params).toString();
      setUrlIframe(url+'?isIframe=true&'+paramsString)
    } else {
      setUrlIframe(url+'?isIframe=true')
    }
    handleOpenIframeLoading()
  }

  const handleLoadingSuccess = () => {
    const iframe = document.getElementById('select-list-iframe') as HTMLIFrameElement
    if (!iframe) {
      return;

    }
    iframe.contentWindow.postMessage(
      {
        'key': IFRAME_ACTION.LIST_DATA,
        'data': dataSendIframe
      },
      '*'
    )
    setLoadingIframe(false)
  }

  useEffect(() => {
    const itemData = block.content_attributes?.dp_data?.length > 0 ? block.content_attributes.dp_data : null
    const itemID = block.content_attributes?.dp_items?.length > 0 ? block.content_attributes.dp_items : null
    if (itemData) {
      setDigitalProductArr(itemData);
    } else {
      fetchProduct(itemID)
    }
  }, [])

  const checkHasIntro = async () => {
    try {
      const res = await checkIntroDigitalProduct()
      const dataRes = res.data
      if(dataRes.success) {
        setShowIntro(!dataRes.has_intro_dp)
        return;
      }
    } catch (e) {

    }
    setShowIntro(false)
  }
  useEffect(() => {
    checkHasIntro();
  }, []);
  const handleSetUp = async () => {
    try {
      await saveIntroDigitalProduct()
      setShowIntro(false)
    } catch (e) {

    }
  }
  if(showIntro === null){
    return <></>
  }
  if(showIntro){
    return (
      <div className="w-full fixed min-h-screen top-0 left-0" style={{ zIndex: 999999 }}>
        <iframe
          src={`${url_Iframe}kol/digital-product/intro?isIframe=true`}
          className="min-h-screen w-full"
        />
      </div>
    )
  }


  if (loading) {
    return <div className="flex items-center justify-center min-w-full min-h-screen absolute top-0 bottom-0">
      <span>{t("ss_builder_loading_label")}</span>
      <DotLoading />
    </div>
  }



  return (
    <div>
      <Popup
        visible={showPopup}
        bodyClassName="adm-popup-body-position-bottom-panel"
        bodyStyle={{
          backgroundColor: "none",
        }}
        onClose={() => setShowPopup(false)}
        closeOnMaskClick
        destroyOnClose
      >
        <div
          className="bg-[#fff] max-width-content-layout m-auto relative h-full max-h-[80vh]"
          style={{
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <div className="popup-header">
            <div className={`title ${style['title-header-popup']}`}>{t('ss_iframe_add_product')}</div>
          </div>
          <div className="pb-8">
            <div className="popup-body overflow-y-auto border-t-[1px] border-b-[1px] border-[#EEEEEE]">
              <List>
                <List.Item
                  onClick={() => handleChooseIframe(URL_IFRAME.SELECT_CATEGORY, { closeIframe: true, actionCloseIframe: true })}
                  clickable={false}
                  className={`cursor-pointer ${style['list-item-popup']}`}
                >
                  <div className="flex items-center">
                    <AddProductPlusIcon className="mr-3" /> {t('ss_iframe_add_new_product')}
                  </div>
                </List.Item>
                <List.Item
                  onClick={() => handleChooseIframe(URL_IFRAME.IFRAME)}
                  clickable={false}
                  className={`cursor-pointer ${style['list-item-popup']}`}
                >
                  <div className="flex items-center">
                    <ChooseDataStoreIcon className="mr-3" />{t('ss_iframe_choose_product_from_store')}
                  </div>
                </List.Item>
              </List>
            </div>
          </div>
          
        </div>
      </Popup>
      {loadingIframe && <div
        className="flex items-center justify-center min-w-full min-h-screen absolute top-0 bottom-0"
        style={{ zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <SpinLoading className="text-white" />
      </div>}
      {openIframe && !loadingIframe && <div
        className="flex items-center justify-center min-w-full min-h-screen absolute top-0 bottom-0"
        style={{ zIndex: 99999, backgroundColor: "#FFF" }}
      >
      </div>}
      {openIframe && urlIframe && <div className="w-full fixed min-h-screen top-0 left-0" style={{ zIndex: 999999 }}>
        <iframe
          id={"select-list-iframe"}
          src={`${url_Iframe}kol/digital-product/${urlIframe}`}
          className="min-h-screen w-full"
        />
      </div>}
      <div>
        <div className=" py-[13px]">
          <div className="text-[17px] font-normal text-[#666666] flex justify-between">
            <span className="flex"><RequiredIcon /> {t("ss_builder_list_title_digital_product")}</span>
            <button
              className="text-white bg-[#EE3244] text-[15px] px-3 py-[3px] rounded-[4px] mr-3 flex items-center"
              onClick={() => setShowPopup(true)}><div className="pr-2"><PlusIcon /></div> {t('ss_dp_add')}</button>
          </div>
          {digitalProductArr && digitalProductArr?.length > 0 ? (
            <div className="flex flex-col gap-3 px-3 mt-2">
              <div>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId='droppable'>
                    {droppableProvided => (
                      <div ref={droppableProvided.innerRef}>
                        {digitalProductArr.filter(Boolean).map((item, index: number) => (
                          <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  opacity: snapshot.isDragging ? 0.8 : 1,
                                }}
                              >
                                <ProductItem
                                  item={item}
                                  countryCode={countryCode}
                                  handleMove={handleMove}
                                  handleDelete={handleDelete}
                                  popoverActive={popoverActive}
                                  setPopoverActive={setPopoverActive}
                                  number={index}
                                  lastList={digitalProductArr.filter(Boolean).length -1}
                                  handleChooseIframe={handleChooseIframe}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-center items-center justify-center mt-4">
              <span className="inline-block">
                  <Image
                    src="https://i.ecomobi.com/ssp/passio-page/empty_digital_product.png"
                  />
              </span>
              <div className="text-[#333333] text-lg leading-6 pb-[2px]">
                {t("ss_builder_affiliate_product_list_empty_title")}
              </div>
              <div className="text-[#8C8C8C] text-xs leading-4">
                {t("ss_builder_affiliate_product_list_empty_content")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DigitalProduct;
