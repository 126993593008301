export const POSITION_SLUG = {
  HEADER: "header",
  BODY: "body",
  FOOTER: "footer",
  EXPAND: "expand",
};

export interface Block {
  tab_uid?: string;
  id?: string;
  uid: string;
  sort_order: number;
  enable: number;
  block_type: string;
  content_attributes: any;
  style_attributes?: any;
  title: string;
}

export interface ImageInterface {
  uid: string;
  sort_order: number;
  enable: number;
  image: string;
  origin_url: string;
  target_url: string;
  use_aff_url: number;
  image_description: string;
  link_data?: any;
  title?: string;
  open_new_tab?: boolean;
}

export interface ProductInterface {
  uid: string;
  sort_order: number;
  enable: number;
  image: string;
  title: string;
  use_aff_url: number;
  source_type: string;
  origin_link: string;
  image_name: string;
  image_base64: string;
  is_cleanup_lock: number;
  shortern_link?: string;
  label_product?: any;
  open_new_tab?: boolean;
  post_link?: any;
}

export interface AffiliateProductInterface {
  uid: string;
  sort_order: number;
  enable: number;
  title: string;
  block_type: string;
  content_attributes: any;
  style_attributes: any;
}

export interface StoryInterface {
  uid: string;
  sort_order: number;
  enable: number;
  image: string;
  title?: string;
  origin_url: string;
  target_url: string;
  use_aff_url: number | boolean;
  link_data?: any;
  open_new_tab?: boolean;
}

export interface ImageGalleryInterface {
  uid: string;
  sort_order: number;
  enable: number;
  image: string;
  title?: string;
  description?: string;
}

export interface StoryInterface {
  uid: string;
  sort_order: number;
  enable: number;
  image: string;
  origin_url: string;
  target_url: string;
  use_aff_url: number | boolean;
}

export interface CardInterface {
  uid: string;
  sort_order: number;
  enable: number;
  image: string;
  card_description: string;
  card_label: string;
  card_subtitle: string;
  card_title: string;
  card_url: string;
}

export interface Position {
  id: string | number;
  slug: string;
  title: string;
  enable: number;
  block_list: Block[];
  user_id?: string;
  site_setting_id?: string;
}

export interface SortBlockPayload {
  postion_id: string | number;
  block_orders: { uid: number | string; sort_order }[];
}

export interface AddBlockPayload {
  position_id: string | number;
  block: Block;
}

export interface UpdateBlockPayload {
  index: number;
}

export interface socialLinkInterface {
  uid: string;
  sort_order: number;
  enable: number;
  url: string;
  icon: string;
  link_data?: any;
  use_aff_url: number | boolean;
  open_new_tab?: boolean;
}

export enum CREATOR_ROLE_ENUM {
  ADMIN = 1,
  THEME_CREATOR = 2,
  SELLER = 3,
}

export interface PassioPageProfileInterface {
  id: string | number;
  name: string;
  avatar: string;
  email: string;
  country: string;
  cps_user_id: string;
  eop_user_id: string;
  username: string;
  creator_role: CREATOR_ROLE_ENUM;
  passio_page_onboard_state: number;
  finish_product_tour: number;
  site_id: number;
  domain: string;
  creator_type: CREATOR_TYPE_ENUM;
}

export interface ButtonMultiLinkInterface {
  uid: string;
  sort_order: number;
  enable: number;
  image: string;
  button_link_description: string;
  button_link_label: string;
  button_link_subtitle: string;
  button_link_title: string;
  button_link_url: string;
}

export interface ImageCover {
  sort_order: number;
  enable: number;
  image: string;
  origin_url: string;
  target_url: string;
  use_aff_url: number;
  link_data?: any;
  open_new_tab?: boolean;
}

export enum CREATOR_TYPE_ENUM {
  NORMAL = 0,
  FOURTEEN = 1,
}

export interface CreatorProfileInterface {
  finish_product_tour?: number;
  finish_product_tour_at: Date;
  finish_onboard: number;
  finish_onboard_at: Date;
  user_id: string;
  categories: any;
  selected_template_id: string;
  createdAt: string;
  country: string;
  username: string;
  background: {
    color: string;
    gradient: string;
    type: string;
    color_type: string;
  };
  banner: string;
  image: string;
  name: string;
  slug: string;
  last_publish_at: string;
  id: string;
  categoriesInitial: any;
}

export interface UpdateCreatorProfileInterface {
  finish_product_tour?: FINISH_PRODUCT_TOUR_ENUM;
  finish_onboard?: FINISH_ONBOARD_ENUM;
  categories?: Array<number>;
  selected_template_id?: string;
  banner?: string;
  image?: string;
  name?: string;
}

export enum FINISH_ONBOARD_ENUM {
  TRUE = 1,
  FALSE = 2,
}

export enum FINISH_PRODUCT_TOUR_ENUM {
  TRUE = 1,
  FALSE = 2,
}

export interface BookingProfileInterface {
  finish_product_tour?: number;
  finish_product_tour_at: Date;
  finish_onboard: number;
  finish_onboard_at: Date;
  user_id: string;
  categories: any;
  selected_template_id: string;
  createdAt: string;
  country: string;
  username: string;
  background: {
    color: string;
    gradient: string;
    type: string;
    color_type: string;
  };
  banner: string;
  image: string;
  name: string;
  slug: string;
  last_publish_at: string;
  id: string;
}

export interface WebsiteConfigInterface {
  favicon: string;
  id: string;
  meta_description: string;
  meta_title: string;
  site_title: string;
}
