import { Position } from "@features/main/store/interface";
import { ENABLE_BLOCK, ENABLE_POSITION } from "@share/configs/const";
import React, { useMemo } from "react";
import { Box } from "@features/share/components/Box";
import { POSITION_TYPE } from "@features/block/config/interface";

const ONE_TAB = 1;

interface PositionOptionProps {
  position: Position & {
    block_maximum: number;
    translated_title: string;
    limited_msg: string;
  };
  onSelect: (slug?: string, tabId?: string) => void;
  selected?: {
    position: POSITION_TYPE;
    uid: string;
  };
  extraProps: {
    tab_uid: string;
    position_slug: string;
  };
}

const PositionOption: React.FunctionComponent<PositionOptionProps> = (
  props
) => {
  const { position, onSelect, selected, extraProps } = props;

  const positionTransformed = useMemo(() => {
    let tabLists = position?.block_list[0]?.content_attributes?.tabs || [];
    let countOfBlock =
      position?.slug !== POSITION_TYPE.BODY
        ? position?.block_list?.length || 0
        : tabLists[0]?.block_list?.length || 0;
    let isPositionFull = countOfBlock >= position.block_maximum;
    let displayEnablePosition = position?.enable !== ENABLE_POSITION;
    let style = isPositionFull ? "cursor-not-allowed" : "cursor-pointer ";
    let isHiddenPositon = position?.slug === extraProps?.position_slug;

    let tabs = tabLists?.map((e) => {
      let isTabFull = e?.block_list?.length >= position.block_maximum;
      let displayEnableTab = e?.enable !== ENABLE_POSITION;
      const isHiddenTab = e?.uid === extraProps?.tab_uid;
      return {
        ...e,
        count: e?.block_list?.length,
        max: position.block_maximum,
        disabled: isTabFull,
        message: position.limited_msg || "",
        title: e?.name || "",
        enable: displayEnableTab,
        active: e?.isDefault,
        id: e.uid,
        hidden: isHiddenTab,
      };
    });

    return {
      ...position,
      is_enabled: displayEnablePosition,
      block_count: countOfBlock,
      is_disabled: isPositionFull,
      style: style,
      tabs: tabs,
      is_hidden: isHiddenPositon,
    };
  }, [position, extraProps]);

  if (
    positionTransformed?.tabs &&
    positionTransformed?.tabs?.length <= ONE_TAB
  ) {
    return (
      <Box.SingleBox
        item={{
          id: positionTransformed.slug,
          title: positionTransformed.translated_title,
          count: positionTransformed.block_count,
          max: positionTransformed.block_maximum,
          disabled: positionTransformed.is_disabled,
          message: positionTransformed.limited_msg,
          enable: positionTransformed.is_enabled,
          hidden: positionTransformed.is_hidden,
        }}
        onSelect={onSelect}
        selected={selected}
      />
    );
  }
  return (
    <Box.MultiBox
      item={{
        id: positionTransformed.slug,
        title: positionTransformed.translated_title,
        listBox: positionTransformed.tabs,
      }}
      onSelect={onSelect}
      selected={selected}
    />
  );
};

export default PositionOption;
