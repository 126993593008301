import { StoryInterface } from "@features/main/store/interface";
import {
  CheckCircleIcon,
  InformationIcon,
  InformationTriangleIcon,
  TrashIcon,
} from "@share/icons";
import { mockUpload } from "@share/lib";
import {
  Button,
  Checkbox,
  Divider,
  ImageUploader,
  Input,
  Modal,
  Popover,
  Toast,
} from "antd-mobile";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import FieldTitle from "@share/components/FieldTitle";
import UploadImage from "@share/components/UploadImage";
import FormInput from "@features/block/components/FormInput";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import { checkOpenNewTab } from "@share/helper/validator";

type Props = {
  callBackCancelImageSliderAddNew: any;
  handleSaveImageSlider: any;
  imageEditData: StoryInterface;
  indexImageEdit: number;
};

const StoryNewImage = (props: Props) => {
  const {
    callBackCancelImageSliderAddNew,
    handleSaveImageSlider,
    imageEditData,
  } = props;
  const { t } = useTranslation();

  const UID = `image-${uuidv4()}`;

  const originUrlDefault = imageEditData?.origin_url || null;
  const affiliateUrlDefault = imageEditData?.target_url || null;
  const valueUrlImageDefault = imageEditData && imageEditData.image;
  const defaultTitle = imageEditData?.title;
  const hasAffiliateUrlDefault = imageEditData?.use_aff_url
    ? !!(imageEditData?.use_aff_url === 1)
    : true;
  const defaultLinkData = imageEditData?.link_data || {
    type: LinkTypeEnum.WEB,
    value: originUrlDefault,
  };
  const openInNewTabDefault = imageEditData?.open_new_tab || false;

  const [valueUrlImage, setValueUrlImage] = useState(valueUrlImageDefault);
  const [valueNameImage, setValueNameImage] = useState("");
  const [valueImageBase64, setValueImageBase64] = useState(null);
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [originUrl, setOriginUrl] = useState(originUrlDefault);
  const [affiliateUrl, setAffiliateUrl] = useState(affiliateUrlDefault);
  const [hasAffiliateUrl, setHasAffiliateUrl] = useState(
    hasAffiliateUrlDefault
  );
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isDisableBtnSave, setIsDisableBtnSave] = useState(false);
  const [title, setTitle] = useState(defaultTitle);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);
  const [isValidFileSize, setIsValidFileSize] = useState(false);

  const [linkData, setLinkData] = useState<LinkDataInterface>(defaultLinkData);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);
  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
  };

  const checkEcommerce = (data) => {
    if (data) {
      try {
        const ecommerceOrSocial = new URL(data);
        setIsEcommerce(checkOpenNewTab(ecommerceOrSocial));
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    if (linkData && linkData.type == LinkTypeEnum.WEB) {
      checkEcommerce(linkData.target_url);
    } else if (originUrl) checkEcommerce(originUrl);
  }, [originUrl, linkData]);

  const handleDeleteImage = () => {
    Modal.confirm({
      header: (
        <InformationIcon
          className="w-11 h-11"
          fillColor="#FF8F1F"
          onClick={() => {}}
        />
      ),
      title: <div>{t("ss_builder_confirm-delete_image_title")}</div>,
      content: (
        <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
          {t("ss_builder_confirm-delete_image_label")}
        </div>
      ),
      confirmText: t("ss_builder_delete_image_label"),
      cancelText: t("ss_builder_close_panel"),
      onConfirm: () => {
        setValueUrlImage(null);
        setUploadedImageArr([]);
        Toast.show({
          icon: <CheckCircleIcon className="text-center inline-block" />,
          content: (
            <div className="text-center">
              {t("ss_builder_delete_image_successfully_label")}
            </div>
          ),
        });
      },
      onCancel: () => {
        return;
      },
    });
  };

  const handleImageUrl = (value) => {
    const imgFileItems = value && value[value.length - 1];
    if (imgFileItems?.isValidFormat) {
      setIsValidFileFormat(true);
      setIsValidFileSize(false);
    } else if (imgFileItems?.isValidSize) {
      setIsValidFileFormat(false);
      setIsValidFileSize(true);
    } else {
      setUploadedImageArr(value);
      setValueImageBase64(imgFileItems.dataBase64);
      setValueUrlImage(imgFileItems.url);
      setValueNameImage(imgFileItems.fileName);
    }
  };

  const handleSaveImage = () => {
    const defaultImageData = {
      uid: UID,
      sort_order: 1,
      enable: 1,
      use_aff_url:
        hasAffiliateUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
      image: valueImageBase64 || valueUrlImage,
      image_base64: valueImageBase64,
      title: title,
      origin_url: originUrl,
      target_url: affiliateUrl,
      name: valueNameImage,
      link_data: linkData,
      open_new_tab: isEcommerce ? true : openNewTab,
    };
    if (imageEditData) {
      handleSaveImageSlider({
        ...imageEditData,
        use_aff_url:
          hasAffiliateUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
        image: valueImageBase64 || valueUrlImage,
        image_base64: valueImageBase64,
        title: title,
        origin_url: originUrl,
        target_url: affiliateUrl,
        link_data: linkData,
        open_new_tab: isEcommerce ? true : openNewTab,
      });
    } else {
      handleSaveImageSlider(defaultImageData);
    }
    callBackCancelImageSliderAddNew();
    setValueUrlImage(null);
    setUploadedImageArr([]);
    setValueImageBase64(null);
  };

  const handleCancelImageSliderAddNew = () => {
    if (
      (valueUrlImage && valueUrlImage !== valueUrlImageDefault) ||
      (originUrl && originUrl !== originUrlDefault) ||
      (typeof hasAffiliateUrl === "boolean" &&
        hasAffiliateUrl !== hasAffiliateUrlDefault) ||
      openNewTab !== openInNewTabDefault
    ) {
      Modal.confirm({
        header: (
          <InformationIcon
            className="w-11 h-11"
            fillColor="#FF8F1F"
            onClick={() => {}}
          />
        ),
        title: <div>{t("ss_builder_cancel_edit_block_title")}</div>,
        content: (
          <div className="text-[15px] font-normal leading-[140%] text-textColorDefault text-center">
            {t("ss_builder_cancel_edit_block_content")}
          </div>
        ),
        confirmText: t("ss_builder_continue_edit_block_label"),
        cancelText: t("ss_builder_cancel_block_btn_label"),
        onConfirm: () => {
          return;
        },
        onCancel: () => {
          callBackCancelImageSliderAddNew();
          setValueUrlImage(null);
          setUploadedImageArr([]);
          setValueImageBase64(null);
        },
      });
    } else {
      callBackCancelImageSliderAddNew();
      setValueUrlImage(null);
      setUploadedImageArr([]);
      setValueImageBase64(null);
    }
  };

  const handleCheckAffiliateUrl = (data) => {
    setHasAffiliateUrl(data);
  };
  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    if (linkData.type === LinkTypeEnum.WEB) {
      setOriginUrl(linkData.value);
    } else {
      setOriginUrl(null);
    }
    setLinkData(linkData);
  };

  const isSaveButtonEnable = useMemo(() => {
    return (
      valueUrlImage &&
      (originUrl || linkData.value) &&
      title &&
      (valueUrlImage !== valueUrlImageDefault ||
        originUrl !== originUrlDefault ||
        (typeof hasAffiliateUrl === "boolean" &&
          hasAffiliateUrl !== hasAffiliateUrlDefault) ||
        JSON.stringify(linkData) !== JSON.stringify(defaultLinkData) ||
        title !== defaultTitle ||
        openNewTab !== openInNewTabDefault) &&
      !isValidUrl &&
      !isDisableBtnSave
    );
  }, [
    valueUrlImage,
    valueUrlImageDefault,
    isValidUrl,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    linkData,
    defaultLinkData,
    originUrl,
    originUrlDefault,
    title,
    defaultTitle,
    isDisableBtnSave,
    openNewTab,
    openInNewTabDefault,
  ]);
  useEffect(() => {
    setIsDisableBtnSave(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setIsLoadingBtn(isLoadingBtn);
  }, [isLoadingBtn]);

  const onAffLoading = useCallback(
    (isLoading) => {
      setIsDisableBtnSave(isLoading);
      setIsLoadingBtn(isLoading);
    },
    [setIsDisableBtnSave, setIsLoadingBtn]
  );

  return (
    <div className="z-[2]">
      <div
        className="flex flex-row text-center items-center justify-center py-3 relative bg-white"
        style={{
          boxShadow: "inset 0px -1px 0px #EEEEEE",
        }}
      >
        <div>{title && title != "" ? title : t("ss_builder_story")}</div>
      </div>
      <div className="p-4 bg-white pb-[70px]">
        <FieldTitle
          title={t("ss_builder_image")}
          isRequire={true}
        />
        <UploadImage
          valueUrlImage={valueUrlImage}
          imageRatio="1/1"
        />
        {isValidFileFormat && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_format_invalid_content")}
            </div>
          </div>
        )}
        {isValidFileSize && (
          <div className="flex flex-row gap-1 items-center mt-1">
            <InformationTriangleIcon
              fillColor="#FF3141"
              className="w-4 h-4"
            />
            <div className="text-[#FF3141]">
              {t("ss_builder_upload_file_size_invalid_content")}
            </div>
          </div>
        )}
        <div className="flex gap-2 mt-4">
          <Button
            size="middle"
            color={valueUrlImage ? "primary" : "default"}
            fill="solid"
            className=""
            onClick={valueUrlImage ? handleDeleteImage : () => {}}
          >
            <TrashIcon
              className="w-[17px] h-[17px]"
              fillColor={valueUrlImage ? "#FFFFFF" : "#CCCCCC"}
            />
          </Button>
          <ImageUploader
            onChange={handleImageUrl}
            upload={(file) => mockUpload(file, 160, 160, [], 2)}
            className={`image-slider-upload ${
              uploadedImageArr.length > 0 ? "image-uploaded-hidden" : ""
            }`}
            value={uploadedImageArr}
            // disableUpload={isDisableUploadBtn}
            children={
              <Button
                block
                color="primary"
                fill="solid"
                size="middle"
                className="w-full grow"
                //   onClick={handleSaveBlockText}
                // disabled={isDisableUploadBtn}
              >
                {t("ss_builder_upload_button_label")}
              </Button>
            }
          />
        </div>
        <Divider />
        <FormInput
          label={t("ss_builder_button_link_icon_origin_url_label")}
          isRequire={true}
        >
          <LinkInput
            item={linkData}
            onChange={(item) => {
              onUpdateLinkData(item);
            }}
            onLoading={onAffLoading}
            onError={(errorMessage) => {
              setIsValidUrl(!!errorMessage);
            }}
          />
        </FormInput>
        {linkData.type === LinkTypeEnum.WEB && (
          <>
            <Divider />
            <div className="flex flex-row text-center items-center leading-[22px] gap-1">
              <Checkbox
                checked={
                  typeof hasAffiliateUrl !== "boolean" ? true : hasAffiliateUrl
                }
                onChange={handleCheckAffiliateUrl}
              >
                <div>{t("ss_builder_use_ecomobi_tracking_link")}</div>
              </Checkbox>
              <Popover
                content={t("ss_builder_use_affiliate_link_description")}
                placement="top-start"
                mode="dark"
                trigger="click"
              >
                <InformationIcon
                  className="ml-1 w-[14px] h-[14px]"
                  onClick={() => {}}
                  fillColor="#1890FF"
                />
              </Popover>
            </div>
            {!isEcommerce && (
              <>
                <Divider />
                <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                  <Checkbox
                    checked={!!openNewTab}
                    onChange={handleChangeOpenNewTab}
                  >
                    <div className="text-left">
                      {t("ss_builder_open_new_tab_label")}
                    </div>
                  </Checkbox>
                  <Popover
                    content={t("ss_builder_open_new_tab_description")}
                    placement="top-start"
                    mode="dark"
                    trigger="click"
                  >
                    <InformationIcon
                      className="ml-1 w-[14px] h-[14px]"
                      onClick={() => {}}
                      fillColor="#1890FF"
                    />
                  </Popover>
                </div>
              </>
            )}
          </>
        )}

        <Divider />
        <div>
          <FieldTitle
            title={t("ss_builder_title")}
            isRequire={true}
          />
          <Input
            type="text"
            value={title}
            placeholder={t("ss_builder_story_title_place_holder")}
            onChange={(value) => {
              setTitle(value);
            }}
            maxLength={25}
          />
          <div className="py-1 text-gray-500 text-sm text-right">
            {title ? title.length : 0}/25
          </div>
        </div>
      </div>
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[1] bg-[#FFFFFF]">
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            onClick={handleCancelImageSliderAddNew}
          >
            {t("ss_builder_editor_btn-cancel")}
          </Button>
        </div>
        <div className="w-1/2 text-center">
          <Button
            className="w-full"
            disabled={!isSaveButtonEnable}
            color={"primary"}
            onClick={handleSaveImage}
            loading={isLoadingBtn}
          >
            {t("ss_builder_editor_btn-save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StoryNewImage;
