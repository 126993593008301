export const BOOKING_PROFILE_FIELD = {
  IMAGE: "image",
  TITLE: "booking_profile_title",
  DESCRIPTION: "booking_profile_description",
  BUTTON_CONTENT: "booking_profile_button_content",
  STYLE: "booking_profile_style",
  OPEN_NEW_TAB: "open_new_tab",
  APPLY_BLOCK_SETTINGS: "apply_block_settings",
}

export const BOOKING_PROFILE_STYLE = {
  CARD_CONTENT: "card_content",
  EMBED_CODE: "embed_code",
}