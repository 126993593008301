import DndScrollable from "@share/components/dnd-scrollable";
import { IMAGE_ORIENTATION_TYPE } from "@share/configs/const";
import { Divider, Image, Selector } from "antd-mobile";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const ImageOrientation = (props) => {
  const {
    imageOrientationDefault,
    imageRatioDefault,
    onUpdateImageOrientationValue,
    onUpdateImageRatioValue,
  } = props;
  const { t } = useTranslation();

  const ratioDefault = useMemo(() => {
    let valueRatio = imageRatioDefault || "1/1";
    if (imageOrientationDefault === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [imageRatioDefault, imageOrientationDefault]);

  const [imageOrientation, setImageOrientation] = useState(
    imageOrientationDefault
  );
  const [imageRatio, setImageRatio] = useState(ratioDefault);

  const imageRatioDetection = useMemo(() => {
    let valueRatio = imageRatio;
    if (imageOrientation === IMAGE_ORIENTATION_TYPE.PORTRAIT) {
      const revertRatioArr = valueRatio?.split("/");
      valueRatio = `${revertRatioArr[1]}/${revertRatioArr[0]}`;
    }
    return valueRatio;
  }, [imageOrientation, imageRatio]);

  const imageOptions = [
    {
      label: "1:1",
      value: "1/1",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "4:3" : "3:4",
      value: "4/3",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:2" : "2:3",
      value: "3/2",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "16:9" : "9:16",
      value: "16/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "21:9" : "9:21",
      value: "21/9",
    },
    {
      label:
        imageOrientation === IMAGE_ORIENTATION_TYPE.LANDSCAPE ? "3:1" : "1:3",
      value: "3/1",
    },
  ];

  const imageOrientationOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://i.ecomobi.com/ssp/passio-page/config/landscape.png"
          height={96}
          width={96}
          className="m-auto"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_landscape_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.LANDSCAPE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://i.ecomobi.com/ssp/passio-page/config/portrait.png"
          height={96}
          width={96}
          className="m-auto"
        />
      ),
      description: (
        <div className="mt-1">
          {t("ss_builder_portrait_image_orientation_label")}
        </div>
      ),
      value: IMAGE_ORIENTATION_TYPE.PORTRAIT,
    },
  ];

  useEffect(() => {
    if (imageRatioDetection) {
      onUpdateImageRatioValue(imageRatioDetection);
    }
  }, [imageRatioDetection]);

  useEffect(() => {
    if (imageOrientation) {
      onUpdateImageOrientationValue(imageOrientation);
    }
  }, [imageOrientation]);

  const handleSelectImageRatio = (arr) => {
    if (arr && arr.length) {
      const valueRatio = arr[0];
      setImageRatio(valueRatio);
    }
  };

  const handleSelectImageOrientation = (arr) => {
    if (arr && arr.length) {
      const imageOrientation = arr[0];
      setImageOrientation(imageOrientation);
    }
  };
  return (
    <>
      <div>
        <span className="text-[15px] leading-[13px] text-[#666666]">
          {t("ss_builder_image_orientation_label")}
        </span>
        <DndScrollable className="selector-scroll">
          <Selector
            options={imageOrientationOptions}
            value={[imageOrientation]}
            onChange={handleSelectImageOrientation}
            style={{
              "--border": "solid transparent 1px",
              "--checked-border": "solid var(--adm-color-primary) 1px",
            }}
          />
        </DndScrollable>
      </div>
      <Divider />
      <div>
        <div className="text-[15px] text-[#666666]">
          {t("ss_builder_image_slider_ratio_selected_title")}
        </div>
        <Selector
          options={imageOptions}
          value={[imageRatio]}
          onChange={handleSelectImageRatio}
          columns={4}
          style={{
            "--border": "solid transparent 1px",
            "--checked-border": "solid var(--adm-color-primary) 1px",
          }}
        />
      </div>
    </>
  );
};

export default ImageOrientation;
