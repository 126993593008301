export function isClient() {
  return typeof window !== "undefined";
}

export function isLocal() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === "local";
}

export function isDevelopment() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === "dev";
}

export function isStaging() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === "staging";
}

export function isProduction() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === "prod";
}

export function logError(e, path = null) {
  console.log(e, "//" + path);
}
