export const openInNewBrowser = (url) => {
  const castWindow = window as any;
  if (castWindow.flutter_inappwebview !== undefined) {
    var args = [{ url: url }];
    castWindow.flutter_inappwebview.callHandler("openInBrowser", ...args);
  } else {
    window.open(url);
  }
};

export const copyToClipboard = (value) => {
  const castWindow = window as any;
  const args2 = [{ text: value }];
  if (castWindow.flutter_inappwebview !== undefined) {
    castWindow.flutter_inappwebview.callHandler("copyToClipboard", ...args2);
  } else {
    navigator.clipboard.writeText(value);
  }
};
