import Kol, { LocaleType } from "@features/user/models/Kol";
import CreateAxios from "@share/api/createAxios";
import { dataService } from "@share/configs/rxjs";

export interface ShortenLink {
  code: string;
  error: string;
  id: string;
  link: string;
  link_go: string;
  link_shorten: string;
  shorten_character: string;
  success: boolean;
  tar_get_link: string;
  url: string;
}

const baseApiUrl = process.env.NEXT_PUBLIC_API_URL;

const axios = CreateAxios();

const API_URL = {
  me: baseApiUrl + "/api/account/me",
  genMultiLink: baseApiUrl + "/api/v1/affiliate/generate-link/gen-multi-link",
};

const getLocaleFromCountry = (country: string): LocaleType => {
  if (["EN", "VN", "TH", "ID"].indexOf(country) === -1) {
    return "en";
  }
  switch (country) {
    case "VN":
      return "vi";
    default:
      return country.toLowerCase();
  }
};

interface LinkSubInterface {
  sub1?: string;
  sub2?: string;
  sub3?: string;
  sub4?: string;
  traffic_source?: string;
}

export const genMultiLink = async (
  text,
  subs: LinkSubInterface
): Promise<any> => {
  const res = await axios.post(API_URL.genMultiLink, { link: text, ...subs });
  const data = res?.data?.data;
  return data;
};

export const genLink = async (
  link,
  subs: LinkSubInterface
): Promise<string | null> => {
  const multiLinkResult = await genMultiLink(link, subs);
  const results = multiLinkResult?.results;
  if (results && results[0]) {
    return results[0]?.link_shorten;
  }

  return null;
};
const accountApi = {
  me: async (): Promise<Kol | null> => {
    try {
      const res = await axios.get(API_URL.me);
      if (res.status !== 200 || !res.data) return null;
      const data = res.data.data || {};
      return {
        _id: data._id,
        username: data.username,
        mobile: data.mobile,
        email: data.email,
        fullname: data.fullname,
        traffic_source: data.traffic_source,
        link_traffic_source: data.link_traffic_source,
        ip: data.ip,
        _token: data._token,
        api_token: data.api_token,
        api_token_private: data.api_token_private,
        salt: data.salt,
        password: data.password,
        currency: data.currency,
        country: data.country,
        locale: getLocaleFromCountry(data.country),
        approval: data.approval,
        register_source: data.register_source,
        my_answers: data.my_answers,
        chat_app: data.chat_app,
        skype: data.skype,
        promotion_code: data.promotion_code,
        request_country: data.request_country,
        address: data.address,
        version: data.version,
        offer_promotion_country_name: data.offer_promotion_country_name,
        referral_code: data.referral_code,
        verify_publisher_group: data.verify_publisher_group,
        follower_number: data.follower_number,
        publisher_group: data.publisher_group,
        updated_at: data.updated_at,
        created_at: data.created_at,
        sync_eop_user_status: data.sync_eop_user_status,
        eop_user_id: data.eop_user_id,
        flag: data.flag,
        remember_token: data.remember_token,
        account_type: data.account_type,
        admin_note: data.admin_note,
        api: data.api,
        birthday: data.birthday,
        configs: data.configs,
        default_rank: data.default_rank,
        fixed_rank: data.fixed_rank,
        hide_withdraw: data.hide_withdraw,
        parent_id: data.parent_id,
        payout_pending_percent: data.payout_pending_percent,
        rate_default: data.rate_default,
        rate_pending: data.rate_pending,
        rate_success: data.rate_success,
        domain: data.domain,
        avatar: data.avatar,
      };
    } catch (e) {
      return null;
    }
  },
};
export const genLinkV2 = async (
  link,
  subs: LinkSubInterface
): Promise<ShortenLink | null> => {
  const multiLinkResult = await genMultiLink(link, subs);
  const results = multiLinkResult?.results;
  if (results && results[0]) {
    return results[0];
  }
  return null;
};
export default accountApi;
