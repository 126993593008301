import { KEY_TOKEN, KEY_GATEWAY_TOKEN } from "./../configs/system";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { logError } from "@features/share/helper/system";
import Cookies from "js-cookie";
import { AUTH_PAGE } from "@share/configs/pages";
import * as Sentry from "@sentry/nextjs";

const token = Cookies.get(KEY_TOKEN);
const gatewayToken = Cookies.get(KEY_GATEWAY_TOKEN);

export default function CreateAxios() {
  let instance;
  if (token) {
    instance = axios.create({
      baseURL: process.env.API_URL,
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json'
      },
    });
  } else instance = axios;

  instance.interceptors.request.use(
    function (config: AxiosRequestConfig) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response: AxiosResponse) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
      //return response.data; //neu chi muon lay data
    },
    function (error) {
      Sentry.captureException(error);
      if (error.response.status === 401) {
        logError(error.response, "createAxios");
        Cookies.remove(KEY_TOKEN);
        window.location.href = AUTH_PAGE.login;
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

export function CreateGatewayAxios(): AxiosInstance {
  let instance;
  if (gatewayToken) {
    instance = axios.create({
      baseURL: process.env.NEXT_PUBLIC_GATEWAY_URL,
      headers: {
        Authorization: `Bearer ${gatewayToken}`,
        // 'Content-Type': 'application/json'
      },
    });
  } else instance = axios;

  instance.interceptors.request.use(
    function (config: AxiosRequestConfig) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response: AxiosResponse) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
      //return response.data; //neu chi muon lay data
    },
    function (error) {
      Sentry.captureException(error);
      if (error.response.status === 401) {
        logError(error.response, "createAxios");
        Cookies.remove(KEY_GATEWAY_TOKEN);
        window.location.href = AUTH_PAGE.login;
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

export function CreateAxiosSASS() {
  let instance;
  if (token) {
    instance = axios.create({
      baseURL: process.env.NEXT_PUBLIC_PASSIO_SASS_API_URL,
      // baseURL: "https://dev.saas.passio.eco",
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json'
      },
    });
  } else instance = axios;

  instance.interceptors.request.use(
    function (config: AxiosRequestConfig) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response: AxiosResponse) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
      //return response.data; //neu chi muon lay data
    },
    function (error) {
      if (error.response.status === 401) {
        logError(error.response, "createAxios");
        Cookies.remove(KEY_TOKEN);
        window.location.href = AUTH_PAGE.login;
      }
      return Promise.reject(error);
    }
  );
  return instance;
}
