import React from "react";
import {
  MINI_STORE_BLOCK,
  MINI_STORE_BLOCK_TYPE,
  MINI_STORE_MIDDLEWARE,
} from "@features/mini-store/mini-store.config";
import { requestCreatePostLink, requestUploadImage } from "@share/api";
import AffiliateProductGroup from "./block-editor/AffiliateProductGroup";
import BioComponent from "./block-editor/Bio";
import ButtonLink from "./block-editor/ButtonLink";
import ButtonMultiLinks from "./block-editor/ButtonMultiLinks";
import Card from "./block-editor/Card";
import DigitalProduct from "./block-editor/DigitalProduct";
import Embed from "./block-editor/Embed";
import GiftComponent from "./block-editor/Gift";
import ImageComponent from "./block-editor/Image";
import ImageGallery from "./block-editor/ImageGallery";
// import {
//   VIDEO_SHOUTOUT_BLOCK,
//   VIDEO_SHOUTOUT_BLOCK_TYPE,
//   VIDEO_SHOUTOUT_MIDDLEWARE,
// } from "@features/video-shoutout/config";
import ImagePopup from "./block-editor/ImagePopup";
import ImageSlider from "./block-editor/ImageSlider";
import Story from "./block-editor/Story";
import TabMenu from "./block-editor/TabMenu";
import { highlightStoryMiddleware } from "./middleware/highlight-story";
import { ImageGalleryMiddleware } from "./middleware/ImageGallery";
import TextV2 from "./block-editor/TextV2";
import SpacerAndDivider from "./block-editor/SpacerAndDivider";
import BookingProfile from "./block-editor/BookingProfile";

const Combined = React.lazy(() => import("./block-editor/Combined"));

export const BLOCK_TYPE = {
  TEXT: "text",
  TAB_BAR: "tab_bar",
  TAB: "tabs",
  IMAGE_SLIDER: "image_slider",
  AFF_PRODUCT: "affiliate_product",
  STORY: "story",
  BIO: "bio",
  IMAGE: "image",
  BUTTON_LINK: "button_link",
  GIFT: "gift",
  DIGITAL_PRODUCT: "digital_product",
  // VIDEO_SHOUTOUT: "video_shoutout",
  AFF_PRODUCT_GROUP: "affiliate_product_group",
  IMAGE_GALLERY: "image_gallery",
  EMBED: "embed",
  CARD: "card",
  BUTTON_MULTI_LINK: "button_multi_link",
  IMAGE_POPUP: "image_popup",
  SPACER_AND_DIVIDER: "spacer_and_divider",
  COMBINED: "combined",
  BOOKING_PROFILE: "booking_profile",
  ...MINI_STORE_BLOCK_TYPE,
  // ...VIDEO_SHOUTOUT_BLOCK_TYPE,
};

export const BLOCK_EDIT_COMPONENT_CONFIG = {
  [BLOCK_TYPE.TEXT]: TextV2,
  [BLOCK_TYPE.IMAGE_SLIDER]: ImageSlider,
  [BLOCK_TYPE.TAB_BAR]: TabMenu,
  [BLOCK_TYPE.STORY]: Story,
  [BLOCK_TYPE.BIO]: BioComponent,
  [BLOCK_TYPE.IMAGE]: ImageComponent,
  [BLOCK_TYPE.BUTTON_LINK]: ButtonLink,
  [BLOCK_TYPE.GIFT]: GiftComponent,
  [BLOCK_TYPE.DIGITAL_PRODUCT]: DigitalProduct,
  [BLOCK_TYPE.AFF_PRODUCT_GROUP]: AffiliateProductGroup,
  [BLOCK_TYPE.IMAGE_GALLERY]: ImageGallery,
  [BLOCK_TYPE.EMBED]: Embed,
  [BLOCK_TYPE.CARD]: Card,
  [BLOCK_TYPE.BUTTON_MULTI_LINK]: ButtonMultiLinks,
  [BLOCK_TYPE.IMAGE_POPUP]: ImagePopup,
  [BLOCK_TYPE.SPACER_AND_DIVIDER]: SpacerAndDivider,
  [BLOCK_TYPE.COMBINED]: Combined,
  [BLOCK_TYPE.BOOKING_PROFILE]: BookingProfile,
  ...MINI_STORE_BLOCK,
  // ...VIDEO_SHOUTOUT_BLOCK,
};

export const BLOCK_TYPE_LANGUAGE_KEY = {
  [BLOCK_TYPE.BUTTON_LINK]: "ss_builder_title_block_button_link",
  [BLOCK_TYPE.IMAGE]: "ss_builder_title_block_image",
  [BLOCK_TYPE.TEXT]: "ss_builder_title_block_text",
  [BLOCK_TYPE.BIO]: "ss_builder_title_block_bio",
  [BLOCK_TYPE.STORY]: "ss_builder_title_block_story",
  [BLOCK_TYPE.IMAGE_SLIDER]: "ss_builder_title_block_image_slider",
  [BLOCK_TYPE.BUTTON_MULTI_LINK]: "ss_builder_title_block_button_multi_link",
  [BLOCK_TYPE.CARD]: "ss_builder_title_block_card",
  [BLOCK_TYPE.EMBED]: "ss_builder_title_block_embed",
  [BLOCK_TYPE.IMAGE_GALLERY]: "ss_builder_title_block_image_gallery",
  [BLOCK_TYPE.AFF_PRODUCT_GROUP]: "ss_builder_title_block_affiliate_product",
  [BLOCK_TYPE.GIFT]: "ss_builder_title_block_virtual_gift",
  [BLOCK_TYPE.GIFT_FOOTER]: "ss_builder_title_block_virtual_gift_footer",
  // [BLOCK_TYPE.TRUTH_OR_DARE]: "ss_builder_title_block_truth_or_dare",
  // [BLOCK_TYPE.TRUTH_OR_DARE_FOOTER]:
  //   "ss_builder_title_block_truth_or_dare_footer",
  [BLOCK_TYPE.DONATION]: "ss_builder_title_block_donation",
  [BLOCK_TYPE.DIGITAL_PRODUCT]: "ss_builder_title_block_digital_product",
  [BLOCK_TYPE.BRAND_GIFT]: "ss_builder_title_block_brand_gift",
  [BLOCK_TYPE.BRAND_GIFT_FOOTER]: "ss_brand_gift_setting_title_block_footer",
  [BLOCK_TYPE.TAB_BAR]: "ss_builder_title_block_tab_bar",
  [BLOCK_TYPE.IMAGE_POPUP]: "ss_builder_title_block_image_popup",
  // [BLOCK_TYPE.VIDEO_SHOUTOUT]: "ss_builder_title_block_video_shoutout",
  [BLOCK_TYPE.SPACER_AND_DIVIDER]: "ss_builder_title_spacer_and_divider",
  [BLOCK_TYPE.COMBINED]: "ss_builder_title_combined",
  [BLOCK_TYPE.BRAND_RATING]: "ss_builder_title_block_brand_rating",
  [BLOCK_TYPE.BOOKING_PROFILE]: "ss_builder_title_block_booking_profile"
};

const uploadImageBase64Middleware = async (data, extraData) => {
  if (data) {
    const imageArrItems =
      data.content_attributes && data.content_attributes.items;
    let response = null;
    try {
      const arrRequest =
        imageArrItems &&
        Array.isArray(imageArrItems) &&
        imageArrItems.map(async (ele) => {
          const eleImage = ele?.image;
          const isImageBase64 =
            typeof eleImage == "string" && eleImage.startsWith("data:image");
          if ((ele.image_base64 && ele.image_name) || isImageBase64) {
            let requestContent = {
              ...extraData,
              content: ele.image_base64,
              name: ele.image_name,
            };
            if (isImageBase64) {
              requestContent = {
                ...extraData,
                content: eleImage,
                name: ele?.image_name || "image_name",
              };
            }
            const dataRequest = await requestUploadImage(requestContent);
            return dataRequest;
          } else {
            return { ...ele, image_base64: undefined, image_name: undefined };
          }
        });
      response = await Promise.all(arrRequest);
    } catch (error) {
      throw error;
    }
    if (response && response.every((x) => x !== undefined)) {
      const arrUrl = response.map((ele) => {
        return ele?.url ?? ele.image;
      });

      const newImageArrItems = imageArrItems.map((element, index) => {
        return {
          ...element,
          image_base64: undefined,
          image_name: undefined,
          image: arrUrl[index],
        };
      });
      const newData = {
        ...data,
        content_attributes: {
          ...data.content_attributes,
          items: newImageArrItems,
        },
      };
      return newData;
    }
  }
};

const requestPostLinkMiddleware = async (data, extraData) => {
  if (data) {
    let responsePostLink = null;
    const newDataBlock = await uploadImageBase64Middleware(data, extraData);
    const newProductsList = newDataBlock?.content_attributes?.items;
    if (newProductsList) {
      try {
        const arrRequestPostLink =
          newProductsList &&
          Array.isArray(newProductsList) &&
          newProductsList.map(async (ele) => {
            if (!ele.post_link) {
              const dataRequest = await requestCreatePostLink({
                origin_url: ele.origin_link,
                title: ele.title,
                image: ele.image,
                product: {
                  id: ele.uid,
                  name: ele.title,
                  image: ele.image,
                  source_url: ele.source_url,
                  domain: ele.domain,
                  source_id: ele.source_id,
                  shop_id: ele.shop_id,
                  source_type: ele.source_type,
                },
              });
              return dataRequest;
            } else {
              return ele;
            }
          });
        responsePostLink = await Promise.all(arrRequestPostLink);
      } catch (error) {
        console.log(error);
      }
      if (responsePostLink && responsePostLink.every((x) => x !== undefined)) {
        const arrPostLink = responsePostLink.map((ele) => {
          return !ele.post_link
            ? {
                id: ele.data.id,
                origin_url: ele.data.url,
                target_url: ele.data.target_url,
              }
            : ele.post_link;
        });
        const newProductsItems = newProductsList.map((element, index) => {
          return !element.post_link
            ? {
                uid: element.uid,
                sort_order: element.sort_order,
                image: element.image,
                title: element.title,
                use_aff_url: element.use_aff_url,
                source_type: element.source_type,
                enable: element.enable,
                origin_link: element.origin_link,
                post_link: arrPostLink[index],
              }
            : element;
        });
        const newData = {
          ...data,
          content_attributes: {
            ...data.content_attributes,
            items: newProductsItems,
          },
        };
        return newData;
      }
    }
  }
};

export const requestPostLinkMiddlewareV2 = async (data, extraData) => {
  if (data) {
    const productGroupArr = data?.content_attributes?.items;
    const productGroupItemsArr = await Promise.all(
      productGroupArr?.map(async (ele) => {
        let responsePostLink = null;
        const newDataBlock = await uploadImageBase64Middleware(ele, extraData);
        const newProductsList = newDataBlock?.content_attributes?.items;
        if (newProductsList) {
          const arrRequestPostLink =
            newProductsList &&
            Array.isArray(newProductsList) &&
            newProductsList.map(async (ele) => {
              if (!ele.post_link) {
                let params;
                params = {
                  origin_url: ele.origin_link,
                  title: ele.title,
                  image: ele.image,
                  product: {
                    id: ele.uid,
                    name: ele.title,
                    image: ele.image,
                    source_url: ele.source_url,
                    domain: ele.domain,
                    shortern_link: ele.shortern_link,
                    source_id: ele.source_id,
                    shop_id: ele.shop_id,
                    source_type: ele.source_type,
                  },
                }
                //Api postlink chỉ yêu cầu params is_import_json nên chỉ cần gửi lên is_import_json cho tất cả trường hợp
                if (ele?.is_import_json || ele?.is_import_by_url) {
                  params.is_import_json = true;
                  delete params.product.id;
                }
                const dataRequest = await requestCreatePostLink(params);
                return dataRequest;
              }
              return ele.post_link
            });
          responsePostLink = (await Promise.all(arrRequestPostLink)).filter(
            (item) => !!item
          );
          if (
            responsePostLink &&
            responsePostLink.every((x) => x !== undefined)
          ) {
            const arrPostLink = responsePostLink.map((ele) => {
              // throw "Some item";
              if (ele.post_link) {
                return ele.post_link;
              }
              if (ele?.data) {
                return {
                  id: ele.data.id,
                  origin_url: ele.data.url,
                  target_url: ele.data.target_url,
                };
              }
              return null;
            });
            const newProductsItems = newProductsList
              .map((element, index) => {
                let newProduct;
                newProduct = {
                  uid: element.uid,
                  sort_order: element.sort_order,
                  image: element.image,
                  title: element.title,
                  use_aff_url: element.use_aff_url,
                  source_type: element.source_type,
                  enable: element.enable,
                  origin_link: element.origin_link,
                  shortern_link: element.shortern_link,
                  is_cleanup_lock: element.is_cleanup_lock,
                  label_product: element.label_product,
                  post_link: arrPostLink ? arrPostLink[index] : null,
                  open_new_tab: element?.open_new_tab,
                  cat_id: element.cat_id,
                  createdAt: element.createdAt,
                }
                if (element?.is_import_json) {
                  newProduct.is_import_json = element?.is_import_json;
                  newProduct.id = element?.id || element.uid
                } else if (element?.is_import_by_url) {
                  newProduct.is_import_by_url = element?.is_import_by_url;
                  newProduct.id = element?.id || element.uid
                }
                return !element.post_link ? newProduct : element;
              })
              .filter((item) => !!item.post_link);
            const newData = {
              ...ele,
              content_attributes: {
                ...ele.content_attributes,
                items: newProductsItems,
              },
            };
            return newData;
          }
        }
      })
    ).catch((e) => {
      throw e;
    });
    if (productGroupItemsArr) {
      const newProductGroupData = {
        ...data,
        content_attributes: {
          ...data.content_attributes,
          items: productGroupItemsArr,
        },
      };
      return newProductGroupData;
    }
  } else {
    return data;
  }
};

const BioMiddleware = async (data, extraData) => {
  if (data) {
    const imageData =
      data.content_attributes && data.content_attributes.bio_image_base64;
    const imageName =
      data.content_attributes && data.content_attributes.bio_image_name;

    const listCoverImage = data?.content_attributes?.bio_cover_image_list?.map(
      (item) => ({
        content: item?.image_base64,
        name: item?.image_name,
      })
    );

    const listImageInBio = [
      { content: imageData, name: imageName },
      ...listCoverImage,
    ];
    let response = null;

    try {
      const arrRequest = listImageInBio?.map(async (ele) => {
        if (ele?.content && ele?.name) {
          const dataRequest = await requestUploadImage({
            ...extraData,
            content: ele?.content,
            name: ele?.name,
          });
          return dataRequest;
        } else {
          return ele;
        }
      });
      response = await Promise.all(arrRequest);
    } catch (error) {
      console.log(error);
    }
    if (response) {
      let newData;
      const newListCoverImage =
        data?.content_attributes?.bio_cover_image_list?.map((item, index) => {
          return {
            ...item,
            image_base64: null,
            image: response[index + 1]?.url || item?.image,
          };
        });
      if (imageData && imageName) {
        newData = {
          ...data,
          content_attributes: {
            ...data.content_attributes,
            bio_image_base64: undefined,
            bio_image_name: undefined,
            bio_image: response[0].url,
            bio_cover_image_list: [...newListCoverImage],
          },
        };
      } else {
        newData = {
          ...data,
          content_attributes: {
            ...data.content_attributes,
            bio_image_base64: undefined,
            bio_image_name: undefined,
            bio_cover_image_list: [...newListCoverImage],
          },
        };
      }
      return newData;
    }
  }
};

const ImageMiddleware = async (data, extraData) => {
  if (data) {
    const imageData =
      data.content_attributes && data.content_attributes.image_base64;
    const imageName =
      data.content_attributes && data.content_attributes.image_name;
    let response = null;
    try {
      if (imageData && imageName) {
        response = await requestUploadImage({
          ...extraData,
          content: imageData,
          name: imageName,
        });
      } else {
        return data;
      }
    } catch (error) {
      console.log(error);
    }
    if (response) {
      const imageUrl = response?.url;
      const newData = {
        ...data,
        content_attributes: {
          ...data.content_attributes,
          image_base64: undefined,
          image_name: undefined,
          image: imageUrl,
        },
      };
      return newData;
    }
  }
};

const TabBarMiddleware = async (data, extraData, extraFunction) => {
  if (data) {
    const newTabs = data?.content_attributes?.items;
    if (newTabs) {
      extraFunction?.handleUpdateTabsBody(newTabs);
      data.content_attributes.items = undefined;
    }
    return data;
  }
};

const ImageMiddleWareMulti = async (data, extraData) => {
  if (data) {
    const imageArrItems =
      data.content_attributes && data.content_attributes.items;
    let response = null;
    try {
      const arrRequest =
        imageArrItems &&
        Array.isArray(imageArrItems) &&
        imageArrItems.map(async (ele) => {
          if (
            ele.content_attributes?.image_base64 &&
            ele.content_attributes?.image_name
          ) {
            const dataRequest = await requestUploadImage({
              ...extraData,
              content: ele.content_attributes?.image_base64,
              name: ele.content_attributes?.image_name,
            });
            return dataRequest;
          } else {
            delete ele?.content_attributes?.image_base64;
            delete ele?.content_attributes?.image_name;
            return ele;
          }
        });
      response = await Promise.all(arrRequest);
    } catch (error) {
      console.log(error);
    }
    if (response && response.every((x) => x !== undefined)) {
      const arrUrl = response.map((ele) => {
        return ele?.url ?? ele?.content_attributes?.image;
      });

      const newImageArrItems = imageArrItems.map((element, index) => {
        delete element?.content_attributes?.image_base64;
        delete element?.content_attributes?.image_name;
        return {
          ...element,
          content_attributes: {
            ...element.content_attributes,
            image: arrUrl[index],
            // image_base64: undefined
          },
        };
      });

      const newData = {
        ...data,
        content_attributes: {
          ...data.content_attributes,
          items: newImageArrItems,
        },
      };
      return newData;
    }
  }
};

const CombinedMiddleWare = async (data, extraData, extraFunction) => {
  try {
    if (data) {
      const listBlock = data.content_attributes.blocks || [];
      const middlewareArr = listBlock.map(async (block) => {
        if (BLOCK_DATA_SAVE_MIDDLEWARE[block.block_type]) {
          const response = await BLOCK_DATA_SAVE_MIDDLEWARE[block.block_type](
            block,
            extraData,
            extraFunction
          );
          return response;
        }
        return block;
      });
      const response = await Promise.all(middlewareArr);
      const newData = {
        ...data,
        content_attributes: {
          ...data.content_attributes,
          blocks: response,
        },
      };
      return newData;
    }
  } catch (e) {
    console.log(e);
  }
};

export const BLOCK_DATA_SAVE_MIDDLEWARE = {
  [BLOCK_TYPE.IMAGE_SLIDER]: uploadImageBase64Middleware,
  [BLOCK_TYPE.AFF_PRODUCT_GROUP]: requestPostLinkMiddlewareV2,
  [BLOCK_TYPE.AFF_PRODUCT]: requestPostLinkMiddleware,
  [BLOCK_TYPE.STORY]: highlightStoryMiddleware,
  [BLOCK_TYPE.BIO]: BioMiddleware,
  [BLOCK_TYPE.IMAGE]: ImageMiddleware,
  [BLOCK_TYPE.IMAGE_GALLERY]: ImageGalleryMiddleware,
  [BLOCK_TYPE.CARD]: uploadImageBase64Middleware,
  [BLOCK_TYPE.BUTTON_LINK]: ImageMiddleware,
  [BLOCK_TYPE.TAB_BAR]: TabBarMiddleware,
  [BLOCK_TYPE.BUTTON_MULTI_LINK]: ImageMiddleWareMulti,
  [BLOCK_TYPE.IMAGE_POPUP]: uploadImageBase64Middleware,
  [BLOCK_TYPE.SPACER_AND_DIVIDER]: ImageMiddleware,
  [BLOCK_TYPE.COMBINED]: CombinedMiddleWare,
  [BLOCK_TYPE.BOOKING_PROFILE]: ImageMiddleware,
  ...MINI_STORE_MIDDLEWARE,
  // ...VIDEO_SHOUTOUT_MIDDLEWARE,
};
