import { DISABLE_BLOCK, ENABLE_BLOCK } from "@share/configs/const";
import { ImagePopup } from "./ImagePopupForm";

export enum IMAGE_POPUP_ACTION {
  SORT_LIST = "SORT_LIST",
  REMOVE_ITEM = "REMOVE_ITEM",
  SWITCH_STATUS = "SWITCH_STATUS",
  SAVE_ITEM = "SAVE_ITEM",
}

const saveItem = (state: Array<ImagePopup>, action: any) => {
  let newState = [...state];
  const isNotExist = Object.is(
    newState.findIndex((e) => Object.is(e.uid, action.payload.uid)),
    -1
  );

  if (isNotExist) {
    newState.push({ ...action.payload, sort_order: newState.length });
  } else {
    newState = newState.map((e) =>
      Object.is(e.uid, action.payload.uid) ? { ...action.payload } : e
    );
  }
  return newState;
};

const removeItem = (state: Array<ImagePopup>, action: any) => {
  let newState = [...state];
  newState = newState
    .filter((e) => e.uid !== action.payload.uid)
    .map((e, index) => ({ ...e, sort_order: index + 1 }));
  return newState;
};

const sortItem = (state: Array<ImagePopup>, action: any) => {
  let newState = [...state];
  newState = action ? [...action.payload] : null;
  return newState;
};

const switchStatusItem = (state: Array<ImagePopup>, action: any) => {
  let newState = [...state];
  newState = newState.map((e) =>
    !Object.is(e.uid, action.payload.uid)
      ? { ...e }
      : {
          ...e,
          enable: Object.is(e.enable, ENABLE_BLOCK)
            ? DISABLE_BLOCK
            : ENABLE_BLOCK,
        }
  );
  return newState;
};

export const listImgPopupReducer = (
  state: ImagePopup[],
  action: {
    type: IMAGE_POPUP_ACTION;
    payload: any;
  }
) => {
  const imagePopupAction = {
    [IMAGE_POPUP_ACTION.SAVE_ITEM]: saveItem,
    [IMAGE_POPUP_ACTION.REMOVE_ITEM]: removeItem,
    [IMAGE_POPUP_ACTION.SORT_LIST]: sortItem,
    [IMAGE_POPUP_ACTION.SWITCH_STATUS]: switchStatusItem,
  };
  const changeImagePopupList = (action: IMAGE_POPUP_ACTION) => {
    return imagePopupAction[action];
  };
  return changeImagePopupList(action.type)(state, action);
};
