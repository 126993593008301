import { put, select, takeLatest } from "redux-saga/effects";
import { ActionInterface } from "@app/store";
import { generateSavingProcessId } from "./saving-item";
import { setDisplaySetting } from "../siteSettingSlice";
import { saveDiplaySetting } from "@features/main/api/display-setting";
import { pushSavingItem } from "../savingItemSlice";

function* siteSettingSaga() {
  yield takeLatest(setDisplaySetting.type, function* (action: ActionInterface) {
    const { payload } = action;
    const siteSetting = yield select((state) => state.siteSettings);
    const savingProcessId = generateSavingProcessId("change-display-setting");
    yield put(
      pushSavingItem({
        item: {
          id: savingProcessId,
          executer: saveDiplaySetting,
          getArguments: (baseData) => {
            return [siteSetting.id, baseData, payload.data];
          },
        },
      })
    );
  });
}

export default siteSettingSaga;
