import { createSlice } from "@reduxjs/toolkit";
import {
  BookingProfileInterface,
} from "./interface";

const initialState: BookingProfileInterface = {
  finish_product_tour: null,
  finish_product_tour_at: null,
  finish_onboard: null,
  finish_onboard_at: null,
  user_id: null,
  categories: [],
  selected_template_id: null,
  createdAt: null,
  country: null,
  username: null,
  background: {
    color: null,
    gradient: null,
    type: null,
    color_type: null,
  },
  banner: null,
  image: null,
  name: null,
  slug: null,
  last_publish_at: null,
  id: null,
};

export const bookingProfileSlice = createSlice({
  name: "bookingProfile",
  initialState,
  reducers: {
    setBookingProfile: (state, { payload: { data } }) => {
      return { ...state, ...data };
    },
  },
});

export const { setBookingProfile } = bookingProfileSlice.actions;
export default bookingProfileSlice.reducer;
