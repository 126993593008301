import {
  AddIcon,
  GlobalIcon,
  IconDefault,
  IconIsChecked,
  IconIsNotChecked,
  InformationIcon,
} from "@share/icons";
import { Button, Checkbox, Divider, Input, Popover } from "antd-mobile";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import SocialLinkSelectedIcon from "../SocialLinkSelectedIcon";
import {
  BehanceIcon,
  DiscordIcon,
  DribbleIcon,
  EmailIcon,
  FacebookIcon,
  GeneralLinkIcon,
  InstagramIcon,
  Lemon8Icon,
  LineChatIcon,
  LinkedInIcon,
  MessengerIcon,
  PinterestIcon,
  SnapchatIcon,
  TelIcon,
  TiktokIcon,
  TwitchIcon,
  TwitterIcon,
  WhatsappIcon,
  YoutubeIcon,
} from "@share/icons/social-network-icon-svg";
import { socialLinkInterface } from "@features/main/store/interface";
import { validURL } from "@share/lib";
import PopupComponent from "@share/components/PopupComponent";
import ButtonConfirmPopup from "@share/components/ButtonConfirmPopup";
import FormInput from "@features/block/components/FormInput";
import LinkInput, {
  LinkDataInterface,
  LinkTypeEnum,
} from "@features/block/components/LinkInput";
import { SOCIAL_LINK } from "@share/configs/const";
import { checkOpenNewTab } from "@share/helper/validator";

type Props = {
  callbackCancelSocialLinkAddNew: any;
  callbackConfirmSocialLinkAddNew: any;
  socialLinkEditData: socialLinkInterface;
};

const socialLinkAddNew = (props: Props) => {
  const {
    callbackCancelSocialLinkAddNew,
    callbackConfirmSocialLinkAddNew,
    socialLinkEditData,
  } = props;

  const valueLinkDefault = socialLinkEditData?.url;
  const valueIconDefault = socialLinkEditData?.icon;
  const defaultLinkData = socialLinkEditData?.link_data || {
    type: LinkTypeEnum.WEB,
    value: valueLinkDefault,
  };
  const UID = `social-network-${uuidv4()}`;
  const { t } = useTranslation();
  const hasAffiliateUrlDefault = socialLinkEditData?.use_aff_url
    ? !!(socialLinkEditData?.use_aff_url === 1)
    : false;
  const openInNewTabDefault = socialLinkEditData?.open_new_tab;

  const [valueLink, setValueLink] = useState(valueLinkDefault);
  const [valueIcon, setValueIcon] = useState(valueIconDefault);
  const [isDisableBtnSave, setIsDisableBtnSave] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isOpenSelectedSocialLinkIcon, setIsOpenSelectedSocialLinkIcon] =
    useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [hasAffiliateUrl, setHasAffiliateUrl] = useState(
    hasAffiliateUrlDefault
  );
  const [linkData, setLinkData] = useState<LinkDataInterface>(defaultLinkData);

  const [isEcommerce, setIsEcommerce] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(openInNewTabDefault);

  const handleCheckAffiliateUrl = (data) => {
    setHasAffiliateUrl(data);
  };

  const checkEcomerce = (data) => {
    if (data) {
      try {
        const ecommerceOrSocial = new URL(data);
        setIsEcommerce(checkOpenNewTab(ecommerceOrSocial));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleChangeOpenNewTab = (data) => {
    setOpenNewTab(data);
  };
  const handleConfirmAddNewTab = () => {
    const defaultSocialLinkData = {
      uid: UID,
      url: valueLink,
      icon: valueIcon,
      sort_order: 1,
      enable: 1,
      // use_aff_url:
      //   hasAffiliateUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
      link_data: linkData,
      open_new_tab: isEcommerce ? true : openNewTab,
    };
    if (socialLinkEditData) {
      callbackConfirmSocialLinkAddNew({
        ...socialLinkEditData,
        uid: socialLinkEditData?.uid || UID,
        url: valueLink,
        icon: valueIcon,
        // use_aff_url:
        //   hasAffiliateUrl && linkData.type === LinkTypeEnum.WEB ? 1 : 2,
        link_data: linkData,
        open_new_tab: isEcommerce ? true : openNewTab,
      });
    } else callbackConfirmSocialLinkAddNew(defaultSocialLinkData);
    handleCancelSocialLinkAddNew();
  };
  const handleCancelSocialLinkAddNew = () => {
    callbackCancelSocialLinkAddNew();
  };

  const handleOpenSelectedSocialLinkIcon = () => {
    setIsOpenSelectedSocialLinkIcon(true);
  };

  const isEnableConfirmAddNewsocialLink = useMemo(() => {
    if (
      (valueLink || linkData.value) &&
      !isDisableBtnSave &&
      valueIcon &&
      !isValidUrl &&
      (valueIcon !== valueIconDefault ||
        valueLink !== valueLinkDefault ||
        JSON.stringify(linkData) !== JSON.stringify(defaultLinkData) ||
        openNewTab !== openInNewTabDefault)
      // || (typeof hasAffiliateUrl === "boolean" &&
      //   hasAffiliateUrl !== hasAffiliateUrlDefault)
    ) {
      return true;
    }
    return false;
  }, [
    valueLink,
    valueIcon,
    valueIconDefault,
    valueLinkDefault,
    isValidUrl,
    isDisableBtnSave,
    defaultLinkData,
    linkData,
    hasAffiliateUrl,
    hasAffiliateUrlDefault,
    openNewTab,
    openInNewTabDefault,
  ]);

  const handleSelectedSocialLinkIcon = (socialLinkIconType) => {
    setValueIcon(socialLinkIconType);
  };

  const handleCancelSelectedSocialLinkIcon = () => {
    setIsOpenSelectedSocialLinkIcon(false);
  };

  const handleChangeOriginUrl = (value) => {
    const isCheckUrl = validURL(value);
    if (isCheckUrl) {
      setIsValidUrl(false);
    } else {
      setIsValidUrl(true);
    }
  };

  useEffect(() => {
    if (linkData && linkData.type == LinkTypeEnum.WEB) {
      checkEcomerce(linkData.value);
    } else if (socialLinkEditData?.url) checkEcomerce(socialLinkEditData?.url);
  }, [socialLinkEditData?.url, linkData]);

  const SocialLinkIconRender = (data) => {
    const type = data.valueIcon;
    let element = null;
    switch (type) {
      case SOCIAL_LINK.FACEBOOK:
        element = <FacebookIcon />;
        break;
      case SOCIAL_LINK.MESSENGER:
        element = <MessengerIcon />;
        break;
      case SOCIAL_LINK.INSTAGRAM:
        element = <InstagramIcon />;
        break;
      case SOCIAL_LINK.LINKEDIN:
        element = <LinkedInIcon />;
        break;
      case SOCIAL_LINK.SNAPCHAT:
        element = <SnapchatIcon />;
        break;
      case SOCIAL_LINK.LINECHAT:
        element = <LineChatIcon />;
        break;
      case SOCIAL_LINK.YOUTUBE:
        element = <YoutubeIcon />;
        break;
      case SOCIAL_LINK.TIKTOK:
        element = <TiktokIcon />;
        break;
      case SOCIAL_LINK.TWITCH:
        element = <TwitchIcon />;
        break;
      case SOCIAL_LINK.TWITTER:
        element = <TwitterIcon />;
        break;
      case SOCIAL_LINK.WHATSAPP:
        element = <WhatsappIcon />;
        break;
      case SOCIAL_LINK.DISCORD:
        element = <DiscordIcon />;
        break;
      case SOCIAL_LINK.BEHANCE:
        element = <BehanceIcon />;
        break;
      case SOCIAL_LINK.DRIBBLE:
        element = <DribbleIcon />;
        break;
      case SOCIAL_LINK.PINTEREST:
        element = <PinterestIcon />;
        break;
      case SOCIAL_LINK.EMAIL:
        element = <EmailIcon />;
        break;
      case SOCIAL_LINK.TEL:
        element = <TelIcon />;
        break;
      case SOCIAL_LINK.LEMON8:
        element = <Lemon8Icon />;
        break;
      case SOCIAL_LINK.OTHER:
        element = <GeneralLinkIcon />;
        break;
      default:
        element = (
          <IconDefault
            className=""
            fillColor="#333"
            width={24}
            height={24}
          />
        );
    }
    return element;
  };
  const onUpdateLinkData = (linkData: LinkDataInterface) => {
    if (linkData.type === LinkTypeEnum.WEB) {
      setValueLink(linkData.value);
    } else {
      setValueLink(null);
    }
    setLinkData(linkData);
  };
  useEffect(() => {
    setIsDisableBtnSave(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setIsLoadingBtn(isLoadingBtn);
  }, [isLoadingBtn]);

  const onAffLoading = useCallback(
    (isLoading) => {
      setIsDisableBtnSave(isLoading);
      setIsLoadingBtn(isLoading);
    },
    [setIsDisableBtnSave, setIsLoadingBtn]
  );

  return (
    <>
      <div>
        <div
          className="px-4 py-3"
          style={{ borderTop: "1px solid #EEEEEE" }}
        >
          <FormInput
            label={t("ss_builder_button_link_icon_origin_url_label")}
            isRequire={true}
          >
            <LinkInput
              item={linkData}
              onChange={(item) => {
                onUpdateLinkData(item);
              }}
              onLoading={onAffLoading}
              onError={(errorMessage) => {
                setIsValidUrl(!!errorMessage);
              }}
              getPopupRef={() => null}
              isHiddenService={true}
            />
          </FormInput>
          <Divider />
          <div className="mb-1">
            <span className="text-red-600">*</span>
            {t("ss_builder_bio_social_link_icon_title")}
          </div>
          <div className="flex flex-row items-center gap-4">
            <Button
              style={{
                backgroundColor: "#F0F0F0",
                border: "none",
              }}
              onClick={handleOpenSelectedSocialLinkIcon}
            >
              <SocialLinkIconRender valueIcon={valueIcon} />
            </Button>
            <div className="flex flex-row items-center gap-2 grow">
              <Button
                block
                size="middle"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={handleOpenSelectedSocialLinkIcon}
              >
                <div className="text-[17px] leading-6">
                  {t("ss_builder_bio_social_link_btn_select_icon_label")}
                </div>
              </Button>
            </div>
          </div>
          {linkData.type === LinkTypeEnum.WEB && !isEcommerce && (
            <>
              <Divider />
              <div className="flex flex-row text-center items-center leading-[22px] gap-1">
                <Checkbox
                  checked={!!openNewTab}
                  onChange={handleChangeOpenNewTab}
                  icon={(checked) =>
                    checked ? <IconIsChecked /> : <IconIsNotChecked />
                  }
                >
                  <div className="text-left">
                    {t("ss_builder_open_new_tab_label")}
                  </div>
                </Checkbox>
                <Popover
                  content={t("ss_builder_open_new_tab_description")}
                  placement="top-start"
                  mode="dark"
                  trigger="click"
                >
                  <InformationIcon
                    className="ml-1 w-[14px] h-[14px]"
                    onClick={() => {}}
                    fillColor="#1890FF"
                  />
                </Popover>
              </div>
            </>
          )}
        </div>
        <ButtonConfirmPopup
          onConfirm={
            isEnableConfirmAddNewsocialLink ? handleConfirmAddNewTab : () => {}
          }
          isDisable={!isEnableConfirmAddNewsocialLink}
          loading={isLoadingBtn}
        />
      </div>

      <PopupComponent
        visible={isOpenSelectedSocialLinkIcon}
        onMaskClick={() => handleCancelSelectedSocialLinkIcon()}
        title={t("ss_builder_panel_add_icon_tab_title")}
        onClose={() => handleCancelSelectedSocialLinkIcon()}
        classNameBody="h-[60vh] popup-hard popup-hard-full"
      >
        <SocialLinkSelectedIcon
          callbackCancelSelectedSocialLinkIcon={
            handleCancelSelectedSocialLinkIcon
          }
          callbackConfirmSelectedSocialLinkIcon={handleSelectedSocialLinkIcon}
          valueIconDefault={valueIcon}
        />
      </PopupComponent>
    </>
  );
};

export default socialLinkAddNew;
