import React from "react";
import ItemWithCheckbox from "./ItemWithCheckbox";

export interface ItemUIProps {
  id: string;
  checked?: boolean;
  [key: string]: any;
}

interface ListItemWithCheckboxProps {
  propertyIndex: string;
  defaultItems: Array<ItemUIProps>;
  item: React.FunctionComponent<ItemUIProps>;
  onSelect: (list: Array<ItemUIProps>) => void;
}

const ListItemWithCheckbox: React.FunctionComponent<
  ListItemWithCheckboxProps
> = (props) => {
  const { defaultItems, item: Item, onSelect, propertyIndex } = props;

  const onChange = (list: Array<ItemUIProps>) => {
    onSelect(list?.filter((e) => e.checked));
  };

  const onChecked = (id: string, value: boolean) => {
    const newList = defaultItems?.map((item) => ({
      ...item,
      checked: item[propertyIndex] === id ? value : item.checked,
    }));
    onChange(newList);
  };

  return (
    <>
      {defaultItems?.map((item) => (
        <ItemWithCheckbox
          key={item[propertyIndex]}
          data={item}
          itemUI={Item}
          checked={!!item?.checked}
          onChecked={(value) => onChecked(item[propertyIndex], value)}
        />
      ))}
    </>
  );
};

export default ListItemWithCheckbox;
