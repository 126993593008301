import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "@app/hooks";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import { useTranslation } from "react-i18next";
import ContentModal from "./ContentModal";
import { DotLoading, Modal } from "antd-mobile";
import { useGetJsonFileURL } from "./hooks/useGetJsonFileURL";
import { useGetProductsFromJsonFileURL } from "./hooks/useGetProductsFromJsonFileURL";
import {
  AT_URL_REGEX,
  DOMAIN_REGEX,
  MSHAKE_URL_REGEX,
  SHOPEE_AFF_URL_REGEX,
} from "./constants";
import { useGetProductsFromShopeeAff } from "./hooks/useGetProductsFromShopeeAff";
import { STATUS } from "@share/configs/const";
import { useGetProductLinksFromMshake } from "./hooks/useGetProductLinksFromMshake";
import {
  validateATURL,
  validateLinkTreeURL,
  validateMshakeURL,
  validateShopeeAffURL,
} from "./libs";
import { useGetProductLinksFromLinkTree } from "./hooks/useGetProductLinksFromLinkTree";

const ProductUploadFromSiteUrl = ({ onUpdateData }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [valueURL, setValueURL] = useState("");
  const [extraCommissionValue, setExtraCommissionValue] = useState(true);

  const handleConfirm = async () => {
    const use_aff_url = extraCommissionValue ? STATUS.ENABLE : STATUS.DISABLE;
    if (newATData) {
      onUpdateData({
        ...newATData,
        atDomain: atDomain?.replace("https", "http"),
        use_aff_url,
      });
    } else if (shopeeAffData) {
      onUpdateData({
        ...shopeeAffData,
        urlSuffix: urlSuffix,
        use_aff_url,
      });
    } else if (mshakeProductLinks) {
      onUpdateData({
        productLinks: mshakeProductLinks,
        use_aff_url,
      });
    } else if (linktreeProductLinks) {
      onUpdateData({
        productLinks: linktreeProductLinks,
        use_aff_url,
      });
    }
    handleCancel();
  };

  const handleCancel = () => {
    dispatch(
      setDynamicParams({
        data: { isUploadProductFromSiteUrl: false },
      })
    );
  };

  const handleChange = (value) => {
    const valueTrim = value.trim();
    setValueURL(valueTrim);
  };

  const atDomain = valueURL?.match(DOMAIN_REGEX)
    ? valueURL?.match(DOMAIN_REGEX)[0]
    : null;

  const isInvalidATURL = validateATURL(valueURL);
  const isInvalidShopeeAffURL = validateShopeeAffURL(valueURL);
  const isInvalidMshakeURL = validateMshakeURL(valueURL);
  const isInvalidLinkTreeURL = validateLinkTreeURL(valueURL);

  //Lấy data từ AT URL
  const {
    urlValue: jsonFileURL,
    isLoading: getJsonFileUrlLoading,
    categories: atCategories,
    isError: isGetJsonFileURLError,
  } = useGetJsonFileURL(valueURL, isInvalidATURL);
  const {
    data: ATData,
    isLoading: getDataFromJsonFileURLLoading,
    isError: isGetProductsFromJsonFileURLError,
  } = useGetProductsFromJsonFileURL(jsonFileURL);
  const newATData = useMemo(() => {
    const categories = ATData?.categories || [];
    const newCategories = categories.map((category) => {
      let categoryName = category.name;
      if (atCategories?.hasOwnProperty(categoryName)) {
        categoryName = atCategories[categoryName];
      }
      return {
        ...category,
        name: categoryName,
      };
    });
    if (newCategories?.length > 0) {
      return {
        ...ATData,
        categories: newCategories,
      };
    }
    return ATData;
  }, [ATData, atCategories]);

  //Lấy data từ Shopee Afiliate
  const {
    data: shopeeAffData,
    urlSuffix,
    isLoading: getDataFromShopeeAffLoading,
    isError: isGetProductsFromShopeeAffError,
  } = useGetProductsFromShopeeAff(valueURL, isInvalidShopeeAffURL);

  //Lấy danh sách đường dẫn sản phẩm từ Mshake
  const {
    isLoading: getDataFromMshakeLoading,
    links: mshakeProductLinks,
    isError: isGetProductsFromMshakeError,
  } = useGetProductLinksFromMshake(valueURL, isInvalidMshakeURL);

  //Lấy danh sách đường dẫn sản phẩm từ Link Tree
  const {
    isLoading: getDataFromLinkTreeLoading,
    links: linktreeProductLinks,
    isError: isGetProductsFromLinkTreeError,
  } = useGetProductLinksFromLinkTree(valueURL, isInvalidLinkTreeURL);

  const isLoadingBtn =
    getJsonFileUrlLoading ||
    getDataFromJsonFileURLLoading ||
    getDataFromShopeeAffLoading ||
    getDataFromMshakeLoading ||
    getDataFromLinkTreeLoading;

  const errorMsg = useMemo(() => {
    let errorMsg = "";
    if (valueURL) {
      if (
        isInvalidATURL &&
        isInvalidShopeeAffURL &&
        isInvalidMshakeURL &&
        isInvalidLinkTreeURL
      ) {
        errorMsg = t("ss_builder_invalid_url");
      } else if (
        isGetJsonFileURLError ||
        isGetProductsFromJsonFileURLError ||
        isGetProductsFromShopeeAffError ||
        isGetProductsFromMshakeError ||
        isGetProductsFromLinkTreeError
      ) {
        errorMsg = t("ss_builder_url_error_content");
      }
    }
    return errorMsg;
  }, [
    valueURL,
    isInvalidATURL,
    isInvalidShopeeAffURL,
    isInvalidMshakeURL,
    isInvalidLinkTreeURL,
    isGetJsonFileURLError,
    isGetProductsFromJsonFileURLError,
    isGetProductsFromShopeeAffError,
    isGetProductsFromMshakeError,
    isGetProductsFromLinkTreeError,
    t,
  ]);

  const isDisableBtn =
    (isInvalidATURL &&
      isInvalidShopeeAffURL &&
      isInvalidMshakeURL &&
      isInvalidLinkTreeURL) ||
    isLoadingBtn;

  return (
    <>
      <Modal
        visible={true}
        // closeOnMaskClick={true}
        // showCloseButton={true}
        title={t("ss_builder_import_site_url_modal_title")}
        content={
          <ContentModal
            onChange={handleChange}
            errorMsg={errorMsg}
            value={valueURL}
            onCheckExtraCommission={(val) => setExtraCommissionValue(val)}
            extraCommissionCheck={extraCommissionValue}
          />
        }
        closeOnAction
        actions={[
          {
            key: "confirm",
            text: isLoadingBtn ? (
              <DotLoading color="white" />
            ) : (
              t("ss_builder_import_site_url_modal_button_label")
            ),
            // text: t("ss_builder_import_site_url_modal_button_label"),
            primary: true,
            onClick: () => handleConfirm(),
            disabled: isDisableBtn,
            style: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
          {
            key: "cancel",
            text: t("ss_builder_cancel"),
            onClick: () => handleCancel(),
          },
        ]}
      />
    </>
  );
};

export default ProductUploadFromSiteUrl;
