import { useEffect, useState } from "react";
import { requestGetDataFromShopeeAff } from "../api";

export const useGetProductsFromShopeeAff = (url: string, isInvalidURL:  boolean) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const parts = url.split("/");
  const urlSuffix = parts[parts.length - 1];

  useEffect(() => {
    const params = {
      urlSuffix,
    };
    const fetchData = async () => {
      let response = null;
      try {
        response = await requestGetDataFromShopeeAff(params);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
        setIsError(true);
      }
      if (response?.success) {
        setData(response.data);
        setIsLoading(false);
        setIsError(false);
      }
    };
    if (url && !isInvalidURL) {
      setIsLoading(true);
      fetchData();
    }
  }, [url]);

  return { data, urlSuffix, isLoading, isError };
};
