import { useEffect, useMemo, useState } from "react";
import { requestGetDataFromUrl } from "../api";

export const useGetProductsFromJsonFileURL = (url: string) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  
  useEffect(() => {
    const fetchData = async (url: string) => {
      let response = null;
      try {
        response = await requestGetDataFromUrl(url);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
        setIsError(true);
      }
      if (response?.success) {
        setData(response.data);
        setIsLoading(false);
        setIsError(false);
      }
    };
    if (url) {
      setIsLoading(true);
      fetchData(url);
    }
  }, [url]);

  return { data, isLoading, isError };
};
