import { createSlice } from "@reduxjs/toolkit";
import { TAB_ICON_DEFAULT } from "@share/configs/const";
export interface PendingProcessDataInterface {
  id: string;
  data: any;
}
const initialState = {
  blocks: {
    KEY: "",
    indexBlock: 0,
    type: "",
    listBlocksData: null,
    positionSlug: "",
    tabUid: "",
  },
  tabs: {
    isOpenTabFloatingPanel: false,
    defaultNameTab: "",
    defaultNameTabIcon: TAB_ICON_DEFAULT,
    KEY: "",
    tabListBody: null,
    indexTab: 0,
    activeKeyTab: "",
    urlIcon: "",
    positionTabMenu: "",
  },
  themeSettings: {
    primaryColor: "",
    fontFamily: "",
    imageUrl: "",
    gradientColor: "",
    solidColor: "",
    typeBackground: "",
    recentlyColor: [],
  },
  affiliateSettingTour: {
    hiddenSteps: [],
  },
  pendingProcessData: [] as PendingProcessDataInterface[],
  isEditContentPage: false,
  isOpenFloatingPanel: false,
  isErrorNetwork: false,
  isSaving: false,
  isError: false,
  isRetry: false,
  isEnableHeaderDynamic: false,
  isSavingProductTour: false,
  isOpenProductTourDynamic: false,
  isOpenAffiliateSettingTour: false,
  isOpenAffiliateGroupTour: false,
  isOpenAffiliateAddNewTour: false,
  isMarginSettingTour: false,
  isMarginBackground: false,
  isUploadProductFile: false,
  isUploadProductFromSiteUrl: false,
};

export const dynamicSlice = createSlice({
  name: "dynamicConfig",
  initialState,
  reducers: {
    setDynamicParams: (state, { payload: { data } }) => {
      if (typeof data.isEditContentPage === "boolean") {
        state.isEditContentPage = data.isEditContentPage;
      }
      if (typeof data.isOpenFloatingPanel === "boolean") {
        state.isOpenFloatingPanel = data.isOpenFloatingPanel;
      }
      if (typeof data.isErrorNetwork === "boolean") {
        state.isErrorNetwork = data.isErrorNetwork;
      }
      if (typeof data.isSaving === "boolean") {
        state.isSaving = data.isSaving;
      }
      if (typeof data.isError === "boolean") {
        state.isError = data.isError;
      }
      if (typeof data.isRetry === "boolean") {
        state.isRetry = data.isRetry;
      }
      if (typeof data.isSavingProductTour === "boolean") {
        state.isSavingProductTour = data.isSavingProductTour;
      }
      if (typeof data.isEnableHeaderDynamic === "boolean") {
        state.isEnableHeaderDynamic = data.isEnableHeaderDynamic;
      }
      if (typeof data.isOpenProductTourDynamic === "boolean") {
        state.isOpenProductTourDynamic = data.isOpenProductTourDynamic;
      }
      if (typeof data.isOpenAffiliateSettingTour === "boolean") {
        state.isOpenAffiliateSettingTour = data.isOpenAffiliateSettingTour;
      }
      if (typeof data.isOpenAffiliateGroupTour === "boolean") {
        state.isOpenAffiliateGroupTour = data.isOpenAffiliateGroupTour;
      }
      if (typeof data.isOpenAffiliateAddNewTour === "boolean") {
        state.isOpenAffiliateAddNewTour = data.isOpenAffiliateAddNewTour;
      }
      if (typeof data.isMarginSettingTour === "boolean") {
        state.isMarginSettingTour = data.isMarginSettingTour;
      }
      if (typeof data.isMarginBackground === "boolean") {
        state.isMarginBackground = data.isMarginBackground;
      }
      if (typeof data.isUploadProductFile === "boolean") {
        state.isUploadProductFile = data.isUploadProductFile;
      }
      if (typeof data.isUploadProductFromSiteUrl === "boolean") {
        state.isUploadProductFromSiteUrl = data.isUploadProductFromSiteUrl;
      }
    },
    setParamsBlock: (state, { payload: { data } }) => {
      state.blocks = {
        ...state.blocks,
        ...data,
      };
    },
    setParamsTab: (state, { payload: { data } }) => {
      state.tabs = {
        ...state.tabs,
        ...data,
      };
    },
    setParamsThemeSetting: (state, { payload: { data } }) => {
      state.themeSettings = {
        ...state.themeSettings,
        ...data,
      };
    },
    setParamsAffiliateProductTours: (state, { payload: { data } }) => {
      state.affiliateSettingTour = {
        ...state.affiliateSettingTour,
        ...data,
      };
    },
    addPendingProcessData: (state, { payload: { id, data } }) => {
      return {
        ...state,
        pendingProcessData: [
          ...state.pendingProcessData,
          { id: id, data: data },
        ],
      };
    },
    removePendingProcessData: (state, { payload: { id } }) => {
      return {
        ...state,
        pendingProcessData: state.pendingProcessData.filter(
          (item) => item.id !== id
        ),
      };
    },
    setError: (state, { payload: { is_error } }) => {
      state.isError = is_error;
    },
  },
});

export const {
  setDynamicParams,
  setParamsBlock,
  setParamsTab,
  setParamsThemeSetting,
  setParamsAffiliateProductTours,
  addPendingProcessData,
  removePendingProcessData,
  setError,
} = dynamicSlice.actions;
export default dynamicSlice.reducer;
