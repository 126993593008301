import { BUILDER_IMAGE_HOST, PASSIO_IMAGE_HOST } from "@share/configs/const";

export const getHorizontalResizeUrl = (url: string, width = 140) => {
  if (
    url.startsWith(BUILDER_IMAGE_HOST) &&
    url.indexOf("passio-prod/builder") !== -1
  ) {
    return url
      .replace(BUILDER_IMAGE_HOST, PASSIO_IMAGE_HOST)
      .replace("passio-prod/builder", `passio-prod/${width}/builder`);
  } else {
    return url;
  }
};
