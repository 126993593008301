import { RESIZE } from "@share/components/ImageUploadCommon/constants";
import { InformationTriangleIcon } from "@share/icons";
import { mockUpload } from "@share/lib";
import { Button, Image, ImageUploader } from "antd-mobile";
import React from "react";
import { useTranslation } from "react-i18next";

const Layout1 = (props) => {
  const { listImg, handleChangeImgs, imgStatus, errorMsg, value } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="grid grid-cols-[120px_auto] gap-4">
        <Image
          src={
            value?.url ||
            "https://i.ecomobi.com/ssp/passio-page/image-default.png"
          }
          fit="cover"
          style={{
            borderRadius: "8px",
            maxWidth: "120px",
            maxHeight: "120px",
          }}
        />
        <div>
          <div className="mb-12 leading-4">
            {t("ss_builder_theme_setting_background_content")}
          </div>
          <ImageUploader
            onChange={handleChangeImgs}
            upload={(file) =>
              mockUpload(file, RESIZE.MAX_WIDTH)
            }
            className="flex-grow"
            value={listImg}
            children={
              <Button
                color="primary"
                fill="solid"
                size="middle"
                className="w-full"
              >
                {t("ss_builder_upload_button_label")}
              </Button>
            }
          />
        </div>
      </div>
      {errorMsg[imgStatus] && (
        <div className="flex flex-row gap-1 items-center mt-1">
          <InformationTriangleIcon
            fillColor="#FF3141"
            className="w-4 h-4"
          />
          <div className="text-[#FF3141]">{errorMsg[imgStatus]}</div>
        </div>
      )}
    </>
  );
};

export default Layout1;
