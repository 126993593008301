import axios from "axios";

export interface paramsInterface {
  urlSuffix: string;
  groupId?: string;
}

const GATEWAY_PATH = {
  GET_DATA: "api/v1/pub/builder/general/get-data",
  GET_DATA_FROM_SHOPEE_AFF:
    "api/v1/pub/builder/general/get-data-from-shopee-aff",
};

const API_PATH = {
  GET_HTML_DATA: "api/crawl-site/get-data-url"
}

export const gatewayInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_GATEWAY_URL,
});

export const myPassioInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MY_PASSIO_API_URL,
});

export const requestGetDataFromUrl = async (url: string) => {
  const res = await gatewayInstance.get(GATEWAY_PATH.GET_DATA, {
    params: { url: url },
  });
  return res.data;
};

export const requestGetDataFromShopeeAff = async (params: paramsInterface) => {
  const res = await gatewayInstance.get(GATEWAY_PATH.GET_DATA_FROM_SHOPEE_AFF, {
    params,
  });
  return res.data;
};

export const requestGetHTMLDataFromUrl = async (url: string) => {
  const res = await myPassioInstance.get(API_PATH.GET_HTML_DATA, {
    params: { url: url },
  });
  return res.data;
};