export const TYPE_JSON = "application/json";
export const IMG_PRODUCT_DEFAULT = "https://image.passio.eco/160x160/page-builder/page-builder/images-default/product-default.jpeg"
export const DEFAULT_AFF_BLOCK = {
  uid: "",
  block_type: "affiliate_product",
  content_attributes: {
    items: [],
    title_product_group: "",
  },
  enable: 1,
  sort_order: 1,
  style_attributes: {},
  title: "",
}

export const DEFAULT_OTHER_AFF_BLOCK = {
  uid: "",
  block_type: "affiliate_product",
  content_attributes: {
    items: [],
    title_product_group: "Other",
  },
  enable: 1,
  sort_order: 1,
  style_attributes: {},
  title: "Other"
}

export const DEFAULT_OTHER_GROUP = "Other";
export const INDEX_NOT_FOUND = -1;
export const MAX_PRODUCT_ITEMS = 400;
export const SOURCE_URL_TYPE = {
  SHOPEE: "shopee",
  LAZADA: "lazada",
  TIKI: "tiki",
  OTHER: "other",
}

export const REG_ECOMERCE_ID = {
  shopee: /https?:\/\/(?:www\.)?shopee\.vn\/.*?(\.\d+\.\d+)/i,
  lazada: /https?:\/\/(?:www\.)?lazada\..*?(i\d+-s\d+)/i,
  tiki: /https?:\/\/(?:www\.)?tiki\..*?(p\d+)\./i
}

export const IMPORT_TYPE = {
  BY_JSON: "by_json",
  BY_URL: "by_url"
}
