import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SavingElement[] = [];

interface SavingElement {
  id: string;
  lockKeys: string[];
  metaData: any;
}

export const savingProcessSlice = createSlice({
  name: "savingProcess",
  initialState,
  reducers: {
    addSavingElement: (
      state,
      action: PayloadAction<{ element: SavingElement }>
    ) => {
      return [...state, action.payload.element];
    },
    releaseSavingElement: (state, action: PayloadAction<{ id: string }>) => {
      return state.filter((item) => item.id !== action.payload.id);
    },
  },
});

export const { addSavingElement, releaseSavingElement } =
  savingProcessSlice.actions;
export default savingProcessSlice.reducer;
