import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Divider,
  Image,
  Input,
  NoticeBar,
  Selector,
  TextArea,
} from "antd-mobile";

import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import FullScreenPopup from "@share/components/full-screen-popup";
import {
  CheckCircleIconV3,
  PackageIcon,
  RightArrowIcon,
  FrameIcon,
  ArrowIcon,
} from "@share/icons";
import AffiliateProductGroupManagement from "./AffiliateProductGroupManagement";
import {
  ENABLE_BLOCK,
  KEY_BLOCK,
  PRODUCT_DISPLAY_STYLES,
  PRODUCT_GROUP_MAXIMUM,
  PRODUCT_ON_ROW,
} from "@share/configs/const";
import AutoScrollSelector from "@share/components/AutoScrollSelector";
import ListItemScrollHorizontal from "@features/design/components/ListItemScrollHorizontal";
import ProductGroupTabItemDetail from "./ProductGroupTabItemDetail";
import {
  AFFILIATE_SETTING_TOUR_CLASS_NAME,
  STEP_INDEX,
} from "./AffiliateProductTourOpening/ProductSettingTour/setting-tour-config";
import { setParamsAffiliateProductTours } from "@features/main/store/dynamicSlice";
import ProductListLayoutHandler from "./ProductListLayoutHandler";
import ProductUploadFile from "./ProductUploadFile";
import DetectedUrlLists from "./AffiliateProductAddNew/DetectedUrlLists";
import FrameAff from "@features/block/block-editor/AffiliateProductGroup/Frame";
import { AffiliateContext } from "@features/block/block-editor/AffiliateProductGroup/context";
import { LIST_FRAME } from "@features/block/block-editor/AffiliateProductGroup/Frame/const";

const BLOCK_HEADING_MAXIMUM = 50;
const BLOCK_DESCRIPTION_MAXIMUM = 250;

const AffiliateProductGroup = (props) => {
  const {
    onUpdateData,
    block,
    setButtonStatus,
    setEditStatus,
    defaultBlockData,
  } = props;

  const { t } = useTranslation();

  const UID = `affiliate-product-group-${uuidv4()}`;

  const dispatch = useAppDispatch();

  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [openProductSelectionChecking, setOpenProductSelectionChecking] =
    useState(false);

  const stickyRef = useRef<HTMLDivElement>(null);
  const headingAreaRef = useRef<HTMLDivElement>(null);

  const edittingBlock = useAppSelector((state) => state.edittingBlock);
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);

  const { hiddenSteps } = dynamicConfig?.affiliateSettingTour || {};
  const isOpenAffiliateSettingTour = dynamicConfig?.isOpenAffiliateSettingTour;

  const KEY = edittingBlock?.KEY;
  const isUploadProductFile = useAppSelector(
    (state) => state?.dynamicConfig?.isUploadProductFile
  );
  const isUploadProductFromSiteUrl = useAppSelector(
    (state) => state?.dynamicConfig?.isUploadProductFromSiteUrl
  );
  const [isOpenDetectedUrlLists, setIsOpenDetectedUrlLists] = useState(false);
  const [detectedUrlList, setDetectedUrlList] = useState([]);
  const [validUrlList, setValidUrlList] = useState([]);
  const [isShowNoticeUpload, setIsShowNoticeUpload] = useState(false);

  // const edittingBlockData = edittingBlock && edittingBlock.block_data;

  useEffect(() => {
    const ref = stickyRef.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    observer.observe(ref);
    return () => observer.unobserve(ref);
  }, [stickyRef]);

  const affiliateProductDefault = useMemo(() => {
    return {
      uid: `affiliate-product-${uuidv4()}`,
      sort_order: 1,
      enable: 1,
      block_type: BLOCK_TYPE.AFF_PRODUCT,
      title: t("ss_builder_product_group_default_title"),
      content_attributes: {
        items: [],
        title_product_group: t("ss_builder_product_group_default_title"),
      },
      style_attributes: {},
    };
  }, []);

  const elementId = useMemo(() => {
    return "e" + Math.random().toString(36).slice(2, 7);
  }, []);

  const defaultValueEditAffiliateProductGroup =
    defaultBlockData && defaultBlockData.uid ? defaultBlockData : null;

  const titleBlockDefault =
    defaultValueEditAffiliateProductGroup?.title || "Affiliate Product";

  const valueDescriptionBlockDefault =
    defaultValueEditAffiliateProductGroup?.content_attributes
      ?.block_description || "";

  const affiliateProductGroupArrDefault = defaultValueEditAffiliateProductGroup
    ?.content_attributes?.items || [affiliateProductDefault];

  const seeMoreBtnContentDefault =
    defaultValueEditAffiliateProductGroup?.content_attributes
      ?.see_more_btn_content || t("ss_builder_see_more_btn_default_title");

  const affiliateProductTitleDefault =
    defaultValueEditAffiliateProductGroup?.content_attributes
      ?.title_product_group || "";

  const activeProductGroupDefault =
    defaultValueEditAffiliateProductGroup?.content_attributes[
      defaultValueEditAffiliateProductGroup?.uid
    ] || affiliateProductGroupArrDefault[0]?.uid;

  const styleProductDisplayDefault = useMemo(() => {
    const styleDisPlay =
      defaultValueEditAffiliateProductGroup?.style_attributes?.style_display ??
      "grid";
    if (styleDisPlay && styleDisPlay.includes("grid")) {
      return "grid";
    } else return styleDisPlay;
  }, [defaultValueEditAffiliateProductGroup]);

  const usingFrameDefault =
    defaultValueEditAffiliateProductGroup?.content_attributes?.using_frame ||
    false;
  const frameOfAffDefault =
    defaultValueEditAffiliateProductGroup?.content_attributes?.frame_of_aff ||
    LIST_FRAME[0];
  const [styleProductDisplay, setStyleProductDisplay] = useState(
    styleProductDisplayDefault || "grid"
  );

  const is_created_new =
    defaultValueEditAffiliateProductGroup?.content_attributes?.is_created_new ??
    false;

  const product_group_maximum =
    defaultValueEditAffiliateProductGroup?.content_attributes
      ?.product_group_maximum;

  const styleGridProductDisplayDefault = useMemo(() => {
    const styleDisPlay =
      defaultValueEditAffiliateProductGroup?.style_attributes?.style_display;
    const itemOnRow =
      defaultValueEditAffiliateProductGroup?.style_attributes?.item_on_row;
    if (styleDisPlay && styleDisPlay === "grid-1") {
      return PRODUCT_ON_ROW.ONE;
    } else if (styleDisPlay && styleDisPlay === "grid-2") {
      return PRODUCT_ON_ROW.TWO;
    } else if (styleDisPlay && styleDisPlay === "grid-3") {
      return PRODUCT_ON_ROW.THREE;
    } else {
      if (
        styleProductDisplay !== "grid" &&
        styleProductDisplay !== "text-over-image"
      ) {
        return null;
      } else {
        if (itemOnRow) {
          return itemOnRow;
        } else return PRODUCT_ON_ROW.TWO;
      }
    }
  }, [defaultValueEditAffiliateProductGroup, styleProductDisplay]);

  const [styleGridProductDisplay, setStyleGridProductDisplay] = useState(
    styleGridProductDisplayDefault || PRODUCT_ON_ROW.TWO
  );

  const [affiliateProductGroupData, setAffiliateProductGroupData] = useState(
    affiliateProductGroupArrDefault
  );
  const [activeProductGroup, setActiveProductGroup] = useState(
    activeProductGroupDefault
  );

  const [seeMoreBtnContent, setSeeMoreBtnContent] = useState(
    seeMoreBtnContentDefault || t("ss_builder_see_more_btn_default_title")
  );

  const [affiliateProductTitle, setAffiliateProductTitle] = useState(
    affiliateProductTitleDefault || ""
  );
  const [isOpenAddProdcutGroup, setIsOpenAddProductGroup] = useState(false);
  const [valueDescriptionBlock, setValueDescriptionBlock] = useState(
    valueDescriptionBlockDefault
  );

  const [isCreatedNew, setIsCreatedNew] = useState<boolean>(is_created_new);
  const [isClickAction, setIsClickAction] = useState(false);

  const [openSettingFrame, setOpenSettingFrame] = useState<boolean>(false);
  const [usingFrame, setUsingFrame] = useState<boolean>(usingFrameDefault);
  const [frameData, setFrameData] = useState<any>(frameOfAffDefault);

  const productGroupQuantityDefaultRef = useRef<number>(
    affiliateProductGroupData?.length
  );

  const activedProductGroupData = useMemo(
    () => affiliateProductGroupData?.find((x) => x?.uid === activeProductGroup),
    [affiliateProductGroupData, activeProductGroup]
  );

  const productGroupMaximumRef = useRef<number>(product_group_maximum);

  const productGroupMaximum = useMemo(() => {
    const productGroupQuantity = affiliateProductGroupData?.length;
    const PRODUCT_GROUP_QUANTITY_CHECKING = 25;
    if (productGroupMaximumRef.current) {
      if (productGroupMaximumRef.current === PRODUCT_GROUP_MAXIMUM) {
        return productGroupMaximumRef.current;
      } else {
        if (productGroupQuantity >= productGroupQuantityDefaultRef.current) {
          productGroupQuantityDefaultRef.current = productGroupQuantity;
          return productGroupMaximumRef.current;
        } else {
          productGroupQuantityDefaultRef.current = productGroupQuantity;
          if (productGroupMaximumRef.current - productGroupQuantity >= 5) {
            if (productGroupQuantity + 5 > PRODUCT_GROUP_MAXIMUM) {
              productGroupMaximumRef.current = productGroupQuantity + 5;
              return productGroupQuantity + 5;
            } else {
              productGroupMaximumRef.current = PRODUCT_GROUP_MAXIMUM;
              return PRODUCT_GROUP_MAXIMUM;
            }
          } else {
            return productGroupMaximumRef.current;
          }
        }
      }
    } else {
      if (KEY === KEY_BLOCK.ADD_NEW) {
        setIsCreatedNew(true);
        return PRODUCT_GROUP_MAXIMUM;
      } else {
        if (isCreatedNew) {
          return PRODUCT_GROUP_MAXIMUM;
        } else {
          if (productGroupQuantity > PRODUCT_GROUP_MAXIMUM) {
            if (productGroupQuantity > productGroupQuantityDefaultRef.current) {
              return productGroupQuantityDefaultRef.current + 5;
            } else {
              productGroupQuantityDefaultRef.current = productGroupQuantity;
              return productGroupQuantity + 5;
            }
          } else {
            if (productGroupQuantity > PRODUCT_GROUP_QUANTITY_CHECKING) {
              if (
                productGroupQuantity > productGroupQuantityDefaultRef.current
              ) {
                return productGroupQuantityDefaultRef.current + 5;
              } else {
                productGroupQuantityDefaultRef.current = productGroupQuantity;
                return productGroupQuantity + 5;
              }
            } else {
              setIsCreatedNew(true);
              return PRODUCT_GROUP_MAXIMUM;
            }
          }
        }
      }
    }
  }, [isCreatedNew, affiliateProductGroupData]);

  useEffect(() => {
    let hiddenStepsClone = Array.from(hiddenSteps);

    if (KEY === KEY_BLOCK.ADD_NEW) {
      if (!hiddenStepsClone?.includes(STEP_INDEX.STEP_1))
        hiddenStepsClone.push(STEP_INDEX.STEP_1);
      if (activedProductGroupData?.content_attributes?.items?.length <= 0) {
        if (!hiddenStepsClone?.includes(STEP_INDEX.STEP_7))
          hiddenStepsClone.push(STEP_INDEX.STEP_7);
      } else {
        if (hiddenStepsClone?.includes(STEP_INDEX.STEP_7))
          hiddenStepsClone = hiddenStepsClone.filter(
            (x) => x !== STEP_INDEX.STEP_7
          );
      }
    } else {
      if (hiddenStepsClone?.includes(STEP_INDEX.STEP_1))
        hiddenStepsClone = hiddenStepsClone.filter(
          (x) => x !== STEP_INDEX.STEP_1
        );
      if (openProductSelectionChecking) {
        if (!hiddenStepsClone?.includes(STEP_INDEX.STEP_6))
          hiddenStepsClone.push(STEP_INDEX.STEP_6);
        if (!hiddenStepsClone?.includes(STEP_INDEX.STEP_7))
          hiddenStepsClone.push(STEP_INDEX.STEP_7);
        if (!hiddenStepsClone?.includes(STEP_INDEX.STEP_8))
          hiddenStepsClone.push(STEP_INDEX.STEP_8);
      } else {
        if (hiddenStepsClone?.includes(STEP_INDEX.STEP_6))
          hiddenStepsClone = hiddenStepsClone.filter(
            (x) => x !== STEP_INDEX.STEP_6
          );
        if (hiddenStepsClone?.includes(STEP_INDEX.STEP_7))
          hiddenStepsClone = hiddenStepsClone.filter(
            (x) => x !== STEP_INDEX.STEP_7
          );
        if (hiddenStepsClone?.includes(STEP_INDEX.STEP_8))
          hiddenStepsClone = hiddenStepsClone.filter(
            (x) => x !== STEP_INDEX.STEP_8
          );
        if (activedProductGroupData?.content_attributes?.items?.length <= 0) {
          if (!hiddenStepsClone?.includes(STEP_INDEX.STEP_7))
            hiddenStepsClone.push(STEP_INDEX.STEP_7);
        } else {
          if (hiddenStepsClone?.includes(STEP_INDEX.STEP_7))
            hiddenStepsClone = hiddenStepsClone.filter(
              (x) => x !== STEP_INDEX.STEP_7
            );
        }
      }
    }
    dispatch(
      setParamsAffiliateProductTours({
        data: {
          hiddenSteps: hiddenStepsClone,
        },
      })
    );
  }, [activedProductGroupData, KEY, openProductSelectionChecking]);

  useEffect(() => {
    if (!block.uid) {
      const defaultAffiliateProductData = {
        uid: UID,
        sort_order: 1,
        enable: ENABLE_BLOCK,
        block_type: BLOCK_TYPE.AFF_PRODUCT_GROUP,
        title: titleBlockDefault,
        content_attributes: {
          items: affiliateProductGroupData,
          title_product_group: affiliateProductTitle,
          see_more_btn_content: seeMoreBtnContent,
          block_description: valueDescriptionBlock,
          block_heading: affiliateProductTitle,
          is_created_new: isCreatedNew,
          product_group_maximum: productGroupMaximum,
          using_frame: usingFrame,
          frame_of_aff: frameData,
        },
        style_attributes: {
          style_display: styleProductDisplay,
          item_on_row: styleGridProductDisplay,
        },
      };
      onUpdateData(defaultAffiliateProductData);
    } else {
      onUpdateData({
        ...block,
        title: titleBlockDefault,
        content_attributes: {
          items: affiliateProductGroupData,
          title_product_group: affiliateProductTitle,
          see_more_btn_content: seeMoreBtnContent,
          block_description: valueDescriptionBlock,
          block_heading: affiliateProductTitle,
          is_created_new: isCreatedNew,
          product_group_maximum: productGroupMaximum,
          using_frame: usingFrame,
          frame_of_aff: frameData,
        },
        style_attributes: {
          style_display: styleProductDisplay,
          item_on_row: styleGridProductDisplay,
        },
      });
    }
  }, [
    affiliateProductGroupData,
    affiliateProductTitle,
    seeMoreBtnContent,
    styleProductDisplay,
    styleGridProductDisplay,
    valueDescriptionBlock,
    isCreatedNew,
    productGroupMaximum,
    usingFrame,
    frameData,
  ]);

  useEffect(() => {
    if (
      styleProductDisplay !== "grid" &&
      styleProductDisplay !== "text-over-image"
    ) {
      setStyleGridProductDisplay(null);
    } else {
      if (!styleGridProductDisplay) {
        setStyleGridProductDisplay(PRODUCT_ON_ROW.TWO);
      }
    }
  }, [styleProductDisplay, styleGridProductDisplay]);

  const isExistErrorProduct = affiliateProductGroupData?.some((group) =>
    group?.content_attributes?.items?.some(
      (item) => !item?.title
    )
  );

  const isDisableBtnSave = useMemo(() => {
    if (
      seeMoreBtnContent &&
      !defaultValueEditAffiliateProductGroup &&
      !isExistErrorProduct
    )
      return false;
    return !(
      seeMoreBtnContent &&
      !isExistErrorProduct &&
      (JSON.stringify(affiliateProductGroupData) !==
        JSON.stringify(affiliateProductGroupArrDefault) ||
        styleProductDisplay !== styleProductDisplayDefault ||
        styleGridProductDisplay !== styleGridProductDisplayDefault ||
        affiliateProductTitle !== affiliateProductTitleDefault ||
        seeMoreBtnContent !== seeMoreBtnContentDefault ||
        valueDescriptionBlock !== valueDescriptionBlockDefault ||
        usingFrame !== usingFrameDefault ||
        frameData !== frameOfAffDefault)
    );
  }, [
    affiliateProductGroupData,
    affiliateProductGroupArrDefault,
    styleProductDisplay,
    styleProductDisplayDefault,
    styleGridProductDisplay,
    styleGridProductDisplayDefault,
    affiliateProductTitle,
    affiliateProductTitleDefault,
    seeMoreBtnContent,
    seeMoreBtnContentDefault,
    valueDescriptionBlock,
    valueDescriptionBlockDefault,
    frameData,
    usingFrame,
    isExistErrorProduct,
  ]);

  const isEditDataStatus = useMemo(() => {
    if (
      JSON.stringify(affiliateProductGroupData) !==
        JSON.stringify(affiliateProductGroupArrDefault) ||
      styleProductDisplay !== styleProductDisplayDefault ||
      styleGridProductDisplay !== styleGridProductDisplayDefault ||
      affiliateProductTitle !== affiliateProductTitleDefault ||
      seeMoreBtnContent !== seeMoreBtnContentDefault ||
      valueDescriptionBlock !== valueDescriptionBlockDefault ||
      usingFrame !== usingFrameDefault ||
      frameData !== frameOfAffDefault
    ) {
      return true;
    } else return false;
  }, [
    affiliateProductGroupData,
    affiliateProductGroupArrDefault,
    styleProductDisplay,
    styleProductDisplayDefault,
    styleGridProductDisplay,
    styleGridProductDisplayDefault,
    affiliateProductTitle,
    affiliateProductTitleDefault,
    seeMoreBtnContent,
    seeMoreBtnContentDefault,
    valueDescriptionBlock,
    valueDescriptionBlockDefault,
    frameData,
    usingFrame,
  ]);

  useEffect(() => {
    setButtonStatus(isDisableBtnSave);
  }, [isDisableBtnSave]);

  useEffect(() => {
    setEditStatus(isEditDataStatus);
  }, [isEditDataStatus]);

  const styleProductDisplayOptions = [
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/afiliate-product-grid-style.png"
        />
      ),
      description: (
        <div
          className="mt-1"
          id={elementId + "-" + PRODUCT_DISPLAY_STYLES.GRID}
        >
          {t("ss_builder_product_list_style_grid_label")}
        </div>
      ),
      value: PRODUCT_DISPLAY_STYLES.GRID,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/affiliate-product-slide-style.png"
        />
      ),
      description: (
        <div
          className="mt-1"
          id={elementId + "-" + PRODUCT_DISPLAY_STYLES.SLIDE}
        >
          {t("ss_builder_product_list_style_slide_label")}
        </div>
      ),
      value: PRODUCT_DISPLAY_STYLES.SLIDE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/affiliate-product-text-over-image-style.png"
        />
      ),
      description: (
        <div
          className="mt-1"
          id={elementId + "-" + PRODUCT_DISPLAY_STYLES.TEXT_OVER_IMAGE}
        >
          {t("ss_builder_product_list_style_text_over_image_label")}
        </div>
      ),
      value: PRODUCT_DISPLAY_STYLES.TEXT_OVER_IMAGE,
    },
    {
      label: (
        <Image
          fit="cover"
          src="https://image.passio.eco/page-builder/page-builder/display-styles/affiliate-product-zig-zag-style.png"
        />
      ),
      description: (
        <div
          className="mt-1"
          id={elementId + "-" + PRODUCT_DISPLAY_STYLES.ZIGZAG}
        >
          {t("ss_builder_product_list_style_zig_zag_label")}
        </div>
      ),
      value: PRODUCT_DISPLAY_STYLES.ZIGZAG,
    },
  ];

  const styleProductOnLineOptions = [
    {
      label: "1",
      value: PRODUCT_ON_ROW.ONE,
    },
    {
      label: "2",
      value: PRODUCT_ON_ROW.TWO,
    },
    {
      label: "3",
      value: PRODUCT_ON_ROW.THREE,
    },
  ];

  const handleSelectStyleProductDisplay = (arr) => {
    if (arr && arr.length) {
      const valueStyle = arr[0];
      setStyleProductDisplay(valueStyle);
    }
  };

  const handleSelectStyleProductOnLineOptions = (arr) => {
    if (arr && arr.length) {
      const valueGridStyle = arr[0];
      setStyleGridProductDisplay(valueGridStyle);
    }
  };

  const handleChangeAffiliateProductTitle = (val) => {
    if (val?.length <= BLOCK_HEADING_MAXIMUM) {
      setAffiliateProductTitle(val);
    }
  };

  const handleChangeBlockDescription = (val) => {
    if (val?.length <= BLOCK_DESCRIPTION_MAXIMUM) {
      setValueDescriptionBlock(val);
    }
  };

  const handleChangeSeeMoreBtnContent = (value) => {
    if (seeMoreBtnContent.length <= 25) {
      setSeeMoreBtnContent(value);
    }
  };

  const handleUpdateAffiliateProductGroup = (data) => {
    setAffiliateProductGroupData(data);
  };

  const handleUploadProductFile = ({
    listProduct,
    detectUrlList,
    validUrlList,
  }) => {
    //set active group has first valid added item
    if (listProduct?.length && validUrlList?.length) {
      let activeUid = null;
      for (let key = 0; key < listProduct?.length; key++) {
        const items = listProduct[key]?.content_attributes?.items;
        for (let keyItem = 0; keyItem < items?.length; keyItem++) {
          if (items[keyItem].origin_link === validUrlList[0]) {
            activeUid = listProduct[key]?.uid;
            break;
          }
        }
        if (activeUid) break;
      }
      if (activeUid) setActiveProductGroup(activeUid);
      setAffiliateProductGroupData(listProduct);
    }
    setDetectedUrlList(detectUrlList);
    setValidUrlList(validUrlList);
    setIsShowNoticeUpload(true);
  };
  const handleOpenSettingFrame = (value) => {
    setOpenSettingFrame(value);
  };
  const handleClickSaveFrame = (useFrame, data) => {
    setUsingFrame(useFrame);
    setFrameData(data);
  };

  return (
    <AffiliateContext.Provider value={{ frameData, usingFrame }}>
      {isShowNoticeUpload && (
        <div
          className="cursor-pointer"
          onClick={() => setIsOpenDetectedUrlLists(true)}
        >
          <NoticeBar
            extra={
              <RightArrowIcon
                className="w-[18px] h-[18px]"
                fillColor="#389E0D"
              />
            }
            icon={
              <CheckCircleIconV3
                className="w-[18px] h-[18px]"
                fillColor="#389E0D"
              />
            }
            content={t("ss_builder_multi_product_url_imported_label", {
              successfullyUrlQuantity: validUrlList?.length,
              detectedUrlQuantity: detectedUrlList?.length,
            })}
            style={{
              "--background-color": "#D9F7BE",
              "--text-color": "#389E0D",
              "--border-color": "#D9F7BE",
            }}
          />
        </div>
      )}
      <div
        id={AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_2}
        className={cls(
          AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_2,
          "bg-white px-4 py-[13px]"
        )}
        ref={headingAreaRef}
      >
        <div>
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_affiliate_product_group_title")}
          </div>
          <Input
            placeholder={t("ss_builder_affiliate_product_group_placeholder")}
            onChange={handleChangeAffiliateProductTitle}
            value={affiliateProductTitle}
            maxLength={BLOCK_HEADING_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            affiliateProductTitle ? affiliateProductTitle.length : 0
          }/${BLOCK_HEADING_MAXIMUM}`}</div>
        </div>
        <Divider />
        <div>
          <span className="text-[15px] leading-[13px] text-[#666666]">
            {t("ss_builder_block_description_label")}
          </span>
          <TextArea
            placeholder={t("ss_builder_block_description_placeholder")}
            onChange={handleChangeBlockDescription}
            value={valueDescriptionBlock}
            maxLength={BLOCK_DESCRIPTION_MAXIMUM}
          />
          <div className="text-[#999999] text-[13px] leading-[17px] w-full text-right">{`${
            valueDescriptionBlock ? valueDescriptionBlock.length : 0
          }/${BLOCK_DESCRIPTION_MAXIMUM}`}</div>
        </div>
      </div>
      <div className="bg-white mt-3 px-4 py-[13px]">
        <div
          id={AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_3}
          className={cls(AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_3)}
        >
          <div className="text-[15px] text-[#666666]">
            {t("ss_builder_affiliate_product_style_title")}
          </div>
          <AutoScrollSelector
            elementId={elementId}
            options={styleProductDisplayOptions}
            value={styleProductDisplay}
            onChange={handleSelectStyleProductDisplay}
          />
          {(styleProductDisplay === "grid" ||
            styleProductDisplay === "text-over-image") && (
            <>
              <Divider />
              <div>
                <div className="text-[15px] text-[#666666]">
                  {t("ss_builder_affiliate_product_quantity_on_row_title")}
                </div>
                <Selector
                  options={styleProductOnLineOptions}
                  value={[styleGridProductDisplay]}
                  onChange={handleSelectStyleProductOnLineOptions}
                  columns={3}
                  style={{
                    "--border": "solid transparent 1px",
                    "--checked-border": "solid var(--adm-color-primary) 1px",
                  }}
                />
              </div>
            </>
          )}
        </div>
        <Divider />
        <div>
          <div className="text-[15px] text-[#666666]">
            <span className="text-red-600">*</span>
            {t("ss_builder_affiliate_product_btn_see_more_title")}
          </div>
          <Input
            value={seeMoreBtnContent}
            placeholder={t(
              "ss_builder_affiliate_product_btn_see_more_placeholder"
            )}
            onChange={handleChangeSeeMoreBtnContent}
            maxLength={25}
          />
        </div>
      </div>

      <div
        className="mt-3 p-3 bg-white flex justify-between cursor-pointer"
        onClick={() => handleOpenSettingFrame(true)}
      >
        <div className="flex gap-3">
          <FrameIcon />
          <div className="text-[17px] leading-[22px] font-normal text-[#333333]">
            {t("ss_builder_frame_aff")}
          </div>
        </div>
        <ArrowIcon />
      </div>

      <div className="py-[13px]">
        <div
          className="bg-white px-4 pt-3 pb-3"
          ref={stickyRef}
        >
          <Button
            className={AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_4}
            block
            color="primary"
            fill="outline"
            size="middle"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              // padding: "3px 12px 3px 12px",
              // marginBottom: "16px",
            }}
            onClick={() => setIsOpenAddProductGroup(true)}
          >
            <PackageIcon
              className="w-[17px] h-[17px]"
              fillColor="#ee3244"
            />
            {t("ss_builder_affiliate_product_group_management_title")}
          </Button>
        </div>
        <div
          id={AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_5}
          className={cls(
            AFFILIATE_SETTING_TOUR_CLASS_NAME.STEP_5,
            "bg-white",
            !isOpenAffiliateSettingTour && "sticky z-10"
          )}
          style={{
            borderBottom: "2px solid #eee",
            top: !isOpenAffiliateSettingTour
              ? block?.enable === 1
                ? 45
                : 83
              : "",
          }}
        >
          <ListItemScrollHorizontal>
            {affiliateProductGroupData?.map((item, index) => (
              <ProductGroupTabItemDetail
                key={index}
                item={item}
                onSelect={(ele) => {
                  if (!openProductSelectionChecking) {
                    setActiveProductGroup(item.uid);
                    ele.scrollIntoView({
                      block: "nearest",
                      behavior: isClickAction ? "smooth" : "auto",
                      inline: "center",
                    });
                  }
                }}
                selected={activeProductGroup === item?.uid}
                isDisabledSelection={openProductSelectionChecking}
                onClickAction={() => setIsClickAction(true)}
              />
            ))}
          </ListItemScrollHorizontal>
        </div>
        <ProductListLayoutHandler
          affiliateProductGroupData={affiliateProductGroupData}
          activeProductGroup={activeProductGroup}
          onUpdateProductGroupData={(data) =>
            setAffiliateProductGroupData(data)
          }
          isSticky={isSticky}
          productGroupMaximum={productGroupMaximum}
          onCheckProductSelectionOpening={(val) =>
            setOpenProductSelectionChecking(val)
          }
          stickyTop={
            !isOpenAffiliateSettingTour ? (block?.enable === 1 ? 86 : 124) : 0
          }
        />
      </div>
      <ProductUploadFile
        isUploadProductFile={isUploadProductFile}
        onChange={handleUploadProductFile}
        affiliateProductGroupData={affiliateProductGroupData}
        isUploadProductFromSiteUrl={isUploadProductFromSiteUrl}
      />
      {isOpenAddProdcutGroup && (
        <FullScreenPopup
          visible={isOpenAddProdcutGroup}
          onMaskClick={() => setIsOpenAddProductGroup(false)}
        >
          <AffiliateProductGroupManagement
            onCancelAffiliateProductGroupManagement={() =>
              setIsOpenAddProductGroup(false)
            }
            onUpdateAffiliateProductGroup={handleUpdateAffiliateProductGroup}
            affiliateProductGroupData={affiliateProductGroupData}
            productGroupMaximum={productGroupMaximum}
            onActiveProductGroup={(value) => {
              setActiveProductGroup(value);
              setIsOpenAddProductGroup(false);
              setIsClickAction(false);
            }}
          />
        </FullScreenPopup>
      )}
      {isOpenDetectedUrlLists && (
        <FullScreenPopup
          visible={isOpenDetectedUrlLists}
          onMaskClick={() => {
            setIsOpenDetectedUrlLists(false);
          }}
        >
          <DetectedUrlLists
            onCancel={() => setIsOpenDetectedUrlLists(false)}
            urlLists={detectedUrlList}
          />
        </FullScreenPopup>
      )}

      <FullScreenPopup
        visible={openSettingFrame}
        onMaskClick={() => setOpenSettingFrame(false)}
      >
        <FrameAff
          triggerOpenClose={handleOpenSettingFrame}
          handleClickSave={handleClickSaveFrame}
          frameDefault={frameData}
          usingFrameDefault={usingFrame}
        />
      </FullScreenPopup>
    </AffiliateContext.Provider>
  );
};

export default AffiliateProductGroup;
